import { MenuItem, Stack, TextField } from '@material-ui/core';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@material-ui/lab';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as React from 'react';
import { copyTermConfig } from '../serviceCalls';
import { setSnackbar } from '../../../../actions/alertActions';

function CopyDialog(props) {
  const { data, refreshData, handleClose, dispatch } = props;

  const Schema = Yup.object().shape({
    source_term_id: Yup.string().required('Source Term is required'),
    target_term_id: Yup.string().required('Target Term is required')
  });

  const formik = useFormik({
    initialValues: {
      source_term_id: '',
      target_term_id: ''
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      copyTermConfig(
        values,
        () => {
          refreshData();
          dispatch(setSnackbar('success', 'Term has been copied'));
        },
        () => {}
      );
    }
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="end" spacing={2} sx={{ mt: 1 }}>
            <TextField
              fullWidth
              select
              label="Source Term"
              {...getFieldProps('source_term_id')}
              error={Boolean(touched.source_term_id && errors.source_term_id)}
              helperText={touched.source_term_id && errors.source_term_id}
            >
              {data.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.description}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              fullWidth
              select
              label="Target Term"
              {...getFieldProps('target_term_id')}
              error={Boolean(touched.target_term_id && errors.target_term_id)}
              helperText={touched.target_term_id && errors.target_term_id}
            >
              {data.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.description}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
          <Stack direction="row" justifyContent="end" spacing={1}>
            <LoadingButton size="small" type="submit" variant="outlined">
              Copy
            </LoadingButton>
            <Button size="small" color="error" onClick={handleClose} variant="outlined">
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

CopyDialog.propTypes = {
  data: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired
};

export default connect(() => ({}))(CopyDialog);
