import * as React from 'react';
import { useState } from 'react';
import { Button, Stack } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { updateVip } from '../serviceCalls';

function ParticipantDialog(props) {
  const { editData, handleClose, handleRefresh } = props;
  const [reason, setReason] = useState(editData.vip_description);
  function handleUpdateVip() {
    const param = {
      participant_id: editData.id,
      description: reason
    };
    updateVip(
      param,
      () => {
        handleRefresh();
      },
      () => {}
    );
  }

  return (
    <Stack spacing={3} sx={{ mt: 1 }}>
      <TextField
        fullWidth
        label="Reason"
        value={reason}
        onChange={(e) => setReason(e.target.value)}
      />
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="end">
        <Button variant="outlined" onClick={handleUpdateVip}>
          Update
        </Button>
        <Button variant="outlined" color="error" onClick={handleClose}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
}

ParticipantDialog.propTypes = {
  editData: PropTypes.object,
  handleClose: PropTypes.func,
  handleRefresh: PropTypes.func
};

export default connect(() => ({}))(ParticipantDialog);
