const HOST = process.env.REACT_APP_REST_API_HOST;

const GET_SURVEY_DETAIL = {
  method: 'GET',
  path: `${HOST}go_shared/survey/survey`
};
const SEND_SURVEY_RESULT = {
  method: 'POST',
  path: `${HOST}go_shared/survey/survey/results`
};

module.exports = {
  GET_SURVEY_DETAIL,
  SEND_SURVEY_RESULT
};
