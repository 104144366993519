const HOST = process.env.REACT_APP_REST_API_HOST;

const GET_PERFORMANCE_OPERATION_LIST = {
  method: 'GET',
  path: `${HOST}go_admin/performance_operation/performance/list`
};
const GET_PERFORMANCE_OPERATION = {
  method: 'GET',
  path: `${HOST}go_admin/performance_operation/performance`
};
const UPDATE_STATUS_PERFORMANCE_OPERATION = {
  method: 'PATCH',
  path: `${HOST}go_admin/performance_operation/performance`
};
const UPDATE_PERFORMANCE_OPERATION = {
  method: 'PUT',
  path: `${HOST}go_admin/performance_operation/performance`
};
const GET_PERFORMANCE_OPERATION_PARTICIPANT_LIST = {
  method: 'GET',
  path: `${HOST}go_admin/performance_operation/performance/participant`
};
const GET_PERFORMANCE_OPERATION_STATUS = {
  method: 'GET',
  path: `${HOST}go_admin/performance_operation/performance/status`
};
const DOWNLOAD_PERFORMANCE_FILE = {
  method: 'GET',
  path: `${HOST}go_admin/performance_operation/performance/file`
};
const UPLOAD_PERFORMANCE_FILE = {
  method: 'POST',
  path: `${HOST}go_admin/performance_operation/performance/file`
};
const PATCH_PERFORMANCE_FILE = {
  method: 'PATCH',
  path: `${HOST}go_admin/performance_operation/performance/file`
};
const DELETE_PERFORMANCE_FILE = {
  method: 'DELETE',
  path: `${HOST}go_admin/performance_operation/performance/file`
};
const GET_PERFORMANCE_TYPE = {
  method: 'GET',
  path: `${HOST}go_admin/performance_operation/performance/type`
};
const GET_FLAG_SHOW_LIST = {
  method: 'GET',
  path: `${HOST}go_admin/performance_operation/flag_show`
};
const ADD_FLAG_SHOW = {
  method: 'POST',
  path: `${HOST}go_admin/performance_operation/flag_show`
};
const UPDATE_FLAG_SHOW = {
  method: 'PATCH',
  path: `${HOST}go_admin/performance_operation/flag_show`
};
const GET_ATTENDED_STUDENT_FOR_FLAG_SHOW = {
  method: 'GET',
  path: `${HOST}go_admin/performance_operation/flag_show/attended_student`
};

module.exports = {
  GET_PERFORMANCE_OPERATION_LIST,
  GET_PERFORMANCE_OPERATION,
  UPDATE_PERFORMANCE_OPERATION,
  UPDATE_STATUS_PERFORMANCE_OPERATION,
  GET_PERFORMANCE_OPERATION_STATUS,
  DOWNLOAD_PERFORMANCE_FILE,
  GET_FLAG_SHOW_LIST,
  ADD_FLAG_SHOW,
  UPDATE_FLAG_SHOW,
  GET_ATTENDED_STUDENT_FOR_FLAG_SHOW,
  GET_PERFORMANCE_OPERATION_PARTICIPANT_LIST,
  UPLOAD_PERFORMANCE_FILE,
  PATCH_PERFORMANCE_FILE,
  DELETE_PERFORMANCE_FILE,
  GET_PERFORMANCE_TYPE
};
