import { Navigate, useRoutes } from 'react-router-dom';
import { connect } from 'react-redux';
import { getRoutes } from './route_configs';
import DashboardLayout from './layouts/dashboard';
import Profile from './pages/Profile';
import MainLayout from './layouts/main';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import JudgeRegistration from './pages/Register/JudgeRegister/JudgeRegistration';
import FairRegistration from './pages/Register/FairRegistration';
import GeneralUserRegistration from './pages/Register/GeneralUserRegistration';
import ResetForm from './components/authentication/resetPassword/ResetForm';
import LoginWithLinkView from './pages/LoginWithLinkView';
import NotFound from './pages/Page404';
import PaymentLink from './pages/PaymentLink/PaymentLink';
import Survey from './pages/Survey/Survey';

function Router(props) {
  const { module_access, isProfileOK } = props;
  const routes = getRoutes(module_access, isProfileOK);
  console.log(isProfileOK, 'isProfileOK');
  const profileRoute = [
    {
      path: '/main',
      element: <MainLayout />,
      children: [{ path: '/', element: <Navigate to="/main" replace /> }]
    },
    {
      path: '/profile',
      element: <DashboardLayout />,
      children: [
        { path: '/', element: <Profile to="/profile" replace /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'judge_registration', element: <JudgeRegistration /> },
        { path: 'fair_registration', element: <FairRegistration /> },
        { path: 'general_user_registration', element: <GeneralUserRegistration /> },
        { path: 'reset', element: <ResetForm /> },
        { path: 'login_with_link:user_id', element: <LoginWithLinkView /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/main" /> },
        { path: 'payment', element: <PaymentLink /> },
        { path: 'survey', element: <Survey /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ];
  return useRoutes(isProfileOK ? routes : profileRoute);
}

export default connect((store) => ({
  module_access: store.loginReducer.module_access,
  isProfileOK: store.loginReducer.isProfileOK
}))(Router);
