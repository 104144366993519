import { Button, Grid } from '@mui/material';
import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Icon } from '@iconify/react';
import LandingPageCard from '../../../../components/CustomComponents/CustomCard/LandingPageCard';
import Page from '../../../../components/Page';
import SessionReport from './SessionReport';
import ProjectReport from './ProjectReport';

const CARDS = [
  {
    id: 1,
    name: 'Session Report',
    description: 'From this module you can arrange Session Report.'
  },
  {
    id: 2,
    name: 'Project Report',
    description: 'From this module you can arrange Project Report.'
  }
];

const LANDING_PAGE_MODE = 0;
const SESSION_MODE = 1;
const PROJECT_MODE = 2;

function Index() {
  const [mode, setMode] = useState(LANDING_PAGE_MODE);

  function generate(data) {
    if (data.id === SESSION_MODE) {
      setMode(SESSION_MODE);
    } else {
      setMode(PROJECT_MODE);
    }
  }

  return (
    <Page title="Judge Operation | Genius">
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
        {mode !== LANDING_PAGE_MODE && (
          <>
            <Typography variant="subtitle1">
              Judge Operation > Session >{' '}
              {mode === SESSION_MODE ? 'Session Report' : 'Project Report'}
            </Typography>
            <Button
              startIcon={<Icon icon="akar-icons:arrow-back-thick-fill" />}
              variant="outlined"
              onClick={() => setMode(LANDING_PAGE_MODE)}
            >
              Back
            </Button>
          </>
        )}
      </Box>
      {mode === LANDING_PAGE_MODE ? (
        <Grid container spacing={2}>
          {CARDS.map((item, index) => (
            <Grid item xs={12} md={6} lg={4} xl={3} key={index}>
              <LandingPageCard data={item} generate={generate} />
            </Grid>
          ))}
        </Grid>
      ) : mode === SESSION_MODE ? (
        <SessionReport />
      ) : (
        <ProjectReport />
      )}
    </Page>
  );
}

export default Index;
