const HOST = process.env.REACT_APP_REST_API_HOST;

const LOGIN = {
  method: 'POST',
  path: `${HOST}go_shield/auth/login`
};
const LOGIN_WITH_LINK = {
  method: 'POST',
  path: `${HOST}go_shield/auth/login_with_link`
};
const LOGIN_WITH_ANOTHER_USER = {
  method: 'POST',
  path: `${HOST}go_shield/auth/login_with_another_account`
};
const REFRESH_TOKEN = {
  method: 'POST',
  path: `${HOST}go_shield/auth/refresh_token`
};
const LOGOUT = {
  method: 'POST',
  path: `${HOST}go_shield/auth/logout`
};
const UPDATE_PASSWORD = {
  method: 'PUT',
  path: `${HOST}go_shield/auth/update_password`
};
const RESET_PASSWORD = {
  method: 'PUT',
  path: `${HOST}go_shield/auth/reset_password`
};

module.exports = {
  LOGIN,
  REFRESH_TOKEN,
  LOGOUT,
  UPDATE_PASSWORD,
  RESET_PASSWORD,
  LOGIN_WITH_LINK,
  LOGIN_WITH_ANOTHER_USER
};
