import { convertHourToSeconds } from '../../../../../utils/formatTime';

export default [
  {
    name: 'judge_id',
    label: 'Judge ID'
  },
  {
    name: 'judge_first_name',
    label: 'First Name'
  },
  {
    name: 'judge_last_name',
    label: 'Last Name'
  },
  {
    name: 'id',
    label: 'Project ID'
  },
  {
    name: 'title',
    label: 'Title'
  },
  {
    name: 'country',
    label: 'Country'
  },
  {
    name: 'school_name',
    label: 'School'
  },
  {
    name: 'discipline',
    label: 'Discipline'
  },
  {
    name: 'category',
    label: 'Category'
  },
  {
    name: 'project_student',
    label: 'Students'
  },
  {
    name: 'createdDate',
    label: 'Created At'
  },
  {
    name: 'submittedDate',
    label: 'Submitted At'
  },
  {
    name: 'modifiedData',
    label: 'Modified At'
  },
  {
    name: 'totalTime',
    label: 'Judging Time',
    options: {
      sortCompare: (order) => (obj1, obj2) => {
        const val1 = convertHourToSeconds(obj1?.data);
        const val2 = convertHourToSeconds(obj2?.data);
        return (val1 - val2) * (order === 'asc' ? 1 : -1);
      }
    }
  },
  {
    name: 'score',
    label: 'Score'
  },
  {
    name: 'balanceComp',
    label: 'Balance',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'balance_filter',
    label: 'Balance',
    options: {
      display: false
    }
  }
];
