import React, { useEffect, useState } from 'react';
import { DialogContent, Stack } from '@mui/material';
import { Icon } from '@iconify/react';
import { IconButton, MenuItem, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import headerVariablesForStateProjects from './utils/headerVariablesForStateProjects';
import MuiTable from '../../../../components/CustomComponents/MuiTable';
import { getReviewList, getStateProject } from '../serviceCalls';
import ProcessInfoBox from '../../../../components/CustomComponents/CustomCard/ProcessInfoBox';
import { getSummaryTableData } from './utils/dataMaker';
import SummaryTable from '../../../../components/CustomComponents/CustomCard/SummaryTable';
import CustomDialog from '../../../../components/CustomComponents/CustomDialog';
import ReviewDetail from './CountryProjects/ReviewDetail';

function StateProjects(props) {
  const { terms, active_term } = props;
  const [term, setTerm] = useState(active_term);
  const [stateProjectList, setStateProjectList] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [dialogShow, setDialogShow] = useState(false);
  const [reviewList, setReviewList] = useState([]);
  const [guidelines, setGuidelines] = useState([]);

  useEffect(() => {
    getStateProject(
      { term_id: term },
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        const _summaryData = getSummaryTableData(list.summary);
        const results = list.result_set;
        results.forEach((item) => {
          item.review_status_comp = (
            <IconButton color="secondary" onClick={() => handleChangeReviewStatus(item)}>
              <Icon icon="bx:bx-comment-detail" width={24} height={24} />
            </IconButton>
          );
        });
        setSummaryData(_summaryData);
        setStateProjectList(results);
      },
      () => {}
    );
  }, [term]);

  function handleChangeReviewStatus(state) {
    const param = {
      state_id: state.state_id
    };
    getReviewList(
      param,
      (data) => {
        setReviewList(data);
        setDialogShow(true);
      },
      () => {}
    );
  }

  function TermSelect() {
    return (
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 2 }}>
        <TextField select label="Terms" value={term} onChange={(e) => setTerm(e.target.value)}>
          {terms.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
    );
  }

  return (
    <Stack spacing={2}>
      {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
      <ProcessInfoBox Comp={<SummaryTable data={summaryData} />} title="Summary" />
      <MuiTable
        title={<TermSelect />}
        tableData={stateProjectList}
        tableColumns={headerVariablesForStateProjects}
      />
      <CustomDialog
        maxWidth="lg"
        fullWidth
        onClose={() => setDialogShow(false)}
        open={dialogShow}
        title="Review Details"
      >
        <DialogContent>
          <ReviewDetail handleClose={() => setDialogShow(false)} reviewList={reviewList} />
        </DialogContent>
      </CustomDialog>
    </Stack>
  );
}

StateProjects.propTypes = {
  terms: PropTypes.array,
  active_term: PropTypes.number
};

export default connect((store) => ({
  terms: store.loginReducer.terms,
  active_term: store.loginReducer.active_term
}))(StateProjects);
