import { fetchApi } from '../../../api/fetchApi';

const documentVariables = require('./apis');

export async function getDocumentList(config, scb, ecb) {
  try {
    const url = documentVariables.GET_DOCUMENT_LIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_DOCUMENT_LIST');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getDocument(config, scb, ecb) {
  try {
    const url = documentVariables.GET_DOCUMENT;
    await fetchApi(
      url,
      { document_id: config.id },
      (data) => {
        getFilesApi(data, config, scb, ecb);
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

const getFilesApi = (data, config, scb, ecb) => {
  const { url } = data;
  const requestOptions = {
    method: 'GET'
  };
  try {
    fetch(url, requestOptions)
      // eslint-disable-next-line consistent-return
      .then((response) => {
        if (response.status === 200) {
          return response.blob();
        }
        ecb(response.status);
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        scb();
        console.log(link);
        link.href = href;
        link.setAttribute('download', `${config.name}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  } catch (error) {
    console.log(error);
    ecb(error);
  }
};
