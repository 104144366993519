export default [
  {
    name: 'project_id',
    label: 'ID'
  },
  {
    name: 'project_title',
    label: 'Project'
  },
  {
    name: 'country',
    label: 'Country'
  },
  {
    name: 'team_name_comp',
    label: 'Team'
  },
  {
    name: 'contest_name_comp',
    label: 'Contest'
  },
  {
    name: 'contest_type_name_comp',
    label: 'Contest Type'
  },
  {
    name: 'rank_comp',
    label: 'Rank'
  },
  {
    name: 'num_solved_comp',
    label: 'Score'
  },
  {
    name: 'total_time_comp',
    label: 'Total Time'
  },
  {
    name: 'carbonComp',
    label: 'Carbon Score'
  },
  {
    name: 'review_status',
    label: 'Review St.'
  },
  {
    name: 'review_status_comp',
    label: 'Review St. Change',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'finalist_status',
    label: 'Finalist St.'
  },
  {
    name: 'finalist_status_comp',
    label: 'Finalist St. Change',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  }
];
