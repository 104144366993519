import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { IconButton, MenuItem, TextField } from '@material-ui/core';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import { Stack } from '@mui/material';
import MuiTable from '../../../../components/CustomComponents/MuiTable';
import headers from './utils/headerVariables';
import { getDRParticipant, patchDRParticipant } from '../serviceCalls';
import { setConfirmationAlert, setSnackbar } from '../../../../actions/alertActions';
import ProcessInfoBox from '../../../../components/CustomComponents/CustomCard/ProcessInfoBox';

function Index(props) {
  const { terms, active_term, dispatch } = props;
  const [tableData, setTableData] = useState([]);
  const [term, setTerm] = useState(active_term);
  const [guidelines, setGuidelines] = useState([]);

  useEffect(() => {
    fetchData();
  }, [term]);
  function fetchData() {
    getDRParticipant(
      { term_id: term },
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((item) => {
          item.name = `${item.first_name} ${item.last_name}`;
          item.date_of_birth_comp = moment(item.date_of_birth).format('YYYY-MM-DD');
          item.is_confirmed_comp = item.is_confirmed ? (
            <IconButton disabled className="Success-icon-color">
              <Icon icon="akar-icons:circle-check" width={24} height={24} />
            </IconButton>
          ) : (
            <IconButton disabled className="Error-icon-color">
              <Icon icon="akar-icons:circle-x" width={24} height={24} />
            </IconButton>
          );
          item.is_confirmed_filter = item.is_confirmed ? 'Yes' : 'No';
          item.is_attended_comp = item.is_attended ? (
            <IconButton disabled className="Success-icon-color">
              <Icon icon="akar-icons:circle-check" width={24} height={24} />
            </IconButton>
          ) : (
            <IconButton disabled className="Error-icon-color">
              <Icon color="error" icon="akar-icons:circle-x" width={24} height={24} />
            </IconButton>
          );
          item.is_attended_filter = item.is_attended ? 'Yes' : 'No';
          item.recover = (
            <IconButton color="secondary" onClick={() => handleRecoverParticipant(item.id)}>
              <Icon icon="ic:baseline-cached" />
            </IconButton>
          );
        });
        setTableData(list);
      },
      () => {}
    );
  }
  function handleRecoverParticipant(participant_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, RECOVER',
        'This action will recover participant data',
        () => {
          patchDRParticipant(
            { participant_id },
            () => {
              dispatch(setSnackbar('success', 'The participant data has been recovered'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
              fetchData();
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }
  function TermSelect() {
    return (
      <TextField select label="Terms" value={term} onChange={(e) => setTerm(e.target.value)}>
        {terms.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.description}
          </MenuItem>
        ))}
      </TextField>
    );
  }
  return (
    <Stack spacing={2}>
      {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
      <MuiTable tableData={tableData} tableColumns={headers} title={<TermSelect />} />
    </Stack>
  );
}

Index.propTypes = {
  terms: PropTypes.array,
  active_term: PropTypes.number,
  dispatch: PropTypes.func
};

export default connect((store) => ({
  terms: store.loginReducer.terms,
  active_term: store.loginReducer.active_term
}))(Index);
