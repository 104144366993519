import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import { FormHelperText, MenuItem, Stack, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { LoadingButton } from '@material-ui/lab';
import { setSnackbarForMain } from '../../actions/alertActions';
import {
  fairRegister,
  fetchCategoriesForRegister,
  getCountryList,
  getStateList
} from '../../actions/registerActions';
import CustomSnackbar from '../../components/CustomComponents/CustomSnackbar';
// ----------------------------------------------------------------------

const US_CODE = 244;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
const phoneRegExp = /^\+?(\d{10,13})$/gm;
const englishChar = /^[-a-zA-Z0-9& ]+$/;
const validUrlRegex =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;

function FairForm(props) {
  const { dispatch } = props;
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [disciplineList, setDisciplineList] = useState([]);
  const [selectedDisciplines, setSelectedDisciplines] = useState([]);
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [disciplineError, setDisciplineError] = useState(false);
  const [schema, setSchema] = useState(
    Yup.object().shape({
      name: Yup.string()
        .min(2, 'Too Short!')
        .max(500, 'Too Long!')
        .matches(englishChar, 'Please enter only English characters')
        .required('Fair name is required'),
      email: Yup.string()
        .max(500, 'Too Long!')
        .email('Email must be a valid email address')
        .required('Fair Email is required'),
      main_contact_first_name: Yup.string()
        .min(2, 'Too Short!')
        .max(500, 'Too Long!')
        .matches(englishChar, 'Please enter only English characters')
        .required('First name is required'),
      main_contact_last_name: Yup.string()
        .min(2, 'Too Short!')
        .max(500, 'Too Long!')
        .matches(englishChar, 'Please enter only English characters')
        .required('Last name is required'),
      phone_number: Yup.string()
        .max(500, 'Too Long!')
        .matches(phoneRegExp, 'Phone number must be in the following format,+15554443322')
        .required('Phone number is required'),
      size: Yup.number().required('Number of projects at your fair is required'),
      zip: Yup.string().max(500, 'Too Long!').required('Zip is required'),
      country_id: Yup.string().required('Country is required'),
      city: Yup.string().max(500, 'Too Long!').required('City is required'),
      street: Yup.string().max(500, 'Too Long!').required('Street is required'),
      website: Yup.string()
        .max(500, 'Too Long!')
        .matches(validUrlRegex, 'Website must be a valid url')
        .nullable(),
      state_id: Yup.string().nullable(),
      start_year: Yup.number()
        .min(1900, 'Please enter at least 1900 year.')
        .max(2099, 'Please enter the year 2099 at most.')
        .required('Start year is required')
    })
  );

  const navigate = useNavigate();
  useEffect(() => {
    getCountryList(
      {},
      (data) => {
        setCountryList(data);
      },
      () => {}
    );
    getStateList(
      {},
      (data) => {
        setStateList(data);
      },
      () => {}
    );
    fetchCategoriesForRegister(
      {},
      (data) => {
        setDisciplineList(data);
      },
      () => {}
    );
  }, []);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      main_contact_first_name: '',
      main_contact_last_name: '',
      zip: '',
      country_id: '',
      state_id: '',
      city: '',
      street: '',
      unit_number: '',
      start_year: '',
      phone_number: '',
      size: '',
      website: null
    },
    validationSchema: schema,
    onSubmit: (values, { setSubmitting }) => {
      const isWebSend = !!values.website;
      const obj = {
        ...values,
        disciplines: selectedDisciplines,
        main_contact_first_name: values.main_contact_first_name.toUpperCase(),
        main_contact_last_name: values.main_contact_last_name.toUpperCase(),
        name: values.name.toUpperCase()
      };
      const { website, ...param2 } = obj;
      console.log(website);
      if (selectedDisciplines.length === 0) {
        setSubmitting(false);
        setDisciplineError(true);
      } else {
        fairRegister(
          isWebSend ? obj : param2,
          () => {
            setSubmitting(false);
            navigate('/', { replace: true });
            dispatch(
              setSnackbarForMain(
                'info',
                'Your Fair affiliation registration needs approval from GENIUS Admins. Please look for an email from GENIUS admins regarding approval of your registration and password to login to your account.'
              )
            );
          },
          (error) => {
            if (error.is_visible) {
              setIsError(true);
              setErrorText(error.go_error_message);
            }
            setSubmitting(false);
          }
        );
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values, setFieldValue } =
    formik;

  useEffect(() => {
    if (values.country_id !== US_CODE) {
      setFieldValue('state_id', null);
    }
    setSchema(
      Yup.object().shape({
        name: Yup.string()
          .min(2, 'Too Short!')
          .max(500, 'Too Long!')
          .matches(englishChar, 'Please enter only English characters')
          .required('Fair name is required'),
        email: Yup.string()
          .max(500, 'Too Long!')
          .email('Email must be a valid email address')
          .required('Fair Email is required'),
        main_contact_first_name: Yup.string()
          .min(2, 'Too Short!')
          .max(500, 'Too Long!')
          .matches(englishChar, 'Please enter only English characters')
          .required('First name is required'),
        main_contact_last_name: Yup.string()
          .min(2, 'Too Short!')
          .max(500, 'Too Long!')
          .matches(englishChar, 'Please enter only English characters')
          .required('Last name is required'),
        phone_number: Yup.string()
          .max(500, 'Too Long!')
          .matches(phoneRegExp, 'Phone number must be in the following format,+15554443322')
          .required('Phone number is required'),
        size: Yup.number().required('Number of projects at your fair is required'),
        zip: Yup.string().max(500, 'Too Long!').required('Zip is required'),
        country_id: Yup.string().required('Country is required'),
        city: Yup.string().max(500, 'Too Long!').required('City is required'),
        street: Yup.string().max(500, 'Too Long!').required('Street is required'),
        website: Yup.string()
          .max(500, 'Too Long!')
          .matches(validUrlRegex, 'Website must be a valid url')
          .nullable(),
        state_id:
          values.country_id === US_CODE
            ? Yup.string().required('State is required').nullable()
            : Yup.string().nullable(),
        start_year: Yup.number()
          .min(1900, 'Please enter at least 1900 year.')
          .max(2099, 'Please enter the year 2099 at most.')
          .required('Start year is required')
      })
    );
  }, [values.country_id]);

  useEffect(() => {
    if (selectedDisciplines.length !== 0) {
      setDisciplineError(false);
    }
  }, [selectedDisciplines]);

  const handleChange = (event) => {
    const {
      target: { value }
    } = event;
    setSelectedDisciplines(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Fair Name"
              inputProps={{ style: { textTransform: 'uppercase' } }}
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Fair Email"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Phone Number"
              {...getFieldProps('phone_number')}
              error={Boolean(touched.phone_number && errors.phone_number)}
              helperText={touched.phone_number && errors.phone_number}
            />
            <TextField
              fullWidth
              label="Web Site"
              {...getFieldProps('website')}
              error={Boolean(touched.website && errors.website)}
              helperText={touched.website && errors.website}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              type="number"
              label="Start Year"
              {...getFieldProps('start_year')}
              error={Boolean(touched.start_year && errors.start_year)}
              helperText={touched.start_year && errors.start_year}
            />
            <TextField
              fullWidth
              type="number"
              label="Number of Projects at Your Fair"
              {...getFieldProps('size')}
              error={Boolean(touched.size && errors.size)}
              helperText={touched.size && errors.size}
            />
          </Stack>
          <FormControl>
            <InputLabel id="demo-multiple-checkbox-label">Discipline</InputLabel>
            <Select
              fullWidth
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={selectedDisciplines}
              onChange={handleChange}
              input={<OutlinedInput label="Discipline" />}
              renderValue={(selected) => {
                const strList = [];
                selected.forEach((item) => {
                  const el = disciplineList.find((x) => x.id === item);
                  // eslint-disable-next-line no-unused-expressions
                  el && strList.push(el.name);
                });
                return strList.join(', ');
              }}
              MenuProps={MenuProps}
            >
              {disciplineList.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  <Checkbox checked={selectedDisciplines.indexOf(item.id) > -1} />
                  <ListItemText primary={item.name} />
                </MenuItem>
              ))}
            </Select>
            {disciplineError && (
              <FormHelperText error id="my-helper-text">
                Please add discipline(s).
              </FormHelperText>
            )}
          </FormControl>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              inputProps={{ style: { textTransform: 'uppercase' } }}
              fullWidth
              label="Main Contact First Name"
              {...getFieldProps('main_contact_first_name')}
              error={Boolean(touched.main_contact_first_name && errors.main_contact_first_name)}
              helperText={touched.main_contact_first_name && errors.main_contact_first_name}
            />
            <TextField
              inputProps={{ style: { textTransform: 'uppercase' } }}
              fullWidth
              label="Main Contact Last Name"
              {...getFieldProps('main_contact_last_name')}
              error={Boolean(touched.main_contact_last_name && errors.main_contact_last_name)}
              helperText={touched.main_contact_last_name && errors.main_contact_last_name}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Country"
              select
              {...getFieldProps('country_id')}
              error={Boolean(touched.country_id && errors.country_id)}
              helperText={touched.country_id && errors.country_id}
            >
              {countryList.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
            {values.country_id === US_CODE && (
              <TextField
                fullWidth
                label="State"
                select
                {...getFieldProps('state_id')}
                error={Boolean(touched.state_id && errors.state_id)}
                helperText={touched.state_id && errors.state_id}
              >
                {stateList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Street"
              {...getFieldProps('street')}
              error={Boolean(touched.street && errors.street)}
              helperText={touched.street && errors.street}
            />
            <TextField
              fullWidth
              label="Unit Number"
              {...getFieldProps('unit_number')}
              error={Boolean(touched.unit_number && errors.unit_number)}
              helperText={touched.unit_number && errors.unit_number}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="City"
              {...getFieldProps('city')}
              error={Boolean(touched.city && errors.city)}
              helperText={touched.city && errors.city}
            />
            <TextField
              fullWidth
              label="Zip Code"
              {...getFieldProps('zip')}
              error={Boolean(touched.zip && errors.zip)}
              helperText={touched.zip && errors.zip}
            />
          </Stack>
          <LoadingButton
            loading={isSubmitting}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            Register
          </LoadingButton>
          {isError && (
            <CustomSnackbar
              settings={{ type: 'error', message: errorText, onHide: () => setIsError(false) }}
            />
          )}
        </Stack>
      </Form>
    </FormikProvider>
  );
}

FairForm.propTypes = {
  dispatch: PropTypes.func.isRequired
};

export default connect(() => ({}))(FairForm);
