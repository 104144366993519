import { Button, DialogContent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { MenuItem, Stack, TextField } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setLoading, setSnackbar } from '../../../../../../actions/alertActions';
import CustomDialog from '../../../../../../components/CustomComponents/CustomDialog';
import { ADD_MODE, EDIT_MODE } from '../../../../../../utils/generalConstants';
import { addSession, updateSession, getSessionType } from '../../../serviceCalls';
import { getDisciplineList } from '../../../../_apis/adminApi';
import { getCurrentDateTime, getDateTime } from '../../../../../../utils/formatTime';

function AddUpdateSessionDialog(props) {
  const { data, mode, handleClose, dispatch, refreshData, terms } = props;
  const [disciplineList, setDisciplineList] = useState([]);
  const [typeList, setTypeList] = useState([]);

  useEffect(() => {
    getDisciplineList(
      {},
      (data) => {
        setDisciplineList(data);
      },
      () => {}
    );
    getSessionType(
      {},
      (data) => {
        setTypeList(data);
      },
      () => {}
    );
  }, []);

  const Schema = Yup.object().shape({
    term_id: Yup.number().required('Term is required'),
    discipline_id: Yup.number().required('Discipline is required'),
    name: Yup.string()
      .min(2, 'Too Short!')
      .max(500, 'Too Long!')
      .matches('^[\x20-\x7F]+$', 'Please enter only English characters!')
      .required('Category name required'),
    capacity: Yup.number().min(0, 'Min 0 capacity').required('Capacity is required'),
    location: Yup.string()
      .min(2, 'Too Short!')
      .max(500, 'Too Long!')
      .matches('^[\x20-\x7F]+$', 'Please enter only English characters!')
      .required('Category name required'),
    start_datetime: Yup.date().required('Start Date Time is required'),
    end_datetime: Yup.date().required('Start Date Time is required'),
    judging_session_type_id: Yup.number().required('Type is required')
  });
  const formik = useFormik({
    initialValues: {
      term_id: mode === EDIT_MODE ? data.term_id : '',
      discipline_id: mode === EDIT_MODE ? data.discipline_id : '',
      capacity: mode === EDIT_MODE ? data.capacity : '',
      name: mode === EDIT_MODE ? data.name : '',
      location: mode === EDIT_MODE ? data.location : '',
      start_datetime: mode === EDIT_MODE ? getDateTime(data.start_datetime) : getCurrentDateTime(),
      end_datetime: mode === EDIT_MODE ? getDateTime(data.end_datetime) : getCurrentDateTime(),
      judging_session_type_id: mode === EDIT_MODE ? data.judging_session_type_id : ''
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      dispatch(setLoading(1, 'Loading...'));
      if (mode === ADD_MODE) {
        addSession(
          values,
          () => {
            dispatch(setLoading(0, ''));
            refreshData();
            dispatch(setSnackbar('success', 'The session has been added'));
          },
          () => {
            dispatch(setLoading(0, ''));
          }
        );
      } else {
        const updateParams = { ...values, judging_session_id: data.id };
        updateSession(
          updateParams,
          () => {
            dispatch(setLoading(0, ''));
            refreshData();
            dispatch(setSnackbar('success', 'The session has been updated'));
          },
          () => {
            dispatch(setLoading(0, ''));
          }
        );
      }
    }
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <CustomDialog
      onClose={handleClose}
      open
      maxWidth="md"
      title={mode === ADD_MODE ? 'Add Session' : 'Update Session'}
      fullWidth
    >
      <DialogContent>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 1 }}>
                <TextField
                  fullWidth
                  label="Session Type"
                  select
                  {...getFieldProps('judging_session_type_id')}
                  error={Boolean(touched.judging_session_type_id && errors.judging_session_type_id)}
                  helperText={touched.judging_session_type_id && errors.judging_session_type_id}
                >
                  {typeList.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  fullWidth
                  label="Related Discipline"
                  select
                  {...getFieldProps('discipline_id')}
                  error={Boolean(touched.discipline_id && errors.discipline_id)}
                  helperText={touched.discipline_id && errors.discipline_id}
                >
                  {disciplineList.map((discipline) => (
                    <MenuItem key={discipline.id} value={discipline.id}>
                      {discipline.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  fullWidth
                  label="Terms"
                  select
                  {...getFieldProps('term_id')}
                  error={Boolean(touched.term_id && errors.term_id)}
                  helperText={touched.term_id && errors.term_id}
                >
                  {terms.map((term) => (
                    <MenuItem key={term.id} value={term.id}>
                      {term.description}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  fullWidth
                  type="number"
                  label="Capacity"
                  {...getFieldProps('capacity')}
                  error={Boolean(touched.capacity && errors.capacity)}
                  helperText={touched.capacity && errors.capacity}
                />
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  fullWidth
                  label="Name"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
                <TextField
                  fullWidth
                  label="Location"
                  {...getFieldProps('location')}
                  error={Boolean(touched.location && errors.location)}
                  helperText={touched.location && errors.location}
                />
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  fullWidth
                  id="datetime-local1"
                  label="Start Date Time"
                  type="datetime-local"
                  defaultValue="2017-05-24T10:30"
                  InputLabelProps={{
                    shrink: true
                  }}
                  {...getFieldProps('start_datetime')}
                  error={Boolean(touched.start_datetime && errors.start_datetime)}
                  helperText={touched.start_datetime && errors.start_datetime}
                />
                <TextField
                  fullWidth
                  id="datetime-local1"
                  label="End Date Time"
                  type="datetime-local"
                  InputLabelProps={{
                    shrink: true
                  }}
                  {...getFieldProps('end_datetime')}
                  error={Boolean(touched.end_datetime && errors.end_datetime)}
                  helperText={touched.end_datetime && errors.end_datetime}
                />
              </Stack>
              <Stack direction="row" justifyContent="end" spacing={1}>
                <LoadingButton size="small" type="submit" variant="outlined">
                  {mode === ADD_MODE ? 'Add' : 'Update'}
                </LoadingButton>
                <Button size="small" color="error" onClick={handleClose} variant="outlined">
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </Form>
        </FormikProvider>
      </DialogContent>
    </CustomDialog>
  );
}

AddUpdateSessionDialog.propTypes = {
  data: PropTypes.object,
  mode: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  terms: PropTypes.array
};

export default connect((store) => ({
  terms: store.loginReducer.terms
}))(AddUpdateSessionDialog);
