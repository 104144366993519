import { Grid, Typography } from '@material-ui/core';
import { Card } from '@mui/material';
import React, { useState } from 'react';
import { styled } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CustomCard from './CustomCard';
import { LoginForm } from '../../components/authentication/login';
import { MHidden } from '../../components/@material-extend';
import HelpDialog from './HelpDialog';

const SectionStyle = styled(Card)(() => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: 'inherit'
}));

function MainCards(props) {
  const { items } = props;
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);

  return (
    <>
      <MHidden width="mdUp">
        <Grid container style={{ width: '80%', marginTop: 150, marginBottom: 70 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card style={{ padding: 10, height: '100%' }}>
                <Typography
                  variant="subtitle2"
                  style={{
                    paddingRight: 10,
                    paddingLeft: 10,
                    textAlign: 'center',
                    marginBottom: 10
                  }}
                >
                  If you don't have an account, please register with one of the options below.
                </Typography>
                <LoginForm />
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            {items.map((product) => (
              <Grid key={product.id} item xs={12}>
                <CustomCard product={product} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </MHidden>
      <MHidden width="mdDown">
        <Grid container style={{ width: '50%', marginTop: 150, marginBottom: 70 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Card style={{ padding: 10, height: '100%' }}>
                <Typography
                  variant="h6"
                  style={{
                    paddingRight: 10,
                    paddingLeft: 10,
                    textAlign: 'center',
                    marginBottom: 10
                  }}
                >
                  If you don't have an account, please register with one of the options below.
                </Typography>
                <LoginForm />
              </Card>
            </Grid>
            <MHidden width="smDown">
              <Grid item xs={12} sm={6} md={4} style={{ height: '100%' }}>
                <SectionStyle>
                  <Typography variant="h5" sx={{ px: 5, mb: 5 }}>
                    Hi, Welcome Back
                  </Typography>
                  <img src="/static/illustrations/illustration_login.png" alt="login" />
                  <div style={{ display: 'flex', justifyContent: 'end', marginRight: 10 }}>
                    <Typography
                      variant="h6"
                      style={{ cursor: 'pointer', color: '#00AB55', fontWeight: 'bold' }}
                      onClick={() => setHelpDialogOpen(true)}
                    >
                      Need Help?
                    </Typography>
                  </div>
                </SectionStyle>
              </Grid>
            </MHidden>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            {items.map((product) => (
              <Grid key={product.id} item xs={12} md={4}>
                <CustomCard product={product} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </MHidden>
      {helpDialogOpen && <HelpDialog closeDialog={() => setHelpDialogOpen(false)} />}
    </>
  );
}

MainCards.propTypes = {
  items: PropTypes.array
};

export default MainCards;
