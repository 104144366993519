import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TextField, Stack, MenuItem } from '@material-ui/core';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { Button } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { addRole, copyRole } from '../../serviceCalls';
import { setSnackbar } from '../../../../../actions/alertActions';

const AddRoleDialog = (props) => {
  const { handleClose, rolesList, refreshRoleList, dispatch } = props;
  const [copyFromSwitch, setCopyFromSwitch] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const Schema = Yup.object().shape({
    role_name: Yup.string()
      .min(2, 'Too Short!')
      .max(500, 'Too Long!')
      .matches('^[\x20-\x7F]+$', 'Please enter only English characters!')
      .required('First name required'),
    role_access_key: Yup.string()
      .min(2, 'Too Short!')
      .max(500, 'Too Long!')
      .matches('^[\x20-\x7F]+$', 'Please enter only English characters!')
      .required('Last name required'),
    role_id: Yup.number()
  });
  const formik = useFormik({
    initialValues: {
      role_name: '',
      role_access_key: '',
      role_id: ''
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      if (copyFromSwitch) {
        copyRole(
          { ...values, role_is_admin: isAdmin ? 1 : 0 },
          () => {
            dispatch(setSnackbar('success', 'New role copied!'));
            refreshRoleList();
          },
          () => {}
        );
      } else {
        const { role_id, ...param } = values;
        console.log(role_id);
        addRole(
          { ...param, role_is_admin: isAdmin ? 1 : 0 },
          () => {
            dispatch(setSnackbar('success', 'New role added!'));
            refreshRoleList();
          },
          () => {}
        );
      }
    }
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;
  const addButtonText = copyFromSwitch ? 'Copy & Add' : 'Add Role';

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit} spacing={2}>
        <Stack spacing={3} sx={{ mt: 1 }}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Role name"
              {...getFieldProps('role_name')}
              error={Boolean(touched.role_name && errors.role_name)}
              helperText={touched.role_name && errors.role_name}
            />
            <TextField
              fullWidth
              label="Role Access Key"
              {...getFieldProps('role_access_key')}
              error={Boolean(touched.role_access_key && errors.role_access_key)}
              helperText={touched.role_access_key && errors.role_access_key}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={copyFromSwitch}
                  onChange={() => setCopyFromSwitch(!copyFromSwitch)}
                />
              }
              label="Copy from"
            />
            <FormControlLabel
              control={<Switch checked={isAdmin} onChange={() => setIsAdmin(!isAdmin)} />}
              label="Is Admin"
            />
          </Stack>
          {copyFromSwitch && (
            <Stack>
              <TextField
                fullWidth
                select
                label="Copy Features From"
                {...getFieldProps('role_id')}
                error={Boolean(touched.role_id && errors.role_id)}
                helperText={touched.role_id && errors.role_id}
              >
                {rolesList.length > 0 &&
                  rolesList.map((option) => (
                    <MenuItem key={option.role_id} value={option.role_id}>
                      {option.role_name}
                    </MenuItem>
                  ))}
              </TextField>
            </Stack>
          )}
          <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="end" spacing={2}>
            <Button type="submit" variant="outlined">
              {addButtonText}
            </Button>
            <Button variant="outlined" color="error" onClick={handleClose}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
};

AddRoleDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  rolesList: PropTypes.array.isRequired,
  refreshRoleList: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired
};

export default connect(() => ({}))(AddRoleDialog);
