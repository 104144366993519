import * as React from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

function QuestionViewDetail(props) {
  const { question } = props;

  return (
    <Card className="drag-drop-inside-card-style">
      <CardHeader
        title={question.label}
        subheader={<p style={{ fontWeight: 'bold' }}>{`Out of ${question?.scale}`}</p>}
        style={{ marginBottom: 10 }}
        avatar={
          <Avatar className="drag-drop-current-rank-style" aria-label="recipe">
            {question.rank}
          </Avatar>
        }
      />
      <CardContent>
        <div>{ReactHtmlParser(question?.markdown)}</div>
      </CardContent>
    </Card>
  );
}

QuestionViewDetail.propTypes = {
  question: PropTypes.object
};

export default QuestionViewDetail;
