import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Stack, TextField } from '@material-ui/core';
import * as React from 'react';
import { Button } from '@mui/material';
import Typography from '@material-ui/core/Typography';
import { useState, useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { setSnackbar } from '../../../../../actions/alertActions';
import { updateFairOperation } from '../../serviceCalls';

const SELECTION_MODE = 0;
const APPROVE_MODE = 1;

function AddPaymentDialog(props) {
  const { data, dispatch, handleRefresh } = props;
  const [mode, setMode] = useState(SELECTION_MODE);
  const [disciplineParam, setDisciplineParam] = useState([]);

  useEffect(() => {
    const arr = [];
    data.fair_discipline_capacity.forEach((item) => {
      const obj = {
        discipline_id: item.discipline_id,
        capacity: item.capacity,
        discipline_name: item.discipline
      };
      arr.push(obj);
    });
    setDisciplineParam(arr);
  }, []);

  function handleChange(event) {
    const { name, value } = event.target;
    const newArr = [];
    disciplineParam.forEach((item) => {
      const obj =
        Number(item.discipline_id) === Number(name)
          ? {
              discipline_id: name,
              capacity: value,
              discipline_name: item.discipline_name
            }
          : item;
      newArr.push(obj);
    });
    setDisciplineParam(newArr);
  }
  function handleApprove() {
    const param = {
      fair_id: data.id,
      fair_approval_status_id: 1,
      disciplines: disciplineParam
    };
    updateFairOperation(
      param,
      () => {
        handleRefresh();
        dispatch(setSnackbar('success', 'Fair has been approved'));
      },
      () => {}
    );
  }
  const handleChangeMode = (event) => {
    setMode(event.target.value);
  };
  function handleDeny() {
    const newDisParam = [];
    disciplineParam.forEach((item) => {
      const obj = {
        discipline_id: item.discipline_id,
        capacity: 0
      };
      newDisParam.push(obj);
    });
    const param = {
      fair_id: data.id,
      fair_approval_status_id: 2,
      disciplines: newDisParam
    };
    updateFairOperation(
      param,
      () => {
        handleRefresh();
        dispatch(setSnackbar('success', 'Fair has been denied'));
      },
      () => {}
    );
  }

  return mode === SELECTION_MODE ? (
    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
      <InputLabel id="demo-simple-select-standard-label">Approve/Deny</InputLabel>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={mode}
        onChange={handleChangeMode}
        label="Select"
      >
        <MenuItem value={1}>Approve</MenuItem>
        <MenuItem value={2}>Deny</MenuItem>
      </Select>
    </FormControl>
  ) : mode === APPROVE_MODE ? (
    <Stack spacing={3} sx={{ mt: 1 }}>
      {disciplineParam.map((item, index) => (
        <Stack
          key={index}
          direction="row"
          justifyContent="space-between"
          spacing={1}
          style={{ alignItems: 'baseline' }}
        >
          <Typography gutterBottom align="center" variant="subtitle1">
            {item.discipline_name}
          </Typography>
          <TextField
            InputProps={{ shrink: false }}
            fullWidth
            type="number"
            label="Capacity"
            style={{ maxWidth: 100 }}
            name={item.discipline_id}
            value={item.capacity}
            onChange={(event) => {
              handleChange(event);
            }}
          />
        </Stack>
      ))}
      <Stack direction="row" justifyContent="end" spacing={1}>
        <Button size="small" onClick={handleApprove} variant="outlined">
          Approve
        </Button>
        <Button
          size="small"
          color="error"
          onClick={() => setMode(SELECTION_MODE)}
          variant="outlined"
        >
          Back
        </Button>
      </Stack>
    </Stack>
  ) : (
    <Stack spacing={3} sx={{ mt: 1 }}>
      {disciplineParam.map((item, index) => (
        <Stack
          key={index}
          direction="row"
          justifyContent="space-between"
          spacing={1}
          style={{ alignItems: 'baseline' }}
        >
          <Typography gutterBottom align="center" variant="subtitle1">
            {item.discipline_name}
          </Typography>
          <TextField
            disabled
            value="0"
            fullWidth
            type="number"
            style={{ maxWidth: 100 }}
            name={item}
          />
        </Stack>
      ))}
      <Stack direction="row" justifyContent="end" spacing={1}>
        <Button size="small" onClick={handleDeny} variant="outlined">
          Deny
        </Button>
        <Button
          size="small"
          color="error"
          onClick={() => setMode(SELECTION_MODE)}
          variant="outlined"
        >
          Back
        </Button>
      </Stack>
    </Stack>
  );
}

AddPaymentDialog.propTypes = {
  data: PropTypes.array.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired
};

export default connect(() => ({}))(AddPaymentDialog);
