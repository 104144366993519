import { Button, Stack } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import QuestionDetail from './QuestionDetail';
import { sendSurveyResult } from '../_apis/serviceCalls';

function Index(props) {
  const { surveyData, setIsSuccess } = props;
  const questionList = surveyData.questions;
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [answerList, setAnswerList] = useState(
    questionList.map(() => ({ score: '', comment: '' }))
  );
  questionList.sort((a, b) => a.rank - b.rank);

  function handleSubmit() {
    const survey_results = [];
    answerList.forEach((answer, index) => {
      if (answer.score) {
        survey_results.push({
          survey_question_id: questionList[index].id,
          score: answer.score,
          comment: answer.comment
        });
      }
    });
    const param = {
      survey_id: surveyData.survey_id,
      survey_code: surveyData.survey_code,
      survey_results
    };
    sendSurveyResult(
      param,
      () => {
        setIsSuccess(true);
      },
      () => {}
    );
  }

  function answerChange(key, score) {
    const updatedList = answerList;
    updatedList[key].score = score;
    setAnswerList(updatedList);
    setButtonDisabled(false);
  }

  function commentChange(key, comment) {
    const updatedList = answerList;
    updatedList[key].comment = comment;
    setAnswerList(updatedList);
  }

  return (
    <Stack spacing={2} style={{ marginTop: 50 }}>
      <Typography variant="h1" gutterBottom>
        {surveyData.survey_name}
      </Typography>
      {questionList.map((question, index) => (
        <QuestionDetail
          question={question}
          key={index}
          answerChange={answerChange}
          commentChange={commentChange}
        />
      ))}
      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="end" spacing={2}>
        <Button variant="outlined" onClick={handleSubmit} disabled={buttonDisabled}>
          Submit
        </Button>
      </Stack>
    </Stack>
  );
}

Index.propTypes = {
  surveyData: PropTypes.object.isRequired,
  setIsSuccess: PropTypes.func
};

export default connect(() => ({}))(Index);
