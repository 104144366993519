import * as React from 'react';
import { Button, Stack } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { setSnackbar } from '../../../../actions/alertActions';
import { addUser, updateUser } from '../serviceCalls';

const ADD_MODE = 1;
const UPDATE_MODE = 2;

function AddTravelDialog(props) {
  const { editData, mode, handleClose, handleRefresh, dispatch } = props;
  const [showPassword, setShowPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(2, 'Too Short!')
      .max(500, 'Too Long!')
      .required('First name required'),
    last_name: Yup.string()
      .min(2, 'Too Short!')
      .max(500, 'Too Long!')
      .required('Last name required'),
    email: Yup.string()
      .max(500, 'Too Long!')
      .email('Email must be a valid email address')
      .required('Email is required'),
    password: Yup.string()
      .min(6, 'Password should be of minimum 6 characters length!')
      .required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      first_name: mode === UPDATE_MODE ? editData.first_name : '',
      last_name: mode === UPDATE_MODE ? editData.last_name : '',
      email: mode === UPDATE_MODE ? editData.email : '',
      password: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      if (mode === ADD_MODE) {
        addUser(
          values,
          () => {
            dispatch(setSnackbar('success', 'User added'));
            handleRefresh();
          },
          () => {}
        );
      } else {
        updateUser(
          { ...values, user_id: editData.user_id },
          () => {
            dispatch(setSnackbar('success', 'User updated'));
            handleRefresh();
          },
          () => {}
        );
      }
    }
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{ mt: 1 }}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="First name"
              {...getFieldProps('first_name')}
              error={Boolean(touched.first_name && errors.first_name)}
              helperText={touched.first_name && errors.first_name}
            />

            <TextField
              fullWidth
              label="Last name"
              {...getFieldProps('last_name')}
              error={Boolean(touched.last_name && errors.last_name)}
              helperText={touched.last_name && errors.last_name}
            />
          </Stack>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="end" spacing={2}>
            <Button type="submit" variant="outlined">
              {mode === ADD_MODE ? 'Add' : 'Update'}
            </Button>
            <Button variant="outlined" color="error" onClick={handleClose}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

AddTravelDialog.propTypes = {
  handleClose: PropTypes.func,
  handleRefresh: PropTypes.func,
  dispatch: PropTypes.func,
  editData: PropTypes.object,
  mode: PropTypes.number
};

export default connect(() => ({}))(AddTravelDialog);
