export default [
  {
    name: 'flag_show_id',
    label: 'ID'
  },
  {
    name: 'country',
    label: 'Country'
  },
  {
    name: 'school_name',
    label: 'School'
  },
  {
    name: 'student_name',
    label: 'Student'
  },
  {
    name: 'user_name',
    label: 'User Name'
  },
  {
    name: 'user_email',
    label: 'User Email'
  },
  {
    name: 'edit',
    label: 'Update',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  }
];
