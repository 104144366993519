export function getApplicantRoutes(allModules) {
  const isPerformance = allModules.findIndex((x) => x.module_access_key === 'performance');
  const isParticipation = allModules.findIndex((x) => x.module_access_key === 'participation');
  const isApplicantPayment = allModules.findIndex((x) => x.module_access_key === 'payments');
  const isHome = allModules.findIndex((x) => x.module_access_key === 'home');
  const isDocumentForApplicant = allModules.findIndex((x) => x.module_access_key === 'document');
  const isParticipantFeature =
    isParticipation !== -1
      ? allModules[isParticipation].features.findIndex(
          (x) => x.feature_access_key === 'participant'
        )
      : -1;
  const isInvitationLetterFeature =
    isParticipation !== -1
      ? allModules[isParticipation].features.findIndex(
          (x) => x.feature_access_key === 'invitation_letter'
        )
      : -1;
  const isVipFeature =
    isParticipation !== -1
      ? allModules[isParticipation].features.findIndex((x) => x.feature_access_key === 'vip')
      : -1;
  const isProject = allModules.findIndex((x) => x.module_access_key === 'project');
  const isSchool = allModules.findIndex((x) => x.module_access_key === 'school');
  const isTravel = allModules.findIndex((x) => x.module_access_key === 'travel');
  const isTrip = allModules.findIndex((x) => x.module_access_key === 'trip');
  const isPresentation = allModules.findIndex((x) => x.module_access_key === 'presentation');
  const isApplicantJudging = allModules.findIndex((x) => x.module_access_key === 'judging_session');
  const isProfile = allModules.findIndex((x) => x.module_access_key === 'profile');
  return {
    isPerformance,
    isParticipation,
    isHome,
    isDocumentForApplicant,
    isParticipantFeature,
    isInvitationLetterFeature,
    isVipFeature,
    isProject,
    isSchool,
    isTravel,
    isTrip,
    isPresentation,
    isProfile,
    isApplicantJudging,
    isApplicantPayment
  };
}
