import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, DialogContent, Stack } from '@mui/material';
import { Checkbox } from '@material-ui/core';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiTable from '../../../../../../components/CustomComponents/MuiTable';
import headers from '../_utils/headerVariablesForSession';
import { setSnackbar } from '../../../../../../actions/alertActions';
import { updateSessionInSession } from '../../../serviceCalls';
import CustomDialog from '../../../../../../components/CustomComponents/CustomDialog';

const SCIENCE = 1;

function UpdateProjectDialog(props) {
  const { data, handleClose, project, handleSuccess, dispatch } = props;
  const [selected, setSelected] = useState([]);
  const [electric, setElectric] = useState(false);
  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelected(rowsSelected);
  };

  function handleAdd() {
    const param = {
      project_id: project.project_id,
      judging_session_id: data[selected].id,
      electricity_needed: electric ? 1 : 0
    };
    updateSessionInSession(
      param,
      () => {
        dispatch(setSnackbar('success', 'You have successfully selected your judging session!'));
        handleSuccess();
      },
      () => {}
    );
  }

  return (
    <CustomDialog fullWidth maxWidth="lg" onClose={handleClose} title="Add Session">
      <DialogContent>
        <Stack spacing={3}>
          <MuiTable
            tableData={data}
            tableColumns={headers}
            customOption={{
              selectableRows: 'single',
              onRowSelectionChange,
              rowsSelected: selected
            }}
          />
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="end">
            {project.discipline_id === SCIENCE && (
              <FormControlLabel
                control={
                  <Checkbox checked={electric} onChange={(e) => setElectric(e.target.checked)} />
                }
                label="I need electricity when presenting my project. "
              />
            )}
            <>
              {selected.length > 0 && (
                <Button variant="outlined" onClick={handleAdd}>
                  Add
                </Button>
              )}
              <Button variant="outlined" color="error" onClick={handleClose}>
                Cancel
              </Button>
            </>
          </Stack>
        </Stack>
      </DialogContent>
    </CustomDialog>
  );
}

UpdateProjectDialog.propTypes = {
  data: PropTypes.array,
  handleClose: PropTypes.func,
  project: PropTypes.number,
  handleSuccess: PropTypes.func,
  dispatch: PropTypes.func
};

export default connect(() => ({}))(UpdateProjectDialog);
