import { fetchApi } from '../../../api/fetchApi';

const judge_operation = require('../../../api/RestVariables/Admin/judge_operation');

export async function getSessionList(config, scb, ecb) {
  try {
    const url = judge_operation.GET_SESSION_LIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_SESSION_LIST');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getSession(config, scb, ecb) {
  try {
    const url = judge_operation.GET_SESSION;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_SESSION');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addCertificateForJudge(config, scb, ecb) {
  try {
    const url = judge_operation.ADD_CERTIFICATE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_CERTIFICATE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function downloadCertificate(config, scb, ecb) {
  try {
    const url = judge_operation.DOWNLOAD_CERTIFICATE;
    await fetchApi(
      url,
      config,
      (data) => {
        getFilesApi(data, config, scb, ecb);
        console.log(data, 'DOWNLOAD_PARTICIPANT_OP_INVITATION_LETTER');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}
const getFilesApi = (data, config, scb, ecb) => {
  const { url } = data;
  const extension = url.substring(url.lastIndexOf('.') + 1, url.lastIndexOf('?'));

  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'multipart/form-data' }
  };
  try {
    fetch(url, requestOptions)
      // eslint-disable-next-line consistent-return
      .then((response) => {
        if (response.status === 200) {
          return response.blob();
        }
        ecb(response.status);
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        console.log(link);
        link.href = href;
        link.setAttribute('download', `certificate.${extension}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        scb();
      });
  } catch (error) {
    console.log(error);
    ecb(error);
  }
};

export async function addSession(config, scb, ecb) {
  try {
    const url = judge_operation.ADD_SESSION;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_SESSION');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateSession(config, scb, ecb) {
  try {
    const url = judge_operation.UPDATE_SESSION;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_SESSION');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteSession(config, scb, ecb) {
  try {
    const url = judge_operation.DELETE_SESSION;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_SESSION');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getSessionType(config, scb, ecb) {
  try {
    const url = judge_operation.GET_SESSION_TYPE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_SESSION');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function copySession(config, scb, ecb) {
  try {
    const url = judge_operation.COPY_SESSION;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'COPY_SESSION');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getJudgeList(config, scb, ecb) {
  try {
    const url = judge_operation.GET_JUDGE_LIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_JUDGE_LIST');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateJudgeStatus(config, scb, ecb) {
  try {
    const url = judge_operation.UPDATE_JUDGE_STATUS;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_JUDGE_STATUS');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteJudge(config, scb, ecb) {
  try {
    const url = judge_operation.DELETE_JUDGE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_JUDGE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateJudge(config, scb, ecb) {
  try {
    const url = judge_operation.UPDATE_JUDGE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_JUDGE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getSessionListForJudge(config, scb, ecb) {
  try {
    const url = judge_operation.SESSION_LIST_FOR_JUDGE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'SESSION_LIST_FOR_JUDGE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getGroup(config, scb, ecb) {
  try {
    const url = judge_operation.GET_GROUP;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_GROUP');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addGroup(config, scb, ecb) {
  try {
    const url = judge_operation.ADD_GROUP;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_GROUP');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateGroup(config, scb, ecb) {
  try {
    const url = judge_operation.UPDATE_GROUP;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_GROUP');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteGroup(config, scb, ecb) {
  try {
    const url = judge_operation.DELETE_GROUP;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_GROUP');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getGroupList(config, scb, ecb) {
  try {
    const url = judge_operation.GET_GROUP_LIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_GROUP_LIST');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getGroupSessionList(config, scb, ecb) {
  try {
    const url = judge_operation.GET_GROUP_SESSION_LIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_GROUP_SESSION_LIST');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getGroupGroupProject(config, scb, ecb) {
  try {
    const url = judge_operation.GET_GROUP_GROUP_PROJECT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_GROUP_GROUP_PROJECT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getGroupProject(config, scb, ecb) {
  try {
    const url = judge_operation.GET_GROUP_PROJECT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_GROUP_PROJECT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addGroupProject(config, scb, ecb) {
  try {
    const url = judge_operation.ADD_GROUP_PROJECT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_GROUP_PROJECT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteGroupProject(config, scb, ecb) {
  try {
    const url = judge_operation.DELETE_GROUP_PROJECT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_GROUP_PROJECT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getAvailableGroup(config, scb, ecb) {
  try {
    const url = judge_operation.GET_AVAILABLE_GROUP;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_AVAILABLE_GROUP');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getProjectList(config, scb, ecb) {
  try {
    const url = judge_operation.GET_PROJECT_LIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_PROJECT_LIST');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function assignAllForGroup(config, scb, ecb) {
  try {
    const url = judge_operation.GROUP_ASSIGN_ALL;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GROUP_ASSIGN_ALL');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function fetchSessionProjectList(config, scb, ecb) {
  try {
    const url = judge_operation.FETCH_SESSION_PROJECT_LIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'FETCH_SESSION_PROJECT_LIST');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function fetchSessionProjectSessionList(config, scb, ecb) {
  try {
    const url = judge_operation.FETCH_SESSION_PROJECT_SESSION_LIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'FETCH_SESSION_PROJECT_SESSION_LIST');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateSessionInSession(config, scb, ecb) {
  try {
    const url = judge_operation.UPDATE_SESSION_IN_SESSION;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_SESSION_IN_SESSION');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function unassignProject(config, scb, ecb) {
  try {
    const url = judge_operation.UNASSIGN_PROJECTS;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UNASSIGN_PROJECTS');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}
