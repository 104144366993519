import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Button } from '@mui/material';
import { Stack } from '@material-ui/core';
import TaskList from './TaskList';

function ViewDialog(props) {
  const { data, handleClose } = props;

  return (
    <Stack spacing={3} sx={{ minWidth: 300 }}>
      <TaskList data={data} />
      <Stack direction="row" justifyContent="end" spacing={1}>
        <Button size="small" color="error" onClick={handleClose} variant="outlined">
          Close
        </Button>
      </Stack>
    </Stack>
  );
}

ViewDialog.propTypes = {
  data: PropTypes.object,
  handleClose: PropTypes.func
};

export default connect(() => ({}))(ViewDialog);
