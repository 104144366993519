import * as React from 'react';
import Box from '@mui/material/Box';
import { Card, Grid } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import InfoPart from '../EditPage/InfoPart';
import FilePart from '../EditPage/FilePart';
import StudentPart from '../EditPage/StudentPart';
import ProcessInfoBox from '../../../../components/CustomComponents/CustomCard/ProcessInfoBox';
import FairPart from '../EditPage/FairPart';
import { guidelineMaker } from '../utils/guidelineMaker';

const ADD_MODE = 1;

const steps = ['General Info', 'Project Files', 'Students', 'Affiliated Fair'];

export default function Index(props) {
  const { handleFinish, guidelines } = props;
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const guidelineList = guidelineMaker(guidelines);

  const isStepOptional = (step) => step === 3;

  const isStepSkipped = (step) => skipped.has(step);

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Card sx={{ padding: 3 }}>
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = <Typography variant="caption">Optional</Typography>;
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <>
            <Typography sx={{ mt: 2, mb: 1 }}>
              You have submitted project general information and required files. You must complete
              application payment under project page. Without application payment, your project will
              not be considered registered.
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button variant="outlined" onClick={handleFinish}>
                Complete
              </Button>
            </Box>
          </>
        ) : (
          <>
            {activeStep === 0 ? (
              <Grid container>
                <Grid item xs={12}>
                  {guidelineList[0] && (
                    <ProcessInfoBox Comp={ReactHtmlParser(guidelineList[0].markdown)} />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <InfoPart handleNext={handleNext} handleBack={handleFinish} mode={ADD_MODE} />
                </Grid>
              </Grid>
            ) : activeStep === 1 ? (
              <Grid container>
                <Grid item xs={12}>
                  {guidelineList[1] && (
                    <ProcessInfoBox Comp={ReactHtmlParser(guidelineList[1].markdown)} />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FilePart handleNext={handleNext} handleBack={handleBack} />
                </Grid>
              </Grid>
            ) : activeStep === 2 ? (
              <Grid container>
                <Grid item xs={12}>
                  {guidelineList[2] && (
                    <ProcessInfoBox Comp={ReactHtmlParser(guidelineList[2].markdown)} />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <StudentPart handleNext={handleNext} handleBack={handleBack} />
                </Grid>
              </Grid>
            ) : (
              <Grid container>
                <Grid item xs={12}>
                  {guidelineList[3] && (
                    <ProcessInfoBox Comp={ReactHtmlParser(guidelineList[3].markdown)} />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FairPart handleNext={handleNext} handleBack={handleBack} />
                </Grid>
              </Grid>
            )}
            {activeStep !== 0 && (
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button variant="outlined" color="secondary" onClick={handleFinish}>
                  Save and Exit
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>
    </Card>
  );
}

Index.propTypes = {
  handleFinish: PropTypes.func.isRequired,
  active_term: PropTypes.number,
  guidelines: PropTypes.arrayOf(PropTypes.object)
};
