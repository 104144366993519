import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { InfoOutlined } from '@material-ui/icons';
import { IconButton, Stack } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import { Icon } from '@iconify/react';

const style = {
  root: {
    width: 'auto',
    marginTop: 30,
    paddingBottom: 10,
    backgroundColor: '#ffffff'
  },
  title: {
    color: '#FFC107',
    fontSize: '1rem',
    marginTop: 2
  }
};

function ProcessWarningBox(props) {
  const { Comp } = props;
  const [open, setOpen] = useState(true);
  return (
    <Card style={style.root} variant="outlined">
      <CardContent
        style={{
          paddingTop: 0,
          paddingBottom: 0
        }}
      >
        <Stack spacing={2}>
          <div
            style={{
              height: 5,
              background: 'linear-gradient(90deg, #FFC107, #FFF7CD)',
              marginLeft: -20,
              marginRight: -20
            }}
          />
          <Stack direction="row" spacing={1} justifyContent="space-between">
            <Stack direction="row" spacing={1}>
              <InfoOutlined style={{ color: '#FFC107' }} />
              <Typography variant="h6" component="h2" style={style.title}>
                Warning
              </Typography>
            </Stack>
            {open ? (
              <Tooltip title="Minimize">
                <IconButton color="warning" onClick={() => setOpen(!open)}>
                  <Icon icon="fluent:arrow-minimize-16-filled" width={24} height={24} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Maximize">
                <IconButton color="warning" onClick={() => setOpen(!open)}>
                  <Icon icon="fluent:arrow-maximize-16-filled" width={24} height={24} />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        </Stack>
        {open && Comp}
      </CardContent>
    </Card>
  );
}

ProcessWarningBox.propTypes = {
  Comp: PropTypes.object
};

export default ProcessWarningBox;
