const HOST = process.env.REACT_APP_REST_API_HOST;

const GET_SURVEY_LIST = {
  method: 'GET',
  path: `${HOST}go_admin/survey/survey`
};
const ADD_SURVEY = {
  method: 'POST',
  path: `${HOST}go_admin/survey/survey`
};
const UPDATE_SURVEY = {
  method: 'PUT',
  path: `${HOST}go_admin/survey/survey`
};
const DELETE_SURVEY = {
  method: 'DELETE',
  path: `${HOST}go_admin/survey/survey`
};
const COPY_SURVEY = {
  method: 'POST',
  path: `${HOST}go_admin/survey/survey/copy`
};
const GET_SURVEY_DETAIL = {
  method: 'GET',
  path: `${HOST}go_admin/survey/survey/detail`
};
const ADD_SURVEY_QUESTION = {
  method: 'POST',
  path: `${HOST}go_admin/survey/survey/question`
};
const UPDATE_SURVEY_QUESTION = {
  method: 'PUT',
  path: `${HOST}go_admin/survey/survey/question`
};
const DELETE_SURVEY_QUESTION = {
  method: 'DELETE',
  path: `${HOST}go_admin/survey/survey/question`
};
const CHANGE_SURVEY_QUESTION = {
  method: 'PATCH',
  path: `${HOST}go_admin/survey/survey/question`
};
const GET_REPORT_LIST = {
  method: 'GET',
  path: `${HOST}go_admin/survey/report/list`
};
const GET_REPORT_RESULTS = {
  method: 'GET',
  path: `${HOST}go_admin/survey/report/results`
};

module.exports = {
  GET_SURVEY_LIST,
  ADD_SURVEY,
  UPDATE_SURVEY,
  DELETE_SURVEY,
  COPY_SURVEY,
  GET_SURVEY_DETAIL,
  ADD_SURVEY_QUESTION,
  UPDATE_SURVEY_QUESTION,
  DELETE_SURVEY_QUESTION,
  CHANGE_SURVEY_QUESTION,
  GET_REPORT_LIST,
  GET_REPORT_RESULTS
};
