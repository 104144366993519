import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { ClipLoader } from 'react-spinners';
import { css } from '@emotion/react';
import { Chip, Grid } from '@material-ui/core';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import Dialog from '@material-ui/core/Dialog';
import customLoadingStyle from './CustomLoadingStyle';

const override = css`
  display: block;
  margin: 0 auto;
`;

function CustomLoading(props) {
  const { isOpen, text } = props;
  const bool = isOpen === 1;
  const [dialogOpen, setDialogOpen] = useState(bool);
  useEffect(() => {
    setDialogOpen(bool);
  }, [isOpen, bool]);
  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none'
        }
      }}
    >
      <Grid container className="sweet-loading" alignItems="center" direction="column">
        <Grid item xs={12}>
          <ClipLoader css={override} size={60} color="#FFFFFF" loading={dialogOpen} />
        </Grid>
        <Grid item xs={12} style={{ marginTop: 20 }}>
          <Chip
            style={{ marginRight: 50, marginLeft: 50, fontWeight: 'bold' }}
            icon={<HourglassEmptyIcon />}
            label={text}
            color="primary"
          />
        </Grid>
      </Grid>
    </Dialog>
  );
}

CustomLoading.propTypes = {
  isOpen: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired
};

export default withStyles(customLoadingStyle)(CustomLoading);
