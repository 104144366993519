import * as React from 'react';
import { Card, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useState, useEffect } from 'react';
import { Stack, TextField } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import { setConfirmationAlert, setSnackbar } from '../../../../../actions/alertActions';
import AffiliatedFairCard from '../../../../../components/CustomComponents/CustomCard/AffiliatedFairCard';
import Badge from '../../../../../components/CustomComponents/Badge/Badge';
import {
  fetchAffiliatedFair,
  getAffiliatedFairReq,
  addAffiliatedFair,
  deleteAffiliatedFair
} from '../../serviceCalls';

const ZERO_MODE = 0;
const EXIST_MODE = 1;
const NONE_MODE = 2;

function AffiliatedFairPart(props) {
  const { project_id, dispatch, activeDiscipline } = props;
  const [mode, setMode] = useState(ZERO_MODE);
  const [fairData, setFairData] = useState(null);
  const [fairList, setFairList] = useState([]);
  const [selectedFair, setSelectedFair] = useState(null);

  useEffect(() => {
    fetchAllFairs();
    fetchFairByProjectId();
  }, []);

  function fetchFairByProjectId() {
    getAffiliatedFairReq(
      { project_id },
      (data) => {
        const isExist = Object.keys(data).length !== 0;
        setFairData(data);
        setMode(isExist ? EXIST_MODE : NONE_MODE);
      },
      () => {}
    );
  }
  function fetchAllFairs() {
    fetchAffiliatedFair(
      {},
      (data) => {
        const arr = [];
        data.forEach((item) => {
          const discList = item.fair_discipline_id.split(',');
          const isExist = discList.findIndex((x) => parseInt(x, 10) === activeDiscipline);
          // eslint-disable-next-line no-unused-expressions
          isExist !== -1 && arr.push(item);
        });
        setFairList(arr);
      },
      () => {}
    );
  }
  function deleteFair() {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'This action will delete Affiliation request, are you sure?',
        () => {
          deleteAffiliatedFair(
            { project_id },
            () => {
              setFairData(null);
              setMode(NONE_MODE);
              dispatch(setSnackbar('success', 'Affiliation request has been deleted'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }
  function sendRequest() {
    const param = {
      project_id,
      fair_id: selectedFair.id
    };
    addAffiliatedFair(
      param,
      () => {
        fetchFairByProjectId();
        dispatch(setSnackbar('success', 'Affiliation request has been received'));
      },
      () => {}
    );
  }

  return (
    <Card sx={{ padding: 2, mt: 2 }}>
      <Badge color="submitColor"> Fair </Badge>
      {mode === ZERO_MODE ? (
        <></>
      ) : mode === EXIST_MODE ? (
        <Grid item xs={6} style={{ marginTop: 10 }}>
          <AffiliatedFairCard data={fairData} deleteFair={deleteFair} />
        </Grid>
      ) : (
        <Stack spacing={3} sx={{ mt: 1, mb: 1 }} direction={{ xs: 'column', sm: 'row' }}>
          <Autocomplete
            value={selectedFair}
            onChange={(event, newValue) => {
              setSelectedFair(newValue);
            }}
            style={{ width: 400 }}
            id="free-solo-demo"
            freeSolo
            options={fairList}
            getOptionLabel={(option) =>
              `${option.country}${option.state ? `-${option.state}` : ''}-${option.name}`
            }
            renderInput={(params) => <TextField {...params} fullWidth label="Affiliated Fair" />}
          />
          <Button variant="outlined" onClick={sendRequest}>
            Send Request
          </Button>
        </Stack>
      )}
    </Card>
  );
}

AffiliatedFairPart.propTypes = {
  dispatch: PropTypes.func.isRequired,
  project_id: PropTypes.number.isRequired,
  activeDiscipline: PropTypes.number
};

export default connect((store) => ({
  activeDiscipline: store.projectReducer.activeDiscipline
}))(AffiliatedFairPart);
