export default [
  {
    name: 'country',
    label: 'Country'
  },
  {
    name: 'review_status_comp',
    label: 'Review Detail',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'total_school',
    label: 'T.S.',
    options: {
      tooltip: 'Total School'
    }
  },
  {
    name: 'total',
    label: 'T.P.A.',
    options: {
      tooltip: 'Total Project Application'
    }
  },
  {
    name: 'review_finalist',
    label: 'T.P.F.',
    options: {
      tooltip: 'Total Project Finalist'
    }
  },
  {
    name: 'confirmed_project_count',
    label: 'T.P.C.',
    options: {
      tooltip: 'Total Project Confirmed'
    }
  },
  {
    name: 'student_count',
    label: 'T.S.A.',
    options: {
      tooltip: 'Total Student Application'
    }
  },
  {
    name: 'paid_student_count',
    label: 'T.P.S.C',
    options: {
      tooltip: 'Total Paid Student Count'
    }
  },
  {
    name: 'project_with_paid_student_count',
    label: 'T.P.W.P.S.C',
    options: {
      tooltip: 'Total Project With Paid Student Count'
    }
  },
  {
    name: 'finalist_student_count',
    label: 'T.S.F.',
    options: {
      tooltip: 'Total Student Finalist'
    }
  },
  {
    name: 'attended_student_count',
    label: 'T.S.A.',
    options: {
      tooltip: 'Total Student Attended'
    }
  },
  {
    name: 'art_total',
    label: 'A.A.',
    options: {
      tooltip: 'Art Application'
    }
  },
  {
    name: 'art_review_finalist',
    label: 'A.R.F.',
    options: {
      tooltip: 'Art Review Finalist'
    }
  },
  {
    name: 'art_confirmed_project_count',
    label: 'A.P.C.',
    options: {
      tooltip: 'Art Project Confirmed'
    }
  },
  {
    name: 'art_student_count',
    label: 'A.S.C',
    options: {
      tooltip: 'Art Student Count'
    }
  },
  {
    name: 'art_paid_student_count',
    label: 'A.P.S.C',
    options: {
      tooltip: 'Art Paid Student Count'
    }
  },
  {
    name: 'art_project_with_paid_student_count',
    label: 'A.P.W.P.S.C',
    options: {
      tooltip: 'Art Project With Paid Student Count'
    }
  },
  {
    name: 'art_finalist_student_count',
    label: 'A.S.F.',
    options: {
      tooltip: 'Art Student Finalist'
    }
  },
  {
    name: 'art_attended_student_count',
    label: 'A.A.S.C',
    options: {
      tooltip: 'Art Attended Student Count'
    }
  },
  {
    name: 'business_total',
    label: 'B.A.',
    options: {
      tooltip: 'Business Application'
    }
  },
  {
    name: 'business_review_finalist',
    label: 'B.R.F.',
    options: {
      tooltip: 'Business Review Finalist'
    }
  },
  {
    name: 'business_confirmed_project_count',
    label: 'B.P.C.',
    options: {
      tooltip: 'Business Project Confirmed'
    }
  },
  {
    name: 'business_student_count',
    label: 'B.S.C',
    options: {
      tooltip: 'Business Student Count'
    }
  },
  {
    name: 'business_paid_student_count',
    label: 'B.P.S.C',
    options: {
      tooltip: 'Business Paid Student Count'
    }
  },
  {
    name: 'business_project_with_paid_student_count',
    label: 'B.P.W.P.S.C',
    options: {
      tooltip: 'Business Project With Paid Student Count'
    }
  },
  {
    name: 'business_finalist_student_count',
    label: 'B.S.F.',
    options: {
      tooltip: 'Business Student Finalist'
    }
  },
  {
    name: 'business_attended_student_count',
    label: 'B.A.S.C',
    options: {
      tooltip: 'Business Attended Student Count'
    }
  },
  {
    name: 'coding_total',
    label: 'C.A.',
    options: {
      tooltip: 'Coding Application'
    }
  },
  {
    name: 'coding_review_finalist',
    label: 'C.R.F.',
    options: {
      tooltip: 'Coding Review Finalist'
    }
  },
  {
    name: 'coding_confirmed_project_count',
    label: 'C.P.C.',
    options: {
      tooltip: 'Coding Project Confirmed'
    }
  },
  {
    name: 'coding_student_count',
    label: 'C.S.C',
    options: {
      tooltip: 'Coding Student Count'
    }
  },
  {
    name: 'coding_paid_student_count',
    label: 'C.P.S.C',
    options: {
      tooltip: 'Coding Paid Student Count'
    }
  },
  {
    name: 'coding_project_with_paid_student_count',
    label: 'C.P.W.P.S.C',
    options: {
      tooltip: 'Coding Project With Paid Student Count'
    }
  },
  {
    name: 'coding_finalist_student_count',
    label: 'C.S.F.',
    options: {
      tooltip: 'Coding Student Finalist'
    }
  },
  {
    name: 'coding_attended_student_count',
    label: 'C.A.S.C',
    options: {
      tooltip: 'Coding Attended Student Count'
    }
  },
  {
    name: 'creative_writing_total',
    label: 'C.W.A.',
    options: {
      tooltip: 'Creative Writing Application'
    }
  },
  {
    name: 'creative_writing_review_finalist',
    label: 'C.W.R.F.',
    options: {
      tooltip: 'Creative Writing Review Finalist'
    }
  },
  {
    name: 'creative_writing_confirmed_project_count',
    label: 'C.W.P.C.',
    options: {
      tooltip: 'Creative Writing Project Confirmed'
    }
  },
  {
    name: 'creative_writing_student_count',
    label: 'C.W.S.C',
    options: {
      tooltip: 'Creative Writing Student Count'
    }
  },
  {
    name: 'creative_writing_paid_student_count',
    label: 'C.W.P.S.C',
    options: {
      tooltip: 'Creative Writing Paid Student Count'
    }
  },
  {
    name: 'creative_writing_project_with_paid_student_count',
    label: 'C.W.P.W.P.S.C',
    options: {
      tooltip: 'Creative Writing Project With Paid Student Count'
    }
  },
  {
    name: 'creative_writing_finalist_student_count',
    label: 'C.W.S.F.',
    options: {
      tooltip: 'Creative Writing Student Finalist'
    }
  },
  {
    name: 'creative_writing_attended_student_count',
    label: 'C.W.A.S.C',
    options: {
      tooltip: 'Creative Writing Attended Student Count'
    }
  },
  {
    name: 'music_total',
    label: 'M.A.',
    options: {
      tooltip: 'Music Application'
    }
  },
  {
    name: 'music_review_finalist',
    label: 'M.R.F.',
    options: {
      tooltip: 'Music Review Finalist'
    }
  },
  {
    name: 'music_confirmed_project_count',
    label: 'M.P.C.',
    options: {
      tooltip: 'Music Project Confirmed'
    }
  },
  {
    name: 'music_student_count',
    label: 'M.S.C',
    options: {
      tooltip: 'Music Student Count'
    }
  },
  {
    name: 'music_paid_student_count',
    label: 'M.P.S.C',
    options: {
      tooltip: 'Music Paid Student Count'
    }
  },
  {
    name: 'music_project_with_paid_student_count',
    label: 'M.P.W.P.S.C',
    options: {
      tooltip: 'Music Project With Paid Student Count'
    }
  },
  {
    name: 'music_finalist_student_count',
    label: 'M.S.F.',
    options: {
      tooltip: 'Music Student Finalist'
    }
  },
  {
    name: 'music_attended_student_count',
    label: 'M.A.S.C',
    options: {
      tooltip: 'Music Attended Student Count'
    }
  },
  {
    name: 'robotics_total',
    label: 'R.A.',
    options: {
      tooltip: 'Robotics Application'
    }
  },
  {
    name: 'robotics_review_finalist',
    label: 'R.R.F.',
    options: {
      tooltip: 'Robotics Review Finalist'
    }
  },
  {
    name: 'robotics_confirmed_project_count',
    label: 'R.P.C.',
    options: {
      tooltip: 'Robotics Project Confirmed'
    }
  },
  {
    name: 'robotics_student_count',
    label: 'R.S.C',
    options: {
      tooltip: 'Robotics Student Count'
    }
  },
  {
    name: 'robotics_paid_student_count',
    label: 'R.P.S.C',
    options: {
      tooltip: 'Robotics Paid Student Count'
    }
  },
  {
    name: 'robotics_project_with_paid_student_count',
    label: 'R.P.W.P.S.C',
    options: {
      tooltip: 'Robotics Project With Paid Student Count'
    }
  },
  {
    name: 'robotics_finalist_student_count',
    label: 'R.S.F.',
    options: {
      tooltip: 'Robotics Student Finalist'
    }
  },
  {
    name: 'robotics_attended_student_count',
    label: 'R.A.S.C',
    options: {
      tooltip: 'Robotics Attended Student Count'
    }
  },
  {
    name: 'science_total',
    label: 'S.A.',
    options: {
      tooltip: 'Science Application'
    }
  },
  {
    name: 'science_review_finalist',
    label: 'S.R.F.',
    options: {
      tooltip: 'Science Review Finalist'
    }
  },
  {
    name: 'science_confirmed_project_count',
    label: 'S.P.C.',
    options: {
      tooltip: 'Science Project Confirmed'
    }
  },
  {
    name: 'science_student_count',
    label: 'S.S.C',
    options: {
      tooltip: 'Science Student Count'
    }
  },
  {
    name: 'science_paid_student_count',
    label: 'S.P.S.C',
    options: {
      tooltip: 'Science Paid Student Count'
    }
  },
  {
    name: 'science_project_with_paid_student_count',
    label: 'S.P.W.P.S.C',
    options: {
      tooltip: 'Science Project With Paid Student Count'
    }
  },
  {
    name: 'science_finalist_student_count',
    label: 'S.S.F.',
    options: {
      tooltip: 'Science Student Finalist'
    }
  },
  {
    name: 'science_attended_student_count',
    label: 'S.A.S.C',
    options: {
      tooltip: 'Science Attended Student Count'
    }
  },
  {
    name: 'short_film_total',
    label: 'S.F.A.',
    options: {
      tooltip: 'Short Film Application'
    }
  },
  {
    name: 'short_film_review_finalist',
    label: 'S.F.R.F.',
    options: {
      tooltip: 'Short Film Review Finalist'
    }
  },
  {
    name: 'short_film_confirmed_project_count',
    label: 'S.F.P.C.',
    options: {
      tooltip: 'Short Film Project Confirmed'
    }
  },
  {
    name: 'short_film_student_count',
    label: 'S.F.S.C',
    options: {
      tooltip: 'Short Film Student Count'
    }
  },
  {
    name: 'short_film_paid_student_count',
    label: 'S.F.P.S.C',
    options: {
      tooltip: 'Short Film Paid Student Count'
    }
  },
  {
    name: 'short_film_project_with_paid_student_count',
    label: 'S.F.P.W.P.S.C',
    options: {
      tooltip: 'Short Film Project With Paid Student Count'
    }
  },
  {
    name: 'short_film_finalist_student_count',
    label: 'S.F.S.F.',
    options: {
      tooltip: 'Short Film Student Finalist'
    }
  },
  {
    name: 'short_film_attended_student_count',
    label: 'S.F.A.S.C',
    options: {
      tooltip: 'Short Film Attended Student Count'
    }
  }
];
