export default [
  {
    name: 'id',
    label: 'ID'
  },
  {
    name: 'term_id',
    label: 'Term'
  },
  {
    name: 'country',
    label: 'Country'
  },
  {
    name: 'discipline',
    label: 'Discipline'
  },
  {
    name: 'category',
    label: 'Category'
  },
  {
    name: 'title',
    label: 'Title'
  },
  {
    name: 'file_comp',
    label: 'File',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'file_check_list',
    label: 'File Check',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'file_filter',
    label: 'File',
    options: {
      display: false
    }
  },
  {
    name: 'file_check_list_filter',
    label: 'File Check',
    options: {
      display: false
    }
  },
  {
    name: 'media_link_comp',
    label: 'Media Link',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'media_link_filter',
    label: 'Media Link',
    options: {
      display: false
    }
  },
  {
    name: 'review_score',
    label: 'Review Score'
  },
  {
    name: 'score',
    label: 'Score',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  }
];
