import { IconButton } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { Tooltip } from '@mui/material';
import React from 'react';

export function dataMaker(
  list,
  handleEditPassword,
  handleEditUserRoles,
  handleEditUser,
  handleVerifyUser,
  handleUnlockUser,
  handleRevokeUser,
  handleDeleteUser,
  handleLoginWithUser
) {
  list.forEach((item) => {
    item.login = (
      <Tooltip title="Login with User">
        <IconButton color="secondary" onClick={() => handleLoginWithUser(item.email)}>
          <Icon icon="ri:login-circle-line" width={24} height={24} />
        </IconButton>
      </Tooltip>
    );
    item.password = (
      <IconButton color="secondary" onClick={() => handleEditPassword(item.user_id)}>
        <Icon icon="carbon:password" width={24} height={24} />
      </IconButton>
    );
    item.roles = (
      <IconButton color="secondary" onClick={() => handleEditUserRoles(item.user_id)}>
        <Icon icon="carbon:user-role" width={24} height={24} />
      </IconButton>
    );
    item.edit = (
      <IconButton color="secondary" onClick={() => handleEditUser(item)}>
        <Icon icon="ci:edit" width={24} height={24} />
      </IconButton>
    );
    item.verify = (
      <IconButton
        disabled={item.is_verified}
        color="secondary"
        onClick={() => handleVerifyUser(item.user_id)}
      >
        <Icon icon="uil:comment-verify" width={24} height={24} />
      </IconButton>
    );
    item.revoke = item.is_locked ? (
      <Tooltip title="Unlock User Role">
        <IconButton color="error" onClick={() => handleUnlockUser(item.user_id)}>
          <Icon icon="bx:bx-lock-open" width={24} height={24} />
        </IconButton>
      </Tooltip>
    ) : (
      <Tooltip title="Revoke User Role">
        <IconButton color="secondary" onClick={() => handleRevokeUser(item.user_id)}>
          <Icon icon="bx:bx-lock" width={24} height={24} />
        </IconButton>
      </Tooltip>
    );
    item.delete = (
      <IconButton color="error" onClick={() => handleDeleteUser(item.user_id)}>
        <Icon icon="fluent:delete-48-filled" width={24} height={24} />
      </IconButton>
    );
  });

  return list;
}
