import { Stack, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { Icon } from '@iconify/react';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import { getHomeParticipantCheckListReport, getHomeProjectCheckListReport } from './serviceCalls';
import MuiTable from '../../../components/CustomComponents/MuiTable';
import headers from './headerVariables';
import { getHeaderForParticipants } from './headerVariablesForParticipants';
import Page from '../../../components/Page';
import ProcessInfoBox from '../../../components/CustomComponents/CustomCard/ProcessInfoBox';
import Label from '../../../components/Label';
import { fCurrency } from '../../../utils/formatNumber';
import TableBox from '../../../components/CustomComponents/CustomCard/TableBox';
import FlagDialog from './Components/FlagDialog';

const FINALIST = 1;
const NOT_FINALIST = 2;

function Index(props) {
  const { is_online, dispatch, user_profile } = props;
  const [checkList, setCheckList] = useState([]);
  const [participantCheckList, setParticipantCheckList] = useState([]);
  const headerForParticipants = getHeaderForParticipants(is_online);
  const [isGuidelineClosed, setIsGuidelineClosed] = useState(-1);
  const [guidelines, setGuidelines] = useState([]);
  const [flagDialog, setFlagDialog] = useState(user_profile?.flag_show === 0);

  useEffect(() => {
    getHomeParticipantCheckListReport(
      {},
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((item) => {
          const _balance = Number(parseFloat(item.balance).toFixed(2));
          item.balance_comp =
            _balance < 0 || _balance === 0 ? (
              <Label variant="ghost" color="success">
                {fCurrency(item.balance)}
              </Label>
            ) : (
              <Label variant="ghost" color="error">
                {fCurrency(item.balance)}
              </Label>
            );
          item.balance_filter = _balance < 0 || _balance === 0 ? 'Paid' : 'Not Paid';
          item.name = `${item.first_name} ${item.last_name}`;
          item.date_of_birth_comp = moment(item.date_of_birth).format('YYYY-MM-DD');
          item.judging_session_comp =
            item.judging_session === 'Complete' ? (
              <Tooltip title="Complete">
                <IconButton color="success">
                  <Icon icon="akar-icons:circle-check" width={24} height={24} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Incomplete">
                <IconButton color="error">
                  <Icon color="error" icon="akar-icons:circle-x" width={24} height={24} />
                </IconButton>
              </Tooltip>
            );
          item.confirmation_comp =
            item.confirmation === 'Complete' ? (
              <Tooltip title="Complete">
                <IconButton color="success">
                  <Icon icon="akar-icons:circle-check" width={24} height={24} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Incomplete">
                <IconButton color="error">
                  <Icon color="error" icon="akar-icons:circle-x" width={24} height={24} />
                </IconButton>
              </Tooltip>
            );
          item.presentation_comp =
            item.presentation === 'Complete' ? (
              <Tooltip title="Complete">
                <IconButton color="success">
                  <Icon icon="akar-icons:circle-check" width={24} height={24} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Incomplete">
                <IconButton color="error">
                  <Icon color="error" icon="akar-icons:circle-x" width={24} height={24} />
                </IconButton>
              </Tooltip>
            );
          item.participant_status_comp = (
            <p
              style={{
                fontWeight: 'bold',
                color: item.participant_status === 'Complete' ? '#00ab55' : '#FF4842'
              }}
            >
              {item.participant_status}
            </p>
          );
          item.trip_payment_comp =
            item.trip_payment === 'Complete' ? (
              <Tooltip title="Complete">
                <IconButton color="success">
                  <Icon icon="akar-icons:circle-check" width={24} height={24} />
                </IconButton>
              </Tooltip>
            ) : item.trip_payment === 'Optional' ? (
              <Tooltip title="Optional">
                <IconButton color="secondary">
                  <Icon icon="ph:dots-three-circle-duotone" width={24} height={24} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Incomplete">
                <IconButton color="error">
                  <Icon color="error" icon="akar-icons:circle-x" width={24} height={24} />
                </IconButton>
              </Tooltip>
            );
          item.travel_comp =
            item.travel === 'Complete' ? (
              <Tooltip title="Complete">
                <IconButton color="success">
                  <Icon icon="akar-icons:circle-check" width={24} height={24} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Incomplete">
                <IconButton color="error">
                  <Icon color="error" icon="akar-icons:circle-x" width={24} height={24} />
                </IconButton>
              </Tooltip>
            );
          item.payment_comp =
            item.payment === 'Complete' ? (
              <Tooltip title="Complete">
                <IconButton color="success">
                  <Icon icon="akar-icons:circle-check" width={24} height={24} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Incomplete">
                <IconButton color="error">
                  <Icon color="error" icon="akar-icons:circle-x" width={24} height={24} />
                </IconButton>
              </Tooltip>
            );
        });
        setParticipantCheckList(list);
      },
      () => {}
    );
    getHomeProjectCheckListReport(
      {},
      (data) => {
        const list = data.data;
        setIsGuidelineClosed(data.data.length === 0 ? 0 : 1);
        let isFinalist = false;
        list.forEach((item) => {
          if (item.finalist_status_id === 1) {
            isFinalist = true;
          }
          item.file_comp =
            item['Project File'] === 'Complete' ? (
              <Tooltip title="Complete">
                <IconButton color="success">
                  <Icon icon="akar-icons:circle-check" width={24} height={24} />
                </IconButton>
              </Tooltip>
            ) : item['Project File'] === 'Optional' ? (
              <Tooltip title="Optional">
                <IconButton color="secondary">
                  <Icon icon="ph:dots-three-circle-duotone" width={24} height={24} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Incomplete">
                <IconButton color="error">
                  <Icon color="error" icon="akar-icons:circle-x" width={24} height={24} />
                </IconButton>
              </Tooltip>
            );
          item.fair_comp = (
            <p
              style={{
                fontWeight: 'bold',
                color:
                  item['Fair Affiliation'] === 'Approved'
                    ? '#00ab55'
                    : item['Fair Affiliation'] === 'Denied'
                    ? '#FF4842'
                    : item['Fair Affiliation'] === 'Requested'
                    ? '#3366FF'
                    : '#212B36'
              }}
            >
              {item['Fair Affiliation']}
            </p>
          );
          item.payment_comp =
            item['Project Payment'] === 'Complete' ? (
              <Tooltip title="Complete">
                <IconButton color="success">
                  <Icon icon="akar-icons:circle-check" width={24} height={24} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Incomplete">
                <IconButton color="error">
                  <Icon color="error" icon="akar-icons:circle-x" width={24} height={24} />
                </IconButton>
              </Tooltip>
            );
          item.student_comp =
            item['Student Info'] === 'Complete' ? (
              <Tooltip title="Complete">
                <IconButton color="success">
                  <Icon icon="akar-icons:circle-check" width={24} height={24} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Incomplete">
                <IconButton color="error">
                  <Icon color="error" icon="akar-icons:circle-x" width={24} height={24} />
                </IconButton>
              </Tooltip>
            );
          item.youtube_link_comp =
            item['Youtube Link'] === 'Complete' ? (
              <Tooltip title="Complete">
                <IconButton color="success">
                  <Icon icon="akar-icons:circle-check" width={24} height={24} />
                </IconButton>
              </Tooltip>
            ) : item['Youtube Link'] === 'Optional' ? (
              <Tooltip title="Optional">
                <IconButton color="secondary">
                  <Icon icon="ph:dots-three-circle-duotone" width={24} height={24} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Incomplete">
                <IconButton color="error">
                  <Icon color="error" icon="akar-icons:circle-x" width={24} height={24} />
                </IconButton>
              </Tooltip>
            );
          item.project_status_comp = (
            <p
              style={{
                fontWeight: 'bold',
                color:
                  item['Application Status'] === 'Complete' || item.finalist_status_id === FINALIST
                    ? '#00ab55'
                    : '#FF4842'
              }}
            >
              {item.finalist_status_id === FINALIST
                ? 'Finalist'
                : item.finalist_status_id === NOT_FINALIST
                ? 'Not Finalist'
                : item['Application Status']}
            </p>
          );
        });
        dispatch({ type: 'SET_FINALIST', payload: isFinalist });
        setCheckList(list);
      },
      () => {}
    );
  }, []);

  return (
    <Page title="Home | Genius">
      {isGuidelineClosed === -1 ? (
        <> </>
      ) : (
        <Stack spacing={2}>
          {guidelines.length > 0 && (
            <ProcessInfoBox
              Comp={ReactHtmlParser(guidelines[0].markdown)}
              isClosed={isGuidelineClosed}
            />
          )}
          <TableBox
            title="Project Application Checklist"
            Comp={<MuiTable rowsPerPage={5} tableData={checkList} tableColumns={headers} />}
          />
          <TableBox
            title="Participant Checklist"
            Comp={
              <MuiTable
                rowsPerPage={5}
                tableData={participantCheckList}
                tableColumns={headerForParticipants}
              />
            }
          />
        </Stack>
      )}
      {flagDialog && <FlagDialog handleClose={() => setFlagDialog(false)} />}
    </Page>
  );
}

Index.propTypes = {
  is_online: PropTypes.bool,
  dispatch: PropTypes.func,
  user_profile: PropTypes.object
};

export default connect((store) => ({
  is_online: store.loginReducer.active_term_object?.is_online,
  user_profile: store.loginReducer.user_profile
}))(Index);
