import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import CardContent from '@material-ui/core/CardContent';
import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { blue } from '@material-ui/core/colors';
import PropTypes from 'prop-types';
import Timer from './Timer';

const useStyles = makeStyles(() => ({
  avatar: {
    backgroundColor: blue[500]
  },
  cardAction: {
    float: 'right'
  }
}));

function Index(props) {
  const { projectData } = props;
  const classes = useStyles();

  return (
    <Card>
      <CardHeader
        avatar={<Avatar className={classes.avatar}>{`${projectData.title.charAt(0)}`}</Avatar>}
        title={`${projectData.title}`}
        subheader={projectData.discipline}
        action={<Timer duration={projectData.duration} />}
      />
      <CardContent>
        <Typography variant="body2" color="textPrimary" component="span">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <span>
                <b>Project ID: </b>
              </span>
              {projectData.id || '-'}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <span>
                <b>Country: </b>
              </span>
              {projectData.country || '-'}
            </Grid>
          </Grid>
        </Typography>
      </CardContent>
    </Card>
  );
}

Index.propTypes = {
  projectData: PropTypes.object.isRequired
};

export default Index;
