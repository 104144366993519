import { fetchApi } from '../../../api/fetchApi';

const fair_operation = require('../../../api/RestVariables/Admin/fair_operation');

export async function getFairOperation(config, scb, ecb) {
  try {
    const url = fair_operation.GET_FAIR_OPERATION;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_FAIR_OPERATION');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateFairOperation(config, scb, ecb) {
  try {
    const url = fair_operation.UPDATE_FAIR_OPERATION;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_FAIR_OPERATION');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getFairProfile(config, scb, ecb) {
  try {
    const url = fair_operation.GET_FAIR_PROFILE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_FAIR_PROFILE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function migrateFair(config, scb, ecb) {
  try {
    const url = fair_operation.MIGRATE_FAIR;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'MIGRATE_FAIR');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateFairProfile(config, scb, ecb) {
  try {
    const url = fair_operation.UPDATE_FAIR_PROFILE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_FAIR_PROFILE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteFairOperation(config, scb, ecb) {
  try {
    const url = fair_operation.DELETE_FAIR_OPERATION;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_FAIR_OPERATION');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getFairRequest(config, scb, ecb) {
  try {
    const url = fair_operation.GET_FAIR_REQUEST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_FAIR_REQUEST');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateFairRequest(config, scb, ecb) {
  try {
    const url = fair_operation.UPDATE_FAIR_REQUEST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_FAIR_REQUEST');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}
