import * as React from 'react';
import { Card, DialogContent } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { Stack, TextField, Button, MenuItem, IconButton, Divider } from '@material-ui/core';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { Icon } from '@iconify/react';
import ReactHtmlParser from 'react-html-parser';
import Box from '@mui/material/Box';
import CustomDialog from '../../../components/CustomComponents/CustomDialog';
import ParticipantDialog from './ParticipantDialog';
import { addPerformance, fetchPerformanceTypes, updatePerformance } from './utils/serviceCalls';
import { setSnackbar } from '../../../actions/alertActions';

const ADD_PERFORMANCE = 1;
const EDIT_PERFORMANCE = 2;

function AddPage(props) {
  const { handleBack, mode, data, dispatch, handleNext } = props;
  const [types, setTypes] = useState([]);
  const [dialogShow, setDialogShow] = useState(false);
  const [participantList, setParticipantList] = useState([]);
  const [isFrameShow, setIsFrameShow] = useState(false);
  const [isPreviewText, setIsPreviewText] = useState(false);

  useEffect(() => {
    if (data) {
      const arr = [];
      const idList = data.performance_participant_ids.split(',');
      const nameList = data.performance_participant_complete.split(',');
      idList.forEach((item, index) => {
        const obj = {
          id: Number(item),
          first_name: nameList[index].split(' ')[0],
          last_name: nameList[index].split(' ')[1]
        };
        arr.push(obj);
      });
      setParticipantList(arr);
    }
    fetchPerformanceTypes(
      {},
      (data) => {
        setTypes(data);
      },
      () => {}
    );
  }, []);

  useEffect(() => {
    setValues();
  }, [types]);

  const Schema = Yup.object().shape({
    description: Yup.string()
      .min(2, 'Too Short!')
      .max(500, 'Too Long!')
      .required('Description is required'),
    type_id: Yup.string().required('Type is required'),
    media_link: Yup.string()
      .max(500, 'Too Long!')
      .matches(
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/,
        'Enter a valid YouTube link!'
      )
      .required('YouTube Media Link required')
  });
  const formik = useFormik({
    initialValues: {
      description: mode === EDIT_PERFORMANCE ? data.description : '',
      type_id: mode === EDIT_PERFORMANCE ? data.performance_type_id : '',
      media_link: mode === EDIT_PERFORMANCE ? data.media_link : ''
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      if (participantList.length === 0) {
        dispatch(setSnackbar('warning', 'Please select participant(s).'));
      } else if (mode === ADD_PERFORMANCE) {
        const arr = [];
        participantList.forEach((item) => {
          arr.push(item.id);
        });
        const params = { ...values, participant_ids: arr };
        addPerformance(
          params,
          (data) => {
            dispatch({ type: 'SET_ACTIVE_PERFORMANCE', payload: data.id });
            dispatch(setSnackbar('success', 'The performance has been added.'));
            handleNext();
          },
          () => {}
        );
      } else {
        const arr = [];
        participantList.forEach((item) => {
          arr.push(item.id);
        });
        const params = { ...values, participant_ids: arr, performance_id: data.id };
        updatePerformance(
          params,
          () => {
            handleNext();
            dispatch(setSnackbar('success', 'The performance has been updated.'));
          },
          () => {}
        );
      }
    }
  });
  const { errors, touched, handleSubmit, getFieldProps, values, setFieldValue } = formik;
  function setValues() {
    setFieldValue('type_id', data?.performance_type_id || '');
  }

  useEffect(() => {
    if (values.media_link === '') {
      setIsPreviewText(false);
    } else {
      setIsPreviewText(true);
    }
  }, [values.media_link]);

  function goBack() {
    setFieldValue('type_id', '');
    setFieldValue('description', '');
    setFieldValue('media_link', '');
    setParticipantList([]);
    handleBack();
  }

  function addParticipantToList(arr) {
    arr.sort((a, b) => a.id - b.id);
    setParticipantList(arr);
    setDialogShow(false);
  }

  function handleRemove(id) {
    const arr = participantList.filter((x) => x.id !== id);
    setParticipantList(arr);
  }

  function getId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  }
  const videoId = getId(values.media_link);
  const iframeMarkup = `<iframe width="560" height="315" src="//www.youtube.com/embed/${videoId}" frameborder="0" allowfullscreen></iframe>`;

  return (
    <Card style={{ padding: 20, marginTop: 10 }}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit} spacing={2}>
          <Stack spacing={3}>
            <Stack spacing={2}>
              <TextField
                style={{ maxWidth: 300 }}
                select
                label="Performance Type"
                {...getFieldProps('type_id')}
                error={Boolean(touched.type_id && errors.type_id)}
                helperText={touched.type_id && errors.type_id}
              >
                {types.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                label="Description"
                {...getFieldProps('description')}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
              />
            </Stack>
            <Divider sx={{ mt: 2 }} />
            <Stack spacing={3} sx={{ mt: 1, mb: 1 }} direction={{ xs: 'column', sm: 'row' }}>
              <TextField
                fullWidth
                type="media_link"
                label="Media Link"
                {...getFieldProps('media_link')}
                error={Boolean(touched.media_link && errors.media_link)}
                helperText={touched.media_link && errors.media_link}
              />
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button
                  variant="outlined"
                  disabled={
                    Boolean(touched.media_link && errors.media_link) || values.media_link === ''
                  }
                  onClick={() => setIsFrameShow(true)}
                >
                  Preview
                </Button>
              </div>
            </Stack>
            {isPreviewText && !isFrameShow && (
              <p style={{ color: '#FF4842' }}>
                You must click on preview to confirm the valid YouTube link is correct.
              </p>
            )}
            {isFrameShow && <div> {ReactHtmlParser(iframeMarkup)} </div>}
            <Divider sx={{ mt: 2 }} />
            <Stack spacing={2}>
              <div>
                <Button variant="outlined" onClick={() => setDialogShow(true)}>
                  Choose Participants
                </Button>
              </div>
              {participantList.length > 0 && (
                <TableContainer style={{ maxWidth: 500 }} component={Paper}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow style={{ background: 'gainsboro' }}>
                        <TableCell style={{ width: '30%' }}>ID</TableCell>
                        <TableCell style={{ width: '40%' }}>Name</TableCell>
                        <TableCell style={{ width: '20%' }}>Remove</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {participantList.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell>{row?.id}</TableCell>
                          <TableCell>{`${row?.first_name} ${row?.last_name}`}</TableCell>
                          <TableCell>
                            <IconButton color="secondary" onClick={() => handleRemove(row.id)}>
                              <Icon icon="fluent:delete-48-filled" width={24} height={24} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Stack>
          </Stack>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button variant="outlined" color="inherit" sx={{ mr: 1 }} onClick={goBack}>
              Cancel
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button type="submit" variant="outlined" disabled={!isFrameShow}>
              Next
            </Button>
          </Box>
        </Form>
        <CustomDialog
          onClose={() => setDialogShow(false)}
          open={dialogShow}
          maxWidth="md"
          fullWidth
          title="Participants"
        >
          <DialogContent>
            <ParticipantDialog
              addParticipantToList={addParticipantToList}
              handleClose={() => setDialogShow(false)}
              selectedParticipantList={participantList}
            />
          </DialogContent>
        </CustomDialog>
      </FormikProvider>
    </Card>
  );
}

AddPage.propTypes = {
  dispatch: PropTypes.func,
  handleBack: PropTypes.func.isRequired,
  data: PropTypes.object,
  handleNext: PropTypes.func.isRequired,
  mode: PropTypes.number
};

export default connect(() => ({}))(AddPage);
