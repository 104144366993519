import { Auth } from '@aws-amplify/auth';
import { API } from 'aws-amplify';

export async function handleLogin(response) {
  // when user clicks transfer, get the data from backend server
  // first sign in to aws cognito sliently using
  const username = response.cognito_user_name;
  const password = response.cognito_user_password;
  await Auth.signIn(username, password);
  // you may investigate the result from signin. there might be some useful data.
  // get the jwt token from aws sdk before calling the actual service
  const currentSession = await Auth.currentSession();
  const token = currentSession.idToken.jwtToken;
  // finally call aws api to send data to aws-lambda service like
  const apiName = 'go-transfer-projects';
  const path = '/transfer';
  const options = {
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    body: response
  };
  return API.post(apiName, path, options);
}
