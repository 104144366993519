export default [
  {
    name: 'id',
    label: 'ID'
  },
  {
    name: 'name',
    label: 'Name'
  },
  {
    name: 'judging_session_type',
    label: 'Session Type'
  },
  {
    name: 'discipline',
    label: 'Discipline'
  },
  {
    name: 'capacity',
    label: 'Capacity'
  },
  {
    name: 'number_of_projects',
    label: 'Number of Projects'
  },
  {
    name: 'term_id',
    label: 'Term'
  },
  {
    name: 'location',
    label: 'Location'
  },
  {
    name: 'start_date_view',
    label: 'Start Date'
  },
  {
    name: 'end_date_view',
    label: 'End Date'
  }
];
