import { fetchApi } from '../../../api/fetchApi';

const school_operation = require('../../../api/RestVariables/Admin/school_operation');

export async function getSchoolOperation(config, scb, ecb) {
  try {
    const url = school_operation.GET_SCHOOL_INFO;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_SCHOOL_INFO');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateSchoolOperation(config, scb, ecb) {
  try {
    const url = school_operation.UPDATE_SCHOOL_INFO;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_SCHOOL_INFO');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteSchoolOperation(config, scb, ecb) {
  try {
    const url = school_operation.DELETE_SCHOOL_INFO;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_SCHOOL_INFO');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function transferSchool(config, scb, ecb) {
  try {
    const url = school_operation.UPDATE_TRANSFER_SCHOOL;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_TRANSFER_SCHOOL');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function mergeSchool(config, scb, ecb) {
  try {
    const url = school_operation.MERGE_SCHOOL;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'MERGE_SCHOOL');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getReviewList(config, scb, ecb) {
  try {
    const url = school_operation.GET_REVIEW_LIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_REVIEW_LIST');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getReviewDetail(config, scb, ecb) {
  try {
    const url = school_operation.GET_REVIEW_DETAIL;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_REVIEW_DETAIL');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateReviewStatus(config, scb, ecb) {
  try {
    const url = school_operation.UPDATE_REVIEW_STATUS;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_REVIEW_STATUS');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getReportFile(param, scb, ecb) {
  try {
    const url = school_operation.GET_FILE_LINK;
    await fetchApi(
      url,
      param,
      (data) => {
        scb(data);
        console.log(data, 'GET_FILE_LINK');
      },
      (error) => {
        ecb(error);
        console.log(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getReportFileMeta(config, scb, ecb) {
  try {
    const url = school_operation.GET_FILE_LINK;
    await fetchApi(
      url,
      { file_id: config.id },
      (data) => {
        handleGetFilesApi(data, config, scb, ecb);
        console.log(data, 'ADD_FILE');
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

const handleGetFilesApi = (data, config, scb, ecb) => {
  const { url } = data;
  const extension = url.substring(url.lastIndexOf('.') + 1, url.lastIndexOf('?'));
  const requestOptions = {
    method: 'GET'
  };
  try {
    fetch(url, requestOptions)
      // eslint-disable-next-line consistent-return
      .then((response) => {
        if (response.status === 200) {
          scb();
          return response.blob();
        }
        ecb(response.status);
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        console.log(link);
        link.href = href;
        link.setAttribute('download', `${config.file_name}.${extension}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  } catch (error) {
    console.log(error);
    ecb(error);
  }
};
