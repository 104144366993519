import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField, IconButton, Tooltip, Stack } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { Button } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const AddRoleDialog = ({ handleEditRole, selectedRole, roleListUpdateIndex }) => {
  const [roleName, setRoleName] = useState('');
  const [roleAccessKey, setRoleAccessKey] = useState('');
  const [roleId, setRoleId] = useState(-1);
  const [editName, setEditName] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    setRoleName(selectedRole.role_name);
    setRoleAccessKey(selectedRole.role_access_key);
    setRoleId(selectedRole.role_id);
    setIsAdmin(!!selectedRole.role_is_admin);
  }, [selectedRole]);
  useEffect(() => {
    setEditName(false);
  }, [roleListUpdateIndex]);
  const onCancel = () => {
    setRoleName(selectedRole.role_name);
    setRoleAccessKey(selectedRole.role_access_key);
    setEditName(false);
  };

  return (
    <Stack spacing={2}>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <TextField
          label="Role Name"
          value={roleName}
          disabled={!editName}
          onChange={(e) => setRoleName(e.target.value)}
        />
        <TextField
          label="Role Access Key"
          value={roleAccessKey}
          disabled={!editName}
          onChange={(e) => setRoleAccessKey(e.target.value)}
        />
        <FormControlLabel
          disabled={!editName}
          control={<Switch checked={isAdmin} onChange={() => setIsAdmin(!isAdmin)} />}
          label="Is Admin"
        />
        <Tooltip title="Edit Role">
          <IconButton color="primary" onClick={() => setEditName(true)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Stack>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        {editName && (
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Button
              variant="outlined"
              onClick={() => handleEditRole(roleName, roleAccessKey, roleId, isAdmin)}
            >
              Update Role
            </Button>
            <Button variant="outlined" color="error" onClick={onCancel}>
              Cancel
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

AddRoleDialog.propTypes = {
  handleEditRole: PropTypes.func.isRequired,
  selectedRole: PropTypes.object.isRequired,
  roleListUpdateIndex: PropTypes.number.isRequired
};

export default AddRoleDialog;
