import { Button, Stack } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InfoCard from './InfoCard';
import QuestionDetail from './QuestionDetail';
import { deleteJudging, submitJudging } from '../_apis/serviceCalls';
import { setConfirmationAlert } from '../../../actions/alertActions';

function Index(props) {
  const { projectData, handleSuccess, handleClose, mode, dispatch } = props;
  const questionList = projectData.questions;
  const [controlList, setControlList] = useState([]);
  const [submitDisable, setSubmitDisable] = useState(mode === 'start');

  useEffect(() => {
    const arr = [];
    projectData.questions.forEach((question) => {
      const obj = {
        judging_form_question_id: question.judging_form_question_id,
        state: mode !== 'start'
      };
      arr.push(obj);
    });
    setControlList(arr);
  }, []);

  useEffect(() => {
    const isFalseCaseExist = controlList.find((x) => x.state === false);
    setSubmitDisable(isFalseCaseExist);
  }, [controlList]);

  function handleSubmitJudging() {
    const params = { judging_id: projectData.judging_id };
    if (mode === 'edit') {
      params.is_edit = 1;
    }
    submitJudging(
      params,
      () => {
        handleSuccess();
      },
      () => {}
    );
  }

  function handleSelectScore(judging_form_question_id) {
    const updatedList = controlList.map((x) =>
      x.judging_form_question_id === judging_form_question_id ? { ...x, state: true } : x
    );
    setControlList(updatedList);
  }

  function closeClick() {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, CANCEL',
        'This will delete unsaved changes permanently',
        () => {
          deleteJudging(
            { judging_id: projectData.judging_id },
            () => {
              handleClose();
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            },
            () => {}
          );
        }
      )
    );
  }

  return (
    <Stack spacing={2}>
      <InfoCard projectData={projectData} />
      {questionList.map((question, index) => (
        <QuestionDetail question={question} key={index} handleSelectScore={handleSelectScore} />
      ))}
      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="start" spacing={2}>
        <Button disabled={submitDisable} variant="outlined" onClick={handleSubmitJudging}>
          Submit
        </Button>
        {mode === 'start' && (
          <Button variant="outlined" color="error" onClick={closeClick}>
            Cancel
          </Button>
        )}
      </Stack>
    </Stack>
  );
}

Index.propTypes = {
  projectData: PropTypes.object.isRequired,
  handleSuccess: PropTypes.func,
  handleClose: PropTypes.func,
  mode: PropTypes.string,
  dispatch: PropTypes.func
};

export default connect(() => ({}))(Index);
