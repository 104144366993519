const HOST = process.env.REACT_APP_REST_API_HOST;

const MAKE_REFUND = {
  method: 'POST',
  path: `${HOST}go_admin/payment/project/refund`
};
const GET_REFUND = {
  method: 'GET',
  path: `${HOST}go_admin/payment/project/refund`
};
const MANUAL = {
  method: 'POST',
  path: `${HOST}go_admin/payment/project/manual`
};
const GET_PAYMENT_PROJECT = {
  method: 'GET',
  path: `${HOST}go_admin/payment/project`
};

const MAKE_REFUND_PARTICIPANT = {
  method: 'POST',
  path: `${HOST}go_admin/payment/participant/refund`
};
const GET_REFUND_PARTICIPANT = {
  method: 'GET',
  path: `${HOST}go_admin/payment/participant/refund`
};
const MANUAL_PARTICIPANT = {
  method: 'POST',
  path: `${HOST}go_admin/payment/participant/manual`
};
const GET_PAYMENT_PROJECT_PARTICIPANT = {
  method: 'GET',
  path: `${HOST}go_admin/payment/participant`
};

const MAKE_REFUND_TRIP = {
  method: 'POST',
  path: `${HOST}go_admin/payment/trip/refund`
};
const GET_REFUND_TRIP = {
  method: 'GET',
  path: `${HOST}go_admin/payment/trip/refund`
};
const MANUAL_TRIP = {
  method: 'POST',
  path: `${HOST}go_admin/payment/trip/manual`
};
const GET_PAYMENT_TRIP = {
  method: 'GET',
  path: `${HOST}go_admin/payment/trip`
};

module.exports = {
  MAKE_REFUND,
  GET_REFUND,
  MANUAL,
  GET_PAYMENT_PROJECT,
  MAKE_REFUND_PARTICIPANT,
  GET_REFUND_PARTICIPANT,
  MANUAL_PARTICIPANT,
  GET_PAYMENT_PROJECT_PARTICIPANT,
  MAKE_REFUND_TRIP,
  GET_REFUND_TRIP,
  MANUAL_TRIP,
  GET_PAYMENT_TRIP
};
