import React, { useState } from 'react';
import './read-more.css';
import PropTypes from 'prop-types';

const ELLIPSES = '…';
const SHOW_LESS_TEXT = 'Show Less';
const SHOW_MORE_TEXT = 'Read More';

const ReadMore = (props) => {
  const [showingAll, setShowingAll] = useState(false);

  const { text, readMoreCharacterLimit } = props;

  const toggleReadMore = () => {
    setShowingAll(!showingAll);
  };

  const getReadMoreParts = ({ text, readMoreCharacterLimit }) => {
    let teaserText;
    let remainingText;
    const remainingWordsArray = [];

    if (text) {
      const teaserWordsArray = text.split(' ');
      while (teaserWordsArray.join(' ').length > readMoreCharacterLimit) {
        remainingWordsArray.unshift(teaserWordsArray.pop(' '));
      }
      teaserText = teaserWordsArray.join(' ');
      if (remainingWordsArray.length > 0) {
        remainingText = remainingWordsArray.join(' ');
      }
    }
    return {
      teaserText,
      remainingText
    };
  };

  const getText = ({ showingAll, text, readMoreCharacterLimit }) => {
    const { teaserText, remainingText } = getReadMoreParts({ text, readMoreCharacterLimit });

    if (!showingAll && text.length > readMoreCharacterLimit) {
      return (
        <span>
          {teaserText.replace(/\s*$/, '')}
          <span className="read-more__text--remaining read-more__text--hide">{remainingText}</span>
          {ELLIPSES}
        </span>
      );
    }

    return (
      <span>
        {teaserText}
        <span className="read-more__text--remaining read-more__text--show">{remainingText}</span>
      </span>
    );
  };

  const getActionButton = (showingAll) => {
    if (text.length <= readMoreCharacterLimit) return;

    const buttonText = showingAll ? SHOW_LESS_TEXT : SHOW_MORE_TEXT;
    // eslint-disable-next-line consistent-return
    return (
      <button type="button" onClick={toggleReadMore} className="read-more__button">
        {buttonText}
      </button>
    );
  };

  const textToDisplay = getText({ showingAll, text, readMoreCharacterLimit });
  const actionButton = getActionButton(showingAll);

  return (
    <>
      {textToDisplay} {actionButton}
    </>
  );
};

ReadMore.propTypes = {
  text: PropTypes.string.isRequired,
  readMoreCharacterLimit: PropTypes.number
};

ReadMore.defaultProps = {
  readMoreCharacterLimit: 100
};

export default ReadMore;
