export default [
  {
    name: 'id',
    label: 'ID'
  },
  {
    name: 'term_id',
    label: 'Term'
  },
  {
    name: 'school_name',
    label: 'School'
  },
  {
    name: 'project_title',
    label: 'Project Title'
  },
  {
    name: 'participant_type',
    label: 'Type'
  },
  {
    name: 'name',
    label: 'Participant Name'
  },
  {
    name: 'gender',
    label: 'Gender'
  },
  {
    name: 'grade_level',
    label: 'Grade Level'
  },
  {
    name: 'date_of_birth_comp',
    label: 'Date of Birth'
  },
  {
    name: 'is_confirmed_comp',
    label: 'Confirmed',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'is_confirmed_filter',
    label: 'Confirmed',
    options: {
      display: false
    }
  },
  {
    name: 'is_attended_comp',
    label: 'Attended',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'is_attended_filter',
    label: 'Attended',
    options: {
      display: false
    }
  },
  {
    name: 'recover',
    label: 'Recover',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  }
];
