import { MenuItem, Stack, TextField } from '@material-ui/core';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as React from 'react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { setSnackbar } from '../../../../actions/alertActions';
import { updateParticipantOperationParticipant } from '../serviceCalls';

const GRADE_LIST = [8, 9, 10, 11, 12];
const englishChar = /^[-a-zA-Z0-9& ]+$/;
const GUEST_TYPE = 3;

function AddUpdateDialog(props) {
  const { data, handleClose, dispatch, refreshData, genderList, active_term_object } = props;
  const [isDisabled, setIsDisabled] = useState(false);

  const [schema, setSchema] = useState(
    Yup.object().shape({
      first_name: Yup.string()
        .min(2, 'Too Short!')
        .max(500, 'Too Long!')
        .matches(englishChar, 'Please enter only English characters!')
        .required('First name required'),
      last_name: Yup.string()
        .min(2, 'Too Short!')
        .max(500, 'Too Long!')
        .matches(englishChar, 'Please enter only English characters!')
        .required('Last name required'),
      gender_id: Yup.string().required('Gender required'),
      email: Yup.string()
        .max(500, 'Too Long!')
        .email('Email must be a valid email address')
        .required('Fair Email is required'),
      date_of_birth:
        data?.participant_type_id === 1
          ? Yup.date()
              .max(
                moment(active_term_object.student_dob_min_value).format('YYYY-MM-DD'),
                'Students must be older than 13 at the time of registration'
              )
              .required('Date of Birth required')
          : data?.participant_type_id === 2
          ? Yup.date()
              .max(
                moment(active_term_object.chaperone_dob_min_value).format('YYYY-MM-DD'),
                'Chaperone must be older than 18 at the time of registration'
              )
              .required('Date of Birth required')
          : Yup.date(),
      grade_level_id:
        data?.participant_type_id === 1 && Yup.string().required('Grade Level required'),
      is_attended: Yup.string().required('Attendance required'),
      staying_at_hotel: Yup.string().required('Staying required'),
      participant_type_id: Yup.string().required('Staying required')
    })
  );

  const formik = useFormik({
    initialValues: {
      first_name: data?.first_name,
      last_name: data?.last_name,
      gender_id: data?.gender_id,
      email: data?.email,
      date_of_birth: moment(data?.date_of_birth).format('YYYY-MM-DD'),
      grade_level_id: data?.grade_level,
      is_attended: data?.is_attended,
      staying_at_hotel: data?.staying_at_hotel,
      participant_type_id: data?.participant_type_id
    },
    validationSchema: schema,
    onSubmit: (values) => {
      const param = {
        ...values,
        balance: data.balance,
        participant_id: data.id
      };
      const { grade_level_id, ...paramWithoutGradeLevel } = param;
      const latestParam = values.participant_type_id === 1 ? param : paramWithoutGradeLevel;
      updateParticipantOperationParticipant(
        latestParam,
        () => {
          refreshData();
          dispatch(setSnackbar('success', 'The participant has been updated'));
        },
        () => {}
      );
    }
  });

  const { errors, touched, handleSubmit, getFieldProps, values, setFieldValue } = formik;

  useEffect(() => {
    if (values.participant_type_id === GUEST_TYPE) {
      const matchPoint = moment(active_term_object.student_dob_min_value).format('YYYY-MM-DD');
      const isYounger = moment(values.date_of_birth).isAfter(moment(matchPoint));
      if (isYounger) {
        setIsDisabled(true);
        setFieldValue('staying_at_hotel', 1);
      } else {
        setIsDisabled(false);
      }
    } else {
      setIsDisabled(false);
    }
  }, [values]);

  useEffect(() => {
    const _schema = Yup.object().shape({
      first_name: Yup.string()
        .min(2, 'Too Short!')
        .max(500, 'Too Long!')
        .matches(englishChar, 'Please enter only English characters!')
        .required('First name required'),
      last_name: Yup.string()
        .min(2, 'Too Short!')
        .max(500, 'Too Long!')
        .matches(englishChar, 'Please enter only English characters!')
        .required('Last name required'),
      gender_id: Yup.string().required('Gender required'),
      email: Yup.string()
        .max(500, 'Too Long!')
        .email('Email must be a valid email address')
        .required('Fair Email is required'),
      date_of_birth:
        values?.participant_type_id === 1
          ? Yup.date()
              .max(
                moment(active_term_object.student_dob_min_value).format('YYYY-MM-DD'),
                'Student must be older than 13 at the time of registration'
              )
              .required('Date of Birth required')
          : values?.participant_type_id === 2
          ? Yup.date()
              .max(
                moment(active_term_object.chaperone_dob_min_value).format('YYYY-MM-DD'),
                'Chaperone must be older than 18 at the time of registration'
              )
              .required('Date of Birth required')
          : Yup.date(),
      grade_level_id:
        values?.participant_type_id === 1 && Yup.string().required('Grade Level required'),
      is_attended: Yup.string().required('Attendance required'),
      staying_at_hotel: Yup.string().required('Staying required'),
      participant_type_id: Yup.string().required('Staying required')
    });
    setSchema(_schema);
  }, [values]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{ mt: 2, mb: 1 }}>
          <Stack spacing={3} sx={{ mt: 2, mb: 1 }} direction={{ xs: 'column', sm: 'row' }}>
            <TextField
              fullWidth
              label="First Name"
              {...getFieldProps('first_name')}
              error={Boolean(touched.first_name && errors.first_name)}
              helperText={touched.first_name && errors.first_name}
            />
            <TextField
              fullWidth
              label="Last Name"
              {...getFieldProps('last_name')}
              error={Boolean(touched.last_name && errors.last_name)}
              helperText={touched.last_name && errors.last_name}
            />
            <TextField
              fullWidth
              label="Gender"
              select
              {...getFieldProps('gender_id')}
              error={Boolean(touched.gender_id && errors.gender_id)}
              helperText={touched.gender_id && errors.gender_id}
            >
              {genderList.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
          <Stack spacing={3} sx={{ mt: 2, mb: 1 }} direction={{ xs: 'column', sm: 'row' }}>
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              type="date"
              label="Date of Birth"
              {...getFieldProps('date_of_birth')}
              error={Boolean(touched.date_of_birth && errors.date_of_birth)}
              helperText={touched.date_of_birth && errors.date_of_birth}
            />
            <TextField
              fullWidth
              label="Email"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
            {values.participant_type_id === 1 && (
              <TextField
                fullWidth
                select
                label="Grade Level"
                {...getFieldProps('grade_level_id')}
                error={Boolean(touched.grade_level_id && errors.grade_level_id)}
                helperText={touched.grade_level_id && errors.grade_level_id}
              >
                {GRADE_LIST.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Stack>
          <Stack spacing={3} sx={{ mt: 2, mb: 1 }} direction={{ xs: 'column', sm: 'row' }}>
            <TextField
              fullWidth
              select
              label="Type"
              {...getFieldProps('participant_type_id')}
              error={Boolean(touched.participant_type_id && errors.participant_type_id)}
              helperText={touched.participant_type_id && errors.participant_type_id}
            >
              <MenuItem key={1} value={1}>
                Student
              </MenuItem>
              <MenuItem key={2} value={2}>
                Chaperone
              </MenuItem>
              <MenuItem key={3} value={3}>
                Guest
              </MenuItem>
            </TextField>
            <TextField
              fullWidth
              select
              label="Attendance"
              {...getFieldProps('is_attended')}
              error={Boolean(touched.is_attended && errors.is_attended)}
              helperText={touched.is_attended && errors.is_attended}
            >
              <MenuItem key={1} value={1}>
                Yes
              </MenuItem>
              <MenuItem key={0} value={0}>
                No
              </MenuItem>
            </TextField>
            <TextField
              disabled={isDisabled}
              fullWidth
              select
              label="Staying at Hotel"
              {...getFieldProps('staying_at_hotel')}
              error={Boolean(touched.staying_at_hotel && errors.staying_at_hotel)}
              helperText={touched.staying_at_hotel && errors.staying_at_hotel}
            >
              <MenuItem key={1} value="1">
                Yes
              </MenuItem>
              <MenuItem key={0} value="0">
                No
              </MenuItem>
            </TextField>
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="end" spacing={2}>
            <Button type="submit" variant="outlined">
              Update
            </Button>
            <Button variant="outlined" color="error" onClick={handleClose}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

AddUpdateDialog.propTypes = {
  data: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  genderList: PropTypes.array,
  active_term_object: PropTypes.object
};

export default connect((store) => ({
  active_term_object: store.loginReducer.active_term_object
}))(AddUpdateDialog);
