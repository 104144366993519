import { IconButton, Stack, Typography } from '@material-ui/core';
import { Button, DialogContent, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import Email from '@mui/icons-material/Email';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MuiTable from '../../../../components/CustomComponents/MuiTable';
import CustomDialog from '../../../../components/CustomComponents/CustomDialog';
import CustomMail from '../../../../components/CustomComponents/CustomMail';
import { TRAVEL_MAIL_TYPE } from '../../../../api/RestVariables/staticVariables';

function TravelSummaryTable(props) {
  const { tableData, tableColumns, isCommunication } = props;
  const [mailDialogShow, setMailDialogShow] = useState(false);
  const [addMail, setAddMail] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [idList, setIdList] = useState([]);

  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedItems(rowsSelected);
    const arr = [];
    rowsSelected.forEach((item) => {
      arr.push(tableData[item].id);
    });
    setIdList(arr);
  };

  function handleRefresh() {
    setMailDialogShow(false);
    setAddMail(false);
    setSelectedItems([]);
  }
  function TermSelect() {
    return (
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 2 }}>
        <Typography variant="h6">Travel Summary</Typography>
        {addMail && (
          <div>
            <Button
              style={{ marginRight: 5 }}
              disabled={selectedItems.length === 0}
              variant="outlined"
              onClick={() => setMailDialogShow(true)}
            >
              Next
            </Button>
            <Button color="error" variant="outlined" onClick={() => setAddMail(false)}>
              Cancel
            </Button>
          </div>
        )}
      </Stack>
    );
  }

  return (
    <>
      <MuiTable
        tableData={tableData}
        tableColumns={tableColumns}
        title={<TermSelect />}
        customOption={{
          selectableRows: addMail ? 'multiple' : 'none',
          onRowSelectionChange,
          rowsSelected: selectedItems,
          customToolbar: () =>
            isCommunication !== -1 && (
              <Tooltip title="Send Email">
                <IconButton onClick={() => setAddMail(true)}>
                  <Email />
                </IconButton>
              </Tooltip>
            )
        }}
      />
      <CustomDialog
        onClose={() => setMailDialogShow(false)}
        open={mailDialogShow}
        maxWidth="lg"
        title="Send Mail"
        fullWidth
        disableEnforceFocus
      >
        <DialogContent>
          <CustomMail
            handleRefresh={handleRefresh}
            mailType={TRAVEL_MAIL_TYPE}
            idList={idList}
            handleClose={() => setMailDialogShow(false)}
          />
        </DialogContent>
      </CustomDialog>
    </>
  );
}

TravelSummaryTable.propTypes = {
  tableData: PropTypes.array,
  tableColumns: PropTypes.array,
  isCommunication: PropTypes.number
};

export default connect((store) => ({
  isCommunication: store.routeReducer.isCommunication
}))(TravelSummaryTable);
