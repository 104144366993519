import { Checkbox, Divider, TextField } from '@material-ui/core';
import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useState } from 'react';
import { Button, DialogActions, DialogContent, Stack } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import CustomDialog from '../../../../../components/CustomComponents/CustomDialog';
import TableBox from '../../../../../components/CustomComponents/CustomCard/TableBox';
import { signForm } from '../../serviceCalls';
import { setSnackbar } from '../../../../../actions/alertActions';

function SignatureDialog(props) {
  const { handleClose, participant, dispatch, refreshData } = props;
  const [parentName, setParentName] = useState('');
  const [checked, setChecked] = useState(false);

  function handleSign() {
    const params = {
      parent_name: parentName,
      participant_id: participant.id
    };
    signForm(params, () => {
      dispatch(setSnackbar('success', 'Signed documents have been sent to your email.'));
      refreshData();
    });
  }

  return (
    <CustomDialog fullWidth maxWidth="md" onClose={handleClose} title="Sign Consent">
      <DialogContent>
        <Stack spacing={2} style={{ marginTop: 10 }}>
          <TableBox
            title=""
            Comp={
              <p>
                Please review the{' '}
                <a
                  style={{ fontWeight: 'bold' }}
                  target="_blank"
                  href="https://geniuscountries.s3.us-east-2.amazonaws.com/consent_forms/Release_Agreement_to_Participate.pdf"
                  rel="noreferrer"
                >
                  consent
                </a>{' '}
                and code of{' '}
                <a
                  style={{ fontWeight: 'bold' }}
                  target="_blank"
                  href="https://geniuscountries.s3.us-east-2.amazonaws.com/consent_forms/Participant_Code_of_Conduct.pdf"
                  rel="noreferrer"
                >
                  conduct
                </a>{' '}
                forms carefully. Then, kindly enter the name of the parent or guardian in the space
                provided below. To confirm acceptance of the terms and conditions, click on the
                “Sign” button. Please note that participants under the age of 18 are not permitted
                to attend GENIUS Olympiad without parental approval.
              </p>
            }
          />
          <Stack spacing={2}>
            <TextField
              value={parentName}
              onChange={(e) => setParentName(e.target.value)}
              fullWidth
              label="Parent Name"
            />
            <Stack spacing={2} direction="row" style={{ display: 'flex', alignItems: 'center' }}>
              <p>Signature: </p>
              <p className="signature-font-style">{parentName.toLowerCase()}</p>
            </Stack>
          </Stack>
          <Divider style={{ marginBottom: 20 }} />
          <Stack spacing={2}>
            <TextField
              value={`${participant.first_name} ${participant.last_name}`}
              fullWidth
              label="Participant Name"
              disabled
            />
            <Stack spacing={2} direction="row" style={{ display: 'flex', alignItems: 'center' }}>
              <p>Signature: </p>
              <p className="signature-font-style">
                {`${participant.first_name} ${participant.last_name}`.toLowerCase()}
              </p>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <FormControlLabel
          control={<Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)} />}
          label="I read all the forms and I agree with all stated terms and conditions."
        />
        <Button variant="outlined" disabled={!checked || !parentName} onClick={handleSign}>
          Sign
        </Button>
        <Button variant="outlined" color="error" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </CustomDialog>
  );
}

SignatureDialog.propTypes = {
  participant: PropTypes.object.isRequired,
  refreshData: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired
};

export default connect(() => ({}))(SignatureDialog);
