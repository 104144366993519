import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MenuItem, TextField } from '@material-ui/core';
import { Button, Stack } from '@mui/material';
import { setSnackbar } from '../../../../../actions/alertActions';
import {
  getPerformanceOperationStatus,
  updateStatusPerformanceOperation
} from '../../serviceCalls';

const StatusDialog = (props) => {
  const { handleClose, performanceId, dispatch, handleRefresh } = props;
  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(null);

  useEffect(() => {
    getPerformanceOperationStatus(
      {},
      (data) => {
        setTypes(data);
      },
      () => {}
    );
  }, []);

  function handleAssign() {
    if (selectedType !== null) {
      updateStatusPerformanceOperation(
        { performance_id: performanceId, status_id: selectedType },
        () => {
          dispatch(setSnackbar('success', 'The status of performance has been updated'));
          handleRefresh();
        },
        () => {}
      );
    } else {
      dispatch(setSnackbar('warning', 'Please select performance status'));
    }
  }

  return (
    <Stack spacing={2} sx={{ mt: 1 }}>
      <TextField
        fullWidth
        select
        label="Performance Status"
        onChange={(e) => setSelectedType(e.target.value)}
      >
        {types.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </TextField>
      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="end" spacing={2}>
        <Button onClick={handleAssign} variant="outlined">
          Save
        </Button>
        <Button variant="outlined" color="error" onClick={handleClose}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};

StatusDialog.propTypes = {
  performanceId: PropTypes.number,
  handleClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  handleRefresh: PropTypes.func
};

export default connect(() => ({}))(StatusDialog);
