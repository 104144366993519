export default [
  {
    name: 'user_id',
    label: 'User ID'
  },
  {
    name: 'user_first_name',
    label: 'First Name'
  },
  {
    name: 'user_last_name',
    label: 'Last Name'
  },
  {
    name: 'user_email',
    label: 'Email'
  },
  {
    name: 'mail_type',
    label: 'Type'
  },
  {
    name: 'date',
    label: 'Send Date',
    options: {
      sortCompare: (order) => (obj1, obj2) => {
        const val1 = new Date(obj1?.data);
        const val2 = new Date(obj2?.data);
        return (val1 - val2) * (order === 'asc' ? 1 : -1);
      }
    }
  },
  {
    name: 'send_to_participant',
    label: 'Send to Participant'
  },
  {
    name: 'successful_count',
    label: 'Successful Count'
  },
  {
    name: 'failed_count',
    label: 'Failed Count'
  },
  {
    name: 'view',
    label: 'View',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  }
];
