import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Tooltip } from '@mui/material';
import { IconButton } from '@material-ui/core';
import { Icon } from '@iconify/react';
import headers from './headerVariables';
import MuiTable from '../../../../../../components/CustomComponents/MuiTable';
import {
  setConfirmationAlert,
  setLoading,
  setSnackbar
} from '../../../../../../actions/alertActions';
import Label from '../../../../../../components/Label';
import { fCurrency } from '../../../../../../utils/formatNumber';
import { deleteJudgingJudgeProject, getJudgingJudgeProjects } from '../../_apis/serviceCalls';
import { convertSecondToHour } from '../../../../../../utils/formatTime';

function ExpandView(props) {
  const { judge_id, dispatch, handleRefresh } = props;
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    dispatch(setLoading(1, 'Loading...'));
    getJudgingJudgeProjects(
      { judge_id },
      (data) => {
        data.forEach((item) => {
          item.totalTime = item.judging_time ? convertSecondToHour(item.judging_time) : '-';
          const _balance = Number(parseFloat(item.balance).toFixed(2));
          item.balance_comp =
            _balance < 0 || _balance === 0 ? (
              <Label variant="ghost" color="success">
                {fCurrency(item.balance)}
              </Label>
            ) : (
              <Label variant="ghost" color="error">
                {fCurrency(item.balance)}
              </Label>
            );
          item.createdDate = item.created_at
            ? moment(item.created_at).format('YYYY-MM-DD HH:mm')
            : '-';
          item.submittedDate = item.submitted_at
            ? moment(item.submitted_at).format('YYYY-MM-DD HH:mm')
            : '-';
          item.modifiedData = item.last_modified_at
            ? moment(item.last_modified_at).format('YYYY-MM-DD HH:mm')
            : '-';
          item.balance_filter = _balance < 0 || _balance === 0 ? 'Paid' : 'Not Paid';
          item.presentation_link_comp = item.presentation_link && (
            <Label variant="ghost" color="primary">
              <a target="_blank" href={item.presentation_link} rel="noreferrer">
                Link
              </a>
            </Label>
          );
          item.presentation_link_filter = item.presentation_link ? 'Exist' : 'None';
          item.project_student_comp = item.project_student_complete && (
            <Tooltip title={item.project_student_complete}>
              <p style={{ cursor: 'pointer' }}>{item.project_student} </p>
            </Tooltip>
          );
          item.media_link_comp = item.media_link && (
            <Label variant="ghost" color="primary">
              <a target="_blank" href={item.media_link} rel="noreferrer">
                Link
              </a>
            </Label>
          );
          item.media_link_filter = item.media_link ? 'Exist' : 'None';
          item.created_at_moment = moment(item.created_at).format('YYYY-MM-DD');
          item.delete = (
            <IconButton color="secondary" onClick={() => handleDeleteProject(item.id)}>
              <Icon icon="fluent:delete-48-filled" width={24} height={24} />
            </IconButton>
          );
        });
        dispatch(setLoading(0, ''));
        setTableData(data);
      },
      () => {
        dispatch(setLoading(0, ''));
      }
    );
  }, []);

  function handleDeleteProject(project_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'This action will delete project from group permanently',
        () => {
          deleteJudgingJudgeProject(
            { project_id, judge_id },
            () => {
              handleRefresh();
              dispatch(setSnackbar('success', 'The project has been deleted from judge'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }

  return <MuiTable tableData={tableData} tableColumns={headers} />;
}

ExpandView.propTypes = {
  judge_id: PropTypes.number,
  dispatch: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func
};

export default connect(() => ({}))(ExpandView);
