import * as React from 'react';
import { Grid, Button, DialogContent } from '@mui/material';
import { Stack } from '@material-ui/core';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CustomStudentCard from '../../../../components/CustomComponents/CustomCard';
import { deleteStudent, getStudentList } from '../utils/serviceCalls';
import EditStudentForm from './EditStudentForm';
import AddStudentForm from '../AddPage/AddStudentForm';
import { setConfirmationAlert, setSnackbar } from '../../../../actions/alertActions';
import CustomDialog from '../../../../components/CustomComponents/CustomDialog';
import AddExistingStudentDialog from '../AddPage/AddExistingStudentDialog';
import { handleProjectEditable } from '../utils/termMaker';

function StudentPart(props) {
  const {
    handleNext,
    handleBack,
    project_id,
    dispatch,
    activeCategory,
    active_term_object,
    atReview
  } = props;
  const [formShowEdit, setFormShowEdit] = useState(false);
  const [formShowAdd, setFormShowAdd] = useState(false);
  const [existingStudentAdd, setExistingStudentAdd] = useState(false);
  const [studentList, setStudentList] = useState([]);
  const [studentDataForEdit, setStudentDataForEdit] = useState(null);
  const isEditable = handleProjectEditable(active_term_object);

  useEffect(() => {
    getStudents();
  }, []);
  function getStudents() {
    setFormShowEdit(false);
    setFormShowAdd(false);
    getStudentList(
      { project_id },
      (data) => {
        setStudentList(data);
      },
      () => {}
    );
  }
  function editStudent(student) {
    setStudentDataForEdit(student);
    setFormShowEdit(true);
  }
  function handleDeleteStudent(student_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'This action will delete student from Project, are you sure?',
        () => {
          deleteStudent(
            { student_id, project_id },
            () => {
              getStudents();
              dispatch(setSnackbar('success', 'The student has been deleted'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }

  return (
    <Stack spacing={2} style={{ marginTop: 20 }}>
      {!(formShowEdit || formShowAdd) && studentList.length < activeCategory.allowed_student_count && (
        <Grid item xs={4}>
          <Stack spacing={1} direction="row">
            <Button variant="outlined" onClick={() => setFormShowAdd(true)} disabled={atReview}>
              Add new Student
            </Button>
            <Button
              disabled={atReview}
              variant="outlined"
              color="secondary"
              onClick={() => setExistingStudentAdd(true)}
            >
              Select Student
            </Button>
          </Stack>
        </Grid>
      )}
      <Grid container spacing={2}>
        {studentList.length > 0 &&
          studentList.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <CustomStudentCard
                data={item}
                editStudent={editStudent}
                deleteStudent={handleDeleteStudent}
                isEditable={isEditable}
                atReview={atReview}
              />
            </Grid>
          ))}
      </Grid>
      <CustomDialog
        onClose={() => setFormShowEdit(false)}
        open={formShowEdit}
        maxWidth="lg"
        fullWidth
        title="Update Student"
      >
        <DialogContent>
          <EditStudentForm
            studentData={studentDataForEdit}
            setFormShow={setFormShowEdit}
            handleRefresh={getStudents}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        onClose={() => setFormShowAdd(false)}
        open={formShowAdd}
        maxWidth="lg"
        fullWidth
        title="Add New Student"
      >
        <DialogContent>
          <AddStudentForm setFormShow={setFormShowAdd} handleRefresh={getStudents} />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        onClose={() => setFormShowAdd(false)}
        open={existingStudentAdd}
        maxWidth="lg"
        fullWidth
        title="Add Existing Student"
      >
        <DialogContent>
          <AddExistingStudentDialog
            addedStudentList={studentList}
            handleClose={() => setExistingStudentAdd(false)}
            handleRefresh={getStudents}
          />
        </DialogContent>
      </CustomDialog>
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Button variant="outlined" color="inherit" sx={{ mr: 1 }} onClick={handleBack}>
          Back
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button onClick={handleNext} variant="outlined" disabled={studentList.length === 0}>
          Next
        </Button>
      </Box>
    </Stack>
  );
}

StudentPart.propTypes = {
  handleBack: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  project_id: PropTypes.number,
  activeCategory: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  active_term_object: PropTypes.object,
  atReview: PropTypes.bool
};

export default connect((store) => ({
  project_id: store.projectReducer.activeProject,
  activeCategory: store.projectReducer.activeCategory,
  active_term_object: store.loginReducer.active_term_object
}))(StudentPart);
