import React, { useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { ThemeProvider } from '@mui/styles';
import PropTypes from 'prop-types';
import getMuiTheme from './utils/getMuiTheme';
import getMuiThemeForNote from './utils/getMuiThemeForCsnNote';
import { tableOptions } from './utils/tableOptions';
import ReadMore from '../ReadMore';

function MuiTable(props) {
  const {
    tableData,
    title,
    tableColumns,
    customOption,
    rowsPerPage,
    components,
    readMoreDisable,
    noteTable
  } = props;
  const [sortOrder, setSortOrder] = useState({});

  tableColumns.forEach((column) => {
    const isCellShouldTranslate =
      column.translate ||
      ((column?.name.toLowerCase().includes('name') ||
        column?.name.toLowerCase().includes('title')) &&
        'no');
    column.options = {
      customFilterListOptions: {
        render: (v) => `${column.label}: ${v || 'Blanks'}`
      },
      filterOptions: {
        renderValue: (v) => v || 'Blanks'
      },
      customBodyRender: (value) => {
        const type = typeof value;
        return readMoreDisable ? (
          value
        ) : type === 'string' ? (
          <ReadMore text={value} readMoreCharacterLimit={20} />
        ) : (
          value
        );
      },
      setCellProps: () => ({
        translate: isCellShouldTranslate
      }),
      ...column.options
    };
  });

  return (
    <ThemeProvider theme={noteTable ? getMuiThemeForNote : getMuiTheme}>
      <MUIDataTable
        title={title}
        data={tableData}
        columns={tableColumns}
        options={{
          ...tableOptions,
          ...customOption,
          rowsPerPage,
          onColumnSortChange: (changedColumn, direction) => {
            setSortOrder({ name: changedColumn, direction });
          },
          sortOrder
        }}
        components={components}
      />
    </ThemeProvider>
  );
}

MuiTable.propTypes = {
  tableData: PropTypes.array,
  title: PropTypes.object,
  tableColumns: PropTypes.array,
  customOption: PropTypes.object,
  rowsPerPage: PropTypes.number,
  components: PropTypes.object,
  readMoreDisable: PropTypes.bool,
  noteTable: PropTypes.bool
};

export default MuiTable;
