export default [
  {
    name: 'judging_group_id',
    label: 'ID'
  },
  {
    name: 'group_number',
    label: 'Group Number'
  },
  {
    name: 'discipline',
    label: 'Discipline'
  },
  {
    name: 'judging_session_type',
    label: 'Session Type'
  },
  {
    name: 'judging_session',
    label: 'Session Name'
  },
  {
    name: 'judging_group_capacity',
    label: 'Group Capacity'
  },
  {
    name: 'number_of_projects',
    label: 'Number of Projects'
  },
  {
    name: 'electricity_access_comp',
    label: 'Electricity Access'
  },
  {
    name: 'project',
    label: 'Add Project',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'edit',
    label: 'Edit',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'delete',
    label: 'Delete',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  }
];
