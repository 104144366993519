import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PropTypes from 'prop-types';
import PaymentForm from './PaymentForm';

const stripeTestPromise = loadStripe(
  process.env.REACT_APP_CUSTOM_ENV === 'prod'
    ? process.env.REACT_APP_STRIPE_LIVE_KEY
    : process.env.REACT_APP_STRIPE_TEST_KEY
);

function Index(props) {
  const {
    setSuccess,
    handleClose,
    type,
    discountList,
    totalDiscount,
    netBalance,
    external,
    fromGeneralPayments
  } = props;
  return (
    <Elements stripe={stripeTestPromise} options={{ locale: 'en' }}>
      <PaymentForm
        fromGeneralPayments={fromGeneralPayments}
        external={external}
        netBalance={netBalance}
        setSuccess={setSuccess}
        handleClose={handleClose}
        type={type}
        discountList={discountList}
        totalDiscount={totalDiscount}
      />
    </Elements>
  );
}

Index.propTypes = {
  handleClose: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  discountList: PropTypes.array,
  totalDiscount: PropTypes.number,
  netBalance: PropTypes.number,
  external: PropTypes.bool,
  fromGeneralPayments: PropTypes.bool
};

export default Index;
