import React, { useEffect, useState } from 'react';
import { IconButton, MenuItem, Stack, TextField } from '@material-ui/core';
import { Button, DialogContent, Tooltip } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Email from '@mui/icons-material/Email';
import moment from 'moment';
import { Icon } from '@iconify/react';
import CircularProgress from '@mui/material/CircularProgress';
import ReactHtmlParser from 'react-html-parser';
import MuiTable from '../../../../components/CustomComponents/MuiTable';
import headers from './utils/headerVariables';
import { downloadPerformanceFile, getPerformanceOperationList } from '../serviceCalls';
import Page from '../../../../components/Page';
import CustomDialog from '../../../../components/CustomComponents/CustomDialog';
import StatusDialog from './utils/StatusDialog';
import { CUSTOM_MAIL_TYPE } from '../../../../api/RestVariables/staticVariables';
import CustomMail from '../../../../components/CustomComponents/CustomMail';
import Label from '../../../../components/Label';
import ProcessInfoBox from '../../../../components/CustomComponents/CustomCard/ProcessInfoBox';
import UpdateDialog from './Components/UpdateDialog';
import FileDialog from './Components/FileDialog';

function Index(props) {
  const { terms, active_term, isCommunication } = props;
  const [term, setTerm] = useState(active_term);
  const [tableData, setTableData] = useState([]);
  const [mailDialogShow, setMailDialogShow] = useState(false);
  const [actionDialog, setActionDialog] = useState(false);
  const [updateDialog, setUpdateDialog] = useState(false);
  const [fileDialog, setFileDialog] = useState(false);
  const [selectedPerformance, setSelectedPerformance] = useState(null);
  const [addMail, setAddMail] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [performanceId, setPerformanceId] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const [idList, setIdList] = useState([]);
  const [guidelines, setGuidelines] = useState([]);

  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedItems(rowsSelected);
    const arr = [];
    rowsSelected.forEach((item) => {
      arr.push(tableData[item].created_by_user_id);
    });
    setIdList(arr);
  };

  useEffect(() => {
    fetchData();
  }, [term]);
  function fetchData() {
    getPerformanceOperationList(
      { term_id: term },
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((item) => {
          const file_ids = item.performance_file_id?.split(',');
          const file_names = item.performance_file?.split(',');
          item.created_at_comp = moment(item.created_at).format('YYYY-MM-DD');
          item.participants_comp = item.performance_participant_complete && (
            <Tooltip title={item.performance_participant_complete}>
              <p style={{ cursor: 'pointer' }}>{item.performance_participant} </p>
            </Tooltip>
          );
          item.created_by = `${item.created_by_first_name} ${item.created_by_last_name}`;
          item.file_comp = (
            <Stack direction={{ xs: 'row', sm: 'column' }} spacing={2}>
              {file_ids?.map((item, index) => (
                <Label
                  key={index}
                  style={{ cursor: 'pointer' }}
                  variant="ghost"
                  color="secondary"
                  onClick={() => downloadFile(item, file_names[index])}
                >
                  {`File ${index + 1}`}
                </Label>
              ))}
            </Stack>
          );
          item.performance_participant_comp = item.performance_participant_complete && (
            <Tooltip title={item.performance_participant_complete}>
              <p style={{ cursor: 'pointer' }}>{item.performance_participant} </p>
            </Tooltip>
          );
          item.file_filter = item.project_file_id ? 'Exist' : 'None';
          item.media_link_comp = item.media_link && (
            <Label variant="ghost" color="primary">
              <a target="_blank" href={item.media_link} rel="noreferrer">
                Link
              </a>
            </Label>
          );
          item.action = (
            <IconButton color="secondary" onClick={() => handleEditPerformance(item.id)}>
              <Icon icon="mdi:interaction-double-tap" width={24} height={24} />
            </IconButton>
          );
          item.fileUpdate = (
            <IconButton color="secondary" onClick={() => handleEditFile(item.id)}>
              <Icon icon="material-symbols:folder" width={24} height={24} />
            </IconButton>
          );
          item.edit = (
            <IconButton color="secondary" onClick={() => handleUpdatePerformance(item.id)}>
              <Icon icon="ci:edit" width={24} height={24} />
            </IconButton>
          );
        });
        setTableData(list);
      },
      () => {}
    );
  }
  function downloadFile(id, name) {
    setDownloading(true);
    const file_name = name.split('.')[0];
    const param = {
      id,
      file_name
    };
    downloadPerformanceFile(
      param,
      () => {
        setDownloading(false);
      },
      () => {
        setDownloading(false);
      }
    );
  }
  function handleRefresh() {
    setMailDialogShow(false);
    setAddMail(false);
    setSelectedItems([]);
    setActionDialog(false);
    setPerformanceId(null);
    setUpdateDialog(false);
    setFileDialog(false);
    setSelectedPerformance(null);
    fetchData();
  }
  function handleEditPerformance(performance_id) {
    setActionDialog(true);
    setPerformanceId(performance_id);
  }
  function handleUpdatePerformance(performance_id) {
    setSelectedPerformance(performance_id);
    setUpdateDialog(true);
  }
  function handleEditFile(performance_id) {
    setSelectedPerformance(performance_id);
    setFileDialog(true);
  }
  function TermSelect() {
    return (
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 2 }}>
        <TextField select label="Terms" value={term} onChange={(e) => setTerm(e.target.value)}>
          {terms.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
        {addMail && (
          <div>
            <Button
              style={{ marginRight: 5 }}
              disabled={selectedItems.length === 0}
              variant="outlined"
              onClick={() => setMailDialogShow(true)}
            >
              Next
            </Button>
            <Button color="error" variant="outlined" onClick={() => setAddMail(false)}>
              Cancel
            </Button>
          </div>
        )}
        {downloading && (
          <Stack
            spacing={1}
            direction={{ xs: 'column', sm: 'row' }}
            sx={{ mr: 1, display: 'flex', alignItems: 'center' }}
          >
            <p style={{ fontWeight: 'bold' }}>Downloading...</p>
            <CircularProgress size={20} />
          </Stack>
        )}
      </Stack>
    );
  }

  function handleClose() {
    setUpdateDialog(false);
    setFileDialog(false);
    setSelectedPerformance(null);
  }

  return (
    <Page title="Performance Operation | Genius">
      {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
      <MuiTable
        tableData={tableData}
        tableColumns={headers}
        title={<TermSelect />}
        customOption={{
          selectableRows: addMail ? 'multiple' : 'none',
          onRowSelectionChange,
          rowsSelected: selectedItems,
          customToolbar: () =>
            isCommunication !== -1 && (
              <Tooltip title="Send Email">
                <IconButton onClick={() => setAddMail(true)}>
                  <Email />
                </IconButton>
              </Tooltip>
            )
        }}
      />
      <CustomDialog
        onClose={() => setMailDialogShow(false)}
        open={mailDialogShow}
        maxWidth="lg"
        title="Send Mail"
        fullWidth
        disableEnforceFocus
      >
        <DialogContent>
          <CustomMail
            handleRefresh={handleRefresh}
            mailType={CUSTOM_MAIL_TYPE}
            idList={idList}
            handleClose={() => setMailDialogShow(false)}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        onClose={() => setActionDialog(false)}
        open={actionDialog}
        maxWidth="md"
        title="Approve/Deny"
      >
        <DialogContent>
          <StatusDialog
            handleRefresh={handleRefresh}
            performanceId={performanceId}
            handleClose={() => setActionDialog(false)}
          />
        </DialogContent>
      </CustomDialog>
      {updateDialog && (
        <UpdateDialog
          handleClose={handleClose}
          selectedPerformance={selectedPerformance}
          handleSuccess={handleRefresh}
        />
      )}
      {fileDialog && (
        <FileDialog
          handleClose={handleClose}
          selectedPerformance={selectedPerformance}
          handleRefresh={handleRefresh}
        />
      )}
    </Page>
  );
}

Index.propTypes = {
  terms: PropTypes.array,
  active_term: PropTypes.number,
  isCommunication: PropTypes.number
};

export default connect((store) => ({
  terms: store.loginReducer.terms,
  active_term: store.loginReducer.active_term,
  isCommunication: store.routeReducer.isCommunication
}))(Index);
