export default [
  {
    name: 'name',
    label: 'Name'
  },
  {
    name: 'description',
    label: 'Description'
  },
  {
    name: 'fee',
    label: 'Fee'
  }
];
