const HOST = process.env.REACT_APP_REST_API_HOST;

const GET_GENDER = {
  method: 'GET',
  path: `${HOST}go_core/project/common/gender`
};
const GET_DISCIPLINE = {
  method: 'GET',
  path: `${HOST}go_core/project/common/discipline`
};
const GET_DISCIPLINE_FILE_TYPE = {
  method: 'GET',
  path: `${HOST}go_core/project/common/discipline_file_type`
};
const GET_CATEGORY = {
  method: 'GET',
  path: `${HOST}go_core/project/common/category`
};
const GET_AFFILIATED_FAIR = {
  method: 'GET',
  path: `${HOST}go_core/project/common/fair`
};
const ADD_AFFILIATED_FAIR = {
  method: 'POST',
  path: `${HOST}go_core/project/info/fair_request`
};
const DELETE_AFFILIATED_FAIR = {
  method: 'DELETE',
  path: `${HOST}go_core/project/info/fair_request`
};
const GET_AFFILIATED_FAIR_REQ = {
  method: 'GET',
  path: `${HOST}go_core/project/info/fair_request`
};
const GET_INFO = {
  method: 'GET',
  path: `${HOST}go_core/project/info/project_info`
};
const ADD_INFO = {
  method: 'POST',
  path: `${HOST}go_core/project/info/general_info`
};
const UPDATE_INFO = {
  method: 'PUT',
  path: `${HOST}go_core/project/info/general_info`
};
const DELETE_INFO = {
  method: 'DELETE',
  path: `${HOST}go_core/project/info/general_info`
};
const GET_STUDENT = {
  method: 'GET',
  path: `${HOST}go_core/project/info/student`
};
const ADD_STUDENT = {
  method: 'POST',
  path: `${HOST}go_core/project/info/student`
};
const ADD_EXISTING_STUDENT = {
  method: 'POST',
  path: `${HOST}go_core/project/info/existing_student`
};
const UPDATE_STUDENT = {
  method: 'PUT',
  path: `${HOST}go_core/project/info/student`
};
const DELETE_STUDENT = {
  method: 'DELETE',
  path: `${HOST}go_core/project/info/student`
};
const GET_INFO_LIST = {
  method: 'GET',
  path: `${HOST}go_core/project/info/list`
};
const GET_STUDENT_LIST = {
  method: 'GET',
  path: `${HOST}go_core/project/info/project_student`
};
const PAYMENT = {
  method: 'POST',
  path: `${HOST}go_core/project/payment`
};
const ADD_FILE = {
  method: 'POST',
  path: `${HOST}go_core/project/info/file`
};
const UPDATE_FILE = {
  method: 'PUT',
  path: `${HOST}go_core/project/info/file`
};
const GET_FILE = {
  method: 'GET',
  path: `${HOST}go_core/project/info/file`
};
const DELETE_FILE = {
  method: 'DELETE',
  path: `${HOST}go_core/project/info/file`
};
const GET_FILE_LIST = {
  method: 'GET',
  path: `${HOST}go_core/project/info/file_list`
};
const GET_CHECK_LIST = {
  method: 'GET',
  path: `${HOST}go_core/project/info/check_list`
};

module.exports = {
  GET_AFFILIATED_FAIR_REQ,
  GET_FILE_LIST,
  GET_FILE,
  ADD_FILE,
  UPDATE_FILE,
  DELETE_FILE,
  GET_GENDER,
  GET_DISCIPLINE,
  GET_CATEGORY,
  GET_AFFILIATED_FAIR,
  ADD_AFFILIATED_FAIR,
  DELETE_AFFILIATED_FAIR,
  GET_INFO,
  ADD_INFO,
  UPDATE_INFO,
  DELETE_INFO,
  ADD_EXISTING_STUDENT,
  GET_STUDENT,
  ADD_STUDENT,
  UPDATE_STUDENT,
  DELETE_STUDENT,
  GET_INFO_LIST,
  GET_STUDENT_LIST,
  PAYMENT,
  GET_CHECK_LIST,
  GET_DISCIPLINE_FILE_TYPE
};
