import React, { useRef, useMemo } from 'react';
import JoditEditor from 'jodit-react';
import { connect } from 'react-redux';

const MarkdownEditor = (props) => {
  const { markdown, setMarkdown } = props;
  const editor = useRef(null);

  const config = {
    iframe: true,
    uploader: {
      insertImageAsBase64URI: true,
      error: () => {
        console.log('The image file format you are trying to add is not appropriate!');
      }
    },
    buttons: [
      'bold',
      'italic',
      'underline',
      'strikethrough',
      'eraser',
      'ul',
      'ol',
      'font',
      'fontsize',
      'paragraph',
      'classSpan',
      'lineHeight',
      'superscript',
      'subscript',
      'cut',
      'copy',
      'paste',
      'indent',
      'outdent'
    ],
    readonly: false,
    toolbarAdaptive: false
  };

  return useMemo(
    () => (
      <JoditEditor
        ref={editor}
        value={markdown}
        config={config}
        onBlur={(newContent) => setMarkdown(newContent)}
      />
    ),
    [markdown]
  );
};

export default connect(() => ({}))(MarkdownEditor);
