import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MenuItem, Paper } from '@material-ui/core';
import { Button, Stack } from '@mui/material';
import ListItemText from '@material-ui/core/ListItemText';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { assignReview } from '../../serviceCalls';
import { setSnackbar } from '../../../../../actions/alertActions';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const AddReviewerDialog = (props) => {
  const { reviewerList, handleClose, selectedItems, tableData, dispatch, handleRefresh } = props;
  const [selectedReviewers, setSelectedReviewers] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value }
    } = event;
    setSelectedReviewers(typeof value === 'string' ? value.split(',') : value);
  };
  function handleAssign() {
    const project_ids = [];
    selectedItems.forEach((item) => {
      project_ids.push(tableData[item].id);
    });
    assignReview(
      { project_ids, reviewer_ids: selectedReviewers },
      () => {
        dispatch(setSnackbar('success', 'The reviewers has been assigned'));
        handleRefresh();
      },
      () => {}
    );
  }

  return (
    <Stack spacing={2}>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 1 }}>
        <FormControl>
          <InputLabel id="demo-multiple-checkbox-label">Reviewers</InputLabel>
          <Select
            sx={{ minWidth: 200 }}
            fullWidth
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={selectedReviewers}
            onChange={handleChange}
            input={<OutlinedInput label="Discipline" />}
            renderValue={(selected) => {
              const strList = [];
              selected.forEach((item) => {
                const el = reviewerList.find((x) => x.user_id === item);
                // eslint-disable-next-line no-unused-expressions
                el && strList.push(el.first_name);
              });
              return strList.join(', ');
            }}
            MenuProps={MenuProps}
          >
            {reviewerList.map((item) => (
              <MenuItem key={item.user_id} value={item.user_id}>
                <Checkbox checked={selectedReviewers.indexOf(item.user_id) > -1} />
                <ListItemText primary={item.first_name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className="header-button-style">
          <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="end" spacing={1}>
            <Button
              onClick={handleAssign}
              variant="outlined"
              disabled={selectedReviewers.length === 0}
            >
              Assign
            </Button>
            <Button variant="outlined" color="error" onClick={handleClose}>
              Cancel
            </Button>
          </Stack>
        </div>
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow style={{ background: 'gainsboro' }}>
              <TableCell style={{ width: '20%' }}>ID</TableCell>
              <TableCell style={{ width: '40%' }}>Project Title</TableCell>
              <TableCell style={{ width: '40%' }}>Discipline</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedItems.map((row, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {tableData[row].id}
                </TableCell>
                <TableCell>{tableData[row].title}</TableCell>
                <TableCell>{tableData[row].discipline}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

AddReviewerDialog.propTypes = {
  reviewerList: PropTypes.array,
  selectedItems: PropTypes.array,
  tableData: PropTypes.array,
  handleClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  handleRefresh: PropTypes.func
};

export default connect(() => ({}))(AddReviewerDialog);
