import * as React from 'react';
import { useState } from 'react';
import { Button, Stack } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import MuiTable from '../../../../components/CustomComponents/MuiTable';
import getHeaders from './utils/headerVariablesParticipantList';
import { addVip } from '../serviceCalls';

function ParticipantDialog(props) {
  const { participantList, handleClose, handleRefresh } = props;
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [reason, setReason] = useState('');
  const headers = getHeaders(participantList);
  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedStudent(rowsSelected);
  };
  function handleAddVip() {
    const param = {
      participant_id: participantList[selectedStudent].id,
      description: reason
    };
    addVip(
      param,
      () => {
        handleRefresh();
      },
      () => {}
    );
  }

  return (
    <Stack spacing={3}>
      <MuiTable
        tableData={participantList}
        tableColumns={headers}
        customOption={{
          selectableRows: 'single',
          onRowSelectionChange
        }}
      />
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="end">
        {selectedStudent && (
          <TextField
            style={{ maxWidth: '50%' }}
            fullWidth
            label="Reason"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        )}
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="end">
          <Button disabled={reason === ''} variant="outlined" onClick={handleAddVip}>
            Add
          </Button>
          <Button variant="outlined" color="error" onClick={handleClose}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}

ParticipantDialog.propTypes = {
  participantList: PropTypes.array,
  handleClose: PropTypes.func,
  handleRefresh: PropTypes.func
};

export default connect(() => ({}))(ParticipantDialog);
