import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@mui/material/Alert';

const useStyles = {
  error: {
    color: '#ffffff',
    backgroundColor: '#B63333'
  },
  warning: {
    color: '#ffffff',
    backgroundColor: '#D16C1B'
  },
  success: {
    color: '#ffffff',
    backgroundColor: '#507B3A'
  },
  info: {
    color: '#ffffff',
    backgroundColor: '#4C86CD'
  }
};

const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

const CustomizedSnackbars = (props) => {
  const { settings } = props;
  console.log(settings, 'snackbarSettings');
  const { type } = settings;
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      open
      autoHideDuration={15456000}
      onClose={() => settings.onHide()}
    >
      <Alert severity={settings.type} style={useStyles[type]}>
        {settings.message}
      </Alert>
    </Snackbar>
  );
};

CustomizedSnackbars.propTypes = {
  settings: PropTypes.object
};

export default CustomizedSnackbars;
