import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Button, DialogContent, Tooltip } from '@mui/material';
import { IconButton, Stack, TableCell } from '@material-ui/core';
import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow';
import Email from '@mui/icons-material/Email';
import ReactHtmlParser from 'react-html-parser';
import MuiTable from '../../../../../components/CustomComponents/MuiTable';
import headerVariables from './headerVariables';
import { deleteGroupProject, getProjectList } from '../../serviceCalls';
import { setConfirmationAlert, setLoading, setSnackbar } from '../../../../../actions/alertActions';
import Label from '../../../../../components/Label';
import { fCurrency } from '../../../../../utils/formatNumber';
import CustomDialog from '../../../../../components/CustomComponents/CustomDialog';
import AssignProjectDialog from './AssignProjectDialog';
import CustomMail from '../../../../../components/CustomComponents/CustomMail';
import { CUSTOM_MAIL_TYPE } from '../../../../../api/RestVariables/staticVariables';
import ExpandView from '../../Judging/JudgeMode/ExpandView/ExpandView';
import ProcessInfoBox from '../../../../../components/CustomComponents/CustomCard/ProcessInfoBox';

function ProjectMode(props) {
  const { dispatch, isCommunication } = props;
  const [tableData, setTableData] = useState();
  const [assignDialog, setAssignDialog] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [mailDialogShow, setMailDialogShow] = useState(false);
  const [addMail, setAddMail] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [idList, setIdList] = useState([]);
  const [guidelines, setGuidelines] = useState([]);

  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedItems(rowsSelected);
    const arr = [];
    rowsSelected.forEach((item) => {
      arr.push(tableData[item].user_id);
    });
    setIdList(arr);
  };

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    dispatch(setLoading(1, 'Loading...'));
    getProjectList(
      {},
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((item) => {
          const _balance = Number(parseFloat(item.balance).toFixed(2));
          item.balance_comp =
            _balance < 0 || _balance === 0 ? (
              <Label variant="ghost" color="success">
                {fCurrency(item.balance)}
              </Label>
            ) : (
              <Label variant="ghost" color="error">
                {fCurrency(item.balance)}
              </Label>
            );
          item.balance_filter = _balance < 0 || _balance === 0 ? 'Paid' : 'Not Paid';
          item.project_student_comp = item.project_student_complete && (
            <Tooltip title={item.project_student_complete}>
              <p style={{ cursor: 'pointer' }}>{item.project_student} </p>
            </Tooltip>
          );
          item.presentation_link_comp = item.presentation_link && (
            <Label variant="ghost" color="primary">
              <a target="_blank" href={item.presentation_link} rel="noreferrer">
                Link
              </a>
            </Label>
          );
          item.presentation_link_filter = item.presentation_link ? 'Exist' : 'None';
          item.media_link_comp = item.media_link && (
            <Label variant="ghost" color="primary">
              <a target="_blank" href={item.media_link} rel="noreferrer">
                Link
              </a>
            </Label>
          );
          item.media_link_filter = item.media_link ? 'Exist' : 'None';
          item.created_at_moment = moment(item.created_at).format('YYYY-MM-DD');
          item.assign = (
            <IconButton
              disabled={item.group_number}
              color="secondary"
              onClick={() => handleAssignProject(item)}
            >
              <Icon icon="ic:outline-assignment-turned-in" width={24} height={24} />
            </IconButton>
          );
          item.assign_filter = item.group_number ? 'Yes' : 'No';
          item.delete = (
            <IconButton
              disabled={!item.group_number}
              color="secondary"
              onClick={() => handleDeleteProject(item.id)}
            >
              <Icon icon="fluent:delete-48-filled" width={24} height={24} />
            </IconButton>
          );
        });
        setTableData(list);
        dispatch(setLoading(0, ''));
      },
      () => {
        dispatch(setLoading(0, ''));
      }
    );
  }

  function handleDeleteProject(project_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'This action will delete project from group permanently',
        () => {
          deleteGroupProject(
            { project_id },
            () => {
              fetchData();
              dispatch(setSnackbar('success', 'The project has been deleted from group'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }

  function handleAssignProject(project) {
    setAssignDialog(true);
    setSelectedProject(project);
  }

  function handleRefresh() {
    setAssignDialog(false);
    fetchData();
  }

  function TermSelect() {
    return (
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 2 }}>
        {addMail && (
          <div>
            <Button
              style={{ marginRight: 5 }}
              disabled={selectedItems.length === 0}
              variant="outlined"
              onClick={() => setMailDialogShow(true)}
            >
              Next
            </Button>
            <Button color="error" variant="outlined" onClick={() => setAddMail(false)}>
              Cancel
            </Button>
          </div>
        )}
      </Stack>
    );
  }

  return (
    <Stack spacing={1}>
      {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
      <MuiTable
        tableColumns={headerVariables}
        tableData={tableData}
        title={<TermSelect />}
        customOption={{
          selectableRows: addMail ? 'multiple' : 'none',
          onRowSelectionChange,
          rowsSelected: selectedItems,
          expandableRows: true,
          renderExpandableRow: (rowData) => {
            const colSpan = rowData.length + 1;
            return (
              <TableRow>
                <TableCell colSpan={colSpan}>
                  <ExpandView judge_id={rowData[0]} handleRefresh={handleRefresh} />
                </TableCell>
              </TableRow>
            );
          },
          customToolbar: () =>
            isCommunication !== -1 && (
              <Tooltip title="Send Email">
                <IconButton onClick={() => setAddMail(true)}>
                  <Email />
                </IconButton>
              </Tooltip>
            )
        }}
      />
      <CustomDialog
        onClose={() => setAssignDialog(false)}
        open={assignDialog}
        title="Assign Project"
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          <AssignProjectDialog
            data={selectedProject}
            handleClose={() => setAssignDialog(null)}
            refreshData={handleRefresh}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        onClose={() => setMailDialogShow(false)}
        open={mailDialogShow}
        maxWidth="lg"
        title="Send Mail"
        fullWidth
        disableEnforceFocus
      >
        <DialogContent>
          <CustomMail
            handleRefresh={handleRefresh}
            mailType={CUSTOM_MAIL_TYPE}
            idList={idList}
            handleClose={() => setMailDialogShow(false)}
          />
        </DialogContent>
      </CustomDialog>
    </Stack>
  );
}

ProjectMode.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isCommunication: PropTypes.number
};

export default connect((store) => ({
  isCommunication: store.routeReducer.isCommunication
}))(ProjectMode);
