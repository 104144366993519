import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Stack, TextField } from '@material-ui/core';
import * as React from 'react';
import { Button, Grid } from '@mui/material';
import Typography from '@material-ui/core/Typography';
import { useState, useEffect } from 'react';
import { setSnackbar } from '../../../../actions/alertActions';
import { updatePartnerConfig } from '../_apis/serviceCalls';

function CapacityDialog(props) {
  const { data, dispatch, handleRefresh, term_id, handleClose } = props;
  const [disciplineParam, setDisciplineParam] = useState([]);

  useEffect(() => {
    const arr = [];
    data.discipline_capacities.forEach((item) => {
      const obj = {
        discipline_id: item.discipline_id,
        capacity: item.capacity,
        discipline_name: item.discipline
      };
      arr.push(obj);
    });
    setDisciplineParam(arr);
  }, []);

  function handleChange(event) {
    const { name, value } = event.target;
    const newArr = [];
    disciplineParam.forEach((item) => {
      const obj =
        Number(item.discipline_id) === Number(name)
          ? {
              discipline_id: name,
              capacity: value,
              discipline_name: item.discipline_name
            }
          : item;
      newArr.push(obj);
    });
    setDisciplineParam(newArr);
  }
  function handleApprove() {
    const param = {
      term_id,
      partner_fair_id: data.id,
      disciplines: disciplineParam
    };
    updatePartnerConfig(
      param,
      () => {
        handleRefresh();
        dispatch(setSnackbar('success', 'Partner Fair has been approved'));
      },
      () => {}
    );
  }

  return (
    <Stack spacing={1} sx={{ mt: 1 }}>
      <Grid container>
        {disciplineParam.map((item, index) => (
          <Grid item xs={6} key={index} style={{ marginBottom: 15 }}>
            <Stack direction="row" spacing={1} style={{ alignItems: 'baseline' }}>
              <Typography gutterBottom align="left" variant="subtitle1" style={{ minWidth: 150 }}>
                {item.discipline_name}
              </Typography>
              <TextField
                InputProps={{ shrink: false }}
                type="number"
                label="Capacity"
                style={{ maxWidth: 100 }}
                name={item.discipline_id}
                value={item.capacity}
                onChange={(event) => {
                  handleChange(event);
                }}
              />
            </Stack>
          </Grid>
        ))}
      </Grid>
      <Stack direction="row" justifyContent="end" spacing={1}>
        <Button size="small" onClick={handleApprove} variant="outlined">
          Update
        </Button>
        <Button size="small" color="error" onClick={handleClose} variant="outlined">
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
}

CapacityDialog.propTypes = {
  data: PropTypes.array.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  term_id: PropTypes.number,
  handleClose: PropTypes.func
};

export default connect(() => ({}))(CapacityDialog);
