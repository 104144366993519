import { MenuItem, Stack, TextField } from '@material-ui/core';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@material-ui/lab';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useState } from 'react';
import { setLoading, setSnackbar } from '../../../../../../actions/alertActions';
import { copySurvey } from '../../../_apis/serviceCalls';

function CopyDialog(props) {
  const { data, handleClose, dispatch, refreshData, active_term, terms } = props;
  const [term, setTerm] = useState(active_term);

  const Schema = Yup.object().shape({
    target_name: Yup.string().required('Name is required')
  });
  const formik = useFormik({
    initialValues: {
      target_name: ''
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      dispatch(setLoading(1, 'Loading...'));
      copySurvey(
        { ...values, survey_id: data.survey_id, target_term_id: term },
        () => {
          dispatch(setLoading(0, ''));
          refreshData();
          dispatch(setSnackbar('success', 'The survey has been copied'));
        },
        () => {
          dispatch(setLoading(0, ''));
        }
      );
    }
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{ mt: 1 }}>
          <TextField
            select
            label="Target Term"
            value={term}
            onChange={(e) => setTerm(e.target.value)}
          >
            {terms.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.description}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            label="Target Name"
            {...getFieldProps('target_name')}
            error={Boolean(touched.target_name && errors.target_name)}
            helperText={touched.target_name && errors.target_name}
          />
          <Stack direction="row" justifyContent="end" spacing={1}>
            <LoadingButton size="small" type="submit" variant="outlined">
              Copy
            </LoadingButton>
            <Button size="small" color="error" onClick={handleClose} variant="outlined">
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

CopyDialog.propTypes = {
  data: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  terms: PropTypes.array.isRequired,
  active_term: PropTypes.number.isRequired
};

export default connect((store) => ({
  terms: store.loginReducer.terms,
  active_term: store.loginReducer.active_term
}))(CopyDialog);
