import { Button, DialogContent, Tooltip } from '@mui/material';
import { IconButton, MenuItem, Stack, TableCell, TextField } from '@material-ui/core';
import Email from '@mui/icons-material/Email';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Icon } from '@iconify/react';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import MuiTable from '../../../../../components/CustomComponents/MuiTable';
import headers from './headerVariables';
import CustomDialog from '../../../../../components/CustomComponents/CustomDialog';
import CustomMail from '../../../../../components/CustomComponents/CustomMail';
import { PROJECT_MAIL_TYPE } from '../../../../../api/RestVariables/staticVariables';
import AddJudgeDialog from './AddJudge/AddJudgeDialog';
import ExpandView from './ExpandView/ExpandView';
import { getJudgingProject, getJudgingProjectBadge } from '../_apis/serviceCalls';
import Label from '../../../../../components/Label';
import { fCurrency } from '../../../../../utils/formatNumber';
import ProcessInfoBox from '../../../../../components/CustomComponents/CustomCard/ProcessInfoBox';
import { setSnackbar } from '../../../../../actions/alertActions';

function Index(props) {
  const { terms, active_term, isCommunication, dispatch } = props;
  const [tableData, setTableData] = useState([]);
  const [editData, setEditData] = useState(null);
  const [term, setTerm] = useState(active_term);
  const [mailDialogShow, setMailDialogShow] = useState(false);
  const [addJudgeDialogShow, setAddJudgeDialogShow] = useState(false);
  const [addMail, setAddMail] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [projectIdList, setProjectIdList] = useState([]);
  const [guidelines, setGuidelines] = useState([]);
  const [badge, setBadge] = useState(false);

  useEffect(() => {
    fetchData();
  }, [term]);

  function fetchData() {
    getJudgingProject(
      { term_id: term },
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((item) => {
          item.electricity_needed_comp = item.electricity_needed ? 'Yes' : 'No';
          const _balance = Number(parseFloat(item.balance).toFixed(2));
          item.balance_comp =
            _balance < 0 || _balance === 0 ? (
              <Label variant="ghost" color="success">
                {fCurrency(item.balance)}
              </Label>
            ) : (
              <Label variant="ghost" color="error">
                {fCurrency(item.balance)}
              </Label>
            );
          item.balance_filter = _balance < 0 || _balance === 0 ? 'Paid' : 'Not Paid';
          item.project_student_comp = item.project_student_complete && (
            <Tooltip title={item.project_student_complete}>
              <p style={{ cursor: 'pointer' }}>{item.project_student} </p>
            </Tooltip>
          );
          item.presentation_link_comp = item.presentation_link && (
            <Label variant="ghost" color="primary">
              <a target="_blank" href={item.presentation_link} rel="noreferrer">
                Link
              </a>
            </Label>
          );
          item.presentation_link_filter = item.presentation_link ? 'Exist' : 'None';
          item.media_link_comp = item.media_link && (
            <Label variant="ghost" color="primary">
              <a target="_blank" href={item.media_link} rel="noreferrer">
                Link
              </a>
            </Label>
          );
          item.media_link_filter = item.media_link ? 'Exist' : 'None';
          item.created_at_moment = moment(item.created_at).format('YYYY-MM-DD');
          item.average_score_comp = item.average_score && parseFloat(item.average_score).toFixed(2);
          item.paid_attended_student_count_comp =
            Number(item.paid_attended_student_count) > 0 ? (
              <Tooltip title="Paid">
                <IconButton disabled className="Success-icon-color">
                  <Icon icon="akar-icons:circle-check" width={24} height={24} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Not Paid">
                <IconButton disabled className="Error-icon-color">
                  <Icon color="error" icon="akar-icons:circle-x" width={24} height={24} />
                </IconButton>
              </Tooltip>
            );
          item.paid_attended_student_count_filter =
            Number(item.paid_attended_student_count) > 0 ? 'Paid' : 'Not Paid';
          item.assign = (
            <IconButton
              disabled={!item.group_number}
              color="secondary"
              onClick={() => handleAssignJudge(item)}
            >
              <Icon icon="clarity:assign-user-line" width={24} height={24} />
            </IconButton>
          );
        });
        setTableData(list);
      },
      () => {}
    );
  }

  function handleAssignJudge(project) {
    setEditData(project);
    setAddJudgeDialogShow(true);
  }

  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedItems(rowsSelected);
    const arr2 = [];
    rowsSelected.forEach((item) => {
      arr2.push(tableData[item].id);
    });
    setProjectIdList(arr2);
  };

  function handleDownloadBadge() {
    const params = {
      project_ids: projectIdList
    };
    getJudgingProjectBadge(
      params,
      (data) => {
        if (!data?.result.url) {
          dispatch(
            setSnackbar(
              'warning',
              'Badge files are not ready for all selected participants. You will be notified by e-mail when it is completed.'
            )
          );
        }
        handleCancel();
      },
      (error) => {
        console.log(error);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  function TermSelect() {
    return (
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 2 }}>
        <TextField select label="Terms" value={term} onChange={(e) => setTerm(e.target.value)}>
          {terms.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
        {!addMail && !badge && (
          <div className="header-button-style">
            <Button color="secondary" variant="outlined" onClick={() => setBadge(true)}>
              Badge
            </Button>
          </div>
        )}
        {badge && (
          <div className="header-button-style">
            <Button
              color="primary"
              variant="outlined"
              disabled={startButtonDisabled}
              onClick={handleDownloadBadge}
            >
              Download Badge
            </Button>
            <Button
              variant="outlined"
              color="error"
              style={{ marginLeft: 10 }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        )}
        {addMail && !badge && (
          <div>
            <Button
              style={{ marginRight: 5 }}
              disabled={selectedItems.length === 0}
              variant="outlined"
              onClick={() => setMailDialogShow(true)}
            >
              Next
            </Button>
            <Button color="error" variant="outlined" onClick={() => setAddMail(false)}>
              Cancel
            </Button>
          </div>
        )}
      </Stack>
    );
  }

  function handleRefresh() {
    setMailDialogShow(false);
    setAddJudgeDialogShow(false);
    setAddMail(false);
    setSelectedItems([]);
    setBadge(false);
    fetchData();
  }

  const handleCancel = () => {
    setSelectedItems([]);
    setBadge(false);
  };

  const startButtonDisabled = selectedItems.length === 0;

  return (
    <Stack spacing={1}>
      {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
      <MuiTable
        tableData={tableData}
        tableColumns={headers}
        title={<TermSelect />}
        customOption={{
          selectableRows: addMail || badge ? 'multiple' : 'none',
          isRowSelectable: (dataIndex) => (badge ? tableData[dataIndex].judging_group_id : true),
          onRowSelectionChange,
          rowsSelected: selectedItems,
          expandableRows: true,
          expandableRowsHeader: false,
          renderExpandableRow: (rowData) => {
            const colSpan = rowData.length + 1;
            return (
              <TableRow>
                <TableCell colSpan={colSpan}>
                  <ExpandView project_id={rowData[0]} refreshData={handleRefresh} />
                </TableCell>
              </TableRow>
            );
          },
          customToolbar: () =>
            isCommunication !== -1 && (
              <Tooltip title="Send Email">
                <IconButton onClick={() => setAddMail(true)}>
                  <Email />
                </IconButton>
              </Tooltip>
            )
        }}
      />
      <CustomDialog
        onClose={() => setMailDialogShow(false)}
        open={mailDialogShow}
        maxWidth="lg"
        title="Send Mail"
        fullWidth
        disableEnforceFocus
      >
        <DialogContent>
          <CustomMail
            handleRefresh={handleRefresh}
            mailType={PROJECT_MAIL_TYPE}
            idList={projectIdList}
            handleClose={() => setMailDialogShow(false)}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        onClose={() => setAddJudgeDialogShow(false)}
        open={addJudgeDialogShow}
        title="Assign Judge"
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          <AddJudgeDialog
            data={editData}
            handleClose={() => setAddJudgeDialogShow(null)}
            refreshData={handleRefresh}
          />
        </DialogContent>
      </CustomDialog>
    </Stack>
  );
}

Index.propTypes = {
  terms: PropTypes.array,
  active_term: PropTypes.number,
  isCommunication: PropTypes.number,
  dispatch: PropTypes.func
};

export default connect((store) => ({
  terms: store.loginReducer.terms,
  active_term: store.loginReducer.active_term,
  isCommunication: store.routeReducer.isCommunication
}))(Index);
