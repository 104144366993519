import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { Icon } from '@iconify/react';
import { Button, Tooltip } from '@mui/material';
import { IconButton, Stack, TextField } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import { useState } from 'react';
import { addUserRole, deleteUserRole, getUserRole, patchUserRole } from '../serviceCalls';
import { setConfirmationAlert, setSnackbar } from '../../../../actions/alertActions';

function UserRoleDialog(props) {
  const { userRoles, roleList, userId, dispatch } = props;
  const [selectedRole, setSelectedRole] = useState(null);
  const [isAddCondition, setIsAddCondition] = useState(false);
  const [tableData, setTableData] = useState(userRoles);

  function fetchUserRole() {
    getUserRole(
      { user_id: userId },
      (data) => {
        setTableData(data);
      },
      () => {}
    );
  }
  function handleDeleteRole(user_role_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'This action will delete user role',
        () => {
          deleteUserRole(
            { user_role_id },
            () => {
              dispatch(setSnackbar('success', 'The user role has been deleted'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
              fetchUserRole();
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }
  function handleChangeDefaultRole(item) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, CHANGE',
        'This action will set default user role',
        () => {
          patchUserRole(
            { user_id: item.user_id, role_id: item.role_id },
            () => {
              dispatch(setSnackbar('success', 'The default user role has been set'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
              fetchUserRole();
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }
  function handleAddRole() {
    addUserRole(
      {
        user_id: userId,
        role_id: selectedRole.role_id,
        is_default: 0
      },
      () => {
        fetchUserRole();
        closeAddRole();
      },
      () => {}
    );
  }
  function closeAddRole() {
    setIsAddCondition(false);
    setSelectedRole(null);
  }

  return (
    <Stack spacing={3}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow style={{ background: 'gainsboro' }}>
              <TableCell style={{ width: '20%' }}>ID</TableCell>
              <TableCell style={{ width: '20%' }}>Role Name</TableCell>
              <TableCell style={{ width: '30%' }}>User Name</TableCell>
              <TableCell style={{ width: '30%' }}>Default Role</TableCell>
              <TableCell style={{ width: '10%' }}>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.role_id}
                </TableCell>
                <TableCell>{row.role_name}</TableCell>
                <TableCell>{`${row.first_name} ${row.last_name}`}</TableCell>
                <TableCell>
                  {row.is_default ? (
                    <IconButton color="success" disabled style={{ color: '#54D62C' }}>
                      <Icon icon="akar-icons:circle-check" width={24} height={24} />
                    </IconButton>
                  ) : (
                    <Tooltip title="Make Default Role">
                      <IconButton color="error" onClick={() => handleChangeDefaultRole(row)}>
                        <Icon color="error" icon="akar-icons:circle-x" width={24} height={24} />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    color="error"
                    disabled={tableData.length === 1}
                    onClick={() => handleDeleteRole(row.user_role_id)}
                  >
                    <Icon icon="fluent:delete-48-filled" width={24} height={24} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        {!isAddCondition && (
          <Button onClick={() => setIsAddCondition(true)} variant="outlined">
            Add New Role
          </Button>
        )}
        {isAddCondition && (
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Autocomplete
              value={selectedRole}
              onChange={(event, newValue) => {
                setSelectedRole(newValue);
              }}
              style={{ width: 400 }}
              id="free-solo-demo"
              freeSolo
              options={roleList}
              getOptionLabel={(option) => `${option.role_name}`}
              renderInput={(params) => <TextField {...params} fullWidth label="Roles" />}
            />
            <div style={{ alignItems: 'baseline' }}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <Button onClick={handleAddRole} variant="outlined">
                  Add Role to User
                </Button>
                <Button variant="outlined" color="error" onClick={closeAddRole}>
                  Cancel
                </Button>
              </Stack>
            </div>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}

UserRoleDialog.propTypes = {
  dispatch: PropTypes.func,
  userRoles: PropTypes.array,
  roleList: PropTypes.array,
  userId: PropTypes.number
};

export default connect(() => ({}))(UserRoleDialog);
