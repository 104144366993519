import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { blue } from '@material-ui/core/colors';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Grid, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(() => ({
  avatar: {
    backgroundColor: blue[500]
  },
  cardAction: {
    float: 'right'
  }
}));

export default function CustomStudentCard(props) {
  const classes = useStyles();
  const { data, handleEditSchool, handleDeleteSchool } = props;

  return (
    <Card>
      <CardHeader
        avatar={<Avatar className={classes.avatar}>{`${data.name.charAt(0)}`}</Avatar>}
        title={`${data.name}`}
        subheader={data.schoolType}
      />
      <CardContent>
        <Typography variant="body2" color="textPrimary" component="span">
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <span>
                <b>First Name: </b>
              </span>
              {data.main_contact_first_name || '-'}
            </Grid>
            <Grid item xs={6}>
              <span>
                <b>Last Name: </b>
              </span>
              {data.main_contact_last_name || '-'}
            </Grid>
            <Grid item xs={12}>
              <span>
                <b>Phone Number: </b>
              </span>
              {data.phone_number || '-'}
            </Grid>
            <Grid item xs={12}>
              <span>
                <b>Email: </b>
              </span>
              {data.main_contact_email || '-'}
            </Grid>
            <Grid item xs={12}>
              <span>
                <b>Address: </b>
              </span>
              {`${data.street}, ${data.city} ${data.zip}, 
                 ${data.state ? data.state : '-'} ${data.country}`}
            </Grid>
          </Grid>
        </Typography>
      </CardContent>
      <CardActions className={classes.cardAction}>
        <Tooltip title="Edit">
          <IconButton size="small" color="primary" onClick={() => handleEditSchool(data)}>
            <EditIcon size="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton size="small" color="error" onClick={() => handleDeleteSchool(data.id)}>
            <DeleteIcon size="small" />
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  );
}

CustomStudentCard.propTypes = {
  data: PropTypes.object.isRequired,
  handleEditSchool: PropTypes.func.isRequired,
  handleDeleteSchool: PropTypes.func.isRequired
};
