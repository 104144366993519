export default [
  {
    name: 'add_session',
    label: 'Session',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'project_id',
    label: 'Project ID'
  },
  {
    name: 'title',
    label: 'Title'
  },
  {
    name: 'judging_session',
    label: 'Session'
  },
  {
    name: 'judging_session_type',
    label: 'Session Type'
  },
  {
    name: 'judging_group_number_comp',
    label: 'Group Number'
  },
  {
    name: 'judging_group_place_comp',
    label: 'Group Place'
  },
  {
    name: 'electricity_needed_comp',
    label: 'Electricity Need'
  },
  {
    name: 'school_name',
    label: 'School'
  },
  {
    name: 'discipline',
    label: 'Discipline'
  },
  {
    name: 'category',
    label: 'Category'
  },
  {
    name: 'teacher_name',
    label: 'Teacher'
  },
  {
    name: 'project_confirmed_student_complete',
    label: 'Confirmed Students'
  },
  {
    name: 'fair',
    label: 'Fair'
  },
  {
    name: 'media_link_comp',
    label: 'YT Link',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'media_link_filter',
    label: 'YT Link',
    options: {
      display: false
    }
  },
  {
    name: 'balanceComp',
    label: 'Balance',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'balance_filter',
    label: 'Balance',
    options: {
      display: false
    }
  }
];
