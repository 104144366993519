import { MenuItem, Stack } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Button, DialogContent, TextField } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import CustomDialog from '../../../../components/CustomComponents/CustomDialog';
import { setSnackbar } from '../../../../actions/alertActions';
import { addDocument, getDocumentRole } from '../_apis/serviceCalls';

const englishChar = /^[-a-zA-Z0-9&_ ]+\.(gif|svg|jpg|jpeg|png|pdf)$/;

function AddUpdateDialog(props) {
  const { handleClose, dispatch, refreshData } = props;
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [roleId, setRoleId] = useState('');
  const [roleList, setRoleList] = useState([]);
  const [nameError, setNameError] = useState(false);

  useEffect(() => {
    getDocumentRole({}, (data) => {
      setRoleList(data);
    });
  }, []);

  function changeHandler(event) {
    const _file = event.target.files[0];
    const fileNameOk = englishChar.test(_file.name);
    if (fileNameOk) {
      setFile(_file);
      setNameError(false);
    } else {
      setNameError(true);
    }
  }

  function handleSubmission() {
    setLoading(true);
    const formData = new FormData();
    formData.append('File', file);
    const metaDataParam = {
      roleId,
      description,
      file,
      title
    };
    addDocument(
      metaDataParam,
      () => {
        dispatch(setSnackbar('success', 'The file has been uploaded'));
        handleClose();
        refreshData();
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    ).then(() => {});
  }
  return (
    <CustomDialog onClose={handleClose} title="ADD" fullWidth maxWidth="xs">
      <DialogContent>
        <Stack spacing={2} sx={{ mt: 1 }}>
          <TextField
            select
            label="Roles"
            value={roleId}
            onChange={(e) => setRoleId(e.target.value)}
          >
            {roleList.map((role) => (
              <MenuItem key={role.id} value={role.id}>
                {role.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            label="Title"
            variant="outlined"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            fullWidth
            label="Description"
            variant="outlined"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <input
            type="file"
            name="file"
            onChange={changeHandler}
            accept=".jpg,.pdf,.png,.jpeg,.svg"
          />
          {nameError && (
            <p style={{ color: 'red', fontWeight: 'bold' }}>
              File name must contain only English characters and cannot include special characters.
              Only pdf and image files are allowed
            </p>
          )}
          <Stack direction="row" justifyContent="end" spacing={1} sx={{ mt: 1 }}>
            <LoadingButton
              size="small"
              type="submit"
              variant="outlined"
              disabled={!title || !description || !file}
              onClick={handleSubmission}
              loading={loading}
            >
              Add
            </LoadingButton>
            <Button size="small" color="error" onClick={handleClose} variant="outlined">
              Cancel
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </CustomDialog>
  );
}

AddUpdateDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired
};

export default connect(() => ({}))(AddUpdateDialog);
