import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormLabel, Paper, Radio, RadioGroup } from '@material-ui/core';
import { Button, Divider, Stack } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { patchReviewAdminReview } from '../serviceCalls';
import { setSnackbar } from '../../../actions/alertActions';

const ScoreDialog = (props) => {
  const { handleClose, data, dispatch, handleRefresh } = props;
  const [value, setValue] = useState(data.review_score && data.review_score.toString());

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  function handleReview() {
    patchReviewAdminReview(
      { project_id: data.id, score: Number(value) },
      () => {
        dispatch(setSnackbar('success', 'The project has been scored'));
        handleRefresh();
      },
      () => {}
    );
  }

  return (
    <Stack spacing={2}>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow style={{ background: 'gainsboro' }}>
              <TableCell style={{ width: '20%' }}>ID</TableCell>
              <TableCell style={{ width: '40%' }}>Project Title</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                {data?.id}
              </TableCell>
              <TableCell>{data?.title}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Divider />
      <FormControl component="fieldset">
        <FormLabel component="legend">Score</FormLabel>
        <RadioGroup
          row
          aria-label="score"
          name="row-radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel value="1" control={<Radio />} label="1" />
          <FormControlLabel value="2" control={<Radio />} label="2" />
          <FormControlLabel value="3" control={<Radio />} label="3" />
          <FormControlLabel value="4" control={<Radio />} label="4" />
          <FormControlLabel value="5" control={<Radio />} label="5" />
        </RadioGroup>
      </FormControl>
      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="end" spacing={2}>
        <Button variant="outlined" onClick={handleReview}>
          Save
        </Button>
        <Button variant="outlined" color="error" onClick={handleClose}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};

ScoreDialog.propTypes = {
  data: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired
};

export default connect(() => ({}))(ScoreDialog);
