import { Paper } from '@material-ui/core';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import React from 'react';
import PropTypes from 'prop-types';

function SummaryTable({ data }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 400 }} aria-label="simple table">
        <TableHead>
          <TableRow style={{ background: 'gainsboro' }}>
            <TableCell>Discipline</TableCell>
            <TableCell align="center">Type</TableCell>
            <TableCell align="center">Total Approved</TableCell>
            <TableCell align="center">Total Pending</TableCell>
            <TableCell style={{ width: '10%' }} />
            <TableCell>Discipline</TableCell>
            <TableCell align="center">Type</TableCell>
            <TableCell align="center">Total Approved</TableCell>
            <TableCell align="center">Total Pending</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, index) =>
            index === data.length - 1 && index % 2 === 0 ? (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row" variant="head">
                  {row.discipline}
                </TableCell>
                <TableCell align="center">{row.type}</TableCell>
                <TableCell align="center">{row.totalApproved}</TableCell>
                <TableCell align="center">{row.totalPending}</TableCell>
              </TableRow>
            ) : index % 2 === 0 ? (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row" variant="head">
                  {row.discipline}
                </TableCell>
                <TableCell align="center">{row.type}</TableCell>
                <TableCell align="center">{row.totalApproved}</TableCell>
                <TableCell align="center">{row.totalPending}</TableCell>
                <TableCell />
                <TableCell component="th" scope="row" variant="head">
                  {data[index + 1].discipline}
                </TableCell>
                <TableCell align="center">{data[index + 1].type}</TableCell>
                <TableCell align="center">{data[index + 1].totalApproved}</TableCell>
                <TableCell align="center">{data[index + 1].totalPending}</TableCell>
              </TableRow>
            ) : null
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

SummaryTable.propTypes = {
  data: PropTypes.array.isRequired
};

export default SummaryTable;
