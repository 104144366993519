// Environmental settings
// set global fetch prep function
// disable logs for prod
const pjson = require('../package.json');

console.log('version', pjson.version);

export default function envFunc() {
  const env = process.env.REACT_APP_CUSTOM_ENV || 'local';
  console.log('ENV', env);
  console.log('HOST URL', process.env.REACT_APP_REST_API_HOST);
  if (env === 'prod' || env === 'uat' || env === 'demo') {
    // eslint-disable-next-line
        console = console || {};
    // eslint-disable-next-line func-names
    console.log = function () {};
    // eslint-disable-next-line func-names
    console.warn = function () {};
    // eslint-disable-next-line func-names
    console.error = function () {};
    // eslint-disable-next-line func-names
    console.time = function () {};
    // eslint-disable-next-line func-names
    console.timeEnd = function () {};
  }
  // eslint-disable-next-line global-require
  const { AwsClient } = require('aws4fetch');
  const aws = new AwsClient({
    accessKeyId: process.env.REACT_APP_API_KEY,
    secretAccessKey: process.env.REACT_APP_API_SECRET,
    region: process.env.REACT_APP_API_REGION,
    service: process.env.REACT_APP_API_SERVICE
  });
  window._prepareFetchRequest =
    env === 'local'
      ? (...params) => new Promise((resolve) => resolve(new Request(...params)))
      : (...params) => aws.sign(...params);
}
