import { Checkbox, Stack } from '@material-ui/core';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useState } from 'react';
import { setConfirmationAlert, setSnackbar } from '../../../../actions/alertActions';
import { updateParticipantOperationAttendance } from '../serviceCalls';

function AddUpdateDialog(props) {
  const { data, handleClose, dispatch, refreshData, selectedParticipants } = props;
  const [isAttend, setIsAttend] = useState(false);

  const arr = [];
  selectedParticipants.forEach((item) => {
    arr.push(data[item].id);
  });

  function handleAdd() {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, UPDATE',
        isAttend
          ? 'Attendance will be added as a result of this action'
          : 'Attendance will be removed as a result of this action',
        () => {
          updateParticipantOperationAttendance(
            { participant_ids: arr, is_attended: isAttend ? 1 : 0 },
            () => {
              refreshData();
              dispatch(setSnackbar('success', 'The attendance has been updated'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }

  return (
    <Stack spacing={2}>
      <FormControlLabel
        control={<Checkbox checked={isAttend} onChange={(e) => setIsAttend(e.target.checked)} />}
        label="If the participants attended, tick the check box."
      />
      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="end" spacing={2}>
        <Button onClick={handleAdd} variant="outlined">
          Add
        </Button>
        <Button variant="outlined" color="error" onClick={handleClose}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
}

AddUpdateDialog.propTypes = {
  data: PropTypes.array,
  selectedParticipants: PropTypes.array,
  handleClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired
};

export default connect(() => ({}))(AddUpdateDialog);
