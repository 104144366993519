import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { IconButton } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { Button, DialogContent, Stack, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ContentCopy } from '@mui/icons-material';
import ReactHtmlParser from 'react-html-parser';
import MuiTable from '../../../../components/CustomComponents/MuiTable';
import { deleteTerm, getTerm, patchOnlineTerm, patchTerm } from '../serviceCalls';
import { setConfirmationAlert, setSnackbar } from '../../../../actions/alertActions';
import headers from './utils/headerVariables';
import CustomDialog from '../../../../components/CustomComponents/CustomDialog';
import AddUpdateDialog from './AddUpdateDialog';
import CopyDialog from './CopyDialog';
import Page from '../../../../components/Page';
import ProcessInfoBox from '../../../../components/CustomComponents/CustomCard/ProcessInfoBox';

const ADD_TERM = 0;
const UPDATE_TERM = 1;

function Index(props) {
  const { dispatch } = props;
  const [tableData, setTableData] = useState([]);
  const [addUpdateDialog, setAddUpdateDialog] = useState(false);
  const [configDialog, setConfigDialog] = useState(false);
  const [mode, setMode] = useState(null);
  const [editData, setEditData] = useState(null);
  const [guidelines, setGuidelines] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);
  function fetchData() {
    getTerm(
      {},
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((item) => {
          item.application_start_date_comp = moment(item.application_start_date).format(
            'YYYY-MM-DD'
          );
          item.application_end_date_comp = moment(item.application_end_date).format('YYYY-MM-DD');
          item.application_fair_end_date_comp = moment(item.application_fair_end_date).format(
            'YYYY-MM-DD'
          );
          item.invitation_end_date_comp = moment(item.invitation_end_date).format('YYYY-MM-DD');
          item.presentation_end_date_comp = moment(item.presentation_end_date).format('YYYY-MM-DD');
          item.registration_end_date_comp = moment(item.registration_end_date).format('YYYY-MM-DD');
          item.trip_end_date_comp = moment(item.trip_end_date).format('YYYY-MM-DD');
          item.event_start_date_comp = moment(item.event_start_date).format('YYYY-MM-DD');
          item.event_end_date_comp = moment(item.event_end_date).format('YYYY-MM-DD');
          item.is_locked_filter = item.is_locked ? 'Yes' : 'No';
          item.is_locked_comp = item.is_locked ? (
            <Tooltip title="Change Lock">
              <IconButton color="success" onClick={() => handleLockedTerm(item.id)}>
                <Icon icon="akar-icons:circle-check" width={24} height={24} />
              </IconButton>
            </Tooltip>
          ) : (
            <Icon color="#FF4842" icon="akar-icons:circle-x" width={24} height={24} />
          );
          item.is_online_filter = item.is_online ? 'Yes' : 'No';
          item.is_online_comp = item.is_online ? (
            <Tooltip title="Change Online">
              <IconButton color="success" onClick={() => handleOnlineTerm(item.id, 0)}>
                <Icon icon="akar-icons:circle-check" width={24} height={24} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Change Online">
              <IconButton color="error" onClick={() => handleOnlineTerm(item.id, 1)}>
                <Icon color="error" icon="akar-icons:circle-x" width={24} height={24} />
              </IconButton>
            </Tooltip>
          );
          item.edit = (
            <IconButton color="secondary" onClick={() => handleEditTerm(item)}>
              <Icon icon="ci:edit" width={24} height={24} />
            </IconButton>
          );
          item.delete = (
            <IconButton color="secondary" onClick={() => handleDeleteTerm(item.id)}>
              <Icon icon="fluent:delete-48-filled" width={24} height={24} />
            </IconButton>
          );
        });
        setTableData(list);
      },
      () => {}
    );
  }
  function handleAddTerm() {
    setMode(ADD_TERM);
    setAddUpdateDialog(true);
  }
  function handleEditTerm(data) {
    setMode(UPDATE_TERM);
    setAddUpdateDialog(true);
    setEditData(data);
  }
  function refreshData() {
    setAddUpdateDialog(false);
    setConfigDialog(false);
    fetchData();
  }
  function handleDeleteTerm(id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'This action will delete term permanently',
        () => {
          deleteTerm(
            { id },
            () => {
              fetchData();
              dispatch(setSnackbar('success', 'The term has been deleted'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }
  function handleLockedTerm(id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, CHANGE',
        'This action will change the lock of term',
        () => {
          patchTerm(
            { id },
            () => {
              fetchData();
              dispatch(setSnackbar('success', 'The lock of term has been changed'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }
  function handleOnlineTerm(id, is_online) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, CHANGE',
        'This action will change the online of term',
        () => {
          patchOnlineTerm(
            { id, is_online },
            () => {
              fetchData();
              dispatch(setSnackbar('success', 'The online of term has been changed'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }

  return (
    <Page title="Term Settings | Genius">
      <Stack spacing={2}>
        {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
        <MuiTable
          title={
            <div className="header-button-style">
              <Button variant="outlined" onClick={handleAddTerm}>
                Add New
              </Button>
            </div>
          }
          tableData={tableData}
          tableColumns={headers}
          customOption={{
            customToolbar: () => (
              <Tooltip title="Copy Config">
                <IconButton onClick={() => setConfigDialog(true)}>
                  <ContentCopy />
                </IconButton>
              </Tooltip>
            )
          }}
        />
      </Stack>
      <CustomDialog
        onClose={() => setAddUpdateDialog(false)}
        open={addUpdateDialog}
        title={mode === ADD_TERM ? 'ADD TERM' : 'UPDATE TERM'}
      >
        <DialogContent>
          <AddUpdateDialog
            data={editData}
            mode={mode}
            handleClose={() => setAddUpdateDialog(false)}
            refreshData={refreshData}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        fullWidth
        maxWidth="sm"
        onClose={() => setConfigDialog(false)}
        open={configDialog}
        title="Copy Config"
      >
        <DialogContent>
          <CopyDialog
            data={tableData}
            handleClose={() => setConfigDialog(false)}
            refreshData={refreshData}
          />
        </DialogContent>
      </CustomDialog>
    </Page>
  );
}

Index.propTypes = {
  dispatch: PropTypes.func.isRequired
};

export default connect(() => ({}))(Index);
