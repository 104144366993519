import React, { useState } from 'react';
import { Button, DialogContent, Stack, Tooltip } from '@mui/material';
import moment from 'moment';
import { IconButton, TableCell } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { connect } from 'react-redux';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import MuiTable from '../../../components/CustomComponents/MuiTable';
import headers from './utils/headerVariables';
import CustomDialog from '../../../components/CustomComponents/CustomDialog';
import AddTravelDialog from './AddTravelDialog';
import { setConfirmationAlert, setSnackbar } from '../../../actions/alertActions';
import { deleteTravel } from './utils/serviceCalls';
import ExpandComponent from './utils/ExpandComponent';
import Badge from '../../../components/CustomComponents/Badge/Badge';

const ADD_MODE = 1;
const UPDATE_MODE = 2;

function ArrivalTable(props) {
  const { tableData, travelTypes, travelOption, handleRefresh, dispatch } = props;
  const [dialogShow, setDialogShow] = useState(false);
  const [mode, setMode] = useState(null);
  const [editData, setEditData] = useState(null);

  tableData.forEach((item) => {
    item.travel_date = moment(item.travel_date).format('YYYY-MM-DD');
    item.participant_comp = item.participant_names_complete && (
      <Tooltip title={item.participant_names_complete}>
        <p style={{ cursor: 'pointer' }}>{item.participant_names} </p>
      </Tooltip>
    );
    item.edit = (
      <IconButton color="secondary" onClick={() => handleEditTravel(item)}>
        <Icon icon="ci:edit" width={24} height={24} />
      </IconButton>
    );
    item.delete = (
      <IconButton color="secondary" onClick={() => handleDeleteTravel(item.id)}>
        <Icon icon="fluent:delete-48-filled" width={24} height={24} />
      </IconButton>
    );
  });

  function handleAddNew() {
    setMode(ADD_MODE);
    setDialogShow(true);
  }
  function handleDeleteTravel(travel_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'This action will delete arrival travel',
        () => {
          deleteTravel(
            { travel_id },
            () => {
              dispatch(setSnackbar('success', 'The travel has been deleted'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
              handleRefresh();
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }
  function handleEditTravel(item) {
    setEditData(item);
    setMode(UPDATE_MODE);
    setDialogShow(true);
  }
  function handleClose() {
    setEditData(null);
    setDialogShow(false);
    handleRefresh();
  }
  function CustomHeader() {
    return (
      <Stack spacing={2} direction={{ sm: 'row' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Badge color="submitColor"> Arrival </Badge>
        </div>
        <Button variant="outlined" onClick={handleAddNew}>
          Add New
        </Button>
      </Stack>
    );
  }

  return (
    <>
      <MuiTable
        title={<CustomHeader />}
        tableColumns={headers}
        tableData={tableData}
        rowsPerPage={10}
        customOption={{
          expandableRows: true,
          renderExpandableRow: (rowData) => {
            const colSpan = rowData.length + 1;
            return (
              <TableRow>
                <TableCell colSpan={colSpan}>
                  <ExpandComponent
                    data={rowData[0]}
                    travel_id={rowData[1]}
                    handleRefresh={handleRefresh}
                  />
                </TableCell>
              </TableRow>
            );
          }
        }}
      />
      <CustomDialog onClose={handleClose} open={dialogShow} maxWidth="lg" title="Arrival Itinerary">
        <DialogContent>
          <AddTravelDialog
            mode={mode}
            editData={editData}
            type={1}
            travelTypes={travelTypes}
            travelOption={travelOption.filter((x) => x.is_arrival === 1)}
            handleClose={handleClose}
            handleRefresh={handleRefresh}
          />
        </DialogContent>
      </CustomDialog>
    </>
  );
}

ArrivalTable.propTypes = {
  dispatch: PropTypes.func,
  handleRefresh: PropTypes.func,
  tableData: PropTypes.array,
  travelTypes: PropTypes.array,
  travelOption: PropTypes.array
};

export default connect(() => ({}))(ArrivalTable);
