import { MenuItem, Stack, TextField } from '@material-ui/core';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@material-ui/lab';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  addGuideline,
  getGuideline,
  getGuidelineFeature,
  getGuidelineType,
  updateGuideline
} from '../_apis/serviceCalls';
import { setLoading, setSnackbar } from '../../../../actions/alertActions';
import CustomDialog, { DialogContent } from '../../../../components/CustomComponents/CustomDialog';
import MarkdownEditor from './MarkdownEditor';

function AddUpdateDialog(props) {
  const { selectedGuideline, handleClose, dispatch, refreshData } = props;
  const [typeList, setTypeList] = React.useState([]);
  const [featureList, setFeatureList] = React.useState([]);
  const [markdown, setMarkdown] = React.useState('');

  const Schema = Yup.object().shape({
    feature_id: Yup.number().required('Feature is required'),
    description: Yup.string().max(500, 'Too Long!').required('Description is required'),
    guideline_type_id: Yup.number().required('Type is required')
  });

  const formik = useFormik({
    initialValues: {
      description: !selectedGuideline && '',
      feature_id: !selectedGuideline && '',
      is_protected: !selectedGuideline && false,
      guideline_type_id: !selectedGuideline && ''
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      const snackbarMessage = selectedGuideline
        ? 'Guideline updated successfully'
        : 'Guideline added successfully';
      let param;
      if (selectedGuideline) {
        param = {
          guideline_id: selectedGuideline.guideline_id,
          description: values.description,
          markdown
        };
      } else {
        param = { ...values, markdown, is_protected: values.is_protected ? 1 : 0 };
      }
      const method = selectedGuideline ? updateGuideline : addGuideline;
      method(
        param,
        () => {
          refreshData();
          handleClose();
          dispatch(setSnackbar('success', snackbarMessage));
        },
        () => {}
      ).then(() => dispatch(setLoading(0)));
    }
  });
  const { errors, touched, handleSubmit, getFieldProps, values, setFieldValue } = formik;

  useEffect(() => {
    if (selectedGuideline) {
      getGuideline(
        { guideline_id: selectedGuideline.guideline_id },
        (data) => {
          setFieldValue('feature_id', data.feature_id);
          setFieldValue('description', data.description);
          setFieldValue('guideline_type_id', data.guideline_type_id);
          setMarkdown(data.markdown);
          setFieldValue('is_protected', data.is_protected);
        },
        () => {}
      ).then(() => dispatch(setLoading(0)));
    }
    getGuidelineType({}, (data) => {
      setTypeList(data);
    });
    getGuidelineFeature({}, (data) => {
      setFeatureList(data);
    });
  }, []);

  return (
    <CustomDialog
      onClose={handleClose}
      title={selectedGuideline ? 'UPDATE' : 'ADD'}
      fullWidth
      maxWidth="lg"
      disableEnforceFocus
    >
      <DialogContent>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={1}>
              <Stack spacing={3} sx={{ mt: 1 }}>
                <TextField
                  disabled={selectedGuideline}
                  fullWidth
                  label="Guideline Type"
                  select
                  {...getFieldProps('guideline_type_id')}
                  error={Boolean(touched.guideline_type_id && errors.guideline_type_id)}
                  helperText={touched.guideline_type_id && errors.guideline_type_id}
                >
                  {typeList.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  disabled={selectedGuideline}
                  fullWidth
                  label="Guideline Feature"
                  select
                  {...getFieldProps('feature_id')}
                  error={Boolean(touched.feature_id && errors.feature_id)}
                  helperText={touched.feature_id && errors.feature_id}
                >
                  {featureList.map((feature) => (
                    <MenuItem key={feature.feature_id} value={feature.feature_id}>
                      {`${feature.application_name} - ${feature.module_name} - ${feature.feature_name}`}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  fullWidth
                  label="Description"
                  {...getFieldProps('description')}
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                />
                <FormControlLabel
                  disabled={selectedGuideline}
                  control={
                    <Checkbox {...getFieldProps('is_protected')} checked={values.is_protected} />
                  }
                  label="Protected Guideline"
                />
              </Stack>
              <MarkdownEditor markdown={markdown} setMarkdown={setMarkdown} />
            </Stack>
            <Stack direction="row" justifyContent="end" spacing={1} sx={{ mt: 1 }}>
              <LoadingButton size="small" type="submit" variant="outlined" disabled={!markdown}>
                {selectedGuideline ? 'Update' : 'Add'}
              </LoadingButton>
              <Button size="small" color="error" onClick={handleClose} variant="outlined">
                Cancel
              </Button>
            </Stack>
          </Form>
        </FormikProvider>
      </DialogContent>
    </CustomDialog>
  );
}

AddUpdateDialog.propTypes = {
  selectedGuideline: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired
};

export default connect(() => ({}))(AddUpdateDialog);
