import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, IconButton, Paper } from '@material-ui/core';
import { Stack } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { Icon } from '@iconify/react';
import { fDateTime } from '../../../../../../utils/formatTime';

const Robotics = (props) => {
  const { data, deleteRobotics } = props;

  return (
    <Stack spacing={2}>
      <p style={{ fontWeight: 'bold' }}>Robotics:</p>
      {data.length > 0 ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow style={{ background: 'gainsboro' }}>
                <TableCell style={{ width: '15%' }}>Competition Id</TableCell>
                <TableCell style={{ width: '35%' }}>Scorekeeper Name</TableCell>
                <TableCell style={{ width: '15%' }}>Result</TableCell>
                <TableCell style={{ width: '25%' }}>Submission Date</TableCell>
                <TableCell style={{ width: '20%' }}>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {row.competition_id ? row.competition_id : '-'}
                  </TableCell>
                  <TableCell>{`${row.scorekeeper_first_name} ${row.scorekeeper_last_name}`}</TableCell>
                  <TableCell>{row.competition_result || '-'}</TableCell>
                  <TableCell>{row.submitted_at ? fDateTime(row.submitted_at) : '-'}</TableCell>
                  <TableCell>
                    <IconButton
                      disabled={!row.competition_id}
                      color="secondary"
                      onClick={() => deleteRobotics(row.competition_id)}
                    >
                      <Icon icon="fluent:delete-48-filled" width={24} height={24} />
                    </IconButton>{' '}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Alert severity="info">There is no Robotics Competition detail for project.</Alert>
      )}
    </Stack>
  );
};

Robotics.propTypes = {
  data: PropTypes.array,
  deleteRobotics: PropTypes.func.isRequired
};

export default connect(() => ({}))(Robotics);
