const HOST = process.env.REACT_APP_REST_API_HOST;

const GET_JUDGING_FORM = {
  method: 'GET',
  path: `${HOST}go_admin/judge_operation/judging_form`
};
const ADD_JUDGING_FORM = {
  method: 'POST',
  path: `${HOST}go_admin/judge_operation/judging_form`
};
const UPDATE_JUDGING_FORM = {
  method: 'PUT',
  path: `${HOST}go_admin/judge_operation/judging_form`
};
const DELETE_JUDGING_FORM = {
  method: 'DELETE',
  path: `${HOST}go_admin/judge_operation/judging_form`
};
const COPY_JUDGING_FORM = {
  method: 'POST',
  path: `${HOST}go_admin/judge_operation/judging_form/copy`
};
const GET_JUDGING_FORM_DETAIL = {
  method: 'GET',
  path: `${HOST}go_admin/judge_operation/judging_form/detail`
};
const ADD_JUDGING_FORM_QUESTION = {
  method: 'POST',
  path: `${HOST}go_admin/judge_operation/judging_form/question`
};
const UPDATE_JUDGING_FORM_QUESTION = {
  method: 'PUT',
  path: `${HOST}go_admin/judge_operation/judging_form/question`
};
const DELETE_JUDGING_FORM_QUESTION = {
  method: 'DELETE',
  path: `${HOST}go_admin/judge_operation/judging_form/question`
};
const UPDATE_JUDGING_FORM_QUESTION_RANK = {
  method: 'PATCH',
  path: `${HOST}go_admin/judge_operation/judging_form/question`
};

module.exports = {
  GET_JUDGING_FORM,
  ADD_JUDGING_FORM,
  UPDATE_JUDGING_FORM,
  DELETE_JUDGING_FORM,
  COPY_JUDGING_FORM,
  GET_JUDGING_FORM_DETAIL,
  ADD_JUDGING_FORM_QUESTION,
  UPDATE_JUDGING_FORM_QUESTION,
  DELETE_JUDGING_FORM_QUESTION,
  UPDATE_JUDGING_FORM_QUESTION_RANK
};
