const HOST = process.env.REACT_APP_REST_API_HOST;

const GET_PAYMENT_LIST = {
  method: 'GET',
  path: `${HOST}go_core/payments/payments/list`
};
const ADD_PROJECT_PAYMENT = {
  method: 'POST',
  path: `${HOST}go_core/payments/payments/project`
};
const ADD_PARTICIPANT_PAYMENT = {
  method: 'POST',
  path: `${HOST}go_core/payments/payments/participant`
};
const ADD_TRIP_PAYMENT = {
  method: 'POST',
  path: `${HOST}go_core/payments/payments/trip`
};
const MAKE_INVOICE = {
  method: 'POST',
  path: `${HOST}go_core/payments/payments/invoice`
};

module.exports = {
  GET_PAYMENT_LIST,
  ADD_PROJECT_PAYMENT,
  ADD_PARTICIPANT_PAYMENT,
  ADD_TRIP_PAYMENT,
  MAKE_INVOICE
};
