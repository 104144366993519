import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Router from './routes';
import ThemeConfig from './theme';
import ScrollToTop from './components/ScrollToTop';
import './App.css';
import GoogleTranslateWidget from './components/CustomComponents/GoogleTranslate/GoogleTranslateWidget';

const SUPERUSER = 21;

// ----------------------------------------------------------------------

function App(props) {
  const { roleId } = props;
  return (
    <ThemeConfig>
      {roleId === SUPERUSER ? <div /> : <GoogleTranslateWidget style={{ width: '100%' }} />}
      <ScrollToTop />
      <Router />
    </ThemeConfig>
  );
}

App.propTypes = {
  roleId: PropTypes.number
};
export default connect((store) => ({
  roleId: store.loginReducer.user_role
}))(App);
