const HOST = process.env.REACT_APP_REST_API_HOST;

const GET_PRESENTATION = {
  method: 'GET',
  path: `${HOST}go_core/presentation/presentation`
};
const ADD_PRESENTATION = {
  method: 'POST',
  path: `${HOST}go_core/presentation/presentation`
};
const DELETE_PRESENTATION = {
  method: 'DELETE',
  path: `${HOST}go_core/presentation/presentation`
};

module.exports = {
  GET_PRESENTATION,
  ADD_PRESENTATION,
  DELETE_PRESENTATION
};
