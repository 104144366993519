const HOST = process.env.REACT_APP_REST_API_HOST;

const UPDATE_PARTNER_CONFIG = {
  method: 'PUT',
  path: `${HOST}go_admin/partner_fair_operation/config`
};
const GET_PARTNER_CONFIG = {
  method: 'GET',
  path: `${HOST}go_admin/partner_fair_operation/config`
};
const GET_PARTNER_REPORT = {
  method: 'GET',
  path: `${HOST}go_admin/partner_fair_operation/report`
};
const APPROVE_PARTNER_REPORT = {
  method: 'PATCH',
  path: `${HOST}go_admin/partner_fair_operation/report/approve`
};

module.exports = {
  UPDATE_PARTNER_CONFIG,
  GET_PARTNER_CONFIG,
  GET_PARTNER_REPORT,
  APPROVE_PARTNER_REPORT
};
