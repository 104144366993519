import React, { useEffect, useState } from 'react';
import { IconButton, MenuItem, Stack, TextField } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { Button, DialogContent, Tooltip } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Email from '@mui/icons-material/Email';
import ReactHtmlParser from 'react-html-parser';
import MuiTable from '../../../../components/CustomComponents/MuiTable';
import headers from '../_utils/headerVariables';
import { approvePartnerReport, getPartnerReport } from '../_apis/serviceCalls';
import CustomDialog from '../../../../components/CustomComponents/CustomDialog';
import CustomMail from '../../../../components/CustomComponents/CustomMail';
import { CUSTOM_MAIL_TYPE } from '../../../../api/RestVariables/staticVariables';
import Page from '../../../../components/Page';
import { setConfirmationAlert, setSnackbar } from '../../../../actions/alertActions';
import ProcessInfoBox from '../../../../components/CustomComponents/CustomCard/ProcessInfoBox';
import StatusDialog from './Components/StatusDialog';

function Index(props) {
  const { terms, active_term, isCommunication, dispatch } = props;
  const [term, setTerm] = useState(active_term);
  const [tableData, setTableData] = useState([]);
  const [mailDialogShow, setMailDialogShow] = useState(false);
  const [addMail, setAddMail] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [idList, setIdList] = useState([]);
  const [guidelines, setGuidelines] = useState([]);
  const [addStatus, setAddStatus] = useState(false);
  const [statusDialog, setStatusDialog] = useState(false);

  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedItems(rowsSelected);
    const arr = [];
    rowsSelected.forEach((item) => {
      arr.push(tableData[item].judge_user_id);
    });
    setIdList(arr);
  };

  useEffect(() => {
    fetchData();
  }, [term]);
  function fetchData() {
    getPartnerReport(
      { term_id: term },
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((item) => {
          item.approve =
            item.finalist_status_id === 1 ? (
              <IconButton color="success" disabled style={{ color: '#54D62C' }}>
                <Icon color="success" icon="akar-icons:circle-check" />
              </IconButton>
            ) : (
              <Tooltip title="Approve">
                <IconButton color="secondary" onClick={() => handleApprove(item.id)}>
                  <Icon icon="dashicons:update" />
                </IconButton>
              </Tooltip>
            );
          item.deny =
            item.finalist_status_id === 2 ? (
              <IconButton color="error" disabled style={{ color: '#FF4842' }}>
                <Icon icon="akar-icons:circle-x" />
              </IconButton>
            ) : (
              <Tooltip title="Deny">
                <IconButton color="secondary" onClick={() => handleDeny(item.id)}>
                  <Icon icon="dashicons:update" />
                </IconButton>
              </Tooltip>
            );
        });
        setTableData(list);
      },
      () => {}
    );
  }
  function handleApprove(project_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, APPROVE',
        'This action will approve the project request',
        () => {
          approvePartnerReport({ project_ids: [project_id], is_approved: 1 }, () => {
            fetchData();
            dispatch(setSnackbar('success', 'The project status has been approved'));
          }).then(() => dispatch({ type: 'RESET_CONFIRMATION_ALERT' }));
        }
      )
    );
  }
  function handleDeny(project_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DENY',
        'This action will deny the project request',
        () => {
          approvePartnerReport({ project_ids: [project_id], is_approved: 0 }, () => {
            fetchData();
            dispatch(setSnackbar('success', 'The project status has been denied'));
            dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
          }).then(() => dispatch({ type: 'RESET_CONFIRMATION_ALERT' }));
        }
      )
    );
  }
  function handleRefresh() {
    setMailDialogShow(false);
    setAddMail(false);
    setSelectedItems([]);
    setStatusDialog(false);
    setAddStatus(false);
    fetchData();
  }
  function TermSelect() {
    return (
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={2}
        sx={{ mt: 2, alignItems: 'center' }}
      >
        <TextField select label="Terms" value={term} onChange={(e) => setTerm(e.target.value)}>
          {terms.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
        {!addStatus && (
          <div>
            <Button color="secondary" variant="outlined" onClick={() => setAddStatus(true)}>
              Status
            </Button>
          </div>
        )}
        {addStatus && (
          <div>
            <Button
              color="secondary"
              variant="outlined"
              disabled={startButtonDisabled}
              onClick={() => setStatusDialog(true)}
            >
              Next
            </Button>
            <Button
              variant="outlined"
              color="error"
              style={{ marginLeft: 10 }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        )}
      </Stack>
    );
  }

  const handleCancel = () => {
    setSelectedItems([]);
    setAddStatus(false);
  };

  const startButtonDisabled = selectedItems.length === 0;

  return (
    <Page title="Judge Operation | Genius">
      <Stack spacing={2}>
        {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
        <MuiTable
          tableData={tableData}
          tableColumns={headers}
          title={<TermSelect />}
          customOption={{
            selectableRows: addMail || addStatus ? 'multiple' : 'none',
            onRowSelectionChange,
            rowsSelected: selectedItems,
            customToolbar: () =>
              isCommunication !== -1 && (
                <Tooltip title="Send Email">
                  <IconButton onClick={() => setAddMail(true)}>
                    <Email />
                  </IconButton>
                </Tooltip>
              )
          }}
        />
        <CustomDialog
          onClose={() => setStatusDialog(false)}
          open={statusDialog}
          maxWidth="lg"
          title="Approve/Deny"
        >
          <DialogContent>
            <StatusDialog
              tableData={tableData}
              handleClose={() => setStatusDialog(false)}
              selectedItems={selectedItems}
              handleRefresh={handleRefresh}
            />
          </DialogContent>
        </CustomDialog>
        <CustomDialog
          onClose={() => setMailDialogShow(false)}
          open={mailDialogShow}
          maxWidth="lg"
          title="Send Mail"
          fullWidth
          disableEnforceFocus
        >
          <DialogContent>
            <CustomMail
              handleRefresh={handleRefresh}
              mailType={CUSTOM_MAIL_TYPE}
              idList={idList}
              handleClose={() => setMailDialogShow(false)}
            />
          </DialogContent>
        </CustomDialog>
      </Stack>
    </Page>
  );
}

Index.propTypes = {
  terms: PropTypes.array,
  active_term: PropTypes.number,
  isCommunication: PropTypes.number,
  dispatch: PropTypes.func
};

export default connect((store) => ({
  terms: store.loginReducer.terms,
  active_term: store.loginReducer.active_term,
  isCommunication: store.routeReducer.isCommunication
}))(Index);
