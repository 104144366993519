import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { IconButton } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { Button, DialogContent } from '@mui/material';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import MuiTable from '../../../../components/CustomComponents/MuiTable';
import { deleteCategory, fetchCategoryList, fetchDisciplineList } from '../serviceCalls';
import { setConfirmationAlert, setSnackbar } from '../../../../actions/alertActions';
import headers from './utils/headerVariables';
import CustomDialog from '../../../../components/CustomComponents/CustomDialog';
import AddUpdateDialog from './AddUpdateDialog';
import Page from '../../../../components/Page';
import ProcessInfoBox from '../../../../components/CustomComponents/CustomCard/ProcessInfoBox';

const ADD_CATEGORY = 0;
const UPDATE_CATEGORY = 1;

function Index(props) {
  const { dispatch } = props;
  const [tableData, setTableData] = useState([]);
  const [addUpdateDialog, setAddUpdateDialog] = useState(false);
  const [mode, setMode] = useState(null);
  const [editData, setEditData] = useState(null);
  const [disciplineList, setDisciplineList] = useState([]);
  const [guidelines, setGuidelines] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);
  function fetchData() {
    fetchDisciplineList(
      {},
      (disciplineData) => {
        setDisciplineList(disciplineData.data);
        fetchCategoryList(
          {},
          (data) => {
            setGuidelines(data.guidelines);
            const list = data.data;
            list.forEach((item) => {
              item.relatedDiscipline = disciplineData.data.find(
                (x) => x.id === item.discipline_id
              ).name;
              item.is_media_link_required_comp = item.is_media_link_required ? (
                <IconButton disabled className="Success-icon-color">
                  <Icon icon="akar-icons:circle-check" width={24} height={24} />
                </IconButton>
              ) : (
                <IconButton disabled className="Error-icon-color">
                  <Icon color="error" icon="akar-icons:circle-x" width={24} height={24} />
                </IconButton>
              );
              item.is_media_link_required_filter = item.is_media_link_required ? 'Yes' : 'No';
              item.is_file_required_comp = item.is_file_required ? (
                <IconButton disabled className="Success-icon-color">
                  <Icon icon="akar-icons:circle-check" width={24} height={24} />
                </IconButton>
              ) : (
                <IconButton disabled className="Error-icon-color">
                  <Icon color="error" icon="akar-icons:circle-x" width={24} height={24} />
                </IconButton>
              );
              item.is_file_required_filter = item.is_file_required ? 'Yes' : 'No';
              item.edit = (
                <IconButton color="secondary" onClick={() => handleEditCategory(item)}>
                  <Icon icon="ci:edit" width={24} height={24} />
                </IconButton>
              );
              item.delete = (
                <IconButton color="secondary" onClick={() => handleDeleteCategory(item.id)}>
                  <Icon icon="fluent:delete-48-filled" width={24} height={24} />
                </IconButton>
              );
            });
            setTableData(list);
          },
          () => {}
        );
      },
      () => {}
    );
  }
  function handleAddCategory() {
    setMode(ADD_CATEGORY);
    setAddUpdateDialog(true);
  }
  function handleEditCategory(data) {
    setMode(UPDATE_CATEGORY);
    setAddUpdateDialog(true);
    setEditData(data);
  }
  function refreshData() {
    setAddUpdateDialog(false);
    fetchData();
  }
  function handleDeleteCategory(id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'This action will delete category permanently',
        () => {
          deleteCategory(
            { id },
            () => {
              fetchData();
              dispatch(setSnackbar('success', 'The category has been deleted'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }

  return (
    <Page title="Category Settings | Genius">
      {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
      <MuiTable
        title={
          <div className="header-button-style">
            <Button variant="outlined" onClick={handleAddCategory}>
              Add New
            </Button>
          </div>
        }
        tableData={tableData}
        tableColumns={headers}
      />
      <CustomDialog
        onClose={() => setAddUpdateDialog(false)}
        open={addUpdateDialog}
        title={mode === ADD_CATEGORY ? 'ADD' : 'UPDATE'}
      >
        <DialogContent>
          <AddUpdateDialog
            disciplineList={disciplineList}
            data={editData}
            mode={mode}
            handleClose={() => setAddUpdateDialog(false)}
            refreshData={refreshData}
          />
        </DialogContent>
      </CustomDialog>
    </Page>
  );
}

Index.propTypes = {
  dispatch: PropTypes.func.isRequired
};

export default connect(() => ({}))(Index);
