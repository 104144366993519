import { useEffect, useState } from 'react';
import { Button, Divider, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as React from 'react';
import Box from '@mui/material/Box';
import {
  deleteJudgingFormQuestion,
  getJudgingFormDetail,
  updateJudgingFormQuestionRank
} from '../_apis/serviceCalls';
import QuestionsHeader from './QuestionsHeader/QuestionsHeader';
import AddQuestion from './AddQuestion/AddQuestion';
import { setConfirmationAlert, setSnackbar } from '../../../../../actions/alertActions';
import QuestionDetail from './QuestionDetail/QuestionDetail';
import QuestionsDragAndDrop from './QuestionsDragAndDrop/QuestionsDragAndDrop';

function Index(props) {
  const { id, dispatch, handleNext, handleBack } = props;
  const [questionList, setQuestionList] = useState(null);
  const [updatedQuestionList, setUpdatedQuestionList] = useState(null);
  const [addQuestionDialogShow, setAddQuestionDialogShow] = useState(false);
  const [rankUpdateAvailable, setRankUpdateAvailable] = useState(false);
  const [updateRankLoading, setUpdateRankLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    getJudgingFormDetail(
      { judging_form_id: id },
      (data) => {
        const _questions = data.questions;
        _questions.sort((a, b) => a.rank - b.rank);
        setQuestionList(_questions);
        setUpdatedQuestionList(_questions);
      },
      () => {}
    );
  }

  function handleUpdateQuestionRank() {
    setUpdateRankLoading(true);
    const rankList = [];
    updatedQuestionList.forEach((question, index) => {
      const obj = {
        judging_form_question_id: question.id,
        rank: index + 1
      };
      rankList.push(obj);
    });
    const param = {
      judging_form_question_ranks: rankList
    };
    updateJudgingFormQuestionRank(
      param,
      () => {
        setUpdateRankLoading(false);
        fetchData();
        setRankUpdateAvailable(false);
      },
      () => {
        setUpdateRankLoading(false);
      }
    );
  }

  function onDragEnd(result) {
    const { destination, source, reason } = result;
    if (!destination || reason === 'CANCEL') {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const list = Object.assign([], updatedQuestionList);
    const droppedQuestion = updatedQuestionList[source.index];

    list.splice(source.index, 1);
    list.splice(destination.index, 0, droppedQuestion);
    setUpdatedQuestionList(list);
  }

  function cancelDragAndDrop() {
    setRankUpdateAvailable(false);
    setUpdatedQuestionList(questionList);
  }

  function refreshData() {
    fetchData();
    setAddQuestionDialogShow(false);
  }

  function handleDeleteQuestion(judging_form_question_id) {
    const param = { judging_form_question_id, force_delete: 0 };
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'As a result of this process, the question will be deleted irreversibly for all users, are you sure?',
        () => {
          deleteJudgingFormQuestion(
            param,
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
              dispatch(setSnackbar('success', 'Question deleted.'));
              fetchData();
            },
            (error) => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
              if (error.http_status_code === 409) {
                forceDelete(judging_form_question_id, error.message);
              }
            }
          );
        }
      )
    );
  }

  function forceDelete(judging_form_question_id, message) {
    dispatch(
      setConfirmationAlert('Are you sure?', 'YES, FORCE DELETE', message, () => {
        deleteJudgingFormQuestion(
          { judging_form_question_id, force_delete: 1 },
          () => {
            dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            dispatch(setSnackbar('success', 'Question deleted.'));
            fetchData();
          },
          () => {
            dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
          }
        );
      })
    );
  }

  return (
    <Stack spacing={2}>
      <Divider style={{ marginTop: 20 }} />
      <QuestionsHeader
        rankUpdateAvailable={rankUpdateAvailable}
        handleUpdateQuestionRank={handleUpdateQuestionRank}
        updateRankLoading={updateRankLoading}
        cancelDragAndDrop={cancelDragAndDrop}
        setAddQuestionDialogShow={setAddQuestionDialogShow}
        questionList={questionList}
        setRankUpdateAvailable={setRankUpdateAvailable}
      />
      {questionList &&
        questionList.length > 0 &&
        !rankUpdateAvailable &&
        questionList.map((question, index) => (
          <QuestionDetail
            question={question}
            key={index}
            refreshData={fetchData}
            handleDeleteQuestion={handleDeleteQuestion}
          />
        ))}
      <QuestionsDragAndDrop
        rankUpdateAvailable={rankUpdateAvailable}
        onDragEnd={onDragEnd}
        updatedQuestionList={updatedQuestionList}
      />
      {addQuestionDialogShow && (
        <AddQuestion
          id={id}
          closeDialog={() => setAddQuestionDialogShow(false)}
          handleSuccess={refreshData}
        />
      )}
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Button variant="outlined" color="inherit" sx={{ mr: 1 }} onClick={handleBack}>
          Back
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button type="submit" variant="outlined" onClick={handleNext}>
          Next
        </Button>
      </Box>
    </Stack>
  );
}

Index.propTypes = {
  dispatch: PropTypes.func,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  id: PropTypes.number
};

export default connect(() => ({}))(Index);
