export function getSummaryTableData(data) {
  return [
    {
      name: 'Art',
      t: data.art_total,
      rf: data.art_review_finalist,
      cpc: data.art_confirmed_project_count,
      sc: data.art_student_count,
      fsc: data.art_finalist_student_count,
      asc: data.art_attended_student_count,
      psc: data.art_paid_student_count,
      pwpsc: data.art_project_with_paid_student_count,
      p: data.art_paid
    },
    {
      name: 'Business',
      t: data.business_total,
      rf: data.business_review_finalist,
      sc: data.business_student_count,
      asc: data.business_attended_student_count,
      cpc: data.business_confirmed_project_count,
      fsc: data.business_finalist_student_count,
      psc: data.business_paid_student_count,
      pwpsc: data.business_project_with_paid_student_count,
      p: data.business_paid
    },
    {
      name: 'Coding',
      t: data.coding_total,
      rf: data.coding_review_finalist,
      sc: data.coding_student_count,
      asc: data.coding_attended_student_count,
      cpc: data.coding_confirmed_project_count,
      fsc: data.coding_finalist_student_count,
      psc: data.coding_paid_student_count,
      pwpsc: data.coding_project_with_paid_student_count,
      p: data.coding_paid
    },
    {
      name: 'Creative Writing',
      t: data.creative_writing_total,
      rf: data.creative_writing_review_finalist,
      sc: data.creative_writing_student_count,
      asc: data.creative_writing_attended_student_count,
      cpc: data.creative_writing_confirmed_project_count,
      fsc: data.creative_writing_finalist_student_count,
      psc: data.creative_writing_paid_student_count,
      pwpsc: data.creative_writing_project_with_paid_student_count,
      p: data.creative_writing_paid
    },
    {
      name: 'Music',
      t: data.music_total,
      rf: data.music_review_finalist,
      sc: data.music_student_count,
      asc: data.music_attended_student_count,
      cpc: data.music_confirmed_project_count,
      fsc: data.music_finalist_student_count,
      psc: data.music_paid_student_count,
      pwpsc: data.music_project_with_paid_student_count,
      p: data.music_paid
    },
    {
      name: 'Robotics',
      t: data.robotics_total,
      rf: data.robotics_review_finalist,
      sc: data.robotics_student_count,
      asc: data.robotics_attended_student_count,
      cpc: data.robotics_confirmed_project_count,
      fsc: data.robotics_finalist_student_count,
      psc: data.robotics_paid_student_count,
      pwpsc: data.robotics_project_with_paid_student_count,
      p: data.robotics_paid
    },
    {
      name: 'Science',
      t: data.science_total,
      rf: data.science_review_finalist,
      sc: data.science_student_count,
      asc: data.science_attended_student_count,
      cpc: data.science_confirmed_project_count,
      fsc: data.science_finalist_student_count,
      psc: data.science_paid_student_count,
      pwpsc: data.science_project_with_paid_student_count,
      p: data.science_paid
    },
    {
      name: 'Short Film',
      t: data.short_film_total,
      rf: data.short_film_review_finalist,
      sc: data.short_film_student_count,
      asc: data.short_film_attended_student_count,
      cpc: data.short_film_confirmed_project_count,
      fsc: data.short_film_finalist_student_count,
      psc: data.short_film_paid_student_count,
      pwpsc: data.short_film_project_with_paid_student_count,
      p: data.short_film_paid
    },
    {
      name: 'Total',
      t: data.summary_total,
      rf: data.summary_review_finalist,
      sc: data.summary_student_count,
      asc: data.summary_attended_student_count,
      cpc: data.summary_confirmed_project_count,
      fsc: data.summary_finalist_student_count,
      psc: data.summary_paid_student_count,
      pwpsc: data.summary_project_with_paid_student_count,
      p: data.summary_paid
    }
  ];
}
