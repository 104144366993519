const HOST = process.env.REACT_APP_REST_API_HOST;

const GET_DR_PROJECT = {
  method: 'GET',
  path: `${HOST}go_admin/data_recovery/project`
};
const PATCH_DR_PROJECT = {
  method: 'PATCH',
  path: `${HOST}go_admin/data_recovery/project`
};
const GET_DR_PARTICIPANT = {
  method: 'GET',
  path: `${HOST}go_admin/data_recovery/participant`
};
const PATCH_DR_PARTICIPANT = {
  method: 'PATCH',
  path: `${HOST}go_admin/data_recovery/participant`
};
const GET_DR_SCHOOL = {
  method: 'GET',
  path: `${HOST}go_admin/data_recovery/school`
};
const PATCH_DR_SCHOOL = {
  method: 'PATCH',
  path: `${HOST}go_admin/data_recovery/school`
};

module.exports = {
  GET_DR_PROJECT,
  PATCH_DR_PROJECT,
  GET_DR_PARTICIPANT,
  PATCH_DR_PARTICIPANT,
  GET_DR_SCHOOL,
  PATCH_DR_SCHOOL
};
