const GENERAL_INFO = 4;
const FILE_INFO = 6;
const STUDENT_INFO = 8;
const FAIR_INFO = 10;
const ONLINE_GENERAL_INFO = 3;
const ONLINE_FILE_INFO = 5;
const ONLINE_STUDENT_INFO = 7;
const ONLINE_FAIR_INFO = 9;

export function guidelineMaker(guidelines) {
  const generalInfo = guidelines.find(
    (item) => item.id === GENERAL_INFO || item.id === ONLINE_GENERAL_INFO
  );
  const fileInfo = guidelines.find((item) => item.id === FILE_INFO || item.id === ONLINE_FILE_INFO);
  const studentInfo = guidelines.find(
    (item) => item.id === STUDENT_INFO || item.id === ONLINE_STUDENT_INFO
  );
  const fairInfo = guidelines.find((item) => item.id === FAIR_INFO || item.id === ONLINE_FAIR_INFO);
  return [generalInfo, fileInfo, studentInfo, fairInfo];
}
