import { MenuItem, Stack, TextField } from '@material-ui/core';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@material-ui/lab';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { setLoading, setSnackbar } from '../../../../../actions/alertActions';
import { getTermList } from '../../../_apis/adminApi';
import { copyJudgingForm } from '../_apis/serviceCalls';

function CopyDialog(props) {
  const { data, handleClose, dispatch, refreshData } = props;
  const [termList, setTermList] = useState([]);

  useEffect(() => {
    getTermList(
      {},
      (data) => {
        setTermList(data);
      },
      () => {}
    );
  }, []);

  const Schema = Yup.object().shape({
    target_term_id: Yup.number().required('Term is required')
  });
  const formik = useFormik({
    initialValues: {
      target_term_id: ''
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      dispatch(setLoading(1, 'Loading...'));
      copyJudgingForm(
        { ...values, judging_form_id: data.judging_form_id },
        () => {
          dispatch(setLoading(0, ''));
          refreshData();
          dispatch(setSnackbar('success', 'The form has been copied'));
        },
        () => {
          dispatch(setLoading(0, ''));
        }
      );
    }
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{ mt: 1 }}>
          <TextField
            fullWidth
            label="Target Term"
            select
            {...getFieldProps('target_term_id')}
            error={Boolean(touched.target_term_id && errors.target_term_id)}
            helperText={touched.target_term_id && errors.target_term_id}
          >
            {termList.map((term) => (
              <MenuItem key={term.id} value={term.id}>
                {term.description}
              </MenuItem>
            ))}
          </TextField>
          <Stack direction="row" justifyContent="end" spacing={1}>
            <LoadingButton size="small" type="submit" variant="outlined">
              Copy
            </LoadingButton>
            <Button size="small" color="error" onClick={handleClose} variant="outlined">
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

CopyDialog.propTypes = {
  data: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired
};

export default connect(() => ({}))(CopyDialog);
