import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Typography, Container } from '@material-ui/core';
import { MotionContainer, varBounceIn } from '../../components/animate';

export default function SuccessPage() {
  return (
    <Container>
      <MotionContainer initial="initial" open>
        <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
          <motion.div variants={varBounceIn}>
            <Typography sx={{ color: 'text.secondary' }}>
              Thank you so much for your time in completing our survey. It is greatly appreciated,
              as we will use your feedback to create a better Genius Olympiad experience.
            </Typography>
          </motion.div>
          <motion.div variants={varBounceIn}>
            <Box
              component="img"
              src="/static/illustrations/success.png"
              sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
            />
          </motion.div>
          <Button to="/" size="large" variant="contained" component={RouterLink}>
            Go to Home
          </Button>
        </Box>
      </MotionContainer>
    </Container>
  );
}
