const HOST = process.env.REACT_APP_REST_API_HOST;

const GET_AWARD_PROJECT = {
  method: 'GET',
  path: `${HOST}go_admin/award_operation/award/project`
};
const ADD_PROJECT_MEDAL = {
  method: 'POST',
  path: `${HOST}go_admin/award_operation/award/project_medal`
};
const UPDATE_PROJECT_MEDAL_TYPE = {
  method: 'PUT',
  path: `${HOST}go_admin/award_operation/award/project_medal_type`
};
const GET_MEDAL_TYPE = {
  method: 'GET',
  path: `${HOST}go_admin/award_operation/award/medal_type`
};
const UPDATE_AWARD_SETTING = {
  method: 'PATCH',
  path: `${HOST}go_admin/award_operation/award/setting`
};
const GET_AWARD_JUDGING_DETAIL = {
  method: 'GET',
  path: `${HOST}go_admin/award_operation/award/judging_detail`
};
const DELETE_AWARD_JUDGING_DETAIL = {
  method: 'DELETE',
  path: `${HOST}go_admin/award_operation/award/judging_detail`
};
const DELETE_ROBOTICS_COMPETITION = {
  method: 'DELETE',
  path: `${HOST}go_admin/award_operation/award/robotics_competition`
};
const ADD_AWARD_INCLUDE_REVIEW = {
  method: 'POST',
  path: `${HOST}go_admin/award_operation/award/include_review`
};
const GET_COUNTRY_REPORTS = {
  method: 'GET',
  path: `${HOST}go_admin/award_operation/report/country_report`
};
const GET_SCHOOL_REPORTS = {
  method: 'GET',
  path: `${HOST}go_admin/award_operation/report/school_report`
};
const GET_PROJECT_LIST = {
  method: 'GET',
  path: `${HOST}go_admin/award_operation/report/project_list`
};
const GET_TEMPLATE_LIST = {
  method: 'GET',
  path: `${HOST}go_admin/award_operation/certificate_template/template_list`
};
const GET_TEMPLATE_TYPE = {
  method: 'GET',
  path: `${HOST}go_admin/award_operation/certificate_template/type`
};
const GET_TEMPLATE = {
  method: 'GET',
  path: `${HOST}go_admin/award_operation/certificate_template`
};
const ADD_TEMPLATE = {
  method: 'POST',
  path: `${HOST}go_admin/award_operation/certificate_template`
};
const ADD_CEREMONY = {
  method: 'POST',
  path: `${HOST}go_admin/award_operation/ceremony`
};
const DELETE_TEMPLATE = {
  method: 'DELETE',
  path: `${HOST}go_admin/award_operation/certificate_template`
};
const TRANSFER_PROJECTS = {
  method: 'POST',
  path: `${HOST}go_admin/award_operation/transfer`
};
module.exports = {
  GET_AWARD_PROJECT,
  GET_MEDAL_TYPE,
  UPDATE_PROJECT_MEDAL_TYPE,
  UPDATE_AWARD_SETTING,
  GET_AWARD_JUDGING_DETAIL,
  DELETE_AWARD_JUDGING_DETAIL,
  DELETE_ROBOTICS_COMPETITION,
  ADD_AWARD_INCLUDE_REVIEW,
  GET_COUNTRY_REPORTS,
  GET_SCHOOL_REPORTS,
  GET_PROJECT_LIST,
  GET_TEMPLATE_LIST,
  GET_TEMPLATE,
  ADD_TEMPLATE,
  DELETE_TEMPLATE,
  GET_TEMPLATE_TYPE,
  ADD_PROJECT_MEDAL,
  ADD_CEREMONY,
  TRANSFER_PROJECTS
};
