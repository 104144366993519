import { Button, DialogContent, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CustomDialog from '../../../../components/CustomComponents/CustomDialog';
import { getAttendedStudentList, updateFlagShow } from '../serviceCalls';
import MuiTable from '../../../../components/CustomComponents/MuiTable';
import headers from '../../../Admin/PerformanceOperation/FlagShow/_utils/headerVariablesForStudent';
import TableBox from '../../../../components/CustomComponents/CustomCard/TableBox';

function FlagDialog(props) {
  const { handleClose, dispatch } = props;
  const [selectedStudent, setSelectedStudent] = useState([]);
  const [studentList, setStudentList] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    getAttendedStudentList({}, (data) => {
      setStudentList(data);
    });
  }
  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedStudent(rowsSelected);
  };
  function handleAdd() {
    const param = {
      student_id: studentList[selectedStudent[0]].student_id
    };

    updateFlagShow(
      param,
      () => {
        handleClose();
        dispatch({ type: 'RESET_FLAG_SHOW' });
      },
      () => {}
    );
  }

  return (
    <CustomDialog maxWidth="lg" title="Flag Show" disableBackdropClick>
      <DialogContent>
        <Stack spacing={3}>
          <TableBox
            title=""
            Comp={
              <p>
                Congratulations! Your team has been chosen to represent your country in the flag
                show during the opening ceremony. Please select one of your students to participate
                and ensure that he/she is present at the ceremony and seated in the assigned area
                for your country. Once your country is announced, the selected student must take the
                provided flag and proceed to the stage. Please note that only the provided flag
                should be used.
                <br />
                The selected student should notify our staff that he/she will attend the flag show
                at the registration desk where you take your ID badges.
                <br />
                Thank you for your participation in GENIUS Olympiad!
              </p>
            }
          />
          <MuiTable
            tableData={studentList}
            tableColumns={headers}
            customOption={{
              selectableRows: 'single',
              onRowSelectionChange,
              rowsSelected: selectedStudent
            }}
          />
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="end">
            <Button variant="outlined" onClick={handleAdd} disabled={selectedStudent.length === 0}>
              Add
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </CustomDialog>
  );
}

FlagDialog.propTypes = {
  handleClose: PropTypes.func,
  dispatch: PropTypes.func
};
export default connect(() => ({}))(FlagDialog);
