import { MenuItem, Stack, TextField } from '@material-ui/core';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { setLoading, setSnackbar } from '../../../../../actions/alertActions';
import { getDisciplineList, getTermList } from '../../../_apis/adminApi';
import { addJudgingForm, getJudgingFormDetail, updateJudgingForm } from '../_apis/serviceCalls';
import { getDuration, getDurationString } from '../../../../../utils/formatTime';

const UPDATE_FORM = 2;

function FormInfoView(props) {
  const { data, mode, dispatch, handleNext, handleBack, setId, id } = props;
  const [disciplineList, setDisciplineList] = useState([]);
  const [termList, setTermList] = useState([]);
  const [formData, setFormData] = useState(data);

  useEffect(() => {
    getDisciplineList(
      {},
      (data) => {
        setDisciplineList(data);
      },
      () => {}
    );
    getTermList(
      {},
      (data) => {
        setTermList(data);
      },
      () => {}
    );
    if (id) {
      getJudgingFormDetail(
        { judging_form_id: id },
        (_data) => {
          setFormData(_data);
          handleSetValues(_data);
        },
        () => {}
      );
    }
  }, []);

  const Schema = Yup.object().shape({
    discipline_id: Yup.number().required('Discipline is required'),
    term_id: Yup.number().required('Term is required'),
    name: Yup.string().max(500, 'Too Long!').required('Form Name is required'),
    duration: Yup.string().required('Duration is required')
  });
  const formik = useFormik({
    initialValues: {
      discipline_id: mode === UPDATE_FORM ? formData.discipline_id : '',
      term_id: mode === UPDATE_FORM ? formData.term_id : '',
      name: mode === UPDATE_FORM ? formData.judging_form_name : '',
      duration: mode === UPDATE_FORM ? getDurationString(formData.duration) : ''
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      dispatch(setLoading(1, 'Loading...'));
      if (!id) {
        const _duration = getDuration(values.duration);
        const param = { ...values, duration: _duration };
        addJudgingForm(
          param,
          (_data) => {
            setId(_data.id);
            dispatch(setSnackbar('success', 'The form has been added'));
            dispatch(setLoading(0, ''));
            handleNext();
          },
          () => {
            dispatch(setLoading(0, ''));
          }
        );
      } else {
        const _duration = getDuration(values.duration);
        const param = { ...values, duration: _duration, judging_form_id: formData.judging_form_id };
        updateJudgingForm(
          param,
          () => {
            dispatch(setLoading(0, ''));
            handleNext();
            dispatch(setSnackbar('success', 'The form has been updated'));
          },
          () => {
            dispatch(setLoading(0, ''));
          }
        );
      }
    }
  });
  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

  function handleSetValues(_data) {
    setFieldValue('discipline_id', _data?.discipline_id || '');
    setFieldValue('term_id', _data?.term_id || '');
    setFieldValue('name', _data?.judging_form_name || '');
    setFieldValue('duration', getDurationString(_data.duration) || '');
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 1 }}>
            <TextField
              fullWidth
              label="Disciplines"
              select
              {...getFieldProps('discipline_id')}
              error={Boolean(touched.discipline_id && errors.discipline_id)}
              helperText={touched.discipline_id && errors.discipline_id}
            >
              {disciplineList.map((discipline) => (
                <MenuItem key={discipline.id} value={discipline.id}>
                  {discipline.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              fullWidth
              label="Terms"
              select
              {...getFieldProps('term_id')}
              error={Boolean(touched.term_id && errors.term_id)}
              helperText={touched.term_id && errors.term_id}
            >
              {termList.map((term) => (
                <MenuItem key={term.id} value={term.id}>
                  {term.description}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 1 }}>
            <TextField
              fullWidth
              label="Form Name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
            <TextField
              fullWidth
              label="Duration(minutes)"
              type="number"
              {...getFieldProps('duration')}
              error={Boolean(touched.duration && errors.duration)}
              helperText={touched.duration && errors.duration}
            />
          </Stack>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button variant="outlined" color="inherit" sx={{ mr: 1 }} onClick={handleBack}>
              Cancel
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button type="submit" variant="outlined">
              Next
            </Button>
          </Box>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

FormInfoView.propTypes = {
  data: PropTypes.object,
  mode: PropTypes.number.isRequired,
  handleNext: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  setId: PropTypes.func,
  id: PropTypes.number
};

export default connect(() => ({}))(FormInfoView);
