import * as React from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import FormControl from '@mui/material/FormControl';
import { FormLabel, Radio, RadioGroup, Stack, TextField } from '@material-ui/core';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/styles';
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles(() => ({
  avatar: {
    backgroundColor: blue[500]
  },
  cardAction: {
    float: 'right'
  }
}));

function QuestionViewDetail(props) {
  const { question, answerChange, commentChange } = props;
  const [value, setValue] = useState('');
  const [comment, setComment] = useState('');
  const { scale } = question;
  const classes = useStyles();

  const scaleList = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < scale + 1; i++) {
    scaleList.push(i.toString());
  }

  const handleChange = (event) => {
    setValue(event.target.value);
    answerChange(question.rank - 1, event.target.value);
  };

  const handleChangeComment = (event) => {
    setComment(event.target.value);
    commentChange(question.rank - 1, event.target.value);
  };

  return (
    <Card className="drag-drop-inside-card-style">
      <CardHeader
        title={
          <div style={{ fontWeight: 'bold', textAlign: 'initial' }}>
            {ReactHtmlParser(question?.markdown)}
          </div>
        }
        avatar={<Avatar className={classes.avatar}>{`${question.rank}`}</Avatar>}
      />
      <CardContent>
        <Stack spacing={1}>
          <FormControl component="fieldset">
            <FormLabel style={{ textAlign: 'initial' }} component="legend">
              Score
            </FormLabel>
            <RadioGroup
              row
              aria-label="score"
              name="row-radio-buttons-group"
              value={value}
              onChange={handleChange}
            >
              {scaleList.map((scale, index) => (
                <FormControlLabel key={index} value={scale} control={<Radio />} label={scale} />
              ))}
            </RadioGroup>
          </FormControl>
          <p style={{ textAlign: 'initial', fontSize: 'small' }}>
            Optional -Please provide feedback or suggest ideas to improve GENIUS Olympiad.
          </p>
          <TextField
            value={comment}
            disabled={!value}
            fullWidth
            type="text"
            label="Comment"
            onChange={handleChangeComment}
            inputProps={{ maxLength: 500 }}
            multiline
            maxRows={6}
          />
        </Stack>
      </CardContent>
    </Card>
  );
}

QuestionViewDetail.propTypes = {
  question: PropTypes.object,
  answerChange: PropTypes.func,
  commentChange: PropTypes.func
};

export default QuestionViewDetail;
