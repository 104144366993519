import * as React from 'react';
import { Button, Card, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TextField } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import Badge from '../../../../../components/CustomComponents/Badge/Badge';
import { addPresentationForPO, getProjectInfo } from '../../serviceCalls';
import { setSnackbar } from '../../../../../actions/alertActions';

function PresentationLinkPart(props) {
  const { project_id, dispatch } = props;
  const [link, setLink] = useState('');

  useEffect(() => {
    getProjectInfo(
      { project_id },
      (data) => {
        setLink(data.project.presentation_link ? data.project.presentation_link : '');
      },
      () => {}
    );
  }, []);

  const [isFrameShow, setIsFrameShow] = useState(false);
  const Schema = Yup.object().shape({
    link: Yup.string()
      .max(500, 'Too Long!')
      .matches(
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/,
        'Enter a valid YouTube link!'
      )
      .required('YouTube Presentation Link required')
  });
  const formik = useFormik({
    initialValues: {
      link
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      const param = {
        project_id,
        link: values.link
      };
      addPresentationForPO(
        param,
        () => {
          dispatch(setSnackbar('success', 'Presentation link has been updated'));
        },
        () => {}
      );
    }
  });
  const { errors, touched, getFieldProps, values, setFieldValue, handleSubmit } = formik;

  useEffect(() => {
    setFieldValue('link', link);
  }, [link]);

  useEffect(() => {
    if (values.link === '') {
      setIsFrameShow(false);
    }
  }, [values]);

  function getId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  }
  const videoId = getId(values.link);
  const iframeMarkup = `<iframe width="560" height="315" src="//www.youtube.com/embed/${videoId}" frameborder="0" allowfullscreen></iframe>`;

  return (
    <Card sx={{ padding: 2, mt: 2 }}>
      <Badge color="submitColor"> Presentation Link </Badge>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit} spacing={2}>
          <Stack spacing={3}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              style={{ marginTop: 20, display: 'flex', alignItems: 'center' }}
              spacing={1}
            >
              <TextField
                fullWidth
                type="link"
                label="Presentation Link"
                {...getFieldProps('link')}
                error={Boolean(touched.link && errors.link)}
                helperText={touched.link && errors.link}
              />
              <div>
                <Button
                  variant="outlined"
                  disabled={Boolean(touched.link && errors.link)}
                  onClick={() => setIsFrameShow(true)}
                >
                  Preview
                </Button>
              </div>
            </Stack>
            {isFrameShow && <div> {ReactHtmlParser(iframeMarkup)} </div>}
            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="end" spacing={2}>
              <Button type="submit" variant="outlined" disabled={!isFrameShow}>
                Save
              </Button>
            </Stack>
          </Stack>
        </Form>
      </FormikProvider>
    </Card>
  );
}

PresentationLinkPart.propTypes = {
  dispatch: PropTypes.func.isRequired,
  project_id: PropTypes.number.isRequired
};

export default connect(() => ({}))(PresentationLinkPart);
