import { Stack } from '@material-ui/core';
import { Button } from '@mui/material';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@material-ui/lab';
import { getReportFile, getReportFileMeta } from '../../../serviceCalls';

function ViewFileDialog(props) {
  const { handleClose, file } = props;
  const [url, setUrl] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const [type, setType] = useState(null);

  useEffect(() => {
    // const file_name = file.name.split('.')[0];
    const param = {
      file_id: file.id
    };
    getReportFile(
      param,
      (data) => {
        const { url } = data;
        const extension = url.substring(url.lastIndexOf('.') + 1, url.lastIndexOf('?'));
        setType(extension);
        setUrl(url);
      },
      () => {}
    );
  }, []);

  function handleDownload() {
    setDownloading(true);
    const file_name = file.name.split('.')[0];
    const param = {
      id: file.id,
      file_name
    };
    getReportFileMeta(
      param,
      () => {
        setDownloading(false);
      },
      () => {
        setDownloading(false);
      }
    );
  }

  return (
    <Stack spacing={3} sx={{ height: '100%', paddingBottom: 10 }}>
      {type === 'pdf' ? (
        // eslint-disable-next-line jsx-a11y/iframe-has-title
        <iframe src={url} className="pdf-view" sandbox />
      ) : (
        // eslint-disable-next-line jsx-a11y/alt-text
        <img className="file-image-view" src={url} />
      )}
      <div>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="center">
          <LoadingButton loading={downloading} variant="outlined" onClick={handleDownload}>
            Download
          </LoadingButton>
          <Button variant="outlined" color="error" onClick={handleClose}>
            Cancel
          </Button>
        </Stack>
      </div>
    </Stack>
  );
}

ViewFileDialog.propTypes = {
  handleClose: PropTypes.func,
  file: PropTypes.object
};

export default ViewFileDialog;
