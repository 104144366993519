import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LandingPageCard from '../../../components/CustomComponents/CustomCard/LandingPageCard';
import Page from '../../../components/Page';

const CARDS = [
  {
    id: 1,
    name: 'Participant',
    description: 'From this module you can arrange participant.'
  },
  {
    id: 2,
    name: 'Invitation Letters',
    description: 'From this module you can arrange invitation letters.'
  },
  {
    id: 3,
    name: 'VIP',
    description: 'From this module you can arrange VIP.'
  }
];

function Index() {
  const navigate = useNavigate();
  function generate(data) {
    // eslint-disable-next-line no-lone-blocks
    {
      // eslint-disable-next-line no-unused-expressions
      data.id === 1
        ? navigate('/dashboard/participation/participant', { replace: true })
        : data.id === 2
        ? navigate('/dashboard/participation/invitation_letters', { replace: true })
        : navigate('/dashboard/participation/vip', { replace: true });
    }
  }

  return (
    <Page title="Participation | Genius">
      <Grid container spacing={2}>
        {CARDS.map((item, index) => (
          <Grid item xs={3} key={index}>
            <LandingPageCard data={item} generate={generate} />
          </Grid>
        ))}
      </Grid>
    </Page>
  );
}

export default Index;
