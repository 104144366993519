import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { Stack, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import { getJudgeSessions, getSessionListData } from '../../../Register/JudgeRegister/dataMaker';
import SessionSelection from '../../../Register/JudgeRegister/SessionSelection';
import { getSessionListForJudge, updateJudge } from '../serviceCalls';

// ----------------------------------------------------------------------
const englishChar = /^[-a-zA-Z0-9& ]+$/;

function JudgeForm(props) {
  const { data, handleRefresh, handleClose } = props;
  const [sessionList, setSessionList] = useState(null);
  const [morningChecked, setMorningChecked] = useState(null);
  const [afternoonChecked, setAfternoonChecked] = useState(null);
  const [onlineCheckedList, setOnlineCheckedList] = useState([]);

  useEffect(() => {
    getSessionListForJudge(
      {},
      (_data) => {
        const refinedData = getSessionListData(_data);
        setSessionList(refinedData);
        const { judging_session_ids } = data;
        const list = judging_session_ids ? judging_session_ids.split(',') : [];
        const arr = [];
        list.forEach((element) => {
          arr.push(Number(element));
        });
        const _morningList = refinedData.find((session) => session.judging_session_type_id === 1);
        _morningList.list.forEach((item) => {
          if (arr.includes(item.id)) {
            setMorningChecked(item.id);
          }
        });
        const _afternoonList = refinedData.find((session) => session.judging_session_type_id === 2);
        _afternoonList.list.forEach((item) => {
          if (arr.includes(item.id)) {
            setAfternoonChecked(item.id);
          }
        });
        const _onlineList = refinedData.find((session) => session.judging_session_type_id === 3);
        const selectedOnlineList = [];
        if (_onlineList) {
          _onlineList.list.forEach((item) => {
            if (arr.includes(item.id)) {
              selectedOnlineList.push(item.id);
            }
          });
        }
        setOnlineCheckedList(selectedOnlineList);
      },
      () => {}
    );
  }, []);

  const RegisterSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(2, 'Too Short!')
      .max(500, 'Too Long!')
      .matches(englishChar, 'Please enter only English characters')
      .required('First name required'),
    last_name: Yup.string()
      .min(2, 'Too Short!')
      .max(500, 'Too Long!')
      .matches(englishChar, 'Please enter only English characters')
      .required('Last name required'),
    institution: Yup.string()
      .min(2, 'Too Short!')
      .max(500, 'Too Long!')
      .required('Institution required'),
    email: Yup.string()
      .max(500, 'Too Long!')
      .email('Email must be a valid email address')
      .required('Email is required')
  });
  const formik = useFormik({
    initialValues: {
      first_name: data?.first_name || '',
      last_name: data?.last_name || '',
      institution: data?.institution || '',
      email: data?.email || ''
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      const judgingSessions = getJudgeSessions(morningChecked, afternoonChecked, onlineCheckedList);
      const param = { ...values, judging_sessions: judgingSessions, judge_id: data.judge_id };
      updateJudge(
        param,
        () => {
          handleRefresh();
        },
        () => {}
      );
    }
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{ mt: 1 }}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="First name"
              {...getFieldProps('first_name')}
              error={Boolean(touched.first_name && errors.first_name)}
              helperText={touched.first_name && errors.first_name}
            />
            <TextField
              fullWidth
              label="Last name"
              {...getFieldProps('last_name')}
              error={Boolean(touched.last_name && errors.last_name)}
              helperText={touched.last_name && errors.last_name}
            />
          </Stack>
          <TextField
            fullWidth
            label="Institution"
            {...getFieldProps('institution')}
            error={Boolean(touched.institution && errors.institution)}
            helperText={touched.institution && errors.institution}
          />
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          {sessionList && (
            <SessionSelection
              sessionList={sessionList}
              morningChecked={morningChecked}
              setMorningChecked={setMorningChecked}
              afternoonChecked={afternoonChecked}
              setAfternoonChecked={setAfternoonChecked}
              onlineCheckedList={onlineCheckedList}
              setOnlineCheckedList={setOnlineCheckedList}
            />
          )}
          <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="end" spacing={2}>
            <Button type="submit" variant="outlined">
              Update
            </Button>
            <Button variant="outlined" color="error" onClick={handleClose}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

JudgeForm.propTypes = {
  data: PropTypes.object.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default connect(() => ({}))(JudgeForm);
