import { fetchApi } from '../../../../api/fetchApi';

const coding = require('./apis');

export async function getScoreboard(config, scb, ecb) {
  try {
    const url = coding.GET_SCOREBOARD;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_SCOREBOARD');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getScoreboardDetail(config, scb, ecb) {
  try {
    const url = coding.GET_SCOREBOARD_DETAIL;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_SCOREBOARD_DETAIL');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addScoreboard(config, scb, ecb) {
  try {
    const url = coding.ADD_SCOREBOARD;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_SCOREBOARD');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addCodingTeam(config, scb, ecb) {
  try {
    const url = coding.ADD_CODING_TEAM;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_CODING_TEAM');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateProjectReview(config, scb, ecb) {
  try {
    const url = coding.UPDATE_PROJECT_REVIEW;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_PROJECT_REVIEW');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateProjectFinalist(config, scb, ecb) {
  try {
    const url = coding.UPDATE_PROJECT_FINALIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_CODING_TEAM');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateCarbonScore(config, scb, ecb) {
  try {
    const url = coding.UPDATE_CARBON_SCORE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_CARBON_SCORE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}
