const HOST = process.env.REACT_APP_REST_API_HOST;

const PROJECT_LINK = {
  method: 'POST',
  path: `${HOST}go_admin/payment/project/direct`
};
const PARTICIPANT_LINK = {
  method: 'POST',
  path: `${HOST}go_admin/payment/participant/direct`
};

module.exports = {
  PROJECT_LINK,
  PARTICIPANT_LINK
};
