import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import { Delete } from '@material-ui/icons';
import { Grid, Stack, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(() => ({
  avatar: {
    backgroundColor: red[500]
  },
  cardAction: {
    float: 'right',
    padding: 5
  }
}));

export default function AffiliatedFairCard(props) {
  const classes = useStyles();
  const { data, deleteFair, atReview } = props;

  return (
    <Card>
      <CardContent>
        <Typography variant="body2" color="textPrimary" component="span">
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <span>
                <b>Fair Country: </b>
              </span>
              {data?.fair_country || '-'}
            </Grid>
            <Grid item xs={6}>
              <span>
                <b>Fair State: </b>
              </span>
              {data?.fair_state || '-'}
            </Grid>
            <Grid item xs={6}>
              <span>
                <b>Fair Name: </b>
              </span>
              {data?.fair_name || '-'}
            </Grid>
            <Grid item xs={6}>
              <span>
                <b>Fair Status: </b>
              </span>
              {data?.fair_status || '-'}
            </Grid>
          </Grid>
        </Typography>
      </CardContent>
      <CardActions className={classes.cardAction}>
        <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>
          <Tooltip title="Delete Affiliated Fair">
            <IconButton disabled={atReview} size="small" color="error" onClick={deleteFair}>
              <Delete size="small" />
            </IconButton>
          </Tooltip>
        </Stack>
      </CardActions>
    </Card>
  );
}

AffiliatedFairCard.propTypes = {
  data: PropTypes.object.isRequired,
  deleteFair: PropTypes.func.isRequired,
  atReview: PropTypes.number
};
