import { fetchApi } from '../../../../api/fetchApi';

const presentation = require('../../../../api/RestVariables/Application/presentation');

export async function getPresentation(config, scb, ecb) {
  try {
    const url = presentation.GET_PRESENTATION;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_PRESENTATION');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addPresentation(config, scb, ecb) {
  try {
    const url = presentation.ADD_PRESENTATION;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_PRESENTATION');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deletePresentation(config, scb, ecb) {
  try {
    const url = presentation.DELETE_PRESENTATION;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_PRESENTATION');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}
