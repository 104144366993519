import PropTypes from 'prop-types';
import { Box, CardHeader, Typography, Stack, IconButton } from '@material-ui/core';
import { Icon } from '@iconify/react';
import React from 'react';
import { Divider } from '@mui/material';

// ----------------------------------------------------------------------

const TASKS = [
  'Project File',
  'Youtube Link',
  'Project Payment',
  'Student Info',
  'Fair Affiliation(Optional)',
  'Application Status'
];

// ----------------------------------------------------------------------

TaskItem.propTypes = {
  task: PropTypes.string,
  data: PropTypes.string
};

TaskItemFair.propTypes = {
  task: PropTypes.string,
  data: PropTypes.string
};

function TaskItem({ task, data }) {
  return (
    <Stack direction="row" sx={{ py: 0.75, alignItems: 'center' }}>
      {data === 'Complete' ? (
        <IconButton color="success">
          <Icon icon="akar-icons:circle-check" width={24} height={24} />
        </IconButton>
      ) : data === 'Optional' ? (
        <IconButton color="secondary">
          <Icon icon="ph:dots-three-circle-duotone" width={24} height={24} />
        </IconButton>
      ) : (
        <IconButton color="error">
          <Icon color="error" icon="akar-icons:circle-x" width={24} height={24} />
        </IconButton>
      )}
      <Typography
        variant="body2"
        sx={{
          ...(task === 'Application Status' && {
            fontWeight: 'bold'
          })
        }}
      >{`${task}: ${data}`}</Typography>
    </Stack>
  );
}

function TaskItemFair({ task, data }) {
  return (
    <Stack direction="row" sx={{ py: 0.75, alignItems: 'center' }}>
      {data === 'Approved' ? (
        <IconButton color="success">
          <Icon icon="akar-icons:circle-check" width={24} height={24} />
        </IconButton>
      ) : data === 'Requested' ? (
        <IconButton color="secondary">
          <Icon icon="ph:dots-three-circle-duotone" width={24} height={24} />
        </IconButton>
      ) : (
        <IconButton color="error">
          <Icon icon="akar-icons:circle-x" width={24} height={24} />
        </IconButton>
      )}
      <Typography
        variant="body2"
        sx={{
          ...(task === 'Application Status' && {
            fontWeight: 'bold'
          })
        }}
      >{`${task}: ${data}`}</Typography>
    </Stack>
  );
}

export default function TaskList(props) {
  const { data } = props;
  const arr = [];
  arr.push(data['Project File']);
  arr.push(data['Youtube Link']);
  arr.push(data['Project Payment']);
  arr.push(data['Student Info']);
  arr.push(data['Fair Affiliation']);
  arr.push(data['Application Status']);

  return (
    <div>
      <CardHeader title={data.title} />
      <Box sx={{ px: 3, py: 1 }}>
        {TASKS.map((task, index) =>
          index === 4 ? (
            <TaskItemFair key={index} task={task} data={arr[index]} />
          ) : index === 5 ? (
            <div>
              <Divider />
              <TaskItem key={index} task={task} data={arr[index]} />
            </div>
          ) : (
            <TaskItem key={index} task={task} data={arr[index]} />
          )
        )}
      </Box>
    </div>
  );
}

TaskList.propTypes = {
  data: PropTypes.object
};
