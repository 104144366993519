import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Divider } from '@material-ui/core';
import FeaturesCheckboxTree from './featuresCheckboxTree';
import RoleEdit from './RoleEdit';

export default function RoleCreation(props) {
  const { mode, selectedRole, rolesList, onCancel, handleEditRole, roleListUpdateIndex } = props;
  return (
    <Paper style={{ marginTop: 10 }}>
      <RoleEdit
        onCancel={onCancel}
        handleEditRole={handleEditRole}
        selectedRole={selectedRole}
        roleListUpdateIndex={roleListUpdateIndex}
      />
      <Divider style={{ marginTop: 10, marginBottom: 10 }} />
      <FeaturesCheckboxTree
        mode={mode}
        rolesList={rolesList}
        selectedRole={selectedRole}
        onCancel={onCancel}
      />
    </Paper>
  );
}

RoleCreation.propTypes = {
  mode: PropTypes.number.isRequired,
  selectedRole: PropTypes.object.isRequired,
  rolesList: PropTypes.array,
  onCancel: PropTypes.func.isRequired,
  handleEditRole: PropTypes.func.isRequired,
  roleListUpdateIndex: PropTypes.number.isRequired
};
