export default function reducer(
  state = {
    profile: null,
    errorMessage: null,
    user_role: null,
    module_access: [],
    user_roles: [],
    active_role: null,
    user_profile: null,
    terms: [],
    active_term: null,
    active_term_object: null,
    isProfileOK: false
  },
  action
) {
  switch (action.type) {
    case 'SET_LOGGED_IN': {
      const {
        profile,
        user_role,
        module_access,
        user_roles,
        active_role,
        user_profile,
        isProfileOK
      } = action.payload;
      return {
        ...state,
        profile,
        user_role,
        user_roles,
        module_access,
        active_role,
        user_profile,
        isProfileOK
      };
    }
    case 'SET_TERMS': {
      return {
        ...state,
        terms: action.payload
      };
    }
    case 'SET_ACTIVE_TERM': {
      return {
        ...state,
        active_term_object: action.payload,
        active_term: action.payload.id
      };
    }
    case 'SET_USER_PROFILE': {
      const _name = `${action.payload.first_name} ${action.payload.last_name}`;
      return {
        ...state,
        profile: { ...state.profile, name: _name },
        user_profile: action.payload
      };
    }
    case 'RESET_FLAG_SHOW': {
      return {
        ...state,
        user_profile: { ...state.user_profile, flag_show: 1 }
      };
    }
    case 'UNSET_LOGGED_IN': {
      return {
        ...state,
        profile: null,
        user_role: null,
        module_access: [],
        user_roles: [],
        active_role: null,
        errorMessage: action.payload,
        user_profile: null,
        terms: [],
        active_term: null,
        active_term_object: null,
        isProfileOK: false
      };
    }
    default: {
      return state;
    }
  }
}
