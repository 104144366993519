export default [
  {
    label: 'User Id',
    name: 'user_id',
    options: {
      filter: false
    }
  },
  {
    label: 'Email',
    name: 'email',
    options: {
      filter: false
    }
  },
  {
    label: 'First N.',
    name: 'first_name',
    options: {
      filter: false
    }
  },
  {
    label: 'Last N.',
    name: 'last_name',
    options: {
      filter: false
    }
  },
  {
    label: 'Login',
    name: 'login',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    label: 'Reset Password',
    name: 'password',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    label: 'User Roles',
    name: 'roles',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    label: 'Edit',
    name: 'edit',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    label: 'Lock/Unlock',
    name: 'revoke',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    label: 'Verify',
    name: 'verify',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    label: 'Delete',
    name: 'delete',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  }
];
