const HOST = process.env.REACT_APP_REST_API_HOST;

const GET_TRIP_LIST = {
  method: 'GET',
  path: `${HOST}go_core/trip/trip/list`
};
const GET_TRIP_PARTICIPANT = {
  method: 'GET',
  path: `${HOST}go_core/trip/trip/participant`
};
const GET_DISCOUNT = {
  method: 'GET',
  path: `${HOST}go_core/trip/trip/discount`
};
const UPDATE_TRIP = {
  method: 'PUT',
  path: `${HOST}go_core/trip/trip`
};
const MAKE_PAYMENT_TRIP = {
  method: 'POST',
  path: `${HOST}go_core/trip/trip/payment`
};

module.exports = {
  GET_TRIP_LIST,
  GET_TRIP_PARTICIPANT,
  GET_DISCOUNT,
  UPDATE_TRIP,
  MAKE_PAYMENT_TRIP
};
