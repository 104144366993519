import React, { useEffect, useState } from 'react';
import { IconButton, MenuItem, Stack, TextField } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { DialogContent, Tooltip } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Email from '@mui/icons-material/Email';
import ReactHtmlParser from 'react-html-parser';
import MuiTable from '../../../../components/CustomComponents/MuiTable';
import headers from '../_utils/configHeaderVariables';
import { getPartnerConfig } from '../_apis/serviceCalls';
import CustomDialog from '../../../../components/CustomComponents/CustomDialog';
import CustomMail from '../../../../components/CustomComponents/CustomMail';
import { CUSTOM_MAIL_TYPE } from '../../../../api/RestVariables/staticVariables';
import Page from '../../../../components/Page';
import ProcessInfoBox from '../../../../components/CustomComponents/CustomCard/ProcessInfoBox';
import CapacityDialog from '../../FairOperation/Fair/utils/CapacityDialog';
import UpdateCapacityDialog from './CapacityDialog';

function Index(props) {
  const { terms, active_term, isCommunication } = props;
  const [term, setTerm] = useState(active_term);
  const [tableData, setTableData] = useState([]);
  const [mailDialogShow, setMailDialogShow] = useState(false);
  const [addMail, setAddMail] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [idList, setIdList] = useState([]);
  const [guidelines, setGuidelines] = useState([]);
  const [capacityData, setCapacityData] = useState(null);
  const [capacityDialog, setCapacityDialog] = useState(false);
  const [approveData, setApproveData] = useState(null);
  const [approveDialogShow, setApproveDialogShow] = useState(false);

  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedItems(rowsSelected);
    const arr = [];
    rowsSelected.forEach((item) => {
      arr.push(tableData[item].judge_user_id);
    });
    setIdList(arr);
  };

  useEffect(() => {
    fetchData();
  }, [term]);
  function fetchData() {
    getPartnerConfig(
      { term_id: term },
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((item) => {
          item.capacity = (
            <IconButton color="secondary" onClick={() => viewCapacity(item)}>
              <Icon icon="mdi:human-capacity-increase" />
            </IconButton>
          );
          item.edit = (
            <IconButton color="secondary" onClick={() => handleEditFair(item)}>
              <Icon icon="ci:edit" />
            </IconButton>
          );
        });
        setTableData(list);
      },
      () => {}
    );
  }

  function viewCapacity(item) {
    setCapacityData(item.discipline_capacities);
    setCapacityDialog(true);
  }
  function handleEditFair(fair) {
    setApproveData(fair);
    setApproveDialogShow(true);
  }
  function TermSelect() {
    return (
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 2 }}>
        <TextField select label="Terms" value={term} onChange={(e) => setTerm(e.target.value)}>
          {terms.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
    );
  }

  function handleRefresh() {
    setApproveDialogShow(false);
    setApproveData(null);
    setMailDialogShow(false);
    setCapacityDialog(false);
    setCapacityData(null);
    setAddMail(false);
    setSelectedItems([]);
    fetchData();
  }

  return (
    <Page title="Partner Fair Operation | Genius">
      <Stack spacing={2}>
        {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
        <MuiTable
          tableData={tableData}
          tableColumns={headers}
          title={<TermSelect />}
          customOption={{
            selectableRows: addMail ? 'multiple' : 'none',
            onRowSelectionChange,
            rowsSelected: selectedItems,
            customToolbar: () =>
              isCommunication !== -1 && (
                <Tooltip title="Send Email">
                  <IconButton onClick={() => setAddMail(true)}>
                    <Email />
                  </IconButton>
                </Tooltip>
              )
          }}
        />
        <CustomDialog
          onClose={() => setMailDialogShow(false)}
          open={mailDialogShow}
          maxWidth="lg"
          title="Send Mail"
          fullWidth
          disableEnforceFocus
        >
          <DialogContent>
            <CustomMail
              handleRefresh={handleRefresh}
              mailType={CUSTOM_MAIL_TYPE}
              idList={idList}
              handleClose={() => setMailDialogShow(false)}
            />
          </DialogContent>
        </CustomDialog>
        <CustomDialog
          onClose={() => setCapacityDialog(false)}
          open={capacityDialog}
          maxWidth="lg"
          title="Discipline Capacity"
        >
          <DialogContent>
            <CapacityDialog data={capacityData} handleClose={() => setCapacityDialog(false)} />
          </DialogContent>
        </CustomDialog>
        <CustomDialog
          maxWidth="sm"
          onClose={() => setApproveDialogShow(false)}
          open={approveDialogShow}
          title="Capacity Update"
        >
          <DialogContent>
            <UpdateCapacityDialog
              term_id={term}
              data={approveData}
              handleRefresh={handleRefresh}
              handleClose={() => setApproveDialogShow(false)}
            />
          </DialogContent>
        </CustomDialog>
      </Stack>
    </Page>
  );
}

Index.propTypes = {
  terms: PropTypes.array,
  active_term: PropTypes.number,
  isCommunication: PropTypes.number
};

export default connect((store) => ({
  terms: store.loginReducer.terms,
  active_term: store.loginReducer.active_term,
  isCommunication: store.routeReducer.isCommunication
}))(Index);
