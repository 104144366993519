import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Button, DialogContent, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { useNavigate } from 'react-router-dom';
import MuiTable from '../../../../components/CustomComponents/MuiTable';
import headers from './_utils/headerVariables';
import {
  deleteUser,
  fetchRolesList,
  fetchUsers,
  getUserRole,
  revokeUser,
  verifyUser
} from '../serviceCalls';
import CustomDialog from '../../../../components/CustomComponents/CustomDialog';
import AddUserDialog from './AddUserDialog';
import UserRoleDialog from './UserRoleDialog';
import UnlockDialog from './UnlockDialog';
import PasswordDialog from './PasswordDialog';
import { setConfirmationAlert, setSnackbar } from '../../../../actions/alertActions';
import Page from '../../../../components/Page';
import ProcessInfoBox from '../../../../components/CustomComponents/CustomCard/ProcessInfoBox';
import { dataMaker } from './_utils/dataMaker';
import {
  handleGetActiveTerm,
  handleGetTerms,
  loginWithAnotherUser
} from '../../../../actions/loginActions';

const ADD_MODE = 1;
const UPDATE_MODE = 2;
const APPLICANT = 2;
const FAIR = 3;
const REVIEWER = 4;
const JUDGE = 5;
const SUPER_USER = 21;
const PROJECT_REVIEWER = 25;
function Index(props) {
  const { dispatch } = props;
  const [mode, setMode] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [dialogShow, setDialogShow] = useState(false);
  const [roleDialogShow, setRoleDialogShow] = useState(false);
  const [unlockDialogShow, setUnlockDialogShow] = useState(false);
  const [passwordDialogShow, setPasswordDialogShow] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [editData, setEditData] = useState(null);
  const [guidelines, setGuidelines] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);
  function fetchData() {
    fetchUsers(
      {},
      (data) => {
        setGuidelines(data.guidelines);
        const updatedList = dataMaker(
          data.data,
          handleEditPassword,
          handleEditUserRoles,
          handleEditUser,
          handleVerifyUser,
          handleUnlockUser,
          handleRevokeUser,
          handleDeleteUser,
          handleLoginWithUser
        );
        setTableData(updatedList);
      },
      () => {}
    );
  }
  function handleAddNew() {
    setMode(ADD_MODE);
    setDialogShow(true);
  }
  function handleEditUser(user) {
    setMode(UPDATE_MODE);
    setDialogShow(true);
    setEditData(user);
  }
  function handleDeleteUser(user_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'This action will delete user permanently',
        () => {
          deleteUser(
            { user_id },
            () => {
              dispatch(setSnackbar('success', 'The user has been deleted'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
              handleRefresh();
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }
  function handleEditUserRoles(user_id) {
    setSelectedUserId(user_id);
    getUserRole(
      { user_id },
      (data) => {
        setUserRoles(data);
        setRoleDialogShow(true);
      },
      () => {}
    );
    fetchRolesList(
      {},
      (data) => {
        setRoleList(data.data);
      },
      () => {}
    );
  }
  function handleRevokeUser(user_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, LOCK',
        'This action will lock user permanently',
        () => {
          revokeUser(
            { user_id },
            () => {
              dispatch(setSnackbar('success', 'The user has been locked'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
              handleRefresh();
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }
  function handleUnlockUser(user_id) {
    setSelectedUserId(user_id);
    fetchRolesList(
      {},
      (data) => {
        setRoleList(data.data);
        setUnlockDialogShow(true);
      },
      () => {}
    );
  }
  function handleVerifyUser(user_id) {
    verifyUser(
      { user_id },
      () => {
        dispatch(setSnackbar('success', 'The user has been verified'));
        dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
        handleRefresh();
      },
      () => {}
    );
  }
  function handleEditPassword(user_id) {
    setPasswordDialogShow(true);
    setSelectedUserId(user_id);
  }
  function handleLoginWithUser(email) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, LOGIN',
        'Are you sure you want to login with this user?',
        () => {
          dispatch(
            loginWithAnotherUser(
              { email },
              (data) => {
                dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
                dispatch({ type: 'RESET_SNACKBAR', payload: null });
                let arr = [];
                let isAdmin = false;
                let commonModule = false;
                data.module_access.forEach((item) => {
                  if (item.application_access_key !== 'go_shield') {
                    if (item.application_access_key === 'go_admin') {
                      const adminModules = item.modules;
                      commonModule = adminModules.find((x) => x.module_access_key === 'common');
                      isAdmin = true;
                    }
                    arr = [...arr, ...item.modules];
                  }
                });
                dispatch(
                  handleGetActiveTerm(
                    () => {
                      const isProfileOK =
                        Object.keys(data.user_profile).length !== 0 ||
                        data.user_role === FAIR ||
                        data.user_role === JUDGE;
                      if (!isProfileOK) {
                        navigate('/profile', { replace: true });
                      } else if (data.user_role === FAIR) {
                        navigate('/fair', { replace: true });
                      } else if (data.user_role === APPLICANT) {
                        navigate('/dashboard', { replace: true });
                      } else if (data.user_role === REVIEWER) {
                        navigate('/reviewer', { replace: true });
                      } else if (data.user_role === PROJECT_REVIEWER) {
                        if (commonModule) {
                          dispatch(handleGetTerms());
                        }
                        navigate('/project_reviewer', { replace: true });
                      } else if (data.user_role === SUPER_USER) {
                        if (commonModule) {
                          dispatch(handleGetTerms());
                        }
                        navigate('/admin', { replace: true });
                      } else if (isAdmin) {
                        if (commonModule) {
                          dispatch(handleGetTerms());
                        }
                        navigate(`/admin/${arr[0].module_access_key}`, { replace: true });
                      } else navigate(`/dashboard/${arr[0].module_access_key}`, { replace: true });
                    },
                    () => {
                      dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
                    }
                  )
                );
              },
              () => {
                dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
              }
            )
          );
        }
      )
    );
  }
  function handleRefresh() {
    setPasswordDialogShow(false);
    setUnlockDialogShow(false);
    setRoleDialogShow(false);
    setDialogShow(false);
    fetchData();
  }

  return (
    <Page title="Users | Genius">
      <Stack spacing={2}>
        {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
        <MuiTable
          title={
            <div className="header-button-style">
              <Button variant="outlined" onClick={handleAddNew}>
                Add New
              </Button>
            </div>
          }
          tableData={tableData}
          tableColumns={headers}
          readMoreDisable
        />
      </Stack>
      <CustomDialog
        onClose={() => setDialogShow(false)}
        open={dialogShow}
        maxWidth="lg"
        title={mode === ADD_MODE ? 'Add New User' : 'Update User'}
      >
        <DialogContent>
          <AddUserDialog
            editData={editData}
            mode={mode}
            handleClose={() => setDialogShow(false)}
            handleRefresh={handleRefresh}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        onClose={() => setRoleDialogShow(false)}
        open={roleDialogShow}
        maxWidth="lg"
        title="User Roles"
      >
        <DialogContent>
          <UserRoleDialog
            userId={selectedUserId}
            roleList={roleList}
            userRoles={userRoles}
            handleClose={() => setDialogShow(false)}
            handleRefresh={handleRefresh}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        onClose={() => setUnlockDialogShow(false)}
        open={unlockDialogShow}
        maxWidth="lg"
        title="Unlock User Roles"
      >
        <DialogContent>
          <UnlockDialog
            userId={selectedUserId}
            roleList={roleList}
            handleClose={() => setUnlockDialogShow(false)}
            handleRefresh={handleRefresh}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        onClose={() => setPasswordDialogShow(false)}
        open={passwordDialogShow}
        maxWidth="lg"
        title="Reset Password"
      >
        <DialogContent>
          <PasswordDialog
            userId={selectedUserId}
            handleClose={() => setPasswordDialogShow(false)}
            handleRefresh={handleRefresh}
          />
        </DialogContent>
      </CustomDialog>
    </Page>
  );
}

Index.propTypes = {
  dispatch: PropTypes.func
};

export default connect(() => ({}))(Index);
