import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Stack, TextField, Typography } from '@material-ui/core';
import * as React from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@material-ui/lab';
import { Button } from '@mui/material';
import { makeRefundTrip } from '../serviceCalls';
import { setConfirmationAlert, setSnackbar } from '../../../../actions/alertActions';
import { fCurrency } from '../../../../utils/formatNumber';

function RefundDialog(props) {
  const { data, dispatch, handleRefresh, handleClose } = props;
  const refundableAmount =
    parseFloat(data?.amount).toFixed(2) - parseFloat(data?.total_refund).toFixed(2);

  function handleRefund(amount, description) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, REFUND',
        'As a result of this transaction, the payment will be refunded.',
        () => {
          makeRefundTrip(
            {
              payment_id: data.transaction_id,
              amount: parseFloat(amount).toFixed(2),
              participant_id: data.participant_id,
              description
            },
            () => {
              handleRefresh();
              dispatch(setSnackbar('success', 'Refund has been completed'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }
  const Schema = Yup.object().shape({
    Amount: Yup.number().min(0).max(refundableAmount).required('Refund amount required'),
    note: Yup.string().max(500, 'Too Long!').required('Refund note required')
  });
  const formik = useFormik({
    initialValues: {
      Amount: 0,
      note: ''
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      handleRefund(values.Amount, values.note);
    }
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Total Amount</TableCell>
                  <TableCell align="center">Refunded Amount</TableCell>
                  <TableCell align="center">Total Refundable Amount</TableCell>
                  <TableCell align="center">Refund</TableCell>
                  <TableCell align="center">Note</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={1} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="center" component="th" scope="row">
                    {fCurrency(data?.amount)}
                  </TableCell>
                  <TableCell align="center">{fCurrency(data?.total_refund)}</TableCell>
                  <TableCell align="center">
                    <Typography variant="h6" style={{ color: 'red' }}>
                      {fCurrency(refundableAmount)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      fullWidth
                      type="number"
                      {...getFieldProps('Amount')}
                      error={Boolean(touched.Amount && errors.Amount)}
                      helperText={touched.Amount && errors.Amount}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      fullWidth
                      type="text"
                      {...getFieldProps('note')}
                      error={Boolean(touched.note && errors.note)}
                      helperText={touched.note && errors.note}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Stack direction="row" justifyContent="end" spacing={1}>
            <LoadingButton size="small" type="submit" variant="outlined">
              Refund
            </LoadingButton>
            <Button size="small" color="error" onClick={handleClose} variant="outlined">
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

RefundDialog.propTypes = {
  data: PropTypes.array.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default connect(() => ({}))(RefundDialog);
