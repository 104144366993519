import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { IconButton } from '@material-ui/core';
import { Icon } from '@iconify/react';
import headers from './headerVariables';
import MuiTable from '../../../../../../components/CustomComponents/MuiTable';
import { deleteGroupProject, getGroupGroupProject } from '../../../serviceCalls';
import {
  setConfirmationAlert,
  setLoading,
  setSnackbar
} from '../../../../../../actions/alertActions';
import Label from '../../../../../../components/Label';
import { fCurrency } from '../../../../../../utils/formatNumber';

function ExpandView(props) {
  const { judging_group_id, dispatch, handleRefresh } = props;
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    dispatch(setLoading(1, 'Loading...'));
    getGroupGroupProject(
      { judging_group_id },
      (data) => {
        data.forEach((item) => {
          const _balance = Number(parseFloat(item.balance).toFixed(2));
          item.balance_comp =
            _balance < 0 || _balance === 0 ? (
              <Label variant="ghost" color="success">
                {fCurrency(item.balance)}
              </Label>
            ) : (
              <Label variant="ghost" color="error">
                {fCurrency(item.balance)}
              </Label>
            );
          item.balance_filter = _balance < 0 || _balance === 0 ? 'Paid' : 'Not Paid';
          item.presentation_link_comp = item.presentation_link && (
            <Label variant="ghost" color="primary">
              <a target="_blank" href={item.presentation_link} rel="noreferrer">
                Link
              </a>
            </Label>
          );
          item.presentation_link_filter = item.presentation_link ? 'Exist' : 'None';
          item.media_link_comp = item.media_link && (
            <Label variant="ghost" color="primary">
              <a target="_blank" href={item.media_link} rel="noreferrer">
                Link
              </a>
            </Label>
          );
          item.media_link_filter = item.media_link ? 'Exist' : 'None';
          item.created_at_moment = moment(item.created_at).format('YYYY-MM-DD');
          item.delete = (
            <IconButton color="secondary" onClick={() => handleDeleteProject(item.id)}>
              <Icon icon="fluent:delete-48-filled" width={24} height={24} />
            </IconButton>
          );
        });
        dispatch(setLoading(0, ''));
        setTableData(data);
      },
      () => {
        dispatch(setLoading(0, ''));
      }
    );
  }, []);

  function handleDeleteProject(project_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'This action will delete project from group permanently',
        () => {
          deleteGroupProject(
            { project_id },
            () => {
              handleRefresh();
              dispatch(setSnackbar('success', 'The project has been deleted from group'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }

  return <MuiTable tableData={tableData} tableColumns={headers} />;
}

ExpandView.propTypes = {
  judging_group_id: PropTypes.number,
  dispatch: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired
};

export default connect(() => ({}))(ExpandView);
