import React, { useEffect, useState } from 'react';
import { IconButton, Stack } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { DialogContent } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import LandingPage from './LandingPage';
import SubmissionPage from './SubmissionPage';
import { deleteJudging, getJudgingProject, startJudging } from './_apis/serviceCalls';
import Page from '../../components/Page';
import CustomDialog from '../../components/CustomComponents/CustomDialog';
import { setConfirmationAlert } from '../../actions/alertActions';
import ProcessInfoBox from '../../components/CustomComponents/CustomCard/ProcessInfoBox';

function Index(props) {
  const { dispatch } = props;
  const [tableData, setTableData] = useState([]);
  const [projectData, setProjectData] = useState(null);
  const [dialogShow, setDialogShow] = useState(false);
  const [mode, setMode] = useState('');
  const [guidelines, setGuidelines] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    getJudgingProject(
      {},
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((item) => {
          const compareDate = moment().utc(true);
          const startDate = moment(item.judging_session_start_datetime).utc();
          const endDate = moment(item.judging_session_end_datetime).utc();
          const dateAvailable = compareDate.isBetween(startDate, endDate);

          item.total_score_view = item.total_score ? item.total_score : '-';
          item.edit = (
            <IconButton
              disabled={!item.total_score || !dateAvailable}
              color="secondary"
              onClick={() => handleStartJudging(item, 'edit')}
            >
              <Icon icon="ci:edit" width={24} height={24} />
            </IconButton>
          );
          item.start = (
            <IconButton
              disabled={item.total_score || !dateAvailable}
              color="secondary"
              onClick={() => handleStartJudging(item, 'start')}
            >
              <Icon icon="material-symbols:not-started-rounded" width={24} height={24} />
            </IconButton>
          );
        });
        setTableData(list);
      },
      () => {}
    );
  }

  function handleStartJudging(project, _mode) {
    setMode(_mode);
    const params = { project_id: project.project_id };
    const buttonText = 'CONFIRM';
    const { title, project_id } = project;
    const dialogTitle = `${project_id} - ${title}`;
    const message = 'Please confirm the project id and title!';
    dispatch(
      setConfirmationAlert(dialogTitle, buttonText, message, () => {
        startJudging(params, (data) => {
          setProjectData(data);
          setDialogShow(true);
        }).then(() => dispatch({ type: 'RESET_CONFIRMATION_ALERT' }));
      })
    );
  }

  function handleSuccess() {
    setDialogShow(false);
    fetchData();
  }

  function closeClick() {
    console.log(projectData);
    if (mode === 'start') {
      dispatch(
        setConfirmationAlert(
          'Are you sure?',
          'YES, CANCEL',
          'This will delete unsaved changes permanently',
          () => {
            deleteJudging({ judging_id: projectData.judging_id }, () => {
              setDialogShow(false);
              fetchData();
            }).then(() => dispatch({ type: 'RESET_CONFIRMATION_ALERT' }));
          }
        )
      );
    } else {
      setDialogShow(false);
    }
  }

  return (
    <Page title="Judging | Genius">
      <Stack spacing={2}>
        {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
        <LandingPage tableData={tableData} />
        <CustomDialog
          onClose={closeClick}
          open={dialogShow}
          maxWidth="lg"
          title="Submission"
          fullWidth
        >
          <DialogContent>
            <SubmissionPage
              mode={mode}
              projectData={projectData}
              handleSuccess={handleSuccess}
              handleClose={() => setDialogShow(false)}
            />
          </DialogContent>
        </CustomDialog>
      </Stack>
    </Page>
  );
}

Index.propTypes = {
  dispatch: PropTypes.func
};

export default connect(() => ({}))(Index);
