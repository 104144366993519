import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DialogContent, Stack } from '@mui/material';
import CustomDialog from '../../../../../components/CustomComponents/CustomDialog';
import { getPerformanceOperation } from '../../serviceCalls';
import PerformanceContent from './PerformanceContent';

const UpdateDialog = (props) => {
  const { handleClose, selectedPerformance, handleSuccess } = props;
  const [performanceData, setPerformanceData] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    getPerformanceOperation(
      { performance_id: selectedPerformance },
      (data) => {
        setPerformanceData(data.data[0]);
      },
      () => {}
    );
  }

  return (
    <CustomDialog onClose={handleClose} open maxWidth="lg" title="Update Performance">
      <DialogContent>
        <Stack spacing={2} sx={{ mt: 1 }}>
          {performanceData && (
            <PerformanceContent
              data={performanceData}
              handleClose={handleClose}
              handleSuccess={handleSuccess}
            />
          )}
        </Stack>
      </DialogContent>
    </CustomDialog>
  );
};

UpdateDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  selectedPerformance: PropTypes.number,
  handleSuccess: PropTypes.func
};

export default connect(() => ({}))(UpdateDialog);
