import React from 'react';
import { withStyles } from '@material-ui/styles';
import { ClipLoader } from 'react-spinners';
import { css } from '@emotion/react';
import { Chip, Grid } from '@material-ui/core';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import Dialog from '@material-ui/core/Dialog';
import customLoadingStyle from './CustomLoadingStyle';

const override = css`
  display: block;
  margin: 0 auto;
`;

function FileUploadDialog() {
  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none'
        }
      }}
    >
      <Grid container className="sweet-loading" alignItems="center" direction="column">
        <Grid item xs={12}>
          <ClipLoader css={override} size={60} color="#FFFFFF" loading />
        </Grid>
        <Grid item xs={12} style={{ marginTop: 20 }}>
          <Chip
            style={{ marginRight: 50, marginLeft: 50, fontWeight: 'bold' }}
            icon={<HourglassEmptyIcon />}
            label="File upload has started. It may take some time. Please wait until it's finished"
            color="primary"
          />
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default withStyles(customLoadingStyle)(FileUploadDialog);
