import React, { useEffect, useState } from 'react';
import { DialogContent, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Icon } from '@iconify/react';
import { IconButton } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import LandingPage from './LandingPage';
import CustomDialog from '../../../../components/CustomComponents/CustomDialog';
import CopyDialog from './CopyDialog';
import ViewDialog from './ViewDialog';
import { setConfirmationAlert, setLoading, setSnackbar } from '../../../../actions/alertActions';
import { deleteJudgingForm, getJudgingForm } from './_apis/serviceCalls';
import AddUpdateStepper from './AddUpdateStepper';
import ProcessInfoBox from '../../../../components/CustomComponents/CustomCard/ProcessInfoBox';

const LANDING_MODE = 0;
const ADD_MODE = 1;
const UPDATE_MODE = 2;

function Index(props) {
  const { dispatch, active_term } = props;
  const [mode, setMode] = useState(LANDING_MODE);
  const [editData, setEditData] = useState(null);
  const [term, setTerm] = useState(active_term);
  const [tableData, setTableData] = useState([]);
  const [copyDialog, setCopyDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);
  const [guidelines, setGuidelines] = useState([]);

  useEffect(() => {
    fetchData();
  }, [term]);

  function fetchData() {
    dispatch(setLoading(1, 'Loading...'));
    getJudgingForm(
      { term_id: term },
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((item) => {
          item.score_view = item.available_score ? item.available_score : '-';
          item.copy = (
            <IconButton color="secondary" onClick={() => handleCopyForm(item)}>
              <Icon icon="bxs:copy" width={24} height={24} />
            </IconButton>
          );
          item.view = (
            <IconButton
              disabled={!item.number_of_questions}
              color="secondary"
              onClick={() => handleViewForm(item)}
            >
              <Icon icon="ep:view" width={24} height={24} />
            </IconButton>
          );
          item.edit = (
            <IconButton color="secondary" onClick={() => handleEditForm(item)}>
              <Icon icon="ci:edit" width={24} height={24} />
            </IconButton>
          );
          item.delete = (
            <IconButton color="secondary" onClick={() => handleDeleteForm(item.judging_form_id)}>
              <Icon icon="fluent:delete-48-filled" width={24} height={24} />
            </IconButton>
          );
        });
        dispatch(setLoading(0, ''));
        setTableData(list);
      },
      () => {
        dispatch(setLoading(0, ''));
      }
    );
  }

  function handleEditForm(form) {
    setEditData(form);
    setMode(UPDATE_MODE);
  }

  function handleViewForm(form) {
    setEditData(form);
    setViewDialog(true);
  }

  function handleCopyForm(form) {
    setEditData(form);
    setCopyDialog(true);
  }

  function handleRefresh() {
    setMode(LANDING_MODE);
    setEditData(null);
    setCopyDialog(false);
    fetchData();
  }

  function handleDeleteForm(judging_form_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'This action will delete form permanently',
        () => {
          deleteJudgingForm(
            { judging_form_id, force_delete: 0 },
            () => {
              handleRefresh();
              dispatch(setSnackbar('success', 'The form has been deleted'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            },
            (error) => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
              if (error.http_status_code === 409) {
                forceDelete(judging_form_id, error.message);
              }
            }
          );
        }
      )
    );
  }

  function forceDelete(judging_form_id, message) {
    dispatch(
      setConfirmationAlert('Are you sure?', 'YES, FORCE DELETE', message, () => {
        deleteJudgingForm(
          { judging_form_id, force_delete: 1 },
          () => {
            fetchData();
            dispatch(setSnackbar('success', 'The form has been deleted'));
            dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
          },
          () => {
            dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
          }
        );
      })
    );
  }

  return (
    <Stack spacing={2}>
      {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
      <LandingPage
        tableData={tableData}
        addForm={() => setMode(ADD_MODE)}
        term={term}
        setTerm={setTerm}
      />
      <CustomDialog
        onClose={() => setMode(null)}
        open={mode === ADD_MODE || mode === UPDATE_MODE}
        title={mode === ADD_MODE ? 'Add Form' : 'Update Form'}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          <AddUpdateStepper handleFinish={handleRefresh} data={editData} mode={mode} />
        </DialogContent>
      </CustomDialog>
      <CustomDialog onClose={() => setCopyDialog(false)} open={copyDialog} title="Copy Form">
        <DialogContent>
          <CopyDialog
            data={editData}
            handleClose={() => setCopyDialog(false)}
            refreshData={handleRefresh}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        maxWidth="lg"
        fullWidth
        onClose={() => setViewDialog(false)}
        open={viewDialog}
        title="View Form"
      >
        <DialogContent>
          <ViewDialog data={editData} handleClose={() => setViewDialog(false)} />
        </DialogContent>
      </CustomDialog>
    </Stack>
  );
}

Index.propTypes = {
  dispatch: PropTypes.func,
  active_term: PropTypes.number
};

export default connect((store) => ({
  active_term: store.loginReducer.active_term
}))(Index);
