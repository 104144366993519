const HOST = process.env.REACT_APP_REST_API_HOST;

const GET_HOME_PROJECT_CHECK_LIST_REPORT = {
  method: 'GET',
  path: `${HOST}go_core/home/home/project_check_list_report`
};
const GET_HOME_PARTICIPANT_CHECK_LIST_REPORT = {
  method: 'GET',
  path: `${HOST}go_core/home/home/participant_check_list_report`
};
const UPDATE_FLAG_SHOW = {
  method: 'PATCH',
  path: `${HOST}go_core/performance/flag_show`
};
const GET_ATTENDED_STUDENT_LIST = {
  method: 'GET',
  path: `${HOST}go_core/performance/flag_show/attended_student`
};

module.exports = {
  GET_HOME_PARTICIPANT_CHECK_LIST_REPORT,
  GET_HOME_PROJECT_CHECK_LIST_REPORT,
  UPDATE_FLAG_SHOW,
  GET_ATTENDED_STUDENT_LIST
};
