import { Button } from '@mui/material';
import React from 'react';
import { MenuItem, Stack, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

function Index(props) {
  const {
    setSettingDialog,
    setTerm,
    terms,
    term,
    setIncludeDialog,
    setMedalDialog,
    addMedal,
    setAddMedal,
    addCeremony,
    setAddCeremony,
    addMail,
    setAddMail,
    selectedItems,
    setMailDialogShow,
    startButtonDisabled,
    handleCancel,
    _handleAddCeremony,
    handleAddCertificate,
    addCertificate,
    setAddCertificate,
    transfer,
    setTransfer,
    handleTransfer
  } = props;
  const isPartner = process.env.REACT_APP_IS_PARTNER || process.env.REACT_APP_CUSTOM_ENV === 'dev';

  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      spacing={2}
      sx={{ mt: 2 }}
      style={{ display: 'flex', alignItems: 'center' }}
    >
      <TextField select label="Terms" value={term} onChange={(e) => setTerm(e.target.value)}>
        {terms.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.description}
          </MenuItem>
        ))}
      </TextField>
      <div>
        <Button variant="outlined" onClick={() => setSettingDialog(true)}>
          Setting
        </Button>
      </div>
      <div>
        <Button variant="outlined" onClick={() => setIncludeDialog(true)}>
          Include Review
        </Button>
      </div>
      {!addMedal && (
        <Button color="secondary" variant="outlined" onClick={() => setAddMedal(true)}>
          Medal Type Change
        </Button>
      )}
      {!addCeremony && (
        <Button color="secondary" variant="outlined" onClick={() => setAddCeremony(true)}>
          Ceremony
        </Button>
      )}
      {!addCertificate && (
        <Button color="secondary" variant="outlined" onClick={() => setAddCertificate(true)}>
          Add Certificate
        </Button>
      )}
      {!transfer && isPartner && (
        <Button color="secondary" variant="outlined" onClick={() => setTransfer(true)}>
          Transfer
        </Button>
      )}
      {addMail && (
        <div>
          <Button
            style={{ marginRight: 5 }}
            disabled={selectedItems.length === 0}
            variant="outlined"
            onClick={() => setMailDialogShow(true)}
          >
            Next
          </Button>
          <Button color="error" variant="outlined" onClick={() => setAddMail(false)}>
            Cancel
          </Button>
        </div>
      )}
      {addMedal && (
        <div>
          <Button
            color="secondary"
            variant="outlined"
            disabled={startButtonDisabled}
            onClick={() => setMedalDialog(true)}
          >
            Next
          </Button>
          <Button
            variant="outlined"
            color="error"
            style={{ marginLeft: 10 }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      )}
      {transfer && (
        <div>
          <Button
            color="secondary"
            variant="outlined"
            disabled={startButtonDisabled}
            onClick={handleTransfer}
          >
            Next
          </Button>
          <Button
            variant="outlined"
            color="error"
            style={{ marginLeft: 10 }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      )}
      {addCeremony && (
        <div>
          <Button
            color="secondary"
            variant="outlined"
            disabled={startButtonDisabled}
            onClick={_handleAddCeremony}
          >
            Add
          </Button>
          <Button
            variant="outlined"
            color="error"
            style={{ marginLeft: 10 }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      )}
      {addCertificate && (
        <div>
          <Button
            color="secondary"
            variant="outlined"
            disabled={startButtonDisabled}
            onClick={handleAddCertificate}
          >
            Add
          </Button>
          <Button
            variant="outlined"
            color="error"
            style={{ marginLeft: 10 }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      )}
    </Stack>
  );
}

Index.propTypes = {
  setSettingDialog: PropTypes.func,
  setTerm: PropTypes.func,
  terms: PropTypes.array,
  term: PropTypes.number,
  setIncludeDialog: PropTypes.func,
  setMedalDialog: PropTypes.func,
  addMedal: PropTypes.bool,
  setAddMedal: PropTypes.func,
  addCeremony: PropTypes.bool,
  setAddCeremony: PropTypes.func,
  addMail: PropTypes.bool,
  setAddMail: PropTypes.func,
  selectedItems: PropTypes.array,
  setMailDialogShow: PropTypes.func,
  startButtonDisabled: PropTypes.bool,
  handleCancel: PropTypes.func,
  _handleAddCeremony: PropTypes.func,
  handleAddCertificate: PropTypes.func,
  addCertificate: PropTypes.bool,
  setAddCertificate: PropTypes.func,
  transfer: PropTypes.bool,
  setTransfer: PropTypes.func,
  handleTransfer: PropTypes.func
};

export default Index;
