import React, { useState } from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import { Button, Grid } from '@mui/material';
import { Icon } from '@iconify/react';
import Page from '../../../components/Page';
import Award from './Award';
import Report from './Report';
import LandingPageCard from '../../../components/CustomComponents/CustomCard/LandingPageCard';

const CARDS = [
  {
    id: 1,
    name: 'Award',
    description: 'From this module you can arrange award operation.'
  },
  {
    id: 2,
    name: 'Report',
    description: 'From this module you can arrange award report'
  }
];

const LANDING_PAGE = 0;
const AWARD_PAGE = 1;
const REPORT_PAGE = 2;

function Index() {
  const [mode, setMode] = useState(LANDING_PAGE);

  function generate(data) {
    if (data.id === 1) {
      setMode(AWARD_PAGE);
    } else {
      setMode(REPORT_PAGE);
    }
  }

  return (
    <Page title="Award Operation | Genius">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h4">
            Review Operation {/* eslint-disable-next-line no-nested-ternary */}
            {mode === AWARD_PAGE ? ' / Award' : mode === REPORT_PAGE ? ' / Award Report' : null}
          </Typography>
          {mode !== LANDING_PAGE && (
            <Button
              startIcon={<Icon icon="akar-icons:arrow-back-thick-fill" />}
              variant="outlined"
              onClick={() => setMode(LANDING_PAGE)}
            >
              Back
            </Button>
          )}
        </Box>
        {mode === LANDING_PAGE ? (
          <Grid container spacing={2}>
            {CARDS.map((item, index) => (
              <Grid item xs={3} key={index}>
                <LandingPageCard data={item} generate={generate} />
              </Grid>
            ))}
          </Grid>
        ) : mode === AWARD_PAGE ? (
          <Award />
        ) : (
          <Report />
        )}
      </Container>
    </Page>
  );
}

export default Index;
