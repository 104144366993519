import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Button, Card, DialogContent } from '@mui/material';
import Box from '@mui/material/Box';
import { Typography } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import { setConfirmationAlert, setSnackbar } from '../../../../actions/alertActions';
import RoleList from './RoleList';
import RoleFeatures from './RoleFeatures';
import AddRoleDialog from './RoleList/AddRoleDialog';
import Page from '../../../../components/Page';
import { fetchRolesList, deleteRole, updateRole } from '../serviceCalls';
import CustomDialog from '../../../../components/CustomComponents/CustomDialog';
import ProcessInfoBox from '../../../../components/CustomComponents/CustomCard/ProcessInfoBox';

/*
    Has 3 modes
        - Mode 0: List mode: List all current roles
        - Mode 1: Update mode
*/
const LIST_MODE = 0;
const UPDATE_MODE = 1;

const Roles = (props) => {
  const { dispatch } = props;
  const [mode, setMode] = useState(LIST_MODE);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedRoleIndex, setSelectedRoleIndex] = useState(-1);
  const [modal, setModal] = useState(false);
  const [roleListUpdateIndex, setRoleListUpdateIndex] = useState(0);
  const [rolesList, setRolesList] = useState([]);
  const [guidelines, setGuidelines] = useState([]);

  useEffect(() => {
    fetchRolesList(
      {},
      (data) => {
        setGuidelines(data.guidelines);
        setRolesList(data.data);
      },
      () => {}
    );
  }, [roleListUpdateIndex]);
  const addRoleClick = () => {
    setModal(true);
    setMode(LIST_MODE);
  };
  const editRoleClick = (role, index) => {
    setMode(UPDATE_MODE);
    setSelectedRole(role);
    setSelectedRoleIndex(index);
  };
  const handleEditRole = (role_name, role_access_key, role_id, role_is_admin) => {
    updateRole(
      { role_name, role_access_key, role_id, role_is_admin: role_is_admin ? 1 : 0 },
      () => {
        dispatch(setSnackbar('success', 'Role Updated'));
        refreshRoleList();
      },
      () => {}
    );
  };
  const handleDeleteRole = (role_id) => {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'This action will delete role permanently',
        () => {
          deleteRole(
            { role_id },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT', payload: null });
              dispatch(setSnackbar('success', 'Role is deleted!'));
              refreshRoleList();
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT', payload: null });
            }
          );
        }
      )
    );
  };
  const onCancel = () => {
    setMode(LIST_MODE);
    setSelectedRoleIndex(-1);
  };
  const refreshRoleList = () => {
    setModal(false);
    setRoleListUpdateIndex(roleListUpdateIndex + 1);
  };
  return (
    <Page title="Roles | Genius">
      <Box sx={{ pb: 5, display: 'flex', justifyContent: 'space-between' }}>
        <Grid container alignItems="center">
          {guidelines.length > 0 && (
            <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />
          )}
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <Card style={{ padding: 10 }}>
              <Typography variant="h6">Roles</Typography>
              <RoleList
                editRoleClick={editRoleClick}
                addRoleClick={addRoleClick}
                deleteRoleClick={handleDeleteRole}
                selectedRoleIndex={selectedRoleIndex}
                roleListUpdateIndex={roleListUpdateIndex}
                rolesList={rolesList}
              />
              <CustomDialog
                onClose={() => setModal(false)}
                open={modal}
                maxWidth="lg"
                title="Add New Role"
              >
                <DialogContent>
                  <AddRoleDialog
                    handleClose={() => setModal(false)}
                    rolesList={rolesList}
                    refreshRoleList={refreshRoleList}
                  />
                </DialogContent>
              </CustomDialog>
            </Card>
          </Grid>
          {mode === 1 && <Button disabled> &gt;</Button>}
          {mode === 1 && (
            <Grid item xs={12} md={6} lg={4} xl={3}>
              <Card style={{ padding: 10 }}>
                <RoleFeatures
                  mode={mode}
                  selectedRole={selectedRole}
                  onCancel={onCancel}
                  handleEditRole={handleEditRole}
                  roleListUpdateIndex={roleListUpdateIndex}
                />
              </Card>
            </Grid>
          )}
        </Grid>
      </Box>
    </Page>
  );
};

Roles.propTypes = {
  dispatch: PropTypes.func.isRequired
};

export default connect(() => ({}))(Roles);
