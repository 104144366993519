import React, { useEffect, useState } from 'react';
import { IconButton, Stack } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import { Icon } from '@iconify/react';
import moment from 'moment/moment';
import { fetchSessionProjectList, fetchSessionProjectSessionList } from '../../serviceCalls';
import Page from '../../../../../components/Page';
import ProcessInfoBox from '../../../../../components/CustomComponents/CustomCard/ProcessInfoBox';
import MuiTable from '../../../../../components/CustomComponents/MuiTable';
import headers from './_utils/headerVariables';
import MailView from '../SessionReport/MailView/MailView';
import UpdateProjectDialog from './UpdateProjectDialog/UpdateProjectDialog';
import Label from '../../../../../components/Label';
import { fCurrency } from '../../../../../utils/formatNumber';
import { fDateTime } from '../../../../../utils/formatTime';

function Index() {
  const [tableData, setTableData] = useState([]);
  const [guidelines, setGuidelines] = useState([]);
  const [addMail, setAddMail] = useState(false);
  const [mailDialogShow, setMailDialogShow] = useState(false);
  const [updateDialogShow, setUpdateDialogShow] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [idList, setIdList] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [sessionList, setSessionList] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    fetchSessionProjectList(
      {},
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((project) => {
          const _balance = Number(parseFloat(project.balance).toFixed(2));
          const _balanceConfirmedStudent = Number(
            parseFloat(project.project_confirmed_student_balance).toFixed(2)
          );
          project.balanceComp =
            _balance < 0 || _balance === 0 ? (
              <Label variant="ghost" color="success">
                {fCurrency(project.balance)}
              </Label>
            ) : (
              <Label variant="ghost" color="error">
                {fCurrency(project.balance)}
              </Label>
            );
          project.balance_filter = _balance < 0 || _balance === 0 ? 'Paid' : 'Not Paid';
          project.balanceComp2 =
            _balanceConfirmedStudent < 0 || _balanceConfirmedStudent === 0 ? (
              <Label variant="ghost" color="success">
                {fCurrency(project.project_confirmed_student_balance)}
              </Label>
            ) : (
              <Label variant="ghost" color="error">
                {fCurrency(project.project_confirmed_student_balance)}
              </Label>
            );
          project.balance_filter2 =
            _balanceConfirmedStudent < 0 || _balanceConfirmedStudent === 0 ? 'Paid' : 'Not Paid';
          project.media_link_comp = project.media_link && (
            <Label variant="ghost" color="primary">
              <a target="_blank" href={project.media_link} rel="noreferrer">
                Link
              </a>
            </Label>
          );
          project.media_link_filter = project.media_link ? 'Exist' : 'None';
          project.electricity_needed_comp = project.electricity_needed ? 'Yes' : 'No';
          project.created_at_moment = moment(project.created_at).format('YYYY-MM-DD');
          project.add_session = (
            <IconButton
              disabled={project.judging_group_number}
              color="secondary"
              onClick={() => handleEditProject(project)}
            >
              <Icon icon="ci:edit" width={24} height={24} />
            </IconButton>
          );
          project.judging_group_number_comp = project.judging_group_number || 'N/A';
          project.judging_group_place_comp = project.judging_group_place || 'N/A';
        });
        setTableData(list);
      },
      () => {}
    );
  }
  function handleEditProject(project) {
    setSelectedProject(project);
    fetchSessionProjectSessionList(
      { project_id: project.project_id },
      (data) => {
        data.forEach((session) => {
          session.start_date_view = fDateTime(session.start_datetime);
          session.end_date_view = fDateTime(session.end_datetime);
        });
        setSessionList(data);
        setUpdateDialogShow(true);
      },
      () => {}
    );
  }
  function handleRefresh() {
    setMailDialogShow(false);
    setAddMail(false);
    setUpdateDialogShow(false);
    setSelectedItems([]);
    fetchData();
  }
  const CustomOption = () => ({
    selectableRows: addMail ? 'multiple' : 'none',
    onRowSelectionChange,
    rowsSelected: selectedItems
  });
  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedItems(rowsSelected);
    const arr = [];
    rowsSelected.forEach((item) => {
      arr.push(tableData[item].id);
    });
    setIdList(arr);
  };
  function CustomTitle() {
    return (
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 2 }}>
        {addMail && (
          <div>
            <Button
              style={{ marginRight: 5 }}
              disabled={selectedItems.length === 0}
              variant="outlined"
              onClick={() => setMailDialogShow(true)}
            >
              Next
            </Button>
            <Button color="error" variant="outlined" onClick={() => setAddMail(false)}>
              Cancel
            </Button>
          </div>
        )}
      </Stack>
    );
  }

  return (
    <Page title="Judge Operation | Genius">
      <Stack spacing={2}>
        {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
        <MuiTable
          tableData={tableData}
          tableColumns={headers}
          title={<CustomTitle />}
          customOption={CustomOption()}
        />
      </Stack>
      <MailView
        mailDialogShow={mailDialogShow}
        setMailDialogShow={setMailDialogShow}
        handleRefresh={handleRefresh}
        idList={idList}
      />
      {updateDialogShow && (
        <UpdateProjectDialog
          project={selectedProject}
          data={sessionList}
          handleClose={() => setUpdateDialogShow(false)}
          handleSuccess={handleRefresh}
        />
      )}
    </Page>
  );
}

export default connect(() => ({}))(Index);
