import React, { useEffect, useState } from 'react';
import { IconButton, MenuItem, Stack, TextField } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { Button, DialogContent, Tooltip } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Email from '@mui/icons-material/Email';
import ReactHtmlParser from 'react-html-parser';
import MuiTable from '../../../../components/CustomComponents/MuiTable';
import headers from './utils/headerVariables';
import { deleteFairOperation, getFairOperation, getFairProfile } from '../serviceCalls';
import ApproveDialog from './utils/ApproveDialog';
import ProfileDialog from './utils/ProfileDialog';
import CustomDialog from '../../../../components/CustomComponents/CustomDialog';
import CustomMail from '../../../../components/CustomComponents/CustomMail';
import { CUSTOM_MAIL_TYPE } from '../../../../api/RestVariables/staticVariables';
import Page from '../../../../components/Page';
import CapacityDialog from './utils/CapacityDialog';
import { setConfirmationAlert, setSnackbar } from '../../../../actions/alertActions';
import ProcessInfoBox from '../../../../components/CustomComponents/CustomCard/ProcessInfoBox';
import MigrationDialog from './utils/MigrationDialog';

function Index(props) {
  const { terms, active_term, isCommunication, dispatch } = props;
  const [term, setTerm] = useState(active_term);
  const [tableData, setTableData] = useState([]);
  const [approveDialogShow, setApproveDialogShow] = useState(false);
  const [profileDialogShow, setProfileDialogShow] = useState(false);
  const [approveData, setApproveData] = useState(null);
  const [profile, setProfile] = useState(null);
  const [mailDialogShow, setMailDialogShow] = useState(false);
  const [addMail, setAddMail] = useState(false);
  const [migration, setMigration] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [capacityDialog, setCapacityDialog] = useState(false);
  const [migrationDialog, setMigrationDialog] = useState(false);
  const [capacityData, setCapacityData] = useState(null);
  const [idList, setIdList] = useState([]);
  const [guidelines, setGuidelines] = useState([]);

  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedItems(rowsSelected);
    const arr = [];
    rowsSelected.forEach((item) => {
      arr.push(tableData[item].id);
    });
    setIdList(arr);
  };

  useEffect(() => {
    fetchData();
  }, [term]);
  function fetchData() {
    getFairOperation(
      { term_id: term },
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((item) => {
          item.capacity = (
            <IconButton color="secondary" onClick={() => viewCapacity(item)}>
              <Icon icon="mdi:human-capacity-increase" />
            </IconButton>
          );
          item.edit = (
            <IconButton color="secondary" onClick={() => handleEditFair(item)}>
              <Icon icon="ci:edit" />
            </IconButton>
          );
          item.profile = (
            <IconButton color="secondary" onClick={() => handleEditProfile(item)}>
              <Icon icon="iconoir:profile-circled" />
            </IconButton>
          );
          item.delete = (
            <IconButton color="secondary" onClick={() => handleDeleteFair(item.id)}>
              <Icon icon="fluent:delete-48-filled" />
            </IconButton>
          );
        });
        setTableData(list);
      },
      () => {}
    );
  }
  function viewCapacity(item) {
    setCapacityData(item.fair_discipline_capacity);
    setCapacityDialog(true);
  }
  function handleEditFair(fair) {
    setApproveData(fair);
    setApproveDialogShow(true);
  }
  function handleEditProfile(fair) {
    getFairProfile(
      { fair_id: fair.id },
      (data) => {
        setProfile(data);
        setProfileDialogShow(true);
      },
      () => {}
    );
  }
  function handleDeleteFair(fair_id) {
    dispatch(
      setConfirmationAlert('Are you sure?', 'YES, DELETE', 'This action will delete fair', () => {
        deleteFairOperation(
          { fair_id },
          () => {
            dispatch(setSnackbar('success', 'The fair has been deleted'));
            dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            handleRefresh();
          },
          () => {
            dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
          }
        );
      })
    );
  }
  function handleRefresh() {
    setApproveDialogShow(false);
    setProfileDialogShow(false);
    setApproveData(null);
    setMailDialogShow(false);
    setCapacityDialog(false);
    setCapacityData(null);
    setAddMail(false);
    setSelectedItems([]);
    fetchData();
  }

  function handleCloseNextButton() {
    setAddMail(false);
    setMigration(false);
    setSelectedItems([]);
  }

  function handleOpenDialog() {
    if (addMail) {
      setMailDialogShow(true);
    } else {
      setMigrationDialog(true);
    }
  }

  function TermSelect() {
    return (
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={2}
        sx={{ mt: 2, alignItems: 'center' }}
      >
        <TextField select label="Terms" value={term} onChange={(e) => setTerm(e.target.value)}>
          {terms.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
        {tableData.length > 0 && !(addMail || migration) && (
          <div>
            <Button onClick={() => setMigration(true)} variant="outlined" color="secondary">
              Migrate
            </Button>
          </div>
        )}
        {(addMail || migration) && (
          <div>
            <Button
              style={{ marginRight: 5 }}
              disabled={selectedItems.length === 0}
              variant="outlined"
              onClick={handleOpenDialog}
            >
              Next
            </Button>
            <Button color="error" variant="outlined" onClick={handleCloseNextButton}>
              Cancel
            </Button>
          </div>
        )}
      </Stack>
    );
  }

  function handleCloseMigration() {
    setMigrationDialog(false);
    setMigration(false);
    setSelectedItems([]);
  }

  return (
    <Page title="Fair Operation | Genius">
      <Stack spacing={2}>
        {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
        <MuiTable
          tableData={tableData}
          tableColumns={headers}
          title={<TermSelect />}
          customOption={{
            selectableRows: addMail || migration ? 'multiple' : 'none',
            onRowSelectionChange,
            rowsSelected: selectedItems,
            customToolbar: () =>
              isCommunication !== -1 && (
                <Tooltip title="Send Email">
                  <IconButton onClick={() => setAddMail(true)}>
                    <Email />
                  </IconButton>
                </Tooltip>
              )
          }}
        />
      </Stack>
      {migrationDialog && (
        <MigrationDialog
          idList={idList}
          handleClose={handleCloseMigration}
          handleRefresh={fetchData}
        />
      )}
      <CustomDialog
        maxWidth="md"
        onClose={() => setApproveDialogShow(false)}
        open={approveDialogShow}
        title="Edit"
      >
        <DialogContent>
          <ApproveDialog
            data={approveData}
            handleRefresh={handleRefresh}
            handleClose={() => setApproveDialogShow(false)}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        maxWidth="lg"
        onClose={() => setProfileDialogShow(false)}
        open={profileDialogShow}
        title="Profile"
      >
        <DialogContent>
          <ProfileDialog
            data={profile}
            handleRefresh={handleRefresh}
            handleClose={() => setProfileDialogShow(false)}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        onClose={() => setMailDialogShow(false)}
        open={mailDialogShow}
        maxWidth="lg"
        title="Send Mail"
        fullWidth
        disableEnforceFocus
      >
        <DialogContent>
          <CustomMail
            handleRefresh={handleRefresh}
            mailType={CUSTOM_MAIL_TYPE}
            idList={idList}
            handleClose={() => setMailDialogShow(false)}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        onClose={() => setCapacityDialog(false)}
        open={capacityDialog}
        maxWidth="lg"
        title="Discipline Capacity"
      >
        <DialogContent>
          <CapacityDialog data={capacityData} handleClose={() => setCapacityDialog(false)} />
        </DialogContent>
      </CustomDialog>
    </Page>
  );
}

Index.propTypes = {
  terms: PropTypes.array,
  active_term: PropTypes.number,
  isCommunication: PropTypes.number,
  dispatch: PropTypes.func
};

export default connect((store) => ({
  terms: store.loginReducer.terms,
  active_term: store.loginReducer.active_term,
  isCommunication: store.routeReducer.isCommunication
}))(Index);
