const HOST = process.env.REACT_APP_REST_API_HOST;

const GET_PROJECT_OP = {
  method: 'GET',
  path: `${HOST}go_admin/project_operation/project`
};
const PATCH_POP_FINALIST = {
  method: 'PATCH',
  path: `${HOST}go_admin/project_operation/info/finalist`
};
const UPDATE_INFO = {
  method: 'PUT',
  path: `${HOST}go_admin/project_operation/info/general_info`
};
const DELETE_INFO = {
  method: 'DELETE',
  path: `${HOST}go_admin/project_operation/info/general_info`
};
const ADD_AFFILIATED_FAIR = {
  method: 'POST',
  path: `${HOST}go_admin/project_operation/info/fair_request`
};
const DELETE_AFFILIATED_FAIR = {
  method: 'DELETE',
  path: `${HOST}go_admin/project_operation/info/fair_request`
};
const GET_AFFILIATED_FAIR_REQ = {
  method: 'GET',
  path: `${HOST}go_admin/project_operation/info/fair_request`
};
const GET_STUDENT = {
  method: 'GET',
  path: `${HOST}go_admin/project_operation/info/student`
};
const GET_EXISTING_STUDENT = {
  method: 'GET',
  path: `${HOST}go_admin/project_operation/info/existing_student`
};
const ADD_STUDENT = {
  method: 'POST',
  path: `${HOST}go_admin/project_operation/info/student`
};
const ADD_EXISTING_STUDENT = {
  method: 'POST',
  path: `${HOST}go_admin/project_operation/info/existing_student`
};
const UPDATE_STUDENT = {
  method: 'PUT',
  path: `${HOST}go_admin/project_operation/info/student`
};
const DELETE_STUDENT = {
  method: 'DELETE',
  path: `${HOST}go_admin/project_operation/info/student`
};
const GET_INFO_LIST = {
  method: 'GET',
  path: `${HOST}go_admin/project_operation/info/list`
};
const GET_INFO = {
  method: 'GET',
  path: `${HOST}go_admin/project_operation/info/project_info`
};
const GET_STUDENT_LIST = {
  method: 'GET',
  path: `${HOST}go_admin/project_operation/info/student_list`
};
const GET_FILE_LIST = {
  method: 'GET',
  path: `${HOST}go_admin/project_operation/info/file_list`
};
const ADD_FILE = {
  method: 'POST',
  path: `${HOST}go_admin/project_operation/info/file`
};
const UPDATE_FILE = {
  method: 'PUT',
  path: `${HOST}go_admin/project_operation/info/file`
};
const GET_FILE = {
  method: 'GET',
  path: `${HOST}go_admin/project_operation/info/file`
};
const DELETE_FILE = {
  method: 'DELETE',
  path: `${HOST}go_admin/project_operation/info/file`
};
const GET_STUDENT_DUPLICATE = {
  method: 'GET',
  path: `${HOST}go_admin/project_operation/report/student_duplicate`
};
const GET_COUNTRY_PROJECT = {
  method: 'GET',
  path: `${HOST}go_admin/project_operation/report/country_project`
};
const GET_STATE_PROJECT = {
  method: 'GET',
  path: `${HOST}go_admin/project_operation/report/state_project`
};
const GET_REVIEW_LIST = {
  method: 'GET',
  path: `${HOST}go_admin/project_operation/report/review_list`
};
const GET_REVIEW_DETAIL = {
  method: 'GET',
  path: `${HOST}go_admin/project_operation/report/review_detail`
};
const UPDATE_REVIEW_STATUS = {
  method: 'POST',
  path: `${HOST}go_admin/project_operation/report/review_status`
};
const GET_FILE_LINK = {
  method: 'GET',
  path: `${HOST}go_admin/project_operation/report/file`
};
const PROJECT_MANUAL_PAYMENT = {
  method: 'POST',
  path: `${HOST}go_admin/project_operation/info/manual_payment`
};
const PROJECT_OP_PRESENTATION = {
  method: 'POST',
  path: `${HOST}go_admin/project_operation/info/presentation`
};
const PROJECT_OP_FILE_CHECK = {
  method: 'POST',
  path: `${HOST}go_admin/project_operation/info/file_check_result`
};

module.exports = {
  GET_PROJECT_OP,
  GET_AFFILIATED_FAIR_REQ,
  GET_FILE_LIST,
  GET_FILE,
  ADD_FILE,
  UPDATE_FILE,
  DELETE_FILE,
  ADD_AFFILIATED_FAIR,
  DELETE_AFFILIATED_FAIR,
  GET_INFO,
  PATCH_POP_FINALIST,
  UPDATE_INFO,
  DELETE_INFO,
  GET_STUDENT,
  GET_EXISTING_STUDENT,
  ADD_STUDENT,
  ADD_EXISTING_STUDENT,
  UPDATE_STUDENT,
  DELETE_STUDENT,
  GET_INFO_LIST,
  GET_STUDENT_LIST,
  GET_STUDENT_DUPLICATE,
  GET_COUNTRY_PROJECT,
  GET_STATE_PROJECT,
  GET_REVIEW_LIST,
  GET_REVIEW_DETAIL,
  UPDATE_REVIEW_STATUS,
  GET_FILE_LINK,
  PROJECT_MANUAL_PAYMENT,
  PROJECT_OP_PRESENTATION,
  PROJECT_OP_FILE_CHECK
};
