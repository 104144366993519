export default [
  {
    name: 'id',
    label: 'ID'
  },
  {
    name: 'judging_detail',
    label: 'Judging Detail',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'medal_type_change',
    label: 'C.M.T.',
    options: {
      filter: false,
      print: false,
      download: false,
      tooltip: 'Change Medal Type'
    }
  },
  {
    name: 'medal_type',
    label: 'Medal Type'
  },
  {
    name: 'completed_judging',
    label: 'Completed Judge'
  },
  {
    name: 'average_score_comp',
    label: 'Average Score'
  },
  {
    name: 'project_student_count',
    label: 'Student Count'
  },
  {
    name: 'balance_comp',
    label: 'Balance',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'balance_filter',
    label: 'Balance',
    options: {
      display: false
    }
  },
  {
    name: 'balance_comp_student',
    label: 'Participation Balance',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'balance_filter_student',
    label: 'Balance',
    options: {
      display: false
    }
  },
  {
    name: 'country',
    label: 'Country'
  },
  {
    name: 'state',
    label: 'State'
  },
  {
    name: 'discipline',
    label: 'Discipline'
  },
  {
    name: 'title',
    label: 'Title'
  },
  {
    name: 'school_name',
    label: 'School'
  },
  {
    name: 'project_student_complete',
    label: 'Students'
  },
  {
    name: 'media_link_comp',
    label: 'YT Link',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'media_link_filter',
    label: 'YT Link',
    options: {
      display: false
    }
  },
  {
    name: 'presentation_link_comp',
    label: 'Presentation Link',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'presentation_link_filter',
    label: 'Presentation Link',
    options: {
      display: false
    }
  }
];
