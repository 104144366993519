import { Checkbox, MenuItem, Stack, TextField } from '@material-ui/core';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@material-ui/lab';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useEffect, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { setLoading, setSnackbar } from '../../../../../actions/alertActions';
import { addGroup, getGroupSessionList, updateGroup } from '../../serviceCalls';

const ADD_GROUP = 0;
const UPDATE_GROUP = 1;
const SCIENCE = 1;

function AddUpdateDialog(props) {
  const { data, mode, handleClose, dispatch, refreshData } = props;
  const [sessionList, setSessionList] = useState([]);
  const [electric, setElectric] = useState(
    mode === UPDATE_GROUP ? !!data.electricity_access : false
  );
  const [isElectricShow, setIsElectricShow] = useState(false);

  useEffect(() => {
    getGroupSessionList(
      {},
      (_data) => {
        setSessionList(_data);
      },
      () => {}
    );
  }, []);

  useEffect(() => {
    if (sessionList.length > 0 && mode === UPDATE_GROUP) {
      const selectedSession = sessionList.find((session) => session.id === data.judging_session_id);
      if (selectedSession?.discipline_id === SCIENCE) {
        setIsElectricShow(true);
        setElectric(!!data.electricity_access);
      } else {
        setIsElectricShow(false);
        setElectric(false);
      }
    }
  }, [sessionList]);

  const Schema = Yup.object().shape({
    judging_session_id: Yup.number().required('Session is required'),
    number_of_groups: mode === ADD_GROUP && Yup.number().required('Number of groups is required'),
    capacity: Yup.number().required('Capacity is required'),
    group_number: mode === UPDATE_GROUP && Yup.number().required('Group Number is required')
  });
  const formik = useFormik({
    initialValues: {
      judging_session_id: mode === UPDATE_GROUP ? data.judging_session_id : '',
      number_of_groups: '',
      capacity: mode === UPDATE_GROUP ? data.judging_group_capacity : '',
      group_number: mode === UPDATE_GROUP && data.group_number
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      dispatch(setLoading(1, 'Loading...'));
      if (mode === ADD_GROUP) {
        const param = {
          judging_session_id: values.judging_session_id,
          number_of_groups: values.number_of_groups,
          capacity: values.capacity,
          electricity_access: electric ? 1 : 0
        };
        addGroup(
          param,
          () => {
            dispatch(setLoading(0, ''));
            refreshData();
            dispatch(setSnackbar('success', 'The group has been added'));
          },
          () => {
            dispatch(setLoading(0, ''));
          }
        );
      } else {
        const param = {
          judging_group_id: data.judging_group_id,
          judging_session_id: values.judging_session_id,
          group_number: values.group_number,
          capacity: values.capacity,
          electricity_access: electric ? 1 : 0
        };
        updateGroup(
          param,
          () => {
            dispatch(setLoading(0, ''));
            refreshData();
            dispatch(setSnackbar('success', 'The group has been updated'));
          },
          () => {
            dispatch(setLoading(0, ''));
          }
        );
      }
    }
  });
  const { errors, touched, handleSubmit, getFieldProps, values } = formik;

  useEffect(() => {
    const selectedSession = sessionList.find((session) => session.id === values.judging_session_id);
    if (selectedSession?.discipline_id === SCIENCE) {
      setIsElectricShow(true);
    } else {
      setIsElectricShow(false);
      setElectric(false);
    }
  }, [values.judging_session_id]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            sx={{ mt: 1 }}
            fullWidth
            label="Session List"
            select
            {...getFieldProps('judging_session_id')}
            error={Boolean(touched.judging_session_id && errors.judging_session_id)}
            helperText={touched.judging_session_id && errors.judging_session_id}
          >
            {sessionList.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {`${item.judging_session_type}-${item.name}-${item.discipline}`}
              </MenuItem>
            ))}
          </TextField>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 1 }}>
            {mode === ADD_GROUP ? (
              <TextField
                fullWidth
                label="Number of Groups"
                type="number"
                {...getFieldProps('number_of_groups')}
                error={Boolean(touched.number_of_groups && errors.number_of_groups)}
                helperText={touched.number_of_groups && errors.number_of_groups}
              />
            ) : (
              <TextField
                fullWidth
                label="Group Number"
                type="number"
                {...getFieldProps('group_number')}
                error={Boolean(touched.group_number && errors.group_number)}
                helperText={touched.group_number && errors.group_number}
              />
            )}
            <TextField
              fullWidth
              label="Group Capacity"
              type="number"
              {...getFieldProps('capacity')}
              error={Boolean(touched.capacity && errors.capacity)}
              helperText={touched.capacity && errors.capacity}
            />
          </Stack>
          {isElectricShow && (
            <FormControlLabel
              control={
                <Checkbox checked={electric} onChange={(e) => setElectric(e.target.checked)} />
              }
              label="Electricity Access"
            />
          )}
          <Stack direction="row" justifyContent="end" spacing={1}>
            <LoadingButton size="small" type="submit" variant="outlined">
              {mode === ADD_GROUP ? 'Add' : 'Update'}
            </LoadingButton>
            <Button size="small" color="error" onClick={handleClose} variant="outlined">
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

AddUpdateDialog.propTypes = {
  data: PropTypes.object,
  mode: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired
};

export default connect(() => ({}))(AddUpdateDialog);
