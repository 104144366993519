import { Grid, Stack } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import CustomSchoolCard from './CustomSchoolCard';
import { setConfirmationAlert, setSnackbar } from '../../../actions/alertActions';
import { deleteSchoolInfo } from './utils/serviceCalls';

function LandingPage(props) {
  const { handleAddSchool, schoolList, dispatch, refreshData, handleEditSchool } = props;

  function handleDeleteSchool(school_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'This action will delete the school record permanently. Do you want to proceed?',
        () => {
          deleteSchoolInfo(
            { school_id },
            () => {
              refreshData();
              dispatch(setSnackbar('success', 'The school has been deleted'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }
  return (
    <Stack spacing={2}>
      <Grid container spacing={3}>
        {schoolList.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <CustomSchoolCard
              data={item}
              handleDeleteSchool={handleDeleteSchool}
              handleEditSchool={handleEditSchool}
            />
          </Grid>
        ))}
      </Grid>
      <Grid xs={12} sm={6} md={4}>
        <Button variant="outlined" onClick={handleAddSchool}>
          Add new School
        </Button>
      </Grid>
    </Stack>
  );
}

LandingPage.propTypes = {
  handleAddSchool: PropTypes.func,
  handleEditSchool: PropTypes.func,
  dispatch: PropTypes.func,
  schoolList: PropTypes.array,
  refreshData: PropTypes.func
};

export default connect(() => ({}))(LandingPage);
