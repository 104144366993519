import React from 'react';
import PropTypes from 'prop-types';
import CustomDialog, {
  DialogContent
} from '../../../../../../components/CustomComponents/CustomDialog';
import EditQuestionForm from './EditQuestionForm';

/*
This view is a modal that includes SubQuestionStepper to add new sub question
*/

function AddSubQuestion(props) {
  const { closeDialog, handleSuccess, question } = props;

  return (
    <CustomDialog onClose={closeDialog} maxWidth="md" fullWidth title="Update Question">
      <DialogContent>
        <EditQuestionForm
          question={question}
          handleSuccess={handleSuccess}
          handleClose={closeDialog}
        />
      </DialogContent>
    </CustomDialog>
  );
}

AddSubQuestion.propTypes = {
  closeDialog: PropTypes.func,
  question: PropTypes.object,
  handleSuccess: PropTypes.func
};

export default AddSubQuestion;
