const HOST = process.env.REACT_APP_REST_API_HOST;

const GET_SCHOOL_INFO = {
  method: 'GET',
  path: `${HOST}go_admin/school_operation/info`
};
const UPDATE_SCHOOL_INFO = {
  method: 'PUT',
  path: `${HOST}go_admin/school_operation/info`
};
const DELETE_SCHOOL_INFO = {
  method: 'DELETE',
  path: `${HOST}go_admin/school_operation/info`
};
const UPDATE_TRANSFER_SCHOOL = {
  method: 'PUT',
  path: `${HOST}go_admin/school_operation/info/transfer`
};
const MERGE_SCHOOL = {
  method: 'PUT',
  path: `${HOST}go_admin/school_operation/info/merge`
};
const GET_REVIEW_LIST = {
  method: 'GET',
  path: `${HOST}go_admin/school_operation/info/review_list`
};
const GET_REVIEW_DETAIL = {
  method: 'GET',
  path: `${HOST}go_admin/school_operation/info/review_detail`
};
const UPDATE_REVIEW_STATUS = {
  method: 'POST',
  path: `${HOST}go_admin/school_operation/info/review_status`
};
const GET_FILE_LINK = {
  method: 'GET',
  path: `${HOST}go_admin/school_operation/info/file`
};

module.exports = {
  GET_SCHOOL_INFO,
  UPDATE_SCHOOL_INFO,
  DELETE_SCHOOL_INFO,
  UPDATE_TRANSFER_SCHOOL,
  MERGE_SCHOOL,
  GET_REVIEW_LIST,
  GET_REVIEW_DETAIL,
  UPDATE_REVIEW_STATUS,
  GET_FILE_LINK
};
