import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Stack, TextField } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import { Button } from '@mui/material';
import { mergeSchool } from '../serviceCalls';
import { setSnackbar } from '../../../../actions/alertActions';

function MergeDialog(props) {
  const { tableData, data, handleRefresh, dispatch } = props;
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [options, setOptions] = useState([]);
  useEffect(() => {
    const list = tableData.filter((x) => x.id !== data.id);
    setOptions(list);
  }, []);

  function handleMergeSchool() {
    mergeSchool(
      { target_school_id: selectedSchool.id, merged_school_ids: [data.id] },
      () => {
        dispatch(setSnackbar('success', 'The school has been merged'));
        handleRefresh();
      },
      () => {}
    );
  }
  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 1 }}>
      <Autocomplete
        value={selectedSchool}
        onChange={(event, newValue) => {
          setSelectedSchool(newValue);
        }}
        style={{ width: 400 }}
        id="free-solo-demo"
        freeSolo
        options={options}
        getOptionLabel={(option) => `${option.id}-${option.name}`}
        renderInput={(params) => <TextField {...params} fullWidth label="Schools" />}
      />
      <Button onClick={handleMergeSchool} variant="outlined">
        Merge
      </Button>
    </Stack>
  );
}

MergeDialog.propTypes = {
  dispatch: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  tableData: PropTypes.array
};

export default connect(() => ({}))(MergeDialog);
