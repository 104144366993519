import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Checkbox, MenuItem, Paper, TextField } from '@material-ui/core';
import { Button, Stack } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import FormControlLabel from '@mui/material/FormControlLabel';
import { setSnackbar } from '../../../../../actions/alertActions';
import { getMedalType, updateProjectMedalType } from '../../_apis/serviceCalls';

const MedalDialog = (props) => {
  const { handleClose, selectedItems, tableData, dispatch, handleRefresh } = props;
  const [medalList, setMedalList] = useState([]);
  const [selectedMedal, setSelectedMedal] = useState(null);
  const [updateChecked, setUpdateChecked] = useState(false);

  useEffect(() => {
    getMedalType(
      {},
      (data) => {
        setMedalList(data);
      },
      () => {}
    );
  }, []);

  function handleAssign() {
    const project_ids = [];
    selectedItems.forEach((item) => {
      project_ids.push(tableData[item].id);
    });
    updateProjectMedalType(
      { project_ids, medal_type_id: selectedMedal, update_certificate: updateChecked ? 1 : 0 },
      () => {
        dispatch(setSnackbar('success', 'The medal type(s) changed.'));
        handleRefresh();
      },
      () => {}
    );
  }

  return (
    <Stack spacing={2}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow style={{ background: 'gainsboro' }}>
              <TableCell style={{ width: '10%' }}>ID</TableCell>
              <TableCell style={{ width: '40%' }}>Project Title</TableCell>
              <TableCell style={{ width: '30%' }}>School</TableCell>
              <TableCell style={{ width: '20%' }}>Medal</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedItems.map((row, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {tableData[row].id}
                </TableCell>
                <TableCell>{tableData[row].title}</TableCell>
                <TableCell>{tableData[row].school_name}</TableCell>
                <TableCell>{tableData[row].medal_type}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TextField
        fullWidth
        label="Medal Type"
        select
        onChange={(e) => setSelectedMedal(e.target.value)}
      >
        {medalList.map((medal) => (
          <MenuItem key={medal.id} value={medal.id}>
            {medal.name}
          </MenuItem>
        ))}
      </TextField>
      <FormControlLabel
        control={
          <Checkbox checked={updateChecked} onChange={(e) => setUpdateChecked(e.target.checked)} />
        }
        label="Update Certificate"
      />
      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="end" spacing={2}>
        <Button onClick={handleAssign} variant="outlined">
          Save
        </Button>
        <Button variant="outlined" color="error" onClick={handleClose}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};

MedalDialog.propTypes = {
  selectedItems: PropTypes.array,
  tableData: PropTypes.array,
  handleClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  handleRefresh: PropTypes.func
};

export default connect(() => ({}))(MedalDialog);
