import { IconButton } from '@material-ui/core';
import { Icon } from '@iconify/react';
import React from 'react';

export function dataMaker(
  list,
  handleCopySurvey,
  handleViewSurvey,
  handleEditSurvey,
  handleDeleteSurvey
) {
  list.forEach((item) => {
    item.copy = (
      <IconButton color="secondary" onClick={() => handleCopySurvey(item)}>
        <Icon icon="bxs:copy" width={24} height={24} />
      </IconButton>
    );
    item.view = (
      <IconButton
        disabled={!item.number_of_questions}
        color="secondary"
        onClick={() => handleViewSurvey(item)}
      >
        <Icon icon="ep:view" width={24} height={24} />
      </IconButton>
    );
    item.edit = (
      <IconButton color="secondary" onClick={() => handleEditSurvey(item)}>
        <Icon icon="ci:edit" width={24} height={24} />
      </IconButton>
    );
    item.delete = (
      <IconButton color="secondary" onClick={() => handleDeleteSurvey(item.survey_id)}>
        <Icon icon="fluent:delete-48-filled" width={24} height={24} />
      </IconButton>
    );
  });
  return list;
}
