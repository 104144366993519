import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';
// eslint-disable-next-line import/extensions
import badgeStyle from './badgeStyle.js';

function Badge({ ...props }) {
  const { classes, color, children } = props;
  return (
    <span
      className={`${classes.badge} ${classes[color]}`}
      style={classes[color] === undefined ? { backgroundColor: color } : {}}
    >
      {children}
    </span>
  );
}

Badge.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string,
  children: PropTypes.string
};

export default withStyles(badgeStyle)(Badge);
