import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, DialogContent, Stack, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { IconButton, MenuItem, TextField } from '@material-ui/core';
import Email from '@mui/icons-material/Email';
import ReactHtmlParser from 'react-html-parser';
import MuiTable from '../../../../components/CustomComponents/MuiTable';
import headers from './utils/headerVariables';
import { getReviewerReport } from '../serviceCalls';
import Badge from '../../../../components/CustomComponents/Badge/Badge';
import CustomDialog from '../../../../components/CustomComponents/CustomDialog';
import CustomMail from '../../../../components/CustomComponents/CustomMail';
import { CUSTOM_MAIL_TYPE } from '../../../../api/RestVariables/staticVariables';
import Page from '../../../../components/Page';
import ProcessInfoBox from '../../../../components/CustomComponents/CustomCard/ProcessInfoBox';

function Index(props) {
  const { isCommunication, terms, active_term } = props;
  const [term, setTerm] = useState(active_term);
  const [tableData, setTableData] = useState([]);
  const [totalAssigned, setTotalAssigned] = useState(0);
  const [totalCompleted, setTotalCompleted] = useState(0);
  const [displayData, setDisplayData] = useState([]);
  const [mailDialogShow, setMailDialogShow] = useState(false);
  const [addMail, setAddMail] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [idList, setIdList] = useState([]);
  const [guidelines, setGuidelines] = useState([]);

  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedItems(rowsSelected);
    const arr = [];
    rowsSelected.forEach((item) => {
      arr.push(tableData[item].reviewer_id);
    });
    setIdList(arr);
  };

  useEffect(() => {
    fetchData();
  }, [term]);
  useEffect(() => {
    let assign = 0;
    let completed = 0;
    displayData.forEach((item) => {
      assign += Number(item.assigned);
      completed += Number(item.completed);
    });
    setTotalAssigned(assign);
    setTotalCompleted(completed);
  }, [displayData]);
  function fetchData() {
    getReviewerReport(
      { term_id: term },
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        setTableData(list);
        setDisplayData(list);
      },
      () => {}
    );
  }
  function handleRefresh() {
    setMailDialogShow(false);
    setAddMail(false);
    setSelectedItems([]);
  }
  function TermSelect() {
    return (
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={2}
        sx={{ mt: 2, display: 'flex', alignItems: 'center' }}
      >
        <TextField select label="Terms" value={term} onChange={(e) => setTerm(e.target.value)}>
          {terms.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
        <div>
          <Badge color="dueColor"> Total Assigned: {totalAssigned}</Badge>
          <Badge color="submitColor"> Total Completed: {totalCompleted}</Badge>
        </div>
        {addMail && (
          <div>
            <Button
              style={{ marginRight: 5 }}
              disabled={selectedItems.length === 0}
              variant="outlined"
              onClick={() => setMailDialogShow(true)}
            >
              Next
            </Button>
            <Button color="error" variant="outlined" onClick={() => setAddMail(false)}>
              Cancel
            </Button>
          </div>
        )}
      </Stack>
    );
  }

  return (
    <Page title="Reviewer Report | Genius">
      {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
      <MuiTable
        title={<TermSelect />}
        tableData={tableData}
        tableColumns={headers}
        readMoreDisable
        customOption={{
          selectableRows: addMail ? 'multiple' : 'none',
          onRowSelectionChange,
          rowsSelected: selectedItems,
          customToolbar: () =>
            isCommunication !== -1 && (
              <Tooltip title="Send Email">
                <IconButton onClick={() => setAddMail(true)}>
                  <Email />
                </IconButton>
              </Tooltip>
            ),
          onFilterChange: (changedColumn, filterList, type, changedColumnIndex, displayData) => {
            const arr = [];
            displayData.forEach((item) => {
              arr.push(tableData[item.dataIndex]);
            });
            setDisplayData(arr);
          }
        }}
      />
      <CustomDialog
        onClose={() => setMailDialogShow(false)}
        open={mailDialogShow}
        maxWidth="lg"
        title="Send Mail"
        fullWidth
        disableEnforceFocus
      >
        <DialogContent>
          <CustomMail
            handleRefresh={handleRefresh}
            mailType={CUSTOM_MAIL_TYPE}
            idList={idList}
            handleClose={() => setMailDialogShow(false)}
          />
        </DialogContent>
      </CustomDialog>
    </Page>
  );
}

Index.propTypes = {
  isCommunication: PropTypes.number,
  terms: PropTypes.array,
  active_term: PropTypes.number
};

export default connect((store) => ({
  isCommunication: store.routeReducer.isCommunication,
  terms: store.loginReducer.terms,
  active_term: store.loginReducer.active_term
}))(Index);
