import React, { useState } from 'react';
import { Button, Grid } from '@mui/material';
import { Box, Typography } from '@material-ui/core';
import { Icon } from '@iconify/react';
import Page from '../../../../components/Page';
import LandingPageCard from '../../../../components/CustomComponents/CustomCard/LandingPageCard';
import CountryReports from './CountryReports';
import SchoolReports from './SchoolReports';

const LANDING_PAGE = 0;
const COUNTRY_REPORT = 1;
const SCHOOL_REPORT = 2;

const CARDS = [
  {
    id: 1,
    name: 'Country Report',
    description: 'Project application stats for countries'
  },
  {
    id: 2,
    name: 'School Report',
    description: 'Project application stats for school'
  }
];

function Index() {
  const [mode, setMode] = useState(LANDING_PAGE);

  function generate(data) {
    if (data.id === 1) {
      setMode(COUNTRY_REPORT);
    } else {
      setMode(SCHOOL_REPORT);
    }
  }

  return (
    <Page title="Award Reports | Genius">
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
        {mode !== LANDING_PAGE && (
          <>
            <Typography variant="subtitle1">
              Award Operation > Report >{' '}
              {mode === COUNTRY_REPORT ? 'Country Report' : 'School Report'}
            </Typography>
            <Button
              startIcon={<Icon icon="akar-icons:arrow-back-thick-fill" />}
              variant="outlined"
              onClick={() => setMode(LANDING_PAGE)}
            >
              Back
            </Button>
          </>
        )}
      </Box>
      {mode === LANDING_PAGE ? (
        <Grid container spacing={2}>
          {CARDS.map((item, index) => (
            <Grid item xs={12} md={6} lg={4} xl={3} key={index}>
              <LandingPageCard data={item} generate={generate} />
            </Grid>
          ))}
        </Grid>
      ) : mode === COUNTRY_REPORT ? (
        <CountryReports />
      ) : (
        <SchoolReports />
      )}
    </Page>
  );
}

export default Index;
