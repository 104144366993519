export default [
  {
    name: 'participant_id',
    label: 'ID'
  },
  {
    name: 'first_name',
    label: 'First Name'
  },
  {
    name: 'last_name',
    label: 'Last Name'
  },
  {
    name: 'gender',
    label: 'Gender'
  },
  {
    name: 'email',
    label: 'Email'
  },
  {
    name: 'participant_type',
    label: 'Type'
  },
  {
    name: 'grade_level',
    label: 'Grade Level'
  },
  {
    name: 'date_of_birth_comp',
    label: 'Date of Birth'
  }
];
