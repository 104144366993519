import { combineReducers } from 'redux';
import loginReducer from './loginReducer';
import alertReducer from './alertReducer';
import dashboardReducer from './dashboardReducer';
import projectReducer from './projectReducer';
import routeReducer from './routeReducer';
import tokenReducer from './tokenReducer';
import headerReducer from './headerReducer';

export default combineReducers({
  loginReducer,
  alertReducer,
  dashboardReducer,
  projectReducer,
  routeReducer,
  tokenReducer,
  headerReducer
});
