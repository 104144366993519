import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Button, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import MuiTable from '../../../components/CustomComponents/MuiTable';
import headers from './_utils/headerVariables';
import Page from '../../../components/Page';
import { deleteGuideline, getGuideline, getGuidelineList } from './_apis/serviceCalls';
import ProcessInfoBox from '../../../components/CustomComponents/CustomCard/ProcessInfoBox';
import { dataMaker } from './_utils/dataMaker';
import AddUpdateDialog from './Dialogs/AddUpdateDialog';
import ViewDialog from './Dialogs/ViewDialog';
import { setConfirmationAlert, setSnackbar } from '../../../actions/alertActions';

function Index(props) {
  const { dispatch } = props;
  const [tableData, setTableData] = useState([]);
  const [guidelines, setGuidelines] = useState([]);
  const [addDialog, setAddDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);
  const [selectedGuideline, setSelectedGuideline] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    getGuidelineList(
      {},
      (data) => {
        const updatedData = dataMaker(
          data.data,
          handleViewGuideline,
          handleEditGuideline,
          handleDeleteGuideline
        );
        setGuidelines(data.guidelines);
        setTableData(updatedData);
      },
      () => {}
    ).then(() => {});
  }

  function handleEditGuideline(guideline) {
    setSelectedGuideline(guideline);
    setAddDialog(true);
  }

  function handleDeleteGuideline(guideline_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'As a result of this action, the guideline will delete.',
        () => {
          deleteGuideline(
            { guideline_id },
            () => {
              dispatch(setSnackbar('success', 'The guideline deleted successfully.'));
              fetchData();
            },
            () => {}
          ).then(() => dispatch({ type: 'RESET_CONFIRMATION_ALERT' }));
        }
      )
    );
  }

  function handleViewGuideline(guideline_id) {
    getGuideline({ guideline_id }, (data) => {
      setSelectedGuideline(data);
      setViewDialog(true);
    });
  }

  function handleClose() {
    setAddDialog(false);
    setViewDialog(false);
    setSelectedGuideline(null);
  }

  return (
    <Page title="Guideline Operation | Genius">
      <Stack spacing={2}>
        {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
        <MuiTable
          tableData={tableData}
          tableColumns={headers}
          title={
            <Button variant="outlined" onClick={() => setAddDialog(true)}>
              Add New
            </Button>
          }
        />
      </Stack>
      {addDialog && (
        <AddUpdateDialog
          handleClose={handleClose}
          selectedGuideline={selectedGuideline}
          refreshData={fetchData}
        />
      )}
      {viewDialog && <ViewDialog handleClose={handleClose} selectedGuideline={selectedGuideline} />}
    </Page>
  );
}

Index.propTypes = {
  dispatch: PropTypes.func
};

export default connect(() => ({}))(Index);
