import { map } from 'lodash';
import store from '../store';
import { setLoading, setSnackbar } from '../actions/alertActions';
import { refreshToken } from '../actions/loginActions';

const UNAUTHORIZED = 401;

export async function fetchApi(url, params, successCallback, errorCallback) {
  try {
    store.dispatch(setLoading(1, 'Loading...'));
    const state = store.getState();
    if (!(state && state.tokenReducer && state.tokenReducer)) {
      throw new Error('Store not initialized yet');
    }
    const { access_token } = state.tokenReducer;
    if (
      !(
        access_token ||
        url.path.includes('auth') ||
        url.path.includes('registration') ||
        url.path.includes('general') ||
        url.path.includes('payment') ||
        url.path.includes('survey')
      )
    ) {
      store.dispatch(setSnackbar('error', 'You need to login!'));
      throw new Error('Need access token for the operation');
    }
    let signedRequest;
    if (url.method === 'GET') {
      const endUrl = `${url.path}?${buildFetchApiParameters(params)}`;
      signedRequest = await window._prepareFetchRequest(endUrl, {
        method: url.method,
        headers: {
          Accept: 'application/json',
          't-access-token': access_token
        },
        credentials: 'include'
      });
    } else {
      signedRequest = await window._prepareFetchRequest(url.path, {
        method: url.method,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          't-access-token': access_token
        },
        credentials: 'include',
        body: JSON.stringify({ ...params })
      });
    }
    const response = await fetch(signedRequest);
    if (response.ok) {
      const data = await response.json();
      successCallback(data);
      store.dispatch(setLoading(0, ''));
    } else {
      store.dispatch(setLoading(0, ''));
      const error = await response.json();
      console.log(error, 'errorData');
      if (url.path.includes('payment')) {
        store.dispatch(setSnackbar('error', error.message));
      }
      if (error.is_visible) {
        store.dispatch(setSnackbar('error', error.go_error_message));
      }
      errorCallback(error);
      if (
        error.http_status_code === UNAUTHORIZED &&
        error.go_error_code === 2 &&
        !url.path.includes('refresh_token')
      ) {
        store.dispatch(
          refreshToken(
            () => fetchApi(url, params, successCallback, errorCallback),
            () => {}
          )
        );
      } else {
        errorCallback(error);
      }
    }
  } catch (error) {
    store.dispatch(setSnackbar('error', 'An unknown error occurred!'));
    errorCallback(error);
  }
}

const buildFetchApiParameters = (data) => {
  const params = new URLSearchParams();
  map(data, (value, key) => {
    if (Array.isArray(data[key])) {
      map(value, (item) => params.append(`${key}[]`, item));
    } else {
      params.append(key, value);
    }
  });
  return params;
};
