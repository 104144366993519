import { Button, DialogContent, Grid, Stack, Tooltip } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { Icon } from '@iconify/react';
import { IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import MuiTable from '../../../../components/CustomComponents/MuiTable';
import headers from './utils/headerVariables';
import {
  downloadInvitationLetter,
  getInvitationLetterList,
  participantInvitationLetter
} from '../serviceCalls';
import CustomDialog from '../../../../components/CustomComponents/CustomDialog';
import ParticipantDialog from './ParticipantDialog';
import Page from '../../../../components/Page';
import ProcessInfoBox from '../../../../components/CustomComponents/CustomCard/ProcessInfoBox';
import { ParticipantInfo } from '../../../../info/ParticipantInfo';
import ProcessWarningBox from '../../../../components/CustomComponents/CustomCard/ProcessWarningBox';

function Index({ isFinalist }) {
  const [tableData, setTableData] = useState([]);
  const [dialogShow, setDialogShow] = useState(false);
  const [participantList, setParticipantList] = useState([]);
  const [guidelines, setGuidelines] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);
  function fetchData() {
    getInvitationLetterList(
      {},
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((item) => {
          item.created_at_comp = moment(item.created_at).format('YYYY-MM-DD / LT');
          item.participant_comp = item.participant_names_complete && (
            <Tooltip title={item.participant_names_complete}>
              <p style={{ cursor: 'pointer' }}>{item.participant_names} </p>
            </Tooltip>
          );
          item.download = (
            <IconButton color="primary" onClick={() => handleDownload(item.id)}>
              <Icon icon="akar-icons:cloud-download" width={24} height={24} />
            </IconButton>
          );
        });
        setTableData(list);
      },
      () => {}
    );
  }
  function requestInvitationLetter() {
    participantInvitationLetter(
      {},
      (data) => {
        data.forEach((item) => {
          item.name = `${item.first_name} ${item.last_name}`;
          item.date_of_birth_comp = moment(item.date_of_birth).format('YYYY-MM-DD');
        });
        setParticipantList(data);
        setDialogShow(true);
      },
      () => {}
    );
  }
  function handleRefresh() {
    setDialogShow(false);
    fetchData();
  }
  function handleDownload(invitation_letter_id) {
    downloadInvitationLetter(
      { invitation_letter_id },
      () => {},
      () => {}
    );
  }

  return (
    <Page title="Invitation Letter | Genius">
      <Grid container>
        <Grid item xs={12}>
          {isFinalist ? (
            <Stack spacing={1}>
              {guidelines.length > 0 && (
                <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />
              )}
              <MuiTable
                title={
                  <div style={{ marginTop: 10 }}>
                    <Button variant="outlined" onClick={requestInvitationLetter}>
                      Request Invitation Letter
                    </Button>
                  </div>
                }
                tableData={tableData}
                tableColumns={headers}
              />
            </Stack>
          ) : (
            <ProcessWarningBox Comp={<ParticipantInfo />} />
          )}
        </Grid>
        <CustomDialog
          onClose={() => setDialogShow(false)}
          open={dialogShow}
          maxWidth="lg"
          title="Request Invitation Letter"
        >
          <DialogContent>
            <ParticipantDialog
              participantList={participantList}
              handleClose={() => setDialogShow(false)}
              handleRefresh={handleRefresh}
            />
          </DialogContent>
        </CustomDialog>
      </Grid>
    </Page>
  );
}

Index.propTypes = {
  isFinalist: PropTypes.bool
};

export default connect((store) => ({
  isFinalist: store.dashboardReducer.isFinalist
}))(Index);
