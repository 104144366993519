export default [
  {
    name: 'balance_comp',
    label: 'Balance',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'balance_filter',
    label: 'Balance',
    options: {
      display: false
    }
  },
  {
    name: 'is_confirmed_comp',
    label: 'Confirmed',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'is_confirmed_filter',
    label: 'Confirmed',
    options: {
      display: false
    }
  },
  {
    name: 'is_attended_comp',
    label: 'Attended',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'is_attended_filter',
    label: 'Attended',
    options: {
      display: false
    }
  },
  {
    name: 'edit',
    label: 'Edit',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'id',
    label: 'ID'
  },
  {
    name: 'country',
    label: 'Country'
  },
  {
    name: 'address',
    label: 'Address'
  },
  {
    name: 'school_name',
    label: 'School'
  },
  {
    name: 'project_id',
    label: 'Project ID'
  },
  {
    name: 'project_title',
    label: 'Project Title'
  },
  {
    name: 'project_count',
    label: 'Project Count'
  },
  {
    name: 'discipline',
    label: 'Discipline'
  },
  {
    name: 'presentation_link_comp',
    label: 'Presentation Link',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'presentation_link_filter',
    label: 'Presentation Link',
    options: {
      display: false
    }
  },
  {
    name: 'participant_type',
    label: 'Type'
  },
  {
    name: 'name',
    label: 'Participant Name'
  },
  {
    name: 'email',
    label: 'Email'
  },
  {
    name: 'user_email',
    label: 'User Email'
  },
  {
    name: 'gender',
    label: 'Gender'
  },
  {
    name: 'grade_level',
    label: 'Grade Level'
  },
  {
    name: 'staying_at_hotel_comp',
    label: 'Staying at Hotel'
  },
  {
    name: 'date_of_birth_comp',
    label: 'Date of Birth'
  },
  {
    name: 'finalist_status',
    label: 'Finalist Status'
  },
  {
    name: 'review_status',
    label: 'Review Status'
  },
  {
    name: 'medal_type',
    label: 'Medal Type'
  },
  {
    name: 'certificate',
    label: 'Download Certificate',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'medal',
    label: 'Download Medal',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'add_money',
    label: 'Add Payment',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  }
];
