import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Divider, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { getTravel, getTravelOption, getTravelType } from './utils/serviceCalls';
import ArrivalTable from './ArrivalTable';
import DepartureTable from './DepartureTable';
import Page from '../../../components/Page';
import ProcessInfoBox from '../../../components/CustomComponents/CustomCard/ProcessInfoBox';
import ProcessWarningBox from '../../../components/CustomComponents/CustomCard/ProcessWarningBox';
import { ParticipantInfo } from '../../../info/ParticipantInfo';

function Index({ isFinalist }) {
  const [arrivalData, setArrivalData] = useState([]);
  const [departureData, setDepartureData] = useState([]);
  const [travelTypes, setTravelTypes] = useState([]);
  const [travelOption, setTravelOption] = useState([]);
  const [guidelines, setGuidelines] = useState([]);
  useEffect(() => {
    getTravelOption(
      {},
      (data) => {
        setTravelOption(data);
      },
      () => {}
    );
    getTravelType(
      {},
      (data) => {
        setTravelTypes(data);
      },
      () => {}
    );
    getTravel(
      { travel_type_id: 1 },
      (data) => {
        setGuidelines(data.guidelines);
        setArrivalData(data.data);
      },
      () => {}
    );
    getTravel(
      { travel_type_id: 2 },
      (data) => {
        setGuidelines(data.guidelines);
        setDepartureData(data.data);
      },
      () => {}
    );
  }, []);
  function handleRefresh() {
    getTravel(
      { travel_type_id: 1 },
      (data) => {
        setArrivalData(data.data);
      },
      () => {}
    );
    getTravel(
      { travel_type_id: 2 },
      (data) => {
        setDepartureData(data.data);
      },
      () => {}
    );
  }

  return (
    <Page title="Travel | Genius">
      {isFinalist ? (
        <Stack spacing={3}>
          {guidelines.length > 0 && (
            <Grid item xs={12}>
              <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />
            </Grid>
          )}
          <ArrivalTable
            handleRefresh={handleRefresh}
            tableData={arrivalData}
            travelTypes={travelTypes}
            travelOption={travelOption}
          />
          <Divider />
          <DepartureTable
            tableData={departureData}
            handleRefresh={handleRefresh}
            travelTypes={travelTypes}
            travelOption={travelOption}
          />
        </Stack>
      ) : (
        <ProcessWarningBox Comp={<ParticipantInfo />} />
      )}
    </Page>
  );
}

Index.propTypes = {
  isFinalist: PropTypes.bool
};

export default connect((store) => ({
  isFinalist: store.dashboardReducer.isFinalist
}))(Index);
