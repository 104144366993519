import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Link, Stack, TextField, IconButton, InputAdornment, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import { LoadingButton } from '@material-ui/lab';
import { handleGetTerms, handleLogin, handleGetActiveTerm } from '../../../actions/loginActions';
import { emailVerificationCreate } from '../../../actions/registerActions';
import { setSnackbarForMain } from '../../../actions/alertActions';
import ProcessErrorBox from '../../CustomComponents/CustomCard/ProcessErrorBox';

// ----------------------------------------------------------------------
const APPLICANT = 2;
const FAIR = 3;
const REVIEWER = 4;
const JUDGE = 5;
const SUPER_USER = 21;
const PROJECT_REVIEWER = 25;

function LoginForm(props) {
  const { dispatch } = props;
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showUnverifiedWarning, setShowUnverifiedWarning] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .max(500, 'Too Long!')
      .email('Email must be a valid email address')
      .required('Email is required'),
    password: Yup.string().required('Password is required')
  });
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: (values, { setSubmitting }) => {
      dispatch(
        handleLogin(
          { email: values.email, password: values.password },
          (data) => {
            dispatch({ type: 'RESET_SNACKBAR', payload: null });
            let arr = [];
            let isAdmin = false;
            let commonModule = false;
            data.module_access.forEach((item) => {
              if (item.application_access_key !== 'go_shield') {
                if (item.application_access_key === 'go_admin') {
                  const adminModules = item.modules;
                  commonModule = adminModules.find((x) => x.module_access_key === 'common');
                  isAdmin = true;
                }
                arr = [...arr, ...item.modules];
              }
            });
            dispatch(
              handleGetActiveTerm(
                () => {
                  setSubmitting(false);
                  const isProfileOK =
                    Object.keys(data.user_profile).length !== 0 ||
                    data.user_role === FAIR ||
                    data.user_role === JUDGE;
                  if (!isProfileOK) {
                    navigate('/profile', { replace: true });
                  } else if (data.user_role === FAIR) {
                    navigate('/fair', { replace: true });
                  } else if (data.user_role === APPLICANT) {
                    navigate('/dashboard', { replace: true });
                  } else if (data.user_role === REVIEWER) {
                    navigate('/reviewer', { replace: true });
                  } else if (data.user_role === PROJECT_REVIEWER) {
                    if (commonModule) {
                      dispatch(handleGetTerms());
                    }
                    navigate('/project_reviewer', { replace: true });
                  } else if (data.user_role === SUPER_USER) {
                    if (commonModule) {
                      dispatch(handleGetTerms());
                    }
                    navigate('/admin', { replace: true });
                  } else if (isAdmin) {
                    if (commonModule) {
                      dispatch(handleGetTerms());
                    }
                    navigate(`/admin/${arr[0].module_access_key}`, { replace: true });
                  } else navigate(`/dashboard/${arr[0].module_access_key}`, { replace: true });
                },
                () => {
                  setSubmitting(false);
                }
              )
            );
          },
          (error) => {
            console.log(error, 'Login Failed');
            if (error.details === 'Unverified user') {
              setShowUnverifiedWarning(true);
            }
            setSubmitting(false);
            if (error.is_visible) {
              dispatch(setSnackbarForMain('error', error.go_error_message));
            }
          }
        )
      );
    }
  });
  function resendEmail() {
    emailVerificationCreate(
      { email: values.email },
      () => {
        dispatch(
          setSnackbarForMain('info', 'A verification email has been sent, please check your inbox.')
        );
        setShowUnverifiedWarning(false);
      },
      () => {}
    );
  }
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  useEffect(() => {
    setShowUnverifiedWarning(false);
  }, [values]);
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="end" sx={{ my: 2 }}>
          <Link component={RouterLink} variant="subtitle2" to="/reset">
            Forgot password?
          </Link>
        </Stack>
        <LoadingButton
          loading={isSubmitting}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          Login
        </LoadingButton>
      </Form>
      {showUnverifiedWarning && (
        <ProcessErrorBox
          Comp={
            <Stack direction="column" alignItems="center" justifyContent="center" spacing={1}>
              <Typography variant="subtitle2">
                The account has not been verified yet, please check your email to verify. To resend
                the email, click on the button.
              </Typography>
              <Button variant="outlined" onClick={resendEmail}>
                Resend Email
              </Button>
            </Stack>
          }
        />
      )}
    </FormikProvider>
  );
}

LoginForm.propTypes = {
  dispatch: PropTypes.func.isRequired
};

export default connect(() => ({}))(LoginForm);
