import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import { Box, Container, Typography } from '@material-ui/core';
import { motion } from 'framer-motion';
import { getSurveyDetail } from './_apis/serviceCalls';
import { MotionContainer, varBounceIn } from '../../components/animate';
import Page from '../../components/Page';
import SubmissionPage from './SubmissionPage';
import SuccessPage from './SuccessPage';

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(10)
}));

function Survey() {
  const [surveyData, setSurveyData] = useState(null);
  const [error, setError] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const { search } = useLocation();
  const survey_id = new URLSearchParams(search).get('survey_id');
  const survey_code = new URLSearchParams(search).get('survey_code');

  useEffect(() => {
    const params = { survey_id, survey_code };
    getSurveyDetail(
      params,
      (data) => {
        setSurveyData(data);
      },
      (err) => {
        setError(err.message);
      }
    );
  }, []);

  return (
    <RootStyle title="Survey | Genius">
      {isSuccess ? (
        <SuccessPage />
      ) : (
        <Container>
          <MotionContainer initial="initial" open>
            <Box sx={{ margin: 'auto', textAlign: 'center' }}>
              <motion.div variants={varBounceIn}>
                {surveyData ? (
                  <SubmissionPage surveyData={surveyData} setIsSuccess={setIsSuccess} />
                ) : error ? (
                  <Typography variant="h3" paragraph>
                    {error}
                  </Typography>
                ) : (
                  <p>Loading...</p>
                )}
              </motion.div>
            </Box>
          </MotionContainer>
        </Container>
      )}
    </RootStyle>
  );
}

export default connect(() => ({}))(Survey);
