export default [
  {
    name: 'judge_id',
    label: 'ID'
  },
  {
    name: 'first_name',
    label: 'First Name'
  },
  {
    name: 'last_name',
    label: 'Last Name'
  },
  {
    name: 'institution',
    label: 'Institution'
  },
  {
    name: 'email',
    label: 'Email'
  },
  {
    name: 'term_id',
    label: 'Term'
  },
  {
    name: 'judging_session_disciplines',
    label: 'Disciplines'
  },
  {
    name: 'judging_sessions',
    label: 'Sessions'
  },
  {
    name: 'judging_session_types',
    label: 'Session Types'
  },
  {
    name: 'judge_approval_status',
    label: 'Approval Status'
  }
];
