export default [
  {
    name: 'id',
    label: 'ID'
  },
  {
    name: 'first_name',
    label: 'First Name'
  },
  {
    name: 'last_name',
    label: 'Last Name'
  },
  {
    name: 'grade_level',
    label: 'Grade Level'
  },
  {
    name: 'date_of_birth',
    label: 'Date of Birth'
  },
  {
    name: 'email',
    label: 'Email'
  },
  {
    name: 'country',
    label: 'Country'
  },
  {
    name: 'state',
    label: 'State'
  },
  {
    name: 'school_name',
    label: 'School'
  },
  {
    name: 'project_id',
    label: 'Project ID'
  },
  {
    name: 'project_title',
    label: 'Project Title'
  },
  {
    name: 'discipline',
    label: 'Discipline'
  },
  {
    name: 'medal_type',
    label: 'Medal'
  }
];
