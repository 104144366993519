export default function reducer(
  state = {
    cartTotalFee: 0.0,
    cartItemsProject: [],
    cartItemsParticipants: [],
    cartItemsTrips: [],
    isFinalist: false,
    activePerformance: null
  },
  action
) {
  switch (action.type) {
    case 'SET_FINALIST': {
      return {
        ...state,
        isFinalist: action.payload
      };
    }
    case 'SET_ACTIVE_PERFORMANCE': {
      return {
        ...state,
        activePerformance: action.payload
      };
    }
    case 'ADD_FEE': {
      return {
        ...state,
        cartTotalFee: action.payload
      };
    }
    case 'ADD_ITEM_CART_FROM_PROJECTS': {
      return {
        ...state,
        cartItemsProject: action.payload
      };
    }
    case 'ADD_ITEM_CART_FROM_PARTICIPANTS': {
      return {
        ...state,
        cartItemsParticipants: action.payload
      };
    }
    case 'ADD_ITEM_CART_FROM_TRIPS': {
      return {
        ...state,
        cartItemsTrips: action.payload
      };
    }
    case 'DELETE_ITEM_CART_FROM_PROJECTS': {
      const arr = state.cartItemsProject.filter((x) => x.id !== action.payload);
      const currentFee =
        parseFloat(state.cartTotalFee).toFixed(2) -
        parseFloat(state.cartItemsProject.find((x) => x.id === action.payload).balance).toFixed(2);
      return {
        ...state,
        cartItemsProject: arr,
        cartTotalFee: currentFee
      };
    }
    case 'DELETE_ITEM_CART_FROM_PARTICIPANTS': {
      const arr = state.cartItemsParticipants.filter((x) => x.id !== action.payload);
      const currentFee =
        parseFloat(state.cartTotalFee).toFixed(2) -
        parseFloat(
          state.cartItemsParticipants.find((x) => x.id === action.payload).balance
        ).toFixed(2);
      return {
        ...state,
        cartItemsParticipants: arr,
        cartTotalFee: currentFee
      };
    }
    case 'DELETE_ITEM_CART_FROM_TRIPS': {
      const arr = state.cartItemsTrips.filter((x) => x.participant_id !== action.payload);
      const currentFee =
        parseFloat(state.cartTotalFee).toFixed(2) -
        parseFloat(
          state.cartItemsTrips.find((x) => x.participant_id === action.payload).trip_balance
        ).toFixed(2);
      return {
        ...state,
        cartItemsTrips: arr,
        cartTotalFee: currentFee
      };
    }
    case 'CLEAR_CART': {
      return {
        ...state,
        cartTotalFee: 0,
        cartItemsProject: [],
        cartItemsParticipants: [],
        cartItemsTrips: []
      };
    }
    default: {
      return state;
    }
  }
}
