import * as React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { TextField } from '@material-ui/core';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import ReactHtmlParser from 'react-html-parser';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { addPresentation } from './utils/serviceCalls';

function AddUpdateDialog(props) {
  const { projectData, handleClose, handleRefresh } = props;
  const [isFrameShow, setIsFrameShow] = useState(false);
  const Schema = Yup.object().shape({
    link: Yup.string()
      .max(500, 'Too Long!')
      .matches(
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/,
        'Enter a valid YouTube link!'
      )
      .required('YouTube Media Link required')
  });
  const formik = useFormik({
    initialValues: {
      link: projectData?.presentation_link || ''
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      addPresentation(
        { link: values.link, project_id: projectData.project_id },
        () => {
          handleRefresh();
        },
        () => {}
      );
    }
  });
  const { errors, touched, getFieldProps, values, handleSubmit } = formik;

  useEffect(() => {
    if (values.link === '') {
      setIsFrameShow(false);
    }
  }, [values]);

  function getId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  }
  const videoId = getId(values.link);
  const iframeMarkup = `<iframe width="560" height="315" src="//www.youtube.com/embed/${videoId}" frameborder="0" allowfullscreen></iframe>`;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Typography variant="subtitle1">{projectData.title}</Typography>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
            <TextField
              fullWidth
              type="link"
              label="Media Link"
              {...getFieldProps('link')}
              error={Boolean(touched.link && errors.link)}
              helperText={touched.link && errors.link}
            />
            <Button
              variant="outlined"
              disabled={Boolean(touched.link && errors.link)}
              onClick={() => setIsFrameShow(true)}
            >
              Preview
            </Button>
          </Stack>
          {isFrameShow && <div> {ReactHtmlParser(iframeMarkup)} </div>}
          <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="end" spacing={2}>
            <Button type="submit" variant="outlined" disabled={!isFrameShow}>
              Save
            </Button>
            <Button variant="outlined" color="error" onClick={handleClose}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

AddUpdateDialog.propTypes = {
  projectData: PropTypes.object,
  handleClose: PropTypes.func,
  handleRefresh: PropTypes.func
};

export default connect(() => ({}))(AddUpdateDialog);
