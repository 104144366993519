export default function reducer(
  state = {
    isPerformance: -1,
    isHome: -1,
    isParticipation: -1,
    isParticipantFeature: -1,
    isInvitationLetterFeature: -1,
    isVipFeature: -1,
    isProject: -1,
    isSchool: -1,
    isTravel: -1,
    isTrip: -1,
    isPresentation: -1,
    isProfile: -1,
    isPayment: -1,
    isParticipantPaymentFeature: -1,
    isProjectPaymentFeature: -1,
    isTripPaymentFeature: -1,
    isParticipantOperation: -1,
    isParticipationParticipantOpFeature: -1,
    isInvitationLettersParticipantOpFeature: -1,
    isVipParticipantOpFeature: -1,
    isTripParticipantOpFeature: -1,
    isTravelParticipantOpFeature: -1,
    isFairOperation: -1,
    isProfileFairOpFeature: -1,
    isSchoolOperation: -1,
    isFairFairOpFeature: -1,
    isRequestFairOpFeature: -1,
    isSettings: -1,
    isFeeSettingsFeature: -1,
    isTripSettingsFeature: -1,
    isCategorySettingsFeature: -1,
    isDisciplineSettingsFeature: -1,
    isTermSettingsFeature: -1,
    isUserManagement: -1,
    isRoleUMFeature: -1,
    isUserUMFeature: -1,
    isProjectOperation: -1,
    isFairAdmin: -1,
    isReviewOperation: -1,
    isReviewRO: -1,
    isReviewerReportRO: -1,
    isDataRecovery: -1,
    isCommunication: -1,
    isProfileFairAdminFeature: -1,
    isRequestFairAdminFeature: -1,
    isReviewAdmin: -1,
    isInfoPOFeature: -1,
    isReportPOFeature: -1,
    isPerformanceOperation: -1,
    isDocumentFairAdminFeature: -1,
    isDocumentForApplicant: -1,
    isJudgeOperation: -1,
    isSessionJOFeature: -1,
    isJudgeJOFeature: -1,
    isGroupJOFeature: -1,
    isJudgingJOFeature: -1,
    isJudgingFormJOFeature: -1,
    isJudge: -1,
    isRoboticsJOFeature: -1,
    isScorekeeper: -1,
    isApplicantJudging: -1,
    isAwardOperation: -1,
    isAwardRO: -1,
    isAwardReportRO: -1,
    isCertificateRO: -1,
    isJudgeCertificate: -1,
    isGuidelineOperation: -1,
    isDocumentOperation: -1,
    isPFO: -1,
    isConfigPFO: -1,
    isReportPFO: -1,
    isApplicantPayment: -1,
    isSurvey: -1,
    isCodingOperation: -1,
    isSOS: -1,
    isSOR: -1
  },
  action
) {
  switch (action.type) {
    case 'SET_ROUTE': {
      const {
        isPerformance,
        isHome,
        isParticipation,
        isParticipantFeature,
        isInvitationLetterFeature,
        isVipFeature,
        isProject,
        isSchool,
        isTravel,
        isTrip,
        isPresentation,
        isProfile,
        isPayment,
        isParticipantPaymentFeature,
        isProjectPaymentFeature,
        isTripPaymentFeature,
        isParticipantOperation,
        isParticipationParticipantOpFeature,
        isInvitationLettersParticipantOpFeature,
        isVipParticipantOpFeature,
        isTripParticipantOpFeature,
        isTravelParticipantOpFeature,
        isFairOperation,
        isSchoolOperation,
        isProfileFairOpFeature,
        isFairFairOpFeature,
        isRequestFairOpFeature,
        isSettings,
        isFeeSettingsFeature,
        isTripSettingsFeature,
        isCategorySettingsFeature,
        isDisciplineSettingsFeature,
        isTermSettingsFeature,
        isUserManagement,
        isRoleUMFeature,
        isUserUMFeature,
        isProjectOperation,
        isFairAdmin,
        isReviewOperation,
        isReviewRO,
        isReviewerReportRO,
        isDataRecovery,
        isCommunication,
        isProfileFairAdminFeature,
        isRequestFairAdminFeature,
        isReviewAdmin,
        isInfoPOFeature,
        isReportPOFeature,
        isPerformanceOperation,
        isDocumentFairAdminFeature,
        isDocumentForApplicant,
        isJudgeOperation,
        isSessionJOFeature,
        isJudgeJOFeature,
        isGroupJOFeature,
        isJudgingJOFeature,
        isJudgingFormJOFeature,
        isJudge,
        isRoboticsJOFeature,
        isScorekeeper,
        isApplicantJudging,
        isAwardOperation,
        isAwardRO,
        isAwardReportRO,
        isCertificateRO,
        isJudgeCertificate,
        isGuidelineOperation,
        isDocumentOperation,
        isPFO,
        isConfigPFO,
        isReportPFO,
        isApplicantPayment,
        isSurvey,
        isCodingOperation,
        isSOS,
        isSOR
      } = action.payload;
      return {
        isPerformance,
        isHome,
        isParticipation,
        isParticipantFeature,
        isInvitationLetterFeature,
        isVipFeature,
        isProject,
        isSchool,
        isTravel,
        isTrip,
        isPresentation,
        isProfile,
        isPayment,
        isParticipantPaymentFeature,
        isProjectPaymentFeature,
        isTripPaymentFeature,
        isParticipantOperation,
        isParticipationParticipantOpFeature,
        isInvitationLettersParticipantOpFeature,
        isVipParticipantOpFeature,
        isTripParticipantOpFeature,
        isTravelParticipantOpFeature,
        isFairOperation,
        isRequestFairOpFeature,
        isSchoolOperation,
        isProfileFairOpFeature,
        isFairFairOpFeature,
        isSettings,
        isFeeSettingsFeature,
        isTripSettingsFeature,
        isCategorySettingsFeature,
        isDisciplineSettingsFeature,
        isTermSettingsFeature,
        isUserManagement,
        isRoleUMFeature,
        isUserUMFeature,
        isProjectOperation,
        isFairAdmin,
        isDataRecovery,
        isCommunication,
        isReviewOperation,
        isReviewRO,
        isReviewerReportRO,
        isProfileFairAdminFeature,
        isRequestFairAdminFeature,
        isReviewAdmin,
        isInfoPOFeature,
        isReportPOFeature,
        isPerformanceOperation,
        isDocumentFairAdminFeature,
        isDocumentForApplicant,
        isJudgeOperation,
        isSessionJOFeature,
        isJudgeJOFeature,
        isGroupJOFeature,
        isJudgingJOFeature,
        isJudgingFormJOFeature,
        isJudge,
        isRoboticsJOFeature,
        isScorekeeper,
        isApplicantJudging,
        isAwardOperation,
        isAwardRO,
        isAwardReportRO,
        isCertificateRO,
        isJudgeCertificate,
        isGuidelineOperation,
        isDocumentOperation,
        isPFO,
        isConfigPFO,
        isReportPFO,
        isApplicantPayment,
        isSurvey,
        isCodingOperation,
        isSOS,
        isSOR
      };
    }
    case 'CLEAR_ROUTE': {
      return {
        isPerformance: -1,
        isHome: -1,
        isParticipation: -1,
        isParticipantFeature: -1,
        isInvitationLetterFeature: -1,
        isVipFeature: -1,
        isProject: -1,
        isSchool: -1,
        isTravel: -1,
        isTrip: -1,
        isPresentation: -1,
        isProfile: -1,
        isPayment: -1,
        isParticipantPaymentFeature: -1,
        isProjectPaymentFeature: -1,
        isTripPaymentFeature: -1,
        isParticipantOperation: -1,
        isParticipationParticipantOpFeature: -1,
        isInvitationLettersParticipantOpFeature: -1,
        isVipParticipantOpFeature: -1,
        isTripParticipantOpFeature: -1,
        isTravelParticipantOpFeature: -1,
        isFairOperation: -1,
        isProfileFairOpFeature: -1,
        isSchoolOperation: -1,
        isFairFairOpFeature: -1,
        isRequestFairOpFeature: -1,
        isSettings: -1,
        isFeeSettingsFeature: -1,
        isTripSettingsFeature: -1,
        isCategorySettingsFeature: -1,
        isDisciplineSettingsFeature: -1,
        isTermSettingsFeature: -1,
        isUserManagement: -1,
        isRoleUMFeature: -1,
        isUserUMFeature: -1,
        isProjectOperation: -1,
        isFairAdmin: -1,
        isReviewOperation: -1,
        isReviewRO: -1,
        isReviewerReportRO: -1,
        isDataRecovery: -1,
        isCommunication: -1,
        isProfileFairAdminFeature: -1,
        isRequestFairAdminFeature: -1,
        isReviewAdmin: -1,
        isInfoPOFeature: -1,
        isReportPOFeature: -1,
        isPerformanceOperation: -1,
        isDocumentFairAdminFeature: -1,
        isDocumentForApplicant: -1,
        isJudgeOperation: -1,
        isSessionJOFeature: -1,
        isJudgeJOFeature: -1,
        isGroupJOFeature: -1,
        isJudgingJOFeature: -1,
        isJudgingFormJOFeature: -1,
        isJudge: -1,
        isRoboticsJOFeature: -1,
        isScorekeeper: -1,
        isApplicantJudging: -1,
        isAwardOperation: -1,
        isAwardRO: -1,
        isAwardReportRO: -1,
        isCertificateRO: -1,
        isJudgeCertificate: -1,
        isGuidelineOperation: -1,
        isDocumentOperation: -1,
        isPFO: -1,
        isConfigPFO: -1,
        isReportPFO: -1,
        isApplicantPayment: -1,
        isSurvey: -1,
        isCodingOperation: -1,
        isSOS: -1,
        isSOR: -1
      };
    }
    default: {
      return state;
    }
  }
}
