import { MenuItem, Stack, TextField } from '@material-ui/core';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { setLoading, setSnackbar } from '../../../../../../actions/alertActions';
import { addSurvey, getSurveyDetail, updateSurvey } from '../../../_apis/serviceCalls';
import { getTermList } from '../../../../_apis/adminApi';

const UPDATE_SURVEY = 2;

function FormInfoView(props) {
  const { data, mode, dispatch, handleNext, handleBack, setId, id } = props;
  const [surveyData, setSurveyData] = useState(data);
  const [termList, setTermList] = useState([]);

  useEffect(() => {
    getTermList(
      {},
      (data) => {
        setTermList(data);
      },
      () => {}
    );
    if (id) {
      getSurveyDetail(
        { survey_id: id },
        (_data) => {
          setSurveyData(_data);
          handleSetValues(_data);
        },
        () => {}
      );
    }
  }, []);

  const Schema = Yup.object().shape({
    name: Yup.string().max(500, 'Too Long!').required('Survey Name is required'),
    term_id: Yup.number().required('Term is required')
  });
  const formik = useFormik({
    initialValues: {
      name: mode === UPDATE_SURVEY ? surveyData.survey_name : '',
      term_id: mode === UPDATE_SURVEY ? surveyData.term_id : ''
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      dispatch(setLoading(1, 'Loading...'));
      if (!id) {
        addSurvey(
          values,
          (_data) => {
            setId(_data.id);
            dispatch(setSnackbar('success', 'The survey has been added'));
            dispatch(setLoading(0, ''));
            handleNext();
          },
          () => {
            dispatch(setLoading(0, ''));
          }
        );
      } else {
        const param = { ...values, survey_id: surveyData.survey_id };
        updateSurvey(
          param,
          () => {
            dispatch(setLoading(0, ''));
            handleNext();
            dispatch(setSnackbar('success', 'The survey has been updated'));
          },
          () => {
            dispatch(setLoading(0, ''));
          }
        );
      }
    }
  });
  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

  function handleSetValues(_data) {
    setFieldValue('name', _data?.survey_name || '');
    setFieldValue('term_id', _data?.term_id || '');
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 1 }}>
            <TextField
              fullWidth
              label="Terms"
              select
              {...getFieldProps('term_id')}
              error={Boolean(touched.term_id && errors.term_id)}
              helperText={touched.term_id && errors.term_id}
            >
              {termList.map((term) => (
                <MenuItem key={term.id} value={term.id}>
                  {term.description}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              fullWidth
              label="Survey Name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
          </Stack>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button variant="outlined" color="inherit" sx={{ mr: 1 }} onClick={handleBack}>
              Cancel
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button type="submit" variant="outlined">
              Next
            </Button>
          </Box>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

FormInfoView.propTypes = {
  data: PropTypes.object,
  mode: PropTypes.number.isRequired,
  handleNext: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  setId: PropTypes.func,
  id: PropTypes.number
};

export default connect(() => ({}))(FormInfoView);
