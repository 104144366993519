import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Button, Stack } from '@mui/material';
import PropTypes from 'prop-types';

export default function ProfilePicture(props) {
  const { addPicture, pic, handleClose } = props;
  const [upImg, setUpImg] = useState(pic);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 16 / 16 });
  const [completedCrop, setCompletedCrop] = useState(null);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
  }, [completedCrop]);

  return (
    <Stack spacing={2}>
      <div className="App">
        <div style={{ marginBottom: 10 }}>
          <input type="file" accept="image/*" onChange={onSelectFile} />
        </div>
        <ReactCrop
          src={upImg}
          onImageLoaded={onLoad}
          crop={crop}
          onChange={(c) => setCrop(c)}
          onComplete={(c) => setCompletedCrop(c)}
        />
        <div>
          <canvas
            ref={previewCanvasRef}
            // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
            style={{
              width: Math.round(completedCrop?.width ?? 0),
              height: Math.round(completedCrop?.height ?? 0)
            }}
          />
        </div>
      </div>
      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="end" spacing={1}>
        <Button
          variant="outlined"
          disabled={!completedCrop?.width || !completedCrop?.height}
          onClick={() => addPicture(previewCanvasRef.current, completedCrop)}
        >
          Upload
        </Button>
        <Button color="error" onClick={handleClose} variant="outlined">
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
}

ProfilePicture.propTypes = {
  addPicture: PropTypes.func,
  pic: PropTypes.string,
  handleClose: PropTypes.func
};
