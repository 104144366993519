import Alert from '@mui/material/Alert';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Grid } from '@material-ui/core';
import { Stack } from '@mui/material';
import { DragIndicator } from '@mui/icons-material';
import * as React from 'react';
import PropTypes from 'prop-types';
import QuestionDetail from '../QuestionDetail/QuestionDetail';

/*
    This view includes drag and drop zone to change order of the sub questions
*/

function QuestionsDragAndDrop(props) {
  const { rankUpdateAvailable, onDragEnd, updatedQuestionList } = props;

  return (
    <>
      {rankUpdateAvailable && (
        <Alert severity="info">
          Please drag the questions and drop them in the order you want. Numbers in red old
          ordering, numbers in blue new ordering.
        </Alert>
      )}
      {rankUpdateAvailable && (
        <div className="drag-drop-div-style">
          <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
            <Droppable droppableId="list">
              {(provided) => (
                <Grid item xs={12} ref={provided.innerRef} {...provided.droppableProps}>
                  {updatedQuestionList &&
                    updatedQuestionList.length > 0 &&
                    updatedQuestionList.map((question, index) => (
                      <Draggable key={index} draggableId={index.toString()} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Stack
                              spacing={1}
                              direction={{ xs: 'column', sm: 'row' }}
                              className="drag-drop-inside-stack-style"
                            >
                              <DragIndicator className="drag-drop-icon" />
                              <QuestionDetail question={question} newRank={index} rankChange />
                            </Stack>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </Grid>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
    </>
  );
}

QuestionsDragAndDrop.propTypes = {
  rankUpdateAvailable: PropTypes.bool,
  onDragEnd: PropTypes.func,
  updatedQuestionList: PropTypes.array
};

export default QuestionsDragAndDrop;
