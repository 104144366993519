import { fetchApi } from '../../../../api/fetchApi';

const judge = require('./judge_apis');

export async function getJudgingSession(config, scb, ecb) {
  try {
    const url = judge.GET_JUDGING_SESSION;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_JUDGING_SESSION');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addJudgingSession(config, scb, ecb) {
  try {
    const url = judge.ADD_JUDGING_SESSION;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_JUDGING_SESSION');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getJudgingSessionProject(config, scb, ecb) {
  try {
    const url = judge.GET_JUDGING_SESSION_PROJECT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_JUDGING_SESSION_PROJECT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}
