export function getSessionListData(sessionList) {
  const refinedData = [];
  const sessionIds = new Set(sessionList.map((session) => session.judging_session_type_id));
  const sessionIdList = Array.from(sessionIds);
  sessionIdList.sort((a, b) => (a > b ? 1 : -1));
  sessionIdList.forEach((session) => {
    const _sessionList = sessionList.filter(
      (_session) => _session.judging_session_type_id === session
    );
    const obj = {
      judging_session_type_id: session,
      judging_session_type_name: _sessionList[0].judging_session_type,
      list: _sessionList
    };
    refinedData.push(obj);
  });
  return refinedData;
}

export function getJudgeSessions(morningChecked, afternoonChecked, onlineCheckedlist) {
  let arr = [];
  if (morningChecked) {
    arr.push(morningChecked);
  }
  if (afternoonChecked) {
    arr.push(afternoonChecked);
  }
  if (onlineCheckedlist.length > 0) {
    arr = [...arr, ...onlineCheckedlist];
  }
  return arr;
}
