import { alpha, styled } from '@material-ui/core/styles';
import { Box, AppBar, Toolbar } from '@material-ui/core';
import mainLogo from './icons/GENIUS_Logo_horizontal.png';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 10;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  alignItems: 'center',
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

export default function MainNavbar() {
  return (
    <RootStyle>
      <ToolbarStyle>
        <img src={mainLogo} alt="logo" style={{ height: 100 }} />
        <Box sx={{ flexGrow: 1 }} />
      </ToolbarStyle>
    </RootStyle>
  );
}
