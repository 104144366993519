import PropTypes from 'prop-types';
import * as React from 'react';
import { Button, Stack } from '@mui/material';

/*
    This view includes buttons to add new sub question
     and change order of the sub questions
*/

function RubricHeader(props) {
  const {
    rankUpdateAvailable,
    handleUpdateRubricRank,
    updateRankLoading,
    cancelDragAndDrop,
    setAddRubricDialogShow,
    rubricList,
    setRankUpdateAvailable
  } = props;

  return rankUpdateAvailable ? (
    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} sx={{ mt: 1 }}>
      <Button onClick={handleUpdateRubricRank} loading={updateRankLoading} variant="outlined">
        Order
      </Button>
      <Button color="secondary" onClick={cancelDragAndDrop} variant="outlined">
        Cancel
      </Button>
    </Stack>
  ) : (
    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} sx={{ mt: 1 }}>
      <Button onClick={() => setAddRubricDialogShow(true)} variant="outlined">
        Add New Rubric
      </Button>
      {rubricList && rubricList.length > 1 && (
        <Button
          variant="outlined"
          tooltip="Change the order of questions"
          color="secondary"
          onClick={() => setRankUpdateAvailable(true)}
        >
          Change Order
        </Button>
      )}
    </Stack>
  );
}

RubricHeader.propTypes = {
  rankUpdateAvailable: PropTypes.bool,
  handleUpdateRubricRank: PropTypes.func,
  updateRankLoading: PropTypes.bool,
  cancelDragAndDrop: PropTypes.func,
  setAddRubricDialogShow: PropTypes.func,
  rubricList: PropTypes.array,
  setRankUpdateAvailable: PropTypes.func
};

export default RubricHeader;
