import * as React from 'react';
import { Card } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { Stack, TextField, Button, MenuItem } from '@material-ui/core';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { addSchoolInfo, updateSchoolInfo } from './utils/serviceCalls';
import { setSnackbar } from '../../../actions/alertActions';
import { getCountryList, getStateList } from '../../../actions/registerActions';

const ADD_SCHOOL = 1;
const EDIT_SCHOOL = 2;
const US_CODE = 244;
const phoneRegExp = /^\+?(\d{10,13})$/gm;
const englishChar = /^[-a-zA-Z0-9& ]+$/;

function AddPage(props) {
  const { handleBack, mode, data, dispatch } = props;
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [schema, setSchema] = useState(
    Yup.object().shape({
      main_contact_first_name: Yup.string()
        .min(2, 'Too Short!')
        .max(500, 'Too Long!')
        .matches(englishChar, 'Please enter only English characters')
        .required('First name is required'),
      main_contact_last_name: Yup.string()
        .min(2, 'Too Short!')
        .max(500, 'Too Long!')
        .matches(englishChar, 'Please enter only English characters')
        .required('Last name is required'),
      name: Yup.string()
        .min(2, 'Too Short!')
        .max(500, 'Too Long!')
        .matches(englishChar, 'Please enter only English characters')
        .required('School name is required'),
      main_contact_email: Yup.string()
        .max(500, 'Too Long!')
        .email('Email must be a valid email address')
        .required('Main Contact Email is required'),
      phone_number: Yup.string()
        .max(500, 'Too Long!')
        .matches(phoneRegExp, 'Phone number must be in the following format,+15554443322')
        .required('Phone number is required'),
      zip: Yup.string().max(500, 'Too Long!').required('Zip is required'),
      country_id: Yup.string().required('Country is required'),
      city: Yup.string().max(500, 'Too Long!').required('City is required'),
      street: Yup.string().max(500, 'Too Long!').required('Street is required'),
      state_id: Yup.string().nullable()
    })
  );

  useEffect(() => {
    getCountryList(
      {},
      (data) => {
        setCountryList(data);
      },
      () => {}
    );
    getStateList(
      {},
      (data) => {
        setStateList(data);
      },
      () => {}
    );
  }, []);

  const formik = useFormik({
    initialValues: {
      main_contact_first_name: mode === EDIT_SCHOOL ? data.main_contact_first_name : '',
      main_contact_last_name: mode === EDIT_SCHOOL ? data.main_contact_last_name : '',
      name:
        mode === EDIT_SCHOOL
          ? data.name === null || data.name === undefined
            ? ''
            : data.name
          : '',
      main_contact_email: mode === EDIT_SCHOOL ? data.main_contact_email : '',
      phone_number: mode === EDIT_SCHOOL ? data.phone_number : '',
      address: mode === EDIT_SCHOOL ? data.address : '',
      zip: mode === EDIT_SCHOOL ? data.zip : '',
      country_id: mode === EDIT_SCHOOL ? data.country_id : '',
      state_id: mode === EDIT_SCHOOL ? data.state_id : '',
      city: mode === EDIT_SCHOOL ? data.city : '',
      street: mode === EDIT_SCHOOL ? data.street : '',
      unit_number: mode === EDIT_SCHOOL ? data.unit_number : ''
    },
    validationSchema: schema,
    onSubmit: (values) => {
      if (mode === ADD_SCHOOL) {
        const { address, ...params } = values;
        const updatedParam = {
          ...params,
          main_contact_first_name: values.main_contact_first_name.toUpperCase(),
          main_contact_last_name: values.main_contact_last_name.toUpperCase()
        };
        console.log(address);
        addSchoolInfo(
          updatedParam,
          () => {
            handleBack();
          },
          () => {}
        );
      } else {
        const obj = {
          ...values,
          school_id: data.id,
          main_contact_first_name: values.main_contact_first_name.toUpperCase(),
          main_contact_last_name: values.main_contact_last_name.toUpperCase()
        };
        const { address, ...params } = obj;
        console.log(address);
        updateSchoolInfo(
          params,
          () => {
            dispatch(setSnackbar('success', 'The school info has been updated'));
            handleBack();
          },
          () => {}
        );
      }
    }
  });
  const { errors, touched, handleSubmit, getFieldProps, isSubmitting, setFieldValue, values } =
    formik;

  useEffect(() => {
    if (values.country_id !== US_CODE) {
      setFieldValue('state_id', null);
    }
    setSchema(
      Yup.object().shape({
        main_contact_first_name: Yup.string()
          .min(2, 'Too Short!')
          .max(500, 'Too Long!')
          .matches(englishChar, 'Please enter only English characters')
          .required('First name is required'),
        main_contact_last_name: Yup.string()
          .min(2, 'Too Short!')
          .max(500, 'Too Long!')
          .matches(englishChar, 'Please enter only English characters')
          .required('Last name is required'),
        name: Yup.string()
          .min(2, 'Too Short!')
          .max(500, 'Too Long!')
          .matches(englishChar, 'Please enter only English characters')
          .required('School name is required'),
        main_contact_email: Yup.string()
          .max(500, 'Too Long!')
          .email('Email must be a valid email address')
          .required('Main Contact Email is required'),
        phone_number: Yup.string()
          .max(500, 'Too Long!')
          .matches(phoneRegExp, 'Phone number must be in the following format,+15554443322')
          .required('Phone number is required'),
        zip: Yup.string().max(500, 'Too Long!').required('Zip is required'),
        country_id: Yup.string().required('Country is required'),
        city: Yup.string().max(500, 'Too Long!').required('City is required'),
        street: Yup.string().max(500, 'Too Long!').required('Street is required'),
        state_id:
          values.country_id === US_CODE
            ? Yup.string().required('State is required').nullable()
            : Yup.string().nullable()
      })
    );
  }, [values.country_id]);

  return (
    <Card style={{ padding: 20, marginTop: 10 }}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit} spacing={2}>
          <Stack spacing={3}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                label="School Name"
                {...getFieldProps('name')}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
              <TextField
                inputProps={{ style: { textTransform: 'uppercase' } }}
                fullWidth
                label="Main Contact First Name"
                {...getFieldProps('main_contact_first_name')}
                error={Boolean(touched.main_contact_first_name && errors.main_contact_first_name)}
                helperText={touched.main_contact_first_name && errors.main_contact_first_name}
              />
              <TextField
                inputProps={{ style: { textTransform: 'uppercase' } }}
                fullWidth
                label="Main Contact Last Name"
                {...getFieldProps('main_contact_last_name')}
                error={Boolean(touched.main_contact_last_name && errors.main_contact_last_name)}
                helperText={touched.main_contact_last_name && errors.main_contact_last_name}
              />
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                label="Phone Number"
                {...getFieldProps('phone_number')}
                error={Boolean(touched.phone_number && errors.phone_number)}
                helperText={touched.phone_number && errors.phone_number}
              />
              <TextField
                fullWidth
                type="Email"
                label="Main Contact Email"
                {...getFieldProps('main_contact_email')}
                error={Boolean(touched.main_contact_email && errors.main_contact_email)}
                helperText={touched.main_contact_email && errors.main_contact_email}
              />
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                label="Country"
                select
                {...getFieldProps('country_id')}
                error={Boolean(touched.country_id && errors.country_id)}
                helperText={touched.country_id && errors.country_id}
              >
                {countryList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
              {values.country_id === US_CODE && (
                <TextField
                  fullWidth
                  label="State"
                  select
                  {...getFieldProps('state_id')}
                  error={Boolean(touched.state_id && errors.state_id)}
                  helperText={touched.state_id && errors.state_id}
                >
                  {stateList.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                label="Street"
                {...getFieldProps('street')}
                error={Boolean(touched.street && errors.street)}
                helperText={touched.street && errors.street}
              />
              <TextField
                fullWidth
                label="Unit Number"
                {...getFieldProps('unit_number')}
                error={Boolean(touched.unit_number && errors.unit_number)}
                helperText={touched.unit_number && errors.unit_number}
              />
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                label="City"
                {...getFieldProps('city')}
                error={Boolean(touched.city && errors.city)}
                helperText={touched.city && errors.city}
              />
              <TextField
                fullWidth
                label="Zip Code"
                {...getFieldProps('zip')}
                error={Boolean(touched.zip && errors.zip)}
                helperText={touched.zip && errors.zip}
              />
            </Stack>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="end"
            mb={5}
            style={{ marginTop: 20 }}
            spacing={1}
          >
            <Button type="submit" variant="outlined" loading={isSubmitting}>
              {mode === ADD_SCHOOL ? 'Add' : 'Update'}
            </Button>
            <Button variant="outlined" color="error" onClick={handleBack}>
              Cancel
            </Button>
          </Stack>
        </Form>
      </FormikProvider>
    </Card>
  );
}

AddPage.propTypes = {
  dispatch: PropTypes.func,
  handleBack: PropTypes.func.isRequired,
  data: PropTypes.object,
  mode: PropTypes.number
};

export default connect(() => ({}))(AddPage);
