export default [
  {
    name: 'participant_ids',
    label: 'Participant IDs'
  },
  {
    name: 'participant_comp',
    label: 'Participants',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'participant_names',
    label: 'Participants',
    options: {
      display: false
    }
  },
  {
    name: 'created_at_comp',
    label: 'Created At'
  },
  {
    name: 'download',
    label: 'Download',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  }
];
