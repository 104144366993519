import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import { Box, Card, Link, Container, Typography } from '@material-ui/core';
import React from 'react';
import AuthLayout from '../../layouts/AuthLayout';
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import GeneralUserForm from './GeneralUserForm';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 540,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Register() {
  return (
    <RootStyle title="Register | Genius">
      <AuthLayout>
        Already have an account? &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/main">
          Login
        </Link>
      </AuthLayout>

      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Registration for GENIUS Olympiad Project Application
          </Typography>
          <img alt="register" src="/static/illustrations/illustration_register.png" />
        </SectionStyle>
      </MHidden>

      <Container>
        <ContentStyle>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              You must use a valid email address that you have access to verify and activate your
              account.
            </Typography>
          </Box>

          <GeneralUserForm />

          <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 3 }}>
            By registering, I agree to Genius&nbsp;
            <Link underline="always" sx={{ color: 'text.primary' }} style={{ cursor: 'pointer' }}>
              <a
                target="_blank"
                href="https://geniuscountries.s3.us-east-2.amazonaws.com/GENIUS_Terms_Service.pdf"
                rel="noreferrer"
              >
                Terms of Service
              </a>
            </Link>
            &nbsp;and&nbsp;
            <Link underline="always" sx={{ color: 'text.primary' }} style={{ cursor: 'pointer' }}>
              <a
                target="_blank"
                href="https://geniuscountries.s3.us-east-2.amazonaws.com/GENIUS_Privacy_Policy.pdf"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </Link>
            .
          </Typography>
          <MHidden width="smUp">
            <Typography variant="subtitle2" sx={{ mt: 3, textAlign: 'center' }}>
              Already have an account?&nbsp;
              <Link to="/login" component={RouterLink}>
                Login
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
