import { Navigate } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import Settings from './pages/Admin/Settings';
import Terms from './pages/Admin/Settings/Term';
import Disciplines from './pages/Admin/Settings/Disciplines';
import Category from './pages/Admin/Settings/Category';
import Trip from './pages/Admin/Settings/Trip';
import Fee from './pages/Admin/Settings/Fee';
import AdminPayment from './pages/Admin/Payment';
import ProjectPayment from './pages/Admin/Payment/Project';
import ParticipantPayment from './pages/Admin/Payment/Participant';
import TripPayment from './pages/Admin/Payment/Trip';
import FairOperation from './pages/Admin/FairOperation';
import FairFairOperation from './pages/Admin/FairOperation/Fair';
import RequestFairOperation from './pages/Admin/FairOperation/Request';
import FairAdmin from './pages/Fair/FairAdmin';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import JudgeRegistration from './pages/Register/JudgeRegister/JudgeRegistration';
import FairRegistration from './pages/Register/FairRegistration';
import GeneralUserRegistration from './pages/Register/GeneralUserRegistration';
import ResetForm from './components/authentication/resetPassword/ResetForm';
import NotFound from './pages/Page404';
import MainLayout from './layouts/main';
import FairProfile from './pages/Fair/Profile';
import School from './pages/Application/School';
import Travel from './pages/Application/Travel';
import TripForGeneralUser from './pages/Application/Trip';
import PresentationForGeneralUser from './pages/Application/Presentation';
import Projects from './pages/Application/Projects';
import Profile from './pages/Profile';
import PaymentForProjects from './pages/Payments/CheckoutWidget';
import PaymentForParticipants from './pages/Payments/PaymentForParticipants';
import PaymentForTrips from './pages/Payments/PaymentForTrips';
import ParticipantOperation from './pages/Admin/ParticipantOperation';
import SchoolOperation from './pages/Admin/SchoolOperation';
import ProjectOperation from './pages/Admin/ProjectOperation';
import Participation from './pages/Admin/ParticipantOperation/Participation';
import ParticipationForRegularUser from './pages/Application/Participation';
import ParticipantForRegularUser from './pages/Application/Participation/Participant';
import InvitationLetterForRegularUser from './pages/Application/Participation/InvitationLetter';
import VipForRegularUser from './pages/Application/Participation/Vip';
import InvitationLetterForAdmin from './pages/Admin/ParticipantOperation/InvitationLetter';
import ParticipantReport from './pages/Admin/ParticipantOperation/ParticipantReport';
import VipForAdmin from './pages/Admin/ParticipantOperation/Vip';
import TripForAdmin from './pages/Admin/ParticipantOperation/Trip';
import TravelForAdmin from './pages/Admin/ParticipantOperation/Travel';
import UserManagement from './pages/Admin/UserManagement';
import DataRecovery from './pages/Admin/DataRecovery';
import ReviewOperation from './pages/Admin/ReviewOperation';
// eslint-disable-next-line import/extensions
import Communication from './pages/Admin/Communication/Communication.jsx';
import ReviewRO from './pages/Admin/ReviewOperation/Review';
import ReviewerReportRO from './pages/Admin/ReviewOperation/ReviewerReport';
import InfoPO from './pages/Admin/ProjectOperation/Info';
import ReportPO from './pages/Admin/ProjectOperation/Report';
import RoleUM from './pages/Admin/UserManagement/Roles';
import UserUM from './pages/Admin/UserManagement/Users';
import ReviewAdmin from './pages/Reviewer';
import PerformanceApplicant from './pages/Application/Performance';
import PerformanceOperation from './pages/Admin/PerformanceOperation';
import Home from './pages/Application/Home';
import JudgeOperation from './pages/Admin/JudgeOperation';
import Session from './pages/Admin/JudgeOperation/Session';
import Judge from './pages/Admin/JudgeOperation/Judge';
import Group from './pages/Admin/JudgeOperation/Group';
import Judging from './pages/Admin/JudgeOperation/Judging';
import ScoreKeeper from './pages/ScoreKeeper';
import JudgingForm from './pages/Admin/JudgeOperation/JudgingForm';
import JudgeAdmin from './pages/Judge';
import Robotics from './pages/Admin/JudgeOperation/Robotics';
import JudgingSession from './pages/Application/JudgingSession';
import AwardOperation from './pages/Admin/AwardOperation';
import AwardRO from './pages/Admin/AwardOperation/Award';
import AwardReportRO from './pages/Admin/AwardOperation/Report';
import CertificateRO from './pages/Admin/AwardOperation/Certificate';
import JudgeCertificateAdmin from './pages/Judge/Certificate';
import store from './store';
import { getApplicantRoutes } from './utils/routeUtils/applicant';
import { getAdminRoutes } from './utils/routeUtils/admin';
import { getFairRoutes } from './utils/routeUtils/fair';
import LoginWithLinkView from './pages/LoginWithLinkView';
import GuidelineOperation from './pages/Admin/GuidelineOperation';
import DocumentOperation from './pages/Admin/DocumentOperation';
import SurveyOperation from './pages/Admin/SurveyOperation/Survey';
import SOR from './pages/Admin/SurveyOperation/Report/Report';
import Documents from './pages/Documents';
import PaymentLink from './pages/PaymentLink/PaymentLink';
import PartnerFairOperation from './pages/Admin/PartnerFairOperation';
import ConfigPartnerFairOperation from './pages/Admin/PartnerFairOperation/Config/Config';
import ReportPartnerFairOperation from './pages/Admin/PartnerFairOperation/Report/Report';
import ApplicantPayment from './pages/Application/Payment/Payment';
import Survey from './pages/Survey/Survey';
import CodingOperation from './pages/Admin/CodingOperation';

export function getRoutes(module_access, isProfileOK) {
  let allModules = [];
  module_access.forEach((item) => {
    allModules = [...allModules, ...item.modules];
  });
  // APPLICANT
  const {
    isPerformance,
    isParticipation,
    isHome,
    isDocumentForApplicant,
    isParticipantFeature,
    isInvitationLetterFeature,
    isVipFeature,
    isProject,
    isSchool,
    isTravel,
    isTrip,
    isPresentation,
    isProfile,
    isApplicantJudging,
    isApplicantPayment
  } = getApplicantRoutes(allModules);

  // ADMIN
  const {
    isPayment,
    isParticipantPaymentFeature,
    isProjectPaymentFeature,
    isTripPaymentFeature,
    isSchoolOperation,
    isPerformanceOperation,
    isParticipantOperation,
    isParticipationParticipantOpFeature,
    isInvitationLettersParticipantOpFeature,
    isVipParticipantOpFeature,
    isTripParticipantOpFeature,
    isTravelParticipantOpFeature,
    isFairOperation,
    isFairFairOpFeature,
    isProfileFairOpFeature,
    isRequestFairOpFeature,
    isSettings,
    isFeeSettingsFeature,
    isTripSettingsFeature,
    isCategorySettingsFeature,
    isDisciplineSettingsFeature,
    isTermSettingsFeature,
    isUserManagement,
    isRoleUMFeature,
    isUserUMFeature,
    isProjectOperation,
    isInfoPOFeature,
    isReportPOFeature,
    isDataRecovery,
    isCommunication,
    isReviewOperation,
    isReviewRO,
    isReviewerReportRO,
    isJudgeOperation,
    isSessionJOFeature,
    isJudgeJOFeature,
    isGroupJOFeature,
    isJudgingJOFeature,
    isJudgingFormJOFeature,
    isRoboticsJOFeature,
    isAwardOperation,
    isAwardRO,
    isAwardReportRO,
    isCertificateRO,
    isGuidelineOperation,
    isDocumentOperation,
    isPFO,
    isConfigPFO,
    isReportPFO,
    isSurvey,
    isCodingOperation,
    isSOS,
    isSOR
  } = getAdminRoutes(allModules);

  // FAIR
  const {
    isFairAdmin,
    isProfileFairAdminFeature,
    isRequestFairAdminFeature,
    isDocumentFairAdminFeature
  } = getFairRoutes(allModules);

  // REVIEWER
  const isReviewAdmin = allModules.findIndex((x) => x.module_access_key === 'reviewer');

  // JUDGE
  const isJudge = allModules.findIndex((x) => x.module_access_key === 'judge');
  const isJudgeCertificate =
    isJudge !== -1
      ? allModules[isJudge].features.findIndex((x) => x.feature_access_key === 'certificate')
      : -1;

  // JUDGE
  const isScorekeeper = allModules.findIndex((x) => x.module_access_key === 'scorekeeper');

  if (isProfileOK) {
    store.dispatch({
      type: 'SET_ROUTE',
      payload: {
        isPerformance,
        isHome,
        isParticipation,
        isParticipantFeature,
        isInvitationLetterFeature,
        isVipFeature,
        isProject,
        isSchool,
        isTravel,
        isTrip,
        isPresentation,
        isProfile,
        isPayment,
        isParticipantPaymentFeature,
        isProjectPaymentFeature,
        isTripPaymentFeature,
        isSchoolOperation,
        isParticipantOperation,
        isParticipationParticipantOpFeature,
        isInvitationLettersParticipantOpFeature,
        isVipParticipantOpFeature,
        isTripParticipantOpFeature,
        isTravelParticipantOpFeature,
        isFairOperation,
        isProfileFairOpFeature,
        isFairFairOpFeature,
        isRequestFairOpFeature,
        isSettings,
        isFeeSettingsFeature,
        isTripSettingsFeature,
        isCategorySettingsFeature,
        isDisciplineSettingsFeature,
        isTermSettingsFeature,
        isUserManagement,
        isRoleUMFeature,
        isUserUMFeature,
        isProjectOperation,
        isFairAdmin,
        isDataRecovery,
        isCommunication,
        isReviewOperation,
        isReviewRO,
        isReviewerReportRO,
        isProfileFairAdminFeature,
        isRequestFairAdminFeature,
        isReviewAdmin,
        isInfoPOFeature,
        isReportPOFeature,
        isPerformanceOperation,
        isDocumentFairAdminFeature,
        isDocumentForApplicant,
        isJudgeOperation,
        isSessionJOFeature,
        isJudgeJOFeature,
        isGroupJOFeature,
        isJudgingJOFeature,
        isJudgingFormJOFeature,
        isJudge,
        isRoboticsJOFeature,
        isScorekeeper,
        isApplicantJudging,
        isAwardOperation,
        isAwardRO,
        isAwardReportRO,
        isCertificateRO,
        isJudgeCertificate,
        isGuidelineOperation,
        isDocumentOperation,
        isPFO,
        isConfigPFO,
        isReportPFO,
        isApplicantPayment,
        isSurvey,
        isCodingOperation,
        isSOS,
        isSOR
      }
    });
  }

  return [
    {
      path: '/main',
      element: <MainLayout />,
      children: [{ path: '/', element: <Navigate to="/main" replace /> }]
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/home" replace /> },
        isHome !== -1 && { path: 'home', element: <Home /> },
        isDocumentForApplicant !== -1 && { path: 'documents', element: <Documents /> },
        isSchool !== -1 && { path: 'school', element: <School /> },
        isTravel !== -1 && { path: 'travel', element: <Travel /> },
        isTrip !== -1 && { path: 'trip', element: <TripForGeneralUser /> },
        isPresentation !== -1 && { path: 'presentation', element: <PresentationForGeneralUser /> },
        isPerformance !== -1 && { path: 'performance', element: <PerformanceApplicant /> },
        isApplicantPayment !== -1 && { path: 'payment', element: <ApplicantPayment /> },
        isParticipation !== -1 && {
          path: 'participation',
          element: <ParticipationForRegularUser />
        },
        isParticipantFeature !== -1 && {
          path: 'participation/participant',
          element: <ParticipantForRegularUser />
        },
        isInvitationLetterFeature !== -1 && {
          path: 'participation/invitation_letter',
          element: <InvitationLetterForRegularUser />
        },
        isVipFeature !== -1 && {
          path: 'participation/vip',
          element: <VipForRegularUser />
        },
        isApplicantJudging !== -1 && { path: 'judging_session', element: <JudgingSession /> },
        isProject !== -1 && { path: 'projects', element: <Projects /> },
        { path: 'profile', element: <Profile /> },
        { path: 'payments_for_projects', element: <PaymentForProjects /> },
        { path: 'payments_for_participants', element: <PaymentForParticipants /> },
        { path: 'payments_for_trips', element: <PaymentForTrips /> },
        { path: 'survey', element: <Survey /> },
        { path: '*', element: <Navigate to="/main" /> }
      ]
    },
    {
      path: '/admin',
      element: <DashboardLayout />,
      children: [
        { path: '/', element: <Navigate to="/admin/school_operation" replace /> },
        isSettings !== -1 && { path: 'settings', element: <Settings /> },
        isSchoolOperation !== -1 && { path: 'school_operation', element: <SchoolOperation /> },
        isPerformanceOperation !== -1 && {
          path: 'performance_operation',
          element: <PerformanceOperation />
        },
        isProjectOperation !== -1 && { path: 'project_operation', element: <ProjectOperation /> },
        isInfoPOFeature !== -1 && { path: 'project_operation/info', element: <InfoPO /> },
        isReportPOFeature !== -1 && {
          path: 'project_operation/report',
          element: <ReportPO />
        },

        isTermSettingsFeature !== -1 && { path: 'settings/terms', element: <Terms /> },
        isDisciplineSettingsFeature !== -1 && {
          path: 'settings/discipline',
          element: <Disciplines />
        },
        isCategorySettingsFeature !== -1 && { path: 'settings/category', element: <Category /> },
        isTripSettingsFeature !== -1 && { path: 'settings/trip', element: <Trip /> },
        isFeeSettingsFeature !== -1 && { path: 'settings/fee', element: <Fee /> },
        isPayment !== -1 && { path: 'payment', element: <AdminPayment /> },
        isSurvey !== -1 && { path: 'survey_operation', element: <SurveyOperation /> },
        isCodingOperation !== -1 && { path: 'coding_operation', element: <CodingOperation /> },
        isSOS !== -1 && { path: 'survey_operation/survey', element: <SurveyOperation /> },
        isSOR !== -1 && { path: 'survey_operation/report', element: <SOR /> },
        isProjectPaymentFeature !== -1 && {
          path: 'payment/projects',
          element: <ProjectPayment />
        },
        isParticipantPaymentFeature !== -1 && {
          path: 'payment/participants',
          element: <ParticipantPayment />
        },
        isTripPaymentFeature !== -1 && {
          path: 'payment/trip',
          element: <TripPayment />
        },
        isJudgeOperation !== -1 && {
          path: 'judge_operation',
          element: <JudgeOperation />
        },
        isSessionJOFeature !== -1 && {
          path: 'judge_operation/session',
          element: <Session />
        },
        isJudgeJOFeature !== -1 && {
          path: 'judge_operation/judge',
          element: <Judge />
        },
        isGroupJOFeature !== -1 && {
          path: 'judge_operation/group',
          element: <Group />
        },
        isJudgingJOFeature !== -1 && {
          path: 'judge_operation/judging',
          element: <Judging />
        },
        isJudgingFormJOFeature !== -1 && {
          path: 'judge_operation/form',
          element: <JudgingForm />
        },
        isRoboticsJOFeature !== -1 && {
          path: 'judge_operation/robotics_competition',
          element: <Robotics />
        },
        isJudge !== -1 && {
          path: 'judge',
          element: <JudgeAdmin />
        },
        isJudgeCertificate !== -1 && {
          path: 'certificate',
          element: <JudgeCertificateAdmin />
        },
        isScorekeeper !== -1 && {
          path: 'scorekeeper',
          element: <ScoreKeeper />
        },
        // TODO control
        isUserManagement !== -1 && { path: 'fair_operation', element: <FairOperation /> },
        isFairOperation !== -1 && { path: 'fair_operation/fair', element: <FairFairOperation /> },
        isRequestFairOpFeature !== -1 && {
          path: 'fair_operation/request',
          element: <RequestFairOperation />
        },
        isPFO !== -1 && { path: 'partner_fair_operation', element: <PartnerFairOperation /> },
        isConfigPFO !== -1 && {
          path: 'partner_fair_operation/config',
          element: <ConfigPartnerFairOperation />
        },
        isReportPFO !== -1 && {
          path: 'partner_fair_operation/report',
          element: <ReportPartnerFairOperation />
        },
        isUserManagement !== -1 && { path: 'user_management', element: <UserManagement /> },
        isRoleUMFeature !== -1 && { path: 'user_management/role', element: <RoleUM /> },
        isUserUMFeature !== -1 && { path: 'user_management/user', element: <UserUM /> },
        isParticipantOperation !== -1 && {
          path: 'participant_operation',
          element: <ParticipantOperation />
        },
        isParticipationParticipantOpFeature !== -1 && {
          path: 'participant_operation/participation',
          element: <Participation />
        },
        {
          path: 'participant_operation/participant_report',
          element: <ParticipantReport />
        },
        isInvitationLettersParticipantOpFeature !== -1 && {
          path: 'participant_operation/invitation_letter',
          element: <InvitationLetterForAdmin />
        },
        isVipParticipantOpFeature !== -1 && {
          path: 'participant_operation/vip',
          element: <VipForAdmin />
        },
        isTripParticipantOpFeature !== -1 && {
          path: 'participant_operation/trip',
          element: <TripForAdmin />
        },
        isTravelParticipantOpFeature !== -1 && {
          path: 'participant_operation/travel',
          element: <TravelForAdmin />
        },
        isReviewOperation !== -1 && {
          path: 'review_operation',
          element: <ReviewOperation />
        },
        isReviewRO !== -1 && {
          path: 'review_operation/review',
          element: <ReviewRO />
        },
        isReviewerReportRO !== -1 && {
          path: 'review_operation/report',
          element: <ReviewerReportRO />
        },
        isAwardOperation !== -1 && {
          path: 'award_operation',
          element: <AwardOperation />
        },
        isGuidelineOperation !== -1 && {
          path: 'guideline_operation',
          element: <GuidelineOperation />
        },
        isDocumentOperation !== -1 && {
          path: 'document_operation',
          element: <DocumentOperation />
        },
        isAwardRO !== -1 && {
          path: 'award_operation/award',
          element: <AwardRO />
        },
        isAwardReportRO !== -1 && {
          path: 'award_operation/report',
          element: <AwardReportRO />
        },
        isCertificateRO !== -1 && {
          path: 'award_operation/certificate_template',
          element: <CertificateRO />
        },
        isDataRecovery !== -1 && {
          path: 'data_recovery',
          element: <DataRecovery />
        },
        isCommunication !== -1 && {
          path: 'communication',
          element: <Communication />
        },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: '/fair',
      element: <DashboardLayout />,
      children: [
        { path: '/', element: <Navigate to="/fair/fair_admin" replace /> },
        isRequestFairAdminFeature !== -1 && { path: 'fair_admin', element: <FairAdmin /> },
        isProfileFairAdminFeature !== -1 && { path: 'fair_profile', element: <FairProfile /> },
        isDocumentFairAdminFeature !== -1 && { path: 'documents', element: <Documents /> },
        { path: '*', element: <Navigate to="/main" /> }
      ]
    },
    {
      path: '/reviewer',
      element: <DashboardLayout />,
      children: [
        { path: '/', element: <Navigate to="/reviewer/review_admin" replace /> },
        isReviewAdmin !== -1 && { path: 'review_admin', element: <ReviewAdmin /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: '/project_reviewer',
      element: <DashboardLayout />,
      children: [
        { path: '/', element: <Navigate to="/admin/review_operation" replace /> },
        isReviewOperation !== -1 && {
          path: 'review_operation',
          element: <ReviewOperation />
        },
        isReviewRO !== -1 && {
          path: 'review_operation/review',
          element: <ReviewRO />
        },
        isReviewerReportRO !== -1 && {
          path: 'review_operation/report',
          element: <ReviewerReportRO />
        },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'judge_registration', element: <JudgeRegistration /> },
        { path: 'fair_registration', element: <FairRegistration /> },
        { path: 'general_user_registration', element: <GeneralUserRegistration /> },
        { path: 'login_with_link:user_id', element: <LoginWithLinkView /> },
        { path: 'reset', element: <ResetForm /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/main" /> },
        { path: '*', element: <Navigate to="/404" /> },
        { path: 'payment', element: <PaymentLink /> },
        { path: 'survey', element: <Survey /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ];
}
