import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Stack, TextField } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { handleResetPassword } from '../../../actions/loginActions';
import { setSnackbarForMain } from '../../../actions/alertActions';
import CustomSnackbar from '../../CustomComponents/CustomSnackbar';

function ResetForm(props) {
  const { dispatch } = props;
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState('');

  const Schema = Yup.object().shape({
    email: Yup.string()
      .max(500, 'Too Long!')
      .email('Email must be a valid email address')
      .required('Email is required')
  });

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: Schema,
    onSubmit: (values, { setSubmitting }) => {
      handleResetPassword(
        values,
        () => {
          setSubmitting(false);
          dispatch(
            setSnackbarForMain(
              'success',
              'Your new password has been sent to your e-mail address, please check'
            )
          );
          navigate('/', { replace: true });
        },
        (error) => {
          if (error.is_visible) {
            setIsError(true);
            setErrorText(error.go_error_message);
          }
          setSubmitting(false);
        }
      );
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Grid container style={{ marginTop: 100, padding: 10 }}>
      <Grid item xs={12} md={4} />
      <Grid item xs={12} md={4}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                autoComplete="username"
                type="email"
                label="Email address"
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
            </Stack>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              style={{ marginTop: 20 }}
            >
              Reset
            </LoadingButton>
          </Form>
        </FormikProvider>
      </Grid>
      {isError && (
        <CustomSnackbar
          settings={{ type: 'error', message: errorText, onHide: () => setIsError(false) }}
        />
      )}
    </Grid>
  );
}

ResetForm.propTypes = {
  dispatch: PropTypes.func.isRequired
};

export default connect(() => ({}))(ResetForm);
