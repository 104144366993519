import { Grid, IconButton, MenuItem, Stack, TextField } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, DialogActions, DialogContent, Card } from '@mui/material';
import { confirmParticipant, getParticipantStudent, getParticipantType } from '../serviceCalls';
import MuiTable from '../../../../components/CustomComponents/MuiTable';
import { setSnackbar } from '../../../../actions/alertActions';
import CustomDialog from '../../../../components/CustomComponents/CustomDialog';
import SupervisorGuestMode from './SupervisorGuestMode';
import getHeaders from './utils/headerVariablesForConfirmation';

const STUDENT_MODE = 1;
const OTHER_MODE = 2;

function AddPage(props) {
  const { dispatch, handleBack } = props;
  const [participantTypes, setParticipantTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [mode, setMode] = useState(0);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [headers, setHeaders] = useState([]);

  useEffect(() => {
    const _headers = getHeaders(tableData);
    setHeaders(_headers);
    fetchStudentList();
    getParticipantType(
      {},
      (data) => {
        setParticipantTypes(data);
      },
      () => {}
    );
  }, []);
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    selectedType ? (selectedType === 1 ? setMode(STUDENT_MODE) : setMode(OTHER_MODE)) : setMode(0);
  }, [selectedType]);
  function fetchStudentList() {
    getParticipantStudent(
      {},
      (data) => {
        data.forEach((item) => {
          item.project_titles_filter = item.project_titles;
          item.project_ids_filter = item.project_ids;
          item.name = `${item.first_name} ${item.last_name}`;
          item.date_of_birth_comp = moment(item.date_of_birth).format('YYYY-MM-DD');
          item.is_confirmed_comp = item.is_confirmed ? (
            <IconButton color="success" style={{ cursor: 'default' }}>
              <Icon icon="akar-icons:circle-check" width={24} height={24} />
            </IconButton>
          ) : (
            <Tooltip title="Confirm Student">
              <IconButton color="error" onClick={() => handleConfirmStudent(item.id)}>
                <Icon color="error" icon="akar-icons:circle-x" width={24} height={24} />
              </IconButton>
            </Tooltip>
          );
        });
        const _headers = getHeaders(data);
        setHeaders(_headers);
        setTableData(data);
      },
      () => {}
    );
  }
  function handleConfirmStudent() {
    const idList = [];
    selectedStudents.forEach((item) => {
      idList.push(tableData[item].id);
    });
    confirmParticipant({ participants: idList }, () => {
      handleBack();
      dispatch(setSnackbar('success', 'The student(s) has been confirmed'));
    });
  }
  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedStudents(rowsSelected);
  };

  return (
    <Card style={{ padding: 20 }}>
      <Stack spacing={3}>
        <Grid item xs={4} direction={{ xs: 'column', sm: 'row' }}>
          <TextField
            fullWidth
            select
            label="Type"
            onChange={(e) => {
              setSelectedType(e.target.value);
            }}
          >
            {participantTypes.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        {mode === OTHER_MODE && (
          <SupervisorGuestMode
            participant_type_id={selectedType}
            handleClose={() => setMode(0)}
            handleRefresh={handleBack}
          />
        )}
        <CustomDialog
          maxWidth="lg"
          onClose={() => setMode(0)}
          open={mode === STUDENT_MODE}
          title="Confirmation"
        >
          <DialogContent>
            <MuiTable
              tableColumns={headers}
              tableData={tableData}
              customOption={{
                selectableRows: 'multiple',
                onRowSelectionChange,
                rowsSelected: selectedStudents
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              disabled={selectedStudents.length === 0}
              onClick={handleConfirmStudent}
            >
              Add
            </Button>
            <Button variant="outlined" color="error" onClick={() => setMode(0)}>
              Cancel
            </Button>
          </DialogActions>
        </CustomDialog>
      </Stack>
    </Card>
  );
}

AddPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired
};

export default connect(() => ({}))(AddPage);
