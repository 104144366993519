import { AppBar, Container, Toolbar, Typography, Stack } from '@material-ui/core';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';

const iconStyle = {
  cursor: 'pointer'
};

export default function Footer() {
  return (
    <AppBar position="static" color="primary">
      <Container maxWidth="md">
        <Toolbar>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            style={{ width: '100%', justifyContent: 'space-between' }}
            spacing={2}
          >
            <Typography variant="body1" color="inherit">
              Copyright © Genius Olympiad {1900 + new Date().getYear()}
            </Typography>
            <Stack direction={{ xs: 'row', sm: 'row' }} spacing={1}>
              <TwitterIcon
                style={iconStyle}
                onClick={() => window.open('https://twitter.com/GeniusOlympiad')}
              />
              <FacebookIcon
                style={iconStyle}
                onClick={() => window.open('https://www.facebook.com/GeniusOlympiad')}
              />
              <InstagramIcon
                style={iconStyle}
                onClick={() => window.open('https://www.instagram.com/geniusolympiad')}
              />
              <YouTubeIcon
                style={iconStyle}
                onClick={() => window.open('https://www.youtube.com/user/GeniusOlympiad')}
              />
            </Stack>
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
