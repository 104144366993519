const HOST = process.env.REACT_APP_REST_API_HOST;

const START_COMPETITION = {
  method: 'POST',
  path: `${HOST}go_admin/scorekeeper/competition`
};
const ADD_RUBRIC_COUNTS = {
  method: 'POST',
  path: `${HOST}go_admin/scorekeeper/competition/rubric`
};

module.exports = {
  START_COMPETITION,
  ADD_RUBRIC_COUNTS
};
