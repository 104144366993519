import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import { Box, Container, Typography } from '@material-ui/core';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { getPaymentDetail } from './_apis/serviceCalls';
import ProjectCheckoutComponent from './ProjectCheckoutComponent';
import ParticipantCheckoutComponent from './ParticipantCheckoutComponent';
import { MotionContainer, varBounceIn } from '../../components/animate';
import Page from '../../components/Page';

const PROJECT_MODE = 1;
const PARTICIPANT_MODE = 2;

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(10)
}));

function PaymentLink({ dispatch }) {
  const [paymentData, setPaymentData] = useState(null);
  const [error, setError] = useState(null);
  const [mode, setMode] = useState(null);
  const { search } = useLocation();
  const id = new URLSearchParams(search).get('id');
  const token = new URLSearchParams(search).get('token');

  useEffect(() => {
    const params = { id, token };
    getPaymentDetail(
      params,
      (data) => {
        data.balance = data.amount;
        data.paramId = id;
        data.token = token;
        if (data?.project_id) {
          data.id = data.project_id;
          dispatch({ type: 'ADD_FEE', payload: data.amount });
          dispatch({ type: 'ADD_ITEM_CART_FROM_PROJECTS', payload: [data] });
          setMode(PROJECT_MODE);
        } else if (data?.participant_id) {
          data.id = data.participant_id;
          data.name = `${data.first_name} ${data.last_name}`;
          dispatch({ type: 'ADD_FEE', payload: data.amount });
          dispatch({ type: 'ADD_ITEM_CART_FROM_PARTICIPANTS', payload: [data] });
          setMode(PARTICIPANT_MODE);
        }
        setPaymentData(data);
      },
      (err) => {
        setError(err.message);
      }
    );
  }, []);

  return (
    <RootStyle title="Payment | Genius">
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ margin: 'auto', textAlign: 'center' }}>
            <motion.div variants={varBounceIn}>
              {paymentData ? (
                mode === PROJECT_MODE ? (
                  <ProjectCheckoutComponent />
                ) : (
                  <ParticipantCheckoutComponent />
                )
              ) : error ? (
                <Typography variant="h3" paragraph>
                  {error}
                </Typography>
              ) : (
                <p>Loading...</p>
              )}
            </motion.div>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
}

PaymentLink.propTypes = {
  dispatch: PropTypes.func.isRequired
};

export default connect(() => ({}))(PaymentLink);
