import React, { useEffect, useState } from 'react';
import { DialogContent, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import LandingPage from './LandingPage';
import CustomDialog from '../../../../components/CustomComponents/CustomDialog';
import CopyDialog from './Dialogs/CopyDialog';
import ViewDialog from './Dialogs/ViewDialog';
import { setConfirmationAlert, setLoading, setSnackbar } from '../../../../actions/alertActions';
import { deleteSurvey, getSurveyList } from '../_apis/serviceCalls';
import AddUpdateStepper from './Components/AddUpdateStepper';
import ProcessInfoBox from '../../../../components/CustomComponents/CustomCard/ProcessInfoBox';
import { dataMaker } from './_utils/dataMaker';
import Page from '../../../../components/Page';

const LANDING_MODE = 0;
const ADD_MODE = 1;
const UPDATE_MODE = 2;

function Index(props) {
  const { dispatch, active_term } = props;
  const [mode, setMode] = useState(LANDING_MODE);
  const [editData, setEditData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [copyDialog, setCopyDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);
  const [guidelines, setGuidelines] = useState([]);
  const [term, setTerm] = useState(active_term);

  useEffect(() => {
    fetchData();
  }, [term]);

  function fetchData() {
    dispatch(setLoading(1, 'Loading...'));
    getSurveyList({ term_id: term }, (data) => {
      setGuidelines(data.guidelines);
      const list = dataMaker(
        data.data,
        handleCopySurvey,
        handleViewSurvey,
        handleEditSurvey,
        handleDeleteSurvey
      );
      setTableData(list);
    }).then(() => dispatch(setLoading(0, '')));
  }

  function handleEditSurvey(survey) {
    setEditData(survey);
    setMode(UPDATE_MODE);
  }

  function handleViewSurvey(survey) {
    setEditData(survey);
    setViewDialog(true);
  }

  function handleCopySurvey(survey) {
    setEditData(survey);
    setCopyDialog(true);
  }

  function handleRefresh() {
    setMode(LANDING_MODE);
    setEditData(null);
    setCopyDialog(false);
    fetchData();
  }

  function handleDeleteSurvey(survey_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'This action will delete survey permanently',
        () => {
          deleteSurvey(
            { survey_id, force_delete: 0 },
            () => {
              handleRefresh();
              dispatch(setSnackbar('success', 'The survey has been deleted'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            },
            (error) => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
              if (error.http_status_code === 409) {
                forceDelete(survey_id, error.message);
              }
            }
          );
        }
      )
    );
  }

  function forceDelete(survey_id, message) {
    dispatch(
      setConfirmationAlert('Are you sure?', 'YES, FORCE DELETE', message, () => {
        deleteSurvey(
          { survey_id, force_delete: 1 },
          () => {
            fetchData();
            dispatch(setSnackbar('success', 'The survey has been deleted'));
            dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
          },
          () => {
            dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
          }
        );
      })
    );
  }

  return (
    <Page title="Survey Operation | Genius">
      <Stack spacing={2}>
        {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
        <LandingPage
          tableData={tableData}
          addSurvey={() => setMode(ADD_MODE)}
          term={term}
          setTerm={setTerm}
        />
        <CustomDialog
          onClose={() => setMode(null)}
          open={mode === ADD_MODE || mode === UPDATE_MODE}
          title={mode === ADD_MODE ? 'Add Survey' : 'Update Survey'}
          maxWidth="lg"
          fullWidth
        >
          <DialogContent>
            <AddUpdateStepper handleFinish={handleRefresh} data={editData} mode={mode} />
          </DialogContent>
        </CustomDialog>
        <CustomDialog onClose={() => setCopyDialog(false)} open={copyDialog} title="Copy Survey">
          <DialogContent>
            <CopyDialog
              data={editData}
              handleClose={() => setCopyDialog(false)}
              refreshData={handleRefresh}
            />
          </DialogContent>
        </CustomDialog>
        <CustomDialog
          maxWidth="lg"
          fullWidth
          onClose={() => setViewDialog(false)}
          open={viewDialog}
          title="View Survey"
        >
          <DialogContent>
            <ViewDialog data={editData} handleClose={() => setViewDialog(false)} />
          </DialogContent>
        </CustomDialog>
      </Stack>
    </Page>
  );
}

Index.propTypes = {
  dispatch: PropTypes.func,
  active_term: PropTypes.number
};

export default connect((store) => ({
  active_term: store.loginReducer.active_term
}))(Index);
