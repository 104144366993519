import React, { useEffect, useState } from 'react';
import { IconButton, MenuItem, Stack, TextField } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { Button, DialogContent, Tooltip } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Email from '@mui/icons-material/Email';
import CircularProgress from '@mui/material/CircularProgress';
import ReactHtmlParser from 'react-html-parser';
import MuiTable from '../../../../components/CustomComponents/MuiTable';
import { getHeaders } from './utils/headerVariables';
import {
  addCertificateForJudge,
  deleteJudge,
  getJudgeList,
  updateJudgeStatus,
  downloadCertificate
} from '../serviceCalls';
import CustomDialog from '../../../../components/CustomComponents/CustomDialog';
import CustomMail from '../../../../components/CustomComponents/CustomMail';
import { CUSTOM_MAIL_TYPE } from '../../../../api/RestVariables/staticVariables';
import Page from '../../../../components/Page';
import { setConfirmationAlert, setSnackbar } from '../../../../actions/alertActions';
import JudgeForm from './JudgeForm';
import ProcessInfoBox from '../../../../components/CustomComponents/CustomCard/ProcessInfoBox';
import SummaryTable from './SummaryTable';
import { getSummaryTableData } from './utils/_utils';

function Index(props) {
  const { terms, active_term, isCommunication, dispatch } = props;
  const [summaryData, setSummaryData] = useState([]);
  const [term, setTerm] = useState(active_term);
  const [tableData, setTableData] = useState([]);
  const [mailDialogShow, setMailDialogShow] = useState(false);
  const [editDialogShow, setEditDialogShow] = useState(false);
  const [addMail, setAddMail] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedJudge, setSelectedJudge] = useState([]);
  const [idList, setIdList] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [addCertificate, setAddCertificate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [guidelines, setGuidelines] = useState([]);

  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedItems(rowsSelected);
    const arr = [];
    rowsSelected.forEach((item) => {
      arr.push(tableData[item].judge_user_id);
    });
    setIdList(arr);
  };

  useEffect(() => {
    fetchData();
  }, [term]);
  function fetchData() {
    getJudgeList(
      { term_id: term },
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        const _headers = getHeaders(list);
        const _summaryData = getSummaryTableData(list);
        setSummaryData(_summaryData);
        setHeaders(_headers.list);
        list.forEach((item) => {
          item.approve =
            item.judge_approval_status_id === 1 ? (
              <IconButton color="success" disabled style={{ color: '#54D62C' }}>
                <Icon color="success" icon="akar-icons:circle-check" />
              </IconButton>
            ) : (
              <Tooltip title="Approve">
                <IconButton color="secondary" onClick={() => handleApprove(item.judge_id)}>
                  <Icon icon="dashicons:update" />
                </IconButton>
              </Tooltip>
            );
          item.deny =
            item.judge_approval_status_id === 2 ? (
              <IconButton color="error" disabled style={{ color: '#FF4842' }}>
                <Icon icon="akar-icons:circle-x" />
              </IconButton>
            ) : (
              <Tooltip title="Deny">
                <IconButton color="secondary" onClick={() => handleDeny(item.judge_id)}>
                  <Icon icon="dashicons:update" />
                </IconButton>
              </Tooltip>
            );
          item.edit = (
            <IconButton color="secondary" onClick={() => handleEditJudge(item)}>
              <Icon icon="ci:edit" />
            </IconButton>
          );
          item.medal = (
            <IconButton
              color="secondary"
              disabled={!item.judge_certificate_id}
              onClick={() => handleDownload(item.judge_certificate_id)}
            >
              <Icon icon="akar-icons:cloud-download" width={24} height={24} />
            </IconButton>
          );
          item.delete = (
            <IconButton color="secondary" onClick={() => handleDeleteJudge(item.judge_id)}>
              <Icon icon="fluent:delete-48-filled" />
            </IconButton>
          );
        });
        setTableData(list);
      },
      () => {}
    );
  }
  function handleDownload(certificate_id) {
    setLoading(true);
    downloadCertificate(
      { certificate_id },
      () => {
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  }
  function handleDeleteJudge(judge_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'This action will delete the judge',
        () => {
          deleteJudge(
            { judge_id, force_delete: 0 },
            () => {
              fetchData();
              dispatch(setSnackbar('success', 'The judge has been deleted'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            },
            (error) => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
              if (error.http_status_code === 409) {
                forceDelete(judge_id, error.message);
              }
            }
          );
        }
      )
    );
  }
  function forceDelete(judge_id, message) {
    dispatch(
      setConfirmationAlert('Are you sure?', 'YES, FORCE DELETE', message, () => {
        deleteJudge(
          { judge_id, force_delete: 1 },
          () => {
            fetchData();
            dispatch(setSnackbar('success', 'The judge has been deleted'));
            dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
          },
          () => {
            dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
          }
        );
      })
    );
  }
  function handleEditJudge(judge) {
    setSelectedJudge(judge);
    setEditDialogShow(true);
  }
  function handleApprove(judge_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, APPROVE',
        'This action will approve the request',
        () => {
          updateJudgeStatus(
            { judge_id, judge_approval_status_id: 1 },
            () => {
              fetchData();
              dispatch(setSnackbar('success', 'The judge status has been approved'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }
  function handleDeny(judge_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DENY',
        'This action will deny the request',
        () => {
          updateJudgeStatus(
            { judge_id, judge_approval_status_id: 2 },
            () => {
              fetchData();
              dispatch(setSnackbar('success', 'The judge status has been denied'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }
  function handleRefresh() {
    setMailDialogShow(false);
    setEditDialogShow(false);
    setSelectedJudge(null);
    setAddMail(false);
    setSelectedItems([]);
    setAddCertificate(false);
    fetchData();
  }
  function TermSelect() {
    return (
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 2 }}>
        <TextField select label="Terms" value={term} onChange={(e) => setTerm(e.target.value)}>
          {terms.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
        {!addCertificate && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button color="secondary" variant="outlined" onClick={() => setAddCertificate(true)}>
              Add Certificate
            </Button>
          </div>
        )}
        {loading && (
          <Stack
            spacing={1}
            direction={{ xs: 'column', sm: 'row' }}
            sx={{ mr: 1, display: 'flex', alignItems: 'center' }}
          >
            <p style={{ fontWeight: 'bold' }}>Downloading...</p>
            <CircularProgress size={20} />
          </Stack>
        )}
        {addCertificate && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              color="secondary"
              variant="outlined"
              disabled={startButtonDisabled}
              onClick={handleAddCertificate}
            >
              Next
            </Button>
            <Button
              variant="outlined"
              color="error"
              style={{ marginLeft: 10 }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        )}
        {addMail && (
          <div>
            <Button
              style={{ marginRight: 5 }}
              disabled={selectedItems.length === 0}
              variant="outlined"
              onClick={() => setMailDialogShow(true)}
            >
              Next
            </Button>
            <Button color="error" variant="outlined" onClick={() => setAddMail(false)}>
              Cancel
            </Button>
          </div>
        )}
      </Stack>
    );
  }
  const handleCancel = () => {
    setSelectedItems([]);
    setAddCertificate(false);
  };

  const startButtonDisabled = selectedItems.length === 0;

  function handleAddCertificate() {
    const arr = [];
    selectedItems.forEach((item) => {
      arr.push(tableData[item].judge_id);
    });
    const param = {
      judge_ids: arr
    };
    addCertificateForJudge(
      param,
      () => {
        dispatch(
          setSnackbar(
            'success',
            'The certificate operation is in progress. After operation finished, you will receive an email notification.'
          )
        );
        handleRefresh();
      },
      () => {}
    );
  }

  return (
    <Page title="Judge Operation | Genius">
      <Stack spacing={2}>
        {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
        <ProcessInfoBox Comp={<SummaryTable data={summaryData} />} title="Summary" />
        <MuiTable
          tableData={tableData}
          tableColumns={headers}
          title={<TermSelect />}
          customOption={{
            selectableRows: addMail || addCertificate ? 'multiple' : 'none',
            onRowSelectionChange,
            rowsSelected: selectedItems,
            customToolbar: () =>
              isCommunication !== -1 && (
                <Tooltip title="Send Email">
                  <IconButton onClick={() => setAddMail(true)}>
                    <Email />
                  </IconButton>
                </Tooltip>
              )
          }}
        />
        <CustomDialog
          onClose={() => setMailDialogShow(false)}
          open={mailDialogShow}
          maxWidth="lg"
          title="Send Mail"
          fullWidth
          disableEnforceFocus
        >
          <DialogContent>
            <CustomMail
              handleRefresh={handleRefresh}
              mailType={CUSTOM_MAIL_TYPE}
              idList={idList}
              handleClose={() => setMailDialogShow(false)}
            />
          </DialogContent>
        </CustomDialog>
        <CustomDialog
          onClose={() => setEditDialogShow(false)}
          open={editDialogShow}
          maxWidth="md"
          title="Update Judge"
          fullWidth
        >
          <DialogContent>
            <JudgeForm
              handleRefresh={handleRefresh}
              data={selectedJudge}
              handleClose={() => setEditDialogShow(false)}
            />
          </DialogContent>
        </CustomDialog>
      </Stack>
    </Page>
  );
}

Index.propTypes = {
  terms: PropTypes.array,
  active_term: PropTypes.number,
  isCommunication: PropTypes.number,
  dispatch: PropTypes.func
};

export default connect((store) => ({
  terms: store.loginReducer.terms,
  active_term: store.loginReducer.active_term,
  isCommunication: store.routeReducer.isCommunication
}))(Index);
