import { fetchApi } from '../api/fetchApi';
import store from '../store';

const auth = require('../api/RestVariables/Shield/auth');
const shared = require('../api/RestVariables/Shield/shared');
const common = require('../api/RestVariables/Admin/common');

const FAIR_ROLE = 3;
const JUDGE_ROLE = 5;

export function handleLogin(params, scb, ecb) {
  const { email } = params;
  return async (dispatch) => {
    try {
      const url = auth.LOGIN;
      await fetchApi(
        url,
        params,
        (responseData) => {
          console.log(responseData, 'responseData');
          const active_role = responseData.user_roles.find(
            (x) => x.role_id === responseData.user_role
          );
          const isProfileOK =
            Object.keys(responseData.user_profile).length !== 0 ||
            responseData.user_role === FAIR_ROLE ||
            responseData.user_role === JUDGE_ROLE;
          dispatch({
            type: 'SET_LOGGED_IN',
            payload: {
              active_role,
              profile: {
                name: responseData.name,
                email
              },
              user_role: responseData.user_role,
              user_roles: responseData.user_roles,
              module_access: responseData.module_access,
              user_profile: responseData.user_profile,
              isProfileOK
            }
          });
          dispatch({
            type: 'SET_ACC_TOKEN',
            payload: {
              access_token: responseData.access_token
            }
          });
          scb(responseData);
        },
        (error, message) => {
          ecb(error, message);
        }
      );
    } catch (error) {
      console.log(error);
      ecb('Login Failed!');
    }
  };
}
export function handleLoginWithLink(params, scb, ecb) {
  return async (dispatch) => {
    try {
      const url = auth.LOGIN_WITH_LINK;
      await fetchApi(
        url,
        params,
        (responseData) => {
          console.log(responseData, 'responseData');
          const active_role = responseData.user_roles.find(
            (x) => x.role_id === responseData.user_role
          );
          const isProfileOK =
            Object.keys(responseData.user_profile).length !== 0 ||
            responseData.user_role === FAIR_ROLE ||
            responseData.user_role === JUDGE_ROLE;
          dispatch({
            type: 'SET_LOGGED_IN',
            payload: {
              active_role,
              profile: {
                name: responseData.name,
                email: responseData.user_profile.email
              },
              user_role: responseData.user_role,
              user_roles: responseData.user_roles,
              module_access: responseData.module_access,
              user_profile: responseData.user_profile,
              isProfileOK
            }
          });
          dispatch({
            type: 'SET_ACC_TOKEN',
            payload: {
              access_token: responseData.access_token
            }
          });
          scb(responseData);
        },
        (error, message) => {
          ecb(error, message);
        }
      );
    } catch (error) {
      console.log(error);
      ecb('Login Failed!');
    }
  };
}

export function loginWithAnotherUser(params, scb, ecb) {
  return async (dispatch) => {
    try {
      const url = auth.LOGIN_WITH_ANOTHER_USER;
      fetchApi(
        url,
        params,
        (responseData) => {
          console.log(responseData, 'responseData');
          const active_role = responseData.user_roles.find(
            (x) => x.role_id === responseData.user_role
          );
          const isProfileOK =
            Object.keys(responseData.user_profile).length !== 0 ||
            responseData.user_role === FAIR_ROLE ||
            responseData.user_role === JUDGE_ROLE;
          dispatch({
            type: 'SET_LOGGED_IN',
            payload: {
              active_role,
              profile: {
                name: responseData.name,
                email: responseData.user_profile.email
              },
              user_role: responseData.user_role,
              user_roles: responseData.user_roles,
              module_access: responseData.module_access,
              user_profile: responseData.user_profile,
              isProfileOK
            }
          });
          dispatch({
            type: 'SET_ACC_TOKEN',
            payload: {
              access_token: responseData.access_token
            }
          });
          scb(responseData);
        },
        (error, message) => {
          ecb(error, message);
        }
      );
    } catch (error) {
      console.log(error);
      ecb('Login Failed!');
    }
  };
}
export function onLogout(scb, ecb) {
  return async (dispatch) => {
    try {
      const url = auth.LOGOUT;
      fetchApi(
        url,
        {},
        () => {
          dispatch({ type: 'UNSET_LOGGED_IN' });
          dispatch({ type: 'CLEAR_ACC_TOKEN' });
          scb();
        },
        (error) => {
          console.log('LOG_OUT', error);
          ecb(error);
        },
        dispatch
      );
    } catch (error) {
      console.log(error);
      ecb(error);
    }
  };
}
export function checkLogin() {
  console.log('render_refresh');
  store.dispatch(refreshToken());
}
export function refreshToken(scb, scbWithData) {
  return async (dispatch) => {
    try {
      const url = auth.REFRESH_TOKEN;
      await fetchApi(
        url,
        {},
        (responseData) => {
          const active_role = responseData.user_roles.find(
            (x) => x.role_id === responseData.user_role
          );
          const isProfileOK =
            Object.keys(responseData.user_profile).length !== 0 ||
            responseData.user_role === FAIR_ROLE ||
            responseData.user_role === JUDGE_ROLE;
          dispatch({
            type: 'SET_LOGGED_IN',
            payload: {
              active_role,
              profile: {
                name: responseData.name,
                email: responseData.email
              },
              user_role: responseData.user_role,
              user_roles: responseData.user_roles,
              module_access: responseData.module_access,
              user_profile: responseData.user_profile,
              isProfileOK
            }
          });
          dispatch({
            type: 'SET_ACC_TOKEN',
            payload: {
              access_token: responseData.access_token
            }
          });
          scb();
          scbWithData(responseData);
        },
        (error) => {
          console.log(error, 'ecb error');
          dispatch({ type: 'UNSET_LOGGED_IN', payload: 'Could not get authorized!' });
          dispatch({ type: 'CLEAR_ACC_TOKEN' });
        }
      );
    } catch (error) {
      console.log(error, 'catch error refresh token');
      dispatch({ type: 'UNSET_LOGGED_IN', payload: 'Could not get authorized!' });
      dispatch({ type: 'CLEAR_ACC_TOKEN' });
    }
  };
}
export function handleGetTerms() {
  return async (dispatch) => {
    try {
      const url = common.GET_TERMS;
      await fetchApi(
        url,
        {},
        (data) => {
          console.log(data, 'GET_TERM');
          dispatch({
            type: 'SET_TERMS',
            payload: data
          });
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };
}
export function handleGetActiveTerm(scb, ecb) {
  return async (dispatch) => {
    try {
      const url = shared.GET_TERMS;
      await fetchApi(
        url,
        {},
        (data) => {
          dispatch({
            type: 'SET_ACTIVE_TERM',
            payload: data
          });
          scb();
        },
        (error) => {
          ecb();
          console.log(error);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };
}
export async function handleResetPassword(config, scb, ecb) {
  try {
    const url = auth.RESET_PASSWORD;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'RESET_PASSWORD');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}
