import React from 'react';
import 'simplebar/src/simplebar.css';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { throttle } from 'lodash';
import Amplify from '@aws-amplify/core';
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import store from './store';
import './App.css';
import './components/CustomComponents/GoogleTranslate/style.css';
import envFunc from './envUtils';
import { saveState } from './localStorage';
import { refreshToken } from './actions/loginActions';
import config from './configs/awsConfig';
// ----------------------------------------------------------------------

Amplify.configure(config);

store.subscribe(
  throttle(() => {
    saveState({
      loginReducer: store.getState().loginReducer
    });
  }, 1000)
);
envFunc();
store.dispatch(
  refreshToken(
    () => {},
    (data) => {
      console.log(data);
    }
  )
);

ReactDOM.render(
  <HelmetProvider>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </HelmetProvider>,
  document.getElementById('root')
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

reportWebVitals();

// award operation medal type change sayfa bazli yapalim
