import React from 'react';
import PropTypes from 'prop-types';
import CustomDialog, {
  DialogContent
} from '../../../../../../components/CustomComponents/CustomDialog';
import RubricForm from './RubricForm';

/*
This view is a modal that includes SubQuestionStepper to add new sub question
*/

function AddSubRubric(props) {
  const { closeDialog, id, handleSuccess } = props;

  return (
    <CustomDialog onClose={closeDialog} maxWidth="md" fullWidth title="Add Rubric">
      <DialogContent>
        <RubricForm id={id} handleSuccess={handleSuccess} handleClose={closeDialog} />
      </DialogContent>
    </CustomDialog>
  );
}

AddSubRubric.propTypes = {
  closeDialog: PropTypes.func,
  id: PropTypes.number,
  handleSuccess: PropTypes.func
};

export default AddSubRubric;
