export default {
  Auth: {
    region: process.env.REACT_APP_AWS_LAMBDA_TRANSFER_REGION,
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: false,
    authenticationFlowType: 'USER_PASSWORD_AUTH'
  },
  API: {
    endpoints: [
      {
        name: 'go-transfer-projects',
        endpoint: process.env.REACT_APP_AWS_LAMBDA_TRANSFER_ENDPOINT,
        region: process.env.REACT_APP_AWS_LAMBDA_TRANSFER_REGION
      }
    ]
  }
};
