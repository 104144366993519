import React from 'react';
import { MenuItem, Stack, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import MuiTable from '../../../../../components/CustomComponents/MuiTable';
import headers from './headerVariables';

function Index(props) {
  const { terms, addForm, term, setTerm, tableData } = props;

  function TermSelect() {
    return (
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 2 }}>
        <TextField select label="Terms" value={term} onChange={(e) => setTerm(e.target.value)}>
          {terms.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
        <div className="header-button-style">
          <Button variant="outlined" onClick={addForm}>
            Add New
          </Button>
        </div>
      </Stack>
    );
  }

  return <MuiTable tableData={tableData} tableColumns={headers} title={<TermSelect />} />;
}

Index.propTypes = {
  terms: PropTypes.array,
  addForm: PropTypes.func,
  term: PropTypes.number,
  setTerm: PropTypes.func,
  tableData: PropTypes.array
};

export default connect((store) => ({
  terms: store.loginReducer.terms,
  active_term: store.loginReducer.active_term
}))(Index);
