import { Button, DialogContent, Grid, Stack } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { Icon } from '@iconify/react';
import { IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import MuiTable from '../../../../components/CustomComponents/MuiTable';
import getHeaders from './utils/headerVariables';
import { deleteVip, getParticipantList, getVip } from '../serviceCalls';
import CustomDialog from '../../../../components/CustomComponents/CustomDialog';
import ParticipantDialog from './ParticipantDialog';
import VipDialog from './VipDialog';
import { setConfirmationAlert, setSnackbar } from '../../../../actions/alertActions';
import Page from '../../../../components/Page';
import ProcessInfoBox from '../../../../components/CustomComponents/CustomCard/ProcessInfoBox';
import { ParticipantInfo } from '../../../../info/ParticipantInfo';
import ProcessWarningBox from '../../../../components/CustomComponents/CustomCard/ProcessWarningBox';

function Index(props) {
  const { dispatch, isFinalist } = props;
  const [tableData, setTableData] = useState([]);
  const [dialogShow, setDialogShow] = useState(false);
  const [editDialogShow, setEditDialogShow] = useState(false);
  const [participantList, setParticipantList] = useState([]);
  const [guidelines, setGuidelines] = useState([]);
  const [editData, setEditData] = useState(null);
  const [headers, setHeaders] = useState([]);

  useEffect(() => {
    const _headers = getHeaders(tableData);
    setHeaders(_headers);
    fetchData();
  }, []);
  function fetchData() {
    getVip(
      {},
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((item) => {
          item.project_titles_filter = item.project_titles;
          item.project_ids_filter = item.project_ids;
          item.name = `${item.first_name} ${item.last_name}`;
          item.date_of_birth_comp = moment(item.date_of_birth).format('YYYY-MM-DD');
          item.edit = (
            <IconButton color="secondary" onClick={() => handleEditVip(item)}>
              <Icon icon="ci:edit" width={24} height={24} />
            </IconButton>
          );
          item.delete = (
            <IconButton color="secondary" onClick={() => handleDeleteVip(item.id)}>
              <Icon icon="fluent:delete-48-filled" width={24} height={24} />
            </IconButton>
          );
          item.created_at_comp = moment(item.created_at).format('YYYY-MM-DD / LT');
        });
        const _headers = getHeaders(list);
        setHeaders(_headers);
        setTableData(list);
      },
      () => {}
    );
  }
  function handleAddVip() {
    getParticipantList(
      {},
      (data) => {
        const list = data.data;
        list.forEach((item) => {
          item.project_titles_filter = item.project_titles;
          item.project_ids_filter = item.project_ids;
          item.name = `${item.first_name} ${item.last_name}`;
          item.date_of_birth_comp = moment(item.date_of_birth).format('YYYY-MM-DD');
        });
        setParticipantList(list);
        setDialogShow(true);
      },
      () => {}
    );
  }
  function handleEditVip(data) {
    setEditDialogShow(true);
    setEditData(data);
  }
  function handleDeleteVip(participant_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'This action will delete vip reason of participant permanently',
        () => {
          deleteVip(
            { participant_id },
            () => {
              fetchData();
              dispatch(setSnackbar('success', 'The VIP reason of participant has been deleted'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }
  function handleRefresh() {
    setDialogShow(false);
    setEditDialogShow(false);
    fetchData();
  }

  return (
    <Page title="VIP | Genius">
      <Grid container>
        <Grid item xs={12} sx={{ mt: 2 }}>
          {isFinalist ? (
            <Stack spacing={1}>
              {guidelines.length > 0 && (
                <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />
              )}
              <MuiTable
                tableData={tableData}
                tableColumns={headers}
                title={
                  <div style={{ marginTop: 10 }}>
                    <Button variant="outlined" onClick={handleAddVip}>
                      VIP Registration
                    </Button>
                  </div>
                }
              />
            </Stack>
          ) : (
            <ProcessWarningBox Comp={<ParticipantInfo />} />
          )}
        </Grid>
        <CustomDialog
          onClose={() => setDialogShow(false)}
          open={dialogShow}
          maxWidth="lg"
          title="VIP Registration"
        >
          <DialogContent>
            <ParticipantDialog
              participantList={participantList}
              handleClose={() => setDialogShow(false)}
              handleRefresh={handleRefresh}
            />
          </DialogContent>
        </CustomDialog>
        <CustomDialog
          onClose={() => setEditDialogShow(false)}
          open={editDialogShow}
          maxWidth="md"
          title="Edit VIP Info"
        >
          <DialogContent>
            <VipDialog
              editData={editData}
              handleClose={() => setEditDialogShow(false)}
              handleRefresh={handleRefresh}
            />
          </DialogContent>
        </CustomDialog>
      </Grid>
    </Page>
  );
}

Index.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isFinalist: PropTypes.bool
};

export default connect((store) => ({
  isFinalist: store.dashboardReducer.isFinalist
}))(Index);
