import React from 'react';
import { Container } from '@material-ui/core';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Page from '../../../components/Page';
import LandingPageCard from '../../../components/CustomComponents/CustomCard/LandingPageCard';

const CARDS = [
  {
    id: 1,
    name: 'Config',
    description: 'From this module you can arrange award operation.'
  },
  {
    id: 2,
    name: 'Report',
    description: 'From this module you can arrange award report'
  }
];

const CONFIG_PAGE = 1;

function Index() {
  const navigate = useNavigate();

  function generate(data) {
    if (data.id === CONFIG_PAGE) {
      navigate('/admin/partner_fair_operation/config', { replace: true });
    } else {
      navigate('/admin/partner_fair_operation/report', { replace: true });
    }
  }

  return (
    <Page title="Award Operation | Genius">
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          {CARDS.map((item, index) => (
            <Grid item xs={3} key={index}>
              <LandingPageCard data={item} generate={generate} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Page>
  );
}

export default Index;
