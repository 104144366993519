import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MenuItem, Paper, TextField } from '@material-ui/core';
import { Button, Stack } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { getFinalistStatus } from '../../ProjectOperation/serviceCalls';
import { updateProjectFinalist } from '../_apis/serviceCalls';
import { setSnackbar } from '../../../../actions/alertActions';

const FinalistStatusDialog = (props) => {
  const { handleClose, data, dispatch, handleRefresh } = props;
  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(null);

  useEffect(() => {
    getFinalistStatus(
      {},
      (data) => {
        // please delete last index of data
        data.pop();
        setTypes(data);
      },
      () => {}
    );
  }, []);

  function handleAssign() {
    if (selectedType !== null) {
      updateProjectFinalist(
        { project_id: data.project_id, finalist_status_id: selectedType },
        () => {
          dispatch(setSnackbar('success', 'The status of project has been updated'));
          handleRefresh();
        },
        () => {}
      );
    } else {
      dispatch(setSnackbar('warning', 'Please select finalist status'));
    }
  }

  return (
    <Stack spacing={2}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 400 }} aria-label="simple table">
          <TableHead>
            <TableRow style={{ background: 'gainsboro' }}>
              <TableCell style={{ width: '20%' }}>ID</TableCell>
              <TableCell style={{ width: '40%' }}>Project</TableCell>
              <TableCell style={{ width: '40%' }}>Team</TableCell>
              <TableCell style={{ width: '40%' }}>Contest</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                {data?.project_id}
              </TableCell>
              <TableCell>{data?.project_title}</TableCell>
              <TableCell>{data?.team_name}</TableCell>
              <TableCell>{data?.contest_name}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TextField
        fullWidth
        select
        label="Finalist Status"
        onChange={(e) => setSelectedType(e.target.value)}
      >
        {types.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </TextField>
      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="end" spacing={2}>
        <Button onClick={handleAssign} variant="outlined" disabled={!selectedType}>
          Save
        </Button>
        <Button variant="outlined" color="error" onClick={handleClose}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};

FinalistStatusDialog.propTypes = {
  data: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  handleRefresh: PropTypes.func
};

export default connect(() => ({}))(FinalistStatusDialog);
