const HOST = process.env.REACT_APP_REST_API_HOST;

const GET_PARTICIPANT_OP_PARTICIPANT = {
  method: 'GET',
  path: `${HOST}go_admin/participant_operation/participation/participant`
};
const UPDATE_PARTICIPANT_OP_PARTICIPANT = {
  method: 'PUT',
  path: `${HOST}go_admin/participant_operation/participation/participant`
};
const GET_PARTICIPANT_OP_LIST = {
  method: 'GET',
  path: `${HOST}go_admin/participant_operation/participation/list`
};
const UPDATE_PARTICIPANT_OP_ATTENDANCE = {
  method: 'PUT',
  path: `${HOST}go_admin/participant_operation/participation/attendance`
};
const DOWNLOAD_CERTIFICATE = {
  method: 'GET',
  path: `${HOST}go_admin/participant_operation/participation/certificate`
};
const GET_PARTICIPANT_OP_INVITATION_LETTER_LIST = {
  method: 'GET',
  path: `${HOST}go_admin/participant_operation/invitation_letter/list`
};
const DOWNLOAD_PARTICIPANT_OP_INVITATION_LETTER = {
  method: 'GET',
  path: `${HOST}go_admin/participant_operation/invitation_letter/download`
};
const GET_VIP_FOR_ADMIN = {
  method: 'GET',
  path: `${HOST}go_admin/participant_operation/vip`
};
const DELETE_VIP_FOR_ADMIN = {
  method: 'DELETE',
  path: `${HOST}go_admin/participant_operation/vip`
};
const GET_TRIP_FOR_ADMIN = {
  method: 'GET',
  path: `${HOST}go_admin/participant_operation/trip`
};
const GET_TRIP_LIST_FOR_ADMIN = {
  method: 'GET',
  path: `${HOST}go_admin/participant_operation/trip/list`
};
const UPDATE_TRIP_FOR_ADMIN = {
  method: 'PUT',
  path: `${HOST}go_admin/participant_operation/trip`
};
const GET_TRAVEL_FOR_ADMIN = {
  method: 'GET',
  path: `${HOST}go_admin/participant_operation/travel`
};
const GET_TRAVEL_PARTICIPANT_FOR_ADMIN = {
  method: 'GET',
  path: `${HOST}go_admin/participant_operation/travel/participant`
};
const PARTICIPANT_MANUAL_PAYMENT = {
  method: 'POST',
  path: `${HOST}go_admin/participant_operation/participation/manual_payment`
};
const GET_PARTICIPANT_REPORT = {
  method: 'GET',
  path: `${HOST}go_admin/participant_operation/participant_report`
};
const GET_BADGE = {
  method: 'POST',
  path: `${HOST}go_admin/participant_operation/participation/badge`
};
const GET_FORM = {
  method: 'POST',
  path: `${HOST}go_admin/participant_operation/participation/consent_form`
};

module.exports = {
  GET_PARTICIPANT_OP_PARTICIPANT,
  UPDATE_PARTICIPANT_OP_PARTICIPANT,
  GET_PARTICIPANT_OP_LIST,
  UPDATE_PARTICIPANT_OP_ATTENDANCE,
  GET_PARTICIPANT_OP_INVITATION_LETTER_LIST,
  DOWNLOAD_PARTICIPANT_OP_INVITATION_LETTER,
  GET_VIP_FOR_ADMIN,
  DELETE_VIP_FOR_ADMIN,
  GET_TRIP_FOR_ADMIN,
  UPDATE_TRIP_FOR_ADMIN,
  GET_TRAVEL_FOR_ADMIN,
  GET_TRAVEL_PARTICIPANT_FOR_ADMIN,
  PARTICIPANT_MANUAL_PAYMENT,
  DOWNLOAD_CERTIFICATE,
  GET_PARTICIPANT_REPORT,
  GET_TRIP_LIST_FOR_ADMIN,
  GET_BADGE,
  GET_FORM
};
