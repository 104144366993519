import React, { useEffect, useState } from 'react';
import { IconButton, MenuItem, Stack, TextField } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { Button, DialogContent, Tooltip } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Email from '@mui/icons-material/Email';
import ReactHtmlParser from 'react-html-parser';
import MuiTable from '../../../components/CustomComponents/MuiTable';
import headers from './utils/headerVariables';
import { deleteSchoolOperation, getSchoolOperation, getReviewList } from './serviceCalls';
import CustomDialog from '../../../components/CustomComponents/CustomDialog';
import UpdateDialog from './utils/UpdateDialog';
import TransferDialog from './utils/TransferDialog';
import { setConfirmationAlert, setSnackbar } from '../../../actions/alertActions';
import MergeDialog from './utils/MergeDialog';
import CustomMail from '../../../components/CustomComponents/CustomMail';
import { SCHOOL_MAIL_TYPE } from '../../../api/RestVariables/staticVariables';
import Page from '../../../components/Page';
import ReviewDetail from './utils/ReviewDetail/ReviewDetail';
import ProcessInfoBox from '../../../components/CustomComponents/CustomCard/ProcessInfoBox';

function Index(props) {
  const { dispatch, isCommunication, terms, active_term } = props;
  const [term, setTerm] = useState(active_term);
  const [tableData, setTableData] = useState([]);
  const [dialogShow, setDialogShow] = useState(false);
  const [mergeDialogShow, setMergeDialogShow] = useState(false);
  const [transferDialogShow, setTransferDialogShow] = useState(false);
  const [editData, setEditData] = useState(null);
  const [mailDialogShow, setMailDialogShow] = useState(false);
  const [addMail, setAddMail] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [idList, setIdList] = useState([]);
  const [reviewList, setReviewList] = useState([]);
  const [reviewDialogShow, setReviewDialogShow] = useState(false);
  const [guidelines, setGuidelines] = useState([]);

  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedItems(rowsSelected);
    const arr = [];
    rowsSelected.forEach((item) => {
      arr.push(tableData[item].id);
    });
    setIdList(arr);
  };

  useEffect(() => {
    fetchData();
  }, [term]);
  function fetchData() {
    getSchoolOperation(
      { term_id: term },
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((item) => {
          item.main_contact_name = `${item.main_contact_first_name} ${item.main_contact_last_name}`;
          item.merge = (
            <IconButton color="secondary" onClick={() => handleMergeSchool(item)}>
              <Icon icon="ic:round-merge-type" />
            </IconButton>
          );
          item.transfer = (
            <IconButton color="secondary" onClick={() => handleTransferSchool(item)}>
              <Icon icon="bx:bx-transfer-alt" />
            </IconButton>
          );
          item.edit = (
            <IconButton color="secondary" onClick={() => handleEditSchool(item)}>
              <Icon icon="ci:edit" />
            </IconButton>
          );
          item.review_status_comp = (
            <IconButton
              disabled={!item.project_count}
              color="secondary"
              onClick={() => handleChangeReviewStatus(item)}
            >
              <Icon icon="bx:bx-comment-detail" width={24} height={24} />
            </IconButton>
          );
          item.delete = (
            <IconButton
              color="secondary"
              disabled={item.trip_id === null}
              onClick={() => handleDeleteSchool(item.id)}
            >
              <Icon icon="fluent:delete-48-filled" width={24} height={24} />
            </IconButton>
          );
        });
        setTableData(list);
      },
      () => {}
    );
  }
  function handleChangeReviewStatus(school) {
    const param = {
      term_id: term,
      school_id: school.id
    };
    getReviewList(
      param,
      (data) => {
        setReviewList(data);
        setReviewDialogShow(true);
      },
      () => {}
    );
  }
  function handleEditSchool(item) {
    setEditData(item);
    setDialogShow(true);
  }
  function handleMergeSchool(item) {
    setEditData(item);
    setMergeDialogShow(true);
  }
  function handleTransferSchool(item) {
    setEditData(item);
    setTransferDialogShow(true);
  }
  function handleDeleteSchool(school_id) {
    dispatch(
      setConfirmationAlert('Are you sure?', 'YES, DELETE', 'This action will delete school', () => {
        deleteSchoolOperation(
          { school_id },
          () => {
            dispatch(setSnackbar('success', 'The school has been deleted'));
            dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            handleRefresh();
          },
          () => {
            dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
          }
        );
      })
    );
  }
  function handleRefresh() {
    setDialogShow(false);
    setTransferDialogShow(false);
    setMergeDialogShow(false);
    setEditData(null);
    setMailDialogShow(false);
    setAddMail(false);
    setSelectedItems([]);
    fetchData();
  }
  function TermSelect() {
    return (
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 2 }}>
        <TextField select label="Terms" value={term} onChange={(e) => setTerm(e.target.value)}>
          {terms.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
        {addMail && (
          <div>
            <Button
              style={{ marginRight: 5 }}
              disabled={selectedItems.length === 0}
              variant="outlined"
              onClick={() => setMailDialogShow(true)}
            >
              Next
            </Button>
            <Button color="error" variant="outlined" onClick={() => setAddMail(false)}>
              Cancel
            </Button>
          </div>
        )}
      </Stack>
    );
  }

  return (
    <Page title="School Operation | Genius">
      <Stack spacing={2}>
        {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
        <MuiTable
          tableData={tableData}
          tableColumns={headers}
          title={<TermSelect />}
          customOption={{
            selectableRows: addMail ? 'multiple' : 'none',
            onRowSelectionChange,
            rowsSelected: selectedItems,
            customToolbar: () =>
              isCommunication !== -1 && (
                <Tooltip title="Send Email">
                  <IconButton onClick={() => setAddMail(true)}>
                    <Email />
                  </IconButton>
                </Tooltip>
              )
          }}
        />
      </Stack>
      <CustomDialog
        maxWidth="md"
        onClose={() => setDialogShow(false)}
        open={dialogShow}
        title="Update School"
      >
        <DialogContent>
          <UpdateDialog
            data={editData}
            handleRefresh={handleRefresh}
            handleClose={() => setDialogShow(false)}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        maxWidth="md"
        onClose={() => setMergeDialogShow(false)}
        open={mergeDialogShow}
        title={`Merge ${editData?.name} to`}
      >
        <DialogContent>
          <MergeDialog
            tableData={tableData}
            data={editData}
            handleRefresh={handleRefresh}
            handleClose={() => setMergeDialogShow(false)}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        maxWidth="md"
        onClose={() => setTransferDialogShow(false)}
        open={transferDialogShow}
        title={`Transfer ${editData?.name} to`}
      >
        <DialogContent>
          <TransferDialog
            tableData={tableData}
            data={editData}
            handleRefresh={handleRefresh}
            handleClose={() => setTransferDialogShow(false)}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        onClose={() => setMailDialogShow(false)}
        open={mailDialogShow}
        maxWidth="lg"
        title="Send Mail"
        fullWidth
        disableEnforceFocus
      >
        <DialogContent>
          <CustomMail
            handleRefresh={handleRefresh}
            mailType={SCHOOL_MAIL_TYPE}
            idList={idList}
            handleClose={() => setMailDialogShow(false)}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        maxWidth="lg"
        fullWidth
        onClose={() => setReviewDialogShow(false)}
        open={reviewDialogShow}
        title="Review Details"
      >
        <DialogContent>
          <ReviewDetail handleClose={() => setReviewDialogShow(false)} reviewList={reviewList} />
        </DialogContent>
      </CustomDialog>
    </Page>
  );
}

Index.propTypes = {
  dispatch: PropTypes.func,
  terms: PropTypes.array,
  active_term: PropTypes.number,
  isCommunication: PropTypes.number
};

export default connect((store) => ({
  terms: store.loginReducer.terms,
  active_term: store.loginReducer.active_term,
  isCommunication: store.routeReducer.isCommunication
}))(Index);
