import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { IconButton } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import { Stack } from '@mui/material';
import MuiTable from '../../../../components/CustomComponents/MuiTable';
import headers from './utils/headerVariables';
import { getDRSchool, patchDRSchool } from '../serviceCalls';
import { setConfirmationAlert, setSnackbar } from '../../../../actions/alertActions';
import ProcessInfoBox from '../../../../components/CustomComponents/CustomCard/ProcessInfoBox';

function Index(props) {
  const { dispatch } = props;
  const [tableData, setTableData] = useState([]);
  const [guidelines, setGuidelines] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);
  function fetchData() {
    getDRSchool(
      {},
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((item) => {
          item.main_contact_name = `${item.main_contact_first_name} ${item.main_contact_last_name}`;
          item.recover = (
            <IconButton color="secondary" onClick={() => handleRecoverSchool(item.id)}>
              <Icon icon="ic:baseline-cached" />
            </IconButton>
          );
        });
        setTableData(list);
      },
      () => {}
    );
  }
  function handleRecoverSchool(school_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, RECOVER',
        'This action will recover school data',
        () => {
          patchDRSchool(
            { school_id },
            () => {
              dispatch(setSnackbar('success', 'The school data has been recovered'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
              fetchData();
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }

  return (
    <Stack spacing={2}>
      {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
      <MuiTable tableData={tableData} tableColumns={headers} />
    </Stack>
  );
}

Index.propTypes = {
  dispatch: PropTypes.func.isRequired
};

export default connect(() => ({}))(Index);
