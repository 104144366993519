import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Stack, TextField } from '@material-ui/core';
import * as React from 'react';
import { Button, DialogContent } from '@mui/material';
import { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import CustomDialog from '../../../../../components/CustomComponents/CustomDialog';
import { migrateFair } from '../../serviceCalls';
import { setSnackbar } from '../../../../../actions/alertActions';

function MigrationDialog(props) {
  const { dispatch, terms, handleRefresh, handleClose, idList } = props;
  const [term, setTerm] = useState('');

  function handleMigrate() {
    const params = {
      term_id: term,
      fair_ids: idList
    };
    migrateFair(params, () => {
      handleClose();
      handleRefresh();
      dispatch(setSnackbar('success', 'Fair migrated successfully'));
    }).then(() => {});
  }
  return (
    <CustomDialog onClose={handleClose} maxWidth="xs" title="Migration" fullWidth>
      <DialogContent>
        <Stack spacing={1} sx={{ mt: 1 }}>
          <TextField select label="Terms" value={term} onChange={(e) => setTerm(e.target.value)}>
            {terms.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.description}
              </MenuItem>
            ))}
          </TextField>
          <Stack direction="row" justifyContent="end" spacing={1}>
            <Button size="small" onClick={handleMigrate} variant="outlined" disabled={!term}>
              Migrate
            </Button>
            <Button size="small" color="error" onClick={handleClose} variant="outlined">
              Cancel
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </CustomDialog>
  );
}

MigrationDialog.propTypes = {
  terms: PropTypes.array.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  idList: PropTypes.array.isRequired
};

export default connect((store) => ({
  terms: store.loginReducer.terms
}))(MigrationDialog);
