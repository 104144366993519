import * as React from 'react';
import { Grid, Button, Card, DialogContent } from '@mui/material';
import { Stack } from '@material-ui/core';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CustomStudentCard from '../../../../../components/CustomComponents/CustomCard';
import EditStudentForm from './EditStudentForm';
import AddStudentForm from './AddStudentForm';
import { setConfirmationAlert, setSnackbar } from '../../../../../actions/alertActions';
import Badge from '../../../../../components/CustomComponents/Badge/Badge';
import { getStudentList, deleteStudent } from '../../serviceCalls';
import CustomDialog from '../../../../../components/CustomComponents/CustomDialog';
import AddExistingStudentDialog from '../../../../Application/Projects/AddPage/AddExistingStudentDialog';

function StudentPart(props) {
  const { project_id, dispatch } = props;
  const [formShowEdit, setFormShowEdit] = useState(false);
  const [formShowAdd, setFormShowAdd] = useState(false);
  const [studentList, setStudentList] = useState([]);
  const [studentDataForEdit, setStudentDataForEdit] = useState(null);
  const [existingStudentAdd, setExistingStudentAdd] = useState(false);

  useEffect(() => {
    getStudents();
  }, []);
  function getStudents() {
    getStudentList(
      { project_id },
      (data) => {
        setStudentList(data);
      },
      () => {}
    );
  }
  function editStudent(student) {
    setStudentDataForEdit(student);
    setFormShowEdit(true);
  }
  function handleDeleteStudent(student_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'This action will delete student from Project, are you sure?',
        () => {
          deleteStudent(
            { student_id, project_id },
            () => {
              getStudents();
              dispatch(setSnackbar('success', 'The student has been deleted'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }

  return (
    <Card sx={{ padding: 2, mt: 2 }}>
      <Badge color="submitColor"> Students </Badge>
      <Stack spacing={2} style={{ marginTop: 20 }}>
        <Grid container spacing={2}>
          {studentList.length > 0 &&
            studentList.map((item, index) => (
              <Grid item xs={12} sm={6} md={6} key={index}>
                <CustomStudentCard
                  data={item}
                  editStudent={editStudent}
                  deleteStudent={handleDeleteStudent}
                  admin
                />
              </Grid>
            ))}
        </Grid>
        {!(formShowEdit || formShowAdd) && (
          <Grid item xs={6}>
            <Stack spacing={1} direction="row">
              <Button variant="outlined" onClick={() => setFormShowAdd(true)}>
                Add new Student
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setExistingStudentAdd(true)}
              >
                Select Student
              </Button>
            </Stack>
          </Grid>
        )}
        {formShowEdit && (
          <EditStudentForm
            studentData={studentDataForEdit}
            setFormShow={setFormShowEdit}
            handleRefresh={getStudents}
          />
        )}
        <CustomDialog
          onClose={() => setFormShowAdd(false)}
          open={existingStudentAdd}
          maxWidth="lg"
          fullWidth
          title="Add Existing Student"
        >
          <DialogContent>
            <AddExistingStudentDialog
              admin
              addedStudentList={studentList}
              handleClose={() => setExistingStudentAdd(false)}
              handleRefresh={getStudents}
            />
          </DialogContent>
        </CustomDialog>
        {formShowAdd && <AddStudentForm setFormShow={setFormShowAdd} handleRefresh={getStudents} />}
      </Stack>
    </Card>
  );
}

StudentPart.propTypes = {
  project_id: PropTypes.number,
  dispatch: PropTypes.func.isRequired
};

export default connect(() => ({}))(StudentPart);
