import { fetchApi } from '../../../../api/fetchApi';

const performance = require('../../../../api/RestVariables/Application/performance');

export async function fetchPerformance(config, scb, ecb) {
  try {
    const url = performance.GET_PERFORMANCE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_PERFORMANCE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addPerformance(config, scb, ecb) {
  try {
    const url = performance.ADD_PERFORMANCE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_PERFORMANCE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updatePerformance(config, scb, ecb) {
  try {
    const url = performance.UPDATE_PERFORMANCE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_PERFORMANCE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deletePerformance(config, scb, ecb) {
  try {
    const url = performance.DELETE_PERFORMANCE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_PERFORMANCE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function fetchPerformanceTypes(config, scb, ecb) {
  try {
    const url = performance.GET_PERFORMANCE_TYPES;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_PERFORMANCE_TYPES');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function fetchPerformanceParticipants(config, scb, ecb) {
  try {
    const url = performance.GET_PERFORMANCE_PARTICIPANTS;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_PERFORMANCE_PARTICIPANTS');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function fetchPerformanceFileList(config, scb, ecb) {
  try {
    const url = performance.GET_PERFORMANCE_FILE_LIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_PERFORMANCE_FILE_LIST');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function fetchPerformanceFile(config, scb, ecb) {
  try {
    const url = performance.GET_PERFORMANCE_FILE;
    await fetchApi(
      url,
      { file_id: config.id },
      (data) => {
        getFilesApi(data, config, scb, ecb);
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

const getFilesApi = (data, config, scb, ecb) => {
  const { url } = data;
  const extension = url.substring(url.lastIndexOf('.') + 1, url.lastIndexOf('?'));
  const requestOptions = {
    method: 'GET'
  };
  try {
    fetch(url, requestOptions)
      // eslint-disable-next-line consistent-return
      .then((response) => {
        if (response.status === 200) {
          return response.blob();
        }
        ecb(response.status);
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        scb();
        console.log(link);
        link.href = href;
        link.setAttribute('download', `${config.file_name}.${extension}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  } catch (error) {
    console.log(error);
    ecb(error);
  }
};

export async function addPerformanceFile(config, scb, ecb) {
  try {
    const url = performance.ADD_PERFORMANCE_FILE;
    await fetchApi(
      url,
      config,
      (data) => {
        uploadFilesApi(data, config, scb, ecb);
        console.log(data, 'ADD_PERFORMANCE_FILE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

const uploadFilesApi = (data, config, scb, ecb) => {
  const { url } = data;

  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': config.file.type },
    body: config.file
  };
  try {
    fetch(url, requestOptions).then((response) => {
      if (response.status === 200) {
        uploadStatusOfFile(
          {
            performance_id: config.performance_id,
            file_name: config.file_name,
            is_verified: 1
          },
          () => {},
          () => {}
        );
        scb();
      } else {
        uploadStatusOfFile(
          {
            performance_id: config.performance_id,
            file_name: config.file_name,
            is_verified: 0
          },
          () => {},
          () => {}
        );
        ecb(response.status);
      }
    });
  } catch (error) {
    console.log(error);
    ecb(error);
  }
};

export async function uploadStatusOfFile(config, scb, ecb) {
  try {
    const url = performance.UPDATE_PERFORMANCE_FILE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_PERFORMANCE_FILE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deletePerformanceFile(config, scb, ecb) {
  try {
    const url = performance.DELETE_PERFORMANCE_FILE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_PERFORMANCE_FILE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}
