export default [
  {
    name: 'id',
    label: 'ID'
  },
  {
    name: 'title',
    label: 'Title'
  },
  {
    name: 'country',
    label: 'Country'
  },
  {
    name: 'school_name',
    label: 'School'
  },
  {
    name: 'discipline',
    label: 'Discipline'
  },
  {
    name: 'category',
    label: 'Category'
  },
  {
    name: 'electricity_needed_comp',
    label: 'Electricity Needed'
  },
  {
    name: 'project_student_complete',
    label: 'Students'
  },
  {
    name: 'judging_session_type',
    label: 'Session Type'
  },
  {
    name: 'media_link_comp',
    label: 'YT Link',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'media_link_filter',
    label: 'YT Link',
    options: {
      display: false
    }
  },
  {
    name: 'presentation_link_comp',
    label: 'Presentation Link',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'presentation_link_filter',
    label: 'Presentation Link',
    options: {
      display: false
    }
  },
  {
    name: 'balance_comp',
    label: 'Balance',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'balance_filter',
    label: 'Balance',
    options: {
      display: false
    }
  },
  {
    name: 'paid_attended_student_count_comp',
    label: 'Paid Student',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'paid_attended_student_count_filter',
    label: 'Paid Student',
    options: {
      display: false
    }
  },
  {
    name: 'group_number',
    label: 'Group Number'
  },
  {
    name: 'place',
    label: 'Place'
  },
  {
    name: 'number_of_assigned_judges',
    label: 'Assigned Judges'
  },
  {
    name: 'number_of_completed_judges',
    label: 'Completed Judges'
  },
  {
    name: 'average_score_comp',
    label: 'Average Score'
  },
  {
    name: 'assign',
    label: 'Assign',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  }
];
