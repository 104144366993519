import { convertHourToSeconds } from '../../../../../../utils/formatTime';

export default [
  {
    name: 'id',
    label: 'ID'
  },
  {
    name: 'title',
    label: 'Title'
  },
  {
    name: 'country',
    label: 'Country'
  },
  {
    name: 'school_name',
    label: 'School'
  },
  {
    name: 'discipline',
    label: 'Discipline'
  },
  {
    name: 'category',
    label: 'Category'
  },
  {
    name: 'totalTime',
    label: 'Judging Time',
    options: {
      sortCompare: (order) => (obj1, obj2) => {
        const val1 = convertHourToSeconds(obj1?.data);
        const val2 = convertHourToSeconds(obj2?.data);
        return (val1 - val2) * (order === 'asc' ? 1 : -1);
      }
    }
  },
  {
    name: 'score',
    label: 'Score'
  },
  {
    name: 'createdDate',
    label: 'Created At'
  },
  {
    name: 'submittedDate',
    label: 'Submitted At'
  },
  {
    name: 'modifiedData',
    label: 'Modified At'
  },
  {
    name: 'project_student_complete',
    label: 'Students'
  },
  {
    name: 'presentation_link_comp',
    label: 'Presentation Link',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'presentation_link_filter',
    label: 'Presentation Link',
    options: {
      display: false
    }
  },
  {
    name: 'balance_comp',
    label: 'Balance',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'balance_filter',
    label: 'Balance',
    options: {
      display: false
    }
  },
  {
    name: 'delete',
    label: 'Delete',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  }
];
