import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import personFill from '@iconify/icons-eva/person-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { alpha } from '@material-ui/core/styles';
import { Button, Box, Divider, MenuItem, Typography, Avatar, IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MenuPopover from '../../components/MenuPopover';
import { onLogout } from '../../actions/loginActions';

// ----------------------------------------------------------------------

function AccountPopover(props) {
  const { profile, dispatch, user_profile, user_role } = props;
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const MENU_OPTIONS = [
    {
      label: 'Profile',
      icon: personFill,
      linkTo: user_role === 3 ? '/fair/fair_profile' : '/dashboard/profile'
    }
  ];

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleLogout = () => {
    dispatch(
      onLogout(
        () => {
          navigate('/main', { replace: true });
        },
        () => {}
      )
    );
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar src={user_profile?.picture_data} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {user_profile.first_name
              ? `${user_profile?.first_name} ${user_profile?.last_name}`
              : profile?.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {`${profile?.email}`}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}

AccountPopover.propTypes = {
  profile: PropTypes.object,
  dispatch: PropTypes.func,
  user_profile: PropTypes.object,
  user_role: PropTypes.number
};

export default connect((store) => ({
  profile: store.loginReducer.profile,
  user_profile: store.loginReducer.user_profile,
  user_role: store.loginReducer.user_role
}))(AccountPopover);
