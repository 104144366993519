import { fetchApi } from '../../../api/fetchApi';

const common = require('../../../api/RestVariables/Admin/common');

export async function getDisciplineList(config, scb, ecb) {
  try {
    const url = common.GET_DISCIPLINE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_DISCIPLINE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getTermList(config, scb, ecb) {
  try {
    const url = common.GET_TERMS;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_TERMS');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}
