import * as React from 'react';
import { useState } from 'react';
import { Button, Stack } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MuiTable from '../../../../components/CustomComponents/MuiTable';
import headers from './utils/headerVariablesParticipantList';
import { addInvitationLetter } from '../serviceCalls';
import { setSnackbar } from '../../../../actions/alertActions';

function ParticipantDialog(props) {
  const { participantList, handleClose, handleRefresh, dispatch } = props;
  const [selectedStudents, setSelectedStudents] = useState([]);
  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedStudents(rowsSelected);
  };
  function handleRequest() {
    const param = [];
    selectedStudents.forEach((item) => {
      const { id } = participantList[item];
      param.push(id);
    });
    addInvitationLetter(
      { participant_ids: param },
      () => {
        dispatch(setSnackbar('success', 'The invitation letter has been requested'));
        handleRefresh();
      },
      () => {}
    );
  }

  return (
    <Stack spacing={3}>
      <MuiTable
        tableData={participantList}
        tableColumns={headers}
        customOption={{
          selectableRows: 'multiple',
          onRowSelectionChange,
          rowsSelected: selectedStudents
        }}
      />
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="end">
        <Button onClick={handleRequest} variant="outlined" disabled={selectedStudents.length === 0}>
          Request
        </Button>
        <Button variant="outlined" color="error" onClick={handleClose}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
}

ParticipantDialog.propTypes = {
  participantList: PropTypes.array,
  handleClose: PropTypes.func,
  handleRefresh: PropTypes.func,
  dispatch: PropTypes.func
};

export default connect(() => ({}))(ParticipantDialog);
