import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { IconButton, MenuItem, Stack, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Button, DialogContent, Tooltip } from '@mui/material';
import Email from '@mui/icons-material/Email';
import ReactHtmlParser from 'react-html-parser';
import MuiTable from '../../../../components/CustomComponents/MuiTable';
import headers from './utils/headerVariables';
import { deleteProject, fetchProjectList, startFileCheck } from '../serviceCalls';
import CustomDialog from '../../../../components/CustomComponents/CustomDialog';
import CustomMail from '../../../../components/CustomComponents/CustomMail';
import UpdateDialog from './utils/UpdateDialog';
import StatusDialog from './utils/StatusDialog';
import { PROJECT_MAIL_TYPE } from '../../../../api/RestVariables/staticVariables';
import Page from '../../../../components/Page';
import PaymentDialog from './utils/AddPaymentDialog';
import ViewFileDialog from './Components/ViewFileDialog';
import { setConfirmationAlert, setSnackbar } from '../../../../actions/alertActions';
import ProcessInfoBox from '../../../../components/CustomComponents/CustomCard/ProcessInfoBox';
import { dataMaker } from './_utils/dataMaker';

function Index(props) {
  const { terms, active_term, dispatch, isCommunication } = props;
  const [tableData, setTableData] = useState([]);
  const [term, setTerm] = useState(active_term);
  const [dialogShow, setDialogShow] = useState(false);
  const [fileDialog, setFileDialog] = useState(false);
  const [mailDialogShow, setMailDialogShow] = useState(false);
  const [finalistDialogShow, setFinalistDialogShow] = useState(false);
  const [editData, setEditData] = useState(null);
  const [addMail, setAddMail] = useState(false);
  const [paymentDialogShow, setPaymentDialogShow] = useState(false);
  const [addMoneyData, setAddMoneyData] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [idList, setIdList] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [guidelines, setGuidelines] = useState([]);
  const [fileCheck, setFileCheck] = useState(false);

  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedItems(rowsSelected);
    const arr = [];
    rowsSelected.forEach((item) => {
      arr.push(tableData[item].id);
    });
    setIdList(arr);
  };

  useEffect(() => {
    fetchData();
  }, [term]);
  function fetchData() {
    fetchProjectList(
      { term_id: term },
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        const updatedData = dataMaker(
          list,
          handleEditProject,
          handleDeleteProject,
          viewFile,
          handleChangeFinalistStatus,
          handleAddMoney
        );
        setTableData(updatedData);
      },
      () => {}
    );
  }
  function handleDeleteProject(project_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'This action will delete project',
        () => {
          deleteProject(
            { project_id },
            () => {
              dispatch(setSnackbar('success', 'The project has been deleted'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
              handleRefresh();
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }
  function handleAddMoney(data) {
    setPaymentDialogShow(true);
    setAddMoneyData(data);
  }
  function handleChangeFinalistStatus(project) {
    setFinalistDialogShow(true);
    setEditData(project);
  }
  function handleEditProject(data) {
    dispatch({ type: 'SET_ACTIVE_PROJECT', payload: data.id });
    dispatch({ type: 'SET_ACTIVE_DISCIPLINE', payload: data.discipline_id });
    dispatch({ type: 'SET_ACTIVE_CATEGORY', payload: data.category_id });
    setDialogShow(true);
    setEditData(data);
  }
  function viewFile(file) {
    const obj = {
      id: file.id,
      name: file.name
    };
    setSelectedFile(obj);
    setFileDialog(true);
  }
  function handleStartFileCheck() {
    startFileCheck({ project_ids: idList }, () => {
      dispatch(setSnackbar('success', 'File check started'));
      handleRefresh();
    });
  }
  function TermSelect() {
    return (
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 2 }}>
        <TextField select label="Terms" value={term} onChange={(e) => setTerm(e.target.value)}>
          {terms.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
        {!fileCheck && !addMail && (
          <div className="header-button-style">
            <Button color="secondary" variant="outlined" onClick={() => setFileCheck(true)}>
              Project File Check
            </Button>
          </div>
        )}
        {fileCheck && (
          <div className="header-button-style">
            <Button
              color="primary"
              variant="outlined"
              disabled={startButtonDisabled}
              onClick={handleStartFileCheck}
            >
              Start
            </Button>
            <Button
              variant="outlined"
              color="error"
              style={{ marginLeft: 10 }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        )}
        {addMail && (
          <div>
            <Button
              style={{ marginRight: 5 }}
              disabled={selectedItems.length === 0}
              variant="outlined"
              onClick={() => setMailDialogShow(true)}
            >
              Next
            </Button>
            <Button color="error" variant="outlined" onClick={() => setAddMail(false)}>
              Cancel
            </Button>
          </div>
        )}
      </Stack>
    );
  }

  function handleCancel() {
    setFileCheck(false);
    setSelectedItems([]);
  }
  function handleRefresh() {
    setDialogShow(false);
    setFinalistDialogShow(false);
    setMailDialogShow(false);
    setAddMail(false);
    setEditData(null);
    setSelectedItems([]);
    setPaymentDialogShow(false);
    setFileCheck(false);
    fetchData();
  }
  const startButtonDisabled = selectedItems.length === 0;

  return (
    <Page title="Project Operation | Genius">
      {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
      <MuiTable
        tableData={tableData}
        tableColumns={headers}
        title={<TermSelect />}
        customOption={{
          selectableRows: addMail || fileCheck ? 'multiple' : 'none',
          onRowSelectionChange,
          rowsSelected: selectedItems,
          customToolbar: () =>
            isCommunication !== -1 && (
              <Tooltip title="Send Email">
                <IconButton onClick={() => setAddMail(true)}>
                  <Email />
                </IconButton>
              </Tooltip>
            )
        }}
      />
      <CustomDialog
        onClose={handleRefresh}
        open={dialogShow}
        maxWidth="md"
        title="Edit Project"
        fullWidth
      >
        <DialogContent>
          <UpdateDialog projectId={editData?.id} handleRefresh={handleRefresh} />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        onClose={() => setMailDialogShow(false)}
        open={mailDialogShow}
        maxWidth="lg"
        title="Send Mail"
        fullWidth
        disableEnforceFocus
      >
        <DialogContent>
          <CustomMail
            handleRefresh={handleRefresh}
            mailType={PROJECT_MAIL_TYPE}
            idList={idList}
            handleClose={() => setMailDialogShow(false)}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        onClose={() => setFinalistDialogShow(false)}
        open={finalistDialogShow}
        maxWidth="md"
        title="Finalist Status"
      >
        <DialogContent>
          <StatusDialog
            data={editData}
            handleClose={() => setFinalistDialogShow(false)}
            handleRefresh={handleRefresh}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        onClose={() => setPaymentDialogShow(false)}
        open={paymentDialogShow}
        title="Payment"
      >
        <DialogContent>
          <PaymentDialog
            data={addMoneyData}
            handleRefresh={handleRefresh}
            handleClose={() => setPaymentDialogShow(false)}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        maxWidth="lg"
        fullScreen
        onClose={() => setFileDialog(false)}
        open={fileDialog}
        title="File"
      >
        <DialogContent>
          <ViewFileDialog handleClose={() => setFileDialog(false)} file={selectedFile} />
        </DialogContent>
      </CustomDialog>
    </Page>
  );
}

Index.propTypes = {
  terms: PropTypes.array,
  active_term: PropTypes.number,
  dispatch: PropTypes.func,
  isCommunication: PropTypes.number
};

export default connect((store) => ({
  terms: store.loginReducer.terms,
  active_term: store.loginReducer.active_term,
  isCommunication: store.routeReducer.isCommunication
}))(Index);
