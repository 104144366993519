import { fetchApi } from '../../../../api/fetchApi';

const project = require('../../../../api/RestVariables/Application/project');

export async function fetchGender(config, scb, ecb) {
  try {
    const url = project.GET_GENDER;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'Genders');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function fetchDiscipline(config, scb, ecb) {
  try {
    const url = project.GET_DISCIPLINE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'Discipline');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function fetchDisciplineFileType(config, scb, ecb) {
  try {
    const url = project.GET_DISCIPLINE_FILE_TYPE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_DISCIPLINE_FILE_TYPE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function fetchCategory(config, scb, ecb) {
  try {
    const url = project.GET_CATEGORY;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'Category');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function fetchAffiliatedFair(config, scb, ecb) {
  try {
    const url = project.GET_AFFILIATED_FAIR;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'Affiliated Fair');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getAffiliatedFairReq(config, scb, ecb) {
  try {
    const url = project.GET_AFFILIATED_FAIR_REQ;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_AFFILIATED_FAIR_REQ');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addAffiliatedFair(config, scb, ecb) {
  try {
    const url = project.ADD_AFFILIATED_FAIR;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_AFFILIATED_FAIR');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteAffiliatedFair(config, scb, ecb) {
  try {
    const url = project.DELETE_AFFILIATED_FAIR;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_AFFILIATED_FAIR');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function fetchProjectList(config, scb, ecb) {
  try {
    const url = project.GET_INFO_LIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_INFO_LIST');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getProjectInfo(config, scb, ecb) {
  try {
    const url = project.GET_INFO;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_INFO');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addProjectInfo(config, scb, ecb) {
  try {
    const url = project.ADD_INFO;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_INFO');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateProjectInfo(config, scb, ecb) {
  try {
    const url = project.UPDATE_INFO;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_INFO');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteProject(config, scb, ecb) {
  try {
    const url = project.DELETE_INFO;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_INFO');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getStudentList(config, scb, ecb) {
  try {
    const url = project.GET_STUDENT_LIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_STUDENT_LIST');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getStudent(config, scb, ecb) {
  try {
    const url = project.GET_STUDENT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_STUDENT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addStudent(config, scb, ecb) {
  try {
    const url = project.ADD_STUDENT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_STUDENT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addExistingStudent(config, scb, ecb) {
  try {
    const url = project.ADD_EXISTING_STUDENT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_EXISTING_STUDENT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateStudent(config, scb, ecb) {
  try {
    const url = project.UPDATE_STUDENT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_STUDENT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteStudent(config, scb, ecb) {
  try {
    const url = project.DELETE_STUDENT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_STUDENT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function payment(config, scb, ecb) {
  try {
    const url = project.PAYMENT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'PAYMENT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addFileMeta(config, scb, ecb) {
  const { formData, ...newObj } = config;
  console.log(formData);
  try {
    const url = project.ADD_FILE;
    await fetchApi(
      url,
      newObj,
      (data) => {
        uploadFilesApi(data, config, scb, ecb);
        console.log(data, 'ADD_FILE');
      },
      (error) => {
        ecb();
        console.log(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

const uploadFilesApi = (data, config, scb, ecb) => {
  const { url } = data;

  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': config.file.type },
    body: config.file
  };
  try {
    fetch(url, requestOptions).then((response) => {
      if (response.status === 200) {
        uploadStatusOfFile(
          {
            project_id: config.project_id,
            file_name: config.file_name,
            is_verified: 1
          },
          () => {},
          () => {}
        );
        scb();
      } else {
        uploadStatusOfFile(
          {
            project_id: config.project_id,
            file_name: config.file_name,
            is_verified: 0
          },
          () => {},
          () => {}
        );
        ecb();
      }
    });
  } catch (error) {
    console.log(error);
    ecb();
  }
};

export async function uploadStatusOfFile(config, scb, ecb) {
  try {
    const url = project.UPDATE_FILE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_FILE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getFileMeta(config, scb, ecb) {
  try {
    const url = project.GET_FILE;
    await fetchApi(
      url,
      { file_id: config.id },
      (data) => {
        getFilesApi(data, config, scb, ecb);
        console.log(data, 'ADD_FILE');
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

const getFilesApi = (data, config, scb, ecb) => {
  const { url } = data;
  const extension = url.substring(url.lastIndexOf('.') + 1, url.lastIndexOf('?'));
  const requestOptions = {
    method: 'GET'
  };
  try {
    fetch(url, requestOptions)
      // eslint-disable-next-line consistent-return
      .then((response) => {
        if (response.status === 200) {
          return response.blob();
        }
        ecb(response.status);
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        scb();
        console.log(link);
        link.href = href;
        link.setAttribute('download', `${config.file_name}.${extension}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  } catch (error) {
    console.log(error);
    ecb(error);
  }
};

export async function deleteFileFromDB(config, scb, ecb) {
  try {
    const url = project.DELETE_FILE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_FILE');
      },
      (error) => {
        ecb(error);
        console.log(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getAllFiles(config, scb, ecb) {
  try {
    const url = project.GET_FILE_LIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_FILE_LIST');
      },
      (error) => {
        ecb(error);
        console.log(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getCheckList(config, scb, ecb) {
  try {
    const url = project.GET_CHECK_LIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_CHECK_LIST');
      },
      (error) => {
        ecb(error);
        console.log(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}
