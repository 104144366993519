export default [
  {
    name: 'id',
    label: 'ID'
  },
  {
    name: 'name',
    label: 'Category Name'
  },
  {
    name: 'relatedDiscipline',
    label: 'Related Discipline'
  },
  {
    name: 'allowed_student_count',
    label: 'Allowed Student'
  },
  {
    name: 'is_file_required_comp',
    label: 'File Required',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'is_file_required_filter',
    label: 'File Required',
    options: {
      display: false
    }
  },
  {
    name: 'is_media_link_required_comp',
    label: 'Media Link Required',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'is_media_link_required_filter',
    label: 'Media Link Required',
    options: {
      display: false
    }
  },
  {
    name: 'edit',
    label: 'Edit',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'delete',
    label: 'Delete',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  }
];
