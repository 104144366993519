const HOST = process.env.REACT_APP_REST_API_HOST;

const GET_SCHOOL = {
  method: 'GET',
  path: `${HOST}go_core/school/info`
};
const ADD_SCHOOL = {
  method: 'POST',
  path: `${HOST}go_core/school/info`
};
const UPDATE_SCHOOL = {
  method: 'PUT',
  path: `${HOST}go_core/school/info`
};
const DELETE_SCHOOL = {
  method: 'DELETE',
  path: `${HOST}go_core/school/info`
};
const UPDATE_TRANSFER_SCHOOL = {
  method: 'PUT',
  path: `${HOST}go_core/school/transfer_school`
};

module.exports = {
  GET_SCHOOL,
  ADD_SCHOOL,
  UPDATE_SCHOOL,
  DELETE_SCHOOL,
  UPDATE_TRANSFER_SCHOOL
};
