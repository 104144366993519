import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { IconButton, MenuItem, Stack, TextField } from '@material-ui/core';
import moment from 'moment';
import { Button, DialogContent, Tooltip } from '@mui/material';
import Email from '@mui/icons-material/Email';
import ReactHtmlParser from 'react-html-parser';
import MuiTable from '../../../../components/CustomComponents/MuiTable';
import headers from './utils/headerVariables';
import { getPaymentProject } from '../serviceCalls';
import CustomDialog from '../../../../components/CustomComponents/CustomDialog';
import RefundDialog from './RefundDialog';
import PaymentDialog from './AddPaymentDialog';
import CustomMail from '../../../../components/CustomComponents/CustomMail';
import { CUSTOM_MAIL_TYPE } from '../../../../api/RestVariables/staticVariables';
import Page from '../../../../components/Page';
import { SINGLE_PAYMENT, MULTI_PAYMENT } from '../../../../utils/generalConstants';
import ProcessInfoBox from '../../../../components/CustomComponents/CustomCard/ProcessInfoBox';
import PaymentLinkDialog from './PaymentLinkDialog';

function Index(props) {
  const { terms, active_term, isCommunication } = props;
  const [tableData, setTableData] = useState([]);
  const [term, setTerm] = useState(active_term);
  const [refundDialogShow, setRefundDialogShow] = useState(false);
  const [paymentDialogShow, setPaymentDialogShow] = useState(false);
  const [linkDialogShow, setLinkDialogShow] = useState(false);
  const [refundData, setRefundData] = useState(null);
  const [mailDialogShow, setMailDialogShow] = useState(false);
  const [addMail, setAddMail] = useState(false);
  const [addPayment, setAddPayment] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [idList, setIdList] = useState([]);
  const [sortOrder, setSortOrder] = useState({});
  const [minBalance, setMinBalance] = useState(null);
  const [guidelines, setGuidelines] = useState([]);

  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedItems(rowsSelected);
    const arr = [];
    const balanceList = [];
    rowsSelected.forEach((item) => {
      const balance = parseFloat(tableData[item].balance).toFixed(2);
      balanceList.push(balance);
      arr.push(tableData[item].project_id);
    });
    const minBalance = Math.min(...balanceList);
    setMinBalance(minBalance);
    setIdList(arr);
  };

  useEffect(() => {
    fetchData();
  }, [term]);
  function fetchData() {
    getPaymentProject(
      { term_id: term },
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((item) => {
          const netBalance =
            parseFloat(item.amount).toFixed(2) - parseFloat(item.total_refund).toFixed(2);
          item.transaction_id_comp = item.transaction_id ? item.transaction_id : 'No Transaction';
          item.transaction_date_comp = item.transaction_date
            ? moment(item.transaction_date).format('YYYY-MM-DD / LT')
            : 'No Date';
          item.add_money = (
            <IconButton color="secondary" onClick={() => handleAddMoney(item)}>
              <Icon icon="cil:money" />
            </IconButton>
          );
          item.payment_link = (
            <IconButton color="secondary" onClick={() => handlePaymentLink(item)}>
              <Icon icon="ph:link-simple-horizontal-bold" />
            </IconButton>
          );
          item.refund =
            netBalance > 0 ? (
              <IconButton color="secondary" onClick={() => handleRefund(item)}>
                <Icon icon="ri:refund-2-line" width={24} height={24} />
              </IconButton>
            ) : (
              <IconButton disabled>
                <Icon icon="ri:refund-2-line" width={24} height={24} />
              </IconButton>
            );
        });
        setTableData(list);
      },
      () => {}
    );
  }
  function handleRefund(data) {
    setRefundDialogShow(true);
    setRefundData(data);
  }
  function handleAddMoney(data) {
    setMinBalance(parseFloat(data.balance).toFixed(2));
    setPaymentDialogShow(SINGLE_PAYMENT);
    setRefundData(data);
  }
  function handlePaymentLink(data) {
    setRefundData(data);
    setLinkDialogShow(true);
  }
  function handleRefresh() {
    setRefundDialogShow(false);
    setPaymentDialogShow(false);
    setLinkDialogShow(false);
    setRefundData(null);
    setMailDialogShow(false);
    setAddMail(false);
    setAddPayment(false);
    setSelectedItems([]);
    fetchData();
  }
  function handleCancelMultiSelect() {
    setAddPayment(false);
    setAddMail(false);
    setSelectedItems([]);
  }
  function TermSelect() {
    return (
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 2 }}>
        <TextField select label="Terms" value={term} onChange={(e) => setTerm(e.target.value)}>
          {terms.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
        {!addPayment && !addMail && (
          <div className="header-button-style">
            <Button color="secondary" variant="outlined" onClick={() => setAddPayment(true)}>
              Add Bulk Payment
            </Button>
          </div>
        )}
        {addMail && (
          <div>
            <Button
              style={{ marginRight: 5 }}
              disabled={selectedItems.length === 0}
              variant="outlined"
              onClick={() => setMailDialogShow(true)}
            >
              Next
            </Button>
            <Button color="error" variant="outlined" onClick={handleCancelMultiSelect}>
              Cancel
            </Button>
          </div>
        )}
        {addPayment && (
          <div className="header-button-style">
            <Button
              style={{ marginRight: 5 }}
              disabled={selectedItems.length === 0}
              variant="outlined"
              onClick={() => setPaymentDialogShow(MULTI_PAYMENT)}
            >
              Next
            </Button>
            <Button color="error" variant="outlined" onClick={handleCancelMultiSelect}>
              Cancel
            </Button>
          </div>
        )}
      </Stack>
    );
  }

  return (
    <Page title="Project Payment | Genius">
      {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
      <MuiTable
        tableData={tableData}
        tableColumns={headers}
        title={<TermSelect />}
        customOption={{
          selectableRows: addMail || addPayment ? 'multiple' : 'none',
          isRowSelectable: (dataIndex) => parseFloat(tableData[dataIndex].balance).toFixed(2) > 0,
          onRowSelectionChange,
          rowsSelected: selectedItems,
          onColumnSortChange: (changedColumn, direction) => {
            setSortOrder({ name: changedColumn, direction });
          },
          sortOrder,
          customToolbar: () =>
            isCommunication !== -1 && (
              <Tooltip title="Send Email">
                <IconButton onClick={() => setAddMail(true)}>
                  <Email />
                </IconButton>
              </Tooltip>
            )
        }}
      />
      <CustomDialog
        maxWidth="md"
        onClose={() => setRefundDialogShow(false)}
        open={refundDialogShow}
        title="Refund"
      >
        <DialogContent>
          <RefundDialog
            data={refundData}
            handleRefresh={handleRefresh}
            handleClose={() => setRefundDialogShow(false)}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        onClose={() => setPaymentDialogShow(false)}
        open={paymentDialogShow}
        title={paymentDialogShow === SINGLE_PAYMENT ? 'Single Payment' : 'Bulk Payment'}
      >
        <DialogContent>
          <PaymentDialog
            minBalance={minBalance}
            projectIDS={idList}
            mode={paymentDialogShow}
            data={refundData}
            handleRefresh={handleRefresh}
            handleClose={() => setPaymentDialogShow(false)}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        onClose={() => setLinkDialogShow(false)}
        open={linkDialogShow}
        title="Payment Link"
      >
        <DialogContent>
          <PaymentLinkDialog
            data={refundData}
            handleRefresh={handleRefresh}
            handleClose={() => setLinkDialogShow(false)}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        onClose={() => setMailDialogShow(false)}
        open={mailDialogShow}
        maxWidth="lg"
        title="Send Mail"
        fullWidth
        disableEnforceFocus
      >
        <DialogContent>
          <CustomMail
            handleRefresh={handleRefresh}
            mailType={CUSTOM_MAIL_TYPE}
            idList={idList}
            handleClose={() => setMailDialogShow(false)}
          />
        </DialogContent>
      </CustomDialog>
    </Page>
  );
}

Index.propTypes = {
  terms: PropTypes.array,
  active_term: PropTypes.number,
  isCommunication: PropTypes.number
};

export default connect((store) => ({
  terms: store.loginReducer.terms,
  active_term: store.loginReducer.active_term,
  isCommunication: store.routeReducer.isCommunication
}))(Index);
