import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React from 'react';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { IconButton, Typography } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import { setConfirmationAlert, setSnackbar } from '../../actions/alertActions';
import { changeUserRole } from './utils/serviceCalls';
import { handleGetActiveTerm, handleGetTerms, refreshToken } from '../../actions/loginActions';
// ----------------------------------------------------------------------
const APPLICANT = 2;
const FAIR = 3;
const REVIEWER = 4;

function RoleCard(props) {
  const { roles, dispatch } = props;
  const navigate = useNavigate();

  function handleChangeDefaultRole(role_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, CHANGE',
        'This action will set default user role',
        () => {
          changeUserRole(
            { role_id },
            () => {
              dispatch(setSnackbar('success', 'The default user role has been set'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
              dispatch(
                refreshToken(
                  () => {},
                  (data) => {
                    dispatch(handleGetActiveTerm());
                    const isProfileOK = Object.keys(data.user_profile).length !== 0;
                    if (!isProfileOK) {
                      navigate('/profile', { replace: true });
                    } else if (data.user_role === FAIR) {
                      navigate('/fair', { replace: true });
                    } else if (data.user_role === APPLICANT) {
                      navigate('/dashboard', { replace: true });
                    } else if (data.user_role === REVIEWER) {
                      navigate('/reviewer', { replace: true });
                    } else {
                      dispatch(handleGetTerms());
                      navigate('/admin', { replace: true });
                    }
                  }
                )
              );
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        User Roles
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow style={{ background: 'gainsboro' }}>
              <TableCell>ID</TableCell>
              <TableCell>Role Name</TableCell>
              <TableCell>Default Role</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {roles.map((row, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.role_id}
                </TableCell>
                <TableCell>{row.role_name}</TableCell>
                <TableCell>
                  {row.is_default ? (
                    <IconButton color="success">
                      <Icon icon="akar-icons:circle-check" width={24} height={24} />
                    </IconButton>
                  ) : (
                    <Tooltip title="Make Default Role">
                      <IconButton
                        color="error"
                        onClick={() => handleChangeDefaultRole(row.role_id)}
                      >
                        <Icon color="error" icon="akar-icons:circle-x" width={24} height={24} />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

RoleCard.propTypes = {
  dispatch: PropTypes.func,
  roles: PropTypes.array
};

export default connect(() => ({}))(RoleCard);
