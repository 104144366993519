import { IconButton } from '@material-ui/core';
import { Icon } from '@iconify/react';
import React from 'react';
import { Stack } from '@mui/material';

export function dataMaker(
  list,
  handleChangeFinalistStatus,
  handleChangeReviewStatus,
  handleChangeCarbonScore
) {
  list.forEach((item, index) => {
    const carbonScore = item.carbon_score === null ? '-' : item.carbon_score;
    item.team_name_comp = item.team_name ? item.team_name : '-';
    item.contest_name_comp = item.contest_name ? item.contest_name : '-';
    item.contest_type_name_comp = item.contest_type_name ? item.contest_type_name : '-';
    item.rank_comp = item.rank ? item.rank : '-';
    item.num_solved_comp = item.num_solved ? item.num_solved : '-';
    item.total_time_comp = item.total_time ? item.total_time : '-';
    item.carbonComp = (
      <Stack
        direction="row"
        spacing={1}
        style={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          justifyContent: 'center'
        }}
      >
        <p> {carbonScore} </p>
        <IconButton color="secondary" onClick={() => handleChangeCarbonScore(item)}>
          <Icon icon="ci:edit" width={16} height={16} />
        </IconButton>
      </Stack>
    );
    item.finalist_status_comp = (
      <IconButton color="secondary" onClick={() => handleChangeFinalistStatus(item)}>
        <Icon icon="ic:twotone-published-with-changes" width={24} height={24} />
      </IconButton>
    );
    item.review_status_comp = (
      <IconButton color="secondary" onClick={() => handleChangeReviewStatus(index)}>
        <Icon icon="ic:twotone-published-with-changes" width={24} height={24} />
      </IconButton>
    );
  });
  return list;
}
