import { fetchApi } from '../../../../api/fetchApi';

const service = require('./apis');

export async function getPaymentList(config, scb, ecb) {
  try {
    const url = service.GET_PAYMENT_LIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_PAYMENT_LIST');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addProjectPayment(config, scb, ecb) {
  try {
    const url = service.ADD_PROJECT_PAYMENT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_PROJECT_PAYMENT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addParticipantPayment(config, scb, ecb) {
  try {
    const url = service.ADD_PARTICIPANT_PAYMENT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_PARTICIPANT_PAYMENT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addTripPayment(config, scb, ecb) {
  try {
    const url = service.ADD_TRIP_PAYMENT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_TRIP_PAYMENT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function makeInvoice(config, scb, ecb) {
  try {
    const url = service.MAKE_INVOICE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'MAKE_INVOICE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}
