import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Grid, Stack } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import Page from '../../../components/Page';
import { getPaymentList } from './_apis/serviceCalls';
import ProcessInfoBox from '../../../components/CustomComponents/CustomCard/ProcessInfoBox';
import { dataMakerForParticipant, dataMakerForProject, dataMakerForTrip } from './_utils/dataMaker';
import Project from './Components/Project';
import Participant from './Components/Participant';
import Trip from './Components/Trip';

function Payment({ dispatch, idList }) {
  const [projectList, setProjectList] = useState([]);
  const [participantList, setParticipantList] = useState([]);
  const [tripList, setTripList] = useState([]);
  const [guidelines, setGuidelines] = useState([]);

  useEffect(() => {
    dispatch({
      type: 'SET_RECEIPT_BUTTON_VISIBILITY',
      payload: true
    });
    fetchData();
  }, []);

  useEffect(
    () => () =>
      dispatch({
        type: 'CLEAR_ALL_HEADER',
        payload: false
      }),
    []
  );

  useEffect(() => {
    if (
      idList.project.length === 0 &&
      idList.participant.length === 0 &&
      idList.trip.length === 0
    ) {
      dispatch({
        type: 'SET_RECEIPT_BUTTON_ENABALTY',
        payload: true
      });
    } else {
      dispatch({
        type: 'SET_RECEIPT_BUTTON_ENABALTY',
        payload: false
      });
    }
  }, [idList]);

  function fetchData() {
    getPaymentList(
      {},
      (data) => {
        setGuidelines(data.guidelines);
        setProjectList(dataMakerForProject(data.data));
        setParticipantList(dataMakerForParticipant(data.data));
        setTripList(dataMakerForTrip(data.data));
      },
      () => {}
    );
  }

  return (
    <Page title="Payment | Genius">
      <Stack spacing={3}>
        <Grid item xs={12}>
          {guidelines.length > 0 && (
            <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />
          )}
        </Grid>
        <Project projectList={projectList} />
        <Participant participantList={participantList} />
        <Trip tripList={tripList} />
      </Stack>
    </Page>
  );
}

Payment.propTypes = {
  dispatch: PropTypes.func.isRequired,
  idList: PropTypes.object
};

export default connect((store) => ({
  idList: store.headerReducer.idList
}))(Payment);
