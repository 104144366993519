import { Checkbox, Stack, TextField } from '@material-ui/core';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@material-ui/lab';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as React from 'react';
import moment from 'moment';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useState } from 'react';
import { addTerm, updateTerm } from '../serviceCalls';
import { setSnackbar } from '../../../../actions/alertActions';

const ADD_TERM = 0;

function AddUpdateDialog(props) {
  const { data, mode, handleClose, dispatch, refreshData } = props;
  const [isOnline, setIsOnline] = useState(mode === ADD_TERM ? false : data.is_online === 1);

  const Schema = Yup.object().shape({
    id: Yup.number().required('ID required'),
    description: Yup.string()
      .min(2, 'Too Short!')
      .max(500, 'Too Long!')
      .required('Description required'),
    application_start_date: Yup.date().required('Application Start Date required'),
    application_end_date: Yup.date().required('Application End Date required'),
    application_fair_end_date: Yup.date().required('Application Fair End Date required'),
    registration_end_date: Yup.date().required('Registration End Date required'),
    invitation_end_date: Yup.date().required('Invitation End Date required'),
    trip_end_date: Yup.date().required('Trip End Date required'),
    presentation_end_date: Yup.date().required('Presentation End Date required'),
    event_start_date: Yup.date().required('Event Start Date required'),
    event_end_date: Yup.date().required('Event End Date required')
  });

  const formik = useFormik({
    initialValues: {
      id: mode === ADD_TERM ? '' : data.id,
      description: mode === ADD_TERM ? '' : data.description,
      application_start_date:
        mode === ADD_TERM ? '' : moment(data.application_start_date).format('YYYY-MM-DD'),
      application_end_date:
        mode === ADD_TERM ? '' : moment(data.application_end_date).format('YYYY-MM-DD'),
      application_fair_end_date:
        mode === ADD_TERM ? '' : moment(data.application_fair_end_date).format('YYYY-MM-DD'),
      registration_end_date:
        mode === ADD_TERM ? '' : moment(data.registration_end_date).format('YYYY-MM-DD'),
      invitation_end_date:
        mode === ADD_TERM ? '' : moment(data.invitation_end_date).format('YYYY-MM-DD'),
      trip_end_date: mode === ADD_TERM ? '' : moment(data.trip_end_date).format('YYYY-MM-DD'),
      presentation_end_date:
        mode === ADD_TERM ? '' : moment(data.presentation_end_date).format('YYYY-MM-DD'),
      event_start_date: mode === ADD_TERM ? '' : moment(data.event_start_date).format('YYYY-MM-DD'),
      event_end_date: mode === ADD_TERM ? '' : moment(data.event_end_date).format('YYYY-MM-DD')
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      const param = { ...values, is_online: isOnline ? 1 : 0 };
      if (mode === ADD_TERM) {
        addTerm(
          param,
          () => {
            refreshData();
            dispatch(setSnackbar('success', 'The term has been added'));
          },
          () => {}
        );
      } else {
        updateTerm(
          param,
          () => {
            refreshData();
            dispatch(setSnackbar('success', 'The term has been updated'));
          },
          () => {}
        );
      }
    }
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack spacing={3} sx={{ mt: 2 }} direction={{ xs: 'column', sm: 'row' }}>
            <TextField
              disabled={mode !== ADD_TERM}
              fullWidth
              label="ID"
              {...getFieldProps('id')}
              error={Boolean(touched.id && errors.id)}
              helperText={touched.id && errors.id}
            />
            <TextField
              fullWidth
              label="Description"
              {...getFieldProps('description')}
              error={Boolean(touched.description && errors.description)}
              helperText={touched.description && errors.description}
            />
          </Stack>
          <Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              type="date"
              label="Application Start Date"
              {...getFieldProps('application_start_date')}
              error={Boolean(touched.application_start_date && errors.application_start_date)}
              helperText={touched.application_start_date && errors.application_start_date}
            />
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              type="date"
              label="Application End Date"
              {...getFieldProps('application_end_date')}
              error={Boolean(touched.application_end_date && errors.application_end_date)}
              helperText={touched.application_end_date && errors.application_end_date}
            />
          </Stack>
          <Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              type="date"
              label="Application Fair End Date"
              {...getFieldProps('application_fair_end_date')}
              error={Boolean(touched.application_fair_end_date && errors.application_fair_end_date)}
              helperText={touched.application_fair_end_date && errors.application_fair_end_date}
            />
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              type="date"
              label="Registration End Date"
              {...getFieldProps('registration_end_date')}
              error={Boolean(touched.registration_end_date && errors.registration_end_date)}
              helperText={touched.registration_end_date && errors.registration_end_date}
            />
          </Stack>
          <Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              type="date"
              label="Invitation Letter End Date"
              {...getFieldProps('invitation_end_date')}
              error={Boolean(touched.invitation_end_date && errors.invitation_end_date)}
              helperText={touched.invitation_end_date && errors.invitation_end_date}
            />
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              type="date"
              label="Trip End Date"
              {...getFieldProps('trip_end_date')}
              error={Boolean(touched.trip_end_date && errors.trip_end_date)}
              helperText={touched.trip_end_date && errors.trip_end_date}
            />
          </Stack>
          <Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              type="date"
              label="Event Start Date"
              {...getFieldProps('event_start_date')}
              error={Boolean(touched.event_start_date && errors.event_start_date)}
              helperText={touched.event_start_date && errors.event_start_date}
            />
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              type="date"
              label="Event End Date"
              {...getFieldProps('event_end_date')}
              error={Boolean(touched.event_end_date && errors.event_end_date)}
              helperText={touched.event_end_date && errors.event_end_date}
            />
          </Stack>
          <Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
            <TextField
              InputLabelProps={{ shrink: true }}
              type="date"
              label="Presentation Fair End Date"
              {...getFieldProps('presentation_end_date')}
              error={Boolean(touched.presentation_end_date && errors.presentation_end_date)}
              helperText={touched.presentation_end_date && errors.presentation_end_date}
            />
            <FormControlLabel
              control={
                <Checkbox checked={isOnline} onChange={(e) => setIsOnline(e.target.checked)} />
              }
              label="Is Online"
            />
          </Stack>
          <Stack direction="row" justifyContent="end" spacing={1}>
            <LoadingButton size="small" type="submit" variant="outlined">
              {mode === ADD_TERM ? 'Submit' : 'Update'}
            </LoadingButton>
            <Button size="small" color="error" onClick={handleClose} variant="outlined">
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

AddUpdateDialog.propTypes = {
  data: PropTypes.object,
  mode: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired
};

export default connect(() => ({}))(AddUpdateDialog);
