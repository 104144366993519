import React, { useEffect, useState } from 'react';
import { IconButton } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { Button, Stack } from '@mui/material';
import MuiTable from '../../../components/CustomComponents/MuiTable';
import headers from './_utils/headerVariables';
import { setConfirmationAlert, setLoading, setSnackbar } from '../../../actions/alertActions';
import Page from '../../../components/Page';
import ProcessInfoBox from '../../../components/CustomComponents/CustomCard/ProcessInfoBox';
import { deleteDocument, getDocument, getDocumentList } from './_apis/serviceCalls';
import AddUpdateDialog from './Dialogs/AddUpdateDialog';

function Index(props) {
  const { dispatch } = props;
  const [tableData, setTableData] = useState([]);
  const [guidelines, setGuidelines] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [dialogShow, setDialogShow] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    getDocumentList(
      {},
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((item) => {
          item.download = (
            <IconButton color="primary" onClick={() => handleDownload(item)}>
              <Icon icon="akar-icons:cloud-download" width={24} height={24} />
            </IconButton>
          );
          item.delete = (
            <IconButton
              color="secondary"
              disabled={item.trip_id === null}
              onClick={() => handleDeleteDocument(item.id)}
            >
              <Icon icon="fluent:delete-48-filled" width={24} height={24} />
            </IconButton>
          );
        });
        setTableData(list);
      },
      () => {}
    );
  }
  function handleDownload(config) {
    dispatch(setLoading(1, 'Downloading...'));
    getDocument(config, () => {}).then(() => dispatch(setLoading(0)));
  }
  function handleDeleteDocument(document_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'This action will delete document',
        () => {
          deleteDocument(
            { document_id },
            () => {
              dispatch(setSnackbar('success', 'The document has been deleted'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
              fetchData();
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          ).then(() => {});
        }
      )
    );
  }

  function handleClose() {
    setDialogShow(false);
    setSelectedDocument(null);
  }

  return (
    <Page title="Document Operation | Genius">
      <Stack spacing={2}>
        {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
        <MuiTable
          tableData={tableData}
          tableColumns={headers}
          title={
            <Button variant="outlined" onClick={() => setDialogShow(true)}>
              Add New
            </Button>
          }
        />
      </Stack>
      {dialogShow && (
        <AddUpdateDialog
          selectedDocument={selectedDocument}
          handleClose={handleClose}
          refreshData={fetchData}
        />
      )}
    </Page>
  );
}

Index.propTypes = {
  dispatch: PropTypes.func
};

export default connect(() => ({}))(Index);
