import PropTypes from 'prop-types';
import * as React from 'react';
import { Button, Stack } from '@mui/material';

/*
    This view includes buttons to add new sub question
     and change order of the sub questions
*/

function QuestionsHeader(props) {
  const {
    rankUpdateAvailable,
    handleUpdateQuestionRank,
    updateRankLoading,
    cancelDragAndDrop,
    setAddQuestionDialogShow,
    questionList,
    setRankUpdateAvailable
  } = props;

  return rankUpdateAvailable ? (
    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} sx={{ mt: 1 }}>
      <Button onClick={handleUpdateQuestionRank} loading={updateRankLoading} variant="outlined">
        Order
      </Button>
      <Button color="secondary" onClick={cancelDragAndDrop} variant="outlined">
        Cancel
      </Button>
    </Stack>
  ) : (
    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} sx={{ mt: 1 }}>
      <Button onClick={() => setAddQuestionDialogShow(true)} variant="outlined">
        Add New Question
      </Button>
      {questionList && questionList.length > 1 && (
        <Button
          variant="outlined"
          tooltip="Change the order of questions"
          color="secondary"
          onClick={() => setRankUpdateAvailable(true)}
        >
          Change Order
        </Button>
      )}
    </Stack>
  );
}

QuestionsHeader.propTypes = {
  rankUpdateAvailable: PropTypes.bool,
  handleUpdateQuestionRank: PropTypes.func,
  updateRankLoading: PropTypes.bool,
  cancelDragAndDrop: PropTypes.func,
  setAddQuestionDialogShow: PropTypes.func,
  questionList: PropTypes.array,
  setRankUpdateAvailable: PropTypes.func
};

export default QuestionsHeader;
