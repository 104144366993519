import { Stack } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { setLoading, setSnackbar } from '../../../../../../actions/alertActions';
import MuiTable from '../../../../../../components/CustomComponents/MuiTable';
import headers from './headerVariables';
import { assignJudgingProject, getJudgingProjectJudge } from '../../_apis/serviceCalls';

function AddJudgeDialog(props) {
  const { data, handleClose, dispatch, refreshData } = props;
  const [projectList, setProjectList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [idList, setIdList] = useState([]);

  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedItems(rowsSelected);
    const arr = [];
    rowsSelected.forEach((item) => {
      arr.push(projectList[item].judge_id);
    });
    setIdList(arr);
  };
  useEffect(() => {
    getJudgingProjectJudge(
      { project_id: data.id },
      (data) => {
        setProjectList(data);
      },
      () => {}
    );
  }, []);

  function handleAssignJudges() {
    dispatch(setLoading(1, 'Loading...'));
    assignJudgingProject(
      { project_id: data.id, judge_ids: idList },
      () => {
        dispatch(setLoading(0, ''));
        refreshData();
        dispatch(setSnackbar('success', 'The judge(s) has been added to group'));
      },
      () => {
        dispatch(setLoading(0, ''));
      }
    );
  }

  return (
    <Stack spacing={3}>
      <MuiTable
        tableColumns={headers}
        tableData={projectList}
        rowsPerPage={10}
        customOption={{
          selectableRows: 'multiple',
          selectableRowsOnClick: true,
          onRowSelectionChange,
          rowsSelected: selectedItems
        }}
      />
      <Stack direction="row" justifyContent="end" spacing={1}>
        <LoadingButton size="small" onClick={handleAssignJudges} variant="outlined">
          Assign
        </LoadingButton>
        <Button size="small" color="error" onClick={handleClose} variant="outlined">
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
}

AddJudgeDialog.propTypes = {
  data: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired
};

export default connect(() => ({}))(AddJudgeDialog);
