const HOST = process.env.REACT_APP_REST_API_HOST;

const GET_SESSION = {
  method: 'GET',
  path: `${HOST}go_admin/judge_operation/session`
};
const ADD_SESSION = {
  method: 'POST',
  path: `${HOST}go_admin/judge_operation/session`
};
const UPDATE_SESSION = {
  method: 'PUT',
  path: `${HOST}go_admin/judge_operation/session`
};
const DELETE_SESSION = {
  method: 'DELETE',
  path: `${HOST}go_admin/judge_operation/session`
};
const GET_SESSION_LIST = {
  method: 'GET',
  path: `${HOST}go_admin/judge_operation/session/list`
};
const GET_SESSION_TYPE = {
  method: 'GET',
  path: `${HOST}go_admin/judge_operation/session/type`
};
const COPY_SESSION = {
  method: 'POST',
  path: `${HOST}go_admin/judge_operation/session/copy`
};
const GET_JUDGE_LIST = {
  method: 'GET',
  path: `${HOST}go_admin/judge_operation/judge/list`
};
const GET_JUDGE_STATUS = {
  method: 'GET',
  path: `${HOST}go_admin/judge_operation/judge/status`
};
const UPDATE_JUDGE_STATUS = {
  method: 'PATCH',
  path: `${HOST}go_admin/judge_operation/judge/status`
};
const DELETE_JUDGE = {
  method: 'DELETE',
  path: `${HOST}go_admin/judge_operation/judge`
};
const UPDATE_JUDGE = {
  method: 'PUT',
  path: `${HOST}go_admin/judge_operation/judge`
};
const ADD_CERTIFICATE = {
  method: 'POST',
  path: `${HOST}go_admin/judge_operation/judge/certificate`
};
const DOWNLOAD_CERTIFICATE = {
  method: 'GET',
  path: `${HOST}go_admin/judge_operation/judge/certificate`
};
const GET_GROUP = {
  method: 'GET',
  path: `${HOST}go_admin/judge_operation/group`
};
const ADD_GROUP = {
  method: 'POST',
  path: `${HOST}go_admin/judge_operation/group`
};
const UPDATE_GROUP = {
  method: 'PUT',
  path: `${HOST}go_admin/judge_operation/group`
};
const DELETE_GROUP = {
  method: 'DELETE',
  path: `${HOST}go_admin/judge_operation/group`
};
const GET_GROUP_LIST = {
  method: 'GET',
  path: `${HOST}go_admin/judge_operation/group/list`
};
const GET_GROUP_SESSION_LIST = {
  method: 'GET',
  path: `${HOST}go_admin/judge_operation/group/session_list`
};
const GET_GROUP_GROUP_PROJECT = {
  method: 'GET',
  path: `${HOST}go_admin/judge_operation/group/group_project`
};
const GET_GROUP_PROJECT = {
  method: 'GET',
  path: `${HOST}go_admin/judge_operation/group/project`
};
const ADD_GROUP_PROJECT = {
  method: 'POST',
  path: `${HOST}go_admin/judge_operation/group/project`
};
const DELETE_GROUP_PROJECT = {
  method: 'DELETE',
  path: `${HOST}go_admin/judge_operation/group/project`
};
const GET_AVAILABLE_GROUP = {
  method: 'GET',
  path: `${HOST}go_admin/judge_operation/group/available_group`
};
const GET_PROJECT_LIST = {
  method: 'GET',
  path: `${HOST}go_admin/judge_operation/group/project_list`
};
const GROUP_ASSIGN_ALL = {
  method: 'POST',
  path: `${HOST}go_admin/judge_operation/group/assign_all`
};
const SESSION_LIST_FOR_JUDGE = {
  method: 'GET',
  path: `${HOST}go_admin/judge_operation/judge/session_list`
};
const FETCH_SESSION_PROJECT_LIST = {
  method: 'GET',
  path: `${HOST}go_admin/judge_operation/session/project_list`
};
const FETCH_SESSION_PROJECT_SESSION_LIST = {
  method: 'GET',
  path: `${HOST}go_admin/judge_operation/session/project_session`
};
const UPDATE_SESSION_IN_SESSION = {
  method: 'PATCH',
  path: `${HOST}go_admin/judge_operation/session/project_session`
};
const UNASSIGN_PROJECTS = {
  method: 'DELETE',
  path: `${HOST}go_admin/judge_operation/judging/judge/all_projects`
};

module.exports = {
  GET_SESSION,
  ADD_SESSION,
  UPDATE_SESSION,
  DELETE_SESSION,
  GET_SESSION_LIST,
  GET_SESSION_TYPE,
  COPY_SESSION,
  GET_JUDGE_LIST,
  GET_JUDGE_STATUS,
  UPDATE_JUDGE_STATUS,
  DELETE_JUDGE,
  UPDATE_JUDGE,
  GET_GROUP,
  ADD_GROUP,
  UPDATE_GROUP,
  DELETE_GROUP,
  GET_GROUP_LIST,
  GET_GROUP_SESSION_LIST,
  GET_GROUP_GROUP_PROJECT,
  GET_GROUP_PROJECT,
  ADD_GROUP_PROJECT,
  DELETE_GROUP_PROJECT,
  GET_AVAILABLE_GROUP,
  GET_PROJECT_LIST,
  GROUP_ASSIGN_ALL,
  ADD_CERTIFICATE,
  DOWNLOAD_CERTIFICATE,
  SESSION_LIST_FOR_JUDGE,
  FETCH_SESSION_PROJECT_LIST,
  FETCH_SESSION_PROJECT_SESSION_LIST,
  UPDATE_SESSION_IN_SESSION,
  UNASSIGN_PROJECTS
};
