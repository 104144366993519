import * as React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import { Divider, Typography } from '@material-ui/core';
import CustomDialog, { DialogContent } from '../../components/CustomComponents/CustomDialog';

function HelpDialog(props) {
  const { closeDialog } = props;

  function getId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  }
  const videoIdAccountCreation = getId('https://youtu.be/pyiplQwQqNs');
  const videoIdPasswordRecovery = getId('https://youtu.be/6GGz3uGFP9Y');

  return (
    <CustomDialog open onClose={closeDialog} maxWidth="sm" fullWidth title="Help">
      <DialogContent>
        <Stack direction="column" spacing={1}>
          <Stack direction="column" style={{ width: '100%' }}>
            <Typography variant="h5" gutterBottom>
              How to create an account?
            </Typography>
            <div className="video-responsive">
              <iframe
                width="853"
                height="480"
                src={`https://www.youtube.com/embed/${videoIdAccountCreation}`}
                frameBorder="0"
                allowFullScreen
                title="Embedded youtube"
              />
            </div>
          </Stack>
          <Divider />
          <Stack direction="column" style={{ width: '100%' }}>
            <Typography variant="h5" gutterBottom>
              How to recover your password?
            </Typography>
            <div className="video-responsive">
              <iframe
                width="853"
                height="480"
                src={`https://www.youtube.com/embed/${videoIdPasswordRecovery}`}
                frameBorder="0"
                allowFullScreen
                title="Embedded youtube"
              />
            </div>
          </Stack>
        </Stack>
      </DialogContent>
    </CustomDialog>
  );
}

HelpDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired
};

export default HelpDialog;
