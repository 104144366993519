import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Stack } from '@mui/material';
import Judgings from './Components/Judgings';
import Robotics from './Components/Robotics';

const JudgingDetailDialog = (props) => {
  const { handleClose, data, deleteJudging, deleteRobotics } = props;

  return (
    <Stack spacing={2}>
      <Judgings data={data.judgings} deleteJudging={deleteJudging} />
      {data.robotics_competitions.length > 0 && (
        <Robotics data={data.robotics_competitions} deleteRobotics={deleteRobotics} />
      )}
      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="end" spacing={2}>
        <Button variant="outlined" color="error" onClick={handleClose}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};

JudgingDetailDialog.propTypes = {
  data: PropTypes.array,
  handleClose: PropTypes.func.isRequired,
  deleteJudging: PropTypes.func.isRequired,
  deleteRobotics: PropTypes.func.isRequired
};

export default connect(() => ({}))(JudgingDetailDialog);
