import React, { useEffect, useState } from 'react';
import { DialogContent, Stack } from '@mui/material';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { Icon } from '@iconify/react';
import { IconButton } from '@material-ui/core';
import MuiTable from '../../../../components/CustomComponents/MuiTable';
import headers from './_utils/headerVariables';
import Page from '../../../../components/Page';
import ProcessInfoBox from '../../../../components/CustomComponents/CustomCard/ProcessInfoBox';
import { getEmailHistory } from '../Template/serviceCalls';
import ViewDialog from '../Template/utils/ViewDialog';
import CustomDialog from '../../../../components/CustomComponents/CustomDialog';

function Report() {
  const [tableData, setTableData] = useState([]);
  const [guidelines, setGuidelines] = useState([]);
  const [viewDialogShow, setViewDialogShow] = useState(false);
  const [tempData, setTempData] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);
  function fetchData() {
    getEmailHistory(
      {},
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((item) => {
          item.date = new Date(item.send_date).toLocaleDateString();
          item.view = (
            <IconButton color="secondary" onClick={() => handleView(item)}>
              <Icon icon="ep:view" />
            </IconButton>
          );
        });
        setTableData(list);
      },
      () => {}
    );
  }

  function handleView(temp) {
    setTempData(temp);
    setViewDialogShow(true);
  }

  return (
    <Page title="Communication | Genius">
      <Stack spacing={2}>
        {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
        <MuiTable tableData={tableData} tableColumns={headers} />
      </Stack>
      <CustomDialog
        maxWidth="lg"
        fullWidth
        onClose={() => setViewDialogShow(false)}
        open={viewDialogShow}
        title="Template View"
      >
        <DialogContent>
          <ViewDialog data={tempData} handleClose={() => setViewDialogShow(false)} />
        </DialogContent>
      </CustomDialog>
    </Page>
  );
}

export default connect(() => ({}))(Report);
