import user_management from '../../../api/RestVariables/Admin/user_management';
import { fetchApi } from '../../../api/fetchApi';

export async function fetchRolesList(config, scb, ecb) {
  try {
    const url = user_management.GET_ROLE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_ROLE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function fetchFeaturesList(config, scb, ecb) {
  try {
    const url = user_management.GET_FEATURE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_FEATURE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function fetchRoleFeatureList(config, scb, ecb) {
  try {
    const url = user_management.GET_ROLE_FEATURE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_ROLE_FEATURE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addRoleFeature(config, scb, ecb) {
  try {
    const url = user_management.ADD_ROLE_FEATURE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_ROLE_FEATURE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateRoleFeature(config, scb, ecb) {
  try {
    const url = user_management.SET_ROLE_FEATURE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_ROLE_FEATURE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function copyRole(config, scb, ecb) {
  try {
    const url = user_management.COPY_ROLE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'COPY_ROLE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addRole(config, scb, ecb) {
  try {
    const url = user_management.ADD_ROLE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_ROLE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateRole(config, scb, ecb) {
  try {
    const url = user_management.UPDATE_ROLE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_ROLE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteRole(config, scb, ecb) {
  try {
    const url = user_management.DELETE_ROLE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_ROLE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function fetchUsers(config, scb, ecb) {
  try {
    const url = user_management.GET_USER;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_USER');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addUser(config, scb, ecb) {
  try {
    const url = user_management.ADD_USER;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_USER');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateUser(config, scb, ecb) {
  try {
    const url = user_management.UPDATE_USER;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_USER');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteUser(config, scb, ecb) {
  try {
    const url = user_management.DELETE_USER;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_USER');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function revokeUser(config, scb, ecb) {
  try {
    const url = user_management.REVOKE_USER;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'REVOKE_USER');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function unlockUser(config, scb, ecb) {
  try {
    const url = user_management.UNLOCK_USER;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UNLOCK_USER');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getUserRole(config, scb, ecb) {
  try {
    const url = user_management.GET_USER_ROLE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'REVOKE_USER');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addUserRole(config, scb, ecb) {
  try {
    const url = user_management.ADD_USER_ROLE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_USER_ROLE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteUserRole(config, scb, ecb) {
  try {
    const url = user_management.DELETE_USER_ROLE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_USER_ROLE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function patchUserRole(config, scb, ecb) {
  try {
    const url = user_management.SET_USER_DEFAULT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'SET_USER_DEFAULT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function resetPassword(config, scb, ecb) {
  try {
    const url = user_management.RESET_PASSWORD;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'RESET_PASSWORD');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function verifyUser(config, scb, ecb) {
  try {
    const url = user_management.VERIFY_USER;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'VERIFY_USER');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}
