const HOST = process.env.REACT_APP_REST_API_HOST;

const GET_JUDGING_GROUP = {
  method: 'GET',
  path: `${HOST}go_admin/judge_operation/judging/group`
};
const JUDGING_ASSIGN = {
  method: 'POST',
  path: `${HOST}go_admin/judge_operation/judging/assign`
};
const GET_JUDGING_GROUP_LIST = {
  method: 'GET',
  path: `${HOST}go_admin/judge_operation/judging/group/judge_list`
};
const GET_JUDGING_GROUP_PROJECT = {
  method: 'GET',
  path: `${HOST}go_admin/judge_operation/judging/group/project`
};
const GET_JUDGING_JUDGE = {
  method: 'GET',
  path: `${HOST}go_admin/judge_operation/judging/judge`
};
const GET_JUDGING_JUDGE_PROJECT = {
  method: 'GET',
  path: `${HOST}go_admin/judge_operation/judging/judge/project`
};
const ADD_JUDGING_JUDGE_PROJECT = {
  method: 'POST',
  path: `${HOST}go_admin/judge_operation/judging/judge/project`
};
const ADD_CERTIFICATE_TO_JUDGING = {
  method: 'POST',
  path: `${HOST}go_admin/judge_operation/judging/judge/certificate`
};
const DELETE_JUDGING_JUDGE_PROJECT = {
  method: 'DELETE',
  path: `${HOST}go_admin/judge_operation/judging/judge/project`
};
const GET_JUDGING_JUDGE_AVAILABLE_PROJECT = {
  method: 'GET',
  path: `${HOST}go_admin/judge_operation/judging/judge/available_project`
};
const GET_JUDGING_PROJECT = {
  method: 'GET',
  path: `${HOST}go_admin/judge_operation/judging/project`
};
const GET_JUDGING_PROJECT_BADGE = {
  method: 'POST',
  path: `${HOST}go_admin/judge_operation/judging/project/badge`
};
const GET_JUDGING_PROJECT_DETAIL = {
  method: 'GET',
  path: `${HOST}go_admin/judge_operation/judging/project/detail`
};
const GET_JUDGING_PROJECT_JUDGE = {
  method: 'GET',
  path: `${HOST}go_admin/judge_operation/judging/project/judge`
};
const ASSIGN_JUDGING_PROJECT = {
  method: 'POST',
  path: `${HOST}go_admin/judge_operation/judging/project/assign`
};
const UNASSIGN_JUDGING_PROJECT = {
  method: 'DELETE',
  path: `${HOST}go_admin/judge_operation/judging/project/unassign`
};
const GET_MONITORING_REPORT = {
  method: 'GET',
  path: `${HOST}go_admin/judge_operation/judging/monitoring`
};

module.exports = {
  GET_JUDGING_GROUP,
  JUDGING_ASSIGN,
  GET_JUDGING_GROUP_LIST,
  GET_JUDGING_GROUP_PROJECT,
  GET_JUDGING_JUDGE,
  GET_JUDGING_JUDGE_PROJECT,
  ADD_JUDGING_JUDGE_PROJECT,
  DELETE_JUDGING_JUDGE_PROJECT,
  GET_JUDGING_JUDGE_AVAILABLE_PROJECT,
  GET_JUDGING_PROJECT,
  GET_JUDGING_PROJECT_DETAIL,
  GET_JUDGING_PROJECT_JUDGE,
  ASSIGN_JUDGING_PROJECT,
  UNASSIGN_JUDGING_PROJECT,
  ADD_CERTIFICATE_TO_JUDGING,
  GET_JUDGING_PROJECT_BADGE,
  GET_MONITORING_REPORT
};
