import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { blue } from '@material-ui/core/colors';
import { Edit, Delete } from '@material-ui/icons';
import { FileUploadOutlined } from '@mui/icons-material';
import { Grid, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import Label from '../../../components/Label';
import ReadMore from '../../../components/CustomComponents/ReadMore';

const useStyles = makeStyles(() => ({
  avatar: {
    backgroundColor: blue[500]
  },
  cardAction: {
    float: 'right'
  }
}));

export default function CustomStudentCard(props) {
  const classes = useStyles();
  const { data, handleEditPerformance, handleDeletePerformance, handleAddFile } = props;

  return (
    <Card>
      <CardHeader
        avatar={<Avatar className={classes.avatar}>{`${data.performance_type.charAt(0)}`}</Avatar>}
        title={`${data.performance_type}`}
      />
      <CardContent>
        <Typography variant="body2" color="textPrimary" component="span">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <span>
                <b>Description: </b>
              </span>
              <ReadMore text={data?.description} readMoreCharacterLimit={100} />
            </Grid>
            <Grid item xs={12}>
              <span>
                <b>Created at: </b>
              </span>
              {moment(data.created_at).format('YYYY-MM-DD') || '-'}
            </Grid>
            <Grid item xs={12}>
              <span>
                <b>Media Link: </b>
              </span>
              <Label variant="ghost" color="primary">
                <a target="_blank" href={data.media_link} rel="noreferrer">
                  {data.media_link || ''}
                </a>
              </Label>
            </Grid>
            <Grid item xs={12}>
              <span>
                <b>Files: </b>
              </span>
              {data.performance_file || '-'}
            </Grid>
            <Grid item xs={12}>
              <span>
                <b>Status: </b>
              </span>
              {data.performance_status || '-'}
            </Grid>
            <Grid item xs={12}>
              <span>
                <b>Participants: </b>
              </span>
              <ReadMore
                text={data?.performance_participant_complete}
                readMoreCharacterLimit={100}
              />
            </Grid>
          </Grid>
        </Typography>
      </CardContent>
      <CardActions className={classes.cardAction}>
        <Tooltip title="Files">
          <IconButton size="small" color="secondary" onClick={() => handleAddFile(data)}>
            <FileUploadOutlined size="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Edit">
          <IconButton size="small" color="primary" onClick={() => handleEditPerformance(data)}>
            <Edit size="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton size="small" color="error" onClick={() => handleDeletePerformance(data.id)}>
            <Delete size="small" />
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  );
}

CustomStudentCard.propTypes = {
  data: PropTypes.object.isRequired,
  handleEditPerformance: PropTypes.func.isRequired,
  handleDeletePerformance: PropTypes.func.isRequired,
  handleAddFile: PropTypes.func
};
