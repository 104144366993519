import PropTypes from 'prop-types';
import MuiTable from '../../../components/CustomComponents/MuiTable';
import headers from '../_utils/headerVariables';

function Index(props) {
  const { tableData } = props;
  return <MuiTable tableColumns={headers} tableData={tableData} rowsPerPage={25} />;
}

Index.propTypes = {
  tableData: PropTypes.array.isRequired
};

export default Index;
