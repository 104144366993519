import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MenuItem, Paper, TextField } from '@material-ui/core';
import { Button, Stack } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { addReviewStatus, getReviewStatus } from '../../serviceCalls';
import { setSnackbar } from '../../../../../actions/alertActions';

const StatusDialog = (props) => {
  const { handleClose, selectedItems, tableData, dispatch, handleRefresh } = props;
  const [statusList, setStatusList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);

  useEffect(() => {
    getReviewStatus(
      {},
      (data) => {
        setStatusList(data);
      },
      () => {}
    );
  }, []);

  function handleAssign() {
    const project_ids = [];
    selectedItems.forEach((item) => {
      project_ids.push(tableData[item].id);
    });
    addReviewStatus(
      { project_ids, is_finalist: selectedStatus },
      () => {
        dispatch(setSnackbar('success', 'The status of projects have been updated'));
        handleRefresh();
      },
      () => {}
    );
  }

  return (
    <Stack spacing={2}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow style={{ background: 'gainsboro' }}>
              <TableCell style={{ width: '20%' }}>ID</TableCell>
              <TableCell style={{ width: '40%' }}>Project Title</TableCell>
              <TableCell style={{ width: '40%' }}>Teacher Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedItems.map((row, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {tableData[row].id}
                </TableCell>
                <TableCell>{tableData[row].title}</TableCell>
                <TableCell>{tableData[row].teacher_name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TextField
        fullWidth
        label="Status"
        select
        onChange={(e) => setSelectedStatus(e.target.value)}
      >
        {statusList.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </TextField>
      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="end" spacing={2}>
        <Button onClick={handleAssign} variant="outlined">
          Save
        </Button>
        <Button variant="outlined" color="error" onClick={handleClose}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};

StatusDialog.propTypes = {
  selectedItems: PropTypes.array,
  tableData: PropTypes.array,
  handleClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  handleRefresh: PropTypes.func
};

export default connect(() => ({}))(StatusDialog);
