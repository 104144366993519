import { fetchApi } from '../../../../api/fetchApi';

const project = require('../../../../api/RestVariables/Application/school');

export async function fetchSchoolInfo(config, scb, ecb) {
  try {
    const url = project.GET_SCHOOL;
    await fetchApi(
      url,
      config,
      (data, guidelines) => {
        scb(data, guidelines);
        console.log(data, 'GET_SCHOOL');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addSchoolInfo(config, scb, ecb) {
  try {
    const url = project.ADD_SCHOOL;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_SCHOOL');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateSchoolInfo(config, scb, ecb) {
  try {
    const url = project.UPDATE_SCHOOL;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_SCHOOL');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteSchoolInfo(config, scb, ecb) {
  try {
    const url = project.DELETE_SCHOOL;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_SCHOOL');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}
