import { Tooltip } from '@mui/material';
import { IconButton } from '@material-ui/core';
import React from 'react';
import { Icon } from '@iconify/react';

export const tableOptions = {
  filterType: 'multiselect',
  fixedHeader: true,
  rowsPerPage: 25,
  rowsPerPageOptions: [5, 10, 25, 50],
  selectableRows: 'none',
  selectToolbarPlacement: 'above',
  // resizableColumns: true,
  setTableProps: () => ({
    // material ui v4 only
    size: 'small'
  }),
  sortOrder: {
    name: 'interventionId',
    direction: 'desc'
  },
  downloadOptions: {
    filename: 'excel-format.csv',
    separator: ',',
    filterOptions: {
      useDisplayedColumnsOnly: false,
      useDisplayedRowsOnly: true
    }
  },
  onDownload: (buildHead, buildBody, columns, data) => `${buildHead(columns)}${buildBody(data)}`,
  textLabels: {
    body: {
      columnHeaderTooltip: (column) => `Sort for ${column.tooltip || column.label}`
    }
  },
  customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
    <div style={{ marginRight: 10 }}>
      <Tooltip title="Uncheck All Items">
        <IconButton onClick={() => setSelectedRows([])}>
          <Icon icon="ep:remove" width={24} height={24} />
        </IconButton>
      </Tooltip>
    </div>
  )
};
