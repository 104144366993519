export default [
  {
    name: 'participant_id',
    label: 'ID'
  },
  {
    name: 'name',
    label: 'Name'
  },
  {
    name: 'participant_type',
    label: 'Type'
  },
  {
    name: 'travel_option',
    label: 'Travel Option'
  },
  {
    name: 'travel_airline',
    label: 'Airline'
  },
  {
    name: 'travel_airport',
    label: 'Airport'
  },
  {
    name: 'travel_flight_number',
    label: 'Flight Number'
  },
  {
    name: 'travel_date',
    label: 'Date'
  },
  {
    name: 'travel_time',
    label: 'Time'
  }
];
