import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { IconButton } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { Button, DialogContent } from '@mui/material';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import MuiTable from '../../../../components/CustomComponents/MuiTable';
import { deleteDiscipline, fetchDisciplineList } from '../serviceCalls';
import { setConfirmationAlert, setSnackbar } from '../../../../actions/alertActions';
import headers from './utils/headerVariables';
import CustomDialog from '../../../../components/CustomComponents/CustomDialog';
import AddUpdateDialog from './AddUpdateDialog';
import Page from '../../../../components/Page';
import ProcessInfoBox from '../../../../components/CustomComponents/CustomCard/ProcessInfoBox';

const ADD_DISCIPLINE = 0;
const UPDATE_DISCIPLINE = 1;

function Index(props) {
  const { dispatch } = props;
  const [tableData, setTableData] = useState([]);
  const [addUpdateDialog, setAddUpdateDialog] = useState(false);
  const [mode, setMode] = useState(null);
  const [editData, setEditData] = useState(null);
  const [guidelines, setGuidelines] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);
  function fetchData() {
    fetchDisciplineList(
      {},
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((item) => {
          item.is_online_allowed_comp = item.is_online_allowed ? (
            <IconButton disabled className="Success-icon-color">
              <Icon icon="akar-icons:circle-check" width={24} height={24} />
            </IconButton>
          ) : (
            <IconButton disabled className="Error-icon-color">
              <Icon color="error" icon="akar-icons:circle-x" width={24} height={24} />
            </IconButton>
          );
          item.is_online_allowed_filter = item.is_online_allowed ? 'Yes' : 'No';
          item.edit = (
            <IconButton color="secondary" onClick={() => handleEditDiscipline(item)}>
              <Icon icon="ci:edit" width={24} height={24} />
            </IconButton>
          );
          item.delete = (
            <IconButton color="secondary" onClick={() => handleDeleteDiscipline(item.id)}>
              <Icon icon="fluent:delete-48-filled" width={24} height={24} />
            </IconButton>
          );
        });
        setTableData(list);
      },
      () => {}
    );
  }
  function handleAddDiscipline() {
    setMode(ADD_DISCIPLINE);
    setAddUpdateDialog(true);
  }
  function handleEditDiscipline(data) {
    setMode(UPDATE_DISCIPLINE);
    setAddUpdateDialog(true);
    setEditData(data);
  }
  function refreshData() {
    setAddUpdateDialog(false);
    fetchData();
  }
  function handleDeleteDiscipline(id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'This action will delete discipline permanently',
        () => {
          deleteDiscipline(
            { id },
            () => {
              fetchData();
              dispatch(setSnackbar('success', 'The discipline has been deleted'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }

  return (
    <Page title="Discipline Settings | Genius">
      {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
      <MuiTable
        title={
          <div className="header-button-style">
            <Button variant="outlined" onClick={handleAddDiscipline}>
              Add New
            </Button>
          </div>
        }
        tableData={tableData}
        tableColumns={headers}
      />
      <CustomDialog
        onClose={() => setAddUpdateDialog(false)}
        open={addUpdateDialog}
        title={mode === ADD_DISCIPLINE ? 'ADD' : 'UPDATE'}
      >
        <DialogContent>
          <AddUpdateDialog
            data={editData}
            mode={mode}
            handleClose={() => setAddUpdateDialog(false)}
            refreshData={refreshData}
          />
        </DialogContent>
      </CustomDialog>
    </Page>
  );
}

Index.propTypes = {
  dispatch: PropTypes.func.isRequired
};

export default connect(() => ({}))(Index);
