import { fetchApi } from '../../../api/fetchApi';

const payment = require('../../../api/RestVariables/Admin/payment');

export async function makeRefund(config, scb, ecb) {
  try {
    const url = payment.MAKE_REFUND;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'MAKE_REFUND');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getRefund(config, scb, ecb) {
  try {
    const url = payment.GET_REFUND;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_REFUND');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function manual(config, scb, ecb) {
  try {
    const url = payment.MANUAL;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'MANUAL');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getPaymentProject(config, scb, ecb) {
  try {
    const url = payment.GET_PAYMENT_PROJECT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_PAYMENT_PROJECT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function makeRefundParticipant(config, scb, ecb) {
  try {
    const url = payment.MAKE_REFUND_PARTICIPANT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'MAKE_REFUND_PARTICIPANT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getRefundParticipant(config, scb, ecb) {
  try {
    const url = payment.GET_REFUND_PARTICIPANT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_REFUND_PARTICIPANT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function manualParticipant(config, scb, ecb) {
  try {
    const url = payment.MANUAL_PARTICIPANT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'MANUAL_PARTICIPANT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getPaymentParticipant(config, scb, ecb) {
  try {
    const url = payment.GET_PAYMENT_PROJECT_PARTICIPANT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_PAYMENT_PROJECT_PARTICIPANT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function makeRefundTrip(config, scb, ecb) {
  try {
    const url = payment.MAKE_REFUND_TRIP;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'MAKE_REFUND_TRIP');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getRefundTrip(config, scb, ecb) {
  try {
    const url = payment.GET_REFUND_TRIP;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_REFUND_TRIP');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function manualTrip(config, scb, ecb) {
  try {
    const url = payment.MANUAL_TRIP;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'MANUAL_TRIP');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getPaymentTrip(config, scb, ecb) {
  try {
    const url = payment.GET_PAYMENT_TRIP;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_PAYMENT_TRIP');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}
