import React, { useEffect, useState } from 'react';
import { DialogContent, Tooltip } from '@mui/material';
import { IconButton, Stack } from '@material-ui/core';
import Email from '@mui/icons-material/Email';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Icon } from '@iconify/react';
import ReactHtmlParser from 'react-html-parser';
import { fCurrency } from '../../../../utils/formatNumber';
import Label from '../../../../components/Label';
import headers from './_utils/headerVariables';
import MuiTable from '../../../../components/CustomComponents/MuiTable';
import CustomDialog from '../../../../components/CustomComponents/CustomDialog';
import CustomMail from '../../../../components/CustomComponents/CustomMail';
import { CUSTOM_MAIL_TYPE } from '../../../../api/RestVariables/staticVariables';
import {
  handleAddCeremony,
  deleteAwardJudgingDetail,
  getAwardJudgingDetail,
  getAwardProject,
  addProjectMedal,
  // eslint-disable-next-line import/named
  transferProject,
  deleteRoboticsCompetition
} from '../_apis/serviceCalls';
import { setConfirmationAlert, setLoading, setSnackbar } from '../../../../actions/alertActions';
import { handleGetActiveTerm, handleGetTerms } from '../../../../actions/loginActions';
import ProcessInfoBox from '../../../../components/CustomComponents/CustomCard/ProcessInfoBox';
import SummaryTable from '../../../../components/CustomComponents/CustomCard/AwardSummaryTable';
import { getSummaryTableData } from './_utils/getSummaryData';
import MedalTypeView from './MedalTypeView';
import IncludeReviewView from './IncludeReviewView';
import SettingView from './SettingView';
import DetailView from './DetailView';
import TermSelect from './TermSelect';
import { handleLogin } from './_utils/awsUtils';

function Index(props) {
  const { terms, active_term, dispatch, isCommunication } = props;
  const [term, setTerm] = useState(active_term);
  const [summaryData, setSummaryData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [judgingDetail, setJudgingDetail] = useState([]);
  const [judgingDialog, setJudgingDialog] = useState(false);
  const [settingDialog, setSettingDialog] = useState(false);
  const [includeDialog, setIncludeDialog] = useState(false);
  const [addMail, setAddMail] = useState(false);
  const [addCeremony, setAddCeremony] = useState(false);
  const [addCertificate, setAddCertificate] = useState(false);
  const [mailDialogShow, setMailDialogShow] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [idList, setIdList] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [medalDialog, setMedalDialog] = useState(false);
  const [addMedal, setAddMedal] = useState(false);
  const [transfer, setTransfer] = useState(false);
  const [guidelines, setGuidelines] = useState([]);

  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedItems(rowsSelected);
    const arr = [];
    rowsSelected.forEach((item) => {
      arr.push(tableData[item].user_id);
    });
    setIdList(arr);
  };

  useEffect(() => {
    fetchData();
  }, [term]);

  function filterChange(displayData) {
    const arr = [];
    displayData.forEach((item) => {
      arr.push(tableData[item.dataIndex]);
    });
    const _summaryData = getSummaryTableData(arr);
    setSummaryData(_summaryData);
  }

  function fetchData() {
    getAwardProject(
      { term_id: term },
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        const _summaryData = getSummaryTableData(list);
        setSummaryData(_summaryData);
        list.forEach((item, index) => {
          const _balance = Number(parseFloat(item.balance).toFixed(2));
          const _project_student_total_balance = Number(
            parseFloat(item.project_student_total_balance).toFixed(2)
          );
          item.balance_comp =
            _balance < 0 || _balance === 0 ? (
              <Label variant="ghost" color="success">
                {fCurrency(item.balance)}
              </Label>
            ) : (
              <Label variant="ghost" color="error">
                {fCurrency(item.balance)}
              </Label>
            );
          item.balance_filter = _balance < 0 || _balance === 0 ? 'Paid' : 'Not Paid';
          item.balance_comp_student =
            _project_student_total_balance < 0 || _project_student_total_balance === 0 ? (
              <Label variant="ghost" color="success">
                {fCurrency(item.project_student_total_balance)}
              </Label>
            ) : (
              <Label variant="ghost" color="error">
                {fCurrency(item.project_student_total_balance)}
              </Label>
            );
          item.balance_filter_student =
            _project_student_total_balance < 0 || _project_student_total_balance === 0
              ? 'Paid'
              : 'Not Paid';
          item.presentation_link_comp = item.presentation_link && (
            <Label variant="ghost" color="primary">
              <a target="_blank" href={item.presentation_link} rel="noreferrer">
                Link
              </a>
            </Label>
          );
          item.presentation_link_filter = item.presentation_link ? 'Exist' : 'None';
          item.media_link_comp = item.media_link && (
            <Label variant="ghost" color="primary">
              <a target="_blank" href={item.media_link} rel="noreferrer">
                Link
              </a>
            </Label>
          );
          item.media_link_filter = item.media_link ? 'Exist' : 'None';
          item.judging_detail = (
            <IconButton
              disabled={!item.completed_judging}
              color="secondary"
              onClick={() => viewJudgingDetail(item.id)}
            >
              <Icon icon="bx:bx-comment-detail" width={24} height={24} />
            </IconButton>
          );
          item.medal_type_change = (
            <IconButton
              disabled={!item.completed_judging}
              onClick={() => handleAddMedal(index)}
              color="secondary"
            >
              <Icon icon="ic:baseline-published-with-changes" width={24} height={24} />
            </IconButton>
          );
          item.average_score_comp = item.average_score
            ? parseFloat(item.average_score).toFixed(2)
            : '-';
        });
        setTableData(list);
      },
      () => {}
    );
  }

  function handleAddMedal(index) {
    setMedalDialog(true);
    setSelectedItems([index]);
  }

  function _handleAddCeremony() {
    const project_ids = [];
    selectedItems.forEach((item) => {
      project_ids.push(tableData[item].id);
    });
    handleAddCeremony(
      { project_ids },
      () => {
        handleRefresh();
        dispatch(
          setSnackbar(
            'success',
            'The ceremony operation is in progress. After operation finished, you will receive an email notification.'
          )
        );
      },
      () => {}
    );
  }

  function handleRefresh() {
    setSelectedItems([]);
    setMailDialogShow(false);
    setAddMail(false);
    setSelectedItems([]);
    setSelectedProjectId(null);
    setIncludeDialog(false);
    setMedalDialog(false);
    setAddMedal(false);
    setAddCeremony(false);
    setAddCertificate(false);
    setTransfer(false);
    fetchData();
  }

  function viewJudgingDetail(project_id) {
    setSelectedProjectId(project_id);
    getAwardJudgingDetail(
      { project_id },
      (data) => {
        setJudgingDetail(data);
        setJudgingDialog(true);
      },
      () => {}
    );
  }

  function deleteJudging(judging_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'This action will delete judging',
        () => {
          deleteAwardJudgingDetail(
            { judging_id },
            () => {
              dispatch(setSnackbar('success', 'The judging has been deleted'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
              viewJudgingDetail(selectedProjectId);
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }

  function deleteRobotics(competition_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'This action will delete robotics competition',
        () => {
          deleteRoboticsCompetition(
            { competition_id },
            () => {
              dispatch(setSnackbar('success', 'The robotics competition has been deleted'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
              viewJudgingDetail(selectedProjectId);
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }

  function handleSuccess() {
    dispatch(handleGetTerms());
    dispatch(
      handleGetActiveTerm(
        () => {},
        () => {}
      )
    );
    setSettingDialog(false);
  }

  const handleCancel = () => {
    setSelectedItems([]);
    setAddMedal(false);
    setAddCeremony(false);
    setAddCertificate(false);
    setTransfer(false);
  };

  const startButtonDisabled = selectedItems.length === 0;

  function getSelectableRow(dataIndex) {
    let bool;
    if (addMail || transfer) {
      bool = true;
    } else if (addMedal) {
      bool = tableData[dataIndex].completed_judging;
    } else if (addCertificate) {
      const medalType = tableData[dataIndex].medal_type_id;
      bool = medalType > 1 && medalType < 8 && true;
    } else {
      const medalType = tableData[dataIndex].medal_type_id;
      bool = medalType > 2 && medalType < 8 && true;
    }
    return bool;
  }

  function handleAddCertificate() {
    const project_ids = [];
    selectedItems.forEach((item) => {
      project_ids.push(tableData[item].id);
    });
    addProjectMedal(
      { project_ids },
      () => {
        dispatch(
          setSnackbar(
            'success',
            'The certificate operation is in progress. After operation finished, you will receive an email notification.'
          )
        );
        handleRefresh();
      },
      () => {}
    );
  }

  function handleTransfer() {
    const project_ids = [];
    selectedItems.forEach((item) => {
      project_ids.push(tableData[item].id);
    });
    let response;
    transferProject({ project_ids }, (data) => {
      response = data;
    }).then(() => {
      dispatch(setLoading(1, 'Loading...'));
      handleLogin(response).then(() => {
        dispatch(setSnackbar('success', 'Transfer operation successfully completed'));
        dispatch(setLoading(0));
        handleRefresh();
      });
    });
  }

  return (
    <Stack spacing={2}>
      {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
      <ProcessInfoBox Comp={<SummaryTable data={summaryData} />} title="Summary" />
      <MuiTable
        tableData={tableData}
        tableColumns={headers}
        title={
          <TermSelect
            setSettingDialog={setSettingDialog}
            setTerm={setTerm}
            terms={terms}
            term={term}
            setIncludeDialog={setIncludeDialog}
            setMedalDialog={setMedalDialog}
            addMedal={addMedal}
            setAddMedal={setAddMedal}
            addCeremony={addCeremony}
            setAddCeremony={setAddCeremony}
            addMail={addMail}
            setAddMail={setAddMail}
            selectedItems={selectedItems}
            setMailDialogShow={setMailDialogShow}
            startButtonDisabled={startButtonDisabled}
            handleCancel={handleCancel}
            _handleAddCeremony={_handleAddCeremony}
            handleAddCertificate={handleAddCertificate}
            addCertificate={addCertificate}
            setAddCertificate={setAddCertificate}
            transfer={transfer}
            setTransfer={setTransfer}
            handleTransfer={handleTransfer}
          />
        }
        customOption={{
          selectableRows:
            addMail || addMedal || addCeremony || addCertificate || transfer ? 'multiple' : 'none',
          onRowSelectionChange,
          rowsSelected: selectedItems,
          isRowSelectable: (dataIndex) => getSelectableRow(dataIndex),
          customToolbar: () =>
            isCommunication !== -1 && (
              <Tooltip title="Send Email">
                <IconButton onClick={() => setAddMail(true)}>
                  <Email />
                </IconButton>
              </Tooltip>
            ),
          onFilterChange: (changedColumn, filterList, type, changedColumnIndex, displayData) => {
            filterChange(displayData);
            console.log(changedColumn);
            console.log(filterList);
            console.log(displayData);
          }
        }}
      />
      <CustomDialog
        onClose={() => setMailDialogShow(false)}
        open={mailDialogShow}
        maxWidth="lg"
        title="Send Mail"
        fullWidth
        disableEnforceFocus
      >
        <DialogContent>
          <CustomMail
            handleRefresh={handleRefresh}
            mailType={CUSTOM_MAIL_TYPE}
            idList={idList}
            handleClose={() => setMailDialogShow(false)}
          />
        </DialogContent>
      </CustomDialog>
      <DetailView
        setJudgingDialog={setJudgingDialog}
        judgingDialog={judgingDialog}
        deleteJudging={deleteJudging}
        deleteRobotics={deleteRobotics}
        judgingDetail={judgingDetail}
      />
      <SettingView
        setSettingDialog={setSettingDialog}
        settingDialog={settingDialog}
        terms={terms}
        term={term}
        handleSuccess={handleSuccess}
      />
      <IncludeReviewView
        setIncludeDialog={setIncludeDialog}
        includeDialog={includeDialog}
        handleRefresh={handleRefresh}
      />
      <MedalTypeView
        setMedalDialog={setMedalDialog}
        medalDialog={medalDialog}
        tableData={tableData}
        selectedItems={selectedItems}
        handleRefresh={handleRefresh}
      />
    </Stack>
  );
}

Index.propTypes = {
  terms: PropTypes.array,
  active_term: PropTypes.number,
  dispatch: PropTypes.func,
  isCommunication: PropTypes.number
};

export default connect((store) => ({
  terms: store.loginReducer.terms,
  active_term: store.loginReducer.active_term,
  isCommunication: store.routeReducer.isCommunication
}))(Index);
