export default [
  {
    name: 'id',
    label: 'ID'
  },
  {
    name: 'name',
    label: 'Participant Name'
  },
  {
    name: 'participant_type',
    label: 'Type'
  },
  {
    name: 'school_name',
    label: 'School'
  },
  {
    name: 'project_titles',
    label: 'Project Title'
  },
  {
    name: 'gender',
    label: 'Gender'
  },
  {
    name: 'grade_level',
    label: 'Grade Level'
  },
  {
    name: 'date_of_birth_comp',
    label: 'Date of Birth'
  }
];
