import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MenuItem, Paper, TextField } from '@material-ui/core';
import { Button, Stack } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { setSnackbar } from '../../../../../actions/alertActions';
import { approvePartnerReport } from '../../_apis/serviceCalls';

const list = [
  {
    id: 1,
    name: 'Approve'
  },
  {
    id: 0,
    name: 'Deny'
  }
];

const StatusDialog = (props) => {
  const { handleClose, selectedItems, tableData, dispatch, handleRefresh } = props;
  const [selectedStatus, setSelectedStatus] = useState(null);

  function handleAssign() {
    const message = selectedStatus
      ? 'The project(s) status has been approved'
      : 'The project(s) status has been denied';
    const project_ids = [];
    selectedItems.forEach((item) => {
      project_ids.push(tableData[item].id);
    });
    approvePartnerReport({ project_ids, is_approved: selectedStatus }, () => {
      handleRefresh();
      dispatch(setSnackbar('success', message));
    }).then(() => {});
  }

  return (
    <Stack spacing={2}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow style={{ background: 'gainsboro' }}>
              <TableCell style={{ width: '20%' }}>ID</TableCell>
              <TableCell align="center" style={{ width: '40%' }}>
                Project Title
              </TableCell>
              <TableCell align="center" style={{ width: '40%' }}>
                Partner Fair Name
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedItems.map((row, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {tableData[row].id}
                </TableCell>
                <TableCell align="center">{tableData[row].title}</TableCell>
                <TableCell align="center">{tableData[row].partner_fair_name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TextField
        fullWidth
        label="Status"
        select
        onChange={(e) => setSelectedStatus(e.target.value)}
      >
        {list.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </TextField>
      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="end" spacing={2}>
        <Button onClick={handleAssign} variant="outlined">
          Save
        </Button>
        <Button variant="outlined" color="error" onClick={handleClose}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};

StatusDialog.propTypes = {
  selectedItems: PropTypes.array,
  tableData: PropTypes.array,
  handleClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  handleRefresh: PropTypes.func
};

export default connect(() => ({}))(StatusDialog);
