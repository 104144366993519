import { Grid, Card } from '@mui/material';
import { useEffect, useState } from 'react';
import Page from '../../../components/Page';
import PasswordCard from './PasswordCard';
import { getFairAdminProfile } from '../FairAdmin/serviceCalls';
import FairProfileForm from './FairProfileForm';
// ----------------------------------------------------------------------

export default function Index() {
  const [profileData, setProfileData] = useState({});
  useEffect(() => {
    getFairAdminProfile(
      {},
      (data) => {
        setProfileData(data);
      },
      () => {}
    );
  }, []);
  return (
    <Page title="Profile | Genius">
      {Object.keys(profileData).length !== 0 && (
        <Grid container>
          <Grid item xs={12} md={8}>
            <Card style={{ padding: 20, margin: 10 }}>
              <FairProfileForm data={profileData} mode={1} />
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <PasswordCard />
          </Grid>
        </Grid>
      )}
    </Page>
  );
}
