export function getHeaderForParticipants(is_online) {
  return [
    {
      name: 'id',
      label: 'ID'
    },
    {
      name: 'school_name',
      label: 'School'
    },
    {
      name: 'project_id',
      label: 'Project ID'
    },
    {
      name: 'participant_type',
      label: 'Type'
    },
    {
      name: 'name',
      label: 'Participant Name'
    },
    {
      name: 'gender',
      label: 'Gender'
    },
    {
      name: 'date_of_birth_comp',
      label: 'Date of Birth'
    },
    {
      name: 'confirmation_comp',
      label: 'Confirmation',
      options: {
        sort: false,
        filter: false,
        print: false,
        download: false
      }
    },
    {
      name: 'confirmation',
      label: 'Confirmation',
      options: {
        display: false
      }
    },
    {
      name: 'payment_comp',
      label: 'Payment',
      options: {
        sort: false,
        filter: false,
        print: false,
        download: false
      }
    },
    {
      name: 'payment',
      label: 'Payment',
      options: {
        display: false
      }
    },
    {
      name: 'judging_session_comp',
      label: 'Judging Session',
      options: {
        sort: false,
        filter: false,
        print: false,
        download: false
      }
    },
    {
      name: 'judging_session',
      label: 'Judging Session',
      options: {
        display: false
      }
    },
    {
      name: 'trip_payment_comp',
      label: 'Trip',
      options: {
        display: !is_online,
        sort: false,
        filter: false,
        print: false,
        download: false
      }
    },
    {
      name: 'trip_payment',
      label: 'Trip',
      options: {
        display: false,
        sort: !is_online,
        filter: !is_online,
        print: !is_online,
        download: !is_online
      }
    },
    {
      name: 'travel_comp',
      label: 'Travel',
      options: {
        display: !is_online,
        sort: false,
        filter: false,
        print: false,
        download: false
      }
    },
    {
      name: 'travel',
      label: 'Travel',
      options: {
        display: false,
        sort: !is_online,
        filter: !is_online,
        print: !is_online,
        download: !is_online
      }
    },
    {
      name: 'presentation_comp',
      label: 'Presentation',
      options: {
        display: !!is_online,
        sort: false,
        filter: false,
        print: false,
        download: false
      }
    },
    {
      name: 'presentation',
      label: 'Presentation',
      options: {
        display: false,
        sort: is_online,
        filter: is_online,
        print: is_online,
        download: is_online
      }
    },
    {
      name: 'participant_status_comp',
      label: 'Status',
      options: {
        sort: false,
        filter: false,
        print: false,
        download: false
      }
    },
    {
      name: 'participant_status',
      label: 'Status',
      options: {
        display: false
      }
    }
  ];
}
