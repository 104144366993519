import { Paper } from '@material-ui/core';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import React from 'react';
import PropTypes from 'prop-types';

function SummaryTable({ data }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 400 }} aria-label="simple table">
        <TableHead>
          <TableRow style={{ background: 'gainsboro' }}>
            <TableCell>Discipline</TableCell>
            <TableCell align="center">Total Project</TableCell>
            <TableCell align="center">Paid</TableCell>
            <TableCell align="center">Review Finalist</TableCell>
            <TableCell align="center">Project Confirmed</TableCell>
            <TableCell align="center">Student Count</TableCell>
            <TableCell align="center">Paid Student Count</TableCell>
            <TableCell align="center">Project With Paid Student Count</TableCell>
            <TableCell align="center">Student Finalist</TableCell>
            <TableCell align="center">Student Attended</TableCell>
            <TableCell style={{ width: '10%' }} />
            <TableCell>Discipline</TableCell>
            <TableCell align="center">Total Project</TableCell>
            <TableCell align="center">Paid</TableCell>
            <TableCell align="center">Review Finalist</TableCell>
            <TableCell align="center">Project Confirmed</TableCell>
            <TableCell align="center">Student Count</TableCell>
            <TableCell align="center">Paid Student Count</TableCell>
            <TableCell align="center">Project With Paid Student Count</TableCell>
            <TableCell align="center">Student Finalist</TableCell>
            <TableCell align="center">Student Attended</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) =>
            index === data.length - 1 && index % 2 === 0 ? (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="center">{row.t}</TableCell>
                <TableCell align="center">{row.p}</TableCell>
                <TableCell align="center">{row.rf}</TableCell>
                <TableCell align="center">{row.cpc}</TableCell>
                <TableCell align="center">{row.sc}</TableCell>
                <TableCell align="center">{row.psc}</TableCell>
                <TableCell align="center">{row.pwpsc}</TableCell>
                <TableCell align="center">{row.fsc}</TableCell>
                <TableCell align="center">{row.asc}</TableCell>
              </TableRow>
            ) : index % 2 === 0 ? (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row" variant="head">
                  {row.name}
                </TableCell>
                <TableCell align="center">{row.t}</TableCell>
                <TableCell align="center">{row.p}</TableCell>
                <TableCell align="center">{row.rf}</TableCell>
                <TableCell align="center">{row.cpc}</TableCell>
                <TableCell align="center">{row.sc}</TableCell>
                <TableCell align="center">{row.psc}</TableCell>
                <TableCell align="center">{row.pwpsc}</TableCell>
                <TableCell align="center">{row.fsc}</TableCell>
                <TableCell align="center">{row.asc}</TableCell>
                <TableCell />
                <TableCell component="th" scope="row" variant="head">
                  {data[index + 1].name}
                </TableCell>
                <TableCell align="center">{data[index + 1].t}</TableCell>
                <TableCell align="center">{data[index + 1].p}</TableCell>
                <TableCell align="center">{data[index + 1].rf}</TableCell>
                <TableCell align="center">{data[index + 1].cpc}</TableCell>
                <TableCell align="center">{data[index + 1].sc}</TableCell>
                <TableCell align="center">{data[index + 1].psc}</TableCell>
                <TableCell align="center">{data[index + 1].pwpsc}</TableCell>
                <TableCell align="center">{data[index + 1].fsc}</TableCell>
                <TableCell align="center">{data[index + 1].asc}</TableCell>
              </TableRow>
            ) : null
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

SummaryTable.propTypes = {
  data: PropTypes.array.isRequired
};

export default SummaryTable;
