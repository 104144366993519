export default [
  {
    name: 'reviewer_id',
    label: 'ID'
  },
  {
    name: 'reviewer_first_name',
    label: 'First Name'
  },
  {
    name: 'reviewer_last_name',
    label: 'Last Name'
  },
  {
    name: 'reviewer_email',
    label: 'Email'
  },
  {
    name: 'discipline',
    label: 'Discipline'
  },
  {
    name: 'assigned',
    label: 'Assigned'
  },
  {
    name: 'completed',
    label: 'Completed'
  }
];
