import { Card, CardContent, CardHeader, Stack } from '@mui/material';
import { Checkbox, FormControlLabel, FormLabel } from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

function SessionSelection(props) {
  const {
    sessionList,
    morningChecked,
    setMorningChecked,
    afternoonChecked,
    setAfternoonChecked,
    onlineCheckedList,
    setOnlineCheckedList
  } = props;
  const morningList = sessionList.find((session) => session.judging_session_type_id === 1);
  const afternoonList = sessionList.find((session) => session.judging_session_type_id === 2);
  const onlineList = sessionList.find((session) => session.judging_session_type_id === 3);
  const [update, setUpdate] = useState(0);

  const MorningCheckbox = (_session, key) => {
    const { session } = _session;
    const sessionId = session.id;
    const handleChange = (event, id) => {
      setMorningChecked(event.target.checked ? id : null);
    };
    return (
      <FormControlLabel
        control={
          <Checkbox
            key={key}
            checked={morningChecked === sessionId}
            onChange={(e) => handleChange(e, sessionId)}
            name={session.id}
          />
        }
        label={session.name}
      />
    );
  };
  const AfternoonCheckbox = (_session, key) => {
    const { session } = _session;
    const sessionId = session.id;
    const handleChange = (event, id) => {
      setAfternoonChecked(event.target.checked ? id : null);
    };
    return (
      <FormControlLabel
        control={
          <Checkbox
            key={key}
            checked={afternoonChecked === sessionId}
            onChange={(e) => handleChange(e, sessionId)}
            name={session.id}
          />
        }
        label={session.name}
      />
    );
  };
  const OnlineCheckbox = (_session, key) => {
    const { session } = _session;
    const sessionId = session.id;
    const handleChange = (event, id) => {
      if (event.target.checked) {
        const arr = onlineCheckedList;
        arr.push(id);
        setOnlineCheckedList(arr);
        setUpdate(update + 1);
      } else {
        const arr = onlineCheckedList.filter((item) => item !== id);
        setOnlineCheckedList(arr);
        setUpdate(update + 1);
      }
    };
    return (
      <FormControlLabel
        control={
          <Checkbox
            key={key}
            checked={onlineCheckedList.includes(sessionId)}
            onChange={(e) => handleChange(e, sessionId)}
            name={sessionId}
          />
        }
        label={session.name}
      />
    );
  };

  return (
    <Card>
      <CardHeader subheader="Please select the sessions you want to participate in as a judge." />
      <CardContent>
        <p style={{ display: 'none' }}>{update}</p>
        <Stack spacing={1}>
          {morningList && (
            <div>
              <FormLabel style={{ fontWeight: 'bold' }} id="demo-radio-buttons-group-label">
                {morningList?.judging_session_type_name}
              </FormLabel>
              <div>
                {morningList.list.map((session) => (
                  <MorningCheckbox key={session.id} session={session} />
                ))}
              </div>
            </div>
          )}
          {afternoonList && (
            <div>
              <FormLabel style={{ fontWeight: 'bold' }} id="demo-radio-buttons-group-label">
                {afternoonList?.judging_session_type_name}
              </FormLabel>
              <div>
                {afternoonList.list.map((session) => (
                  <AfternoonCheckbox key={session.id} session={session} />
                ))}
              </div>
            </div>
          )}
          {onlineList && (
            <div>
              <FormLabel style={{ fontWeight: 'bold' }} id="demo-radio-buttons-group-label">
                {onlineList?.judging_session_type_name}
              </FormLabel>
              <div>
                {onlineList.list.map((session) => (
                  <OnlineCheckbox key={session.id} session={session} />
                ))}
              </div>
            </div>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}

SessionSelection.propTypes = {
  sessionList: PropTypes.array,
  morningChecked: PropTypes.number,
  setMorningChecked: PropTypes.func,
  afternoonChecked: PropTypes.number,
  setAfternoonChecked: PropTypes.func,
  onlineCheckedList: PropTypes.array,
  setOnlineCheckedList: PropTypes.func
};

export default SessionSelection;
