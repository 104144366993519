import { Button, DialogContent } from '@mui/material';
import { IconButton, MenuItem, Stack, TableCell, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Icon } from '@iconify/react';
import TableRow from '@mui/material/TableRow';
import ReactHtmlParser from 'react-html-parser';
import MuiTable from '../../../../../components/CustomComponents/MuiTable';
import headers from './headerVariables';
import CustomDialog from '../../../../../components/CustomComponents/CustomDialog';
import CustomMail from '../../../../../components/CustomComponents/CustomMail';
import { CUSTOM_MAIL_TYPE } from '../../../../../api/RestVariables/staticVariables';
import AddJudgeDialog from './AddJudge/AddJudgeDialog';
import ExpandView from './ExpandView/ExpandView';
import { getJudgingGroup } from '../_apis/serviceCalls';
import ProcessInfoBox from '../../../../../components/CustomComponents/CustomCard/ProcessInfoBox';

function Index(props) {
  const { terms, active_term } = props;
  const [tableData, setTableData] = useState([]);
  const [editData, setEditData] = useState(null);
  const [term, setTerm] = useState(active_term);
  const [mailDialogShow, setMailDialogShow] = useState(false);
  const [addJudgeDialogShow, setAddJudgeDialogShow] = useState(false);
  const [addMail, setAddMail] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [idList, setIdList] = useState([]);
  const [guidelines, setGuidelines] = useState([]);

  useEffect(() => {
    fetchData();
  }, [term]);

  function fetchData() {
    getJudgingGroup(
      { term_id: term },
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((item) => {
          item.assign_action = (
            <IconButton color="secondary" onClick={() => handleAssignJudge(item)}>
              <Icon icon="clarity:assign-user-line" width={24} height={24} />
            </IconButton>
          );
        });
        setTableData(list);
      },
      () => {}
    );
  }

  function handleAssignJudge(group) {
    setEditData(group);
    setAddJudgeDialogShow(true);
  }

  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedItems(rowsSelected);
    const arr = [];
    rowsSelected.forEach((item) => {
      arr.push(tableData[item].id);
    });
    setIdList(arr);
  };

  function TermSelect() {
    return (
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 2 }}>
        <TextField select label="Terms" value={term} onChange={(e) => setTerm(e.target.value)}>
          {terms.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
        {addMail && (
          <div>
            <Button
              style={{ marginRight: 5 }}
              disabled={selectedItems.length === 0}
              variant="outlined"
              onClick={() => setMailDialogShow(true)}
            >
              Next
            </Button>
            <Button color="error" variant="outlined" onClick={() => setAddMail(false)}>
              Cancel
            </Button>
          </div>
        )}
      </Stack>
    );
  }

  function handleRefresh() {
    setMailDialogShow(false);
    setAddJudgeDialogShow(false);
    setAddMail(false);
    setSelectedItems([]);
    fetchData();
  }

  return (
    <Stack spacing={1}>
      {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
      <MuiTable
        tableData={tableData}
        tableColumns={headers}
        title={<TermSelect />}
        customOption={{
          selectableRows: addMail ? 'multiple' : 'none',
          onRowSelectionChange,
          rowsSelected: selectedItems,
          expandableRows: true,
          renderExpandableRow: (rowData) => {
            const colSpan = rowData.length + 1;
            return (
              <TableRow>
                <TableCell colSpan={colSpan}>
                  <ExpandView judging_group_id={rowData[0]} />
                </TableCell>
              </TableRow>
            );
          }
        }}
      />
      <CustomDialog
        onClose={() => setMailDialogShow(false)}
        open={mailDialogShow}
        maxWidth="lg"
        title="Send Mail"
        fullWidth
        disableEnforceFocus
      >
        <DialogContent>
          <CustomMail
            handleRefresh={handleRefresh}
            mailType={CUSTOM_MAIL_TYPE}
            idList={idList}
            handleClose={() => setMailDialogShow(false)}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        onClose={() => setAddJudgeDialogShow(false)}
        open={addJudgeDialogShow}
        title="Assign Judge"
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          <AddJudgeDialog
            data={editData}
            handleClose={() => setAddJudgeDialogShow(null)}
            refreshData={handleRefresh}
          />
        </DialogContent>
      </CustomDialog>
    </Stack>
  );
}

Index.propTypes = {
  terms: PropTypes.array,
  active_term: PropTypes.number
};

export default connect((store) => ({
  terms: store.loginReducer.terms,
  active_term: store.loginReducer.active_term
}))(Index);
