import { Button, DialogContent } from '@mui/material';
import { IconButton, MenuItem, Stack, TableCell, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Icon } from '@iconify/react';
import TableRow from '@mui/material/TableRow';
import ReactHtmlParser from 'react-html-parser';
import MuiTable from '../../../../../components/CustomComponents/MuiTable';
import headers from './headerVariables';
import CustomDialog from '../../../../../components/CustomComponents/CustomDialog';
import CustomMail from '../../../../../components/CustomComponents/CustomMail';
import { CUSTOM_MAIL_TYPE } from '../../../../../api/RestVariables/staticVariables';
import { deleteGroup, getGroupList } from '../../serviceCalls';
import AddUpdateDialog from './AddUpdateDialog';
import { setConfirmationAlert, setSnackbar } from '../../../../../actions/alertActions';
import AddProjectDialog from './AddProject/AddProjectDialog';
import ExpandView from './ExpandView/ExpandView';
import AssignAllDialog from './AssignAllDialog';
import ProcessInfoBox from '../../../../../components/CustomComponents/CustomCard/ProcessInfoBox';

const ADD_GROUP = 0;
const UPDATE_GROUP = 1;

function Index(props) {
  const { terms, active_term, dispatch } = props;
  const [tableData, setTableData] = useState([]);
  const [mode, setMode] = useState(null);
  const [editData, setEditData] = useState(null);
  const [term, setTerm] = useState(active_term);
  const [mailDialogShow, setMailDialogShow] = useState(false);
  const [assignAllDialogShow, setAssignAllDialogShow] = useState(false);
  const [addProjectDialogShow, setAddProjectDialogShow] = useState(false);
  const [addMail, setAddMail] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [idList, setIdList] = useState([]);
  const [guidelines, setGuidelines] = useState([]);

  useEffect(() => {
    fetchData();
  }, [term]);

  function fetchData() {
    getGroupList(
      { term_id: term },
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((item) => {
          const maxProject = item.judging_group_capacity - item.number_of_projects;
          item.electricity_access_comp = item.electricity_access ? 'Yes' : 'No';
          item.project = (
            <IconButton
              disabled={maxProject === 0}
              color="secondary"
              onClick={() => handleAddProject(item)}
            >
              <Icon icon="ant-design:project-outlined" width={24} height={24} />
            </IconButton>
          );
          item.edit = (
            <IconButton color="secondary" onClick={() => handleEditGroup(item)}>
              <Icon icon="ci:edit" width={24} height={24} />
            </IconButton>
          );
          item.delete = (
            <IconButton color="secondary" onClick={() => handleDeleteGroup(item)}>
              <Icon icon="fluent:delete-48-filled" width={24} height={24} />
            </IconButton>
          );
        });
        setTableData(list);
      },
      () => {}
    );
  }

  function handleAddProject(group) {
    setEditData(group);
    setAddProjectDialogShow(true);
  }

  function handleEditGroup(group) {
    setEditData(group);
    setMode(UPDATE_GROUP);
  }

  function handleDeleteGroup(item) {
    const { number_of_projects } = item;
    const message =
      number_of_projects === 1
        ? 'There is an assigned project to group, this action will delete group permanently'
        : number_of_projects > 1
        ? `There are ${number_of_projects} assigned projects to group, this action will delete group permanently`
        : 'This action will delete group permanently';
    dispatch(
      setConfirmationAlert('Are you sure?', 'YES, DELETE', message, () => {
        deleteGroup(
          {
            judging_group_id: item.judging_group_id,
            force_delete: number_of_projects > 0 ? 1 : 0
          },
          () => {
            fetchData();
            dispatch(setSnackbar('success', 'The group has been deleted'));
            dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
          },
          () => {
            dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
          }
        );
      })
    );
  }

  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedItems(rowsSelected);
    const arr = [];
    rowsSelected.forEach((item) => {
      arr.push(tableData[item].user_id);
    });
    setIdList(arr);
  };

  function TermSelect() {
    return (
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 2 }}>
        <TextField select label="Terms" value={term} onChange={(e) => setTerm(e.target.value)}>
          {terms.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
        <div className="header-button-style">
          <Button variant="outlined" onClick={() => setMode(ADD_GROUP)}>
            Add New
          </Button>
        </div>
        <div className="header-button-style">
          <Button variant="outlined" onClick={() => setAssignAllDialogShow(true)}>
            Assign All
          </Button>
        </div>
        {addMail && (
          <div>
            <Button
              style={{ marginRight: 5 }}
              disabled={selectedItems.length === 0}
              variant="outlined"
              onClick={() => setMailDialogShow(true)}
            >
              Next
            </Button>
            <Button color="error" variant="outlined" onClick={() => setAddMail(false)}>
              Cancel
            </Button>
          </div>
        )}
      </Stack>
    );
  }

  function handleRefresh() {
    setMailDialogShow(false);
    setAddProjectDialogShow(false);
    setAddMail(false);
    setSelectedItems([]);
    setMode(null);
    setAssignAllDialogShow(false);
    fetchData();
  }

  return (
    <Stack spacing={1}>
      {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
      <MuiTable
        tableData={tableData}
        tableColumns={headers}
        title={<TermSelect />}
        customOption={{
          selectableRows: addMail ? 'multiple' : 'none',
          onRowSelectionChange,
          rowsSelected: selectedItems,
          expandableRows: true,
          isRowExpandable: (dataIndex) => tableData[dataIndex].number_of_projects > 0,
          renderExpandableRow: (rowData) => {
            const colSpan = rowData.length + 1;
            return (
              <TableRow>
                <TableCell colSpan={colSpan}>
                  <ExpandView judging_group_id={rowData[0]} handleRefresh={handleRefresh} />
                </TableCell>
              </TableRow>
            );
          }
        }}
      />
      <CustomDialog
        onClose={() => setMailDialogShow(false)}
        open={mailDialogShow}
        maxWidth="lg"
        title="Send Mail"
        fullWidth
        disableEnforceFocus
      >
        <DialogContent>
          <CustomMail
            handleRefresh={handleRefresh}
            mailType={CUSTOM_MAIL_TYPE}
            idList={idList}
            handleClose={() => setMailDialogShow(false)}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        onClose={() => setMode(null)}
        open={mode === ADD_GROUP || mode === UPDATE_GROUP}
        title={mode === ADD_GROUP ? 'ADD' : 'UPDATE'}
      >
        <DialogContent>
          <AddUpdateDialog
            data={editData}
            mode={mode}
            handleClose={() => setMode(null)}
            refreshData={handleRefresh}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        onClose={() => setAddProjectDialogShow(false)}
        open={addProjectDialogShow}
        title="Add Project"
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          <AddProjectDialog
            data={editData}
            handleClose={() => setAddProjectDialogShow(null)}
            refreshData={handleRefresh}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        onClose={() => setAssignAllDialogShow(false)}
        open={assignAllDialogShow}
        title="Assign All"
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          <AssignAllDialog
            handleClose={() => setAssignAllDialogShow(false)}
            refreshData={handleRefresh}
          />
        </DialogContent>
      </CustomDialog>
    </Stack>
  );
}

Index.propTypes = {
  terms: PropTypes.array,
  active_term: PropTypes.number,
  dispatch: PropTypes.func
};

export default connect((store) => ({
  terms: store.loginReducer.terms,
  active_term: store.loginReducer.active_term
}))(Index);
