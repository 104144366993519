const HOST = process.env.REACT_APP_REST_API_HOST;

const GET_JUDGING_PROJECT = {
  method: 'GET',
  path: `${HOST}go_admin/judge/judging/project`
};
const START_JUDGING = {
  method: 'POST',
  path: `${HOST}go_admin/judge/judging`
};
const DELETE_JUDGING = {
  method: 'DELETE',
  path: `${HOST}go_admin/judge/judging`
};
const SUBMIT_JUDGING = {
  method: 'POST',
  path: `${HOST}go_admin/judge/judging/submit`
};
const SCORE_JUDGING = {
  method: 'POST',
  path: `${HOST}go_admin/judge/judging/question`
};
const DOWNLOAD_JUDGING_PROJECT = {
  method: 'GET',
  path: `${HOST}go_admin/judge/judging/file`
};
const GET_CERTIFICATE_DETAIL = {
  method: 'GET',
  path: `${HOST}go_admin/judge/certificate/detail`
};
const DOWNLOAD_CERTIFICATE = {
  method: 'GET',
  path: `${HOST}go_admin/judge/certificate/file`
};

module.exports = {
  GET_JUDGING_PROJECT,
  START_JUDGING,
  DELETE_JUDGING,
  SUBMIT_JUDGING,
  SCORE_JUDGING,
  DOWNLOAD_JUDGING_PROJECT,
  GET_CERTIFICATE_DETAIL,
  DOWNLOAD_CERTIFICATE
};
