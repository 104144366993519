export default [
  {
    name: 'action',
    label: 'Approve/Deny',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'fileUpdate',
    label: 'File Update',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'edit',
    label: 'Edit',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'id',
    label: 'ID'
  },
  {
    name: 'performance_type',
    label: 'Type'
  },
  {
    name: 'description',
    label: 'Description'
  },
  {
    name: 'country',
    label: 'Country'
  },
  {
    name: 'created_at_comp',
    label: 'Created At'
  },
  {
    name: 'created_by',
    label: 'Created By'
  },
  {
    name: 'participants_comp',
    label: 'Participants',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'performance_participant',
    label: 'Participants',
    options: {
      display: false
    }
  },
  {
    name: 'file_comp',
    label: 'File',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'file_filter',
    label: 'File',
    options: {
      display: false
    }
  },
  {
    name: 'media_link_comp',
    label: 'YT Link',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'media_link_filter',
    label: 'YT Link',
    options: {
      display: false
    }
  },
  {
    name: 'performance_status',
    label: 'Status'
  }
];
