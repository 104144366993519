import { format, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function getCurrentDateTime() {
  return format(new Date(), "yyyy-MM-dd'T'hh:mm");
}

export function getDateTime(date_time) {
  return format(new Date(date_time), "yyyy-MM-dd'T'HH:mm");
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

export function getDuration(duration) {
  const sec_num = parseInt(duration, 10);
  const refinedSec = sec_num * 60;
  let hours = Math.floor(refinedSec / 3600);
  let minutes = Math.floor((refinedSec - hours * 3600) / 60);
  let seconds = refinedSec - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }
  return `${hours}:${minutes}:${seconds}`;
}

export function getDurationString(duration) {
  const list = duration.split(':');
  const hours = parseInt(list[0], 10);
  const minutes = parseInt(list[1], 10);
  const seconds = parseInt(list[2], 10);
  return hours * 60 + minutes + seconds / 60;
}

export function convertSecondToHour(sec) {
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec - hours * 3600) / 60);
  const seconds = sec - hours * 3600 - minutes * 60;
  return `${hours}h ${minutes}m ${seconds}s`;
}

export function convertHourToSeconds(str) {
  if (str === '-') return 0;
  const list = str.split(' ');
  const hours = parseInt(list[0], 10);
  const minutes = parseInt(list[1], 10);
  const seconds = parseInt(list[2], 10);
  return hours * 3600 + minutes * 60 + seconds;
}
