const HOST = process.env.REACT_APP_REST_API_HOST;

const GET_PROFILE = {
  method: 'GET',
  path: `${HOST}go_shield/user/profile`
};
const ADD_PROFILE = {
  method: 'POST',
  path: `${HOST}go_shield/user/profile`
};
const UPDATE_PROFILE = {
  method: 'PUT',
  path: `${HOST}go_shield/user/profile`
};
const UPLOAD_PROFILE_PICTURE = {
  method: 'POST',
  path: `${HOST}go_shield/user/profile/picture`
};
const GET_USER_ROLE = {
  method: 'GET',
  path: `${HOST}go_shield/user/role`
};
const CHANGE_USER_ROLE = {
  method: 'PATCH',
  path: `${HOST}go_shield/user/role/set_default`
};

module.exports = {
  GET_PROFILE,
  ADD_PROFILE,
  UPDATE_PROFILE,
  GET_USER_ROLE,
  CHANGE_USER_ROLE,
  UPLOAD_PROFILE_PICTURE
};
