import React, { useEffect, useState } from 'react';
import { IconButton, Stack } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, DialogContent } from '@mui/material';
import MuiTable from '../../../../../components/CustomComponents/MuiTable';
import headers from './utils/headerVariables';
import Label from '../../../../../components/Label';
import { fCurrency } from '../../../../../utils/formatNumber';
import CustomDialog from '../../../../../components/CustomComponents/CustomDialog';
import Page from '../../../../../components/Page';
import ViewFileDialog from './utils/ViewFileDialog';
import StatusDialog from '../../../ReviewOperation/Review/utils/StatusDialog';
import { getReviewDetail } from '../../serviceCalls';
import ReviewDetailDialog from './utils/ReviewDetailDialog';
import fileMaker from '../../../../Application/Projects/_utils/fileMaker';

function ReviewDetail(props) {
  const { reviewList, handleClose } = props;
  const [tableData, setTableData] = useState([]);
  const [addAssign, setAddAssign] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [addStatus, setAddStatus] = useState(false);
  const [statusDialog, setStatusDialog] = useState(false);
  const [addMail, setAddMail] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileDialog, setFileDialog] = useState(false);
  const [reviewDialog, setReviewDialog] = useState(false);
  const [reviewDetail, setReviewDetail] = useState(null);

  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedItems(rowsSelected);
  };
  const handleCancel = () => {
    setSelectedItems([]);
    setAddAssign(false);
    setAddStatus(false);
  };
  useEffect(() => {
    reviewList.forEach((item, index) => {
      const _balance = Number(parseFloat(item.balance).toFixed(2));
      item.balance_comp =
        _balance < 0 || _balance === 0 ? (
          <Label variant="ghost" color="success">
            {fCurrency(item.balance)}
          </Label>
        ) : (
          <Label variant="ghost" color="error">
            {fCurrency(item.balance)}
          </Label>
        );
      item.balance_filter = _balance < 0 || _balance === 0 ? 'Paid' : 'Not Paid';
      item.presentation_link_comp = item.presentation_link && (
        <Label variant="ghost" color="primary">
          <a target="_blank" href={item.presentation_link} rel="noreferrer">
            Link
          </a>
        </Label>
      );
      item.presentation_link_filter = item.presentation_link ? 'Exist' : 'None';
      item.file_comp = fileMaker(item.files, viewFile);
      item.review_status_comp = (
        <IconButton color="secondary" onClick={() => handleChangeReviewStatus(index)}>
          <Icon icon="ic:twotone-published-with-changes" width={24} height={24} />
        </IconButton>
      );
      item.file_filter = item.project_file_id ? 'Exist' : 'None';
      item.media_link_comp = item.media_link && (
        <Label variant="ghost" color="primary">
          <a target="_blank" href={item.media_link} rel="noreferrer">
            Link
          </a>
        </Label>
      );
      item.review_detail = (
        <IconButton color="secondary" onClick={() => handleReviewProject(item.id)}>
          <Icon icon="bx:bx-comment-detail" width={24} height={24} />
        </IconButton>
      );
      item.media_link_filter = item.media_link ? 'Exist' : 'None';
      item.created_at_moment = moment(item.created_at).format('YYYY-MM-DD');
    });
    setTableData(reviewList);
  }, []);
  function handleRefresh() {
    setAddStatus(false);
    setAddAssign(false);
    setStatusDialog(false);
    setSelectedItems([]);
    setAddMail(false);
    setSelectedItems([]);
    handleClose();
  }
  function viewFile(file) {
    const obj = {
      id: file.id,
      name: file.name
    };
    setSelectedFile(obj);
    setFileDialog(true);
  }
  function TermSelect() {
    return (
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 2 }}>
        {!addMail && (
          <div className="header-button-style">
            <Stack spacing={2} direction={{ sm: 'row' }}>
              {!addStatus && !addAssign && (
                <Button color="secondary" variant="outlined" onClick={() => setAddStatus(true)}>
                  Status
                </Button>
              )}
              {addStatus && (
                <div>
                  <Button
                    color="secondary"
                    variant="outlined"
                    disabled={startButtonDisabled}
                    onClick={() => setStatusDialog(true)}
                  >
                    Next
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    style={{ marginLeft: 10 }}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </div>
              )}
            </Stack>
          </div>
        )}
      </Stack>
    );
  }
  const startButtonDisabled = selectedItems.length === 0;
  function handleChangeReviewStatus(index) {
    setStatusDialog(true);
    setSelectedItems([index]);
  }
  function handleReviewProject(project_id) {
    getReviewDetail(
      { project_id },
      (data) => {
        setReviewDetail(data);
        setReviewDialog(true);
      },
      () => {}
    );
  }

  return (
    <Page title="Review | Genius">
      <MuiTable
        tableData={tableData}
        tableColumns={headers}
        title={<TermSelect />}
        customOption={{
          selectableRows: addStatus ? 'multiple' : 'none',
          onRowSelectionChange,
          rowsSelected: selectedItems
        }}
      />
      <CustomDialog
        onClose={() => setStatusDialog(false)}
        open={statusDialog}
        maxWidth="lg"
        title="Review Status"
      >
        <DialogContent>
          <StatusDialog
            tableData={tableData}
            handleClose={() => setStatusDialog(false)}
            selectedItems={selectedItems}
            handleRefresh={handleRefresh}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        maxWidth="lg"
        fullScreen
        onClose={() => setFileDialog(false)}
        open={fileDialog}
        title="File"
      >
        <DialogContent>
          <ViewFileDialog handleClose={() => setFileDialog(false)} file={selectedFile} />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        onClose={() => setReviewDialog(false)}
        open={reviewDialog}
        maxWidth="lg"
        title="Review Detail"
      >
        <DialogContent>
          <ReviewDetailDialog data={reviewDetail} handleClose={() => setReviewDialog(false)} />
        </DialogContent>
      </CustomDialog>
    </Page>
  );
}

ReviewDetail.propTypes = {
  reviewList: PropTypes.array,
  handleClose: PropTypes.func
};

export default connect(() => ({}))(ReviewDetail);
