import { Button, Stack } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InfoCard from './InfoCard';
import RubricDetail from './RubricDetail';
import { addRubricCounts } from '../_apis/serviceCalls';

function Index(props) {
  const { projectData, handleSuccess, handleClose } = props;
  const rubricList = projectData.rubrics;
  const [controlList, setControlList] = useState([]);
  const [submitDisable, setSubmitDisable] = useState(false);

  console.log(controlList, 'controlList');

  useEffect(() => {
    const arr = [];
    rubricList.forEach((rubric) => {
      const obj = {
        id: rubric.id,
        point: 0
      };
      arr.push(obj);
    });
    setControlList(arr);
  }, []);

  useEffect(() => {
    const isFalseCaseExist = controlList.find((x) => x.state === false);
    setSubmitDisable(isFalseCaseExist);
  }, [controlList]);

  function handleSubmitJudging() {
    const competition_rubric_counts = [];
    controlList.forEach((item) => {
      const obj = {
        competition_rubric_id: item.id,
        count: item.point
      };
      competition_rubric_counts.push(obj);
    });
    const param = {
      competition_id: projectData.competition_id,
      competition_rubric_counts
    };
    addRubricCounts(
      param,
      () => {
        handleSuccess();
      },
      () => {}
    );
  }

  function handleSelectScore(id, point) {
    const updatedList = controlList.map((x) => (x.id === id ? { ...x, point } : x));
    setControlList(updatedList);
  }

  function closeClick() {
    handleClose();
  }

  return (
    <Stack spacing={2}>
      <InfoCard projectData={projectData} />
      {rubricList.map((rubric, index) => (
        <RubricDetail rubric={rubric} key={index} handleSelectScore={handleSelectScore} />
      ))}
      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="start" spacing={2}>
        <Button disabled={submitDisable} variant="outlined" onClick={handleSubmitJudging}>
          Submit
        </Button>
        <Button variant="outlined" color="error" onClick={closeClick}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
}

Index.propTypes = {
  projectData: PropTypes.object.isRequired,
  handleSuccess: PropTypes.func,
  handleClose: PropTypes.func
};

export default connect(() => ({}))(Index);
