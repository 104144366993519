import { fetchApi } from '../../../api/fetchApi';

const judge = require('./judge_apis');

export async function getJudgingProject(config, scb, ecb) {
  try {
    const url = judge.GET_JUDGING_PROJECT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_JUDGING_PROJECT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function startJudging(config, scb, ecb) {
  try {
    const url = judge.START_JUDGING;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'START_JUDGING');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteJudging(config, scb, ecb) {
  try {
    const url = judge.DELETE_JUDGING;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_JUDGING');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function submitJudging(config, scb, ecb) {
  try {
    const url = judge.SUBMIT_JUDGING;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'SUBMIT_JUDGING');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function scoreJudging(config, scb, ecb) {
  try {
    const url = judge.SCORE_JUDGING;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'SCORE_JUDGING');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getCertificateDetail(config, scb, ecb) {
  try {
    const url = judge.GET_CERTIFICATE_DETAIL;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_CERTIFICATE_DETAIL');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function downloadJudgingFile(config, scb, ecb) {
  try {
    const url = judge.DOWNLOAD_JUDGING_PROJECT;
    await fetchApi(
      url,
      { file_id: config.id },
      (data) => {
        getFilesApi(data, config, scb, ecb);
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function downloadCertificateForJudge(config, scb, ecb) {
  try {
    const url = judge.DOWNLOAD_CERTIFICATE;
    await fetchApi(
      url,
      { certificate_id: config.id },
      (data) => {
        getFilesApi(data, config, scb, ecb);
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

const getFilesApi = (data, config, scb, ecb) => {
  const { url } = data;
  const extension = url.substring(url.lastIndexOf('.') + 1, url.lastIndexOf('?'));
  const requestOptions = {
    method: 'GET'
  };
  try {
    fetch(url, requestOptions)
      // eslint-disable-next-line consistent-return
      .then((response) => {
        if (response.status === 200) {
          scb();
          return response.blob();
        }
        ecb(response.status);
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        console.log(link);
        link.href = href;
        link.setAttribute('download', `${config.file_name}.${extension}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  } catch (error) {
    console.log(error);
    ecb(error);
  }
};
