import React, { useEffect, useState } from 'react';
import { IconButton, Stack } from '@material-ui/core';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import ReactHtmlParser from 'react-html-parser';
import MuiTable from '../../../../../components/CustomComponents/MuiTable';
import headers from './utils/headerVariables';
import Page from '../../../../../components/Page';
import { deleteSession, getSessionList } from '../../serviceCalls';
import MailView from './MailView/MailView';
import AddUpdateSessionDialog from './AddUpdateSessionDialog/AddUpdateSessionDialog';
import CopySessionDialog from './CopySessionDialog/CopySessionDialog';
import { ADD_MODE, EDIT_MODE } from '../../../../../utils/generalConstants';
import { fDateTime } from '../../../../../utils/formatTime';
import { setConfirmationAlert, setSnackbar } from '../../../../../actions/alertActions';
import ProcessInfoBox from '../../../../../components/CustomComponents/CustomCard/ProcessInfoBox';

function Index(props) {
  const { dispatch } = props;
  const [tableData, setTableData] = useState([]);
  const [addMail, setAddMail] = useState(false);
  const [mailDialogShow, setMailDialogShow] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [idList, setIdList] = useState([]);
  const [mode, setMode] = useState(null);
  const [selectedSession, setSelectedSession] = useState(null);
  const [copyDialogShow, setCopyDialogShow] = useState(false);
  const [guidelines, setGuidelines] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    getSessionList(
      {},
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((session) => {
          session.start_date_view = fDateTime(session.start_datetime);
          session.end_date_view = fDateTime(session.end_datetime);
          session.edit = (
            <IconButton color="secondary" onClick={() => handleEditSession(session)}>
              <Icon icon="ci:edit" width={24} height={24} />
            </IconButton>
          );
          session.delete = (
            <IconButton color="secondary" onClick={() => handleDeleteSession(session.id)}>
              <Icon icon="fluent:delete-48-filled" width={24} height={24} />
            </IconButton>
          );
        });
        setTableData(list);
      },
      () => {}
    );
  }

  function handleDeleteSession(judging_session_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'This action will delete judge session',
        () => {
          deleteSession(
            { judging_session_id, force_delete: 0 },
            () => {
              dispatch(setSnackbar('success', 'The session has been deleted'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
              handleRefresh();
            },
            (error) => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
              if (error.http_status_code === 409) {
                forceDelete(judging_session_id, error.message);
              }
            }
          );
        }
      )
    );
  }

  function forceDelete(judging_session_id, message) {
    dispatch(
      setConfirmationAlert('Are you sure?', 'YES, FORCE DELETE', message, () => {
        deleteSession(
          { judging_session_id, force_delete: 1 },
          () => {
            dispatch(setSnackbar('success', 'The session has been deleted'));
            dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            handleRefresh();
          },
          () => {
            dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
          }
        );
      })
    );
  }

  function handleEditSession(session) {
    setSelectedSession(session);
    setMode(EDIT_MODE);
  }
  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedItems(rowsSelected);
    const arr = [];
    rowsSelected.forEach((item) => {
      arr.push(tableData[item].id);
    });
    setIdList(arr);
  };
  function CustomTitle() {
    return (
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 2 }}>
        {addMail ? (
          <div>
            <Button
              style={{ marginRight: 5 }}
              disabled={selectedItems.length === 0}
              variant="outlined"
              onClick={() => setMailDialogShow(true)}
            >
              Next
            </Button>
            <Button color="error" variant="outlined" onClick={() => setAddMail(false)}>
              Cancel
            </Button>
          </div>
        ) : (
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Button variant="outlined" onClick={() => setMode(ADD_MODE)}>
              Add New Session
            </Button>
            <Button variant="outlined" onClick={() => setCopyDialogShow(true)}>
              Copy Session
            </Button>
          </Stack>
        )}
      </Stack>
    );
  }
  function handleRefresh() {
    setCopyDialogShow(false);
    setMailDialogShow(false);
    setAddMail(false);
    setSelectedItems([]);
    setMode(null);
    fetchData();
  }
  const CustomOption = () => ({
    selectableRows: addMail ? 'multiple' : 'none',
    onRowSelectionChange,
    rowsSelected: selectedItems
  });

  return (
    <Page title="Judge Operation | Genius">
      <Stack spacing={2}>
        {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
        <MuiTable
          tableData={tableData}
          tableColumns={headers}
          title={<CustomTitle />}
          customOption={CustomOption()}
        />
      </Stack>
      <MailView
        mailDialogShow={mailDialogShow}
        setMailDialogShow={setMailDialogShow}
        handleRefresh={handleRefresh}
        idList={idList}
      />
      {mode && (
        <AddUpdateSessionDialog
          data={selectedSession}
          mode={mode}
          handleClose={() => setMode(null)}
          refreshData={handleRefresh}
        />
      )}
      {copyDialogShow && (
        <CopySessionDialog
          sessionList={tableData}
          handleClose={() => setCopyDialogShow(false)}
          refreshData={handleRefresh}
        />
      )}
    </Page>
  );
}

Index.propTypes = {
  dispatch: PropTypes.func
};

export default connect(() => ({}))(Index);
