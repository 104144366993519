export default function getHeaders(participantList) {
  const titleList = new Set();
  const idList = new Set();

  participantList.forEach((participant) => {
    const titleArray = participant.project_titles.split(',');
    const idArray = participant.project_ids.split(',');
    if (titleArray.length === 0) {
      titleList.add('Blanks');
    }
    if (idArray.length === 0) {
      idList.add('Blanks');
    }
    titleArray.forEach((title) => {
      titleList.add(title);
    });
    idArray.forEach((id) => {
      idList.add(id);
    });
  });

  return [
    {
      name: 'edit',
      label: 'Edit',
      options: {
        sort: false,
        filter: false,
        print: false,
        download: false
      }
    },
    {
      name: 'delete',
      label: 'Delete',
      options: {
        sort: false,
        filter: false,
        print: false,
        download: false
      }
    },
    {
      name: 'id',
      label: 'ID'
    },
    {
      name: 'name',
      label: 'Participant Name'
    },
    {
      name: 'participant_type',
      label: 'Type'
    },
    {
      name: 'vip_description',
      label: 'Vip Reason'
    },
    {
      name: 'school_name',
      label: 'School'
    },
    {
      name: 'project_ids',
      label: 'Project ID',
      options: {
        filter: false
      }
    },
    {
      name: 'project_titles',
      label: 'Project Title',
      options: {
        filter: false
      }
    },
    {
      name: 'project_ids_filter',
      label: 'Project ID',
      options: {
        display: 'excluded',
        filter: true,
        download: false,
        sort: false,
        filterOptions: {
          logic: (location, filters) => {
            let isExist = false;
            filters.some((item) => {
              isExist = location.includes(item);
              return location.includes(item);
            });
            return !isExist;
          },
          names: Array.from(idList)
        }
      }
    },
    {
      name: 'project_titles_filter',
      label: 'Project Title',
      options: {
        display: 'excluded',
        filter: true,
        download: false,
        sort: false,
        filterOptions: {
          logic: (location, filters) => {
            let isExist = false;
            filters.some((item) => {
              isExist = location.includes(item);
              return location.includes(item);
            });
            return !isExist;
          },
          names: Array.from(titleList)
        }
      }
    },
    {
      name: 'gender',
      label: 'Gender'
    },
    {
      name: 'grade_level',
      label: 'Grade Level'
    },
    {
      name: 'date_of_birth_comp',
      label: 'Date of Birth'
    }
  ];
}
