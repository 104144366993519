export default [
  {
    name: 'id',
    label: 'ID'
  },
  {
    name: 'title',
    label: 'Title'
  },
  {
    name: 'country',
    label: 'Country'
  },
  {
    name: 'school_name',
    label: 'School'
  },
  {
    name: 'project_student_complete',
    label: 'Students'
  },
  {
    name: 'media_link_comp',
    label: 'YT Link',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'media_link_filter',
    label: 'YT Link',
    options: {
      display: false
    }
  },
  {
    name: 'presentation_link_comp',
    label: 'Presentation Link',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'presentation_link_filter',
    label: 'Presentation Link',
    options: {
      display: false
    }
  },
  {
    name: 'group_number',
    label: 'Group Number'
  },
  {
    name: 'place',
    label: 'Place'
  },
  {
    name: 'balance_comp',
    label: 'Balance',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'balance_filter',
    label: 'Balance',
    options: {
      display: false
    }
  },
  {
    name: 'delete',
    label: 'Delete',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  }
];
