import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Stack } from '@mui/material';
import { MenuItem, TextField } from '@material-ui/core';
import crypto from 'crypto';
import { addTemplate, getTemplateType } from '../_apis/serviceCalls';
import { setSnackbar } from '../../../../actions/alertActions';

const TypeDialog = (props) => {
  const { data, handleClose, handleSuccess, dispatch } = props;
  const [typeList, setTypeList] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [loading, setLoading] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    getTemplateType(
      {},
      (data) => {
        setTypeList(data);
      },
      () => {}
    );
  }, []);

  function handleAddTemplate() {
    setLoading(true);
    const formData = new FormData();
    const file = selectedFile;
    formData.append('File', file);
    const hash = crypto.createHash('md5').update(file.name).digest('hex');
    const metaDataParam = {
      term_id: data.id,
      type_id: selectedType,
      file_name: file.name,
      timestamp: file.lastModifiedDate,
      hash,
      file
    };
    addTemplate(
      metaDataParam,
      () => {
        dispatch(setSnackbar('success', 'The file has been uploaded'));
        setLoading(null);
        handleSuccess();
      },
      () => {
        setLoading(null);
      }
    );
  }

  function changeHandler(event) {
    setSelectedFile(event.target.files[0]);
  }

  return (
    <Stack spacing={2}>
      <TextField
        style={{ marginTop: 10 }}
        fullWidth
        label="Template Types"
        select
        value={selectedType}
        onChange={(e) => setSelectedType(e.target.value)}
      >
        {typeList.map((type) => (
          <MenuItem key={type.id} value={type.id}>
            {type.name}
          </MenuItem>
        ))}
      </TextField>
      <div>
        <input
          type="file"
          name="file"
          onChange={changeHandler}
          accept=".jpg,.pdf,.png,.jpeg,.svg"
        />
      </div>
      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="end" spacing={2}>
        <Button
          onClick={handleAddTemplate}
          variant="outlined"
          disabled={loading || !selectedFile || !selectedType}
        >
          Add
        </Button>
        <Button variant="outlined" color="error" onClick={handleClose}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};

TypeDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  handleSuccess: PropTypes.func,
  dispatch: PropTypes.func
};

export default connect(() => ({}))(TypeDialog);
