import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Stack } from '@mui/material';
import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';
import TableBox from '../../../../components/CustomComponents/CustomCard/TableBox';
import headerVariablesForProject from '../_utils/headerVariablesForProject';
import MuiTable from '../../../../components/CustomComponents/MuiTable';

function Project({ projectList, dispatch, receiptSelect, idList, updateIndex }) {
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [addPayment, setAddPayment] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    handleCancel();
  }, [updateIndex]);

  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    if (receiptSelect) {
      const list = [];
      allRowsSelected.forEach((item) => {
        list.push(projectList[item.index].id);
      });
      const updatedList = { ...idList, project: list };
      dispatch({
        type: 'SET_ID_LIST',
        payload: updatedList
      });
      setSelectedStudents(rowsSelected);
    } else {
      let totalFee = parseFloat('0').toFixed(2);
      const list = [];
      allRowsSelected.forEach((item) => {
        totalFee = parseFloat(totalFee) + parseFloat(projectList[item.index].balance);
        list.push(projectList[item.index]);
      });
      setSelectedStudents(rowsSelected);
      dispatch({ type: 'ADD_FEE', payload: totalFee });
      dispatch({ type: 'ADD_ITEM_CART_FROM_PROJECTS', payload: list });
    }
  };

  const handleCancel = () => {
    dispatch({ type: 'CLEAR_CART' });
    setSelectedStudents([]);
    setAddPayment(false);
  };

  const startButtonDisabled = selectedStudents.length === 0;

  function CustomHeader() {
    return (
      <Stack spacing={3} direction={{ sm: 'row' }}>
        {!addPayment && projectList.length > 0 && !receiptSelect && (
          <Button
            startIcon={<Icon icon="ri:secure-payment-fill" width={22} height={22} />}
            color="secondary"
            variant="outlined"
            onClick={() => setAddPayment(true)}
          >
            Make Payment
          </Button>
        )}
        {addPayment && (
          <div>
            <Button
              color="secondary"
              variant="outlined"
              disabled={startButtonDisabled}
              onClick={() => {
                navigate('/dashboard/payments_for_projects', {
                  replace: true,
                  state: { fromGeneralPayments: true }
                });
              }}
            >
              Checkout
            </Button>
            <Button
              variant="outlined"
              color="error"
              style={{ marginLeft: 10 }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        )}
      </Stack>
    );
  }

  return (
    <>
      {projectList.length > 0 && (
        <TableBox
          title="Project Payments"
          Comp={
            <MuiTable
              title={<CustomHeader />}
              rowsPerPage={5}
              tableData={projectList}
              tableColumns={headerVariablesForProject}
              customOption={{
                selectableRows: addPayment || receiptSelect ? 'multiple' : 'none',
                selectableRowsOnClick: true,
                onRowSelectionChange,
                rowsSelected: selectedStudents,
                isRowSelectable: (dataIndex) =>
                  addPayment
                    ? parseFloat(projectList[dataIndex].balance) > 0
                    : parseFloat(projectList[dataIndex].balance) < 0 ||
                      parseFloat(projectList[dataIndex].balance) === 0
              }}
            />
          }
        />
      )}
    </>
  );
}

Project.propTypes = {
  dispatch: PropTypes.func.isRequired,
  projectList: PropTypes.array,
  receiptSelect: PropTypes.bool,
  idList: PropTypes.array,
  updateIndex: PropTypes.number
};

export default connect((store) => ({
  receiptSelect: store.headerReducer.receiptSelect,
  updateIndex: store.headerReducer.updateIndex,
  idList: store.headerReducer.idList
}))(Project);
