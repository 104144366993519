import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { MenuItem, Stack, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { updateSchoolOperation } from '../serviceCalls';
import { setSnackbar } from '../../../../actions/alertActions';
import { getCountryList, getStateList } from '../../../../actions/registerActions';
// ----------------------------------------------------------------------
const US_CODE = 244;
const phoneRegExp = /^\+?(\d{10,13})$/gm;

function UpdateDialog(props) {
  const { data, handleRefresh, dispatch, handleClose } = props;
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [schema, setSchema] = useState(
    Yup.object().shape({
      main_contact_first_name: Yup.string()
        .min(2, 'Too Short!')
        .max(500, 'Too Long!')
        .matches('^[\x20-\x7F]+$', 'Please enter only English characters!')
        .required('First name required'),
      main_contact_last_name: Yup.string()
        .min(2, 'Too Short!')
        .max(500, 'Too Long!')
        .matches('^[\x20-\x7F]+$', 'Please enter only English characters!')
        .required('Last name required'),
      name: Yup.string()
        .min(2, 'Too Short!')
        .max(500, 'Too Long!')
        .matches('^[\x20-\x7F]+$', 'Please enter only English characters!')
        .required('School name required'),
      main_contact_email: Yup.string()
        .max(500, 'Too Long!')
        .email('Email must be a valid email address')
        .required('Main Contact Email is required'),
      phone_number: Yup.string()
        .max(500, 'Too Long!')
        .matches(phoneRegExp, 'Phone number must be in the following format,+15554443322')
        .required('Phone number is required'),
      zip: Yup.string().max(500, 'Too Long!').required('Zip is required'),
      country_id: Yup.string().required('Country is required'),
      state_id: Yup.string().nullable(),
      city: Yup.string().max(500, 'Too Long!').required('City is required'),
      street: Yup.string().max(500, 'Too Long!').required('Street is required')
    })
  );
  useEffect(() => {
    getCountryList(
      {},
      (data) => {
        setCountryList(data);
      },
      () => {}
    );
    getStateList(
      {},
      (data) => {
        setStateList(data);
      },
      () => {}
    );
  }, []);

  const formik = useFormik({
    initialValues: {
      main_contact_first_name: data?.main_contact_first_name || '',
      main_contact_last_name: data?.main_contact_last_name || '',
      name: data?.name || '',
      main_contact_email: data?.main_contact_email || '',
      phone_number: data?.phone_number || '',
      address: data?.address || '',
      zip: data?.zip || '',
      country_id: data?.country_id || '',
      state_id: data?.state_id || '',
      city: data?.city || '',
      street: data?.street || '',
      unit_number: data?.unit_number || ''
    },
    validationSchema: schema,
    onSubmit: (values) => {
      const obj = { school_id: data.id, ...values };
      const { address, ...params } = obj;
      let paramsWithoutStateId = {};
      if (!values.state_id) {
        const { state_id, ...params2 } = params;
        paramsWithoutStateId = params2;
        console.log(state_id);
      }
      console.log(address);
      const lastParam = values.state_id ? params : paramsWithoutStateId;
      updateSchoolOperation(
        lastParam,
        () => {
          dispatch(setSnackbar('success', 'The school info has been updated'));
          handleRefresh();
        },
        () => {}
      );
    }
  });
  const { errors, touched, handleSubmit, getFieldProps, isSubmitting, setFieldValue, values } =
    formik;

  useEffect(() => {
    if (values.country_id !== US_CODE) {
      setFieldValue('state_id', null);
    }
    setSchema(
      Yup.object().shape({
        main_contact_first_name: Yup.string()
          .min(2, 'Too Short!')
          .max(500, 'Too Long!')
          .matches('^[\x20-\x7F]+$', 'Please enter only English characters!')
          .required('First name required'),
        main_contact_last_name: Yup.string()
          .min(2, 'Too Short!')
          .max(500, 'Too Long!')
          .matches('^[\x20-\x7F]+$', 'Please enter only English characters!')
          .required('Last name required'),
        name: Yup.string()
          .min(2, 'Too Short!')
          .max(500, 'Too Long!')
          .matches('^[\x20-\x7F]+$', 'Please enter only English characters!')
          .required('School name required'),
        main_contact_email: Yup.string()
          .max(500, 'Too Long!')
          .email('Email must be a valid email address')
          .required('Main Contact Email is required'),
        phone_number: Yup.string()
          .max(500, 'Too Long!')
          .matches(phoneRegExp, 'Phone number must be in the following format,+15554443322')
          .required('Phone number is required'),
        zip: Yup.string().max(500, 'Too Long!').required('Zip is required'),
        country_id: Yup.string().required('Country is required'),
        city: Yup.string().max(500, 'Too Long!').required('City is required'),
        street: Yup.string().max(500, 'Too Long!').required('Street is required'),
        state_id:
          values.country_id === US_CODE
            ? Yup.string().required('State is required').nullable()
            : Yup.string().nullable()
      })
    );
  }, [values.country_id]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit} spacing={2}>
        <Stack spacing={3} sx={{ mt: 1 }}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="School Name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
            <TextField
              fullWidth
              label="Main Contact First Name"
              {...getFieldProps('main_contact_first_name')}
              error={Boolean(touched.main_contact_first_name && errors.main_contact_first_name)}
              helperText={touched.main_contact_first_name && errors.main_contact_first_name}
            />
            <TextField
              fullWidth
              label="Main Contact Last Name"
              {...getFieldProps('main_contact_last_name')}
              error={Boolean(touched.main_contact_last_name && errors.main_contact_last_name)}
              helperText={touched.main_contact_last_name && errors.main_contact_last_name}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Phone Number"
              {...getFieldProps('phone_number')}
              error={Boolean(touched.phone_number && errors.phone_number)}
              helperText={touched.phone_number && errors.phone_number}
            />
            <TextField
              fullWidth
              type="Email"
              label="Main Contact Email"
              {...getFieldProps('main_contact_email')}
              error={Boolean(touched.main_contact_email && errors.main_contact_email)}
              helperText={touched.main_contact_email && errors.main_contact_email}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Country"
              select
              {...getFieldProps('country_id')}
              error={Boolean(touched.country_id && errors.country_id)}
              helperText={touched.country_id && errors.country_id}
            >
              {countryList.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
            {values.country_id === US_CODE && (
              <TextField
                fullWidth
                label="State"
                select
                {...getFieldProps('state_id')}
                error={Boolean(touched.state_id && errors.state_id)}
                helperText={touched.state_id && errors.state_id}
              >
                {stateList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Street"
              {...getFieldProps('street')}
              error={Boolean(touched.street && errors.street)}
              helperText={touched.street && errors.street}
            />
            <TextField
              fullWidth
              label="Unit Number"
              {...getFieldProps('unit_number')}
              error={Boolean(touched.unit_number && errors.unit_number)}
              helperText={touched.unit_number && errors.unit_number}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="City"
              {...getFieldProps('city')}
              error={Boolean(touched.city && errors.city)}
              helperText={touched.city && errors.city}
            />
            <TextField
              fullWidth
              label="Zip Code"
              {...getFieldProps('zip')}
              error={Boolean(touched.zip && errors.zip)}
              helperText={touched.zip && errors.zip}
            />
          </Stack>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="end"
          mb={5}
          style={{ marginTop: 20 }}
          spacing={1}
        >
          <Button type="submit" variant="outlined" loading={isSubmitting}>
            Update
          </Button>
          <Button variant="outlined" color="error" onClick={handleClose}>
            Cancel
          </Button>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

UpdateDialog.propTypes = {
  dispatch: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  handleClose: PropTypes.func
};

export default connect(() => ({}))(UpdateDialog);
