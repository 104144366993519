import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
import { Stack, TextField, IconButton, InputAdornment, MenuItem } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import handleRegister, { getCountryList, getStateList } from '../../actions/registerActions';
import { setSnackbarForMain } from '../../actions/alertActions';
import CustomSnackbar from '../../components/CustomComponents/CustomSnackbar';

// ----------------------------------------------------------------------
const US_CODE = 244;
const phoneRegExp = /^\+?(\d{10,13})$/gm;
const englishChar = /^[-a-zA-Z0-9& ]+$/;

function RegisterForm(props) {
  const { dispatch } = props;
  const navigate = useNavigate();
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [schema, setSchema] = useState(
    Yup.object().shape({
      first_name: Yup.string()
        .min(2, 'Too Short!')
        .max(500, 'Too Long!')
        .matches(englishChar, 'Please enter only English characters')
        .required('First name is required'),
      last_name: Yup.string()
        .min(2, 'Too Short!')
        .max(500, 'Too Long!')
        .matches(englishChar, 'Please enter only English characters')
        .required('Last name is required'),
      email: Yup.string()
        .max(500, 'Too Long!')
        .email('Email must be a valid email address')
        .required('Email is required'),
      password: Yup.string()
        .max(500, 'Too Long!')
        .min(6, 'Password should be of minimum 6 characters length!')
        .required('Password is required'),
      phone_number: Yup.string()
        .max(500, 'Too Long!')
        .matches(phoneRegExp, 'Phone number must be in the following format,+15554443322')
        .required('Phone number is required'),
      zip: Yup.string().max(500, 'Too Long!').required('Zip is required'),
      country_id: Yup.string().required('Country is required'),
      city: Yup.string().max(500, 'Too Long!').required('City is required'),
      street: Yup.string().max(500, 'Too Long!').required('Street is required'),
      state_id: Yup.string().nullable(),
      unit_number: Yup.string().max(500, 'Too Long!')
    })
  );

  useEffect(() => {
    getCountryList(
      {},
      (data) => {
        setCountryList(data);
      },
      () => {}
    );
    getStateList(
      {},
      (data) => {
        setStateList(data);
      },
      () => {}
    );
  }, []);

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      zip: '',
      country_id: '',
      state_id: '',
      city: '',
      street: '',
      unit_number: '',
      phone_number: ''
    },
    validationSchema: schema,
    onSubmit: (values, { setSubmitting }) => {
      const param = {
        ...values,
        first_name: values.first_name.toUpperCase(),
        last_name: values.last_name.toUpperCase()
      };
      handleRegister(
        param,
        () => {
          setSubmitting(false);
          navigate('/', { replace: true });
          dispatch(
            setSnackbarForMain(
              'info',
              'A verification email has been sent, you must verify your email to activate your account within 30 minutes. Once you verified your account, you can login with your credentials.'
            )
          );
        },
        (error) => {
          if (error.is_visible) {
            setIsError(true);
            setErrorText(error.go_error_message);
          }
          setSubmitting(false);
        }
      );
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue, values } =
    formik;

  useEffect(() => {
    if (values.country_id !== US_CODE) {
      setFieldValue('state_id', null);
    }
    setSchema(
      Yup.object().shape({
        first_name: Yup.string()
          .min(2, 'Too Short!')
          .max(500, 'Too Long!')
          .matches(englishChar, 'Please enter only English characters')
          .required('First name is required'),
        last_name: Yup.string()
          .min(2, 'Too Short!')
          .max(500, 'Too Long!')
          .matches(englishChar, 'Please enter only English characters')
          .required('Last name is required'),
        email: Yup.string()
          .max(500, 'Too Long!')
          .email('Email must be a valid email address')
          .required('Email is required'),
        password: Yup.string()
          .max(500, 'Too Long!')
          .min(6, 'Password should be of minimum 6 characters length!')
          .required('Password is required'),
        phone_number: Yup.string()
          .max(500, 'Too Long!')
          .matches(phoneRegExp, 'Phone number must be in the following format,+15554443322')
          .required('Phone number is required'),
        zip: Yup.string().max(500, 'Too Long!').required('Zip is required'),
        country_id: Yup.string().required('Country is required'),
        city: Yup.string().max(500, 'Too Long!').required('City is required'),
        street: Yup.string().max(500, 'Too Long!').required('Street is required'),
        state_id:
          values.country_id === US_CODE
            ? Yup.string().required('State is required').nullable()
            : Yup.string().nullable(),
        unit_number: Yup.string().max(500, 'Too Long!')
      })
    );
  }, [values.country_id]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              inputProps={{ style: { textTransform: 'uppercase' } }}
              fullWidth
              label="First name"
              {...getFieldProps('first_name')}
              error={Boolean(touched.first_name && errors.first_name)}
              helperText={touched.first_name && errors.first_name}
            />
            <TextField
              inputProps={{ style: { textTransform: 'uppercase' } }}
              fullWidth
              label="Last name"
              {...getFieldProps('last_name')}
              error={Boolean(touched.last_name && errors.last_name)}
              helperText={touched.last_name && errors.last_name}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email address"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
            <TextField
              fullWidth
              label="Phone Number"
              {...getFieldProps('phone_number')}
              error={Boolean(touched.phone_number && errors.phone_number)}
              helperText={touched.phone_number && errors.phone_number}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Country"
              select
              {...getFieldProps('country_id')}
              error={Boolean(touched.country_id && errors.country_id)}
              helperText={touched.country_id && errors.country_id}
            >
              {countryList.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
            {values.country_id === US_CODE && (
              <TextField
                fullWidth
                label="State"
                select
                {...getFieldProps('state_id')}
                error={Boolean(touched.state_id && errors.state_id)}
                helperText={touched.state_id && errors.state_id}
              >
                {stateList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Street"
              {...getFieldProps('street')}
              error={Boolean(touched.street && errors.street)}
              helperText={touched.street && errors.street}
            />
            <TextField
              fullWidth
              label="Unit Number"
              {...getFieldProps('unit_number')}
              error={Boolean(touched.unit_number && errors.unit_number)}
              helperText={touched.unit_number && errors.unit_number}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="City"
              {...getFieldProps('city')}
              error={Boolean(touched.city && errors.city)}
              helperText={touched.city && errors.city}
            />
            <TextField
              fullWidth
              label="Zip Code"
              {...getFieldProps('zip')}
              error={Boolean(touched.zip && errors.zip)}
              helperText={touched.zip && errors.zip}
            />
          </Stack>
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          <LoadingButton
            loading={isSubmitting}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            Register
          </LoadingButton>
          {isError && (
            <CustomSnackbar
              settings={{ type: 'error', message: errorText, onHide: () => setIsError(false) }}
            />
          )}
        </Stack>
      </Form>
    </FormikProvider>
  );
}

RegisterForm.propTypes = {
  dispatch: PropTypes.func.isRequired
};

export default connect(() => ({}))(RegisterForm);
