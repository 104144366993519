export default [
  {
    name: 'survey_id',
    label: 'ID'
  },
  {
    name: 'survey_name',
    label: 'Survey Name'
  },
  {
    name: 'number_of_questions',
    label: 'Number of questions'
  },
  {
    name: 'available_score',
    label: 'Available Score'
  },
  {
    name: 'copy',
    label: 'Copy',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'view',
    label: 'View',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'edit',
    label: 'Edit',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'delete',
    label: 'Delete',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  }
];
