import { fetchApi } from '../../../../api/fetchApi';

const travel = require('../../../../api/RestVariables/Application/travel');

export async function addTravel(config, scb, ecb) {
  try {
    const url = travel.ADD_TRAVEL;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_TRAVEL');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateTravel(config, scb, ecb) {
  try {
    const url = travel.UPDATE_TRAVEL;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_TRAVEL');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteTravel(config, scb, ecb) {
  try {
    const url = travel.DELETE_TRAVEL;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_TRAVEL');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getTravel(config, scb, ecb) {
  try {
    const url = travel.GET_TRAVEL;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_TRAVEL');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getTravelType(config, scb, ecb) {
  try {
    const url = travel.GET_TRAVEL_TYPE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_TRAVEL_TYPE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getTravelOption(config, scb, ecb) {
  try {
    const url = travel.GET_TRAVEL_OPTION;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_TRAVEL_OPTION');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getTravelParticipant(config, scb, ecb) {
  try {
    const url = travel.GET_TRAVEL_PARTICIPANT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_TRAVEL_PARTICIPANT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addTravelParticipant(config, scb, ecb) {
  try {
    const url = travel.ADD_TRAVEL_PARTICIPANT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_TRAVEL_PARTICIPANT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}
