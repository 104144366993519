import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { IconButton, MenuItem, TextField } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { DialogContent, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import MuiTable from '../../../../components/CustomComponents/MuiTable';
import { fetchFeeList } from '../serviceCalls';
import headers from './utils/headerVariables';
import CustomDialog from '../../../../components/CustomComponents/CustomDialog';
import AddUpdateDialog from './utils/AddUpdateDialog';
import { fCurrency } from '../../../../utils/formatNumber';
import Page from '../../../../components/Page';
import ProcessInfoBox from '../../../../components/CustomComponents/CustomCard/ProcessInfoBox';

function Index(props) {
  const { terms, active_term } = props;
  const [term, setTerm] = useState(active_term);
  const [tableData, setTableData] = useState([]);
  const [addUpdateDialog, setAddUpdateDialog] = useState(false);
  const [editData, setEditData] = useState(null);
  const [guidelines, setGuidelines] = useState([]);

  useEffect(() => {
    fetchData();
  }, [term]);
  function fetchData() {
    fetchFeeList(
      { term_id: term },
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((item) => {
          item.amount_comp = fCurrency(item.amount);
          item.edit = (
            <IconButton color="secondary" onClick={() => handleEditFee(item)}>
              <Icon icon="ci:edit" width={24} height={24} />
            </IconButton>
          );
        });
        setTableData(list);
      },
      () => {}
    );
  }
  function handleEditFee(data) {
    setAddUpdateDialog(true);
    setEditData(data);
  }
  function refreshData() {
    setAddUpdateDialog(false);
    fetchData();
  }
  function TermSelect() {
    return (
      <TextField select label="Terms" value={term} onChange={(e) => setTerm(e.target.value)}>
        {terms.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.description}
          </MenuItem>
        ))}
      </TextField>
    );
  }
  return (
    <Page title="Fee Settings | Genius">
      <Stack spacing={2}>
        {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
        <MuiTable tableData={tableData} tableColumns={headers} title={<TermSelect />} />
      </Stack>
      <CustomDialog onClose={() => setAddUpdateDialog(false)} open={addUpdateDialog} title="UPDATE">
        <DialogContent>
          <AddUpdateDialog
            data={editData}
            handleClose={() => setAddUpdateDialog(false)}
            refreshData={refreshData}
          />
        </DialogContent>
      </CustomDialog>
    </Page>
  );
}

Index.propTypes = {
  terms: PropTypes.array,
  active_term: PropTypes.number
};

export default connect((store) => ({
  terms: store.loginReducer.terms,
  active_term: store.loginReducer.active_term
}))(Index);
