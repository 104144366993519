import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LandingPageCard from '../../../components/CustomComponents/CustomCard/LandingPageCard';
import Page from '../../../components/Page';

const CARDS = [
  {
    id: 1,
    name: 'Terms',
    description: 'From this module you can arrange terms.'
  },
  {
    id: 2,
    name: 'Disciplines',
    description: 'From this module you can arrange disciplines.'
  },
  {
    id: 3,
    name: 'Categories',
    description: 'From this module you can arrange categories.'
  },
  {
    id: 4,
    name: 'Trips',
    description: 'From this module you can arrange trips.'
  },
  {
    id: 5,
    name: 'Fees',
    description: 'From this module you can arrange fees.'
  }
];

function Index() {
  const navigate = useNavigate();
  function generate(data) {
    // eslint-disable-next-line no-lone-blocks
    {
      // eslint-disable-next-line no-unused-expressions
      data.id === 1
        ? navigate('/admin/settings/terms', { replace: true })
        : data.id === 2
        ? navigate('/admin/settings/disciplines', { replace: true })
        : data.id === 3
        ? navigate('/admin/settings/category', { replace: true })
        : data.id === 4
        ? navigate('/admin/settings/trip', { replace: true })
        : navigate('/admin/settings/fee', { replace: true });
    }
  }

  return (
    <Page title="Settings | Genius">
      <Grid container spacing={2}>
        {CARDS.map((item, index) => (
          <Grid item xs={3} key={index}>
            <LandingPageCard data={item} generate={generate} />
          </Grid>
        ))}
      </Grid>
    </Page>
  );
}

export default Index;
