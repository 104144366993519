import React from 'react';
import PropTypes from 'prop-types';
import SweetAlert from 'react-bootstrap-sweetalert';
import withStyles from '@material-ui/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button } from '@mui/material';
import sweetAlertStyle from './style';

const confirmationAlertStyle = {
  ...sweetAlertStyle
};

class ConfirmationAlert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: false
    };
  }

  handleConfirm() {
    this.props.settings.onConfirm();
    this.setState({ isDisabled: true });
  }

  render() {
    const { settings } = this.props;
    const { isDisabled } = this.state;
    return (
      <SweetAlert
        dependencies={[isDisabled]}
        type="warning"
        showCancel
        confirmBtnText={settings.confirmBtnText}
        style={{ display: 'block' }}
        title={settings.title}
        disabled={isDisabled}
        onConfirm={() => this.handleConfirm()}
        onCancel={() => settings.onCancel()}
        customButtons={
          <>
            <Button
              onClick={settings.onCancel}
              style={{ marginRight: 5 }}
              color="error"
              disabled={isDisabled}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              onClick={(props) => this.handleConfirm(props)}
              style={{ marginLeft: 5 }}
              color="primary"
              variant="outlined"
              disabled={isDisabled}
              icon={isDisabled && <CircularProgress size={20} color="inherit" thickness={4} />}
            >
              {settings.confirmBtnText || 'Confirm'}
            </Button>
          </>
        }
      >
        {settings.message}
      </SweetAlert>
    );
  }
}

ConfirmationAlert.propTypes = {
  settings: PropTypes.shape({
    confirmBtnText: PropTypes.string,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    title: PropTypes.string,
    message: PropTypes.string
  })
};

export default withStyles(confirmationAlertStyle)(ConfirmationAlert);
