export default function reducer(
  state = {
    buttonVisible: false,
    receiptSelect: false,
    idList: { project: [], participant: [], trip: [] },
    disabledButton: true,
    updateIndex: 0
  },
  action
) {
  switch (action.type) {
    case 'SET_RECEIPT_BUTTON_VISIBILITY': {
      return {
        ...state,
        buttonVisible: action.payload
      };
    }
    case 'SET_RECEIPT_SELECTION': {
      return {
        ...state,
        receiptSelect: action.payload
      };
    }
    case 'SET_RECEIPT_BUTTON_ENABALTY': {
      return {
        ...state,
        disabledButton: action.payload
      };
    }
    case 'SET_ID_LIST': {
      return {
        ...state,
        idList: action.payload
      };
    }
    case 'SET_UPDATE_INDEX': {
      return {
        ...state,
        updateIndex: state.updateIndex + 1
      };
    }
    case 'CLEAR_ALL_HEADER': {
      return {
        buttonVisible: false,
        receiptSelect: false,
        idList: { project: [], participant: [], trip: [] },
        disabledButton: true,
        updateIndex: 0
      };
    }
    default: {
      return state;
    }
  }
}
