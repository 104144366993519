import { Grid, IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Icon } from '@iconify/react';
import { DialogContent, Stack } from '@mui/material';
import ReactHtmlParser from 'react-html-parser';
import MuiTable from '../../../components/CustomComponents/MuiTable';
import headers from './utils/headerVariables';
import Label from '../../../components/Label';
import { deletePresentation, getPresentation } from './utils/serviceCalls';
import CustomDialog from '../../../components/CustomComponents/CustomDialog';
import AddUpdateDialog from './AddUpdateDialog';
import { setConfirmationAlert, setSnackbar } from '../../../actions/alertActions';
import Page from '../../../components/Page';
import ProcessInfoBox from '../../../components/CustomComponents/CustomCard/ProcessInfoBox';
import { ParticipantInfo } from '../../../info/ParticipantInfo';
import ProcessWarningBox from '../../../components/CustomComponents/CustomCard/ProcessWarningBox';

function Index(props) {
  const { dispatch, isFinalist } = props;
  const [tableData, setTableData] = useState([]);
  const [dialogShow, setDialogShow] = useState(false);
  const [editData, setEditData] = useState(null);
  const [guidelines, setGuidelines] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    getPresentation(
      {},
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((item) => {
          item.name = `${item.first_name} ${item.last_name}`;
          item.presentation_link_comp = item.presentation_link ? (
            <Label variant="ghost" color="primary">
              <a target="_blank" href={item.presentation_link} rel="noreferrer">
                Link
              </a>
            </Label>
          ) : (
            <p
              style={{
                fontWeight: 'bold',
                color: '#FF4842'
              }}
            >
              Missing
            </p>
          );
          item.presentation_link_filter = item.presentation_link ? 'Exist' : 'None';
          item.edit = (
            <IconButton color="secondary" onClick={() => handleEditTravel(item)}>
              <Icon icon="ant-design:play-circle-twotone" width={24} height={24} />
            </IconButton>
          );
          item.delete = (
            <IconButton
              color="secondary"
              disabled={item.presentation_link === null}
              onClick={() => handleDeleteTravel(item.project_id)}
            >
              <Icon icon="fluent:delete-48-filled" width={24} height={24} />
            </IconButton>
          );
        });
        setTableData(list);
      },
      () => {}
    );
  }
  function handleEditTravel(item) {
    setEditData(item);
    setDialogShow(true);
  }
  function handleDeleteTravel(project_id) {
    const param = { project_id };
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'This action will delete project presentation',
        () => {
          deletePresentation(
            param,
            () => {
              dispatch(setSnackbar('success', 'The project presentation has been deleted'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
              handleRefresh();
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }
  function handleRefresh() {
    setDialogShow(false);
    fetchData();
  }

  return (
    <Page title="Presentation | Genius">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {isFinalist ? (
            <Stack spacing={1}>
              {guidelines.length > 0 && (
                <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />
              )}
              <MuiTable tableColumns={headers} tableData={tableData} />
            </Stack>
          ) : (
            <ProcessWarningBox Comp={<ParticipantInfo />} />
          )}
        </Grid>
        <CustomDialog
          onClose={() => setDialogShow(false)}
          open={dialogShow}
          maxWidth="lg"
          title="Presentation Update"
        >
          <DialogContent>
            <AddUpdateDialog
              projectData={editData}
              handleClose={() => setDialogShow(false)}
              handleRefresh={handleRefresh}
            />
          </DialogContent>
        </CustomDialog>
      </Grid>
    </Page>
  );
}

Index.propTypes = {
  dispatch: PropTypes.func,
  isFinalist: PropTypes.bool
};

export default connect((store) => ({
  isFinalist: store.dashboardReducer.isFinalist
}))(Index);
