import React, { useEffect, useState } from 'react';
import { Button, DialogContent, Stack, Tooltip } from '@mui/material';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow';
import { IconButton, MenuItem, TableCell, TextField } from '@material-ui/core';
import Email from '@mui/icons-material/Email';
import Page from '../../../components/Page';
import { setConfirmationAlert, setLoading, setSnackbar } from '../../../actions/alertActions';
import { dataMaker } from './_utils/dataMaker';
import ProcessInfoBox from '../../../components/CustomComponents/CustomCard/ProcessInfoBox';
import { addCodingTeam, addScoreboard, getScoreboard } from './_apis/serviceCalls';
import MuiTable from '../../../components/CustomComponents/MuiTable';
import headers from './_utils/headerVariables';
import ExpandView from './ExpandView/ExpandView';
import CustomDialog from '../../../components/CustomComponents/CustomDialog';
import CustomMail from '../../../components/CustomComponents/CustomMail';
import { CODING_MAIL_TYPE } from '../../../api/RestVariables/staticVariables';
import FinalistStatusDialog from './Components/FinalistStatusDialog';
import StatusDialog from './Components/StatusDialog';
import CarbonScoreDialog from './Components/CarbonScoreDialog';

function Index(props) {
  const { dispatch, active_term, terms, isCommunication } = props;
  const [tableData, setTableData] = useState([]);
  const [guidelines, setGuidelines] = useState([]);
  const [term, setTerm] = useState(active_term);
  const [mailDialogShow, setMailDialogShow] = useState(false);
  const [addMail, setAddMail] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [projectIdList, setProjectIdList] = useState([]);
  const [team, setTeam] = useState(false);
  const [statusDialog, setStatusDialog] = useState(false);
  const [finalistDialogShow, setFinalistDialogShow] = useState(false);
  const [editData, setEditData] = useState(null);
  const [addStatus, setAddStatus] = useState(false);
  const [carbonDialog, setCarbonDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    fetchData();
  }, [term]);

  function fetchData() {
    dispatch(setLoading(1, 'Loading...'));
    getScoreboard({ term_id: term }, (data) => {
      setGuidelines(data.guidelines);
      const list = dataMaker(
        data.data,
        handleChangeFinalistStatus,
        handleChangeReviewStatus,
        handleChangeCarbonScore
      );
      setTableData(list);
    }).then(() => dispatch(setLoading(0, '')));
  }

  function handleClickScoreboard() {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, CONTINUE',
        'Scores will be transferred from the DOMJudge system. Do you want to continue?',
        () => {
          addScoreboard({ term_id: term }, () => {
            fetchData();
            dispatch(setSnackbar('success', 'Scoreboard added successfully'));
          }).then(() => dispatch({ type: 'RESET_CONFIRMATION_ALERT' }));
        }
      )
    );
  }

  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedItems(rowsSelected);
    const arr = [];
    rowsSelected.forEach((item) => {
      arr.push(tableData[item].project_id);
    });
    setProjectIdList(arr);
  };

  function handleAddTeam() {
    addCodingTeam({ project_ids: projectIdList }, () => {
      dispatch(setSnackbar('success', 'Coding Team added successfully'));
      handleRefresh();
    });
  }

  function handleChangeCarbonScore(item) {
    setSelectedItem(item);
    setCarbonDialog(true);
  }

  function TermSelect() {
    return (
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 2 }}>
        <TextField select label="Terms" value={term} onChange={(e) => setTerm(e.target.value)}>
          {terms.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
        {!addMail && !team && !addStatus && (
          <div className="header-button-style">
            <Button variant="outlined" onClick={() => setTeam(true)}>
              Add Team
            </Button>
          </div>
        )}
        {!addMail && !team && !addStatus && (
          <div className="header-button-style">
            <Button variant="outlined" onClick={handleClickScoreboard} color="secondary">
              Scoreboard
            </Button>
          </div>
        )}
        {!addStatus && !addMail && !team && (
          <div className="header-button-style">
            <Button color="secondary" variant="outlined" onClick={() => setAddStatus(true)}>
              Status
            </Button>
          </div>
        )}
        {team && (
          <div className="header-button-style">
            <Button
              color="primary"
              variant="outlined"
              disabled={startButtonDisabled}
              onClick={handleAddTeam}
            >
              Add
            </Button>
            <Button
              variant="outlined"
              color="error"
              style={{ marginLeft: 10 }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        )}
        {addMail && !team && (
          <div>
            <Button
              style={{ marginRight: 5 }}
              disabled={selectedItems.length === 0}
              variant="outlined"
              onClick={() => setMailDialogShow(true)}
            >
              Next
            </Button>
            <Button color="error" variant="outlined" onClick={() => setAddMail(false)}>
              Cancel
            </Button>
          </div>
        )}
        {addStatus && (
          <div className="header-button-style">
            <Button
              color="secondary"
              variant="outlined"
              disabled={startButtonDisabled}
              onClick={() => setStatusDialog(true)}
            >
              Next
            </Button>
            <Button
              variant="outlined"
              color="error"
              style={{ marginLeft: 10 }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        )}
      </Stack>
    );
  }

  const handleCancel = () => {
    setSelectedItems([]);
    setTeam(false);
    setAddStatus(false);
    setCarbonDialog(false);
    setSelectedItem(null);
  };

  function handleRefresh() {
    setMailDialogShow(false);
    setAddMail(false);
    setSelectedItems([]);
    setTeam(false);
    setStatusDialog(false);
    setFinalistDialogShow(false);
    setEditData(null);
    setAddStatus(false);
    setCarbonDialog(false);
    setSelectedItem(null);
    fetchData();
  }

  function handleChangeReviewStatus(index) {
    setStatusDialog(true);
    setSelectedItems([index]);
  }

  function handleChangeFinalistStatus(project) {
    setEditData(project);
    setFinalistDialogShow(true);
  }

  const startButtonDisabled = selectedItems.length === 0;

  return (
    <Page title="Coding Operation | Genius">
      <Stack spacing={2}>
        {guidelines?.length > 0 && (
          <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0]?.markdown)} />
        )}
        <MuiTable
          tableData={tableData}
          tableColumns={headers}
          title={<TermSelect />}
          customOption={{
            selectableRows: addMail || team || addStatus ? 'multiple' : 'none',
            onRowSelectionChange,
            isRowExpandable: (dataIndex) =>
              tableData[dataIndex].team_id && tableData[dataIndex].contest_id,
            rowsSelected: selectedItems,
            expandableRows: true,
            expandableRowsHeader: false,
            renderExpandableRow: (rowData, rowMeta) => {
              const project = tableData[rowMeta.dataIndex];
              const colSpan = rowData.length + 1;
              return (
                <TableRow>
                  <TableCell colSpan={colSpan}>
                    <ExpandView project={project} />
                  </TableCell>
                </TableRow>
              );
            },
            customToolbar: () =>
              isCommunication !== -1 && (
                <Tooltip title="Send Email">
                  <IconButton onClick={() => setAddMail(true)}>
                    <Email />
                  </IconButton>
                </Tooltip>
              )
          }}
        />
        <CustomDialog
          onClose={() => setMailDialogShow(false)}
          open={mailDialogShow}
          maxWidth="lg"
          title="Send Mail"
          fullWidth
          disableEnforceFocus
        >
          <DialogContent>
            <CustomMail
              handleRefresh={handleRefresh}
              mailType={CODING_MAIL_TYPE}
              idList={projectIdList}
              handleClose={() => setMailDialogShow(false)}
            />
          </DialogContent>
        </CustomDialog>
        <CustomDialog
          onClose={() => setStatusDialog(false)}
          open={statusDialog}
          maxWidth="lg"
          title="Review Status"
        >
          <DialogContent>
            <StatusDialog
              tableData={tableData}
              handleClose={() => setStatusDialog(false)}
              selectedItems={selectedItems}
              handleRefresh={handleRefresh}
            />
          </DialogContent>
        </CustomDialog>
        <CustomDialog
          onClose={() => setFinalistDialogShow(false)}
          open={finalistDialogShow}
          maxWidth="lg"
          title="Finalist Status"
        >
          <DialogContent>
            <FinalistStatusDialog
              data={editData}
              handleClose={() => setFinalistDialogShow(false)}
              handleRefresh={handleRefresh}
            />
          </DialogContent>
        </CustomDialog>
      </Stack>
      {carbonDialog && (
        <CarbonScoreDialog
          data={selectedItem}
          handleClose={handleCancel}
          handleRefresh={handleRefresh}
        />
      )}
    </Page>
  );
}

Index.propTypes = {
  dispatch: PropTypes.func,
  active_term: PropTypes.number,
  terms: PropTypes.array,
  isCommunication: PropTypes.number
};

export default connect((store) => ({
  active_term: store.loginReducer.active_term,
  terms: store.loginReducer.terms,
  isCommunication: store.routeReducer.isCommunication
}))(Index);
