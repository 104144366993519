import React, { useEffect, useState } from 'react';
import { IconButton } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { connect } from 'react-redux';
import { DialogContent } from '@mui/material';
import ReactHtmlParser from 'react-html-parser';
import MuiTable from '../../components/CustomComponents/MuiTable';
import headers from './utils/headerVariables';
import { getReviewAdminList } from './serviceCalls';
import Label from '../../components/Label';
import CustomDialog from '../../components/CustomComponents/CustomDialog';
import ScoreDetail from './utils/ScoreDialog';
import ProcessInfoBox from '../../components/CustomComponents/CustomCard/ProcessInfoBox';
import ViewFileDialog from './Components/ViewFileDialog';
import fileMaker from '../Application/Projects/_utils/fileMaker';
import { checkListMaker } from '../Admin/ProjectOperation/Info/_utils/dataMaker';

function Index() {
  const [tableData, setTableData] = useState([]);
  const [dialog, setDialog] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileDialog, setFileDialog] = useState(false);
  const [guidelines, setGuidelines] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);
  function fetchData() {
    getReviewAdminList(
      {},
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((item) => {
          item.file_comp = fileMaker(item.files, viewFile);
          item.file_check_list = checkListMaker(item.files);
          item.file_filter = item.project_file_id ? 'Exist' : 'None';
          item.file_check_list_filter = checkListMaker(item.files) ? 'Exist' : 'None';
          item.media_link_comp = item.media_link && (
            <Label variant="ghost" color="primary">
              <a target="_blank" href={item.media_link} rel="noreferrer">
                Link
              </a>
            </Label>
          );
          item.media_link_filter = item.media_link ? 'Exist' : 'None';
          item.score = (
            <IconButton color="secondary" onClick={() => handleScoreProject(item)}>
              <Icon icon="clarity:note-edit-line" width={24} height={24} />
            </IconButton>
          );
        });
        setTableData(list);
      },
      () => {}
    );
  }
  function handleScoreProject(project) {
    setSelectedProject(project);
    setDialog(true);
  }
  function handleRefresh() {
    fetchData();
    setDialog(false);
  }
  function viewFile(file) {
    const obj = {
      id: file.id,
      name: file.name
    };
    setSelectedFile(obj);
    setFileDialog(true);
  }

  return (
    <>
      {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
      <br />
      <MuiTable tableData={tableData} tableColumns={headers} />
      <CustomDialog onClose={() => setDialog(false)} open={dialog} maxWidth="md" title="Score">
        <DialogContent>
          <ScoreDetail
            data={selectedProject}
            handleClose={() => setDialog(false)}
            handleRefresh={handleRefresh}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        maxWidth="lg"
        fullScreen
        onClose={() => setFileDialog(false)}
        open={fileDialog}
        title="File"
      >
        <DialogContent>
          <ViewFileDialog handleClose={() => setFileDialog(false)} file={selectedFile} />
        </DialogContent>
      </CustomDialog>
    </>
  );
}

export default connect(() => ({}))(Index);
