import { Stack, TextField } from '@material-ui/core';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@material-ui/lab';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { addTrip, updateTrip } from '../../serviceCalls';
import { setLoading, setSnackbar } from '../../../../../actions/alertActions';

const ADD_TRIP = 0;

function AddUpdateDialog(props) {
  const { data, mode, handleClose, dispatch, refreshData, term } = props;

  const Schema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Too Short!')
      .max(500, 'Too Long!')
      .matches('^[\x20-\x7F]+$', 'Please enter only English characters!')
      .required('Trip name required'),
    description: Yup.string()
      .min(2, 'Too Short!')
      .max(500, 'Too Long!')
      .required('Description required'),
    capacity: Yup.number().min(0).required('Capacity required'),
    fee: Yup.number().min(0).required('Fee amount required')
  });

  const formik = useFormik({
    initialValues: {
      name: mode === ADD_TRIP ? '' : data.name,
      description: mode === ADD_TRIP ? '' : data.description,
      capacity: mode === ADD_TRIP ? '' : data.capacity,
      fee: mode === ADD_TRIP ? '' : data.fee
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      const param = { ...values, term_id: term };
      // eslint-disable-next-line no-unused-expressions
      mode === ADD_TRIP
        ? addTrip(
            param,
            () => {
              dispatch(setLoading(0, ''));
              refreshData();
              dispatch(setSnackbar('success', 'The trip has been added'));
            },
            () => {
              dispatch(setLoading(0, ''));
            }
          )
        : updateTrip(
            { ...param, id: data.id },
            () => {
              refreshData();
              dispatch(setLoading(0, ''));
              dispatch(setSnackbar('success', 'The trip has been updated'));
            },
            () => {
              dispatch(setLoading(0, ''));
            }
          );
    }
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{ mt: 1 }}>
          <TextField
            fullWidth
            label="Trip name"
            {...getFieldProps('name')}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
          <TextField
            fullWidth
            label="Description"
            {...getFieldProps('description')}
            error={Boolean(touched.description && errors.description)}
            helperText={touched.description && errors.description}
          />
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              type="number"
              fullWidth
              label="Capacity"
              {...getFieldProps('capacity')}
              error={Boolean(touched.capacity && errors.capacity)}
              helperText={touched.capacity && errors.capacity}
            />
            <TextField
              type="number"
              fullWidth
              label="Fee($)"
              {...getFieldProps('fee')}
              error={Boolean(touched.fee && errors.fee)}
              helperText={touched.fee && errors.fee}
            />
          </Stack>
          <Stack direction="row" justifyContent="end" spacing={1}>
            <LoadingButton size="small" type="submit" variant="outlined">
              {mode === ADD_TRIP ? 'Add' : 'Update'}
            </LoadingButton>
            <Button size="small" color="error" onClick={handleClose} variant="outlined">
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

AddUpdateDialog.propTypes = {
  data: PropTypes.object,
  mode: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  term: PropTypes.number
};

export default connect(() => ({}))(AddUpdateDialog);
