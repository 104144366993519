export function getMailTemp(body) {
  return (
    `${
      '<table border="0" cellpadding="0" cellspacing="0" width="100%">' +
      '  <!-- start logo -->' +
      '  <tr>' +
      '    <td align="center" bgcolor="#e9ecef">' +
      '      <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 90%;">' +
      '        <tr>' +
      '          <td align="center" valign="top" style="padding: 36px 24px;">' +
      '            <a href="https://www.geniusolympiad.org" target="_blank" style="display: inline-block;">' +
      '              <img src="https://devapply.geniusolympiad.org/static/media/GENIUS_Logo_horizontal.dc8dca47.png"' +
      '                   width="480" style="display: block; margin-left: auto; margin-right: auto;"' +
      '                   height="120">' +
      '            </a>' +
      '          </td>' +
      '        </tr>' +
      '      </table>' +
      '    </td>' +
      '  </tr>' +
      '  <!-- end logo -->' +
      '  <!-- start copy block -->' +
      '  <tr>' +
      '    <td align="center" bgcolor="#e9ecef">' +
      '      <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 90%;">' +
      '        <!-- start copy -->' +
      '        <tr>' +
      '          <td align="left" bgcolor="#ffffff"' +
      '              style="padding: 24px; font-family: \'Source Sans Pro\', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px;">' +
      '            <p style="margin: 0;">' +
      '            </p>'
    }${body}</td>` +
    `        </tr>` +
    `        <!-- end copy -->` +
    `      </table>` +
    `    </td>` +
    `  </tr>` +
    `  <!-- end copy block -->` +
    `  <!-- start footer -->` +
    `  <tr>` +
    `    <td align="center" bgcolor="#e9ecef" style="padding: 24px;">` +
    `      <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">` +
    `        <!-- start permission -->` +
    `        <tr>` +
    `          <td align="center" bgcolor="#e9ecef"` +
    `              style="padding: 12px 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 20px; color: #666;">` +
    `            <p style="margin: 0;">If you didn't create an account with <a` +
    `              href="https://www.geniusolympiad.org">Genius Olympiad</a>, you can safely delete` +
    `              this email.</p>` +
    `          </td>` +
    `        </tr>` +
    `        <!-- end permission -->` +
    `        <!-- start unsubscribe -->` +
    `        <tr>` +
    `          <td align="center" bgcolor="#e9ecef"` +
    `              style="padding: 12px 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 20px; color: #666;">` +
    `            <p style="margin: 0;">Do not reply to this email.<br>If you have further questions, please do not hesitate to email us at
                                info@geniusolympiad.org</p>` +
    `          </td>` +
    `        </tr>` +
    `        <!-- end unsubscribe -->` +
    `      </table>` +
    `    </td>` +
    `  </tr>` +
    `  <!-- end footer -->` +
    `</table>`
  );
}
