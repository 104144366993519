const HOST = process.env.REACT_APP_REST_API_HOST;

const GET_ROBOTICS_FORM = {
  method: 'GET',
  path: `${HOST}go_admin/judge_operation/robotics_competition/form`
};
const ADD_ROBOTICS_FORM = {
  method: 'POST',
  path: `${HOST}go_admin/judge_operation/robotics_competition/form`
};
const DELETE_ROBOTICS_FORM = {
  method: 'DELETE',
  path: `${HOST}go_admin/judge_operation/robotics_competition/form`
};
const GET_ROBOTICS_FORM_DETAIL = {
  method: 'GET',
  path: `${HOST}go_admin/judge_operation/robotics_competition/form/detail`
};
const COPY_ROBOTICS_FORM = {
  method: 'POST',
  path: `${HOST}go_admin/judge_operation/robotics_competition/form/copy`
};
const GET_ROBOTICS_FORM_RUBRIC_TYPE = {
  method: 'GET',
  path: `${HOST}go_admin/judge_operation/robotics_competition/form/rubric_type`
};
const ADD_ROBOTICS_FORM_RUBRIC = {
  method: 'POST',
  path: `${HOST}go_admin/judge_operation/robotics_competition/form/rubric`
};
const UPDATE_ROBOTICS_FORM_RUBRIC = {
  method: 'PUT',
  path: `${HOST}go_admin/judge_operation/robotics_competition/form/rubric`
};
const DELETE_ROBOTICS_FORM_RUBRIC = {
  method: 'DELETE',
  path: `${HOST}go_admin/judge_operation/robotics_competition/form/rubric`
};
const CHANGE_ROBOTICS_FORM_RUBRIC = {
  method: 'PATCH',
  path: `${HOST}go_admin/judge_operation/robotics_competition/form/rubric`
};

module.exports = {
  GET_ROBOTICS_FORM,
  ADD_ROBOTICS_FORM,
  DELETE_ROBOTICS_FORM,
  GET_ROBOTICS_FORM_DETAIL,
  COPY_ROBOTICS_FORM,
  GET_ROBOTICS_FORM_RUBRIC_TYPE,
  ADD_ROBOTICS_FORM_RUBRIC,
  UPDATE_ROBOTICS_FORM_RUBRIC,
  DELETE_ROBOTICS_FORM_RUBRIC,
  CHANGE_ROBOTICS_FORM_RUBRIC
};
