import { Stack } from '@mui/material';
import { Typography } from '@material-ui/core';

export function ParticipantInfo() {
  return (
    <Stack>
      <Typography variant="body1">
        The “Participants” registration will be available only for finalist projects, after
        finalists are announced. Please visit this page to register your “Participants” after
        finalists are announced but before the participant registration deadline.
      </Typography>
    </Stack>
  );
}

export default ParticipantInfo;
