import { styled } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MainNavbar from './MainNavbar';
import MainCards from './MainCards';
import MainFooter from './MainFooter';
import CustomSnackbar from '../../components/CustomComponents/CustomSnackbar';
import Page from '../../components/Page';
import { handleGetTerms } from '../../actions/loginActions';

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  paddingBottom: theme.spacing(10)
}));

const items = [
  {
    id: 3,
    name: 'name',
    cover: '/static/images/applicant.jpg',
    status: 'Project',
    content:
      'Project applications. There should be only ONE account for projects from the same school. We may transfer or merge projects from the same school under different accounts.'
  },
  {
    id: 2,
    name: 'name',
    cover: '/static/images/fair.png',
    status: 'Fair',
    content:
      'Affiliated Fair Administrator Registration. It requires approval by GENIUS Olympiad Admins. All ISEF affiliated Regional Fairs accepted as affiliated fairs. You need to approve applications submitted by selecting your fair in this system.'
  },
  {
    id: 1,
    name: 'name',
    cover: '/static/images/judge.png',
    status: 'Judge',
    content:
      'Anyone who has an undergraduate degree or expertise in the field they would like to judge can register as a judge. Project Supervisors should not register as a judge. It requires approval by GENIUS Olympiad Admins.'
  }
];
const APPLICANT = 2;
const FAIR = 3;
const REVIEWER = 4;

function MainLayout(props) {
  const {
    isSnackbarVisibleMain,
    dispatch,
    snackbarSettingsMain,
    isLoggedIn,
    user_role,
    isProfileOK
  } = props;
  const navigate = useNavigate();
  console.log(isLoggedIn, 'main');

  useEffect(() => {
    if (isLoggedIn === 1) {
      if (!isProfileOK) {
        navigate('/profile', { replace: true });
      } else if (user_role === FAIR) {
        navigate('/fair', { replace: true });
      } else if (user_role === APPLICANT) {
        navigate('/dashboard', { replace: true });
      } else if (user_role === REVIEWER) {
        navigate('/reviewer', { replace: true });
      } else {
        dispatch(handleGetTerms());
        navigate('/admin', { replace: true });
      }
    }
  }, []);
  return (
    <RootStyle title="Main | Genius">
      <MainNavbar />
      <Grid container style={{ justifyContent: 'center' }}>
        <MainCards items={items} />
        <Grid item xs={12} style={{ bottom: 0, width: '100%', marginTop: 20, position: 'fixed' }}>
          <MainFooter />
        </Grid>
        {isSnackbarVisibleMain && (
          <CustomSnackbar
            settings={{
              ...snackbarSettingsMain,
              onHide: () =>
                dispatch({
                  type: 'RESET_SNACKBAR_MAIN',
                  payload: null
                })
            }}
          />
        )}
      </Grid>
    </RootStyle>
  );
}

MainLayout.propTypes = {
  isSnackbarVisibleMain: PropTypes.bool.isRequired,
  snackbarSettingsMain: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.number,
  user_role: PropTypes.number,
  isProfileOK: PropTypes.bool
};

export default connect((store) => ({
  isSnackbarVisibleMain: store.alertReducer.isSnackbarVisibleMain,
  snackbarSettingsMain: store.alertReducer.snackbarSettingsMain,
  isLoggedIn: store.tokenReducer.isLoggedIn,
  user_role: store.loginReducer.user_role,
  isProfileOK: store.loginReducer.isProfileOK
}))(MainLayout);
