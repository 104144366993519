import * as React from 'react';
import Box from '@mui/material/Box';
import { Card, Grid } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import ProcessInfoBox from '../../../components/CustomComponents/CustomCard/ProcessInfoBox';
import AddPage from './AddPage';
import FileDialog from './FileDialog';

const steps = ['Performance Info', 'Performance Files'];

export default function AddUpdateStepper(props) {
  const { mode, data, handleFinish } = props;
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => step === 1;

  const isStepSkipped = (step) => skipped.has(step);

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Card sx={{ padding: 3, mt: 2 }}>
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = <Typography variant="caption">Optional</Typography>;
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <>
            <Typography sx={{ mt: 2, mb: 1 }}>
              You have entered all required information for your performance successfully.
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button variant="outlined" onClick={handleFinish}>
                Finish
              </Button>
            </Box>
          </>
        ) : (
          <>
            {activeStep === 0 ? (
              <Grid container>
                <Grid item xs={12}>
                  <AddPage
                    handleNext={handleNext}
                    handleBack={handleFinish}
                    mode={mode}
                    data={data}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid container>
                <Grid item xs={12}>
                  <ProcessInfoBox
                    Comp={
                      <p>
                        If your performance require any music file, please upload. All music files
                        must be in '.mp3' format.
                      </p>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FileDialog handleNext={handleNext} handleBack={handleBack} />
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Box>
    </Card>
  );
}

AddUpdateStepper.propTypes = {
  handleFinish: PropTypes.func.isRequired,
  data: PropTypes.object,
  mode: PropTypes.number
};
