export default [
  {
    name: 'id',
    label: 'ID'
  },
  {
    name: 'country',
    label: 'Country'
  },
  {
    name: 'school_name',
    label: 'School'
  },
  {
    name: 'title',
    label: 'Title'
  },
  {
    name: 'discipline',
    label: 'Discipline'
  },
  {
    name: 'category',
    label: 'Category'
  },
  {
    name: 'teacher_name',
    label: 'Supervisor'
  },
  {
    name: 'project_student_complete',
    label: 'Students'
  },
  {
    name: 'fair',
    label: 'Fair'
  },
  {
    name: 'fair_status',
    label: 'Fair Status'
  },
  {
    name: 'review_status',
    label: 'Review Status'
  },
  {
    name: 'finalist_status',
    label: 'Finalist Status'
  },
  {
    name: 'media_link_comp',
    label: 'Media Link',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'media_link_filter',
    label: 'Media Link',
    options: {
      display: false
    }
  },
  {
    name: 'presentation_link_comp',
    label: 'Presentation Link',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'presentation_link_filter',
    label: 'Presentation Link',
    options: {
      display: false
    }
  },
  {
    name: 'balance_comp',
    label: 'Balance',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'balance_filter',
    label: 'Balance',
    options: {
      display: false
    }
  },
  {
    name: 'recover',
    label: 'Recover',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  }
];
