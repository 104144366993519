import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Stack } from '@mui/material';
import { Checkbox } from '@material-ui/core';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiTable from '../../../components/CustomComponents/MuiTable';
import headers from './_utils/headerVariablesForSession';
import { addJudgingSession } from './_apis/serviceCalls';
import { setSnackbar } from '../../../actions/alertActions';

const SCIENCE = 1;

function Index(props) {
  const { data, handleClose, projectId, handleSuccess, dispatch } = props;
  const [selectedStudent, setSelectedStudent] = useState([]);
  const [electric, setElectric] = useState(false);

  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedStudent(rowsSelected);
  };

  function handleAdd() {
    const param = {
      project_id: projectId,
      judging_session_id: data[selectedStudent].id,
      electricity_needed: electric ? 1 : 0
    };
    addJudgingSession(
      param,
      () => {
        dispatch(setSnackbar('success', 'You have successfully selected your judging session!'));
        handleSuccess();
      },
      () => {}
    );
  }

  return (
    <Stack spacing={3}>
      <MuiTable
        tableData={data}
        tableColumns={headers}
        customOption={{
          selectableRows: 'single',
          onRowSelectionChange,
          rowsSelected: selectedStudent
        }}
      />
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="end">
        {data.length > 0 && data[0].discipline_id === SCIENCE && (
          <FormControlLabel
            control={
              <Checkbox checked={electric} onChange={(e) => setElectric(e.target.checked)} />
            }
            label="I need electricity when presenting my project. "
          />
        )}
        <>
          {selectedStudent.length > 0 && (
            <Button variant="outlined" onClick={handleAdd}>
              Add
            </Button>
          )}
          <Button variant="outlined" color="error" onClick={handleClose}>
            Cancel
          </Button>
        </>
      </Stack>
    </Stack>
  );
}

Index.propTypes = {
  data: PropTypes.array,
  handleClose: PropTypes.func,
  projectId: PropTypes.number,
  handleSuccess: PropTypes.func,
  dispatch: PropTypes.func
};

export default connect(() => ({}))(Index);
