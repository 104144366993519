export default [
  {
    name: 'id',
    label: 'ID'
  },
  {
    name: 'name',
    label: 'Name'
  },
  {
    name: 'email',
    label: 'Email'
  },
  {
    name: 'partner_fair_user_id',
    label: 'P.F. UserId'
  },
  {
    name: 'capacity',
    label: 'Capacity'
  },
  {
    name: 'edit',
    label: 'Edit',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  }
];
