export default [
  {
    name: 'id',
    label: 'ID'
  },
  {
    name: 'title',
    label: 'Title'
  },
  {
    name: 'category',
    label: 'Category'
  },
  {
    name: 'discipline',
    label: 'Discipline'
  },
  {
    name: 'Project File',
    label: 'File',
    options: {
      display: false
    }
  },
  {
    name: 'file_comp',
    label: 'File',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'Project Payment',
    label: 'Payment',
    options: {
      display: false
    }
  },
  {
    name: 'payment_comp',
    label: 'Payment',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'Student Info',
    label: 'Student',
    options: {
      display: false
    }
  },
  {
    name: 'student_comp',
    label: 'Student',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'Youtube Link',
    label: 'Youtube Link',
    options: {
      display: false
    }
  },
  {
    name: 'youtube_link_comp',
    label: 'Youtube Link',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'Fair Affiliation',
    label: 'Fair Affiliation',
    options: {
      display: false
    }
  },
  {
    name: 'fair_comp',
    label: 'Fair Affiliation',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'project_status_comp',
    label: 'Application Status',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'Application Status',
    label: 'Application Status',
    options: {
      display: false
    }
  }
];
