import PropTypes from 'prop-types';
// material
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

DashboardLogo.propTypes = {
  sx: PropTypes.object
};

export default function DashboardLogo({ sx }) {
  return (
    <Box
      component="img"
      src="/static/login_logo.png"
      sx={{ width: 'auto', height: 'auto', ...sx }}
    />
  );
}
