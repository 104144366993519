export default function reducer(
  state = {
    snackbarSettings: null,
    isSnackbarVisible: false,
    snackbarSettingsMain: null,
    isSnackbarVisibleMain: false,
    confirmationSettings: null,
    isConfirmationVisible: false,
    loadingStatus: 0,
    loadingText: ''
  },
  action
) {
  switch (action.type) {
    case 'SET_SNACKBAR': {
      return {
        ...state,
        snackbarSettings: action.payload,
        isSnackbarVisible: true
      };
    }
    case 'RESET_SNACKBAR': {
      return {
        ...state,
        snackbarSettings: null,
        isSnackbarVisible: false
      };
    }
    case 'SET_SNACKBAR_MAIN': {
      return {
        ...state,
        snackbarSettingsMain: action.payload,
        isSnackbarVisibleMain: true
      };
    }
    case 'RESET_SNACKBAR_MAIN': {
      return {
        ...state,
        snackbarSettingsMain: null,
        isSnackbarVisibleMain: false
      };
    }
    case 'SET_CONFIRMATION_ALERT': {
      return {
        ...state,
        confirmationSettings: action.payload,
        isConfirmationVisible: true
      };
    }
    case 'RESET_CONFIRMATION_ALERT': {
      return {
        ...state,
        confirmationSettings: null,
        isConfirmationVisible: false
      };
    }
    case 'SET_LOADING': {
      const { loadingStatus, loadingText } = action.payload;
      return {
        ...state,
        loadingStatus,
        loadingText
      };
    }
    default: {
      return state;
    }
  }
}
