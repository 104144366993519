import React from 'react';
import { MenuItem, Stack, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import MuiTable from '../../../../../components/CustomComponents/MuiTable';
import headers from './headerVariables';

function Index(props) {
  const { addSurvey, tableData, terms, term, setTerm } = props;

  function TermSelect() {
    return (
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 2 }}>
        <TextField select label="Terms" value={term} onChange={(e) => setTerm(e.target.value)}>
          {terms.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
        <div className="header-button-style">
          <Button variant="outlined" onClick={addSurvey}>
            Add New
          </Button>
        </div>
      </Stack>
    );
  }

  return <MuiTable tableData={tableData} tableColumns={headers} title={<TermSelect />} />;
}

Index.propTypes = {
  addSurvey: PropTypes.func,
  tableData: PropTypes.array,
  terms: PropTypes.array,
  term: PropTypes.number,
  setTerm: PropTypes.func
};

export default connect((store) => ({
  terms: store.loginReducer.terms
}))(Index);
