export default [
  {
    name: 'participant_id',
    label: 'ID'
  },
  {
    name: 'name',
    label: 'Name'
  },
  {
    name: 'gender',
    label: 'Gender'
  },
  {
    name: 'date_of_birth_comp',
    label: 'DOB'
  },
  {
    name: 'participant_type',
    label: 'Type'
  },
  {
    name: 'trip_name',
    label: 'Trip Name'
  },
  {
    name: 'jfk_bus_comp',
    label: 'Jfk Bus',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'jfk_filter',
    label: 'Jfk Bus',
    options: {
      display: false
    }
  },
  {
    name: 'balance_filter',
    label: 'Balance',
    options: {
      display: false
    }
  },
  {
    name: 'balanceComp',
    label: 'Balance',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  }
];
