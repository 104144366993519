const LOGIN_CHECK = -1;
const LOGGED_IN = 1;
const NOT_LOGGED_IN = 0;

export default function reducer(
  state = {
    isLoggedIn: LOGIN_CHECK,
    access_token: null
  },
  action
) {
  switch (action.type) {
    case 'SET_ACC_TOKEN': {
      const { access_token } = action.payload;
      return {
        ...state,
        isLoggedIn: LOGGED_IN,
        access_token
      };
    }
    case 'CLEAR_ACC_TOKEN': {
      return {
        ...state,
        access_token: null,
        isLoggedIn: NOT_LOGGED_IN
      };
    }
    default: {
      return state;
    }
  }
}
