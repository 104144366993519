import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Stack } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Form, FormikProvider, useFormik } from 'formik';
import { updateAwardSetting } from '../../_apis/serviceCalls';
import { setConfirmationAlert, setSnackbar } from '../../../../../actions/alertActions';

const SettingDialog = (props) => {
  const { data, handleClose, handleSuccess, dispatch } = props;

  const formik = useFormik({
    initialValues: {
      show_medal: data.show_medal !== 0,
      show_certificate: data.show_certificate !== 0
    },
    onSubmit: (values) => {
      const param = {
        show_medal: values.show_medal ? 1 : 0,
        show_certificate: values.show_certificate ? 1 : 0,
        term_id: data.id
      };
      dispatch(
        setConfirmationAlert(
          'Are you sure?',
          'YES, UPDATE',
          'As a result of this action, the appearance of certificates and medals will change.',
          () => {
            updateAwardSetting(
              param,
              () => {
                dispatch(setSnackbar('success', 'The setting has been updated'));
                handleSuccess();
              },
              () => {}
            );
          }
        )
      );
    }
  });

  const { handleSubmit, getFieldProps, values } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
            <FormControlLabel
              control={<Checkbox {...getFieldProps('show_medal')} checked={values.show_medal} />}
              label="Show Medal"
            />
            <FormControlLabel
              control={
                <Checkbox
                  {...getFieldProps('show_certificate')}
                  checked={values.show_certificate}
                />
              }
              label="Show Certificate"
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="end" spacing={2}>
            <Button type="submit" variant="outlined">
              Update
            </Button>
            <Button variant="outlined" color="error" onClick={handleClose}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
};

SettingDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  handleSuccess: PropTypes.func,
  dispatch: PropTypes.func
};

export default connect(() => ({}))(SettingDialog);
