import { fetchApi } from '../../../api/fetchApi';

const project_operation = require('../../../api/RestVariables/Admin/project_operation');
const school_operation = require('../../../api/RestVariables/Admin/school_operation');
const common = require('../../../api/RestVariables/Admin/common');

export async function getProjectOperation(config, scb, ecb) {
  try {
    const url = project_operation.GET_PROJECT_OP;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_PROJECT_OP');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getDisciplineFileTypeList(config, scb, ecb) {
  try {
    const url = common.GET_DISCIPLINE_FILE_TYPE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_DISCIPLINE_FILE_TYPE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function projectManualPayment(config, scb, ecb) {
  try {
    const url = project_operation.PROJECT_MANUAL_PAYMENT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'PROJECT_MANUAL_PAYMENT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function fetchGender(config, scb, ecb) {
  try {
    const url = common.GET_GENDER;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'Genders');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function fetchDiscipline(config, scb, ecb) {
  try {
    const url = common.GET_DISCIPLINE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'Discipline');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function fetchCategory(config, scb, ecb) {
  try {
    const url = common.GET_CATEGORY;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'Category');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function fetchAffiliatedFair(config, scb, ecb) {
  try {
    const url = common.GET_AFFILIATED_FAIR;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'Affiliated Fair');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getAffiliatedFairReq(config, scb, ecb) {
  try {
    const url = project_operation.GET_AFFILIATED_FAIR_REQ;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_AFFILIATED_FAIR_REQ');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addAffiliatedFair(config, scb, ecb) {
  try {
    const url = project_operation.ADD_AFFILIATED_FAIR;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_AFFILIATED_FAIR');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteAffiliatedFair(config, scb, ecb) {
  try {
    const url = project_operation.DELETE_AFFILIATED_FAIR;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_AFFILIATED_FAIR');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function fetchProjectList(config, scb, ecb) {
  try {
    const url = project_operation.GET_INFO_LIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_INFO_LIST');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function startFileCheck(config, scb, ecb) {
  try {
    const url = project_operation.PROJECT_OP_FILE_CHECK;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'PROJECT_OP_FILE_CHECK');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getProjectInfo(config, scb, ecb) {
  try {
    const url = project_operation.GET_INFO;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_INFO');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateProjectInfo(config, scb, ecb) {
  try {
    const url = project_operation.UPDATE_INFO;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_INFO');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteProject(config, scb, ecb) {
  try {
    const url = project_operation.DELETE_INFO;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_INFO');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getStudentList(config, scb, ecb) {
  try {
    const url = project_operation.GET_STUDENT_LIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_STUDENT_LIST');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getStudentForAdmin(config, scb, ecb) {
  try {
    const url = project_operation.GET_STUDENT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_STUDENT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getExistingStudentForAdmin(config, scb, ecb) {
  try {
    const url = project_operation.GET_EXISTING_STUDENT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_EXISTING_STUDENT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addStudent(config, scb, ecb) {
  try {
    const url = project_operation.ADD_STUDENT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_STUDENT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addExistingStudentForAdmin(config, scb, ecb) {
  try {
    const url = project_operation.ADD_EXISTING_STUDENT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_EXISTING_STUDENT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateStudent(config, scb, ecb) {
  try {
    const url = project_operation.UPDATE_STUDENT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_STUDENT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteStudent(config, scb, ecb) {
  try {
    const url = project_operation.DELETE_STUDENT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_STUDENT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addFileMeta(config, scb, ecb) {
  const { formData, ...newObj } = config;
  console.log(formData);
  try {
    const url = project_operation.ADD_FILE;
    await fetchApi(
      url,
      newObj,
      (data) => {
        uploadFilesApi(data, config, scb, ecb);
        console.log(data, 'ADD_FILE');
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

const uploadFilesApi = (data, config, scb, ecb) => {
  const { url } = data;

  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': config.file.type },
    body: config.file
  };
  try {
    fetch(url, requestOptions).then((response) => {
      if (response.status === 200) {
        uploadStatusOfFile(
          {
            project_id: config.project_id,
            file_name: config.file_name,
            is_verified: 1
          },
          () => {},
          () => {}
        );
        scb();
      } else {
        uploadStatusOfFile(
          {
            project_id: config.project_id,
            file_name: config.file_name,
            is_verified: 0
          },
          () => {},
          () => {}
        );
        ecb(response.status);
      }
    });
  } catch (error) {
    console.log(error);
    ecb(error);
  }
};

export async function uploadStatusOfFile(config, scb, ecb) {
  try {
    const url = project_operation.UPDATE_FILE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_FILE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getFileLink(param, scb, ecb) {
  try {
    const url = project_operation.GET_FILE;
    await fetchApi(
      url,
      param,
      (data) => {
        scb(data);
        console.log(data, 'GET_FILE');
      },
      (error) => {
        ecb(error);
        console.log(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getFileMeta(config, scb, ecb) {
  try {
    const url = project_operation.GET_FILE;
    await fetchApi(
      url,
      { file_id: config.id },
      (data) => {
        getFilesApi(data, config, scb, ecb);
        console.log(data, 'ADD_FILE');
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

const getFilesApi = (data, config, scb, ecb) => {
  const { url } = data;
  const extension = url.substring(url.lastIndexOf('.') + 1, url.lastIndexOf('?'));
  const requestOptions = {
    method: 'GET'
  };
  try {
    fetch(url, requestOptions)
      // eslint-disable-next-line consistent-return
      .then((response) => {
        if (response.status === 200) {
          scb();
          return response.blob();
        }
        ecb(response.status);
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        console.log(link);
        link.href = href;
        link.setAttribute('download', `${config.file_name}.${extension}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  } catch (error) {
    console.log(error);
    ecb(error);
  }
};

export async function deleteFileFromDB(config, scb, ecb) {
  try {
    const url = project_operation.DELETE_FILE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_FILE');
      },
      (error) => {
        ecb(error);
        console.log(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getAllFiles(config, scb, ecb) {
  try {
    const url = project_operation.GET_FILE_LIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_FILE_LIST');
      },
      (error) => {
        ecb(error);
        console.log(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function fetchSchoolInfo(config, scb, ecb) {
  try {
    const url = school_operation.GET_SCHOOL_INFO;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_SCHOOL_INFO');
      },
      (error) => {
        ecb(error);
        console.log(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getFinalistStatus(config, scb, ecb) {
  try {
    const url = common.GET_FINALIST_STATUS;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_FINALIST_STATUS');
      },
      (error) => {
        ecb(error);
        console.log(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function changeFinalistStatus(config, scb, ecb) {
  try {
    const url = project_operation.PATCH_POP_FINALIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'PATCH_POP_FINALIST');
      },
      (error) => {
        ecb(error);
        console.log(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getStudentDuplicate(config, scb, ecb) {
  try {
    const url = project_operation.GET_STUDENT_DUPLICATE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_STUDENT_DUPLICATE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getCountryProject(config, scb, ecb) {
  try {
    const url = project_operation.GET_COUNTRY_PROJECT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_COUNTRY_PROJECT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getStateProject(config, scb, ecb) {
  try {
    const url = project_operation.GET_STATE_PROJECT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_STATE_PROJECT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addPresentationForPO(config, scb, ecb) {
  try {
    const url = project_operation.PROJECT_OP_PRESENTATION;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'PROJECT_OP_PRESENTATION');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getReviewList(config, scb, ecb) {
  try {
    const url = project_operation.GET_REVIEW_LIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_REVIEW_LIST');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getReviewDetail(config, scb, ecb) {
  try {
    const url = project_operation.GET_REVIEW_DETAIL;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_REVIEW_DETAIL');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateReviewStatus(config, scb, ecb) {
  try {
    const url = project_operation.UPDATE_REVIEW_STATUS;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_REVIEW_STATUS');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getReportFile(param, scb, ecb) {
  try {
    const url = project_operation.GET_FILE_LINK;
    await fetchApi(
      url,
      param,
      (data) => {
        scb(data);
        console.log(data, 'GET_FILE_LINK');
      },
      (error) => {
        ecb(error);
        console.log(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getReportFileMeta(config, scb, ecb) {
  try {
    const url = project_operation.GET_FILE_LINK;
    await fetchApi(
      url,
      { file_id: config.id },
      (data) => {
        handleGetFilesApi(data, config, scb, ecb);
        console.log(data, 'ADD_FILE');
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

const handleGetFilesApi = (data, config, scb, ecb) => {
  const { url } = data;
  const extension = url.substring(url.lastIndexOf('.') + 1, url.lastIndexOf('?'));
  const requestOptions = {
    method: 'GET'
  };
  try {
    fetch(url, requestOptions)
      // eslint-disable-next-line consistent-return
      .then((response) => {
        if (response.status === 200) {
          scb();
          return response.blob();
        }
        ecb(response.status);
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        console.log(link);
        link.href = href;
        link.setAttribute('download', `${config.file_name}.${extension}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  } catch (error) {
    console.log(error);
    ecb(error);
  }
};
