import React, { useEffect, useState } from 'react';
import { DialogContent, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IconButton, MenuItem, TextField } from '@material-ui/core';
import { Icon } from '@iconify/react';
import ReactHtmlParser from 'react-html-parser';
import MuiTable from '../../../../../components/CustomComponents/MuiTable';
import ProcessInfoBox from '../../../../../components/CustomComponents/CustomCard/ProcessInfoBox';
import SummaryTable from '../../../../../components/CustomComponents/CustomCard/AwardSummaryTable';
import { getCountryReports } from '../../_apis/serviceCalls';
import headerVariables from './headerVariables';
import { getSummaryTableData } from '../_utils/getSummaryData';
import CustomDialog from '../../../../../components/CustomComponents/CustomDialog';
import ProjectListDialog from './ProjectListDialog';

function Index(props) {
  const { terms, active_term } = props;
  const [term, setTerm] = useState(active_term);
  const [countryProjectList, setCountryProjectList] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [projectDialog, setProjectDialog] = useState(false);
  const [param, setParam] = useState(null);
  const [guidelines, setGuidelines] = useState([]);

  useEffect(() => {
    getCountryReports(
      { term_id: term },
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((item) => {
          item.detail = (
            <IconButton color="secondary" onClick={() => getProjectDetail(item)}>
              <Icon icon="bx:bx-comment-detail" width={24} height={24} />
            </IconButton>
          );
        });
        const _summaryData = getSummaryTableData(list);
        setSummaryData(_summaryData);
        setCountryProjectList(list);
      },
      () => {}
    );
  }, [term]);

  function filterChange(displayData) {
    const arr = [];
    displayData.forEach((item) => {
      arr.push(countryProjectList[item.dataIndex]);
    });
    const _summaryData = getSummaryTableData(arr);
    setSummaryData(_summaryData);
  }

  function getProjectDetail(country) {
    const _param = {
      term_id: term,
      country_id: country.country_id
    };
    setParam(_param);
    setProjectDialog(true);
  }

  function TermSelect() {
    return (
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 2 }}>
        <TextField select label="Terms" value={term} onChange={(e) => setTerm(e.target.value)}>
          {terms.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
    );
  }

  return (
    <Stack spacing={2}>
      {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
      <ProcessInfoBox Comp={<SummaryTable data={summaryData} />} title="Summary" />
      <MuiTable
        title={<TermSelect />}
        tableData={countryProjectList}
        tableColumns={headerVariables}
        customOption={{
          onFilterChange: (changedColumn, filterList, type, changedColumnIndex, displayData) => {
            filterChange(displayData);
            console.log(changedColumn);
            console.log(filterList);
            console.log(displayData);
          }
        }}
      />
      <CustomDialog
        onClose={() => setProjectDialog(false)}
        open={projectDialog}
        maxWidth="lg"
        title="Project List"
        fullWidth
      >
        <DialogContent>
          <ProjectListDialog param={param} />
        </DialogContent>
      </CustomDialog>
    </Stack>
  );
}

Index.propTypes = {
  terms: PropTypes.array,
  active_term: PropTypes.number
};

export default connect((store) => ({
  terms: store.loginReducer.terms,
  active_term: store.loginReducer.active_term
}))(Index);
