import { Button, Grid } from '@mui/material';
import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Icon } from '@iconify/react';
import LandingPageCard from '../../../components/CustomComponents/CustomCard/LandingPageCard';
import Page from '../../../components/Page';
import InternationalFair from './InternationalFair/InternationalFair';
import FlagShow from './FlagShow/FlagShow';

const CARDS = [
  {
    id: 1,
    name: 'International Fair',
    description: 'From this module you can arrange International Fair.'
  },
  {
    id: 2,
    name: 'Flag Show',
    description: 'From this module you can arrange Flag Show.'
  }
];

const LANDING_PAGE_MODE = 0;
const FAIR_MODE = 1;
const FLAG_MODE = 2;

function Index() {
  const [mode, setMode] = useState(LANDING_PAGE_MODE);

  function generate(data) {
    if (data.id === FAIR_MODE) {
      setMode(FAIR_MODE);
    } else {
      setMode(FLAG_MODE);
    }
  }

  return (
    <Page title="Performance Operation | Genius">
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
        {mode !== LANDING_PAGE_MODE && (
          <>
            <Typography variant="subtitle1">
              Performance Operation >{mode === FAIR_MODE ? ' International Fair' : ' Flag Show'}
            </Typography>
            <Button
              startIcon={<Icon icon="akar-icons:arrow-back-thick-fill" />}
              variant="outlined"
              onClick={() => setMode(LANDING_PAGE_MODE)}
            >
              Back
            </Button>
          </>
        )}
      </Box>
      {mode === LANDING_PAGE_MODE ? (
        <Grid container spacing={2}>
          {CARDS.map((item, index) => (
            <Grid item xs={12} md={6} lg={4} xl={3} key={index}>
              <LandingPageCard data={item} generate={generate} />
            </Grid>
          ))}
        </Grid>
      ) : mode === FAIR_MODE ? (
        <InternationalFair />
      ) : (
        <FlagShow />
      )}
    </Page>
  );
}

export default Index;
