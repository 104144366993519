import { Stack, Typography, TextField, InputAdornment, IconButton } from '@material-ui/core';
import { Form, FormikProvider, useFormik } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { LoadingButton } from '@material-ui/lab';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updatePassword } from './utils/serviceCalls';
import { setSnackbar } from '../../actions/alertActions';

// ----------------------------------------------------------------------

function PasswordCard(props) {
  const { profile, dispatch } = props;
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const PasswordSchema = Yup.object().shape({
    old_password: Yup.string().max(500, 'Too Long!').required('Password is required'),
    new_password: Yup.string().max(500, 'Too Long!').required('Password is required'),
    confirmPassword: Yup.string()
      .max(500, 'Too Long!')
      .oneOf([Yup.ref('new_password'), null], "Passwords don't match")
      .required('Confirm Password is required')
  });

  const formik = useFormik({
    initialValues: {
      old_password: '',
      new_password: '',
      confirmPassword: ''
    },
    validationSchema: PasswordSchema,
    onSubmit: (values, { resetForm }) => {
      const obj = { ...values, email: profile.email };
      const { confirmPassword, ...params } = obj;
      console.log(confirmPassword, 'profile');
      updatePassword(
        params,
        () => {
          resetForm();
          dispatch(setSnackbar('success', 'Password has been changed'));
        },
        () => {}
      );
    }
  });

  const handleShowCurrentPassword = () => {
    setShowCurrentPassword((show) => !show);
  };

  const handleShowNewPassword = () => {
    setShowNewPassword((show) => !show);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit} spacing={2}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Update Password
          </Typography>
          <LoadingButton type="submit" variant="outlined">
            Change
          </LoadingButton>
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showCurrentPassword ? 'text' : 'password'}
            label="Current Password"
            {...getFieldProps('old_password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowCurrentPassword} edge="end">
                    <Icon icon={showCurrentPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.old_password && errors.old_password)}
            helperText={touched.old_password && errors.old_password}
          />
          <TextField
            fullWidth
            autoComplete="new-password"
            type={showNewPassword ? 'text' : 'password'}
            label="New Password"
            {...getFieldProps('new_password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowNewPassword} edge="end">
                    <Icon icon={showNewPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.new_password && errors.new_password)}
            helperText={touched.new_password && errors.new_password}
          />
          <TextField
            fullWidth
            autoComplete="confirm-password"
            type={showConfirmPassword ? 'text' : 'password'}
            label="Confirm Password"
            {...getFieldProps('confirmPassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowConfirmPassword} edge="end">
                    <Icon icon={showConfirmPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            helperText={touched.confirmPassword && errors.confirmPassword}
          />
        </Stack>
      </Form>
    </FormikProvider>
  );
}

PasswordCard.propTypes = {
  profile: PropTypes.object.isRequired,
  dispatch: PropTypes.func
};

export default connect((store) => ({
  profile: store.loginReducer.profile
}))(PasswordCard);
