import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Stack } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import MuiTable from '../../../components/CustomComponents/MuiTable';
import headers from './utils/headerVariablesForParticipants';
import { getTravelParticipant } from './utils/serviceCalls';

function ParticipantDialog(props) {
  const {
    handleClose,
    addParticipantToList,
    selectedParticipantList,
    travel_type_id,
    travel_id,
    travel_option_id
  } = props;
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [travelParticipants, setTravelParticipants] = useState([]);

  useEffect(() => {
    getTravelParticipant(
      { travel_type_id, travel_id, travel_option_id },
      (data) => {
        data.sort((a, b) => a.participant_id - b.participant_id);
        data.forEach((item) => {
          item.date_of_birth_comp = moment(item.date_of_birth).format('YYYY-MM-DD');
        });
        setTravelParticipants(data);
      },
      () => {}
    );
  }, []);

  useEffect(() => {
    const arr = [];
    selectedParticipantList.forEach((item) => {
      const ind = travelParticipants.findIndex((x) => x.participant_id === item.participant_id);
      arr.push(ind);
    });
    setSelectedStudents(arr);
  }, [travelParticipants]);

  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedStudents(rowsSelected);
  };
  function handleAdd() {
    const arr = [];
    selectedStudents.forEach((item) => {
      const participant = travelParticipants[item];
      const obj = {
        participant_id: participant.participant_id,
        first_name: participant.first_name,
        last_name: participant.last_name
      };
      arr.push(obj);
    });
    addParticipantToList(arr);
  }

  return (
    <Stack spacing={3}>
      <MuiTable
        tableData={travelParticipants}
        tableColumns={headers}
        customOption={{
          selectableRows: 'multiple',
          onRowSelectionChange,
          rowsSelected: selectedStudents
        }}
      />
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="end">
        {selectedStudents.length > 0 && (
          <Button variant="outlined" onClick={handleAdd}>
            Add
          </Button>
        )}
        <Button variant="outlined" color="error" onClick={handleClose}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
}

ParticipantDialog.propTypes = {
  handleClose: PropTypes.func,
  addParticipantToList: PropTypes.func,
  selectedParticipantList: PropTypes.array,
  travel_type_id: PropTypes.number,
  travel_id: PropTypes.number,
  travel_option_id: PropTypes.number
};

export default connect(() => ({}))(ParticipantDialog);
