export default [
  {
    name: 'id',
    label: 'ID'
  },
  {
    name: 'role_name',
    label: 'Role Name'
  },
  {
    name: 'title',
    label: 'Title'
  },
  {
    name: 'description',
    label: 'Description'
  },
  {
    name: 'name',
    label: 'Name'
  },
  {
    name: 'download',
    label: 'Download',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'delete',
    label: 'Delete',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  }
];
