import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import HalfCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

export const icons = {
  check: <CheckBoxIcon color="primary" fontSize="small" />,
  uncheck: <CheckBoxOutlineBlankIcon fontSize="small" />,
  halfCheck: <HalfCheckBoxIcon color="primary" fontSize="small" />,
  expandClose: <ChevronRightIcon color="primary" fontSize="small" />,
  expandOpen: <ExpandMoreIcon color="primary" fontSize="small" />,
  expandAll: <AddIcon fontSize="small" />,
  collapseAll: <RemoveIcon fontSize="small" />,
  parentClose: '',
  parentOpen: '',
  leaf: ''
};

export const groupFeatures = (data) => {
  console.log('ROLE_FEATURES', data);
  // group by application name
  const groupedAppData = data.reduce((r, a) => {
    r[a.application_name] = r[a.application_name] || [];
    r[a.application_name].push(a);
    return r;
  }, Object.create(null));
  // create new array with app value and label keys
  const appsArray = [];
  Object.keys(groupedAppData).map((key) => {
    const modulesArray = [];

    // group by module name
    const groupedModuleData = groupedAppData[key].reduce((r, a) => {
      r[a.module_name] = r[a.module_name] || [];
      r[a.module_name].push(a);
      return r;
    }, Object.create(null));

    Object.keys(groupedModuleData).map((moduleKey) => {
      const moduleChildren = [];
      groupedModuleData[moduleKey].forEach((feature) =>
        moduleChildren.push({
          value: feature.feature_id,
          label: feature.feature_name
        })
      );
      modulesArray.push({ value: moduleKey + key, label: moduleKey, children: moduleChildren });
      return null;
    });
    appsArray.push({ value: key, label: key, children: modulesArray });
    return null;
  });
  return appsArray;
};

export const filterFeaturesByRole = (data, selectedRole) => {
  const filteredFeatureList = [];
  data
    .filter((feature) => feature.role_id === selectedRole.role_id)
    .map((feature) => filteredFeatureList.push(feature.feature_id));
  const featureUpdate = filteredFeatureList.length > 0;
  return { featureUpdate, filteredFeatureList };
};
