import * as React from 'react';
import { Button, DialogContent, Stack } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { IconButton, MenuItem, TextField } from '@material-ui/core';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { Icon } from '@iconify/react';
import moment from 'moment';
import ParticipantDialog from './ParticipantDialog';
import CustomDialog from '../../../components/CustomComponents/CustomDialog';
import { addTravel, deleteTravel, updateTravel } from './utils/serviceCalls';
import { setConfirmationAlert, setSnackbar } from '../../../actions/alertActions';
import { BY_AIR, BY_JFK } from './utils/constants';

const ADD_MODE = 1;

function AddTravelDialog(props) {
  const { handleClose, handleRefresh, travelOption, type, mode, editData, dispatch } = props;
  const [dialogShow, setDialogShow] = useState(false);
  const [participantList, setParticipantList] = useState([]);
  const [schema, setSchema] = useState(
    Yup.object().shape({
      travel_option_id: Yup.number().required('Travel Option Required'),
      date: Yup.date().required('Date Required'),
      time: Yup.string().max(500, 'Too Long!').required('Time Required'),
      airline: Yup.string().max(500, 'Too Long!').nullable(),
      airport: Yup.string().max(500, 'Too Long!').nullable(),
      flight_number: Yup.string().max(500, 'Too Long!').nullable()
    })
  );

  useEffect(() => {
    if (editData) {
      const arr = [];
      editData.participants.forEach((item) => {
        const obj = {
          participant_id: item.participant_id,
          first_name: item.first_name,
          last_name: item.last_name
        };
        arr.push(obj);
      });
      setParticipantList(arr);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      travel_option_id: editData ? editData.travel_option_id : '',
      date: editData ? editData.travel_date : '',
      time: editData ? moment(editData.travel_time, 'HH:mm').format('HH:mm') : '',
      airline: editData ? editData.travel_airline || '' : '',
      flight_number: editData ? editData.travel_flight_number || '' : '',
      airport: editData ? editData.travel_airport || '' : ''
    },
    validationSchema: schema,
    onSubmit: (values, { resetForm }) => {
      const participant_ids = [];
      participantList.forEach((item) => {
        participant_ids.push(item.participant_id);
      });
      if (participantList.length === 0) {
        dispatch(setSnackbar('warning', 'Please select participant(s).'));
      } else {
        const params =
          mode === ADD_MODE
            ? {
                ...values,
                participant_ids,
                travel_type_id: type
              }
            : {
                ...values,
                participant_ids,
                travel_type_id: type,
                travel_id: editData.id
              };
        if (mode === ADD_MODE) {
          addTravel(
            params,
            () => {
              handleClose();
              handleRefresh();
              resetForm();
            },
            () => {}
          );
        } else {
          updateTravel(
            params,
            () => {
              handleClose();
              handleRefresh();
              resetForm();
            },
            () => {}
          );
        }
      }
    }
  });

  const { errors, touched, handleSubmit, getFieldProps, values, setFieldValue } = formik;

  useEffect(() => {
    const _schema = Yup.object().shape({
      travel_option_id: Yup.number().required('Travel Option Required'),
      date: Yup.date().required('Date Required'),
      time: Yup.string().max(500, 'Too Long!').required('Time Required'),
      airline:
        values.travel_option_id === BY_AIR || values.travel_option_id === BY_JFK
          ? Yup.string().max(500, 'Too Long!').required('Airline Required')
          : Yup.string().max(500, 'Too Long!').nullable(),
      airport:
        values.travel_option_id === BY_AIR || values.travel_option_id === BY_JFK
          ? Yup.string().max(500, 'Too Long!').required('Airline Required')
          : Yup.string().max(500, 'Too Long!').nullable(),
      flight_number:
        values.travel_option_id === BY_AIR || values.travel_option_id === BY_JFK
          ? Yup.string().max(500, 'Too Long!').required('Airline Required')
          : Yup.string().max(500, 'Too Long!').nullable()
    });
    setSchema(_schema);
  }, [values]);

  function _handleClose() {
    setFieldValue('travel_option_id', '');
    setFieldValue('date', '');
    setFieldValue('time', '');
    setFieldValue('airline', '');
    setFieldValue('flight_number', '');
    setParticipantList([]);
    handleClose();
  }
  function addParticipantToList(arr) {
    const lastList = [...participantList, ...arr];
    lastList.sort((a, b) => a.participant_id - b.participant_id);
    setParticipantList(lastList);
    setDialogShow(false);
  }
  function removeParticipant(id) {
    if (mode === ADD_MODE) {
      let arr = participantList;
      arr = arr.filter((x) => x.participant_id !== id);
      console.log(arr);
      setParticipantList(arr);
    } else {
      dispatch(
        setConfirmationAlert(
          'Are you sure?',
          'YES, DELETE',
          'This action will delete participant',
          () => {
            deleteTravel(
              { travel_id: editData.id, participant_id: id },
              () => {
                dispatch(setSnackbar('success', 'The participant has been deleted'));
                dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
                let arr = participantList;
                arr = arr.filter((x) => x.participant_id !== id);
                console.log(arr);
                setParticipantList(arr);
              },
              () => {
                dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
              }
            );
          }
        )
      );
    }
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack spacing={3} sx={{ mt: 2 }} direction={{ xs: 'column', sm: 'row' }}>
            <TextField
              fullWidth
              select
              label="Travel Options"
              {...getFieldProps('travel_option_id')}
              error={Boolean(touched.travel_option_id && errors.travel_option_id)}
              helperText={touched.travel_option_id && errors.travel_option_id}
            >
              {travelOption.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
          <Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
            <TextField
              fullWidth
              id="date"
              label="Date"
              type="date"
              InputLabelProps={{
                shrink: true
              }}
              {...getFieldProps('date')}
              error={Boolean(touched.date && errors.date)}
              helperText={touched.date && errors.date}
            />
            <TextField
              fullWidth
              id="time"
              label="Time"
              type="time"
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                step: 300
              }}
              {...getFieldProps('time')}
              error={Boolean(touched.time && errors.time)}
              helperText={touched.time && errors.time}
            />
            <div>
              <Button
                variant="outlined"
                disabled={values.travel_option_id === ''}
                onClick={() => setDialogShow(true)}
              >
                Choose Participants
              </Button>
            </div>
          </Stack>
          <Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
            <TextField
              fullWidth
              label="Airline"
              {...getFieldProps('airline')}
              error={Boolean(touched.airline && errors.airline)}
              helperText={touched.airline && errors.airline}
            />
            <TextField
              fullWidth
              label="Airport"
              {...getFieldProps('airport')}
              error={Boolean(touched.airport && errors.airport)}
              helperText={touched.airport && errors.airport}
            />
            <TextField
              fullWidth
              label="Flight Number"
              {...getFieldProps('flight_number')}
              error={Boolean(touched.flight_number && errors.flight_number)}
              helperText={touched.flight_number && errors.flight_number}
            />
          </Stack>
          {participantList.length > 0 && (
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow style={{ background: 'gainsboro' }}>
                    <TableCell style={{ width: '30%' }}>ID</TableCell>
                    <TableCell style={{ width: '40%' }}>Name</TableCell>
                    <TableCell style={{ width: '20%' }}>Remove</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {participantList.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>{row.participant_id}</TableCell>
                      <TableCell>{`${row.first_name} ${row.last_name}`}</TableCell>
                      <TableCell>
                        <IconButton
                          disabled={participantList.length === 1}
                          color="secondary"
                          onClick={() => removeParticipant(row.participant_id)}
                        >
                          <Icon icon="fluent:delete-48-filled" width={24} height={24} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="end" spacing={2}>
            <Button type="submit" variant="outlined">
              {mode === ADD_MODE ? 'Add' : 'Update'}
            </Button>
            <Button variant="outlined" color="error" onClick={_handleClose}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Form>
      <CustomDialog
        onClose={() => setDialogShow(false)}
        open={dialogShow}
        maxWidth="lg"
        title="Participants"
      >
        <DialogContent>
          <ParticipantDialog
            travel_option_id={values.travel_option_id}
            travel_id={editData?.id || 0}
            addParticipantToList={addParticipantToList}
            travel_type_id={type}
            handleClose={() => setDialogShow(false)}
            selectedParticipantList={participantList}
          />
        </DialogContent>
      </CustomDialog>
    </FormikProvider>
  );
}

AddTravelDialog.propTypes = {
  handleClose: PropTypes.func,
  handleRefresh: PropTypes.func,
  travelOption: PropTypes.array,
  travelParticipants: PropTypes.array,
  type: PropTypes.number,
  mode: PropTypes.number,
  editData: PropTypes.object,
  dispatch: PropTypes.func
};

export default connect(() => ({}))(AddTravelDialog);
