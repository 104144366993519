import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { blue } from '@material-ui/core/colors';
import DeleteIcon from '@material-ui/icons/Delete';
import { Grid, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles(() => ({
  avatar: {
    backgroundColor: blue[500]
  },
  cardAction: {
    float: 'right'
  }
}));

export default function RoboticsFormCard(props) {
  const classes = useStyles();
  const { data, handleDeleteForm, handleEditForm } = props;

  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar className={classes.avatar}>{`${data.competition_form_name.charAt(0)}`}</Avatar>
        }
        title={`${data.competition_form_name}`}
      />
      <CardContent>
        <Typography variant="body2" color="textPrimary" component="span">
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <span>
                <b>Number of Rubrics: </b>
              </span>
              {data.number_of_rubrics || '-'}
            </Grid>
            <Grid item xs={6}>
              <span>
                <b>Term: </b>
              </span>
              {data.term || '-'}
            </Grid>
            <Grid item xs={6}>
              <span>
                <b>Duration: </b>
              </span>
              {data.duration || '-'}
            </Grid>
            <Grid item xs={6}>
              <span>
                <b>Available Points: </b>
              </span>
              {data.available_points || '-'}
            </Grid>
          </Grid>
        </Typography>
      </CardContent>
      <CardActions className={classes.cardAction}>
        <Tooltip title="Edit">
          <IconButton size="small" color="primary" onClick={() => handleEditForm(data)}>
            <EditIcon size="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            size="small"
            color="error"
            onClick={() => handleDeleteForm(data.competition_form_id)}
          >
            <DeleteIcon size="small" />
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  );
}

RoboticsFormCard.propTypes = {
  data: PropTypes.object.isRequired,
  handleDeleteForm: PropTypes.func.isRequired,
  handleEditForm: PropTypes.func.isRequired
};
