export default [
  {
    name: 'id',
    label: 'ID'
  },
  {
    name: 'country',
    label: 'Country'
  },
  {
    name: 'school_name',
    label: 'School'
  },
  {
    name: 'project_id',
    label: 'Project ID'
  },
  {
    name: 'project_title',
    label: 'Project Title'
  },
  {
    name: 'name',
    label: 'Participant Name'
  },
  {
    name: 'gender',
    label: 'Gender'
  },
  {
    name: 'grade_level',
    label: 'Grade Level'
  },
  {
    name: 'date_of_birth_comp',
    label: 'Date of Birth'
  },
  {
    name: 'balance_comp',
    label: 'Balance',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'balance_filter',
    label: 'Balance',
    options: {
      display: false
    }
  }
];
