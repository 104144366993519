import { connect } from 'react-redux';
import { Button } from '@mui/material';
import { Stack } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { addExistingStudent, getStudent } from '../utils/serviceCalls';
import MuiTable from '../../../../components/CustomComponents/MuiTable';
import headers from '../utils/headerVariablesForStudents';
import {
  addExistingStudentForAdmin,
  getExistingStudentForAdmin
} from '../../../Admin/ProjectOperation/serviceCalls';

function AddExistingStudentDialog(props) {
  const { handleClose, addedStudentList, handleRefresh, project_id, admin } = props;
  const [studentList, setStudentList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedItems(rowsSelected);
  };

  useEffect(() => {
    if (admin) {
      getExistingStudentForAdmin(
        { project_id },
        (data) => {
          data.forEach((student) => {
            student.name = `${student.first_name} ${student.last_name}`;
          });
          setStudentList(data);
        },
        () => {}
      );
    } else {
      getStudent(
        { project_id },
        (data) => {
          data.forEach((student) => {
            student.name = `${student.first_name} ${student.last_name}`;
          });
          setStudentList(data);
        },
        () => {}
      );
    }
  }, []);

  function handleSave() {
    const method = admin ? addExistingStudentForAdmin : addExistingStudent;
    method(
      {
        project_id,
        participant_id: studentList[selectedItems[0]].student_id
      },
      () => {
        handleClose();
        handleRefresh();
      },
      () => {}
    );
  }

  return (
    <Stack spacing={2}>
      <MuiTable
        tableColumns={headers}
        tableData={studentList}
        customOption={{
          selectableRows: 'single',
          onRowSelectionChange,
          rowsSelected: selectedItems,
          isRowSelectable: (dataIndex) =>
            addedStudentList.find((x) => x.student_id === studentList[dataIndex].student_id) ===
            undefined
        }}
      />
      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="end" spacing={2}>
        <Button
          onClick={handleSave}
          type="submit"
          variant="outlined"
          disabled={selectedItems.length === 0}
        >
          Save
        </Button>
        <Button variant="outlined" color="error" onClick={handleClose}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
}

AddExistingStudentDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  addedStudentList: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleRefresh: PropTypes.func.isRequired,
  project_id: PropTypes.number.isRequired,
  admin: PropTypes.bool
};

export default connect((store) => ({
  project_id: store.projectReducer.activeProject
}))(AddExistingStudentDialog);
