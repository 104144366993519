import { Stack, Typography, TextField, Avatar, IconButton, MenuItem } from '@material-ui/core';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@material-ui/lab';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { alpha } from '@material-ui/core/styles';
import { DialogContent, Tooltip } from '@mui/material';
import { addProfile, updateProfile, uploadProfilePicture } from './utils/serviceCalls';
import { setSnackbar } from '../../actions/alertActions';
import { handleGetActiveTerm, handleGetTerms, refreshToken } from '../../actions/loginActions';
import ProfilePicture from './ProfilePicture';
import CustomDialog from '../../components/CustomComponents/CustomDialog';
import { getCountryList, getStateList } from '../../actions/registerActions';

// ----------------------------------------------------------------------
const APPLICANT = 2;
const FAIR = 3;
const REVIEWER = 4;
const US_CODE = 244;
const phoneRegExp = /^\+?(\d{10,13})$/gm;
const englishChar = /^[-a-zA-Z0-9& ]+$/;

function InformationCard(props) {
  const { active_role, profile, user_profile, dispatch, refreshData } = props;
  const [profilePic, setProfilePic] = useState(profile?.picture_data);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [schema, setSchema] = useState(
    Yup.object().shape({
      first_name: Yup.string()
        .min(2, 'Too Short!')
        .max(500, 'Too Long!')
        .matches(englishChar, 'Please enter only English characters')
        .required('First name required'),
      last_name: Yup.string()
        .min(2, 'Too Short!')
        .max(500, 'Too Long!')
        .matches(englishChar, 'Please enter only English characters')
        .required('Last name required'),
      phone_number: Yup.string()
        .max(500, 'Too Long!')
        .matches(phoneRegExp, 'Phone number must be in the following format,+15554443322')
        .required('Phone number is required'),
      zip: Yup.string().max(500, 'Too Long!').required('Zip is required'),
      country_id: Yup.string().required('Country is required'),
      city: Yup.string().max(500, 'Too Long!').required('City is required'),
      street: Yup.string().max(500, 'Too Long!').required('Street is required'),
      state_id: Yup.string().nullable()
    })
  );
  const navigate = useNavigate();
  const is_profile_exist = user_profile && Object.keys(user_profile).length !== 0;
  useEffect(() => {
    getCountryList(
      {},
      (data) => {
        setCountryList(data);
      },
      () => {}
    );
    getStateList(
      {},
      (data) => {
        setStateList(data);
      },
      () => {}
    );
  }, []);

  const formik = useFormik({
    initialValues: {
      first_name: profile?.first_name || '',
      last_name: profile?.last_name || '',
      phone_number: profile?.phone_number || '',
      address: '',
      street: profile?.street || '',
      city: profile?.city || '',
      country_id: profile?.country_id || '',
      state_id: profile?.state_id || '',
      zip: profile?.zip || '',
      unit_number: profile?.unit_number || ''
    },
    validationSchema: schema,
    onSubmit: (values) => {
      const obj = {
        ...values,
        user_id: active_role.user_id,
        first_name: values.first_name.toUpperCase(),
        last_name: values.last_name.toUpperCase()
      };
      const { address, ...params } = obj;
      console.log(address);
      if (is_profile_exist) {
        updateProfile(
          params,
          () => {
            refreshData();
            dispatch(setSnackbar('success', 'The profile info has been updated'));
          },
          () => {}
        );
      } else {
        addProfile(
          params,
          () => {
            dispatch(setSnackbar('success', 'The profile info has been updated'));
            dispatch(
              refreshToken(
                () => {},
                (data) => {
                  dispatch(handleGetActiveTerm());
                  const isProfileOK = Object.keys(data.user_profile).length !== 0;
                  if (!isProfileOK) {
                    navigate('/profile', { replace: true });
                  } else if (data.user_role === FAIR) {
                    navigate('/fair', { replace: true });
                  } else if (data.user_role === APPLICANT) {
                    navigate('/dashboard', { replace: true });
                  } else if (data.user_role === REVIEWER) {
                    navigate('/reviewer', { replace: true });
                  } else {
                    dispatch(handleGetTerms());
                    navigate('/admin', { replace: true });
                  }
                }
              )
            );
          },
          () => {}
        );
      }
    }
  });
  const { errors, touched, handleSubmit, getFieldProps, setFieldValue, values } = formik;

  useEffect(() => {
    if (values.country_id !== US_CODE) {
      setFieldValue('state_id', null);
    }
    setSchema(
      Yup.object().shape({
        first_name: Yup.string()
          .min(2, 'Too Short!')
          .max(500, 'Too Long!')
          .matches(englishChar, 'Please enter only English characters')
          .required('First name required'),
        last_name: Yup.string()
          .min(2, 'Too Short!')
          .max(500, 'Too Long!')
          .matches(englishChar, 'Please enter only English characters')
          .required('Last name required'),
        phone_number: Yup.string()
          .max(500, 'Too Long!')
          .matches(phoneRegExp, 'Phone number must be in the following format,+15554443322')
          .required('Phone number is required'),
        zip: Yup.string().max(500, 'Too Long!').required('Zip is required'),
        country_id: Yup.string().required('Country is required'),
        city: Yup.string().max(500, 'Too Long!').required('City is required'),
        street: Yup.string().max(500, 'Too Long!').required('Street is required'),
        state_id:
          values.country_id === US_CODE
            ? Yup.string().required('State is required').nullable()
            : Yup.string().nullable()
      })
    );
  }, [values.country_id]);

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  function addPic(canvas, crop) {
    console.log(canvas, crop, 'canvas, crop');
    const pngUrl = canvas.toDataURL('image/jpeg', 0.2);
    uploadProfilePicture(
      { picture_data: pngUrl },
      () => {
        setProfilePic(pngUrl);
        setOpen(false);
        refreshData();
        dispatch(setSnackbar('success', 'The profile picture has been uploaded'));
      },
      () => {}
    );
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit} spacing={2}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Profile Information
          </Typography>
          <Tooltip title="Change Profile Picture">
            <IconButton
              ref={anchorRef}
              onClick={handleOpen}
              sx={{
                padding: 0,
                width: 96,
                height: 96,
                ...(open && {
                  '&:before': {
                    zIndex: 1,
                    content: "''",
                    width: '100%',
                    height: '100%',
                    borderRadius: '50%',
                    position: 'absolute',
                    bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
                  }
                })
              }}
            >
              <Avatar src={profilePic} alt="photoURL" sx={{ width: 96, height: 96 }} />
            </IconButton>
          </Tooltip>
        </Stack>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              inputProps={{ style: { textTransform: 'uppercase' } }}
              fullWidth
              label="First name"
              {...getFieldProps('first_name')}
              error={Boolean(touched.first_name && errors.first_name)}
              helperText={touched.first_name && errors.first_name}
            />
            <TextField
              inputProps={{ style: { textTransform: 'uppercase' } }}
              fullWidth
              label="Last name"
              {...getFieldProps('last_name')}
              error={Boolean(touched.last_name && errors.last_name)}
              helperText={touched.last_name && errors.last_name}
            />
            <TextField
              fullWidth
              label="Phone Number"
              {...getFieldProps('phone_number')}
              error={Boolean(touched.phone_number && errors.phone_number)}
              helperText={touched.phone_number && errors.phone_number}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Country"
              select
              {...getFieldProps('country_id')}
              error={Boolean(touched.country_id && errors.country_id)}
              helperText={touched.country_id && errors.country_id}
            >
              {countryList.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
            {values.country_id === US_CODE && (
              <TextField
                fullWidth
                label="State"
                select
                {...getFieldProps('state_id')}
                error={Boolean(touched.state_id && errors.state_id)}
                helperText={touched.state_id && errors.state_id}
              >
                {stateList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Street"
              {...getFieldProps('street')}
              error={Boolean(touched.street && errors.street)}
              helperText={touched.street && errors.street}
            />
            <TextField
              fullWidth
              label="Unit Number"
              {...getFieldProps('unit_number')}
              error={Boolean(touched.unit_number && errors.unit_number)}
              helperText={touched.unit_number && errors.unit_number}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="City"
              {...getFieldProps('city')}
              error={Boolean(touched.city && errors.city)}
              helperText={touched.city && errors.city}
            />
            <TextField
              fullWidth
              label="Zip Code"
              {...getFieldProps('zip')}
              error={Boolean(touched.zip && errors.zip)}
              helperText={touched.zip && errors.zip}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="end">
            <LoadingButton type="submit" variant="outlined">
              Save
            </LoadingButton>
          </Stack>
        </Stack>
        <CustomDialog
          maxWidth="sm"
          fullWidth
          onClose={() => setOpen(false)}
          open={open}
          title="Profile Picture"
        >
          <DialogContent>
            <ProfilePicture
              pic={profilePic}
              handleClose={() => setOpen(false)}
              addPicture={addPic}
            />
          </DialogContent>
        </CustomDialog>
      </Form>
    </FormikProvider>
  );
}

InformationCard.propTypes = {
  active_role: PropTypes.object.isRequired,
  user_profile: PropTypes.object,
  profile: PropTypes.object,
  dispatch: PropTypes.func,
  refreshData: PropTypes.func
};

export default connect((store) => ({
  active_role: store.loginReducer.active_role,
  user_profile: store.loginReducer.user_profile
}))(InformationCard);
