import React from 'react';
import moment from 'moment';
import { IconButton } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import headers from './headerVariablesForExpand';
import MuiTable from '../../../../components/CustomComponents/MuiTable';
import { setConfirmationAlert, setSnackbar } from '../../../../actions/alertActions';
import { deleteTravel } from './serviceCalls';

function ExpandComponent(props) {
  const { data, travel_id, dispatch, handleRefresh } = props;

  data.forEach((item) => {
    item.date_of_birth_comp = moment(item.date_of_birth).format('YYYY-MM-DD');
    item.delete = (
      <IconButton color="secondary" onClick={() => handleDeleteParticipant(item.id)}>
        <Icon icon="fluent:delete-48-filled" width={24} height={24} />
      </IconButton>
    );
  });

  function handleDeleteParticipant(participant_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'This action will delete participant',
        () => {
          deleteTravel(
            { travel_id, participant_id },
            () => {
              dispatch(setSnackbar('success', 'The participant has been deleted'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
              handleRefresh();
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }

  return <MuiTable tableColumns={headers} tableData={data} />;
}

ExpandComponent.propTypes = {
  data: PropTypes.array,
  travel_id: PropTypes.number,
  dispatch: PropTypes.func,
  handleRefresh: PropTypes.func
};

export default connect(() => ({}))(ExpandComponent);
