import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Button } from '@mui/material';
import { Stack, Typography } from '@material-ui/core';
import { getMailTemp } from '../../../../../components/CustomComponents/CustomMail/MailTemp';

function ViewDialog(props) {
  const { data, handleClose } = props;

  return (
    <Stack spacing={3}>
      <Typography variant="h6">{data.subject}</Typography>
      <div>
        <div className="template_view">{ReactHtmlParser(data?.message_body)}</div>
      </div>
      <Stack direction="row" justifyContent="end" spacing={1}>
        <Button size="small" color="error" onClick={handleClose} variant="outlined">
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
}

ViewDialog.propTypes = {
  data: PropTypes.object,
  handleClose: PropTypes.func
};

export default connect(() => ({}))(ViewDialog);
