import { Stack } from '@material-ui/core';
import { Button } from '@mui/material';
import * as React from 'react';
import PropTypes from 'prop-types';
import InfoPart from './InfoPart';
import FilePart from './FilePart';
import FairPart from './FairPart';
import StudentPart from './StudentPart';
import PresentationLinkPart from './PresentationLinkPart';

function UpdateDialog(props) {
  const { handleRefresh, projectId } = props;

  return (
    <Stack spacing={3}>
      <InfoPart project_id={projectId} />
      <FilePart project_id={projectId} />
      <FairPart project_id={projectId} />
      <StudentPart project_id={projectId} />
      <PresentationLinkPart project_id={projectId} />
      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="end" spacing={2}>
        <Button variant="outlined" color="error" onClick={handleRefresh}>
          Close
        </Button>
      </Stack>
    </Stack>
  );
}

UpdateDialog.propTypes = {
  handleRefresh: PropTypes.func,
  projectId: PropTypes.number
};

export default UpdateDialog;
