import { Stack, TextField } from '@material-ui/core';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@material-ui/lab';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { addDiscipline, updateDiscipline } from '../serviceCalls';
import { setLoading, setSnackbar } from '../../../../actions/alertActions';

const ADD_DISCIPLINE = 0;

function AddUpdateDialog(props) {
  const { data, mode, handleClose, dispatch, refreshData } = props;

  const DisciplineSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Too Short!')
      .max(500, 'Too Long!')
      .matches('^[\x20-\x7F]+$', 'Please enter only English characters!')
      .required('Discipline name required')
  });

  const formik = useFormik({
    initialValues: {
      name: mode === ADD_DISCIPLINE ? '' : data.name,
      is_online_allowed: mode === ADD_DISCIPLINE ? true : !!data.is_online_allowed
    },
    validationSchema: DisciplineSchema,
    onSubmit: (values) => {
      dispatch(setLoading(1, 'Loading...'));
      // eslint-disable-next-line no-unused-expressions
      mode === ADD_DISCIPLINE
        ? addDiscipline(
            {
              name: values.name,
              is_online_allowed: values.is_online_allowed ? 1 : 0
            },
            () => {
              dispatch(setLoading(0, ''));
              refreshData();
              dispatch(setSnackbar('success', 'The discipline has been added'));
            },
            () => {
              dispatch(setLoading(0, ''));
            }
          )
        : updateDiscipline(
            {
              id: data.id,
              name: values.name,
              is_online_allowed: values.is_online_allowed ? 1 : 0
            },
            () => {
              refreshData();
              dispatch(setLoading(0, ''));
              dispatch(setSnackbar('success', 'The discipline has been updated'));
            },
            () => {
              dispatch(setLoading(0, ''));
            }
          );
    }
  });
  const { errors, touched, handleSubmit, getFieldProps, values } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            sx={{ mt: 1, mb: 1 }}
            fullWidth
            label="Discipline name"
            {...getFieldProps('name')}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
          <FormControlLabel
            control={
              <Checkbox
                {...getFieldProps('is_online_allowed')}
                checked={values.is_online_allowed}
              />
            }
            label="Online Allowed"
          />
        </Stack>
        <Stack direction="row" justifyContent="end" spacing={1}>
          <LoadingButton size="small" type="submit" variant="outlined">
            {mode === ADD_DISCIPLINE ? 'Add' : 'Update'}
          </LoadingButton>
          <Button size="small" color="error" onClick={handleClose} variant="outlined">
            Cancel
          </Button>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

AddUpdateDialog.propTypes = {
  data: PropTypes.object,
  mode: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired
};

export default connect(() => ({}))(AddUpdateDialog);
