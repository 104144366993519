import { fetchApi } from '../../../../api/fetchApi';

const trip = require('../../../../api/RestVariables/Application/trip');

export async function getTripList(config, scb, ecb) {
  try {
    const url = trip.GET_TRIP_LIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_TRIP_LIST');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getTripParticipantList(config, scb, ecb) {
  try {
    const url = trip.GET_TRIP_PARTICIPANT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_TRIP_PARTICIPANT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateTrip(config, scb, ecb) {
  try {
    const url = trip.UPDATE_TRIP;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_TRIP');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getDiscount(config, scb, ecb) {
  try {
    const url = trip.GET_DISCOUNT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_DISCOUNT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function paymentForTrip(config, scb, ecb) {
  try {
    const url = trip.MAKE_PAYMENT_TRIP;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'MAKE_PAYMENT_TRIP');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}
