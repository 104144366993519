import React from 'react';
import PropTypes from 'prop-types';
import CustomDialog, {
  DialogContent
} from '../../../../../../components/CustomComponents/CustomDialog';
import EditRubricForm from './EditRubricForm';

/*
This view is a modal that includes SubQuestionStepper to add new sub question
*/

function AddSubQuestion(props) {
  const { closeDialog, handleSuccess, rubric } = props;

  return (
    <CustomDialog onClose={closeDialog} maxWidth="md" fullWidth title="Update Rubric">
      <DialogContent>
        <EditRubricForm rubric={rubric} handleSuccess={handleSuccess} handleClose={closeDialog} />
      </DialogContent>
    </CustomDialog>
  );
}

AddSubQuestion.propTypes = {
  closeDialog: PropTypes.func,
  rubric: PropTypes.object,
  handleSuccess: PropTypes.func
};

export default AddSubQuestion;
