import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Tooltip } from '@mui/material';
import headers from './headerVariables';
import MuiTable from '../../../../../../components/CustomComponents/MuiTable';
import { setLoading } from '../../../../../../actions/alertActions';
import Label from '../../../../../../components/Label';
import { fCurrency } from '../../../../../../utils/formatNumber';
import { getJudgingGroupProject } from '../../_apis/serviceCalls';

function ExpandView(props) {
  const { judging_group_id, dispatch } = props;
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    dispatch(setLoading(1, 'Loading...'));
    getJudgingGroupProject(
      { judging_group_id },
      (data) => {
        data.forEach((item) => {
          const _balance = Number(parseFloat(item.balance).toFixed(2));
          item.balance_comp =
            _balance < 0 || _balance === 0 ? (
              <Label variant="ghost" color="success">
                {fCurrency(item.balance)}
              </Label>
            ) : (
              <Label variant="ghost" color="error">
                {fCurrency(item.balance)}
              </Label>
            );
          item.balance_filter = _balance < 0 || _balance === 0 ? 'Paid' : 'Not Paid';
          item.presentation_link_comp = item.presentation_link && (
            <Label variant="ghost" color="primary">
              <a target="_blank" href={item.presentation_link} rel="noreferrer">
                Link
              </a>
            </Label>
          );
          item.presentation_link_filter = item.presentation_link ? 'Exist' : 'None';
          item.project_student_comp = item.project_student_complete && (
            <Tooltip title={item.project_student_complete}>
              <p style={{ cursor: 'pointer' }}>{item.project_student} </p>
            </Tooltip>
          );
          item.media_link_comp = item.media_link && (
            <Label variant="ghost" color="primary">
              <a target="_blank" href={item.media_link} rel="noreferrer">
                Link
              </a>
            </Label>
          );
          item.media_link_filter = item.media_link ? 'Exist' : 'None';
          item.created_at_moment = moment(item.created_at).format('YYYY-MM-DD');
        });
        dispatch(setLoading(0, ''));
        setTableData(data);
      },
      () => {
        dispatch(setLoading(0, ''));
      }
    );
  }, []);

  return <MuiTable tableData={tableData} tableColumns={headers} />;
}

ExpandView.propTypes = {
  judging_group_id: PropTypes.number,
  dispatch: PropTypes.func.isRequired
};

export default connect(() => ({}))(ExpandView);
