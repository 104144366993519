import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Grid, IconButton, Stack } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { DialogContent } from '@mui/material';
import ReactHtmlParser from 'react-html-parser';
import Page from '../../../components/Page';
import { getJudgingSession, getJudgingSessionProject } from './_apis/serviceCalls';
import MuiTable from '../../../components/CustomComponents/MuiTable';
import headers from './_utils/headerVariables';
import Label from '../../../components/Label';
import { fCurrency } from '../../../utils/formatNumber';
import CustomDialog from '../../../components/CustomComponents/CustomDialog';
import AddSession from './AddSession';
import { fDateTime } from '../../../utils/formatTime';
import ProcessInfoBox from '../../../components/CustomComponents/CustomCard/ProcessInfoBox';

function Index() {
  const [projectList, setProjectList] = useState([]);
  const [sessionList, setSessionList] = useState([]);
  const [dialogShow, setDialogShow] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [guidelines, setGuidelines] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    getJudgingSessionProject(
      {},
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((item) => {
          const _balance = Number(parseFloat(item.balance).toFixed(2));
          item.balanceComp =
            _balance < 0 || _balance === 0 ? (
              <Label variant="ghost" color="success">
                {fCurrency(item.balance)}
              </Label>
            ) : (
              <Label variant="ghost" color="error">
                {fCurrency(item.balance)}
              </Label>
            );
          item.balance_filter = _balance < 0 || _balance === 0 ? 'Paid' : 'Not Paid';
          item.media_link_comp = item.media_link && (
            <Label variant="ghost" color="primary">
              <a target="_blank" href={item.media_link} rel="noreferrer">
                Link
              </a>
            </Label>
          );
          item.media_link_filter = item.media_link ? 'Exist' : 'None';
          item.electricity_needed_comp = item.electricity_needed ? 'Yes' : 'No';
          item.created_at_moment = moment(item.created_at).format('YYYY-MM-DD');
          item.add_session = (
            <IconButton
              disabled={item.judging_group_number}
              color="secondary"
              onClick={() => addSession(item.project_id)}
            >
              <Icon icon="ci:edit" width={24} height={24} />
            </IconButton>
          );
          item.judging_group_number_comp = item.judging_group_number || 'N/A';
          item.judging_group_place_comp = item.judging_group_place || 'N/A';
        });
        setProjectList(list);
      },
      () => {}
    );
  }

  function addSession(project_id) {
    setSelectedProjectId(project_id);
    getJudgingSession(
      { project_id },
      (data) => {
        data.forEach((session) => {
          session.start_date_view = fDateTime(session.start_datetime);
          session.end_date_view = fDateTime(session.end_datetime);
        });
        setSessionList(data);
        setDialogShow(true);
      },
      () => {}
    );
  }

  function refreshData() {
    setDialogShow(false);
    setSelectedProjectId(null);
    fetchData();
    setSessionList([]);
  }

  return (
    <Page title="Judging Session | Genius">
      <Stack spacing={3}>
        <Grid item xs={12}>
          {guidelines.length > 0 && (
            <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />
          )}
        </Grid>
        <MuiTable tableColumns={headers} tableData={projectList} />
      </Stack>
      <CustomDialog
        fullWidth
        maxWidth="lg"
        onClose={() => setDialogShow(false)}
        open={dialogShow}
        title="Add Session"
      >
        <DialogContent>
          <AddSession
            projectId={selectedProjectId}
            data={sessionList}
            handleClose={() => setDialogShow(false)}
            handleSuccess={refreshData}
          />
        </DialogContent>
      </CustomDialog>
    </Page>
  );
}

Index.propTypes = {};

export default connect(() => ({}))(Index);
