import React from 'react';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { Button, DialogContent } from '@mui/material';
import { MenuItem, Stack, TextField } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CustomDialog from '../../../../../../components/CustomComponents/CustomDialog';
import { setLoading, setSnackbar } from '../../../../../../actions/alertActions';
import { copySession } from '../../../serviceCalls';

function CopySessionDialog(props) {
  const { handleClose, dispatch, refreshData, terms, sessionList } = props;
  const sourceTermList = terms.filter((term) =>
    sessionList.find((session) => session.term_id === term.id)
  );

  const Schema = Yup.object().shape({
    source_term_id: Yup.number().required('Source Term is required'),
    target_term_id: Yup.number().required('Target Term is required')
  });
  const formik = useFormik({
    initialValues: {
      source_term_id: '',
      target_term_id: ''
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      dispatch(setLoading(1, 'Loading...'));
      copySession(
        values,
        () => {
          dispatch(setLoading(0, ''));
          refreshData();
          dispatch(setSnackbar('success', 'The session has been added'));
        },
        () => {
          dispatch(setLoading(0, ''));
        }
      );
    }
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <CustomDialog onClose={handleClose} open maxWidth="md" title="Copy Session" fullWidth>
      <DialogContent>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 1 }}>
                <TextField
                  fullWidth
                  label="Source Term"
                  select
                  {...getFieldProps('source_term_id')}
                  error={Boolean(touched.source_term_id && errors.source_term_id)}
                  helperText={touched.source_term_id && errors.source_term_id}
                >
                  {sourceTermList.map((term) => (
                    <MenuItem key={term.id} value={term.id}>
                      {term.description}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  fullWidth
                  label="Target Term"
                  select
                  {...getFieldProps('target_term_id')}
                  error={Boolean(touched.target_term_id && errors.target_term_id)}
                  helperText={touched.target_term_id && errors.target_term_id}
                >
                  {terms.map((term) => (
                    <MenuItem key={term.id} value={term.id}>
                      {term.description}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              <Stack direction="row" justifyContent="end" spacing={1}>
                <LoadingButton size="small" type="submit" variant="outlined">
                  Copy
                </LoadingButton>
                <Button size="small" color="error" onClick={handleClose} variant="outlined">
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </Form>
        </FormikProvider>
      </DialogContent>
    </CustomDialog>
  );
}

CopySessionDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  terms: PropTypes.array,
  sessionList: PropTypes.array
};

export default connect((store) => ({
  terms: store.loginReducer.terms
}))(CopySessionDialog);
