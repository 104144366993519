import { Box, Container, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { styled } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MotionContainer, varBounceIn } from '../components/animate';
import Page from '../components/Page';
import { handleGetActiveTerm, handleGetTerms, handleLoginWithLink } from '../actions/loginActions';

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

const APPLICANT = 2;
const FAIR = 3;
const REVIEWER = 4;
const JUDGE = 5;
const SUPER_USER = 21;
const PROJECT_REVIEWER = 25;

function LoginWithLinkView(props) {
  const { dispatch } = props;
  const navigate = useNavigate();

  useEffect(() => {
    const url = new URL(window.location.href);
    const arr = url.toString().split(/[=&]/);
    const user_id = arr[1];
    const email_hash = arr[3];
    const link_token = arr[5];
    const param = {
      user_id,
      email_hash,
      link_token
    };
    dispatch(
      handleLoginWithLink(
        param,
        (data) => {
          dispatch({ type: 'RESET_SNACKBAR', payload: null });
          let arr = [];
          let isAdmin = false;
          let commonModule = false;
          data.module_access.forEach((item) => {
            if (item.application_access_key !== 'go_shield') {
              if (item.application_access_key === 'go_admin') {
                const adminModules = item.modules;
                commonModule = adminModules.find((x) => x.module_access_key === 'common');
                isAdmin = true;
              }
              arr = [...arr, ...item.modules];
            }
          });
          dispatch(
            handleGetActiveTerm(
              () => {
                const isProfileOK =
                  Object.keys(data.user_profile).length !== 0 ||
                  data.user_role === FAIR ||
                  data.user_role === JUDGE;
                if (!isProfileOK) {
                  navigate('/profile', { replace: true });
                } else if (data.user_role === FAIR) {
                  navigate('/fair', { replace: true });
                } else if (data.user_role === APPLICANT) {
                  navigate('/dashboard', { replace: true });
                } else if (data.user_role === REVIEWER) {
                  navigate('/reviewer', { replace: true });
                } else if (data.user_role === PROJECT_REVIEWER) {
                  if (commonModule) {
                    dispatch(handleGetTerms());
                  }
                  navigate('/project_reviewer', { replace: true });
                } else if (data.user_role === SUPER_USER) {
                  if (commonModule) {
                    dispatch(handleGetTerms());
                  }
                  navigate('/admin', { replace: true });
                } else if (isAdmin) {
                  if (commonModule) {
                    dispatch(handleGetTerms());
                  }
                  navigate(`/admin/${arr[0].module_access_key}`, { replace: true });
                } else navigate(`/dashboard/${arr[0].module_access_key}`, { replace: true });
              },
              () => {}
            )
          );
        },
        () => {}
      )
    );
  }, []);

  return (
    <RootStyle title="Genius">
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                Loading...
              </Typography>
            </motion.div>
            <Typography sx={{ color: 'text.secondary' }}>
              You are currently being redirected to the home page.
            </Typography>
            <motion.div variants={varBounceIn}>
              <Box
                component="img"
                src="/static/illustrations/illustration_login.png"
                sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
              />
            </motion.div>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
}

LoginWithLinkView.propTypes = {
  dispatch: PropTypes.func.isRequired
};

export default connect(() => ({}))(LoginWithLinkView);
