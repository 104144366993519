import { fetchApi } from '../../../api/fetchApi';

const review_operation = require('../../../api/RestVariables/Admin/review_operation');
const common = require('../../../api/RestVariables/Admin/common');

export async function getReviewStatus(config, scb, ecb) {
  try {
    const url = common.GET_REVIEW_STATUS;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_REVIEW_STATUS');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getReviewList(config, scb, ecb) {
  try {
    const url = review_operation.GET_REVIEW;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_REVIEW');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getReviewDetail(config, scb, ecb) {
  try {
    const url = review_operation.GET_REVIEW_DETAIL;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_REVIEW_DETAIL');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addReviewStatus(config, scb, ecb) {
  try {
    const url = review_operation.ADD_REVIEW_STATUS;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_REVIEW_STATUS');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addReviewAnnounce(config, scb, ecb) {
  try {
    const url = review_operation.ADD_REVIEW_ANNOUNCE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_REVIEW_ANNOUNCE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function assignReview(config, scb, ecb) {
  try {
    const url = review_operation.ASSIGN_REVIEW;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ASSIGN_REVIEW');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getReviewer(config, scb, ecb) {
  try {
    const url = review_operation.GET_REVIEWER;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_REVIEWER');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getReviewerReport(config, scb, ecb) {
  try {
    const url = review_operation.GET_REVIEWER_REPORT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_REVIEWER_REPORT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getFileLink(param, scb, ecb) {
  try {
    const url = review_operation.GET_REVIEW_OPERATION_FILE;
    await fetchApi(
      url,
      param,
      (data) => {
        scb(data);
        console.log(data, 'GET_REVIEW_OPERATION_FILE');
      },
      (error) => {
        ecb(error);
        console.log(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getFileMeta(config, scb, ecb) {
  try {
    const url = review_operation.GET_REVIEW_OPERATION_FILE;
    await fetchApi(
      url,
      { file_id: config.id },
      (data) => {
        getFilesApi(data, config, scb, ecb);
        console.log(data, 'ADD_FILE');
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

const getFilesApi = (data, config, scb, ecb) => {
  const { url } = data;
  const extension = url.substring(url.lastIndexOf('.') + 1, url.lastIndexOf('?'));
  const requestOptions = {
    method: 'GET'
  };
  try {
    fetch(url, requestOptions)
      // eslint-disable-next-line consistent-return
      .then((response) => {
        if (response.status === 200) {
          scb();
          return response.blob();
        }
        ecb(response.status);
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        console.log(link);
        link.href = href;
        link.setAttribute('download', `${config.file_name}.${extension}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  } catch (error) {
    console.log(error);
    ecb(error);
  }
};
