import React from 'react';
import { Button, Stack } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeInvoice } from '../../pages/Application/Payment/_apis/serviceCalls';
import { setSnackbar } from '../../actions/alertActions';

function CustomHeader(props) {
  const { receiptSelect, dispatch, disabledButton, buttonVisible, idList } = props;

  function onClickReceipt() {
    dispatch({
      type: 'SET_RECEIPT_SELECTION',
      payload: true
    });
  }

  function createReceipt() {
    const params = {};
    if (idList.project.length > 0) {
      params.project_ids = idList.project;
    }
    if (idList.participant.length > 0) {
      params.participant_ids = idList.participant;
    }
    if (idList.trip.length > 0) {
      params.trip_participant_ids = idList.trip;
    }

    makeInvoice(params, () => {
      dispatch(setSnackbar('success', 'The receipt has been requested'));
      handleCancel();
    });
  }

  function handleCancel() {
    dispatch({
      type: 'SET_ID_LIST',
      payload: { project: [], participant: [], trip: [] }
    });
    dispatch({
      type: 'SET_RECEIPT_SELECTION',
      payload: false
    });
    dispatch({ type: 'SET_UPDATE_INDEX' });
  }

  return (
    buttonVisible && (
      <div>
        {!receiptSelect && (
          <Button color="secondary" variant="outlined" onClick={onClickReceipt}>
            Select for Receipt
          </Button>
        )}
        {receiptSelect && (
          <Stack spacing={3} direction={{ sm: 'row' }}>
            <Button
              color="secondary"
              variant="outlined"
              onClick={createReceipt}
              disabled={disabledButton}
            >
              Create Receipt
            </Button>
            <Button
              variant="outlined"
              color="error"
              style={{ marginLeft: 10 }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Stack>
        )}
      </div>
    )
  );
}

CustomHeader.propTypes = {
  receiptSelect: PropTypes.bool,
  dispatch: PropTypes.func,
  disabledButton: PropTypes.bool,
  buttonVisible: PropTypes.bool,
  idList: PropTypes.object
};

export default connect((store) => ({
  receiptSelect: store.headerReducer.receiptSelect,
  disabledButton: store.headerReducer.disabledButton,
  buttonVisible: store.headerReducer.buttonVisible,
  idList: store.headerReducer.idList,
  headerReducer: store.headerReducer
}))(CustomHeader);
