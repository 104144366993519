const badgeStyle = {
  badge: {
    borderRadius: '12px',
    padding: '5px 12px',
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: '700',
    lineHeight: '1',
    color: '#fff',
    textAlign: 'center',
    verticalAlign: 'baseline',
    display: 'inline-block'
  },
  submitColor: {
    backgroundColor: '#4caf50'
  },
  overdueColor: {
    backgroundColor: '#f44336'
  },
  dueColor: {
    backgroundColor: '#ff9800'
  },
  lateSubmitColor: {
    backgroundColor: '#999999'
  }
};

export default badgeStyle;
