import { fetchApi } from '../../../../api/fetchApi';

const award = require('./apis');

export async function getAwardProject(config, scb, ecb) {
  try {
    const url = award.GET_AWARD_PROJECT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_AWARD_PROJECT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function transferProject(config, scb, ecb) {
  try {
    const url = award.TRANSFER_PROJECTS;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'TRANSFER_PROJECTS');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addProjectMedal(config, scb, ecb) {
  try {
    const url = award.ADD_PROJECT_MEDAL;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_PROJECT_MEDAL');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateProjectMedalType(config, scb, ecb) {
  try {
    const url = award.UPDATE_PROJECT_MEDAL_TYPE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_PROJECT_MEDAL_TYPE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function handleAddCeremony(config, scb, ecb) {
  try {
    const url = award.ADD_CEREMONY;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_PROJECT_MEDAL');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getMedalType(config, scb, ecb) {
  try {
    const url = award.GET_MEDAL_TYPE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_MEDAL_TYPE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateAwardSetting(config, scb, ecb) {
  try {
    const url = award.UPDATE_AWARD_SETTING;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_AWARD_SETTING');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getAwardJudgingDetail(config, scb, ecb) {
  try {
    const url = award.GET_AWARD_JUDGING_DETAIL;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_AWARD_JUDGING_DETAIL');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteAwardJudgingDetail(config, scb, ecb) {
  try {
    const url = award.DELETE_AWARD_JUDGING_DETAIL;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_AWARD_JUDGING_DETAIL');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteRoboticsCompetition(config, scb, ecb) {
  try {
    const url = award.DELETE_ROBOTICS_COMPETITION;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_ROBOTICS_COMPETITION');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addAwardIncludeReview(config, scb, ecb) {
  try {
    const url = award.ADD_AWARD_INCLUDE_REVIEW;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_AWARD_INCLUDE_REVIEW');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getCountryReports(config, scb, ecb) {
  try {
    const url = award.GET_COUNTRY_REPORTS;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_COUNTRY_REPORTS');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getSchoolReports(config, scb, ecb) {
  try {
    const url = award.GET_SCHOOL_REPORTS;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_SCHOOL_REPORTS');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getProjectList(config, scb, ecb) {
  try {
    const url = award.GET_PROJECT_LIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_PROJECT_LIST');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getTemplateList(config, scb, ecb) {
  try {
    const url = award.GET_TEMPLATE_LIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_TEMPLATE_LIST');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getTemplateType(config, scb, ecb) {
  try {
    const url = award.GET_TEMPLATE_TYPE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_TEMPLATE_TYPE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getTemplate(config, scb, ecb) {
  try {
    const url = award.GET_TEMPLATE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_TEMPLATE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addTemplate(config, scb, ecb) {
  try {
    const url = award.ADD_TEMPLATE;
    await fetchApi(
      url,
      { term_id: config.term_id, type_id: config.type_id },
      (data) => {
        uploadFilesApi(data, config, scb, ecb);
        console.log(data, 'ADD_TEMPLATE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

const uploadFilesApi = (data, config, scb, ecb) => {
  const { url } = data;

  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': config.file.type },
    body: config.file
  };
  try {
    fetch(url, requestOptions).then(() => {
      scb();
    });
  } catch (error) {
    console.log(error);
    ecb(error);
  }
};

export async function deleteTemplate(config, scb, ecb) {
  try {
    const url = award.DELETE_TEMPLATE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_TEMPLATE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getFileLink(param, scb, ecb) {
  try {
    const url = award.GET_TEMPLATE;
    await fetchApi(
      url,
      param,
      (data) => {
        scb(data);
        console.log(data, 'GET_FILE');
      },
      (error) => {
        ecb(error);
        console.log(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getFileMeta(config, scb, ecb) {
  try {
    const url = award.GET_TEMPLATE;
    await fetchApi(
      url,
      { template_id: config.id },
      (data) => {
        getFilesApi(data, config, scb, ecb);
        console.log(data, 'ADD_FILE');
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

const getFilesApi = (data, config, scb, ecb) => {
  const { url } = data;
  const extension = url.substring(url.lastIndexOf('.') + 1, url.lastIndexOf('?'));
  const requestOptions = {
    method: 'GET'
  };
  try {
    fetch(url, requestOptions)
      // eslint-disable-next-line consistent-return
      .then((response) => {
        if (response.status === 200) {
          scb();
          return response.blob();
        }
        ecb(response.status);
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        console.log(link);
        link.href = href;
        link.setAttribute('download', `${config.file_name}.${extension}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  } catch (error) {
    console.log(error);
    ecb(error);
  }
};
