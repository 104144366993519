import React, { useEffect, useState } from 'react';
import { IconButton } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { Button, DialogContent, Stack } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import MuiTable from '../../../../components/CustomComponents/MuiTable';
import headers from './utils/headerVariables';
import { deleteTemplate, fetchPlaceholderType, fetchTemplate } from './serviceCalls';
import CustomDialog from '../../../../components/CustomComponents/CustomDialog';
import { setConfirmationAlert, setSnackbar } from '../../../../actions/alertActions';
import AddUpdateDialog from './utils/AddUpdateDialog';
import ViewDialog from './utils/ViewDialog';
import Page from '../../../../components/Page';
import ProcessInfoBox from '../../../../components/CustomComponents/CustomCard/ProcessInfoBox';

const ADD_MODE = 1;
const UPDATE_MODE = 2;

function Index(props) {
  const { dispatch } = props;
  const [tableData, setTableData] = useState([]);
  const [dialogShow, setDialogShow] = useState(false);
  const [viewDialogShow, setViewDialogShow] = useState(false);
  const [tempData, setTempData] = useState(null);
  const [mode, setMode] = useState(null);
  const [typeList, setTypeList] = useState([]);
  const [guidelines, setGuidelines] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);
  function fetchData() {
    fetchPlaceholderType(
      {},
      (data) => {
        setTypeList(data);
      },
      () => {}
    );
    fetchTemplate(
      {},
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((item) => {
          item.view = (
            <IconButton color="secondary" onClick={() => handleViewTemp(item)}>
              <Icon icon="ep:view" />
            </IconButton>
          );
          item.edit = (
            <IconButton color="secondary" onClick={() => handleEditTemp(item)}>
              <Icon icon="ci:edit" />
            </IconButton>
          );
          item.delete = (
            <IconButton color="secondary" onClick={() => handleDeleteTemp(item.id)}>
              <Icon icon="fluent:delete-48-filled" />
            </IconButton>
          );
        });
        setTableData(list);
      },
      () => {}
    );
  }
  function handleViewTemp(temp) {
    setTempData(temp);
    setViewDialogShow(true);
  }
  function handleEditTemp(item) {
    setMode(UPDATE_MODE);
    setTempData(item);
    setDialogShow(true);
  }
  function handleDeleteTemp(id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'This action will delete template',
        () => {
          deleteTemplate(
            { id },
            () => {
              dispatch(setSnackbar('success', 'The template has been deleted'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
              handleRefresh();
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }
  function handleRefresh() {
    setDialogShow(false);
    setTempData(null);
    setMode(null);
    fetchData();
  }
  function handleAddTemp() {
    setMode(ADD_MODE);
    setDialogShow(true);
  }

  return (
    <Page title="Communication | Genius">
      <Stack spacing={2}>
        {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
        <MuiTable
          title={
            <div className="header-button-style">
              <Button variant="outlined" onClick={handleAddTemp}>
                Add New
              </Button>
            </div>
          }
          tableData={tableData}
          tableColumns={headers}
        />
      </Stack>
      <CustomDialog
        maxWidth="md"
        fullWidth
        onClose={() => setDialogShow(false)}
        open={dialogShow}
        title={mode === ADD_MODE ? 'Add Template' : 'Update Template'}
        disableEnforceFocus
      >
        <DialogContent>
          <AddUpdateDialog
            mode={mode}
            data={tempData}
            typeList={typeList}
            refreshData={handleRefresh}
            handleClose={() => setDialogShow(false)}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        maxWidth="lg"
        fullWidth
        onClose={() => setViewDialogShow(false)}
        open={viewDialogShow}
        title="Template View"
      >
        <DialogContent>
          <ViewDialog data={tempData} handleClose={() => setViewDialogShow(false)} />
        </DialogContent>
      </CustomDialog>
    </Page>
  );
}

Index.propTypes = {
  dispatch: PropTypes.func
};

export default connect(() => ({}))(Index);
