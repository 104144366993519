import { Grid, MenuItem, Stack, TextField, Typography } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormikProvider, useFormik } from 'formik';
import { Button, Card } from '@mui/material';
import * as Yup from 'yup';
import moment from 'moment';
import { fetchGender } from '../../Projects/utils/serviceCalls';
import { getParticipantProjectList, getParticipantType, updateParticipant } from '../serviceCalls';
import { handleProjectEditable } from '../../Projects/utils/termMaker';

const GUEST_TYPE = 3;
const GRADE_LIST = [8, 9, 10, 11, 12];

function AddPage(props) {
  const { handleRefresh, handleClose, data, active_term_object } = props;
  const [participantTypes, setParticipantTypes] = useState([]);
  const [genderList, setGenderList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [hotelRequired, setHotelRequired] = useState(false);
  const englishChar = /^[-a-zA-Z0-9& ]+$/;
  handleProjectEditable(active_term_object);
  const [schema, setSchema] = useState(
    Yup.object().shape({
      participant_type_id: Yup.number().required(),
      first_name: Yup.string()
        .min(2, 'Too Short!')
        .max(500, 'Too Long!')
        .matches(englishChar, 'Please enter only English characters')
        .required('First name is required'),
      last_name: Yup.string()
        .min(2, 'Too Short!')
        .max(500, 'Too Long!')
        .matches(englishChar, 'Please enter only English characters')
        .required('Last name is required'),
      gender_id: Yup.string().required('Gender is required'),
      project_id: Yup.string().required('Project is required'),
      email: Yup.string()
        .max(500, 'Too Long!')
        .email('Email must be a valid email address')
        .required('Email is required'),
      grade_level_id:
        data.participant_type_id === 1 && Yup.string().required('Grade Level required'),
      date_of_birth:
        data?.participant_type_id === 1
          ? Yup.date()
              .max(
                moment(active_term_object.student_dob_min_value).format('YYYY-MM-DD'),
                'Student must be older than 13 at the time of registration'
              )
              .required('Date of Birth required')
          : data?.participant_type_id === 2
          ? Yup.date()
              .max(
                moment(active_term_object.chaperone_dob_min_value).format('YYYY-MM-DD'),
                'Chaperone must be older than 18 at the time of registration'
              )
              .required('Date of Birth required')
          : Yup.date(),
      staying_at_hotel: Yup.number()
    })
  );

  useEffect(() => {
    getParticipantType(
      {},
      (_data) => {
        let updatedData;
        if (data.participant_type_id !== 1) {
          updatedData = _data.filter((x) => x.id !== 1);
        } else if (data.participant_type_id === 1) {
          updatedData = _data.filter((x) => x.id === 1);
        }
        setParticipantTypes(updatedData);
      },
      () => {}
    );
    fetchGender(
      {},
      (data) => {
        setGenderList(data);
      },
      () => {}
    );
    getParticipantProjectList(
      {},
      (data) => {
        setProjectList(data.data);
      },
      () => {}
    );
  }, []);

  const formik = useFormik({
    initialValues: {
      participant_type_id: data?.participant_type_id,
      first_name: data?.first_name,
      last_name: data?.last_name,
      gender_id: data?.gender_id,
      project_id: Number(data?.project_ids),
      grade_level_id: data?.grade_level,
      email: data?.email,
      date_of_birth: moment(data?.date_of_birth).format('YYYY-MM-DD'),
      staying_at_hotel: data?.staying_at_hotel
    },
    validationSchema: schema,
    onSubmit: (values, { resetForm }) => {
      const obj = {
        ...values,
        participant_id: data.id,
        first_name: values.first_name.toUpperCase(),
        last_name: values.last_name.toUpperCase()
      };
      const { grade_level_id, ...paramWithoutGradeLevel } = obj;
      const latestParam = values.participant_type_id === 1 ? obj : paramWithoutGradeLevel;
      updateParticipant(
        latestParam,
        () => {
          handleRefresh();
          resetForm();
        },
        () => {}
      );
    }
  });
  const { errors, touched, handleSubmit, getFieldProps, values, setFieldValue } = formik;

  useEffect(() => {
    if (values.participant_type_id === GUEST_TYPE) {
      const matchPoint = moment(active_term_object.student_dob_min_value).format('YYYY-MM-DD');
      const isYounger = moment(values.date_of_birth).isAfter(moment(matchPoint));
      if (isYounger) {
        setHotelRequired(true);
        setFieldValue('staying_at_hotel', 1);
      } else {
        setHotelRequired(false);
      }
    }
  }, [values]);

  useEffect(() => {
    const _schema = Yup.object().shape({
      participant_type_id: Yup.number().required(),
      first_name: Yup.string()
        .min(2, 'Too Short!')
        .max(500, 'Too Long!')
        .required('First name required'),
      last_name: Yup.string()
        .min(2, 'Too Short!')
        .max(500, 'Too Long!')
        .required('Last name required'),
      gender_id: Yup.string().required('Gender required'),
      project_id: Yup.string().required('Project is required'),
      grade_level_id:
        values.participant_type_id === 1 && Yup.string().required('Grade Level required'),
      email: Yup.string()
        .max(500, 'Too Long!')
        .email('Email must be a valid email address')
        .required('Email is required'),
      date_of_birth:
        values.participant_type_id === 1
          ? Yup.date()
              .max(
                moment(active_term_object.student_dob_min_value).format('YYYY-MM-DD'),
                'Student must be older than 13 at the time of registration'
              )
              .required('Date of Birth required')
          : values.participant_type_id === 2
          ? Yup.date()
              .max(
                moment(active_term_object.chaperone_dob_min_value).format('YYYY-MM-DD'),
                'Chaperone must be older than 18 at the time of registration'
              )
              .required('Date of Birth required')
          : Yup.date(),
      staying_at_hotel: Yup.number()
    });
    setSchema(_schema);
  }, [values]);

  return (
    <Card style={{ padding: 20 }}>
      <Stack spacing={3}>
        <Grid item xs={4} direction={{ xs: 'column', sm: 'row' }} />
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid item xs={12}>
              <Stack spacing={3} sx={{ mt: 2, mb: 1 }}>
                <Stack spacing={3} sx={{ mt: 2, mb: 1 }} direction={{ xs: 'column', sm: 'row' }}>
                  <TextField
                    disabled={data.participant_type_id === 1}
                    fullWidth
                    select
                    label="Participant Type"
                    {...getFieldProps('participant_type_id')}
                    error={Boolean(touched.participant_type_id && errors.participant_type_id)}
                    helperText={touched.participant_type_id && errors.participant_type_id}
                  >
                    {participantTypes.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    disabled={data.participant_type_id === 1}
                    inputProps={{ style: { textTransform: 'uppercase' } }}
                    fullWidth
                    label="First Name"
                    {...getFieldProps('first_name')}
                    error={Boolean(touched.first_name && errors.first_name)}
                    helperText={touched.first_name && errors.first_name}
                  />
                  <TextField
                    disabled={data.participant_type_id === 1}
                    inputProps={{ style: { textTransform: 'uppercase' } }}
                    fullWidth
                    label="Last Name"
                    {...getFieldProps('last_name')}
                    error={Boolean(touched.last_name && errors.last_name)}
                    helperText={touched.last_name && errors.last_name}
                  />
                  {values.participant_type_id === 1 && (
                    <TextField
                      disabled={data.participant_type_id === 1}
                      fullWidth
                      select
                      label="Grade Level"
                      {...getFieldProps('grade_level_id')}
                      error={Boolean(touched.grade_level_id && errors.grade_level_id)}
                      helperText={touched.grade_level_id && errors.grade_level_id}
                    >
                      {GRADE_LIST.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Stack>
                <Stack spacing={3} sx={{ mt: 2, mb: 1 }} direction={{ xs: 'column', sm: 'row' }}>
                  <TextField
                    disabled={data.participant_type_id === 1}
                    fullWidth
                    label="Project"
                    select
                    {...getFieldProps('project_id')}
                    error={Boolean(touched.project_id && errors.project_id)}
                    helperText={touched.project_id && errors.project_id}
                  >
                    {projectList.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.title}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    disabled={data.participant_type_id === 1}
                    fullWidth
                    label="Gender"
                    select
                    {...getFieldProps('gender_id')}
                    error={Boolean(touched.gender_id && errors.gender_id)}
                    helperText={touched.gender_id && errors.gender_id}
                  >
                    {genderList.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    type="date"
                    label="Date of Birth"
                    {...getFieldProps('date_of_birth')}
                    error={Boolean(touched.date_of_birth && errors.date_of_birth)}
                    helperText={touched.date_of_birth && errors.date_of_birth}
                  />
                  <TextField
                    disabled={data.participant_type_id === 1}
                    fullWidth
                    label="Email"
                    {...getFieldProps('email')}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                  {values.participant_type_id === 3 && (
                    <TextField
                      disabled={hotelRequired}
                      fullWidth
                      label="Staying at Hotel"
                      select
                      {...getFieldProps('staying_at_hotel')}
                      error={Boolean(touched.staying_at_hotel && errors.staying_at_hotel)}
                      helperText={touched.staying_at_hotel && errors.staying_at_hotel}
                    >
                      <MenuItem key={1} value={1}>
                        Yes
                      </MenuItem>
                      <MenuItem key={0} value={0}>
                        No
                      </MenuItem>
                    </TextField>
                  )}
                </Stack>
                {hotelRequired && (
                  <Typography variant="subtitle2" sx={{ textAlign: 'center' }}>
                    Participants younger than 13 years old are not allowed to stay in the Genius
                    dorms.
                  </Typography>
                )}
                <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="end" spacing={2}>
                  <Button type="submit" variant="outlined">
                    Update
                  </Button>
                  <Button variant="outlined" color="error" onClick={handleClose}>
                    Cancel
                  </Button>
                </Stack>
              </Stack>
            </Grid>
          </Form>
        </FormikProvider>
      </Stack>
    </Card>
  );
}

AddPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  active_term_object: PropTypes.object
};

export default connect((store) => ({
  active_term_object: store.loginReducer.active_term_object
}))(AddPage);
