export default [
  {
    name: 'guideline_id',
    label: 'ID'
  },
  {
    name: 'application_name',
    label: 'App Name'
  },
  {
    name: 'module_name',
    label: 'Module Name'
  },
  {
    name: 'feature_name',
    label: 'Feature Name'
  },
  {
    name: 'description',
    label: 'Description'
  },
  {
    name: 'guideline_type',
    label: 'Guideline Type'
  },
  {
    name: 'protected',
    label: 'Protected'
  },
  {
    name: 'view',
    label: 'View',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'edit',
    label: 'Edit',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'delete',
    label: 'Delete',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  }
];
