export default function reducer(
  state = {
    activeProject: null,
    activeCategory: null,
    activeDiscipline: null
  },
  action
) {
  switch (action.type) {
    case 'SET_ACTIVE_PROJECT': {
      return {
        ...state,
        activeProject: action.payload
      };
    }
    case 'SET_ACTIVE_CATEGORY': {
      return {
        ...state,
        activeCategory: action.payload
      };
    }
    case 'SET_ACTIVE_DISCIPLINE': {
      return {
        ...state,
        activeDiscipline: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
