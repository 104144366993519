export default [
  {
    name: 'id',
    label: 'ID'
  },
  {
    name: 'country',
    label: 'Country'
  },
  {
    name: 'state',
    label: 'State'
  },
  {
    name: 'school_name',
    label: 'School'
  },
  {
    name: 'title',
    label: 'Title'
  },
  {
    name: 'discipline',
    label: 'Discipline'
  },
  {
    name: 'category',
    label: 'Category'
  },
  {
    name: 'teacher_name',
    label: 'Supervisor'
  },
  {
    name: 'teacher_email',
    label: 'Supervisor Email'
  },
  {
    name: 'project_student_complete',
    label: 'Students'
  },
  {
    name: 'partner_fair_name',
    label: 'Partner Fair'
  },
  {
    name: 'finalist_status',
    label: 'Finalist Status'
  },
  {
    name: 'approve',
    label: 'Approve',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'deny',
    label: 'Deny',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  }
];
