import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { Stack, TextField } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import { useState } from 'react';
import { unlockUser } from '../serviceCalls';
import { setSnackbar } from '../../../../actions/alertActions';

function UserRoleDialog(props) {
  const { roleList, userId, dispatch, handleRefresh, handleClose } = props;
  const [selectedRole, setSelectedRole] = useState(null);

  function handleUnlockUser() {
    unlockUser(
      { user_id: userId, role_id: selectedRole.role_id },
      () => {
        dispatch(setSnackbar('success', 'The user role has been unlocked'));
        dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
        handleRefresh();
      },
      () => {
        dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
      }
    );
  }

  return (
    <Stack spacing={3} sx={{ mt: 1 }}>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <Autocomplete
          value={selectedRole}
          onChange={(event, newValue) => {
            setSelectedRole(newValue);
          }}
          style={{ width: 400 }}
          id="free-solo-demo"
          freeSolo
          options={roleList}
          getOptionLabel={(option) => `${option.role_name}`}
          renderInput={(params) => <TextField {...params} fullWidth label="Roles" />}
        />
        <Button onClick={handleUnlockUser} variant="outlined">
          Add Role to User
        </Button>
        <Button variant="outlined" color="error" onClick={handleClose}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
}

UserRoleDialog.propTypes = {
  dispatch: PropTypes.func,
  userRoles: PropTypes.array,
  roleList: PropTypes.array,
  userId: PropTypes.number,
  handleRefresh: PropTypes.func,
  handleClose: PropTypes.func
};

export default connect(() => ({}))(UserRoleDialog);
