import { Alert, Stack } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { setLoading, setSnackbar } from '../../../../../actions/alertActions';
import { getAvailableGroup, addGroupProject } from '../../serviceCalls';
import MuiTable from '../../../../../components/CustomComponents/MuiTable';
import headers from './headerVariablesForAssign';
import { fDateTime } from '../../../../../utils/formatTime';

function AssignProjectDialog(props) {
  const { data, handleClose, dispatch, refreshData } = props;
  const [groupList, setGroupList] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);

  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedStudent(rowsSelected);
  };

  useEffect(() => {
    getAvailableGroup(
      { project_id: data.id },
      (data) => {
        data.forEach((item) => {
          item.start = fDateTime(item.start_datetime);
          item.end = fDateTime(item.end_datetime);
        });
        setGroupList(data);
      },
      () => {}
    );
  }, []);

  function handleAdd() {
    dispatch(setLoading(1, 'Loading...'));
    addGroupProject(
      { judging_group_id: groupList[selectedStudent].judging_group_id, project_ids: [data.id] },
      () => {
        dispatch(setLoading(0, ''));
        refreshData();
        dispatch(setSnackbar('success', 'The project has been added to group'));
      },
      () => {
        dispatch(setLoading(0, ''));
      }
    );
  }

  return (
    <Stack spacing={3}>
      {groupList ? (
        groupList.length === 0 ? (
          <Alert severity="info">There is no available group for project.</Alert>
        ) : (
          <MuiTable
            tableData={groupList}
            tableColumns={headers}
            customOption={{
              selectableRows: 'single',
              onRowSelectionChange
            }}
          />
        )
      ) : (
        <div />
      )}
      <Stack direction="row" justifyContent="end" spacing={1}>
        <LoadingButton
          size="small"
          onClick={handleAdd}
          variant="outlined"
          disabled={!selectedStudent}
        >
          Add
        </LoadingButton>
        <Button size="small" color="error" onClick={handleClose} variant="outlined">
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
}

AssignProjectDialog.propTypes = {
  data: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired
};

export default connect(() => ({}))(AssignProjectDialog);
