import React, { useEffect, useState } from 'react';
import { Button, DialogContent, Tooltip } from '@mui/material';
import { IconButton, MenuItem, Stack, TextField } from '@material-ui/core';
import Email from '@mui/icons-material/Email';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Icon } from '@iconify/react';
import ReactHtmlParser from 'react-html-parser';
import headers from './_utils/headerVariables';
import MuiTable from '../../../../components/CustomComponents/MuiTable';
import CustomDialog from '../../../../components/CustomComponents/CustomDialog';
import CustomMail from '../../../../components/CustomComponents/CustomMail';
import { CUSTOM_MAIL_TYPE } from '../../../../api/RestVariables/staticVariables';
import { deleteTemplate, getTemplateList } from '../_apis/serviceCalls';
import TypeDialog from './TypeDialog';
import { setConfirmationAlert, setSnackbar } from '../../../../actions/alertActions';
import ViewFileDialog from './ViewFileDialog';
import ProcessInfoBox from '../../../../components/CustomComponents/CustomCard/ProcessInfoBox';

function Index(props) {
  const { terms, active_term, isCommunication, dispatch } = props;
  const [term, setTerm] = useState(active_term);
  const [tableData, setTableData] = useState([]);
  const [addMail, setAddMail] = useState(false);
  const [mailDialogShow, setMailDialogShow] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [idList, setIdList] = useState([]);
  const [typeDialog, setTypeDialog] = useState(false);
  const [fileDialog, setFileDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [guidelines, setGuidelines] = useState([]);

  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedItems(rowsSelected);
    const arr = [];
    rowsSelected.forEach((item) => {
      arr.push(tableData[item].user_id);
    });
    setIdList(arr);
  };

  useEffect(() => {
    fetchData();
  }, [term]);

  function fetchData() {
    getTemplateList(
      { term_id: term },
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((item) => {
          item.delete = (
            <IconButton color="secondary" onClick={() => handleDeleteTemplate(item.id)}>
              <Icon icon="fluent:delete-48-filled" width={24} height={24} />
            </IconButton>
          );
          item.download = (
            <IconButton
              color="primary"
              onClick={() => viewFile(item.id, item.certificate_template)}
            >
              <Icon icon="akar-icons:cloud-download" width={24} height={24} />
            </IconButton>
          );
        });
        setTableData(list);
      },
      () => {}
    );
  }

  function viewFile(id, name) {
    const obj = {
      id,
      name
    };
    setSelectedFile(obj);
    setFileDialog(true);
  }

  function handleDeleteTemplate(template_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'This action will delete template',
        () => {
          deleteTemplate(
            { template_id },
            () => {
              fetchData();
              dispatch(setSnackbar('success', 'The template has been deleted'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }

  function TermSelect() {
    return (
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={2}
        sx={{ mt: 2 }}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <TextField select label="Terms" value={term} onChange={(e) => setTerm(e.target.value)}>
          {terms.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
        <div>
          <Button variant="outlined" onClick={() => setTypeDialog(true)}>
            Add Template
          </Button>
        </div>
        {addMail && (
          <div>
            <Button
              style={{ marginRight: 5 }}
              disabled={selectedItems.length === 0}
              variant="outlined"
              onClick={() => setMailDialogShow(true)}
            >
              Next
            </Button>
            <Button color="error" variant="outlined" onClick={() => setAddMail(false)}>
              Cancel
            </Button>
          </div>
        )}
      </Stack>
    );
  }

  function handleRefresh() {
    setSelectedItems([]);
    setMailDialogShow(false);
    setAddMail(false);
    setSelectedItems([]);
    setTypeDialog(false);
    fetchData();
  }

  return (
    <Stack spacing={2}>
      {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
      <MuiTable
        tableData={tableData}
        tableColumns={headers}
        title={<TermSelect />}
        customOption={{
          selectableRows: addMail ? 'multiple' : 'none',
          onRowSelectionChange,
          rowsSelected: selectedItems,
          customToolbar: () =>
            isCommunication !== -1 && (
              <Tooltip title="Send Email">
                <IconButton onClick={() => setAddMail(true)}>
                  <Email />
                </IconButton>
              </Tooltip>
            )
        }}
      />
      <CustomDialog
        onClose={() => setTypeDialog(false)}
        open={typeDialog}
        maxWidth="md"
        title="Type"
      >
        <DialogContent>
          <TypeDialog
            data={terms.find((x) => x.id === term)}
            handleClose={() => setTypeDialog(false)}
            handleSuccess={handleRefresh}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        onClose={() => setMailDialogShow(false)}
        open={mailDialogShow}
        maxWidth="lg"
        title="Send Mail"
        fullWidth
        disableEnforceFocus
      >
        <DialogContent>
          <CustomMail
            handleRefresh={handleRefresh}
            mailType={CUSTOM_MAIL_TYPE}
            idList={idList}
            handleClose={() => setMailDialogShow(false)}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        maxWidth="lg"
        fullScreen
        onClose={() => setFileDialog(false)}
        open={fileDialog}
        title="File"
      >
        <DialogContent>
          <ViewFileDialog handleClose={() => setFileDialog(false)} file={selectedFile} />
        </DialogContent>
      </CustomDialog>
    </Stack>
  );
}

Index.propTypes = {
  terms: PropTypes.array,
  active_term: PropTypes.number,
  dispatch: PropTypes.func,
  isCommunication: PropTypes.number
};

export default connect((store) => ({
  terms: store.loginReducer.terms,
  active_term: store.loginReducer.active_term,
  isCommunication: store.routeReducer.isCommunication
}))(Index);
