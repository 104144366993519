import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { IconButton, MenuItem, TextField } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { Button, DialogContent, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import MuiTable from '../../../../components/CustomComponents/MuiTable';
import { deleteTrip, fetchTripList } from '../serviceCalls';
import { setConfirmationAlert, setSnackbar } from '../../../../actions/alertActions';
import headers from './utils/headerVariables';
import CustomDialog from '../../../../components/CustomComponents/CustomDialog';
import AddUpdateDialog from './utils/AddUpdateDialog';
import { fCurrency } from '../../../../utils/formatNumber';
import Page from '../../../../components/Page';
import ProcessInfoBox from '../../../../components/CustomComponents/CustomCard/ProcessInfoBox';

const ADD_TRIP = 0;
const UPDATE_TRIP = 1;

function Index(props) {
  const { terms, active_term, dispatch } = props;
  const [term, setTerm] = useState(active_term);
  const [tableData, setTableData] = useState([]);
  const [addUpdateDialog, setAddUpdateDialog] = useState(false);
  const [mode, setMode] = useState(null);
  const [editData, setEditData] = useState(null);
  const [guidelines, setGuidelines] = useState([]);

  useEffect(() => {
    fetchData();
  }, [term]);
  function fetchData() {
    fetchTripList(
      { term_id: term },
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((item) => {
          item.fee_comp = fCurrency(item.fee);
          item.edit = (
            <IconButton color="secondary" onClick={() => handleEditTrip(item)}>
              <Icon icon="ci:edit" width={24} height={24} />
            </IconButton>
          );
          item.delete = (
            <IconButton color="secondary" onClick={() => handleDeleteTrip(item.id)}>
              <Icon icon="fluent:delete-48-filled" width={24} height={24} />
            </IconButton>
          );
        });
        setTableData(list);
      },
      () => {}
    );
  }
  function handleAddTrip() {
    setMode(ADD_TRIP);
    setAddUpdateDialog(true);
  }
  function handleEditTrip(data) {
    setMode(UPDATE_TRIP);
    setAddUpdateDialog(true);
    setEditData(data);
  }
  function refreshData() {
    setAddUpdateDialog(false);
    fetchData();
  }
  function handleDeleteTrip(id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'This action will delete trip permanently',
        () => {
          deleteTrip(
            { id },
            () => {
              fetchData();
              dispatch(setSnackbar('success', 'The trip has been deleted'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }
  function TermSelect() {
    return (
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 2 }}>
        <TextField select label="Terms" value={term} onChange={(e) => setTerm(e.target.value)}>
          {terms.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
        <div className="header-button-style">
          <Button variant="outlined" onClick={handleAddTrip}>
            Add New
          </Button>
        </div>
      </Stack>
    );
  }
  return (
    <Page title="Trip Settings | Genius">
      <Stack spacing={2}>
        {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
        <MuiTable title={<TermSelect />} tableData={tableData} tableColumns={headers} />
      </Stack>
      <CustomDialog
        onClose={() => setAddUpdateDialog(false)}
        open={addUpdateDialog}
        title={mode === ADD_TRIP ? 'ADD' : 'UPDATE'}
      >
        <DialogContent>
          <AddUpdateDialog
            term={term}
            data={editData}
            mode={mode}
            handleClose={() => setAddUpdateDialog(false)}
            refreshData={refreshData}
          />
        </DialogContent>
      </CustomDialog>
    </Page>
  );
}

Index.propTypes = {
  terms: PropTypes.array,
  active_term: PropTypes.number,
  dispatch: PropTypes.func.isRequired
};

export default connect((store) => ({
  terms: store.loginReducer.terms,
  active_term: store.loginReducer.active_term
}))(Index);
