import { fetchApi } from '../../../api/fetchApi';

const variables = require('./variables');

export async function getSurveyDetail(config, scb, ecb) {
  try {
    const url = variables.GET_SURVEY_DETAIL;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_SURVEY_DETAIL');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function sendSurveyResult(config, scb, ecb) {
  try {
    const url = variables.SEND_SURVEY_RESULT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'SEND_SURVEY_RESULT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}
