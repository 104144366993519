import { Grid, MenuItem, Stack, TextField } from '@material-ui/core';
import * as React from 'react';
import { Card } from '@mui/material';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import {
  addProjectInfo,
  fetchCategory,
  fetchDiscipline,
  getProjectInfo,
  updateProjectInfo
} from '../utils/serviceCalls';
import { fetchSchoolInfo } from '../../School/utils/serviceCalls';

function InfoPart(props) {
  const { handleNext, project_id, dispatch, atReview, handleBack } = props;
  const [infoData, setInfoData] = useState(null);
  const [disciplineList, setDisciplineList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [updatedCategoryList, setUpdatedCategoryList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [mediaLinkText, setMediaLinkText] = useState('');
  const [isFrameShow, setIsFrameShow] = useState(false);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
  const [isPreviewText, setIsPreviewText] = useState(false);
  const englishChar = /^[-a-zA-Z0-9& ]+$/;
  const [infoSchema, setInfoSchema] = useState(
    Yup.object().shape({
      school_id: Yup.string().required('School required'),
      title: Yup.string()
        .max(500, 'Too Long!')
        .matches(englishChar, 'Please enter only English characters')
        .required('Title required'),
      discipline_id: Yup.string().required('Discipline required'),
      category_id: Yup.string().required('Category required'),
      teacher_name: Yup.string()
        .max(500, 'Too Long!')
        .matches(englishChar, 'Please enter only English characters')
        .required('Teacher Name required'),
      teacher_email: Yup.string()
        .max(500, 'Too Long!')
        .email('Email must be a valid email address')
        .required('Email required'),
      media_link: Yup.string()
        .max(500, 'Too Long!')
        .matches(
          /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/,
          'Enter a valid YouTube link!'
        )
    })
  );
  useEffect(() => {
    fetchCategory(
      {},
      (_data) => {
        setCategoryList(_data);
        if (project_id) {
          getProjectInfo(
            { project_id },
            (data) => {
              setInfoData(data.project);
            },
            () => {}
          );
        }
      },
      () => {}
    );
    fetchDiscipline(
      {},
      (data) => {
        setDisciplineList(data);
      },
      () => {}
    );
    fetchSchoolInfo(
      {},
      (data) => {
        setSchoolList(data.data);
      },
      () => {}
    );
  }, []);
  const formik = useFormik({
    initialValues: {
      school_id: infoData?.school_id || '',
      title: infoData?.title || '',
      discipline_id: infoData?.discipline_id || '',
      category_id: infoData?.category_id || '',
      teacher_name: infoData?.teacher_name || '',
      teacher_email: infoData?.teacher_email || '',
      media_link: infoData?.media_link || ''
    },
    validationSchema: infoSchema,
    onSubmit: (values) => {
      if (atReview) {
        handleNext();
      } else if (project_id) {
        const isMLSend = !!values.media_link;
        const obj = { ...values, project_id, teacher_name: values.teacher_name.toUpperCase() };
        const { media_link, ...param2 } = obj;
        console.log(media_link);
        updateProjectInfo(
          isMLSend ? obj : param2,
          () => {
            handleNext();
          },
          () => {}
        );
      } else {
        const isMLSend = !!values.media_link;
        const { media_link, ...obj } = values;
        console.log(media_link);
        addProjectInfo(
          isMLSend ? values : obj,
          (data) => {
            handleNext();
            dispatch({ type: 'SET_ACTIVE_PROJECT', payload: data.id });
          },
          () => {}
        );
      }
    }
  });
  const { errors, touched, getFieldProps, handleSubmit, values, setFieldValue } = formik;
  useEffect(() => {
    const arr = [];
    categoryList.forEach((item) => {
      // eslint-disable-next-line no-unused-expressions
      item.discipline_id === values.discipline_id && arr.push(item);
    });
    setUpdatedCategoryList(arr);
    dispatch({ type: 'SET_ACTIVE_DISCIPLINE', payload: values.discipline_id });
  }, [values.discipline_id]);
  useEffect(() => {
    initialValues();
  }, [values.category_id]);
  useEffect(() => {
    initialValues();
  }, [categoryList, infoData]);
  useEffect(() => {
    setValues();
  }, [infoData]);

  function setValues() {
    setFieldValue('school_id', infoData?.school_id || '');
    setFieldValue('title', infoData?.title || '');
    setFieldValue('discipline_id', infoData?.discipline_id || '');
    setFieldValue('category_id', infoData?.category_id || '');
    setFieldValue('teacher_name', infoData?.teacher_name || '');
    setFieldValue('teacher_email', infoData?.teacher_email || '');
    setFieldValue('media_link', infoData?.media_link || '');
  }

  function initialValues() {
    const category = categoryList.find((x) => x.id === values.category_id);
    dispatch({ type: 'SET_ACTIVE_CATEGORY', payload: category });
    const isRequired = category?.is_media_link_required === 1;
    const text = isRequired ? 'Required' : 'Optional';
    setMediaLinkText(text);
    const newSchema = Yup.object().shape({
      school_id: Yup.string().required('School required'),
      title: Yup.string()
        .max(500, 'Too Long!')
        .matches(englishChar, 'Please enter only English characters')
        .required('Title required'),
      discipline_id: Yup.string().required('Discipline required'),
      category_id: Yup.string().required('Category required'),
      teacher_name: Yup.string()
        .max(500, 'Too Long!')
        .matches(englishChar, 'Please enter only English characters')
        .required('Teacher Name required'),
      teacher_email: Yup.string()
        .max(500, 'Too Long!')
        .email('Email must be a valid email address')
        .required('Email required'),
      media_link: isRequired
        ? Yup.string()
            .max(500, 'Too Long!')
            .matches(
              /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/,
              'Enter a valid YouTube link!'
            )
            .required('YouTube Media Link required')
        : Yup.string()
            .max(500, 'Too Long!')
            .matches(
              /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/,
              'Enter a valid YouTube link!'
            )
    });
    setInfoSchema(newSchema);
  }

  useEffect(() => {
    if (values.media_link !== '' && !isFrameShow) {
      setNextButtonDisabled(true);
    } else {
      setNextButtonDisabled(false);
    }
  }, [values, isFrameShow]);

  useEffect(() => {
    if (values.media_link === '') {
      setIsPreviewText(false);
    } else {
      setIsPreviewText(true);
    }
  }, [values.media_link]);

  function getId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  }
  const videoId = getId(values.media_link);
  const iframeMarkup = `<iframe width="560" height="315" src="//www.youtube.com/embed/${videoId}" frameborder="0" allowfullscreen></iframe>`;

  return (
    <Card sx={{ padding: 2, mt: 2 }}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit} spacing={2}>
          <Stack spacing={3} sx={{ mt: 2, mb: 1 }} direction={{ xs: 'column', sm: 'row' }}>
            <Grid item xs={8}>
              <TextField
                disabled={atReview}
                fullWidth
                label="Title"
                {...getFieldProps('title')}
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                disabled={atReview}
                fullWidth
                label="School"
                select
                {...getFieldProps('school_id')}
                error={Boolean(touched.school_id && errors.school_id)}
                helperText={touched.school_id && errors.school_id}
              >
                {schoolList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Stack>
          <Divider sx={{ mt: 2 }} textAlign="left">
            Info
          </Divider>
          <Stack spacing={3} sx={{ mt: 1, mb: 1 }} direction={{ xs: 'column', sm: 'row' }}>
            <TextField
              disabled={atReview}
              fullWidth
              label="Discipline"
              select
              {...getFieldProps('discipline_id')}
              error={Boolean(touched.discipline_id && errors.discipline_id)}
              helperText={touched.discipline_id && errors.discipline_id}
            >
              {disciplineList.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              disabled={atReview}
              fullWidth
              label="Category"
              select
              {...getFieldProps('category_id')}
              error={Boolean(touched.category_id && errors.category_id)}
              helperText={touched.category_id && errors.category_id}
            >
              {updatedCategoryList.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
          <Divider sx={{ mt: 2 }} textAlign="left">
            Teacher Info
          </Divider>
          <Stack spacing={3} sx={{ mt: 1, mb: 1 }} direction={{ xs: 'column', sm: 'row' }}>
            <TextField
              inputProps={{ style: { textTransform: 'uppercase' } }}
              disabled={atReview}
              fullWidth
              label="Teacher Name"
              {...getFieldProps('teacher_name')}
              error={Boolean(touched.teacher_name && errors.teacher_name)}
              helperText={touched.teacher_name && errors.teacher_name}
            />
            <TextField
              disabled={atReview}
              fullWidth
              type="email"
              label="Teacher Email"
              {...getFieldProps('teacher_email')}
              error={Boolean(touched.teacher_email && errors.teacher_email)}
              helperText={touched.teacher_email && errors.teacher_email}
            />
          </Stack>
          <Divider sx={{ mt: 2 }} textAlign="left">
            {mediaLinkText}
          </Divider>
          <Stack spacing={3} sx={{ mt: 1, mb: 1 }} direction={{ xs: 'column', sm: 'row' }}>
            <TextField
              disabled={atReview}
              fullWidth
              type="media_link"
              label="Media Link"
              {...getFieldProps('media_link')}
              error={Boolean(touched.media_link && errors.media_link)}
              helperText={touched.media_link && errors.media_link}
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                variant="outlined"
                disabled={
                  Boolean(touched.media_link && errors.media_link) || values.media_link === ''
                }
                onClick={() => setIsFrameShow(true)}
              >
                Preview
              </Button>
            </div>
          </Stack>
          {isPreviewText && !isFrameShow && (
            <p style={{ color: '#FF4842' }}>
              You must click on preview to confirm the valid YouTube link is correct.
            </p>
          )}
          {isFrameShow && <div> {ReactHtmlParser(iframeMarkup)} </div>}
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            {!project_id && (
              <Button variant="outlined" color="inherit" sx={{ mr: 1 }} onClick={handleBack}>
                Cancel
              </Button>
            )}
            <Box sx={{ flex: '1 1 auto' }} />
            <Button type="submit" variant="outlined" disabled={nextButtonDisabled}>
              Next
            </Button>
          </Box>
        </Form>
      </FormikProvider>
    </Card>
  );
}

InfoPart.propTypes = {
  handleBack: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  project_id: PropTypes.number.isRequired,
  infoData: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  atReview: PropTypes.number,
  mode: PropTypes.number
};

export default connect((store) => ({
  project_id: store.projectReducer.activeProject
}))(InfoPart);
