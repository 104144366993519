import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Stack } from '@mui/material';
import { MenuItem, TextField } from '@material-ui/core';
import { getDisciplineList } from '../../../_apis/adminApi';
import { addAwardIncludeReview } from '../../_apis/serviceCalls';
import { setSnackbar } from '../../../../../actions/alertActions';

const IncludeDialog = (props) => {
  const { handleClose, dispatch, handleSuccess } = props;
  const [disciplineList, setDisciplineList] = useState([]);
  const [selectedDiscipline, setSelectedDiscipline] = useState([]);

  useEffect(() => {
    getDisciplineList(
      {},
      (data) => {
        setDisciplineList(data);
      },
      () => {}
    );
  }, []);

  function addDiscipline() {
    addAwardIncludeReview(
      { discipline_id: selectedDiscipline },
      () => {
        dispatch(setSnackbar('success', 'The discipline has been added'));
        handleSuccess();
      },
      () => {}
    );
  }

  return (
    <Stack spacing={2}>
      <TextField
        style={{ marginTop: 10 }}
        fullWidth
        label="Disciplines"
        select
        value={selectedDiscipline}
        onChange={(e) => setSelectedDiscipline(e.target.value)}
      >
        {disciplineList.map((discipline) => (
          <MenuItem key={discipline.id} value={discipline.id}>
            {discipline.name}
          </MenuItem>
        ))}
      </TextField>
      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="end" spacing={2}>
        <Button onClick={addDiscipline} variant="outlined">
          Add
        </Button>
        <Button variant="outlined" color="error" onClick={handleClose}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};

IncludeDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired
};

export default connect(() => ({}))(IncludeDialog);
