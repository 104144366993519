const HOST = process.env.REACT_APP_REST_API_HOST;

const GET_REVIEW = {
  method: 'GET',
  path: `${HOST}go_admin/review_operation/review`
};
const GET_REVIEW_DETAIL = {
  method: 'GET',
  path: `${HOST}go_admin/review_operation/review/detail`
};
const ADD_REVIEW_STATUS = {
  method: 'POST',
  path: `${HOST}go_admin/review_operation/review/status`
};
const ADD_REVIEW_ANNOUNCE = {
  method: 'POST',
  path: `${HOST}go_admin/review_operation/review/announce`
};
const ASSIGN_REVIEW = {
  method: 'POST',
  path: `${HOST}go_admin/review_operation/review/assign`
};
const GET_REVIEWER = {
  method: 'GET',
  path: `${HOST}go_admin/review_operation/review/reviewer`
};
const GET_REVIEWER_REPORT = {
  method: 'GET',
  path: `${HOST}go_admin/review_operation/reviewer_report`
};
const GET_REVIEW_OPERATION_FILE = {
  method: 'GET',
  path: `${HOST}go_admin/review_operation/review/file`
};

module.exports = {
  GET_REVIEW,
  GET_REVIEW_DETAIL,
  ADD_REVIEW_STATUS,
  ADD_REVIEW_ANNOUNCE,
  ASSIGN_REVIEW,
  GET_REVIEWER,
  GET_REVIEWER_REPORT,
  GET_REVIEW_OPERATION_FILE
};
