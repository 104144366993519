export default [
  {
    name: 'id',
    label: 'ID'
  },
  {
    name: 'certificate_template',
    label: 'Certificate Template'
  },
  {
    name: 'certificate_template_type',
    label: 'Certificate Template Type'
  },
  {
    name: 'download',
    label: 'Download',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'delete',
    label: 'Delete',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  }
];
