import { IconButton } from '@material-ui/core';
import { Icon } from '@iconify/react';
import React from 'react';

export function dataMaker(
  guidelineList,
  handleViewGuideline,
  handleEditGuideline,
  handleDeleteGuideline
) {
  guidelineList.forEach((guideline) => {
    guideline.view = (
      <IconButton color="secondary" onClick={() => handleViewGuideline(guideline.guideline_id)}>
        <Icon icon="ep:view" />
      </IconButton>
    );
    guideline.edit = (
      <IconButton color="secondary" onClick={() => handleEditGuideline(guideline)}>
        <Icon icon="ci:edit" />
      </IconButton>
    );
    guideline.delete = (
      <IconButton
        disabled={guideline.is_protected}
        color="secondary"
        onClick={() => handleDeleteGuideline(guideline.guideline_id)}
      >
        <Icon icon="fluent:delete-48-filled" />
      </IconButton>
    );
    guideline.protected = guideline.is_protected ? 'Yes' : 'No';
  });

  return guidelineList;
}
