export default [
  {
    name: 'edit',
    label: 'Edit',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'delete',
    label: 'Delete',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'id',
    label: 'ID'
  },
  {
    name: 'name',
    label: 'Name'
  },
  {
    name: 'country',
    label: 'Country'
  },
  {
    name: 'state',
    label: 'State'
  },
  {
    name: 'city',
    label: 'City'
  },
  {
    name: 'school_address',
    label: 'Address'
  },
  {
    name: 'phone_number',
    label: 'Phone Number'
  },
  {
    name: 'main_contact_name',
    label: 'Contact Name'
  },
  {
    name: 'main_contact_email',
    label: 'Contact Email'
  },
  {
    name: 'user_email',
    label: 'User Email'
  },
  {
    name: 'project_count',
    label: 'Project Count'
  },
  {
    name: 'paid_count',
    label: 'Paid Count'
  },
  {
    name: 'review_finalist_count',
    label: 'Review Finalist Count'
  },
  {
    name: 'finalist_count',
    label: 'Finalist Count'
  },
  {
    name: 'review_status_comp',
    label: 'Review Detail',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'merge',
    label: 'Merge',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'transfer',
    label: 'Transfer',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  }
];
