import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import ConfirmationAlert from '../../components/CustomComponents/ConfirmationAlert';
import CustomSnackbar from '../../components/CustomComponents/CustomSnackbar';
import CustomLoading from '../../components/CustomComponents/CustomLoading';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

function DashboardLayout(props) {
  const {
    isConfirmationVisible,
    confirmationSettings,
    dispatch,
    isSnackbarVisible,
    snackbarSettings,
    loadingStatus,
    loadingText,
    isLoggedIn
  } = props;
  const [open, setOpen] = useState(false);

  return isLoggedIn === -1 ? (
    <CustomLoading size="lg" isOpen text="Loading..." />
  ) : (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle>
        <Outlet />
      </MainStyle>
      {isConfirmationVisible && (
        <ConfirmationAlert
          settings={{
            ...confirmationSettings,
            onCancel: () =>
              dispatch({
                type: 'RESET_CONFIRMATION_ALERT',
                payload: null
              })
          }}
        />
      )}
      {isSnackbarVisible && (
        <CustomSnackbar
          settings={{
            ...snackbarSettings,
            onHide: () =>
              dispatch({
                type: 'RESET_SNACKBAR',
                payload: null
              })
          }}
        />
      )}
      {loadingStatus === 1 && <CustomLoading size="lg" isOpen={loadingStatus} text={loadingText} />}
    </RootStyle>
  );
}

DashboardLayout.propTypes = {
  isSnackbarVisible: PropTypes.bool.isRequired,
  snackbarSettings: PropTypes.object,
  isConfirmationVisible: PropTypes.bool.isRequired,
  confirmationSettings: PropTypes.object,
  loadingStatus: PropTypes.bool,
  loadingText: PropTypes.string,
  dispatch: PropTypes.func,
  isLoggedIn: PropTypes.number
};

export default connect((store) => ({
  isConfirmationVisible: store.alertReducer.isConfirmationVisible,
  confirmationSettings: store.alertReducer.confirmationSettings,
  isSnackbarVisible: store.alertReducer.isSnackbarVisible,
  snackbarSettings: store.alertReducer.snackbarSettings,
  loadingStatus: store.alertReducer.loadingStatus,
  loadingText: store.alertReducer.loadingText,
  isLoggedIn: store.tokenReducer.isLoggedIn
}))(DashboardLayout);
