import { Button, DialogContent, Tooltip } from '@mui/material';
import { IconButton, MenuItem, Stack, TableCell, TextField } from '@material-ui/core';
import Email from '@mui/icons-material/Email';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Icon } from '@iconify/react';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import ReactHtmlParser from 'react-html-parser';
import MuiTable from '../../../../../components/CustomComponents/MuiTable';
import CustomDialog from '../../../../../components/CustomComponents/CustomDialog';
import CustomMail from '../../../../../components/CustomComponents/CustomMail';
import { CUSTOM_MAIL_TYPE } from '../../../../../api/RestVariables/staticVariables';
import AssignProjectDialog from './AssignProject/AssignProjectDialog';
import ExpandView from './ExpandView/ExpandView';
import { addCertificateForJudging, getJudgingJudge } from '../_apis/serviceCalls';
import { getHeaders } from './headerVariables';
import { downloadCertificate, unassignProject } from '../../serviceCalls';
import { setConfirmationAlert, setSnackbar } from '../../../../../actions/alertActions';
import ProcessInfoBox from '../../../../../components/CustomComponents/CustomCard/ProcessInfoBox';
import { convertSecondToHour } from '../../../../../utils/formatTime';

function Index(props) {
  const { terms, active_term, isCommunication, dispatch } = props;
  const [tableData, setTableData] = useState([]);
  const [editData, setEditData] = useState(null);
  const [term, setTerm] = useState(active_term);
  const [mailDialogShow, setMailDialogShow] = useState(false);
  const [assignProjectDialogShow, setAssignProjectDialogShow] = useState(false);
  const [addMail, setAddMail] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [idList, setIdList] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [addCertificate, setAddCertificate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [guidelines, setGuidelines] = useState([]);

  useEffect(() => {
    fetchData();
  }, [term]);

  function fetchData() {
    getJudgingJudge(
      { term_id: term },
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        const _headers = getHeaders(list);
        setHeaders(_headers.list);
        list.forEach((item) => {
          item.totalTime = item.total_judging_time
            ? convertSecondToHour(item.total_judging_time)
            : '-';
          item.medal = (
            <IconButton
              color="secondary"
              disabled={!item.judge_certificate_id}
              onClick={() => handleDownload(item.judge_certificate_id)}
            >
              <Icon icon="akar-icons:cloud-download" width={24} height={24} />
            </IconButton>
          );
          item.project = (
            <IconButton color="secondary" onClick={() => handleAssignProject(item)}>
              <Icon icon="ant-design:project-outlined" width={24} height={24} />
            </IconButton>
          );
          item.unassign = (
            <IconButton color="secondary" onClick={() => handleUnassignProject(item)}>
              <Icon icon="solar:bill-cross-outline" width={24} height={24} />
            </IconButton>
          );
        });
        setTableData(list);
      },
      () => {}
    );
  }

  function handleDownload(certificate_id) {
    setLoading(true);
    downloadCertificate(
      { certificate_id },
      () => {
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  }

  function handleAssignProject(judge) {
    setEditData(judge);
    setAssignProjectDialogShow(true);
  }

  function handleUnassignProject(judge) {
    const param = { judge_id: judge.judge_id };
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, UNASSIGN',
        'This action will unassign all projects from this judge',
        () => {
          unassignProject(param, () => {
            dispatch(setSnackbar('success', 'The projects has been unasigned'));
            dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            handleRefresh();
          }).then(() => dispatch({ type: 'RESET_CONFIRMATION_ALERT' }));
        }
      )
    );
  }

  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedItems(rowsSelected);
    const arr = [];
    rowsSelected.forEach((item) => {
      arr.push(tableData[item].judge_user_id);
    });
    setIdList(arr);
  };

  function TermSelect() {
    return (
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 2 }}>
        <TextField select label="Terms" value={term} onChange={(e) => setTerm(e.target.value)}>
          {terms.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
        {!addCertificate && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button color="secondary" variant="outlined" onClick={() => setAddCertificate(true)}>
              Add Certificate
            </Button>
          </div>
        )}
        {addMail && (
          <div>
            <Button
              style={{ marginRight: 5 }}
              disabled={selectedItems.length === 0}
              variant="outlined"
              onClick={() => setMailDialogShow(true)}
            >
              Next
            </Button>
            <Button color="error" variant="outlined" onClick={() => setAddMail(false)}>
              Cancel
            </Button>
          </div>
        )}
        {addCertificate && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              color="secondary"
              variant="outlined"
              disabled={startButtonDisabled}
              onClick={handleAddCertificate}
            >
              Next
            </Button>
            <Button
              variant="outlined"
              color="error"
              style={{ marginLeft: 10 }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        )}
        {loading && (
          <Stack
            spacing={1}
            direction={{ xs: 'column', sm: 'row' }}
            sx={{ mr: 1, display: 'flex', alignItems: 'center' }}
          >
            <p style={{ fontWeight: 'bold' }}>Downloading...</p>
            <CircularProgress size={20} />
          </Stack>
        )}
      </Stack>
    );
  }

  function handleRefresh() {
    setMailDialogShow(false);
    setAssignProjectDialogShow(false);
    setAddMail(false);
    setSelectedItems([]);
    setAddCertificate(false);
    fetchData();
  }

  function handleAddCertificate() {
    const arr = [];
    selectedItems.forEach((item) => {
      arr.push(tableData[item].judge_id);
    });
    const param = {
      judge_ids: arr
    };
    addCertificateForJudging(
      param,
      () => {
        dispatch(
          setSnackbar(
            'success',
            'The certificate operation is in progress. After operation finished, you will receive an email notification.'
          )
        );
        handleRefresh();
      },
      () => {}
    );
  }

  const handleCancel = () => {
    setSelectedItems([]);
    setAddCertificate(false);
  };

  const startButtonDisabled = selectedItems.length === 0;

  return (
    <Stack spacing={1}>
      {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
      <MuiTable
        tableData={tableData}
        tableColumns={headers}
        title={<TermSelect />}
        customOption={{
          selectableRows: addMail || addCertificate ? 'multiple' : 'none',
          onRowSelectionChange,
          rowsSelected: selectedItems,
          expandableRows: true,
          renderExpandableRow: (rowData) => {
            const colSpan = rowData.length + 1;
            return (
              <TableRow>
                <TableCell colSpan={colSpan}>
                  <ExpandView judge_id={rowData[0]} handleRefresh={handleRefresh} />
                </TableCell>
              </TableRow>
            );
          },
          customToolbar: () =>
            isCommunication !== -1 && (
              <Tooltip title="Send Email">
                <IconButton onClick={() => setAddMail(true)}>
                  <Email />
                </IconButton>
              </Tooltip>
            )
        }}
      />
      <CustomDialog
        onClose={() => setMailDialogShow(false)}
        open={mailDialogShow}
        maxWidth="lg"
        title="Send Mail"
        fullWidth
        disableEnforceFocus
      >
        <DialogContent>
          <CustomMail
            handleRefresh={handleRefresh}
            mailType={CUSTOM_MAIL_TYPE}
            idList={idList}
            handleClose={() => setMailDialogShow(false)}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        onClose={() => setAssignProjectDialogShow(false)}
        open={assignProjectDialogShow}
        title="Assign Project"
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          <AssignProjectDialog
            data={editData}
            handleClose={() => setAssignProjectDialogShow(null)}
            refreshData={handleRefresh}
          />
        </DialogContent>
      </CustomDialog>
    </Stack>
  );
}

Index.propTypes = {
  terms: PropTypes.array,
  active_term: PropTypes.number,
  isCommunication: PropTypes.number,
  dispatch: PropTypes.func
};

export default connect((store) => ({
  terms: store.loginReducer.terms,
  active_term: store.loginReducer.active_term,
  isCommunication: store.routeReducer.isCommunication
}))(Index);
