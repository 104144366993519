import { fetchApi } from '../../../../api/fetchApi';

const communication = require('../../../../api/RestVariables/Admin/communication');

export async function fetchPlaceholder(config, scb, ecb) {
  try {
    const url = communication.GET_PLACEHOLDER;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_PLACEHOLDER');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function fetchPlaceholderType(config, scb, ecb) {
  try {
    const url = communication.GET_PLACEHOLDER_TYPE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_PLACEHOLDER_TYPE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function sendEmail(config, scb, ecb) {
  try {
    const url = communication.SEND_MAIL;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'SEND_MAIL');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function fetchTemplate(config, scb, ecb) {
  try {
    const url = communication.GET_TEMPLATE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_TEMPLATE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addTemplate(config, scb, ecb) {
  try {
    const url = communication.ADD_TEMPLATE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_TEMPLATE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateTemplate(config, scb, ecb) {
  try {
    const url = communication.UPDATE_TEMPLATE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_TEMPLATE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteTemplate(config, scb, ecb) {
  try {
    const url = communication.DELETE_TEMPLATE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_TEMPLATE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getEmailHistory(config, scb, ecb) {
  try {
    const url = communication.GET_EMAIL_HISTORY;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_EMAIL_HISTORY');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}
