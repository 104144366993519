export function getSummaryTableData(list) {
  // ART
  let total_art_total = 0;
  let total_art_student_count = 0;
  let total_art_honorable_mention_count = 0;
  let total_art_honorable_mention_student_count = 0;
  let total_art_bronze_count = 0;
  let total_art_bronze_student_count = 0;
  let total_art_silver_count = 0;
  let total_art_silver_student_count = 0;
  let total_art_gold_count = 0;
  let total_art_gold_student_count = 0;
  let total_art_grand_count = 0;
  let total_art_grand_student_count = 0;
  // BUSINESS
  let total_business_total = 0;
  let total_business_student_count = 0;
  let total_business_honorable_mention_count = 0;
  let total_business_honorable_mention_student_count = 0;
  let total_business_bronze_count = 0;
  let total_business_bronze_student_count = 0;
  let total_business_silver_count = 0;
  let total_business_silver_student_count = 0;
  let total_business_gold_count = 0;
  let total_business_gold_student_count = 0;
  let total_business_grand_count = 0;
  let total_business_grand_student_count = 0;
  // CODING
  let total_coding_total = 0;
  let total_coding_student_count = 0;
  let total_coding_honorable_mention_count = 0;
  let total_coding_honorable_mention_student_count = 0;
  let total_coding_bronze_count = 0;
  let total_coding_bronze_student_count = 0;
  let total_coding_silver_count = 0;
  let total_coding_silver_student_count = 0;
  let total_coding_gold_count = 0;
  let total_coding_gold_student_count = 0;
  let total_coding_grand_count = 0;
  let total_coding_grand_student_count = 0;
  // CREATIVE WRITING
  let total_creative_writing_total = 0;
  let total_creative_writing_student_count = 0;
  let total_creative_writing_honorable_mention_count = 0;
  let total_creative_writing_honorable_mention_student_count = 0;
  let total_creative_writing_bronze_count = 0;
  let total_creative_writing_bronze_student_count = 0;
  let total_creative_writing_silver_count = 0;
  let total_creative_writing_silver_student_count = 0;
  let total_creative_writing_gold_count = 0;
  let total_creative_writing_gold_student_count = 0;
  let total_creative_writing_grand_count = 0;
  let total_creative_writing_grand_student_count = 0;
  // MUSIC
  let total_music_total = 0;
  let total_music_student_count = 0;
  let total_music_honorable_mention_count = 0;
  let total_music_honorable_mention_student_count = 0;
  let total_music_bronze_count = 0;
  let total_music_bronze_student_count = 0;
  let total_music_silver_count = 0;
  let total_music_silver_student_count = 0;
  let total_music_gold_count = 0;
  let total_music_gold_student_count = 0;
  let total_music_grand_count = 0;
  let total_music_grand_student_count = 0;
  // ROBOTICS
  let total_robotics_total = 0;
  let total_robotics_student_count = 0;
  let total_robotics_honorable_mention_count = 0;
  let total_robotics_honorable_mention_student_count = 0;
  let total_robotics_bronze_count = 0;
  let total_robotics_bronze_student_count = 0;
  let total_robotics_silver_count = 0;
  let total_robotics_silver_student_count = 0;
  let total_robotics_gold_count = 0;
  let total_robotics_gold_student_count = 0;
  let total_robotics_grand_count = 0;
  let total_robotics_grand_student_count = 0;
  // SCIENCE
  let total_science_total = 0;
  let total_science_student_count = 0;
  let total_science_honorable_mention_count = 0;
  let total_science_honorable_mention_student_count = 0;
  let total_science_bronze_count = 0;
  let total_science_bronze_student_count = 0;
  let total_science_silver_count = 0;
  let total_science_silver_student_count = 0;
  let total_science_gold_count = 0;
  let total_science_gold_student_count = 0;
  let total_science_grand_count = 0;
  let total_science_grand_student_count = 0;
  // SHORT FILM
  let total_short_film_total = 0;
  let total_short_film_student_count = 0;
  let total_short_film_honorable_mention_count = 0;
  let total_short_film_honorable_mention_student_count = 0;
  let total_short_film_bronze_count = 0;
  let total_short_film_bronze_student_count = 0;
  let total_short_film_silver_count = 0;
  let total_short_film_silver_student_count = 0;
  let total_short_film_gold_count = 0;
  let total_short_film_gold_student_count = 0;
  let total_short_film_grand_count = 0;
  let total_short_film_grand_student_count = 0;
  // TOTAL
  let total_total = 0;
  let total_student_count = 0;
  let total_honorable_mention_count = 0;
  let total_honorable_mention_student_count = 0;
  let total_bronze_count = 0;
  let total_bronze_student_count = 0;
  let total_silver_count = 0;
  let total_silver_student_count = 0;
  let total_gold_count = 0;
  let total_gold_student_count = 0;
  let total_grand_count = 0;
  let total_grand_student_count = 0;

  list.forEach((item) => {
    total_art_total += item.art_total;
    total_art_student_count += item.art_student_count;
    total_art_honorable_mention_count += item.art_honorable_mention_count;
    total_art_honorable_mention_student_count += item.art_honorable_mention_student_count;
    total_art_bronze_count += item.art_bronze_count;
    total_art_bronze_student_count += item.art_bronze_student_count;
    total_art_silver_count += item.art_silver_count;
    total_art_silver_student_count += item.art_silver_student_count;
    total_art_gold_count += item.art_gold_count;
    total_art_gold_student_count += item.art_gold_student_count;
    total_art_grand_count += item.art_grand_count;
    total_art_grand_student_count += item.art_grand_student_count;

    total_business_total += item.business_total;
    total_business_student_count += item.business_student_count;
    total_business_honorable_mention_count += item.business_honorable_mention_count;
    total_business_honorable_mention_student_count += item.business_honorable_mention_student_count;
    total_business_bronze_count += item.business_bronze_count;
    total_business_bronze_student_count += item.business_bronze_student_count;
    total_business_silver_count += item.business_silver_count;
    total_business_silver_student_count += item.business_silver_student_count;
    total_business_gold_count += item.business_gold_count;
    total_business_gold_student_count += item.business_gold_student_count;
    total_business_grand_count += item.business_grand_count;
    total_business_grand_student_count += item.business_grand_student_count;

    total_coding_total += item.coding_total;
    total_coding_student_count += item.coding_student_count;
    total_coding_honorable_mention_count += item.coding_honorable_mention_count;
    total_coding_honorable_mention_student_count += item.coding_honorable_mention_student_count;
    total_coding_bronze_count += item.coding_bronze_count;
    total_coding_bronze_student_count += item.coding_bronze_student_count;
    total_coding_silver_count += item.coding_silver_count;
    total_coding_silver_student_count += item.coding_silver_student_count;
    total_coding_gold_count += item.coding_gold_count;
    total_coding_gold_student_count += item.coding_gold_student_count;
    total_coding_grand_count += item.coding_grand_count;
    total_coding_grand_student_count += item.coding_grand_student_count;

    total_creative_writing_total += item.creative_writing_total;
    total_creative_writing_student_count += item.creative_writing_student_count;
    total_creative_writing_honorable_mention_count += item.creative_writing_honorable_mention_count;
    total_creative_writing_honorable_mention_student_count +=
      item.creative_writing_honorable_mention_student_count;
    total_creative_writing_bronze_count += item.creative_writing_bronze_count;
    total_creative_writing_bronze_student_count += item.creative_writing_bronze_student_count;
    total_creative_writing_silver_count += item.creative_writing_silver_count;
    total_creative_writing_silver_student_count += item.creative_writing_silver_student_count;
    total_creative_writing_gold_count += item.creative_writing_gold_count;
    total_creative_writing_gold_student_count += item.creative_writing_gold_student_count;
    total_creative_writing_grand_count += item.creative_writing_grand_count;
    total_creative_writing_grand_student_count += item.creative_writing_grand_student_count;

    total_music_total += item.music_total;
    total_music_student_count += item.music_student_count;
    total_music_honorable_mention_count += item.music_honorable_mention_count;
    total_music_honorable_mention_student_count += item.music_honorable_mention_student_count;
    total_music_bronze_count += item.music_bronze_count;
    total_music_bronze_student_count += item.music_bronze_student_count;
    total_music_silver_count += item.music_silver_count;
    total_music_silver_student_count += item.music_silver_student_count;
    total_music_gold_count += item.music_gold_count;
    total_music_gold_student_count += item.music_gold_student_count;
    total_music_grand_count += item.music_grand_count;
    total_music_grand_student_count += item.music_grand_student_count;

    total_robotics_total += item.robotics_total;
    total_robotics_student_count += item.robotics_student_count;
    total_robotics_honorable_mention_count += item.robotics_honorable_mention_count;
    total_robotics_honorable_mention_student_count += item.robotics_honorable_mention_student_count;
    total_robotics_bronze_count += item.robotics_bronze_count;
    total_robotics_bronze_student_count += item.robotics_bronze_student_count;
    total_robotics_silver_count += item.robotics_silver_count;
    total_robotics_silver_student_count += item.robotics_silver_student_count;
    total_robotics_gold_count += item.robotics_gold_count;
    total_robotics_gold_student_count += item.robotics_gold_student_count;
    total_robotics_grand_count += item.robotics_grand_count;
    total_robotics_grand_student_count += item.robotics_grand_student_count;

    total_science_total += item.science_total;
    total_science_student_count += item.science_student_count;
    total_science_honorable_mention_count += item.science_honorable_mention_count;
    total_science_honorable_mention_student_count += item.science_honorable_mention_student_count;
    total_science_bronze_count += item.science_bronze_count;
    total_science_bronze_student_count += item.science_bronze_student_count;
    total_science_silver_count += item.science_silver_count;
    total_science_silver_student_count += item.science_silver_student_count;
    total_science_gold_count += item.science_gold_count;
    total_science_gold_student_count += item.science_gold_student_count;
    total_science_grand_count += item.science_grand_count;
    total_science_grand_student_count += item.science_grand_student_count;

    total_short_film_total += item.short_film_total;
    total_short_film_student_count += item.short_film_student_count;
    total_short_film_honorable_mention_count += item.short_film_honorable_mention_count;
    total_short_film_honorable_mention_student_count +=
      item.short_film_honorable_mention_student_count;
    total_short_film_bronze_count += item.short_film_bronze_count;
    total_short_film_bronze_student_count += item.short_film_bronze_student_count;
    total_short_film_silver_count += item.short_film_silver_count;
    total_short_film_silver_student_count += item.short_film_silver_student_count;
    total_short_film_gold_count += item.short_film_gold_count;
    total_short_film_gold_student_count += item.short_film_gold_student_count;
    total_short_film_grand_count += item.short_film_grand_count;
    total_short_film_grand_student_count += item.short_film_grand_student_count;

    total_total += item.total;
    total_student_count += item.student_count;
    total_honorable_mention_count += item.honorable_mention_count;
    total_honorable_mention_student_count += item.honorable_mention_student_count;
    total_bronze_count += item.bronze_count;
    total_bronze_student_count += item.bronze_student_count;
    total_silver_count += item.silver_count;
    total_silver_student_count += item.silver_student_count;
    total_gold_count += item.gold_count;
    total_gold_student_count += item.gold_student_count;
    total_grand_count += item.grand_count;
    total_grand_student_count += item.grand_student_count;
  });

  return [
    {
      name: 'Art',
      t: total_art_total,
      sc: total_art_student_count,
      hmc: total_art_honorable_mention_count,
      hmsc: total_art_honorable_mention_student_count,
      bc: total_art_bronze_count,
      bsc: total_art_bronze_student_count,
      sic: total_art_silver_count,
      sisc: total_art_silver_student_count,
      gc: total_art_gold_count,
      gsc: total_art_gold_student_count,
      grc: total_art_grand_count,
      grsc: total_art_grand_student_count
    },
    {
      name: 'Business',
      t: total_business_total,
      sc: total_business_student_count,
      hmc: total_business_honorable_mention_count,
      hmsc: total_business_honorable_mention_student_count,
      bc: total_business_bronze_count,
      bsc: total_business_bronze_student_count,
      sic: total_business_silver_count,
      sisc: total_business_silver_student_count,
      gc: total_business_gold_count,
      gsc: total_business_gold_student_count,
      grc: total_business_grand_count,
      grsc: total_business_grand_student_count
    },
    {
      name: 'Coding',
      t: total_coding_total,
      sc: total_coding_student_count,
      hmc: total_coding_honorable_mention_count,
      hmsc: total_coding_honorable_mention_student_count,
      bc: total_coding_bronze_count,
      bsc: total_coding_bronze_student_count,
      sic: total_coding_silver_count,
      sisc: total_coding_silver_student_count,
      gc: total_coding_gold_count,
      gsc: total_coding_gold_student_count,
      grc: total_coding_grand_count,
      grsc: total_coding_grand_student_count
    },
    {
      name: 'Creative Writing',
      t: total_creative_writing_total,
      sc: total_creative_writing_student_count,
      hmc: total_creative_writing_honorable_mention_count,
      hmsc: total_creative_writing_honorable_mention_student_count,
      bc: total_creative_writing_bronze_count,
      bsc: total_creative_writing_bronze_student_count,
      sic: total_creative_writing_silver_count,
      sisc: total_creative_writing_silver_student_count,
      gc: total_creative_writing_gold_count,
      gsc: total_creative_writing_gold_student_count,
      grc: total_creative_writing_grand_count,
      grsc: total_creative_writing_grand_student_count
    },
    {
      name: 'Music',
      t: total_music_total,
      sc: total_music_student_count,
      hmc: total_music_honorable_mention_count,
      hmsc: total_music_honorable_mention_student_count,
      bc: total_music_bronze_count,
      bsc: total_music_bronze_student_count,
      sic: total_music_silver_count,
      sisc: total_music_silver_student_count,
      gc: total_music_gold_count,
      gsc: total_music_gold_student_count,
      grc: total_music_grand_count,
      grsc: total_music_grand_student_count
    },
    {
      name: 'Robotics',
      t: total_robotics_total,
      sc: total_robotics_student_count,
      hmc: total_robotics_honorable_mention_count,
      hmsc: total_robotics_honorable_mention_student_count,
      bc: total_robotics_bronze_count,
      bsc: total_robotics_bronze_student_count,
      sic: total_robotics_silver_count,
      sisc: total_robotics_silver_student_count,
      gc: total_robotics_gold_count,
      gsc: total_robotics_gold_student_count,
      grc: total_robotics_grand_count,
      grsc: total_robotics_grand_student_count
    },
    {
      name: 'Science',
      t: total_science_total,
      sc: total_science_student_count,
      hmc: total_science_honorable_mention_count,
      hmsc: total_science_honorable_mention_student_count,
      bc: total_science_bronze_count,
      bsc: total_science_bronze_student_count,
      sic: total_science_silver_count,
      sisc: total_science_silver_student_count,
      gc: total_science_gold_count,
      gsc: total_science_gold_student_count,
      grc: total_science_grand_count,
      grsc: total_science_grand_student_count
    },
    {
      name: 'Short Film',
      t: total_short_film_total,
      sc: total_short_film_student_count,
      hmc: total_short_film_honorable_mention_count,
      hmsc: total_short_film_honorable_mention_student_count,
      bc: total_short_film_bronze_count,
      bsc: total_short_film_bronze_student_count,
      sic: total_short_film_silver_count,
      sisc: total_short_film_silver_student_count,
      gc: total_short_film_gold_count,
      gsc: total_short_film_gold_student_count,
      grc: total_short_film_grand_count,
      grsc: total_short_film_grand_student_count
    },
    {
      name: 'Total',
      t: total_total,
      sc: total_student_count,
      hmc: total_honorable_mention_count,
      hmsc: total_honorable_mention_student_count,
      bc: total_bronze_count,
      bsc: total_bronze_student_count,
      sic: total_silver_count,
      sisc: total_silver_student_count,
      gc: total_gold_count,
      gsc: total_gold_student_count,
      grc: total_grand_count,
      grsc: total_grand_student_count
    }
  ];
}
