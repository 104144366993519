import { fetchApi } from '../../../api/fetchApi';

const performance_operation = require('../../../api/RestVariables/Admin/performance_operation');

export async function getPerformanceOperationList(config, scb, ecb) {
  try {
    const url = performance_operation.GET_PERFORMANCE_OPERATION_LIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_PERFORMANCE_OPERATION');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getPerformanceOperation(config, scb, ecb) {
  try {
    const url = performance_operation.GET_PERFORMANCE_OPERATION;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_PERFORMANCE_OPERATION');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getPerformanceOperationParticipantList(config, scb, ecb) {
  try {
    const url = performance_operation.GET_PERFORMANCE_OPERATION_PARTICIPANT_LIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_PERFORMANCE_OPERATION_PARTICIPANT_LIST');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getPerformanceOperationType(config, scb, ecb) {
  try {
    const url = performance_operation.GET_PERFORMANCE_TYPE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_PERFORMANCE_TYPE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updatePerformanceOperation(config, scb, ecb) {
  try {
    const url = performance_operation.UPDATE_PERFORMANCE_OPERATION;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_PERFORMANCE_OPERATION');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateStatusPerformanceOperation(config, scb, ecb) {
  try {
    const url = performance_operation.UPDATE_STATUS_PERFORMANCE_OPERATION;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_STATUS_PERFORMANCE_OPERATION');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getPerformanceOperationStatus(config, scb, ecb) {
  try {
    const url = performance_operation.GET_PERFORMANCE_OPERATION_STATUS;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_PERFORMANCE_OPERATION_STATUS');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function downloadPerformanceFile(config, scb, ecb) {
  try {
    const url = performance_operation.DOWNLOAD_PERFORMANCE_FILE;
    await fetchApi(
      url,
      { file_id: config.id },
      (data) => {
        getFilesApi(data, config, scb, ecb);
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

const getFilesApi = (data, config, scb, ecb) => {
  const { url } = data;
  const extension = url.substring(url.lastIndexOf('.') + 1, url.lastIndexOf('?'));
  const requestOptions = {
    method: 'GET'
  };
  try {
    fetch(url, requestOptions)
      // eslint-disable-next-line consistent-return
      .then((response) => {
        if (response.status === 200) {
          scb();
          return response.blob();
        }
        ecb(response.status);
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        console.log(link);
        link.href = href;
        link.setAttribute('download', `${config.file_name}.${extension}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  } catch (error) {
    console.log(error);
    ecb(error);
  }
};

export async function getFlagShowList(config, scb, ecb) {
  try {
    const url = performance_operation.GET_FLAG_SHOW_LIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_FLAG_SHOW_LIST');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addFlagShow(config, scb, ecb) {
  try {
    const url = performance_operation.ADD_FLAG_SHOW;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_FLAG_SHOW');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateFlagShow(config, scb, ecb) {
  try {
    const url = performance_operation.UPDATE_FLAG_SHOW;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_FLAG_SHOW');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getAttendedStudentForFlagShow(config, scb, ecb) {
  try {
    const url = performance_operation.GET_ATTENDED_STUDENT_FOR_FLAG_SHOW;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_FLAG_SHOW');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deletePerformanceFile(config, scb, ecb) {
  try {
    const url = performance_operation.DELETE_PERFORMANCE_FILE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_PERFORMANCE_FILE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addPerformanceFile(config, scb, ecb) {
  try {
    const url = performance_operation.UPLOAD_PERFORMANCE_FILE;
    await fetchApi(
      url,
      config,
      (data) => {
        uploadFilesApi(data, config, scb, ecb);
        console.log(data, 'ADD_PERFORMANCE_FILE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

const uploadFilesApi = (data, config, scb, ecb) => {
  const { url } = data;

  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': config.file.type },
    body: config.file
  };
  try {
    fetch(url, requestOptions).then((response) => {
      if (response.status === 200) {
        uploadStatusOfFile(
          {
            performance_id: config.performance_id,
            file_name: config.file_name,
            is_verified: 1
          },
          () => {},
          () => {}
        );
        scb();
      } else {
        uploadStatusOfFile(
          {
            performance_id: config.performance_id,
            file_name: config.file_name,
            is_verified: 0
          },
          () => {},
          () => {}
        );
        ecb(response.status);
      }
    });
  } catch (error) {
    console.log(error);
    ecb(error);
  }
};

export async function uploadStatusOfFile(config, scb, ecb) {
  try {
    const url = performance_operation.PATCH_PERFORMANCE_FILE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_PERFORMANCE_FILE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}
