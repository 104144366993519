import * as React from 'react';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { Icon } from '@iconify/react';
import { IconButton, MenuItem, Stack, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, DialogContent, Tooltip } from '@mui/material';
import Email from '@mui/icons-material/Email';
import ReactHtmlParser from 'react-html-parser';
import MuiTable from '../../../../components/CustomComponents/MuiTable';
import headers from './utils/headerVariables';
import { getTripForAdmin, updateTripForAdmin } from '../serviceCalls';
import { setConfirmationAlert, setSnackbar } from '../../../../actions/alertActions';
import Label from '../../../../components/Label';
import { fCurrency } from '../../../../utils/formatNumber';
import CustomDialog from '../../../../components/CustomComponents/CustomDialog';
import CustomMail from '../../../../components/CustomComponents/CustomMail';
import { TRIP_MAIL_TYPE } from '../../../../api/RestVariables/staticVariables';
import Page from '../../../../components/Page';
import ProcessInfoBox from '../../../../components/CustomComponents/CustomCard/ProcessInfoBox';
import UpdateTripDialog from './Components/UpdateTripDialog';

function Index(props) {
  const { terms, active_term, dispatch, isCommunication } = props;
  const [tableData, setTableData] = useState([]);
  const [term, setTerm] = useState(active_term);
  const [mailDialogShow, setMailDialogShow] = useState(false);
  const [addMail, setAddMail] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [idList, setIdList] = useState([]);
  const [guidelines, setGuidelines] = useState([]);
  const [dialogShow, setDialogShow] = useState(false);
  const [participantData, setParticipantData] = useState(null);

  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedItems(rowsSelected);
    const arr = [];
    rowsSelected.forEach((item) => {
      arr.push(tableData[item].participant_id);
    });
    setIdList(arr);
  };
  useEffect(() => {
    fetchData();
  }, [term]);
  function fetchData() {
    getTripForAdmin(
      { term_id: term },
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((item) => {
          const _balance = Number(parseFloat(item.trip_balance).toFixed(2));
          item.name = `${item.first_name} ${item.last_name}`;
          item.balance_comp = item.trip_balance ? (
            _balance < 0 || _balance === 0 ? (
              <Label variant="ghost" color="success">
                {fCurrency(item.trip_balance)}
              </Label>
            ) : (
              <Label variant="ghost" color="error">
                {fCurrency(item.trip_balance)}
              </Label>
            )
          ) : (
            'Blank'
          );
          item.balance_filter = item.trip_balance
            ? _balance < 0 || _balance === 0
              ? 'Paid'
              : 'Not Paid'
            : 'Blank';
          item.date_of_birth_comp = moment(item.date_of_birth).format('YYYY-MM-DD');
          item.jfk_bus_comp = item.jfk_bus ? (
            <Tooltip title="Delete JFK Bus">
              <IconButton className="Success-icon-color" onClick={() => handleUpdateJFK(item)}>
                <Icon icon="akar-icons:circle-check" width={24} height={24} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Add JFK Bus">
              <IconButton className="Error-icon-color" onClick={() => handleUpdateJFK(item)}>
                <Icon icon="akar-icons:circle-x" width={24} height={24} />
              </IconButton>
            </Tooltip>
          );
          item.jfk_bus_filter = item.jfk_bus ? 'Yes' : 'No';
          item.edit = (
            <IconButton color="secondary" onClick={() => handleUpdateTrip(item)}>
              <Icon icon="ci:edit" width={24} height={24} />
            </IconButton>
          );
          item.delete = (
            <IconButton
              color="secondary"
              disabled={item.trip_id === null}
              onClick={() => handleEditTrip(item.participant_id)}
            >
              <Icon icon="fluent:delete-48-filled" width={24} height={24} />
            </IconButton>
          );
        });
        setTableData(list);
      },
      () => {}
    );
  }
  function handleEditTrip(participant_id) {
    const param = {
      participant_id,
      trip_id: null,
      jfk_bus: 0
    };
    dispatch(
      setConfirmationAlert('Are you sure?', 'YES, DELETE', 'This action will delete trip', () => {
        updateTripForAdmin(
          param,
          () => {
            dispatch(setSnackbar('success', 'The trip has been deleted'));
            dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            fetchData();
          },
          () => {
            dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
          }
        );
      })
    );
  }
  function handleUpdateJFK(participant) {
    const param = {
      participant_id: participant.participant_id,
      trip_id: participant.trip_id,
      jfk_bus: participant.jfk_bus ? 0 : 1
    };
    const message = participant.jfk_bus
      ? 'This action will delete JFK Bus'
      : 'This action will add JFK Bus';
    const buttonText = participant.jfk_bus ? 'YES, DELETE' : 'YES, ADD';
    const snackbarMessage = participant.jfk_bus
      ? 'The trip has been deleted'
      : 'The trip has been added';

    dispatch(
      setConfirmationAlert('Are you sure?', buttonText, message, () => {
        updateTripForAdmin(
          param,
          () => {
            dispatch(setSnackbar('success', snackbarMessage));
            dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            fetchData();
          },
          () => {
            dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
          }
        );
      })
    );
  }
  function TermSelect() {
    return (
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 2 }}>
        <TextField select label="Terms" value={term} onChange={(e) => setTerm(e.target.value)}>
          {terms.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
        {addMail && (
          <div>
            <Button
              style={{ marginRight: 5 }}
              disabled={selectedItems.length === 0}
              variant="outlined"
              onClick={() => setMailDialogShow(true)}
            >
              Next
            </Button>
            <Button color="error" variant="outlined" onClick={() => setAddMail(false)}>
              Cancel
            </Button>
          </div>
        )}
      </Stack>
    );
  }
  function handleRefresh() {
    setMailDialogShow(false);
    setAddMail(false);
    setSelectedItems([]);
    setDialogShow(false);
    setParticipantData(null);
    fetchData();
  }

  function handleUpdateTrip(participant) {
    setParticipantData(participant);
    setDialogShow(true);
  }

  return (
    <Page title="Trip | Genius">
      <Stack spacing={2}>
        {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
        <MuiTable
          tableData={tableData}
          tableColumns={headers}
          title={<TermSelect />}
          customOption={{
            selectableRows: addMail ? 'multiple' : 'none',
            onRowSelectionChange,
            rowsSelected: selectedItems,
            customToolbar: () =>
              isCommunication !== -1 && (
                <Tooltip title="Send Email">
                  <IconButton onClick={() => setAddMail(true)}>
                    <Email />
                  </IconButton>
                </Tooltip>
              )
          }}
        />
      </Stack>
      <CustomDialog
        onClose={() => setMailDialogShow(false)}
        open={mailDialogShow}
        maxWidth="lg"
        title="Send Mail"
        fullWidth
        disableEnforceFocus
      >
        <DialogContent>
          <CustomMail
            handleRefresh={handleRefresh}
            mailType={TRIP_MAIL_TYPE}
            idList={idList}
            handleClose={() => setMailDialogShow(false)}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        onClose={() => setDialogShow(false)}
        open={dialogShow}
        maxWidth="lg"
        title="Trip Update"
      >
        <DialogContent>
          <UpdateTripDialog
            participantData={participantData}
            handleClose={() => setDialogShow(false)}
            handleRefresh={handleRefresh}
          />
        </DialogContent>
      </CustomDialog>
    </Page>
  );
}

Index.propTypes = {
  terms: PropTypes.array,
  active_term: PropTypes.number,
  dispatch: PropTypes.func.isRequired,
  isCommunication: PropTypes.number
};

export default connect((store) => ({
  terms: store.loginReducer.terms,
  active_term: store.loginReducer.active_term,
  isCommunication: store.routeReducer.isCommunication
}))(Index);
