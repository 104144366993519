import React, { useEffect, useState } from 'react';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import MuiTable from '../../../../components/CustomComponents/MuiTable';
import { getStudentDuplicate } from '../serviceCalls';
import headers from './utils/headerVariables';
import Label from '../../../../components/Label';
import { fCurrency } from '../../../../utils/formatNumber';
import ProcessInfoBox from '../../../../components/CustomComponents/CustomCard/ProcessInfoBox';

function MultipleProjects() {
  const [tableData, setTableData] = useState([]);
  const [guidelines, setGuidelines] = useState([]);

  useEffect(() => {
    getStudentDuplicate(
      {},
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((item) => {
          const _balance = Number(parseFloat(item.balance).toFixed(2));
          item.balance_comp =
            _balance < 0 || _balance === 0 ? (
              <Label variant="ghost" color="success">
                {fCurrency(item.balance)}
              </Label>
            ) : (
              <Label variant="ghost" color="error">
                {fCurrency(item.balance)}
              </Label>
            );
          item.balance_filter = _balance < 0 || _balance === 0 ? 'Paid' : 'Not Paid';
          item.name = `${item.first_name} ${item.last_name}`;
          item.date_of_birth_comp = moment(item.date_of_birth).format('YYYY-MM-DD');
        });
        setTableData(list);
      },
      () => {}
    );
  }, []);

  return (
    <>
      {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
      <MuiTable
        title="Students With Multiple Projects"
        tableData={tableData}
        tableColumns={headers}
      />
    </>
  );
}

export default MultipleProjects;
