export function scoreMaker(data) {
  const keySet = new Set();
  data.forEach((item) => {
    const keys = Object.keys(item);
    keys.forEach((key) => {
      if (key !== 'survey_result_id' && !key.includes('label')) {
        keySet.add(key);
      }
    });
  });
  const headers = [
    {
      name: 'survey_result_id',
      label: 'ID'
    }
  ];
  const keyList = Array.from(keySet);
  const sortedList = keyList.sort((a, b) => {
    const numA = parseInt(a.split('_')[0], 10);
    const numB = parseInt(b.split('_')[0], 10);
    return numA - numB;
  });
  sortedList.forEach((key) => {
    const name = key.substring(key.indexOf('_') + 1);
    const avgScore = parseFloat(getAvgScore(data, key)).toFixed(2);
    let label = '';
    const rank = key.substring(0, key.indexOf('_'));
    const fieldName = `${rank}_label`;
    const labelFromDB = data[0][fieldName];
    if (name === 'score') {
      label = `${labelFromDB}-(${avgScore})`;
    } else {
      label = `${labelFromDB}-C`;
    }

    headers.push({
      name: key,
      label
    });
  });
  return headers;
}

function getAvgScore(data, key) {
  const list = data.map((item) => item[key]);

  if (list.length === 0) {
    return 0;
  }

  let sum = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < list.length; i++) {
    sum += list[i];
  }

  return sum / list.length;
}
