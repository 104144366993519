import { fetchApi } from '../../../api/fetchApi';

const participant_operation = require('../../../api/RestVariables/Admin/participant_operation');

export async function getParticipantOperationList(config, scb, ecb) {
  try {
    const url = participant_operation.GET_PARTICIPANT_OP_LIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_PARTICIPANT_OP_LIST');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getParticipantReport(config, scb, ecb) {
  try {
    const url = participant_operation.GET_PARTICIPANT_REPORT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_PARTICIPANT_REPORT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getParticipantOperationParticipant(config, scb, ecb) {
  try {
    const url = participant_operation.GET_PARTICIPANT_OP_PARTICIPANT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_PARTICIPANT_OP_PARTICIPANT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateParticipantOperationParticipant(config, scb, ecb) {
  try {
    const url = participant_operation.UPDATE_PARTICIPANT_OP_PARTICIPANT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_PARTICIPANT_OP_PARTICIPANT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateParticipantOperationAttendance(config, scb, ecb) {
  try {
    const url = participant_operation.UPDATE_PARTICIPANT_OP_ATTENDANCE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_PARTICIPANT_OP_ATTENDANCE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getParticipantOperationInvitationLetterList(config, scb, ecb) {
  try {
    const url = participant_operation.GET_PARTICIPANT_OP_INVITATION_LETTER_LIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_PARTICIPANT_OP_INVITATION_LETTER_LIST');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function downloadParticipantOperationInvitationLetter(config, scb, ecb) {
  try {
    const url = participant_operation.DOWNLOAD_PARTICIPANT_OP_INVITATION_LETTER;
    await fetchApi(
      url,
      config,
      (data) => {
        getFilesApi(data, config, scb, ecb);
        console.log(data, 'DOWNLOAD_PARTICIPANT_OP_INVITATION_LETTER');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function downloadCertificate(config, scb, ecb) {
  try {
    const url = participant_operation.DOWNLOAD_CERTIFICATE;
    await fetchApi(
      url,
      config,
      (data) => {
        getFilesApi(data, config, scb, ecb);
        console.log(data, 'DOWNLOAD_PARTICIPANT_OP_INVITATION_LETTER');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}
const getFilesApi = (data, config, scb, ecb) => {
  const { url } = data;
  const extension = url.substring(url.lastIndexOf('.') + 1, url.lastIndexOf('?'));

  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'multipart/form-data' }
  };
  try {
    fetch(url, requestOptions)
      // eslint-disable-next-line consistent-return
      .then((response) => {
        if (response.status === 200) {
          return response.blob();
        }
        ecb(response.status);
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        console.log(link);
        link.href = href;
        link.setAttribute('download', `certificate.${extension}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        scb();
      });
  } catch (error) {
    console.log(error);
    ecb(error);
  }
};

export async function getVipForAdmin(config, scb, ecb) {
  try {
    const url = participant_operation.GET_VIP_FOR_ADMIN;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_VIP_FOR_ADMIN');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteVipForAdmin(config, scb, ecb) {
  try {
    const url = participant_operation.DELETE_VIP_FOR_ADMIN;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_VIP_FOR_ADMIN');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getTripForAdmin(config, scb, ecb) {
  try {
    const url = participant_operation.GET_TRIP_FOR_ADMIN;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_VIP_FOR_ADMIN');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateTripForAdmin(config, scb, ecb) {
  try {
    const url = participant_operation.UPDATE_TRIP_FOR_ADMIN;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_TRIP_FOR_ADMIN');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getTripListForAdmin(config, scb, ecb) {
  try {
    const url = participant_operation.GET_TRIP_LIST_FOR_ADMIN;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_TRIP_LIST_FOR_ADMIN');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getTravelForAdmin(config, scb, ecb) {
  try {
    const url = participant_operation.GET_TRAVEL_FOR_ADMIN;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_TRAVEL_FOR_ADMIN');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getTravelParticipantForAdmin(config, scb, ecb) {
  try {
    const url = participant_operation.GET_TRAVEL_PARTICIPANT_FOR_ADMIN;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_TRAVEL_PARTICIPANT_FOR_ADMIN');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function participantManualPayment(config, scb, ecb) {
  try {
    const url = participant_operation.PARTICIPANT_MANUAL_PAYMENT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'PARTICIPANT_MANUAL_PAYMENT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getBadge(config, scb, ecb, secondError) {
  try {
    const url = participant_operation.GET_BADGE;
    await fetchApi(
      url,
      config,
      (data) => {
        if (data?.result.url) {
          getBadgeFiles(data?.result, secondError);
        }
        scb(data);
        console.log(data, 'GET_BADGE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

const getBadgeFiles = (data, ecb) => {
  const { url } = data;
  const extension = url.substring(url.lastIndexOf('.') + 1, url.lastIndexOf('?'));

  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'multipart/form-data' }
  };
  try {
    fetch(url, requestOptions)
      // eslint-disable-next-line consistent-return
      .then((response) => {
        if (response.status === 200) {
          return response.blob();
        }
        ecb(response.status);
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        console.log(link);
        link.href = href;
        link.setAttribute('download', `badges.${extension}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  } catch (error) {
    console.log(error);
    ecb(error);
  }
};

export async function getForm(config, scb, ecb) {
  try {
    const url = participant_operation.GET_FORM;
    await fetchApi(
      url,
      config,
      (data) => {
        if (data?.result.url) {
          getFormFiles(data?.result, ecb);
        }
        scb(data);
        console.log(data, 'GET_FORM');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

const getFormFiles = (data, ecb) => {
  const { url } = data;
  const extension = url.substring(url.lastIndexOf('.') + 1, url.lastIndexOf('?'));

  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'multipart/form-data' }
  };
  try {
    fetch(url, requestOptions)
      // eslint-disable-next-line consistent-return
      .then((response) => {
        if (response.status === 200) {
          return response.blob();
        }
        ecb(response.status);
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        console.log(link);
        link.href = href;
        link.setAttribute('download', `consent_form.${extension}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  } catch (error) {
    console.log(error);
    ecb(error);
  }
};
