const HOST = process.env.REACT_APP_REST_API_HOST;

const REGISTER = {
  method: 'POST',
  path: `${HOST}go_shield/registration/register`
};
const FAIR_REGISTER = {
  method: 'POST',
  path: `${HOST}go_shield/registration/fair_register`
};
const JUDGE_REGISTER = {
  method: 'POST',
  path: `${HOST}go_shield/registration/judge_register`
};
const VERIFICATION_CREATE = {
  method: 'POST',
  path: `${HOST}go_shield/registration/email_verification/create`
};
const VERIFICATION_VERIFY = {
  method: 'GET',
  path: `${HOST}go_shield/registration/email_verification/verify`
};
const GET_DISCIPLINE_FOR_REGISTER = {
  method: 'GET',
  path: `${HOST}go_shield/registration/common/discipline`
};
const GET_SESSION_LIST_FOR_REGISTER = {
  method: 'GET',
  path: `${HOST}go_shield/registration/judge_register/session_list`
};

module.exports = {
  REGISTER,
  FAIR_REGISTER,
  JUDGE_REGISTER,
  VERIFICATION_CREATE,
  VERIFICATION_VERIFY,
  GET_DISCIPLINE_FOR_REGISTER,
  GET_SESSION_LIST_FOR_REGISTER
};
