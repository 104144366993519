import { MenuItem, Stack, TextField } from '@material-ui/core';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { setLoading, setSnackbar } from '../../../../../../actions/alertActions';
import { getRoboticsFormRubricType, updateRoboticsFormRubric } from '../../_apis/serviceCalls';
import MarkdownEditor from '../AddRubric/MarkdownEditor';

function EditRubricForm(props) {
  const { rubric, dispatch, handleSuccess, handleClose } = props;
  const [markdown, setMarkdown] = useState(rubric.markdown);
  const [typeList, setTypeList] = useState([]);

  useEffect(() => {
    getRoboticsFormRubricType(
      {},
      (data) => {
        setTypeList(data);
      },
      () => {}
    );
  }, []);

  const Schema = Yup.object().shape({
    rubric_type_id: Yup.number().required('Type is required'),
    name: Yup.string().max(500, 'Too Long!').required('Name is required'),
    points: Yup.number().required('Points is required'),
    limit: Yup.number().required('Limit is required')
  });
  const formik = useFormik({
    initialValues: {
      rubric_type_id: rubric.rubric_type_id,
      name: rubric.name,
      points: rubric.points,
      limit: rubric.limit
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      dispatch(setLoading(1, 'Loading...'));
      const param = { ...values, markdown, competition_rubric_id: rubric.id };
      updateRoboticsFormRubric(
        param,
        () => {
          handleSuccess();
          dispatch(setLoading(0, ''));
          dispatch(setSnackbar('success', 'The rubric has been updated'));
        },
        () => {
          dispatch(setLoading(0, ''));
        }
      );
    }
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <MarkdownEditor markdown={markdown} setMarkdown={setMarkdown} />
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Rubric Type"
              select
              {...getFieldProps('rubric_type_id')}
              error={Boolean(touched.rubric_type_id && errors.rubric_type_id)}
              helperText={touched.rubric_type_id && errors.rubric_type_id}
            >
              {typeList.map((term) => (
                <MenuItem key={term.id} value={term.id}>
                  {term.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              fullWidth
              label="Name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Points"
              type="number"
              {...getFieldProps('points')}
              error={Boolean(touched.points && errors.points)}
              helperText={touched.points && errors.points}
            />
            <TextField
              fullWidth
              label="Limit"
              type="number"
              {...getFieldProps('limit')}
              error={Boolean(touched.limit && errors.limit)}
              helperText={touched.limit && errors.limit}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="end" spacing={2}>
            <Button type="submit" variant="outlined">
              Update
            </Button>
            <Button variant="outlined" color="error" onClick={handleClose}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

EditRubricForm.propTypes = {
  rubric: PropTypes.object.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default connect(() => ({}))(EditRubricForm);
