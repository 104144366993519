import * as Yup from 'yup';
import React, { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { Stack, TextField } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { judgeRegister } from '../../../actions/registerActions';
import CustomSnackbar from '../../../components/CustomComponents/CustomSnackbar';
import { getJudgeSessions } from './dataMaker';
import SessionSelection from './SessionSelection';

// ----------------------------------------------------------------------
const englishChar = /^[-a-zA-Z0-9& ]+$/;

function RegisterForm(props) {
  const { successRegister, sessionList } = props;
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [morningChecked, setMorningChecked] = useState(null);
  const [afternoonChecked, setAfternoonChecked] = useState(null);
  const [onlineCheckedlist, setOnlineCheckedList] = useState([]);

  const RegisterSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(2, 'Too Short!')
      .max(500, 'Too Long!')
      .matches(englishChar, 'Please enter only English characters')
      .required('First name required'),
    last_name: Yup.string()
      .min(2, 'Too Short!')
      .max(500, 'Too Long!')
      .matches(englishChar, 'Please enter only English characters')
      .required('Last name required'),
    institution: Yup.string()
      .min(2, 'Too Short!')
      .max(500, 'Too Long!')
      .required('Institution required'),
    email: Yup.string()
      .max(500, 'Too Long!')
      .email('Email must be a valid email address')
      .required('Email is required')
  });
  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      institution: '',
      email: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: (values, { setSubmitting }) => {
      const judgingSessions = getJudgeSessions(morningChecked, afternoonChecked, onlineCheckedlist);
      const param = { ...values, judging_sessions: judgingSessions };
      if (judgingSessions.length === 0) {
        setIsError('warning');
        setErrorText('Please select judge session');
        setSubmitting(false);
      } else {
        judgeRegister(
          param,
          () => {
            successRegister();
          },
          (error) => {
            if (error.is_visible) {
              setIsError('error');
              setErrorText(error.go_error_message);
            }
            setSubmitting(false);
          }
        );
      }
    }
  });
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="First name"
              {...getFieldProps('first_name')}
              error={Boolean(touched.first_name && errors.first_name)}
              helperText={touched.first_name && errors.first_name}
            />
            <TextField
              fullWidth
              label="Last name"
              {...getFieldProps('last_name')}
              error={Boolean(touched.last_name && errors.last_name)}
              helperText={touched.last_name && errors.last_name}
            />
          </Stack>
          <TextField
            fullWidth
            label="Institution"
            {...getFieldProps('institution')}
            error={Boolean(touched.institution && errors.institution)}
            helperText={touched.institution && errors.institution}
          />
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          {sessionList && (
            <SessionSelection
              sessionList={sessionList}
              morningChecked={morningChecked}
              setMorningChecked={setMorningChecked}
              afternoonChecked={afternoonChecked}
              setAfternoonChecked={setAfternoonChecked}
              onlineCheckedlist={onlineCheckedlist}
              setOnlineCheckedList={setOnlineCheckedList}
            />
          )}
          <LoadingButton
            loading={isSubmitting}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            Register
          </LoadingButton>
          {isError && (
            <CustomSnackbar
              settings={{ type: isError, message: errorText, onHide: () => setIsError(false) }}
            />
          )}
        </Stack>
      </Form>
    </FormikProvider>
  );
}

RegisterForm.propTypes = {
  successRegister: PropTypes.func.isRequired,
  sessionList: PropTypes.array
};

export default connect(() => ({}))(RegisterForm);
