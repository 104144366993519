const HOST = process.env.REACT_APP_REST_API_HOST;

const GET_GUIDELINE_LIST = {
  method: 'GET',
  path: `${HOST}go_admin/guideline/guideline/list`
};
const GET_GUIDELINE_TYPE = {
  method: 'GET',
  path: `${HOST}go_admin/guideline/guideline/type`
};
const GET_GUIDELINE_FEATURE = {
  method: 'GET',
  path: `${HOST}go_admin/guideline/guideline/feature`
};
const GET_GUIDELINE = {
  method: 'GET',
  path: `${HOST}go_admin/guideline/guideline`
};
const ADD_GUIDELINE = {
  method: 'POST',
  path: `${HOST}go_admin/guideline/guideline`
};
const UPDATE_GUIDELINE = {
  method: 'PUT',
  path: `${HOST}go_admin/guideline/guideline`
};
const DELETE_GUIDELINE = {
  method: 'DELETE',
  path: `${HOST}go_admin/guideline/guideline`
};

module.exports = {
  GET_GUIDELINE_LIST,
  GET_GUIDELINE_TYPE,
  GET_GUIDELINE_FEATURE,
  GET_GUIDELINE,
  ADD_GUIDELINE,
  UPDATE_GUIDELINE,
  DELETE_GUIDELINE
};
