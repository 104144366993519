import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DialogContent, Stack } from '@mui/material';
import ReactHtmlParser from 'react-html-parser';
import LandingPage from './LandingPage';
import { setConfirmationAlert, setLoading, setSnackbar } from '../../../../actions/alertActions';
import { getRoboticsForm, deleteRoboticsForm } from './_apis/serviceCalls';
import CustomDialog from '../../../../components/CustomComponents/CustomDialog';
import AddUpdateStepper from './AddUpdateStepper';
import ProcessInfoBox from '../../../../components/CustomComponents/CustomCard/ProcessInfoBox';

const LANDING_MODE = 0;
const ADD_MODE = 1;
const UPDATE_MODE = 2;

function Index(props) {
  const { dispatch, active_term } = props;
  const [mode, setMode] = useState(LANDING_MODE);
  const [term, setTerm] = useState(active_term);
  const [formData, setFormData] = useState([]);
  const [editData, setEditData] = useState(null);
  const [guidelines, setGuidelines] = useState([]);

  useEffect(() => {
    fetchData();
  }, [term]);

  function fetchData() {
    dispatch(setLoading(1, 'Loading...'));
    getRoboticsForm(
      { term_id: term },
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        dispatch(setLoading(0, ''));
        setFormData(list);
      },
      () => {
        dispatch(setLoading(0, ''));
      }
    );
  }

  function handleRefresh() {
    setMode(LANDING_MODE);
    fetchData();
  }

  function handleEditForm(data) {
    setMode(UPDATE_MODE);
    setEditData(data);
  }

  function handleDeleteForm(competition_form_id) {
    const param = { competition_form_id, force_delete: 0 };
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'As a result of this process, the form will be deleted irreversibly for all users, are you sure?',
        () => {
          deleteRoboticsForm(
            param,
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
              dispatch(setSnackbar('success', 'Form deleted.'));
              fetchData();
            },
            (error) => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
              if (error.http_status_code === 409) {
                forceDelete(competition_form_id, error.message);
              }
            }
          );
        }
      )
    );
  }

  function forceDelete(judging_form_question_id, message) {
    dispatch(
      setConfirmationAlert('Are you sure?', 'YES, FORCE DELETE', message, () => {
        deleteRoboticsForm(
          { judging_form_question_id, force_delete: 1 },
          () => {
            dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            dispatch(setSnackbar('success', 'Form deleted.'));
            fetchData();
          },
          () => {
            dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
          }
        );
      })
    );
  }

  return (
    <Stack spacing={2}>
      {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
      <LandingPage
        formData={formData}
        addForm={() => setMode(ADD_MODE)}
        term={term}
        setTerm={setTerm}
        handleDeleteForm={handleDeleteForm}
        handleEditForm={handleEditForm}
      />
      <CustomDialog
        onClose={() => setMode(null)}
        open={mode === ADD_MODE || mode === UPDATE_MODE}
        title={mode === ADD_MODE ? 'Add Robotics Form' : 'Update Form'}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          <AddUpdateStepper handleFinish={handleRefresh} mode={mode} data={editData} />
        </DialogContent>
      </CustomDialog>
    </Stack>
  );
}

Index.propTypes = {
  dispatch: PropTypes.func,
  active_term: PropTypes.number
};

export default connect((store) => ({
  active_term: store.loginReducer.active_term
}))(Index);
