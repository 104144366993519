// QuestionsLandingPage table headers
import { convertHourToSeconds } from '../../../../../utils/formatTime';

export function getHeaders(judgeList) {
  const _sessionList = new Set();
  const _disciplineList = new Set();
  const _sessionTypeList = new Set();
  const _afternoonList = new Set();
  const _morningList = new Set();
  const _onlineList = new Set();
  judgeList.forEach((judge) => {
    const sessionArray = judge.judging_sessions?.split(',');
    // eslint-disable-next-line no-unused-expressions
    sessionArray &&
      sessionArray.forEach((session) => {
        _sessionList.add(session);
      });
    const disciplineArray = judge.judging_session_disciplines?.split(',');
    // eslint-disable-next-line no-unused-expressions
    disciplineArray &&
      disciplineArray.forEach((discipline) => {
        _disciplineList.add(discipline);
      });
    const typeArray = judge.judging_session_types?.split(',');
    // eslint-disable-next-line no-unused-expressions
    typeArray &&
      typeArray.forEach((type) => {
        _sessionTypeList.add(type);
      });
    const afternoonArray = judge.afternoon_sessions?.split(',');
    // eslint-disable-next-line no-unused-expressions
    afternoonArray &&
      afternoonArray.forEach((afternoon) => {
        _afternoonList.add(afternoon);
      });
    const morningArray = judge.morning_sessions?.split(',');
    // eslint-disable-next-line no-unused-expressions
    morningArray &&
      morningArray.forEach((morning) => {
        _morningList.add(morning);
      });
    const onlineArray = judge.online_sessions?.split(',');
    // eslint-disable-next-line no-unused-expressions
    onlineArray &&
      onlineArray.forEach((online) => {
        _onlineList.add(online);
      });
  });
  const arrayOfSessionList = Array.from(_sessionList);
  const arrayOfDisciplineList = Array.from(_disciplineList);
  const arrayOfAfternoonList = Array.from(_afternoonList);
  const arrayOfMorningList = Array.from(_morningList);
  const arrayOfOnlineList = Array.from(_onlineList);

  console.log(arrayOfAfternoonList, arrayOfMorningList, arrayOfOnlineList, 'headers');

  // eslint-disable-next-line no-unreachable
  const list = [
    {
      name: 'judge_id',
      label: 'ID'
    },
    {
      name: 'first_name',
      label: 'First Name'
    },
    {
      name: 'last_name',
      label: 'Last Name'
    },
    {
      name: 'institution',
      label: 'Institution'
    },
    {
      name: 'email',
      label: 'Email'
    },
    {
      name: 'term_id',
      label: 'Term'
    },
    {
      name: 'judging_session_disciplines',
      label: 'Disciplines',
      options: {
        filter: true,
        filterOptions: {
          logic: (location, filters) => {
            let isExist = false;
            filters.forEach((item) => {
              isExist = !location?.includes(item);
            });
            return isExist;
          },
          names: arrayOfDisciplineList
        }
      }
    },
    {
      name: 'judging_sessions',
      label: 'Sessions',
      options: {
        filter: true,
        filterOptions: {
          logic: (location, filters) => {
            let isExist = false;
            filters.forEach((item) => {
              isExist = !location?.includes(item);
            });
            return isExist;
          },
          names: arrayOfSessionList
        }
      }
    },
    {
      name: 'afternoon_sessions',
      label: 'Afternoon',
      options: {
        display: arrayOfAfternoonList.length > 0,
        filter: true,
        filterOptions: {
          logic: (location, filters) => {
            let isExist = false;
            filters.forEach((item) => {
              isExist = !location?.includes(item);
            });
            return isExist;
          },
          names: arrayOfAfternoonList
        }
      }
    },
    {
      name: 'morning_sessions',
      label: 'Morning',
      options: {
        display: arrayOfMorningList.length > 0,
        filter: true,
        filterOptions: {
          logic: (location, filters) => {
            let isExist = false;
            filters.forEach((item) => {
              isExist = !location?.includes(item);
            });
            return isExist;
          },
          names: arrayOfMorningList
        }
      }
    },
    {
      name: 'online_sessions',
      label: 'Online',
      options: {
        display: arrayOfOnlineList.length > 0,
        filter: true,
        filterOptions: {
          logic: (location, filters) => {
            let isExist = false;
            filters.forEach((item) => {
              isExist = !location?.includes(item);
            });
            return isExist;
          },
          names: arrayOfOnlineList
        }
      }
    },
    {
      name: 'number_assigned_projects',
      label: 'Assigned Project'
    },
    {
      name: 'number_completed_projects',
      label: 'Completed Project'
    },
    {
      name: 'totalTime',
      label: 'Judging Time',
      options: {
        sortCompare: (order) => (obj1, obj2) => {
          const val1 = convertHourToSeconds(obj1?.data);
          const val2 = convertHourToSeconds(obj2?.data);
          return (val1 - val2) * (order === 'asc' ? 1 : -1);
        }
      }
    },
    {
      name: 'medal',
      label: 'Download Certificate',
      options: {
        sort: false,
        filter: false,
        print: false,
        download: false
      }
    },
    {
      name: 'project',
      label: 'Assign Project',
      options: {
        sort: false,
        filter: false,
        print: false,
        download: false
      }
    },
    {
      name: 'unassign',
      label: 'Unassign All Projects',
      options: {
        sort: false,
        filter: false,
        print: false,
        download: false
      }
    }
  ];

  // eslint-disable-next-line no-unreachable
  return { list };
}
