const HOST = process.env.REACT_APP_REST_API_HOST;

const GET_PERFORMANCE = {
  method: 'GET',
  path: `${HOST}go_core/performance/performance`
};
const ADD_PERFORMANCE = {
  method: 'POST',
  path: `${HOST}go_core/performance/performance`
};
const UPDATE_PERFORMANCE = {
  method: 'PUT',
  path: `${HOST}go_core/performance/performance`
};
const DELETE_PERFORMANCE = {
  method: 'DELETE',
  path: `${HOST}go_core/performance/performance`
};
const GET_PERFORMANCE_TYPES = {
  method: 'GET',
  path: `${HOST}go_core/performance/performance/type`
};
const GET_PERFORMANCE_PARTICIPANTS = {
  method: 'GET',
  path: `${HOST}go_core/performance/performance/participant`
};
const GET_PERFORMANCE_FILE = {
  method: 'GET',
  path: `${HOST}go_core/performance/performance/file`
};
const ADD_PERFORMANCE_FILE = {
  method: 'POST',
  path: `${HOST}go_core/performance/performance/file`
};
const UPDATE_PERFORMANCE_FILE = {
  method: 'PATCH',
  path: `${HOST}go_core/performance/performance/file`
};
const DELETE_PERFORMANCE_FILE = {
  method: 'DELETE',
  path: `${HOST}go_core/performance/performance/file`
};
const GET_PERFORMANCE_FILE_LIST = {
  method: 'GET',
  path: `${HOST}go_core/performance/performance/file_list`
};

module.exports = {
  GET_PERFORMANCE,
  ADD_PERFORMANCE,
  UPDATE_PERFORMANCE,
  DELETE_PERFORMANCE,
  GET_PERFORMANCE_TYPES,
  GET_PERFORMANCE_PARTICIPANTS,
  GET_PERFORMANCE_FILE,
  ADD_PERFORMANCE_FILE,
  UPDATE_PERFORMANCE_FILE,
  DELETE_PERFORMANCE_FILE,
  GET_PERFORMANCE_FILE_LIST
};
