import { Divider, Grid, Card } from '@mui/material';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Page from '../../components/Page';
import PasswordCard from './PasswordCard';
import InformationCard from './InformationCard';
import RoleCard from './RoleCard';
import { getProfile, getUserRole } from './utils/serviceCalls';

function Index({ dispatch }) {
  const [profile, setProfile] = useState(null);
  const [roles, setRoles] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    getUserRole(
      {},
      (data) => {
        setRoles(data);
      },
      () => {}
    );
    getProfile(
      {},
      (data) => {
        setProfile(data);
      },
      () => {}
    );
  }

  function refreshData() {
    getProfile(
      {},
      (data) => {
        setProfile(data);
        dispatch({ type: 'SET_USER_PROFILE', payload: data[0] });
      },
      () => {}
    );
    getUserRole(
      {},
      (data) => {
        setRoles(data);
      },
      () => {}
    );
  }

  return (
    <Page title="Profile | Genius">
      {profile && (
        <Grid container>
          <Card style={{ padding: 20, margin: 10 }}>
            <Grid item xs={12}>
              <InformationCard profile={profile[0]} refreshData={refreshData} />
            </Grid>
            <Divider style={{ marginTop: 40, marginBottom: 40 }} />
            <Grid item xs={12}>
              <PasswordCard />
            </Grid>
            <Divider style={{ marginTop: 40, marginBottom: 40 }} />
            {roles.length > 1 && (
              <Grid item xs={12}>
                <RoleCard roles={roles} />
              </Grid>
            )}
          </Card>
        </Grid>
      )}
    </Page>
  );
}

Index.propTypes = {
  dispatch: PropTypes.func
};

export default connect(() => ({}))(Index);
