import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, DialogContent, Tooltip } from '@mui/material';
import { IconButton, MenuItem, Stack, TextField } from '@material-ui/core';
import { Icon } from '@iconify/react';
import ReactHtmlParser from 'react-html-parser';
import Email from '@mui/icons-material/Email';
import { connect } from 'react-redux';
import { addFlagShow, getFlagShowList } from '../serviceCalls';
import Page from '../../../../components/Page';
import ProcessInfoBox from '../../../../components/CustomComponents/CustomCard/ProcessInfoBox';
import MuiTable from '../../../../components/CustomComponents/MuiTable';
import headers from './_utils/headerVariables';
import CustomDialog from '../../../../components/CustomComponents/CustomDialog';
import CustomMail from '../../../../components/CustomComponents/CustomMail';
import { CUSTOM_MAIL_TYPE } from '../../../../api/RestVariables/staticVariables';
import ActionDialog from './ActionDialog';
import { setSnackbar } from '../../../../actions/alertActions';

function FlagShow(props) {
  const { terms, active_term, isCommunication, dispatch } = props;
  const [term, setTerm] = useState(active_term);
  const [tableData, setTableData] = useState([]);
  const [mailDialogShow, setMailDialogShow] = useState(false);
  const [actionDialog, setActionDialog] = useState(false);
  const [addMail, setAddMail] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [country, setCountry] = useState(null);
  const [idList, setIdList] = useState([]);
  const [guidelines, setGuidelines] = useState([]);

  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedItems(rowsSelected);
    const arr = [];
    rowsSelected.forEach((item) => {
      arr.push(tableData[item].user_id);
    });
    setIdList(arr);
  };

  useEffect(() => {
    fetchData();
  }, [term]);
  function fetchData() {
    getFlagShowList(
      { term_id: term },
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((item) => {
          item.user_name = `${item.user_first_name} ${item.user_last_name}`;
          item.student_name = item.student_first_name
            ? `${item.student_first_name} ${item.student_last_name}`
            : '-';
          item.edit = (
            <IconButton color="secondary" onClick={() => handleEditFlagShow(item)}>
              <Icon icon="ci:edit" width={24} height={24} />
            </IconButton>
          );
        });
        setTableData(list);
      },
      () => {}
    );
  }
  function handleRefresh() {
    setMailDialogShow(false);
    setAddMail(false);
    setSelectedItems([]);
    setActionDialog(false);
    setCountry(null);
    fetchData();
  }
  function handleEditFlagShow(country) {
    setCountry(country);
    setActionDialog(true);
  }
  function TermSelect() {
    return (
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 2 }}>
        <TextField select label="Terms" value={term} onChange={(e) => setTerm(e.target.value)}>
          {terms.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
        {!addMail && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button variant="outlined" onClick={handleAddFlag}>
              Add
            </Button>
          </div>
        )}
        {addMail && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              style={{ marginRight: 5 }}
              disabled={selectedItems.length === 0}
              variant="outlined"
              onClick={() => setMailDialogShow(true)}
            >
              Next
            </Button>
            <Button color="error" variant="outlined" onClick={() => setAddMail(false)}>
              Cancel
            </Button>
          </div>
        )}
      </Stack>
    );
  }

  function handleAddFlag() {
    addFlagShow({}, () => {
      dispatch(setSnackbar('success', 'Flag Show added'));
      fetchData();
    });
  }

  return (
    <Page title="Performance Operation | Genius">
      {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
      <MuiTable
        tableData={tableData}
        tableColumns={headers}
        title={<TermSelect />}
        customOption={{
          selectableRows: addMail ? 'multiple' : 'none',
          onRowSelectionChange,
          rowsSelected: selectedItems,
          customToolbar: () =>
            isCommunication !== -1 && (
              <Tooltip title="Send Email">
                <IconButton onClick={() => setAddMail(true)}>
                  <Email />
                </IconButton>
              </Tooltip>
            )
        }}
      />
      <CustomDialog
        onClose={() => setMailDialogShow(false)}
        open={mailDialogShow}
        maxWidth="lg"
        title="Send Mail"
        fullWidth
        disableEnforceFocus
      >
        <DialogContent>
          <CustomMail
            handleRefresh={handleRefresh}
            mailType={CUSTOM_MAIL_TYPE}
            idList={idList}
            handleClose={() => setMailDialogShow(false)}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        onClose={() => setActionDialog(false)}
        open={actionDialog}
        maxWidth="lg"
        title="Flag Show"
      >
        <DialogContent>
          <ActionDialog
            handleRefresh={handleRefresh}
            country={country}
            handleClose={() => setActionDialog(false)}
          />
        </DialogContent>
      </CustomDialog>
    </Page>
  );
}

FlagShow.propTypes = {
  terms: PropTypes.array,
  active_term: PropTypes.number,
  isCommunication: PropTypes.number,
  dispatch: PropTypes.func
};

export default connect((store) => ({
  terms: store.loginReducer.terms,
  active_term: store.loginReducer.active_term,
  isCommunication: store.routeReducer.isCommunication
}))(FlagShow);
