import { fetchApi } from '../../../api/fetchApi';

const data_recovery = require('../../../api/RestVariables/Admin/data_recovery');

export async function getDRProject(config, scb, ecb) {
  try {
    const url = data_recovery.GET_DR_PROJECT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_DR_PROJECT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function patchDRProject(config, scb, ecb) {
  try {
    const url = data_recovery.PATCH_DR_PROJECT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'PATCH_DR_PROJECT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getDRSchool(config, scb, ecb) {
  try {
    const url = data_recovery.GET_DR_SCHOOL;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_DR_SCHOOL');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function patchDRSchool(config, scb, ecb) {
  try {
    const url = data_recovery.PATCH_DR_SCHOOL;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'PATCH_DR_PROJECT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getDRParticipant(config, scb, ecb) {
  try {
    const url = data_recovery.GET_DR_PARTICIPANT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_DR_PARTICIPANT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function patchDRParticipant(config, scb, ecb) {
  try {
    const url = data_recovery.PATCH_DR_PARTICIPANT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'PATCH_DR_PARTICIPANT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}
