export default [
  {
    name: 'term_id',
    label: 'Term'
  },
  {
    name: 'country',
    label: 'Country'
  },
  {
    name: 'school_name',
    label: 'School'
  },
  {
    name: 'project_title',
    label: 'Project Title'
  },
  {
    name: 'project_student',
    label: 'Project Student'
  },
  {
    name: 'discipline_name',
    label: 'Discipline'
  },
  {
    name: 'fair_status',
    label: 'Fair Status'
  },
  {
    name: 'approve',
    label: 'Approve',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'deny',
    label: 'Deny',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  }
];
