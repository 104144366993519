import { Grid, MenuItem, Stack, TextField, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { Form, FormikProvider, useFormik } from 'formik';
import { Button } from '@mui/material';
import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchGender } from '../../Projects/utils/serviceCalls';
import { addParticipant, getParticipantProjectList } from '../serviceCalls';

const GUEST_TYPE = 3;

function SupervisorGuestMode(props) {
  const { participant_type_id, handleClose, handleRefresh, active_term_object } = props;
  const [genderList, setGenderList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [hotelRequired, setHotelRequired] = useState(false);

  useEffect(() => {
    fetchGender(
      {},
      (data) => {
        setGenderList(data);
      },
      () => {}
    );
    getParticipantProjectList(
      {},
      (data) => {
        setProjectList(data.data);
      },
      () => {}
    );
  }, []);
  const englishChar = /^[-a-zA-Z0-9& ]+$/;
  const StudentSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(2, 'Too Short!')
      .max(500, 'Too Long!')
      .matches(englishChar, 'Please enter only English characters')
      .required('First name is required'),
    last_name: Yup.string()
      .min(2, 'Too Short!')
      .max(500, 'Too Long!')
      .matches(englishChar, 'Please enter only English characters')
      .required('Last name is required'),
    gender_id: Yup.string().required('Gender is required'),
    project_id: Yup.string().required('Project is required'),
    email: Yup.string()
      .max(500, 'Too Long!')
      .email('Email must be a valid email address')
      .required('Email is required'),
    date_of_birth:
      participant_type_id !== GUEST_TYPE &&
      Yup.date()
        .max(
          moment(active_term_object.chaperone_dob_min_value).format('YYYY-MM-DD'),
          'Chaperone must be older than 18 at the time of registration'
        )
        .required('Date of Birth is required'),
    staying_at_hotel: Yup.number()
  });

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      gender_id: '',
      project_id: '',
      email: '',
      date_of_birth: '',
      staying_at_hotel: 0
    },
    validationSchema: StudentSchema,
    onSubmit: (values, { resetForm }) => {
      const obj = {
        ...values,
        participant_type_id,
        first_name: values.first_name.toUpperCase(),
        last_name: values.last_name.toUpperCase()
      };
      addParticipant(
        obj,
        () => {
          handleRefresh();
          resetForm();
        },
        () => {}
      );
    }
  });
  const { errors, touched, handleSubmit, getFieldProps, values, setFieldValue } = formik;

  useEffect(() => {
    if (participant_type_id === GUEST_TYPE) {
      const matchPoint = moment(active_term_object.student_dob_min_value).format('YYYY-MM-DD');
      const isYounger = moment(values.date_of_birth).isAfter(moment(matchPoint));
      if (isYounger) {
        setHotelRequired(true);
        setFieldValue('staying_at_hotel', 1);
      } else {
        setHotelRequired(false);
      }
    } else {
      setHotelRequired(false);
    }
  }, [values, participant_type_id]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid item xs={12}>
          <Stack spacing={3} sx={{ mt: 2, mb: 1 }}>
            <Stack spacing={3} sx={{ mt: 2, mb: 1 }} direction={{ xs: 'column', sm: 'row' }}>
              <TextField
                inputProps={{ style: { textTransform: 'uppercase' } }}
                fullWidth
                label="First Name"
                {...getFieldProps('first_name')}
                error={Boolean(touched.first_name && errors.first_name)}
                helperText={touched.first_name && errors.first_name}
              />
              <TextField
                inputProps={{ style: { textTransform: 'uppercase' } }}
                fullWidth
                label="Last Name"
                {...getFieldProps('last_name')}
                error={Boolean(touched.last_name && errors.last_name)}
                helperText={touched.last_name && errors.last_name}
              />
              <TextField
                fullWidth
                label="Project"
                select
                {...getFieldProps('project_id')}
                error={Boolean(touched.project_id && errors.project_id)}
                helperText={touched.project_id && errors.project_id}
              >
                {projectList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
            <Stack spacing={3} sx={{ mt: 2, mb: 1 }} direction={{ xs: 'column', sm: 'row' }}>
              <TextField
                fullWidth
                label="Gender"
                select
                {...getFieldProps('gender_id')}
                error={Boolean(touched.gender_id && errors.gender_id)}
                helperText={touched.gender_id && errors.gender_id}
              >
                {genderList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                type="date"
                label="Date of Birth"
                {...getFieldProps('date_of_birth')}
                error={Boolean(touched.date_of_birth && errors.date_of_birth)}
                helperText={touched.date_of_birth && errors.date_of_birth}
              />
              <TextField
                fullWidth
                label="Email"
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
              {participant_type_id === GUEST_TYPE && (
                <TextField
                  disabled={hotelRequired}
                  fullWidth
                  label="Staying at Hotel"
                  select
                  {...getFieldProps('staying_at_hotel')}
                  error={Boolean(touched.staying_at_hotel && errors.staying_at_hotel)}
                  helperText={touched.staying_at_hotel && errors.staying_at_hotel}
                >
                  <MenuItem key={1} value={1}>
                    Yes
                  </MenuItem>
                  <MenuItem key={0} value={0}>
                    No
                  </MenuItem>
                </TextField>
              )}
            </Stack>
            {hotelRequired && (
              <Typography variant="subtitle2" sx={{ textAlign: 'center' }}>
                Participants younger than 13 years old are not allowed to stay in the Genius dorms.
              </Typography>
            )}
            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="end" spacing={2}>
              <Button type="submit" variant="outlined">
                Add
              </Button>
              <Button variant="outlined" color="error" onClick={handleClose}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

SupervisorGuestMode.propTypes = {
  participant_type_id: PropTypes.number,
  handleClose: PropTypes.func,
  handleRefresh: PropTypes.func,
  active_term_object: PropTypes.object
};

export default connect((store) => ({
  active_term_object: store.loginReducer.active_term_object
}))(SupervisorGuestMode);
