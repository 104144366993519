import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Stack } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MuiTable from '../../../../components/CustomComponents/MuiTable';
import headers from './_utils/headerVariablesForStudent';
import { getAttendedStudentForFlagShow, updateFlagShow } from '../serviceCalls';

function ActionDialog(props) {
  const { country, handleClose, handleRefresh } = props;
  const [selectedStudent, setSelectedStudent] = useState([]);
  const [studentList, setStudentList] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);
  function fetchData() {
    getAttendedStudentForFlagShow(
      { country_id: country.country_id, term_id: country.term_id },
      (data) => {
        setStudentList(data);
        if (country.student_id) {
          const selectedObjectIndex = data.findIndex((x) => x.id === country.student_id);
          setSelectedStudent([selectedObjectIndex]);
        }
      },
      () => {}
    );
  }
  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedStudent(rowsSelected);
  };
  function handleAdd() {
    const param = {
      flag_show_id: country.flag_show_id,
      student_id: studentList[selectedStudent[0]].id
    };
    updateFlagShow(
      param,
      () => {
        handleRefresh();
      },
      () => {}
    );
  }

  return (
    <Stack spacing={3}>
      <MuiTable
        tableData={studentList}
        tableColumns={headers}
        customOption={{
          selectableRows: 'single',
          onRowSelectionChange,
          rowsSelected: selectedStudent
        }}
      />
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="end">
        <Button variant="outlined" onClick={handleAdd} disabled={selectedStudent.length === 0}>
          Add
        </Button>
        <Button variant="outlined" color="error" onClick={handleClose}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
}

ActionDialog.propTypes = {
  country: PropTypes.object,
  handleClose: PropTypes.func,
  handleRefresh: PropTypes.func
};

export default connect(() => ({}))(ActionDialog);
