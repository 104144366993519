import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LandingPageCard from '../../../components/CustomComponents/CustomCard/LandingPageCard';

const CARDS = [
  {
    id: 1,
    name: 'Project Payments',
    description: 'From this module you can arrange payments for projects.'
  },
  {
    id: 2,
    name: 'Participant Payments',
    description: 'From this module you can arrange payments for participants.'
  },
  {
    id: 3,
    name: 'Trip Payments',
    description: 'From this module you can arrange payments for trips.'
  }
];

const PROJECT_VIEW = 1;
const PARTICIPANT_VIEW = 2;

function Index() {
  const navigate = useNavigate();

  function generate(data) {
    if (data.id === PROJECT_VIEW) {
      navigate('/admin/payment/projects', { replace: true });
    } else if (data.id === PARTICIPANT_VIEW) {
      navigate('/admin/payment/participants', { replace: true });
    } else navigate('/admin/payment/trip', { replace: true });
  }

  return (
    <Grid container spacing={2}>
      {CARDS.map((item, index) => (
        <Grid item xs={4} key={index}>
          <LandingPageCard data={item} generate={generate} />
        </Grid>
      ))}
    </Grid>
  );
}

export default Index;
