import { Button, Stack } from '@material-ui/core';
import * as React from 'react';
import { Card, DialogContent, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import FileCard from './FileCard';
import { setConfirmationAlert, setSnackbar } from '../../../../../actions/alertActions';
import {
  addPerformanceFile,
  downloadPerformanceFile,
  deletePerformanceFile,
  getPerformanceOperation
} from '../../serviceCalls';
import CustomDialog, {
  DialogActions
} from '../../../../../components/CustomComponents/CustomDialog';

const crypto = require('crypto');

function FileDialog(props) {
  const { dispatch, handleRefresh, handleClose, selectedPerformance } = props;
  const [list, setList] = useState([]);
  const [time, setTime] = useState(Date.now);
  const [loading, setLoading] = useState(null);
  const [downloading, setDownloading] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    getPerformanceOperation(
      { performance_id: selectedPerformance },
      (data) => {
        const fileNames = data.data[0].performance_file?.split(',');
        const fileIDS = data.data[0].performance_file_id?.split(',');
        const newList = fileNames?.map((item, index) => ({
          data: { name: item, id: fileIDS[index] },
          isSent: true
        }));
        setList(newList || []);
      },
      () => {}
    );
  }

  function changeHandler(event) {
    const arr = list;
    const obj = {
      data: event.target.files[0],
      isSent: false
    };
    arr.push(obj);
    setList(arr);
    setTime(Date.now);
  }
  const handleSubmission = (index) => {
    setLoading(index);
    const file = list[index].data;
    const formData = new FormData();
    formData.append('File', file);
    const hash = crypto.createHash('md5').update(file.name).digest('hex');
    const metaDataParam = {
      performance_id: selectedPerformance,
      file_name: file.name,
      timestamp: file.lastModifiedDate,
      hash,
      file
    };

    addPerformanceFile(
      metaDataParam,
      () => {
        dispatch(setSnackbar('success', 'The file has been uploaded'));
        const newObj = {
          data: list[index].data,
          isSent: true
        };
        const arr = list;
        arr[index] = newObj;
        setList(arr);
        setTime(Date.now);
        setLoading(null);
        handleRefresh();
      },
      () => {
        setLoading(null);
      }
    );
  };
  function deleteFile(index) {
    const arr = list;
    arr.splice(index, 1);
    setList(arr);
    setTime(Date.now);
  }
  function handleDeleteFileFromDB(file_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'This action will delete the file.',
        () => {
          deletePerformanceFile(
            { file_id },
            () => {
              handleRefresh();
              dispatch(setSnackbar('success', 'The file has been deleted'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }
  const downloadFile = (index) => {
    setDownloading(index);
    const file = list[index].data;
    const param = {
      id: file.id,
      file_name: file.name.split('.')[0]
    };
    downloadPerformanceFile(
      param,
      () => {
        setDownloading(null);
      },
      () => {
        setDownloading(null);
      }
    );
  };

  return (
    <CustomDialog onClose={handleClose} open maxWidth="lg" title="Update Performance">
      <DialogContent>
        <Card sx={{ padding: 2, mt: 2 }}>
          <Stack spacing={3} sx={{ mb: 1 }}>
            <div>
              <p style={{ display: 'none' }}>{time}</p>
              <input type="file" name="file" onChange={changeHandler} accept=".mp3" />
              <Grid container spacing={2} sx={{ mt: 1 }}>
                {list.length > 0 &&
                  list.map((item, index) => (
                    <Grid item xs={4} key={index}>
                      <FileCard
                        performance
                        downloading={downloading}
                        loading={loading}
                        index={index}
                        editMode
                        data={item}
                        deleteFileFromDB={handleDeleteFileFromDB}
                        deleteFile={() => deleteFile(index)}
                        submitFile={() => handleSubmission(index)}
                        downloadFile={() => downloadFile(index)}
                      />
                    </Grid>
                  ))}
              </Grid>
            </div>
          </Stack>
        </Card>
      </DialogContent>
      <DialogActions>
        <Stack
          style={{ marginTop: 10 }}
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="end"
          spacing={2}
        >
          <Button variant="outlined" color="error" onClick={handleClose}>
            Close
          </Button>
        </Stack>
      </DialogActions>
    </CustomDialog>
  );
}

FileDialog.propTypes = {
  dispatch: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func,
  selectedPerformance: PropTypes.number,
  handleClose: PropTypes.func
};

export default connect(() => ({}))(FileDialog);
