import React from 'react';
import { Alert, MenuItem, Stack, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Grid } from '@mui/material';
import RoboticsFormCard from './RoboticsFormCard';

function Index(props) {
  const { terms, addForm, term, setTerm, formData, handleDeleteForm, handleEditForm } = props;

  function TermSelect() {
    return (
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 2 }}>
        <TextField select label="Terms" value={term} onChange={(e) => setTerm(e.target.value)}>
          {terms.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
        {Object.keys(formData).length === 0 && (
          <div className="header-button-style">
            <Button variant="outlined" onClick={addForm}>
              Add New
            </Button>
          </div>
        )}
      </Stack>
    );
  }

  return (
    <Stack spacing={2}>
      {TermSelect()}
      {Object.keys(formData).length > 0 ? (
        <Grid item xs={12} md={4}>
          <RoboticsFormCard
            data={formData}
            handleDeleteForm={handleDeleteForm}
            handleEditForm={handleEditForm}
          />
        </Grid>
      ) : (
        <Alert severity="info">There is no robotics form.</Alert>
      )}
    </Stack>
  );
}

Index.propTypes = {
  terms: PropTypes.array,
  addForm: PropTypes.func,
  term: PropTypes.number,
  setTerm: PropTypes.func,
  formData: PropTypes.object,
  handleDeleteForm: PropTypes.func,
  handleEditForm: PropTypes.func
};

export default connect((store) => ({
  terms: store.loginReducer.terms,
  active_term: store.loginReducer.active_term
}))(Index);
