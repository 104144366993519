const HOST = process.env.REACT_APP_REST_API_HOST;

const ADD_JUDGING_SESSION = {
  method: 'POST',
  path: `${HOST}go_core/judging_session/session`
};
const GET_JUDGING_SESSION = {
  method: 'GET',
  path: `${HOST}go_core/judging_session/session`
};
const GET_JUDGING_SESSION_PROJECT = {
  method: 'GET',
  path: `${HOST}go_core/judging_session/session/project`
};

module.exports = {
  ADD_JUDGING_SESSION,
  GET_JUDGING_SESSION,
  GET_JUDGING_SESSION_PROJECT
};
