const HOST = process.env.REACT_APP_REST_API_HOST;

const GET_FAIR_OPERATION = {
  method: 'GET',
  path: `${HOST}go_admin/fair_operation/fair`
};
const UPDATE_FAIR_OPERATION = {
  method: 'PUT',
  path: `${HOST}go_admin/fair_operation/fair`
};
const DELETE_FAIR_OPERATION = {
  method: 'DELETE',
  path: `${HOST}go_admin/fair_operation/fair`
};
const MIGRATE_FAIR = {
  method: 'POST',
  path: `${HOST}go_admin/fair_operation/fair/migrate`
};
const GET_FAIR_PROFILE = {
  method: 'GET',
  path: `${HOST}go_admin/fair_operation/profile`
};
const UPDATE_FAIR_PROFILE = {
  method: 'PUT',
  path: `${HOST}go_admin/fair_operation/profile`
};
const GET_FAIR_REQUEST = {
  method: 'GET',
  path: `${HOST}go_admin/fair_operation/request`
};
const UPDATE_FAIR_REQUEST = {
  method: 'PATCH',
  path: `${HOST}go_admin/fair_operation/request`
};

module.exports = {
  GET_FAIR_OPERATION,
  UPDATE_FAIR_OPERATION,
  GET_FAIR_PROFILE,
  UPDATE_FAIR_PROFILE,
  DELETE_FAIR_OPERATION,
  MIGRATE_FAIR,
  GET_FAIR_REQUEST,
  UPDATE_FAIR_REQUEST
};
