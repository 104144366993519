import { MenuItem, Stack, TextField } from '@material-ui/core';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@material-ui/lab';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { addTemplate, fetchPlaceholder, updateTemplate } from '../serviceCalls';
import { setSnackbar } from '../../../../../actions/alertActions';
import BodyEditor from './BodyEditor';

const ADD = 1;
const UPDATE = 2;

function AddUpdateDialog(props) {
  const { data, mode, handleClose, dispatch, refreshData, typeList } = props;
  const [placeholders, setPlaceholders] = useState([]);
  const [templateBody, setTemplateBody] = useState(mode === UPDATE ? data?.body : '');

  const Schema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Too Short!')
      .max(500, 'Too Long!')
      .matches('^[\x20-\x7F]+$', 'Please enter only English characters!')
      .required('Template name required'),
    template_type_id: Yup.string().required('Type required'),
    subject: Yup.string().max(500, 'Too Long!').required('Subject required')
  });
  const formik = useFormik({
    initialValues: {
      name: mode === UPDATE ? data?.name : '',
      template_type_id: mode === UPDATE ? data?.template_type_id : '',
      subject: mode === UPDATE ? data?.subject : ''
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      if (mode === ADD) {
        addTemplate(
          { ...values, body: templateBody },
          () => {
            refreshData();
            dispatch(setSnackbar('success', 'The template has been added'));
          },
          () => {}
        );
      } else {
        updateTemplate(
          { ...values, body: templateBody, id: data?.id },
          () => {
            refreshData();
            dispatch(setSnackbar('success', 'The template has been updated'));
          },
          () => {}
        );
      }
    }
  });
  const { errors, touched, handleSubmit, getFieldProps, values } = formik;

  useEffect(() => {
    if (values.template_type_id) {
      fetchPlaceholder(
        { type_id: values.template_type_id },
        (data) => {
          setPlaceholders(data);
        },
        () => {}
      );
    }
  }, [values.template_type_id]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 1 }}>
            <TextField
              fullWidth
              label="Name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
            <TextField
              fullWidth
              disabled={values.template_type_id !== ''}
              label="Type"
              select
              {...getFieldProps('template_type_id')}
              error={Boolean(touched.template_type_id && errors.template_type_id)}
              helperText={touched.template_type_id && errors.template_type_id}
            >
              {typeList.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
          <TextField
            fullWidth
            label="Subject"
            {...getFieldProps('subject')}
            error={Boolean(touched.subject && errors.subject)}
            helperText={touched.subject && errors.subject}
          />
          <BodyEditor
            placeholders={placeholders}
            value={templateBody}
            setTemplateBody={setTemplateBody}
          />
          <Stack direction="row" justifyContent="end" spacing={1}>
            <LoadingButton size="small" type="submit" variant="outlined">
              {mode === ADD ? 'Add' : 'Update'}
            </LoadingButton>
            <Button size="small" color="error" onClick={handleClose} variant="outlined">
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

AddUpdateDialog.propTypes = {
  data: PropTypes.object,
  mode: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  typeList: PropTypes.array.isRequired,
  placeholders: PropTypes.array.isRequired
};

export default connect(() => ({}))(AddUpdateDialog);
