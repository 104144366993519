export default [
  {
    name: 'judging_group_id',
    label: 'ID'
  },
  {
    name: 'group_number',
    label: 'Group Number'
  },
  {
    name: 'judging_group_capacity',
    label: 'Group Capacity'
  },
  {
    name: 'number_of_projects',
    label: 'Number of Projects'
  },
  {
    name: 'discipline',
    label: 'Discipline'
  },
  {
    name: 'judging_session_type',
    label: 'Session Type'
  },
  {
    name: 'judging_session',
    label: 'Session Name'
  },
  {
    name: 'location',
    label: 'Location'
  },
  {
    name: 'start',
    label: 'Start Time'
  },
  {
    name: 'end',
    label: 'End Time'
  }
];
