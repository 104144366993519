import { fetchApi } from '../../../api/fetchApi';

const user = require('../../../api/RestVariables/Shield/user');
const auth = require('../../../api/RestVariables/Shield/auth');

export async function getProfile(config, scb, ecb) {
  try {
    const url = user.GET_PROFILE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_PROFILE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateProfile(config, scb, ecb) {
  try {
    const url = user.UPDATE_PROFILE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_PROFILE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addProfile(config, scb, ecb) {
  try {
    const url = user.ADD_PROFILE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_PROFILE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function uploadProfilePicture(config, scb, ecb) {
  try {
    const url = user.UPLOAD_PROFILE_PICTURE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPLOAD_PROFILE_PICTURE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updatePassword(config, scb, ecb) {
  try {
    const url = auth.UPDATE_PASSWORD;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_PASSWORD');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getUserRole(config, scb, ecb) {
  try {
    const url = user.GET_USER_ROLE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_USER_ROLE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function changeUserRole(config, scb, ecb) {
  try {
    const url = user.CHANGE_USER_ROLE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'CHANGE_USER_ROLE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}
