import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Assignment } from '@material-ui/icons';
import { Stack } from '@material-ui/core';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

const style = {
  root: {
    width: 'auto',
    maxHeight: 215,
    marginTop: 30,
    backgroundColor: '#ffffff'
  },
  pos: {
    marginBottom: 12,
    marginTop: 10
  },
  categoryTyp: {
    color: '#7d8887',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  cardActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
};

function LandingPageCard(props) {
  const { data, generate } = props;
  return (
    <Card style={style.root} variant="outlined">
      <CardContent
        style={{
          paddingTop: 0,
          minHeight: 150,
          paddingBottom: 0
        }}
      >
        <Stack spacing={2}>
          <div
            style={{
              height: 5,
              background: 'linear-gradient(90deg, #00ab55, #d2ffe8)',
              marginLeft: -20,
              marginRight: -20
            }}
          />
          <Stack direction="row" spacing={2}>
            <Assignment style={{ color: '#00ab55' }} />
            <Typography variant="h6" component="h2" style={{ color: '#00ab55', fontSize: '1rem' }}>
              {data.name}
            </Typography>
          </Stack>
        </Stack>
        <Typography style={style.pos} color="textSecondary">
          {data.description}
        </Typography>
      </CardContent>
      <CardActions style={style.cardActions}>
        <Button variant="outlined" onClick={() => generate(data)}>
          Details
        </Button>
      </CardActions>
    </Card>
  );
}

LandingPageCard.propTypes = {
  data: PropTypes.object.isRequired,
  generate: PropTypes.func.isRequired
};

export default LandingPageCard;
