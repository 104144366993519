import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import TableContainer from '@mui/material/TableContainer';
import { Paper, TableHead } from '@material-ui/core';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import { getScoreboardDetail } from '../_apis/serviceCalls';

function ExpandView({ project }) {
  const [projectDetail, setProjectDetail] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    getScoreboardDetail({ team_id: project.team_id, contest_id: project.contest_id }, (data) => {
      data.result.sort((a, b) => a.id - b.id);
      setProjectDetail(data.result);
    });
  }

  return projectDetail.length > 0 ? (
    <TableContainer component={Paper} style={{ paddingLeft: 30, paddingRight: 30 }}>
      <Table sx={{ minWidth: 400 }} aria-label="simple table">
        <TableHead>
          <TableRow style={{ background: 'gainsboro' }}>
            <TableCell align="center" style={{ fontWeight: 'bold' }}>
              ID
            </TableCell>
            <TableCell align="center" style={{ fontWeight: 'bold' }}>
              Problem
            </TableCell>
            <TableCell align="center" style={{ fontWeight: 'bold' }}>
              Solved
            </TableCell>
            <TableCell align="center" style={{ fontWeight: 'bold' }}>
              Time
            </TableCell>
            <TableCell align="center" style={{ fontWeight: 'bold' }}>
              First to Solve
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projectDetail.map((item, index) => (
            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="center">{item.id}</TableCell>
              <TableCell align="center">{item.problem_name}</TableCell>
              <TableCell align="center">{item.solved ? 'Yes' : 'No'}</TableCell>
              <TableCell align="center"> {item.time}</TableCell>
              <TableCell align="center">{item.first_to_solve ? 'Yes' : 'No'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <div />
  );
}

ExpandView.propTypes = {
  project: PropTypes.object
};

export default connect(() => ({}))(ExpandView);
