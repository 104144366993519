const HOST = process.env.REACT_APP_REST_API_HOST;

const GET_PARTICIPANT = {
  method: 'GET',
  path: `${HOST}go_core/participation/participant`
};
const ADD_PARTICIPANT = {
  method: 'POST',
  path: `${HOST}go_core/participation/participant`
};
const UPDATE_PARTICIPANT = {
  method: 'PUT',
  path: `${HOST}go_core/participation/participant`
};
const DELETE_PARTICIPANT = {
  method: 'DELETE',
  path: `${HOST}go_core/participation/participant`
};
const GET_PARTICIPANT_CHECK_LIST = {
  method: 'GET',
  path: `${HOST}go_core/participation/participant/check_list`
};
const GET_PARTICIPANT_LIST = {
  method: 'GET',
  path: `${HOST}go_core/participation/participant/list`
};
const GET_PARTICIPANT_PROJECT_LIST = {
  method: 'GET',
  path: `${HOST}go_core/participation/participant/finalist_project`
};
const GET_PARTICIPANT_STUDENT = {
  method: 'GET',
  path: `${HOST}go_core/participation/participant/student`
};
const GET_PARTICIPANT_TYPE = {
  method: 'GET',
  path: `${HOST}go_core/participation/participant/type`
};
const CONFIRM_PARTICIPANT = {
  method: 'PUT',
  path: `${HOST}go_core/participation/participant/confirm`
};
const DOWNLOAD_CERTIFICATE = {
  method: 'GET',
  path: `${HOST}go_core/participation/participant/certificate`
};
const PAYMENT = {
  method: 'POST',
  path: `${HOST}go_core/participation/payment`
};
const INVITATION_LETTER_LIST = {
  method: 'GET',
  path: `${HOST}go_core/participation/invitation_letter/list`
};
const ADD_INVITATION_LETTER = {
  method: 'POST',
  path: `${HOST}go_core/participation/invitation_letter`
};
const DOWNLOAD_INVITATION_LETTER = {
  method: 'GET',
  path: `${HOST}go_core/participation/invitation_letter/download`
};
const PARTICIPANT_INVITATION_LETTER = {
  method: 'GET',
  path: `${HOST}go_core/participation/invitation_letter/participant`
};
const ADD_VIP = {
  method: 'POST',
  path: `${HOST}go_core/participation/vip`
};
const UPDATE_VIP = {
  method: 'PUT',
  path: `${HOST}go_core/participation/vip`
};
const GET_VIP = {
  method: 'GET',
  path: `${HOST}go_core/participation/vip`
};
const DELETE_VIP = {
  method: 'DELETE',
  path: `${HOST}go_core/participation/vip`
};
const SIGN_FORM = {
  method: 'POST',
  path: `${HOST}go_core/participation/participant/consent_form`
};

module.exports = {
  GET_PARTICIPANT,
  ADD_PARTICIPANT,
  UPDATE_PARTICIPANT,
  DELETE_PARTICIPANT,
  GET_PARTICIPANT_LIST,
  GET_PARTICIPANT_STUDENT,
  GET_PARTICIPANT_TYPE,
  CONFIRM_PARTICIPANT,
  PAYMENT,
  INVITATION_LETTER_LIST,
  ADD_INVITATION_LETTER,
  DOWNLOAD_INVITATION_LETTER,
  PARTICIPANT_INVITATION_LETTER,
  ADD_VIP,
  UPDATE_VIP,
  GET_VIP,
  DELETE_VIP,
  GET_PARTICIPANT_CHECK_LIST,
  DOWNLOAD_CERTIFICATE,
  SIGN_FORM,
  GET_PARTICIPANT_PROJECT_LIST
};
