import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { MenuItem, Stack, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import {
  fetchCategoriesForRegister,
  getCountryList,
  getStateList
} from '../../../actions/registerActions';
import { updateFairProfile } from '../../Admin/FairOperation/serviceCalls';
import { updateFairAdminProfile } from '../FairAdmin/serviceCalls';
import { setSnackbar } from '../../../actions/alertActions';
// ----------------------------------------------------------------------

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
const US_CODE = 244;
const phoneRegExp = /^\+?(\d{10,13})$/gm;
const englishChar = /^[-a-zA-Z0-9& ]+$/;

function FairProfileForm(props) {
  const { data, handleRefresh, mode, dispatch } = props;
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [disciplineList, setDisciplineList] = useState([]);
  const [selectedDisciplines, setSelectedDisciplines] = useState([]);
  const [schema, setSchema] = useState(
    Yup.object().shape({
      name: Yup.string()
        .min(2, 'Too Short!')
        .max(500, 'Too Long!')
        .matches(englishChar, 'Please enter only English characters!')
        .required('First name required'),
      email: Yup.string()
        .max(500, 'Too Long!')
        .email('Email must be a valid email address')
        .required('Fair Email is required'),
      main_contact_first_name: Yup.string()
        .min(2, 'Too Short!')
        .max(500, 'Too Long!')
        .matches(englishChar, 'Please enter only English characters!')
        .required('First name required'),
      main_contact_last_name: Yup.string()
        .min(2, 'Too Short!')
        .max(500, 'Too Long!')
        .matches(englishChar, 'Please enter only English characters!')
        .required('Last name required'),
      phone_number: Yup.string()
        .max(500, 'Too Long!')
        .matches(phoneRegExp, 'Phone number must be in the following format,+15554443322')
        .required('Phone number is required'),
      size: Yup.number().required('Size required'),
      zip: Yup.string().max(500, 'Too Long!').required('Zip is required'),
      country_id: Yup.string().required('Country is required'),
      city: Yup.string().max(500, 'Too Long!').required('City is required'),
      street: Yup.string().max(500, 'Too Long!').required('Street is required'),
      state_id: Yup.string().nullable(),
      start_year: Yup.number()
        .min(1900, 'Please enter at least 1900 year.')
        .max(2099, 'Please enter the year 2099 at most.')
        .required('Start year is required')
    })
  );

  useEffect(() => {
    getCountryList(
      {},
      (data) => {
        setCountryList(data);
      },
      () => {}
    );
    getStateList(
      {},
      (data) => {
        setStateList(data);
      },
      () => {}
    );
    fetchCategoriesForRegister(
      {},
      (data) => {
        setDisciplineList(data);
      },
      () => {}
    );
    const arr = [];
    const disIDs = data ? data?.fair_discipline_id.split(',') : [];
    disIDs.forEach((item) => {
      arr.push(parseInt(item, 10));
    });
    setSelectedDisciplines(arr);
  }, []);

  const formik = useFormik({
    initialValues: {
      name: data?.name,
      email: data?.email,
      main_contact_first_name: data?.main_contact_first_name,
      main_contact_last_name: data?.main_contact_last_name,
      zip: data?.zip,
      unit_number: data?.unit_number,
      country_id: data?.country_id,
      state_id: data?.state_id,
      city: data?.city,
      street: data?.street,
      start_year: data?.start_year,
      phone_number: data?.phone_number,
      size: data?.size,
      website: data?.website
    },
    validationSchema: schema,
    onSubmit: (values) => {
      const isWebSend = !!values.website;
      let obj = {};
      if (mode === 1) {
        obj = {
          ...values,
          fair_id: data.id
        };
      } else {
        obj = {
          ...values,
          disciplines: selectedDisciplines,
          fair_id: data.id
        };
      }
      const { website, ...param2 } = obj;
      console.log(website);
      if (mode === 1) {
        updateFairAdminProfile(
          isWebSend ? obj : param2,
          () => {
            dispatch(setSnackbar('success', 'Profile updated.'));
          },
          () => {}
        );
      } else {
        updateFairProfile(
          isWebSend ? obj : param2,
          () => {
            dispatch(setSnackbar('success', 'Profile updated.'));
            handleRefresh();
          },
          () => {}
        );
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue, values } =
    formik;

  useEffect(() => {
    if (values.country_id !== US_CODE) {
      setFieldValue('state_id', null);
    }
    setSchema(
      Yup.object().shape({
        name: Yup.string()
          .min(2, 'Too Short!')
          .max(500, 'Too Long!')
          .matches(englishChar, 'Please enter only English characters!')
          .required('First name required'),
        email: Yup.string()
          .max(500, 'Too Long!')
          .email('Email must be a valid email address')
          .required('Fair Email is required'),
        main_contact_first_name: Yup.string()
          .min(2, 'Too Short!')
          .max(500, 'Too Long!')
          .matches(englishChar, 'Please enter only English characters!')
          .required('First name required'),
        main_contact_last_name: Yup.string()
          .min(2, 'Too Short!')
          .max(500, 'Too Long!')
          .matches(englishChar, 'Please enter only English characters!')
          .required('Last name required'),
        phone_number: Yup.string()
          .max(500, 'Too Long!')
          .matches(phoneRegExp, 'Phone number must be in the following format,+15554443322')
          .required('Phone number is required'),
        size: Yup.number().required('Size required'),
        zip: Yup.string().max(500, 'Too Long!').required('Zip is required'),
        country_id: Yup.string().required('Country is required'),
        city: Yup.string().max(500, 'Too Long!').required('City is required'),
        street: Yup.string().max(500, 'Too Long!').required('Street is required'),
        start_year: Yup.number()
          .min(1900, 'Please enter at least 1900 year.')
          .max(2099, 'Please enter the year 2099 at most.')
          .required('Start year is required'),
        state_id:
          values.country_id === US_CODE
            ? Yup.string().required('State is required').nullable()
            : Yup.string().nullable()
      })
    );
  }, [values.country_id]);

  const handleChange = (event) => {
    const {
      target: { value }
    } = event;
    setSelectedDisciplines(typeof value === 'string' ? value.split(',') : value);
  };
  console.log(selectedDisciplines, 'selectedDisciplines');

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 1 }}>
            <TextField
              fullWidth
              label="Fair Name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Fair Email"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              type="number"
              label="Phone Number"
              {...getFieldProps('phone_number')}
              error={Boolean(touched.phone_number && errors.phone_number)}
              helperText={touched.phone_number && errors.phone_number}
            />
            <TextField
              fullWidth
              label="Web Site"
              {...getFieldProps('website')}
              error={Boolean(touched.website && errors.website)}
              helperText={touched.website && errors.website}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              type="number"
              label="Start Year"
              {...getFieldProps('start_year')}
              error={Boolean(touched.start_year && errors.start_year)}
              helperText={touched.start_year && errors.start_year}
            />
            <TextField
              fullWidth
              type="number"
              label="Number of Projects at Your Fair"
              {...getFieldProps('size')}
              error={Boolean(touched.size && errors.size)}
              helperText={touched.size && errors.size}
            />
          </Stack>
          {mode !== 1 && (
            <FormControl>
              <InputLabel id="demo-multiple-checkbox-label">Discipline</InputLabel>
              <Select
                fullWidth
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedDisciplines}
                onChange={handleChange}
                input={<OutlinedInput label="Discipline" />}
                renderValue={(selected) => {
                  const strList = [];
                  selected.forEach((item) => {
                    const el = disciplineList.find((x) => x.id === item);
                    // eslint-disable-next-line no-unused-expressions
                    el && strList.push(el.name);
                  });
                  return strList.join(', ');
                }}
                MenuProps={MenuProps}
              >
                {disciplineList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    <Checkbox checked={selectedDisciplines.indexOf(item.id) > -1} />
                    <ListItemText primary={item.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Main Contact First Name"
              {...getFieldProps('main_contact_first_name')}
              error={Boolean(touched.main_contact_first_name && errors.main_contact_first_name)}
              helperText={touched.main_contact_first_name && errors.main_contact_first_name}
            />
            <TextField
              fullWidth
              label="Main Contact Last Name"
              {...getFieldProps('main_contact_last_name')}
              error={Boolean(touched.main_contact_last_name && errors.main_contact_last_name)}
              helperText={touched.main_contact_last_name && errors.main_contact_last_name}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Country"
              select
              {...getFieldProps('country_id')}
              error={Boolean(touched.country_id && errors.country_id)}
              helperText={touched.country_id && errors.country_id}
            >
              {countryList.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
            {values.country_id === US_CODE && (
              <TextField
                fullWidth
                label="State"
                select
                {...getFieldProps('state_id')}
                error={Boolean(touched.state_id && errors.state_id)}
                helperText={touched.state_id && errors.state_id}
              >
                {stateList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Street"
              {...getFieldProps('street')}
              error={Boolean(touched.street && errors.street)}
              helperText={touched.street && errors.street}
            />
            <TextField
              fullWidth
              label="Unit Number"
              {...getFieldProps('unit_number')}
              error={Boolean(touched.unit_number && errors.unit_number)}
              helperText={touched.unit_number && errors.unit_number}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="City"
              {...getFieldProps('city')}
              error={Boolean(touched.city && errors.city)}
              helperText={touched.city && errors.city}
            />
            <TextField
              fullWidth
              label="Zip Code"
              {...getFieldProps('zip')}
              error={Boolean(touched.zip && errors.zip)}
              helperText={touched.zip && errors.zip}
            />
          </Stack>
          <Button loading={isSubmitting} fullWidth size="large" type="submit" variant="contained">
            Update
          </Button>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

FairProfileForm.propTypes = {
  handleRefresh: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  mode: PropTypes.number,
  dispatch: PropTypes.func
};

export default connect(() => ({}))(FairProfileForm);
