import { Grid, Stack } from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, DialogContent } from '@mui/material';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import CustomPerformanceCard from './CustomPerformanceCard';
import { setConfirmationAlert, setSnackbar } from '../../../actions/alertActions';
import { deletePerformance } from './utils/serviceCalls';
import CustomDialog from '../../../components/CustomComponents/CustomDialog';
import FileDialog from './FileDialog';
import ProcessWarningBox from '../../../components/CustomComponents/CustomCard/ProcessWarningBox';
import ProcessInfoBox from '../../../components/CustomComponents/CustomCard/ProcessInfoBox';

function LandingPage(props) {
  const {
    handleAddPerformance,
    performanceList,
    dispatch,
    refreshData,
    handleEditPerformance,
    isFinalist,
    guidelines
  } = props;
  const [dialogShow, setDialogShow] = useState(false);

  function handleDeletePerformance(performance_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'This action will delete the performance record permanently. Do you want to proceed?',
        () => {
          deletePerformance(
            { performance_id },
            () => {
              refreshData();
              dispatch(setSnackbar('success', 'The performance has been deleted'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }
  function handleAddFile(item) {
    dispatch({ type: 'SET_ACTIVE_PERFORMANCE', payload: item.id });
    setDialogShow(true);
  }

  function closeDialog() {
    setDialogShow(false);
    refreshData();
  }

  return isFinalist ? (
    <Stack spacing={2}>
      {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
      <Grid container spacing={3}>
        {performanceList.map((item, index) => (
          <Grid item xs={12} md={6} lg={4} xl={3} key={index}>
            <CustomPerformanceCard
              data={item}
              handleDeletePerformance={handleDeletePerformance}
              handleEditPerformance={handleEditPerformance}
              handleAddFile={handleAddFile}
            />
          </Grid>
        ))}
      </Grid>
      <Grid xs={12} sm={6} md={4}>
        <Button variant="outlined" onClick={handleAddPerformance}>
          Add new Performance
        </Button>
      </Grid>
      <CustomDialog onClose={closeDialog} open={dialogShow} maxWidth="lg" fullWidth title="File">
        <DialogContent>
          <FileDialog dialog handleClose={closeDialog} />
        </DialogContent>
      </CustomDialog>
    </Stack>
  ) : (
    <ProcessWarningBox
      Comp={
        <p>
          The “Performance” registration will be available only for finalist projects, after
          finalists are announced. Please visit this page to register your “Performance” after
          finalists are announced but before the participant registration deadline.
        </p>
      }
    />
  );
}

LandingPage.propTypes = {
  handleAddPerformance: PropTypes.func,
  handleEditPerformance: PropTypes.func,
  dispatch: PropTypes.func,
  performanceList: PropTypes.array,
  refreshData: PropTypes.func,
  isFinalist: PropTypes.bool,
  guidelines: PropTypes.array
};

export default connect((store) => ({
  isFinalist: store.dashboardReducer.isFinalist
}))(LandingPage);
