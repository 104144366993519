const HOST = process.env.REACT_APP_REST_API_HOST;

const ADD_TERM = {
  method: 'POST',
  path: `${HOST}go_admin/settings/term`
};
const UPDATE_TERM = {
  method: 'PUT',
  path: `${HOST}go_admin/settings/term`
};
const PATCH_TERM = {
  method: 'PATCH',
  path: `${HOST}go_admin/settings/term`
};
const DELETE_TERM = {
  method: 'DELETE',
  path: `${HOST}go_admin/settings/term`
};
const GET_TERM = {
  method: 'GET',
  path: `${HOST}go_admin/settings/term`
};
const ONLINE_TERM = {
  method: 'PATCH',
  path: `${HOST}go_admin/settings/term/online`
};
const TERM_COPY_CONFIG = {
  method: 'POST',
  path: `${HOST}go_admin/settings/term/copy_config`
};

const ADD_DISCIPLINE = {
  method: 'POST',
  path: `${HOST}go_admin/settings/discipline`
};
const UPDATE_DISCIPLINE = {
  method: 'PUT',
  path: `${HOST}go_admin/settings/discipline`
};
const GET_DISCIPLINE = {
  method: 'GET',
  path: `${HOST}go_admin/settings/discipline`
};
const DELETE_DISCIPLINE = {
  method: 'DELETE',
  path: `${HOST}go_admin/settings/discipline`
};

const ADD_CATEGORY = {
  method: 'POST',
  path: `${HOST}go_admin/settings/category`
};
const UPDATE_CATEGORY = {
  method: 'PUT',
  path: `${HOST}go_admin/settings/category`
};
const GET_CATEGORY = {
  method: 'GET',
  path: `${HOST}go_admin/settings/category`
};
const DELETE_CATEGORY = {
  method: 'DELETE',
  path: `${HOST}go_admin/settings/category`
};

const ADD_TRIP = {
  method: 'POST',
  path: `${HOST}go_admin/settings/trip`
};
const UPDATE_TRIP = {
  method: 'PUT',
  path: `${HOST}go_admin/settings/trip`
};
const GET_TRIP = {
  method: 'GET',
  path: `${HOST}go_admin/settings/trip`
};
const DELETE_TRIP = {
  method: 'DELETE',
  path: `${HOST}go_admin/settings/trip`
};

const GET_FEE = {
  method: 'GET',
  path: `${HOST}go_admin/settings/fee`
};
const UPDATE_FEE = {
  method: 'PUT',
  path: `${HOST}go_admin/settings/fee`
};

module.exports = {
  GET_TERM,
  ADD_TERM,
  UPDATE_TERM,
  PATCH_TERM,
  DELETE_TERM,
  ONLINE_TERM,
  ADD_DISCIPLINE,
  UPDATE_DISCIPLINE,
  GET_DISCIPLINE,
  DELETE_DISCIPLINE,
  ADD_CATEGORY,
  UPDATE_CATEGORY,
  GET_CATEGORY,
  DELETE_CATEGORY,
  ADD_TRIP,
  UPDATE_TRIP,
  GET_TRIP,
  DELETE_TRIP,
  GET_FEE,
  UPDATE_FEE,
  TERM_COPY_CONFIG
};
