import { Stack, TextField } from '@material-ui/core';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@material-ui/lab';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateFee } from '../../serviceCalls';
import { setLoading, setSnackbar } from '../../../../../actions/alertActions';

function AddUpdateDialog(props) {
  const { data, handleClose, dispatch, refreshData } = props;

  const Schema = Yup.object().shape({
    amount: Yup.number().min(0).required('Fee amount required')
  });

  const formik = useFormik({
    initialValues: {
      amount: data.amount
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      const param = { ...values, id: data.id };
      updateFee(
        param,
        () => {
          refreshData();
          dispatch(setLoading(0, ''));
          dispatch(setSnackbar('success', 'The fee has been updated'));
        },
        () => {
          dispatch(setLoading(0, ''));
        }
      );
    }
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{ mt: 1 }}>
          <TextField
            type="number"
            fullWidth
            label="Fee Amount($)"
            {...getFieldProps('amount')}
            error={Boolean(touched.amount && errors.amount)}
            helperText={touched.amount && errors.amount}
          />
          <Stack direction="row" justifyContent="end" spacing={1}>
            <LoadingButton size="small" type="submit" variant="outlined">
              Update
            </LoadingButton>
            <Button size="small" color="error" onClick={handleClose} variant="outlined">
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

AddUpdateDialog.propTypes = {
  data: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired
};

export default connect(() => ({}))(AddUpdateDialog);
