import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Paper } from '@material-ui/core';
import { Button, Stack } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';

const DisciplineDialog = (props) => {
  const { handleClose, data } = props;

  return (
    <Stack spacing={2}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="simple table">
          <TableHead>
            <TableRow style={{ background: 'gainsboro' }}>
              <TableCell style={{ width: '30%' }}>Discipline</TableCell>
              <TableCell align="center" style={{ width: '25%' }}>
                Available Capacity
              </TableCell>
              <TableCell align="center" style={{ width: '25%' }}>
                Total Capacity
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.discipline}
                </TableCell>
                <TableCell align="center">{row.available_capacity}</TableCell>
                <TableCell align="center">{row.total_capacity}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="end" spacing={2}>
        <Button variant="outlined" color="error" onClick={handleClose}>
          Close
        </Button>
      </Stack>
    </Stack>
  );
};

DisciplineDialog.propTypes = {
  data: PropTypes.array,
  handleClose: PropTypes.func.isRequired
};

export default connect(() => ({}))(DisciplineDialog);
