import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Stack, TextField } from '@material-ui/core';
import * as React from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@material-ui/lab';
import { Button } from '@mui/material';
import { setConfirmationAlert, setSnackbar } from '../../../../actions/alertActions';
import { participantManualPayment } from '../serviceCalls';

function AddPaymentDialog(props) {
  const { data, dispatch, handleRefresh, handleClose } = props;

  function handleManuelPayment(amount) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, ADD PAYMENT',
        'As a result of this transaction, the payment will be added.',
        () => {
          participantManualPayment(
            {
              amount: parseFloat(amount).toFixed(2),
              participant_id: data.id,
              description: 'Manual payment for the participant'
            },
            () => {
              handleRefresh();
              dispatch(setSnackbar('success', 'Adding payment has been completed'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }
  const Schema = Yup.object().shape({
    Amount: Yup.number().max(Number(data?.balance).toFixed(2)).required('Payment amount required')
  });
  const formik = useFormik({
    initialValues: {
      Amount: 0
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      handleManuelPayment(values.Amount);
    }
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            type="number"
            {...getFieldProps('Amount')}
            error={Boolean(touched.Amount && errors.Amount)}
            helperText={touched.Amount && errors.Amount}
          />
          <Stack direction="row" justifyContent="end" spacing={1}>
            <LoadingButton size="small" type="submit" variant="outlined">
              Add Payment
            </LoadingButton>
            <Button size="small" color="error" onClick={handleClose} variant="outlined">
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

AddPaymentDialog.propTypes = {
  data: PropTypes.array.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default connect(() => ({}))(AddPaymentDialog);
