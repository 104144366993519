import { fetchApi } from '../../../api/fetchApi';

const settings = require('../../../api/RestVariables/Admin/settings');

export async function getTerm(config, scb, ecb) {
  try {
    const url = settings.GET_TERM;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_TERM');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addTerm(config, scb, ecb) {
  try {
    const url = settings.ADD_TERM;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_TERM');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateTerm(config, scb, ecb) {
  try {
    const url = settings.UPDATE_TERM;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_TERM');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteTerm(config, scb, ecb) {
  try {
    const url = settings.DELETE_TERM;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_TERM');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function patchTerm(config, scb, ecb) {
  try {
    const url = settings.PATCH_TERM;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'PATCH_TERM');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function patchOnlineTerm(config, scb, ecb) {
  try {
    const url = settings.ONLINE_TERM;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ONLINE_TERM');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function fetchDisciplineList(config, scb, ecb) {
  try {
    const url = settings.GET_DISCIPLINE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_DISCIPLINE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addDiscipline(config, scb, ecb) {
  try {
    const url = settings.ADD_DISCIPLINE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_DISCIPLINE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateDiscipline(config, scb, ecb) {
  try {
    const url = settings.UPDATE_DISCIPLINE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_DISCIPLINE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteDiscipline(config, scb, ecb) {
  try {
    const url = settings.DELETE_DISCIPLINE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_DISCIPLINE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function fetchCategoryList(config, scb, ecb) {
  try {
    const url = settings.GET_CATEGORY;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_CATEGORY');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addCategory(config, scb, ecb) {
  try {
    const url = settings.ADD_CATEGORY;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_CATEGORY');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateCategory(config, scb, ecb) {
  try {
    const url = settings.UPDATE_CATEGORY;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_CATEGORY');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteCategory(config, scb, ecb) {
  try {
    const url = settings.DELETE_CATEGORY;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_CATEGORY');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function fetchTripList(config, scb, ecb) {
  try {
    const url = settings.GET_TRIP;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_TRIP');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addTrip(config, scb, ecb) {
  try {
    const url = settings.ADD_TRIP;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_TRIP');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateTrip(config, scb, ecb) {
  try {
    const url = settings.UPDATE_TRIP;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_TRIP');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteTrip(config, scb, ecb) {
  try {
    const url = settings.DELETE_TRIP;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_TRIP');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function fetchFeeList(config, scb, ecb) {
  try {
    const url = settings.GET_FEE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_FEE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateFee(config, scb, ecb) {
  try {
    const url = settings.UPDATE_FEE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_FEE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function copyTermConfig(config, scb, ecb) {
  try {
    const url = settings.TERM_COPY_CONFIG;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'TERM_COPY_CONFIG');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}
