export function getFairRoutes(allModules) {
  const isFairAdmin = allModules.findIndex((x) => x.module_access_key === 'fair_admin');
  const isProfileFairAdminFeature =
    isFairAdmin !== -1
      ? allModules[isFairAdmin].features.findIndex((x) => x.feature_access_key === 'profile')
      : -1;
  const isRequestFairAdminFeature =
    isFairAdmin !== -1
      ? allModules[isFairAdmin].features.findIndex((x) => x.feature_access_key === 'request')
      : -1;
  const isDocumentFairAdminFeature =
    isFairAdmin !== -1
      ? allModules[isFairAdmin].features.findIndex((x) => x.feature_access_key === 'document')
      : -1;
  return {
    isFairAdmin,
    isProfileFairAdminFeature,
    isRequestFairAdminFeature,
    isDocumentFairAdminFeature
  };
}
