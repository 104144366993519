import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Stack, Tooltip } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import { MenuItem, TextField } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import { getTravelForAdmin, getTravelParticipantForAdmin } from '../serviceCalls';
import headers from './utils/headerVariables';
import participantsHeaders from './utils/headerVariablesForParticipants';
import TravelSummaryTable from './TravelSummaryTable';
import TravelParticipantDetailTable from './TravelParticipantDetailTable';
import Page from '../../../../components/Page';
import ProcessInfoBox from '../../../../components/CustomComponents/CustomCard/ProcessInfoBox';

function Index(props) {
  const { terms, active_term } = props;
  const [term, setTerm] = useState(active_term);
  const [summaryData, setSummaryData] = useState([]);
  const [participantsData, setParticipantsData] = useState([]);
  const [guidelines, setGuidelines] = useState([]);

  useEffect(() => {
    getTravelForAdmin(
      { term_id: term },
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((item) => {
          item.participants_comp = item.participant_names_complete && (
            <Tooltip title={item.participant_names_complete}>
              <p style={{ cursor: 'pointer' }}>{item.participant_names} </p>
            </Tooltip>
          );
          item.travel_date = item.travel_date ? moment(item.travel_date).format('YYYY-MM-DD') : '-';
        });
        setSummaryData(list);
      },
      () => {}
    );
    getTravelParticipantForAdmin(
      { term_id: term },
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((item) => {
          item.name = `${item.first_name} ${item.last_name}`;
          item.travel_date = item.travel_date ? moment(item.travel_date).format('YYYY-MM-DD') : '-';
        });
        setParticipantsData(list);
      },
      () => {}
    );
  }, [term]);

  return (
    <Page title="Travel | Genius">
      <Stack spacing={2}>
        {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
        <TextField select label="Terms" value={term} onChange={(e) => setTerm(e.target.value)}>
          {terms.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
        <Stack spacing={3} sx={{ mt: 1 }}>
          <TravelSummaryTable tableColumns={headers} tableData={summaryData} />
          <TravelParticipantDetailTable
            tableColumns={participantsHeaders}
            tableData={participantsData}
          />
        </Stack>
      </Stack>
    </Page>
  );
}

Index.propTypes = {
  terms: PropTypes.array,
  active_term: PropTypes.number
};

export default connect((store) => ({
  terms: store.loginReducer.terms,
  active_term: store.loginReducer.active_term
}))(Index);
