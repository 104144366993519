import { Button, Stack } from '@material-ui/core';
import * as React from 'react';
import { Card, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import FileCard from '../../../components/CustomComponents/CustomCard/FileCard';
import { setConfirmationAlert, setSnackbar } from '../../../actions/alertActions';
import {
  addPerformanceFile,
  deletePerformanceFile,
  fetchPerformanceFile,
  fetchPerformanceFileList
} from './utils/serviceCalls';

const crypto = require('crypto');

function FileDialog(props) {
  const { handleNext, dispatch, activePerformance, dialog, handleClose } = props;
  const [list, setList] = useState([]);
  const [time, setTime] = useState(Date.now);
  const [loading, setLoading] = useState(null);
  const [downloading, setDownloading] = useState(null);

  useEffect(() => {
    fetchFileList();
  }, []);

  function changeHandler(event) {
    const arr = list;
    const obj = {
      data: event.target.files[0],
      isSent: false
    };
    arr.push(obj);
    setList(arr);
    setTime(Date.now);
  }
  const handleSubmission = (index) => {
    setLoading(index);
    const file = list[index].data;
    const formData = new FormData();
    formData.append('File', file);
    const hash = crypto.createHash('md5').update(file.name).digest('hex');
    const metaDataParam = {
      performance_id: activePerformance,
      file_name: file.name,
      timestamp: file.lastModifiedDate,
      hash,
      file
    };

    addPerformanceFile(
      metaDataParam,
      () => {
        dispatch(setSnackbar('success', 'The file has been uploaded'));
        const newObj = {
          data: list[index].data,
          isSent: true
        };
        const arr = list;
        arr[index] = newObj;
        setList(arr);
        setTime(Date.now);
        fetchFileList();
        setLoading(null);
      },
      () => {
        setLoading(null);
      }
    );
  };
  function deleteFile(index) {
    const arr = list;
    arr.splice(index, 1);
    setList(arr);
    setTime(Date.now);
  }
  function handleDeleteFileFromDB(file_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'This action will delete the file.',
        () => {
          deletePerformanceFile(
            { file_id },
            () => {
              fetchFileList();
              dispatch(setSnackbar('success', 'The file has been deleted'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }
  const downloadFile = (index) => {
    setDownloading(index);
    const file = list[index].data;
    const param = {
      id: file.id,
      file_name: file.name.split('.')[0]
    };
    fetchPerformanceFile(
      param,
      () => {
        setDownloading(null);
      },
      () => {
        setDownloading(null);
      }
    );
  };
  function fetchFileList() {
    fetchPerformanceFileList(
      { performance_id: activePerformance },
      (data) => {
        const arr = [];
        data.forEach((item) => {
          const obj = {
            data: item,
            isSent: true
          };
          arr.push(obj);
        });
        setList(arr);
      },
      () => {}
    );
  }

  return (
    <Card sx={{ padding: 2, mt: 2 }}>
      <Stack spacing={3} sx={{ mb: 1 }}>
        <div>
          <p style={{ display: 'none' }}>{time}</p>
          <input type="file" name="file" onChange={changeHandler} accept=".mp3,.MP3" />
          <Grid container spacing={2} sx={{ mt: 1 }}>
            {list.length > 0 &&
              list.map((item, index) => (
                <Grid item xs={4} key={index}>
                  <FileCard
                    performance
                    downloading={downloading}
                    loading={loading}
                    index={index}
                    editMode
                    data={item}
                    deleteFileFromDB={handleDeleteFileFromDB}
                    deleteFile={() => deleteFile(index)}
                    submitFile={() => handleSubmission(index)}
                    downloadFile={() => downloadFile(index)}
                  />
                </Grid>
              ))}
          </Grid>
        </div>
        {!dialog && (
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleNext} variant="outlined">
              Next
            </Button>
          </Box>
        )}
      </Stack>
      {dialog && (
        <Stack justifyContent="end">
          <div style={{ justifyContent: 'end', display: 'flex' }}>
            <Button variant="outlined" color="error" sx={{ mr: 1 }} onClick={handleClose}>
              Close
            </Button>
          </div>
        </Stack>
      )}
    </Card>
  );
}

FileDialog.propTypes = {
  dispatch: PropTypes.func.isRequired,
  activePerformance: PropTypes.number,
  handleNext: PropTypes.func.isRequired,
  handleClose: PropTypes.func,
  dialog: PropTypes.bool
};

export default connect((store) => ({
  activePerformance: store.dashboardReducer.activePerformance
}))(FileDialog);
