export default [
  {
    name: 'id',
    label: 'ID'
  },
  {
    name: 'description',
    label: 'Description'
  },
  {
    name: 'amount_comp',
    label: 'Amount'
  },
  {
    name: 'term_id',
    label: 'Term'
  },
  {
    name: 'edit',
    label: 'Edit',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  }
];
