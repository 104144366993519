import { fetchApi } from '../../../../api/fetchApi';

const variables = require('./variables');

export async function addProjectPaymentLink(config, scb, ecb) {
  try {
    const url = variables.PROJECT_LINK;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'PROJECT_LINK');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addParticipantPaymentLink(config, scb, ecb) {
  try {
    const url = variables.PARTICIPANT_LINK;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'PARTICIPANT_LINK');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}
