import { useEffect, useState } from 'react';
import { Button, Divider, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as React from 'react';
import Box from '@mui/material/Box';
import {
  deleteRoboticsFormRubric,
  getRoboticsFormDetail,
  updateRoboticsFormRubricRank
} from '../_apis/serviceCalls';
import RubricHeader from './RubricHeader/RubricHeader';
import AddRubric from './AddRubric/AddRubric';
import { setConfirmationAlert, setSnackbar } from '../../../../../actions/alertActions';
import RubricDetail from './RubricDetail/RubricDetail';
import RubricsDragAndDrop from './RubricsDragAndDrop/RubricsDragAndDrop';

function Index(props) {
  const { id, dispatch, handleNext } = props;
  const [rubricList, setRubricList] = useState(null);
  const [updatedRubricList, setUpdatedRubricList] = useState(null);
  const [addRubricDialogShow, setAddRubricDialogShow] = useState(false);
  const [rankUpdateAvailable, setRankUpdateAvailable] = useState(false);
  const [updateRankLoading, setUpdateRankLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    getRoboticsFormDetail(
      { competition_form_id: id },
      (data) => {
        const _rubrics = data.rubrics;
        _rubrics.sort((a, b) => a.rank - b.rank);
        setRubricList(_rubrics);
        setUpdatedRubricList(_rubrics);
      },
      () => {}
    );
  }

  function handleUpdateRubricRank() {
    setUpdateRankLoading(true);
    const rankList = [];
    updatedRubricList.forEach((rubric, index) => {
      const obj = {
        competition_rubric_id: rubric.id,
        rank: index + 1
      };
      rankList.push(obj);
    });
    const param = {
      competition_rubric_ranks: rankList
    };
    updateRoboticsFormRubricRank(
      param,
      () => {
        setUpdateRankLoading(false);
        fetchData();
        setRankUpdateAvailable(false);
      },
      () => {
        setUpdateRankLoading(false);
      }
    );
  }

  function onDragEnd(result) {
    const { destination, source, reason } = result;
    if (!destination || reason === 'CANCEL') {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const list = Object.assign([], updatedRubricList);
    const droppedQuestion = updatedRubricList[source.index];

    list.splice(source.index, 1);
    list.splice(destination.index, 0, droppedQuestion);
    setUpdatedRubricList(list);
  }

  function cancelDragAndDrop() {
    setRankUpdateAvailable(false);
    setUpdatedRubricList(rubricList);
  }

  function refreshData() {
    fetchData();
    setAddRubricDialogShow(false);
  }

  function handleDeleteRubric(competition_rubric_id) {
    const param = { competition_rubric_id, force_delete: 0 };
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'As a result of this process, the rubric will be deleted irreversibly for all users, are you sure?',
        () => {
          deleteRoboticsFormRubric(
            param,
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
              dispatch(setSnackbar('success', 'Rubric deleted.'));
              fetchData();
            },
            (error) => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
              if (error.http_status_code === 409) {
                forceDelete(competition_rubric_id, error.message);
              }
            }
          );
        }
      )
    );
  }

  function forceDelete(competition_rubric_id, message) {
    dispatch(
      setConfirmationAlert('Are you sure?', 'YES, FORCE DELETE', message, () => {
        deleteRoboticsFormRubric(
          { competition_rubric_id, force_delete: 1 },
          () => {
            dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            dispatch(setSnackbar('success', 'Rubric deleted.'));
            fetchData();
          },
          () => {
            dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
          }
        );
      })
    );
  }

  return (
    <Stack spacing={2}>
      <Divider style={{ marginTop: 20 }} />
      <RubricHeader
        rankUpdateAvailable={rankUpdateAvailable}
        handleUpdateRubricRank={handleUpdateRubricRank}
        updateRankLoading={updateRankLoading}
        cancelDragAndDrop={cancelDragAndDrop}
        setAddRubricDialogShow={setAddRubricDialogShow}
        rubricList={rubricList}
        setRankUpdateAvailable={setRankUpdateAvailable}
      />
      {rubricList &&
        rubricList.length > 0 &&
        !rankUpdateAvailable &&
        rubricList.map((rubric, index) => (
          <RubricDetail
            rubric={rubric}
            key={index}
            refreshData={fetchData}
            handleDeleteRubric={handleDeleteRubric}
          />
        ))}
      <RubricsDragAndDrop
        rankUpdateAvailable={rankUpdateAvailable}
        onDragEnd={onDragEnd}
        updatedRubricList={updatedRubricList}
      />
      {addRubricDialogShow && (
        <AddRubric
          id={id}
          closeDialog={() => setAddRubricDialogShow(false)}
          handleSuccess={refreshData}
        />
      )}
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button type="submit" variant="outlined" onClick={handleNext}>
          Next
        </Button>
      </Box>
    </Stack>
  );
}

Index.propTypes = {
  dispatch: PropTypes.func,
  handleNext: PropTypes.func,
  id: PropTypes.number
};

export default connect(() => ({}))(Index);
