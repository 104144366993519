import { fetchApi } from '../../../../api/fetchApi';

const guideline = require('./apis');

export async function getGuidelineList(config, scb, ecb) {
  try {
    const url = guideline.GET_GUIDELINE_LIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_GUIDELINE_LIST');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getGuidelineType(config, scb, ecb) {
  try {
    const url = guideline.GET_GUIDELINE_TYPE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_GUIDELINE_TYPE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getGuidelineFeature(config, scb, ecb) {
  try {
    const url = guideline.GET_GUIDELINE_FEATURE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_GUIDELINE_FEATURE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getGuideline(config, scb, ecb) {
  try {
    const url = guideline.GET_GUIDELINE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_GUIDELINE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addGuideline(config, scb, ecb) {
  try {
    const url = guideline.ADD_GUIDELINE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_GUIDELINE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateGuideline(config, scb, ecb) {
  try {
    const url = guideline.UPDATE_GUIDELINE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_GUIDELINE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteGuideline(config, scb, ecb) {
  try {
    const url = guideline.DELETE_GUIDELINE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_GUIDELINE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}
