import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { Grid, Typography } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';

const useStyles = makeStyles(() => ({
  cardAction: {
    position: 'absolute',
    bottom: 5,
    right: 5
  }
}));

export default function FairFileCard(props) {
  const classes = useStyles();
  const { data, downloadFile, loadingButton } = props;

  return (
    <Card style={{ minHeight: 170 }}>
      <CardContent>
        <Typography variant="body2" color="textPrimary" component="span">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <span>
                <b>{data?.title || '-'}</b>
              </span>
            </Grid>
            <Grid item xs={12}>
              {data?.description || '-'}
            </Grid>
          </Grid>
        </Typography>
      </CardContent>
      <CardActions className={classes.cardAction}>
        <LoadingButton
          loading={loadingButton}
          variant="outlined"
          onClick={() => downloadFile(data)}
        >
          Download
        </LoadingButton>
      </CardActions>
    </Card>
  );
}

FairFileCard.propTypes = {
  data: PropTypes.object.isRequired,
  loadingButton: PropTypes.bool.isRequired
};
