import { useEffect, useState } from 'react';
import { MenuItem, Stack, TextField } from '@material-ui/core';
import * as React from 'react';
import { LoadingButton } from '@material-ui/lab';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  fetchPlaceholder,
  fetchTemplate,
  sendEmail
} from '../../../pages/Admin/Communication/Template/serviceCalls';
import Editor from './Editor';
import { setSnackbar } from '../../../actions/alertActions';
import { getMailTemp } from './MailTemp';
import { PARTICIPANT_MAIL_TYPE, TRIP_MAIL_TYPE } from '../../../api/RestVariables/staticVariables';
import { getSurveyList } from '../../../pages/Admin/SurveyOperation/_apis/serviceCalls';

const TEMPLATE_MAIL = 2;

function Index(props) {
  const { mailType, handleClose, idList, dispatch, handleRefresh } = props;
  const [mode, setMode] = useState(null);
  const [mailBody, setMailBody] = useState('');
  const [subject, setSubject] = useState('');
  const [placeholders, setPlaceholders] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [checked, setChecked] = useState(false);
  const [isSurvey, setIsSurvey] = useState(false);
  const [surveyList, setSurveyList] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = useState(null);

  useEffect(() => {
    getSurveyList({}, (data) => {
      setSurveyList(data.data);
    });
    fetchTemplate(
      {},
      (data) => {
        const arr = [];
        data.data.forEach((item) => {
          if (item.template_type_id === mailType) {
            arr.push(item);
          }
        });
        setTemplates(arr);
      },
      () => {}
    );
    fetchPlaceholder(
      { type_id: mailType },
      (data) => {
        setPlaceholders(data);
      },
      () => {}
    );
  }, []);

  useEffect(() => {
    if (!isSurvey) {
      setSelectedSurvey(null);
    }
  }, [isSurvey]);

  function handleChangeTemp(event) {
    setSelectedTemplate(event.target.value);
    const temp = templates.find((x) => x.id === event.target.value);
    setMailBody(temp.body);
    setSubject(temp.subject);
  }

  function handleSave() {
    const paramBody = getMailTemp(mailBody);
    const params = {
      type_id: mailType,
      content_ids: idList,
      message_body: paramBody,
      subject,
      include_place_holder: 1
    };
    if (mailType === PARTICIPANT_MAIL_TYPE || mailType === TRIP_MAIL_TYPE) {
      params.send_to_participant = checked ? 1 : 0;
    }
    sendEmail(
      params,
      () => {
        dispatch(setSnackbar('success', 'The email has been sent'));
        handleClose();
        handleRefresh();
      },
      () => {}
    );
  }

  function handleAddSurveyLink(event) {
    setSelectedSurvey(event.target.value);
    const _surveyLink = surveyList.find((x) => x.survey_id === event.target.value).survey_link;
    setMailBody(mailBody + _surveyLink);
  }

  return (
    <Stack spacing={3} sx={{ mt: 1 }}>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <TextField
          value={mode}
          fullWidth
          label="Mail Type"
          select
          onChange={(e) => setMode(e.target.value)}
        >
          <MenuItem key={1} value={1}>
            Custom
          </MenuItem>
          <MenuItem key={2} value={2}>
            Template
          </MenuItem>
        </TextField>
        {mode === TEMPLATE_MAIL && (
          <TextField
            disabled={selectedTemplate}
            value={selectedTemplate}
            fullWidth
            label="Templates"
            select
            onChange={handleChangeTemp}
          >
            {templates.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Stack>
      <TextField
        fullWidth
        label="Subject"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
      />
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        {mode && (
          <FormControlLabel
            control={
              <Checkbox checked={isSurvey} onChange={(e) => setIsSurvey(e.target.checked)} />
            }
            label="Select Survey"
          />
        )}
        {isSurvey && (
          <TextField
            value={selectedSurvey}
            fullWidth
            label="Survey"
            select
            onChange={handleAddSurveyLink}
          >
            {surveyList.map((item) => (
              <MenuItem key={item.survey_id} value={item.survey_id}>
                {item.survey_name}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Stack>
      {mode && <Editor placeholders={placeholders} value={mailBody} setMailBody={setMailBody} />}
      <Stack direction="row" justifyContent="end" spacing={1}>
        {(mailType === PARTICIPANT_MAIL_TYPE || mailType === TRIP_MAIL_TYPE) && (
          <FormControlLabel
            control={<Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)} />}
            label="Send to Participant"
          />
        )}
        <LoadingButton size="small" type="submit" variant="outlined" onClick={handleSave}>
          Send
        </LoadingButton>
        <Button size="small" color="error" onClick={handleClose} variant="outlined">
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
}

Index.propTypes = {
  handleClose: PropTypes.func,
  idList: PropTypes.array,
  mailType: PropTypes.number,
  dispatch: PropTypes.func,
  handleRefresh: PropTypes.func
};

export default connect(() => ({}))(Index);
