import { fetchApi } from '../../../api/fetchApi';

const participation = require('../../../api/RestVariables/Application/participation');

export async function getParticipantList(config, scb, ecb) {
  try {
    const url = participation.GET_PARTICIPANT_LIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_PARTICIPANT_LIST');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getParticipantCheckList(config, scb, ecb) {
  try {
    const url = participation.GET_PARTICIPANT_CHECK_LIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_PARTICIPANT_CHECK_LIST');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addParticipant(config, scb, ecb) {
  try {
    const url = participation.ADD_PARTICIPANT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_PARTICIPANT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function signForm(config, scb, ecb) {
  try {
    const url = participation.SIGN_FORM;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'SIGN_FORM');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateParticipant(config, scb, ecb) {
  try {
    const url = participation.UPDATE_PARTICIPANT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_PARTICIPANT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteParticipant(config, scb, ecb) {
  try {
    const url = participation.DELETE_PARTICIPANT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'PATCH_TERM');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getParticipantProjectList(config, scb, ecb) {
  try {
    const url = participation.GET_PARTICIPANT_PROJECT_LIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_PARTICIPANT_PROJECT_LIST');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getParticipant(config, scb, ecb) {
  try {
    const url = participation.GET_PARTICIPANT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_PARTICIPANT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getParticipantStudent(config, scb, ecb) {
  try {
    const url = participation.GET_PARTICIPANT_STUDENT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_PARTICIPANT_STUDENT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getParticipantType(config, scb, ecb) {
  try {
    const url = participation.GET_PARTICIPANT_TYPE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_PARTICIPANT_TYPE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function confirmParticipant(config, scb, ecb) {
  try {
    const url = participation.CONFIRM_PARTICIPANT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'CONFIRM_PARTICIPANT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function paymentForParticipant(config, scb, ecb) {
  try {
    const url = participation.PAYMENT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'PAYMENT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addInvitationLetter(config, scb, ecb) {
  try {
    const url = participation.ADD_INVITATION_LETTER;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_INVITATION_LETTER');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getInvitationLetterList(config, scb, ecb) {
  try {
    const url = participation.INVITATION_LETTER_LIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'INVITATION_LETTER_LIST');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function downloadInvitationLetter(config, scb, ecb) {
  try {
    const url = participation.DOWNLOAD_INVITATION_LETTER;
    await fetchApi(
      url,
      config,
      (data) => {
        getFilesApi(data, config, scb, ecb);
        console.log(data, 'DOWNLOAD_INVITATION_LETTER');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

const getFilesApi = (data, config, scb, ecb) => {
  const { url } = data;

  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'multipart/form-data' }
  };
  try {
    fetch(url, requestOptions)
      // eslint-disable-next-line consistent-return
      .then((response) => {
        if (response.status === 200) {
          scb();
          return response.blob();
        }
        ecb(response.status);
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        console.log(link);
        link.href = href;
        link.setAttribute('download', 'download.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  } catch (error) {
    console.log(error);
    ecb(error);
  }
};

export async function participantInvitationLetter(config, scb, ecb) {
  try {
    const url = participation.PARTICIPANT_INVITATION_LETTER;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'PARTICIPANT_INVITATION_LETTER');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getVip(config, scb, ecb) {
  try {
    const url = participation.GET_VIP;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_VIP');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addVip(config, scb, ecb) {
  try {
    const url = participation.ADD_VIP;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_VIP');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateVip(config, scb, ecb) {
  try {
    const url = participation.UPDATE_VIP;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_VIP');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteVip(config, scb, ecb) {
  try {
    const url = participation.DELETE_VIP;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_VIP');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function downloadCertificate(config, scb, ecb) {
  try {
    const url = participation.DOWNLOAD_CERTIFICATE;
    await fetchApi(
      url,
      config,
      (data) => {
        getFilesApiForCertificate(data, config, scb, ecb);
        console.log(data, 'DOWNLOAD_CERTIFICATE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

const getFilesApiForCertificate = (data, config, scb, ecb) => {
  const { url } = data;
  const extension = url.substring(url.lastIndexOf('.') + 1, url.lastIndexOf('?'));

  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'multipart/form-data' }
  };
  try {
    fetch(url, requestOptions)
      // eslint-disable-next-line consistent-return
      .then((response) => {
        if (response.status === 200) {
          return response.blob();
        }
        ecb(response.status);
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        console.log(link);
        link.href = href;
        link.setAttribute('download', `certificate.${extension}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        scb();
      });
  } catch (error) {
    console.log(error);
    ecb(error);
  }
};
