import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, DialogContent, Stack } from '@mui/material';
import { TextField } from '@material-ui/core';
import Page from '../../components/Page';
import { startCompetition } from './_apis/serviceCalls';
import CustomDialog from '../../components/CustomComponents/CustomDialog';
import SubmissionPage from './SubmissionPage';

function Index() {
  const [projectId, setProjectId] = useState('');
  const [loading, setLoading] = useState(false);
  const [projectData, setProjectData] = useState(null);
  const [dialogShow, setDialogShow] = useState(false);

  function handleStart() {
    setLoading(true);
    startCompetition(
      { project_id: projectId },
      (data) => {
        setProjectData(data);
        setDialogShow(true);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  }

  function handleSuccess() {
    setDialogShow(false);
  }

  return (
    <Page title="Scorekeeper | Genius">
      <Stack spacing={2}>
        <p>Please enter project ID</p>
        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
          <TextField
            value={projectId}
            onChange={(e) => setProjectId(e.target.value)}
            type="number"
          />
          <div style={{ alignItems: 'center', display: 'flex' }}>
            <Button variant="outlined" disabled={loading || !projectId} onClick={handleStart}>
              Start
            </Button>
          </div>
        </Stack>
      </Stack>
      <CustomDialog
        onClose={() => setDialogShow(false)}
        open={dialogShow}
        maxWidth="lg"
        title="Submission"
        fullWidth
      >
        <DialogContent>
          <SubmissionPage
            projectData={projectData}
            handleSuccess={handleSuccess}
            handleClose={() => setDialogShow(false)}
          />
        </DialogContent>
      </CustomDialog>
    </Page>
  );
}

Index.propTypes = {};

export default connect(() => ({}))(Index);
