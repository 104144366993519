import { MenuItem, Stack, TextField } from '@material-ui/core';
import * as React from 'react';
import { Card, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import moment from 'moment';
import Divider from '@mui/material/Divider';
import FileCard from '../../../../components/CustomComponents/CustomCard/FileCard';
import {
  addFileMeta,
  deleteFileFromDB,
  fetchDisciplineFileType,
  getAllFiles,
  getFileMeta
} from '../utils/serviceCalls';
import { setConfirmationAlert, setSnackbar } from '../../../../actions/alertActions';
import FileUploadDialog from '../../../../components/CustomComponents/CustomLoading/FileUploadDialog';

const crypto = require('crypto');

function FilePart(props) {
  const {
    handleNext,
    handleBack,
    dispatch,
    activeCategory,
    project_id,
    atReview,
    activeDiscipline
  } = props;
  const [list, setList] = useState([]);
  const [fileTypeList, setFileTypeList] = useState([]);
  const [time, setTime] = useState(Date.now);
  const [enableButton, setEnableButton] = useState(false);
  const [loading, setLoading] = useState(null);
  const [downloading, setDownloading] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [addButtonShow, setAddButtonShow] = useState(true);

  useEffect(() => {
    if (project_id) {
      fetchFileList();
    }
    fetchDisciplineFileType({ discipline_id: activeDiscipline }, (data) => {
      setFileTypeList(data);
    }).then(() => {});
  }, []);

  useEffect(() => {
    if (list.length === 0) {
      setEnableButton(false);
    }
  }, [list]);

  function changeHandler(event) {
    const arr = list;
    const obj = {
      data: event.target.files[0],
      isSent: false
    };
    arr.push(obj);
    setList(arr);
    setTime(Date.now);
  }

  const handleSubmission = (index) => {
    setLoading(index);
    const file = list[index].data;
    const formData = new FormData();
    formData.append('File', file);
    const hash = crypto.createHash('md5').update(file.name).digest('hex');
    const metaDataParam = {
      project_id,
      file_name: file.name,
      timestamp: moment().format(),
      hash,
      file,
      file_type_id: selectedType
    };

    addFileMeta(
      metaDataParam,
      () => {
        dispatch(setSnackbar('success', 'The file has been uploaded'));
        const newObj = {
          data: list[index].data,
          isSent: true
        };
        setEnableButton(true);
        const arr = list;
        arr[index] = newObj;
        setList(arr);
        setTime(Date.now);
        fetchFileList();
        setLoading(null);
        setSelectedType(null);
      },
      () => {
        setLoading(null);
      }
    ).then(() => setAddButtonShow(true));
  };

  function deleteFile(index) {
    const arr = list;
    arr.splice(index, 1);
    setList(arr);
    setTime(Date.now);
    setAddButtonShow(true);
    setSelectedType(null);
  }

  function handleDeleteFileFromDB(file_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'This action will delete the file.',
        () => {
          deleteFileFromDB(
            { file_id },
            () => {
              fetchFileList();
              dispatch(setSnackbar('success', 'The file has been deleted'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }

  const downloadFile = (index) => {
    setDownloading(index);
    const file = list[index].data;
    const param = {
      id: file.id,
      file_name: file.name.split('.')[0]
    };
    getFileMeta(
      param,
      () => {
        setDownloading(null);
      },
      () => {
        setDownloading(null);
      }
    );
  };

  function fetchFileList() {
    getAllFiles(
      { project_id },
      (data) => {
        const arr = [];
        data.forEach((item) => {
          const obj = {
            data: item,
            isSent: true
          };
          setEnableButton(true);
          arr.push(obj);
        });
        setList(arr);
      },
      () => {}
    );
  }

  return (
    <Card sx={{ padding: 2, mt: 2 }}>
      <Stack spacing={3} sx={{ mt: 2, mb: 1 }}>
        <p style={{ display: 'none' }}>{time}</p>
        {addButtonShow ? (
          <div>
            <Button onClick={() => setAddButtonShow(false)} disabled={atReview} variant="outlined">
              Add File
            </Button>
          </div>
        ) : (
          <Stack spacing={2} direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              style={{ minWidth: 150 }}
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
              label="File Type"
              select
            >
              {fileTypeList.map((item) => (
                <MenuItem key={item.file_type_id} value={item.file_type_id}>
                  {item.is_required ? `${item.file_type} (Required)` : item.file_type}
                </MenuItem>
              ))}
            </TextField>
            {selectedType && (
              <input
                disabled={atReview}
                type="file"
                name="file"
                onChange={changeHandler}
                accept=".jpg,.pdf,.png,.jpeg,.svg"
              />
            )}
          </Stack>
        )}
        <Divider />
        <div>
          <Grid container spacing={2}>
            {list.length > 0 &&
              list.map((item, index) => (
                <Grid item xs={4} key={index}>
                  <FileCard
                    downloading={downloading}
                    loading={loading}
                    index={index}
                    atReview={atReview}
                    editMode
                    data={item}
                    deleteFileFromDB={handleDeleteFileFromDB}
                    deleteFile={() => deleteFile(index)}
                    submitFile={() => handleSubmission(index)}
                    downloadFile={() => downloadFile(index)}
                  />
                </Grid>
              ))}
          </Grid>
        </div>
      </Stack>
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Button variant="outlined" color="inherit" sx={{ mr: 1 }} onClick={handleBack}>
          Back
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button
          onClick={handleNext}
          variant="outlined"
          disabled={activeCategory?.is_file_required === 1 && !enableButton}
        >
          Next
        </Button>
      </Box>
      {loading !== null && <FileUploadDialog />}
    </Card>
  );
}

FilePart.propTypes = {
  handleBack: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  activeCategory: PropTypes.object,
  project_id: PropTypes.number,
  atReview: PropTypes.number,
  activeDiscipline: PropTypes.number
};

export default connect((store) => ({
  project_id: store.projectReducer.activeProject,
  activeCategory: store.projectReducer.activeCategory,
  activeDiscipline: store.projectReducer.activeDiscipline
}))(FilePart);
