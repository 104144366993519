const HOST = process.env.REACT_APP_REST_API_HOST;

const GET_PLACEHOLDER = {
  method: 'GET',
  path: `${HOST}go_admin/communication/common/place_holder`
};
const GET_PLACEHOLDER_TYPE = {
  method: 'GET',
  path: `${HOST}go_admin/communication/common/type`
};
const SEND_MAIL = {
  method: 'POST',
  path: `${HOST}go_admin/communication/message/send_email`
};
const GET_TEMPLATE = {
  method: 'GET',
  path: `${HOST}go_admin/communication/template`
};
const ADD_TEMPLATE = {
  method: 'POST',
  path: `${HOST}go_admin/communication/template`
};
const UPDATE_TEMPLATE = {
  method: 'PUT',
  path: `${HOST}go_admin/communication/template`
};
const DELETE_TEMPLATE = {
  method: 'DELETE',
  path: `${HOST}go_admin/communication/template`
};
const GET_EMAIL_HISTORY = {
  method: 'GET',
  path: `${HOST}go_admin/communication/report/email_history`
};

module.exports = {
  GET_PLACEHOLDER,
  GET_PLACEHOLDER_TYPE,
  SEND_MAIL,
  GET_TEMPLATE,
  ADD_TEMPLATE,
  UPDATE_TEMPLATE,
  DELETE_TEMPLATE,
  GET_EMAIL_HISTORY
};
