import { Link as RouterLink, Outlet } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
// components
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Logo from '../components/Logo';
import CustomLoading from '../components/CustomComponents/CustomLoading';
import CustomSnackbar from '../components/CustomComponents/CustomSnackbar';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0)
  }
}));

// ----------------------------------------------------------------------

function LogoOnlyLayout({
  loadingStatus,
  loadingText,
  isSnackbarVisible,
  snackbarSettings,
  dispatch
}) {
  return (
    <>
      <HeaderStyle>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
      </HeaderStyle>
      {loadingStatus === 1 && <CustomLoading size="lg" isOpen={loadingStatus} text={loadingText} />}
      {isSnackbarVisible && (
        <CustomSnackbar
          settings={{
            ...snackbarSettings,
            onHide: () =>
              dispatch({
                type: 'RESET_SNACKBAR',
                payload: null
              })
          }}
        />
      )}
      <Outlet />
    </>
  );
}

LogoOnlyLayout.propTypes = {
  isSnackbarVisible: PropTypes.bool.isRequired,
  snackbarSettings: PropTypes.object,
  loadingStatus: PropTypes.bool,
  loadingText: PropTypes.string,
  dispatch: PropTypes.func
};

export default connect((store) => ({
  isConfirmationVisible: store.alertReducer.isConfirmationVisible,
  confirmationSettings: store.alertReducer.confirmationSettings,
  isSnackbarVisible: store.alertReducer.isSnackbarVisible,
  snackbarSettings: store.alertReducer.snackbarSettings,
  loadingStatus: store.alertReducer.loadingStatus,
  loadingText: store.alertReducer.loadingText,
  isLoggedIn: store.tokenReducer.isLoggedIn
}))(LogoOnlyLayout);
