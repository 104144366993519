import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, DialogContent, Stack, TextField } from '@mui/material';
import CustomDialog from '../../../../components/CustomComponents/CustomDialog';
import { setLoading, setSnackbar } from '../../../../actions/alertActions';
import { updateCarbonScore } from '../_apis/serviceCalls';

const CarbonScoreDialog = (props) => {
  const { handleClose, dispatch, handleRefresh, data } = props;
  const [score, setScore] = useState(data.carbon_score);

  function handleUpdate() {
    dispatch(setLoading(1, 'Loading...'));
    updateCarbonScore(
      { coding_scoreboard_id: data.coding_scoreboard_id, carbon_score: score },
      () => {
        dispatch(setSnackbar('success', 'Carbon Score Updated'));
        handleClose();
        handleRefresh();
      }
    ).then(() => dispatch(setLoading(0)));
  }

  return (
    <CustomDialog onClose={handleClose} open title="Carbon Score" disableEnforceFocus>
      <DialogContent>
        <TextField
          style={{ marginTop: 10 }}
          value={score}
          onChange={(e) => setScore(e.target.value)}
          label="Carbon Score"
        />
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="end"
          spacing={2}
          style={{ marginTop: 10 }}
        >
          <Button onClick={handleUpdate} variant="outlined" disabled={!score}>
            Update
          </Button>
          <Button variant="outlined" color="error" onClick={handleClose}>
            Cancel
          </Button>
        </Stack>
      </DialogContent>
    </CustomDialog>
  );
};

CarbonScoreDialog.propTypes = {
  data: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  handleRefresh: PropTypes.func
};

export default connect(() => ({}))(CarbonScoreDialog);
