import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { Stack } from '@material-ui/core';
import { Button } from '@mui/material';
import { setSnackbar } from '../../../../../actions/alertActions';
import {
  fetchFeaturesList,
  fetchRoleFeatureList,
  addRoleFeature,
  updateRoleFeature
} from '../../serviceCalls';
import { icons, groupFeatures, filterFeaturesByRole } from './featureUtilities';

const RoleCheckboxTree = (props) => {
  const { mode, selectedRole, onCancel, dispatch } = props;
  const [checkedFeatures, setCheckedFeatures] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [featuresList, setFeaturesList] = useState([]);
  const [updateMode, setUpdateMode] = useState(false);

  useEffect(() => {
    fetchFeaturesList(
      {},
      (data) => {
        setFeaturesList(groupFeatures(data));
      },
      () => {}
    );
    fetchRoleFeatureList(
      {},
      (data) => {
        const features = filterFeaturesByRole(data, selectedRole);
        setUpdateMode(features.featureUpdate);
        setCheckedFeatures(features.filteredFeatureList);
      },
      () => {}
    );
  }, [selectedRole]);

  const handleAddRoleFeature = (checkedFeatures) => {
    const { role_id } = selectedRole;
    const params = { role_id, feature_ids: checkedFeatures };
    const method = updateMode ? updateRoleFeature : addRoleFeature;
    console.log(params);
    method(
      params,
      () => {
        dispatch(
          setSnackbar('success', updateMode ? 'Features are Updated!' : 'New feature added!')
        );
        onCancel();
      },
      () => {
        dispatch(
          setSnackbar('error', mode === 1 ? 'Could not update the role' : 'Could not add the role')
        );
      }
    );
  };

  const handleSelectFeatures = (checked) => {
    setCheckedFeatures(checked);
  };

  const addButtonText = updateMode ? 'Update Features' : 'Add Features';
  return (
    <Stack spacing={2}>
      <CheckboxTree
        nodes={featuresList}
        checked={checkedFeatures}
        expanded={expanded}
        icons={icons}
        nameAsArray
        iconsClass="fa5"
        nativeCheckboxes
        onCheck={(checked) => handleSelectFeatures(checked)}
        onExpand={(expanded) => setExpanded(expanded)}
        showExpandAll
      />
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        sx={{ mt: 1 }}
        justifyContent="end"
        spacing={2}
      >
        <Button variant="outlined" onClick={() => handleAddRoleFeature(checkedFeatures)}>
          {addButtonText}
        </Button>
        <Button variant="outlined" onClick={() => onCancel()} color="error">
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};

RoleCheckboxTree.propTypes = {
  mode: PropTypes.number.isRequired,
  selectedRole: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired
};

export default connect(() => ({}))(RoleCheckboxTree);
