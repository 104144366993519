import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Stack } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Checkbox } from '@material-ui/core';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiTable from '../../../../../components/CustomComponents/MuiTable';
import headers from '../utils/headerVariablesTripList';
import { getTripListForAdmin, updateTripForAdmin } from '../../serviceCalls';

function UpdateTripDialog(props) {
  const { participantData, handleClose, handleRefresh } = props;
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [tripList, setTripList] = useState([]);
  const [isJfkBus, setIsJfkBus] = useState(false);

  useEffect(() => {
    fetchTripList();
  }, []);
  function fetchTripList() {
    getTripListForAdmin(
      {},
      (data) => {
        setTripList(data);
        if (participantData.trip_id) {
          data.forEach((item, index) => {
            if (item.id === participantData.trip_id) {
              setSelectedStudent([index]);
              setIsJfkBus(!!participantData.jfk_bus);
            }
          });
        }
      },
      () => {}
    );
  }
  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedStudent(rowsSelected);
  };
  function handleAdd() {
    const param = {
      participant_id: participantData.participant_id,
      trip_id: tripList[selectedStudent].id,
      jfk_bus: isJfkBus ? 1 : 0
    };
    updateTripForAdmin(
      param,
      () => {
        handleRefresh();
      },
      () => {}
    );
  }

  return (
    <Stack spacing={3}>
      <MuiTable
        tableData={tripList}
        tableColumns={headers}
        customOption={{
          selectableRows: 'single',
          onRowSelectionChange,
          rowsSelected: selectedStudent
        }}
      />
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="end">
        {selectedStudent && (
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FormControlLabel
              control={
                <Checkbox checked={isJfkBus} onChange={(e) => setIsJfkBus(e.target.checked)} />
              }
              label="Trip participants can register for JFK bus service. This service is only for pick up from John F Kennedy Airport, NY. "
            />
            <Button variant="outlined" onClick={handleAdd}>
              Add
            </Button>
          </Stack>
        )}
        <Button variant="outlined" color="error" onClick={handleClose}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
}

UpdateTripDialog.propTypes = {
  participantData: PropTypes.object,
  handleClose: PropTypes.func,
  handleRefresh: PropTypes.func
};

export default connect(() => ({}))(UpdateTripDialog);
