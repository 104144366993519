import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import { setLoading } from '../../../../actions/alertActions';
import { scoreMaker } from './_utils/scoreMaker';
import MuiTable from '../../../../components/CustomComponents/MuiTable';
import { getReportResults } from '../_apis/serviceCalls';

function ReportTable(props) {
  const { id, dispatch } = props;
  const [data, setData] = useState([]);
  const [keyList, setKeyList] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    dispatch(setLoading(1, 'Loading...'));
    getReportResults({ survey_id: id }, (_data) => {
      const updatedData = scoreMaker(_data);
      setKeyList(updatedData);
      setData(_data);
    }).then(() => dispatch(setLoading(0, '')));
  }

  function handleFilterTable(displayData) {
    const updatedList = [];
    if (displayData && displayData.length > 0) {
      displayData.forEach((item) => {
        updatedList.push(data[item.dataIndex]);
      });
      const head = scoreMaker(updatedList);
      setKeyList(head);
    }
  }

  return (
    <Stack spacing={2}>
      <MuiTable
        title={<p style={{ fontWeight: 'bold' }}>Results</p>}
        tableData={data}
        tableColumns={keyList}
        noteTable
        customOption={{
          onFilterChange: (changedColumn, filterList, type, changedColumnIndex, displayData) => {
            handleFilterTable(displayData);
          }
        }}
      />
    </Stack>
  );
}

ReportTable.propTypes = {
  id: PropTypes.number,
  dispatch: PropTypes.func
};

export default connect(() => ({}))(ReportTable);
