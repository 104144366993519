export default [
  {
    name: 'start',
    label: 'Start',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'edit',
    label: 'Edit',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'total_score_view',
    label: 'Total Score'
  },
  {
    name: 'project_id',
    label: 'Project ID'
  },
  {
    name: 'title',
    label: 'Project Title'
  },
  {
    name: 'judging_session_type',
    label: 'Session'
  },
  {
    name: 'group_number',
    label: 'Group Number'
  },
  {
    name: 'place',
    label: 'Place'
  },
  {
    name: 'country',
    label: 'Country'
  },
  {
    name: 'discipline',
    label: 'Discipline'
  }
];
