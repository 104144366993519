import { Grid, IconButton, Stack } from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { Icon } from '@iconify/react';
import { Button, DialogContent, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import ReactHtmlParser from 'react-html-parser';
import MuiTable from '../../../components/CustomComponents/MuiTable';
import headers from './utils/headerVariables';
import Label from '../../../components/Label';
import { fCurrency } from '../../../utils/formatNumber';
import { getCheckList, getFileMeta } from './utils/serviceCalls';
import CustomDialog from '../../../components/CustomComponents/CustomDialog';
import CheckListDialog from './utils/CheckListDialog';
import ProcessInfoBox from '../../../components/CustomComponents/CustomCard/ProcessInfoBox';
import { handleProjectEditable } from './utils/termMaker';
import fileMaker from './_utils/fileMaker';

function LandingPage(props) {
  const {
    handleAddNewProject,
    dispatch,
    projectList,
    editProject,
    deleteProject,
    guidelines,
    active_term_object
  } = props;
  const [addPayment, setAddPayment] = useState(false);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [dialogShow, setDialogShow] = useState(false);
  const [checkListData, setCheckListData] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const navigate = useNavigate();
  const isProjectAdd = handleProjectEditable(active_term_object);

  projectList.forEach((item) => {
    const _balance = Number(parseFloat(item.balance).toFixed(2));
    item.balanceComp =
      _balance < 0 || _balance === 0 ? (
        <Label variant="ghost" color="success">
          {fCurrency(item.balance)}
        </Label>
      ) : (
        <Label variant="ghost" color="error">
          {fCurrency(item.balance)}
        </Label>
      );
    item.balance_filter = _balance < 0 || _balance === 0 ? 'Paid' : 'Not Paid';
    item.project_student_comp = item.project_student_complete && (
      <Tooltip title={item.project_student_complete}>
        <p style={{ cursor: 'pointer' }}>{item.project_student} </p>
      </Tooltip>
    );
    item.file_comp = fileMaker(item.files, downloadFile);
    item.file_filter = item.project_file_id ? 'Exist' : 'None';
    item.media_link_comp = item.media_link && (
      <Label variant="ghost" color="primary">
        <a target="_blank" href={item.media_link} rel="noreferrer">
          Link
        </a>
      </Label>
    );
    item.media_link_filter = item.media_link ? 'Exist' : 'None';
    item.check_list = (
      <IconButton color="secondary" onClick={() => handleCheckList(item.id)}>
        <Icon icon="bi:card-checklist" width={24} height={24} />
      </IconButton>
    );
    item.edit = (
      <IconButton color="secondary" onClick={() => editProject(item.id, item.at_review)}>
        <Icon icon="ci:edit" width={24} height={24} />
      </IconButton>
    );
    item.delete = (
      <IconButton
        disabled={!isProjectAdd || item.at_review}
        color="secondary"
        onClick={() => deleteProject(item.id)}
      >
        <Icon icon="fluent:delete-48-filled" width={24} height={24} />
      </IconButton>
    );
    item.created_at_moment = moment(item.created_at).format('YYYY-MM-DD');
  });
  function downloadFile(file) {
    setDownloading(true);
    const file_name = file.name.split('.')[0];
    const param = {
      id: file.id,
      file_name
    };
    getFileMeta(
      param,
      () => {
        setDownloading(false);
      },
      () => {
        setDownloading(false);
      }
    );
  }
  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    let totalFee = parseFloat('0').toFixed(2);
    const list = [];
    allRowsSelected.forEach((item) => {
      totalFee = parseFloat(totalFee) + parseFloat(projectList[item.index].balance);
      list.push(projectList[item.index]);
    });
    setSelectedStudents(rowsSelected);
    dispatch({ type: 'ADD_FEE', payload: totalFee });
    dispatch({ type: 'ADD_ITEM_CART_FROM_PROJECTS', payload: list });
  };
  const handleCancel = () => {
    dispatch({ type: 'CLEAR_CART' });
    setSelectedStudents([]);
    setAddPayment(false);
  };
  function handleCheckList(project_id) {
    getCheckList(
      { project_id },
      (data) => {
        setCheckListData(data);
        setDialogShow(true);
      },
      () => {}
    );
  }

  const startButtonDisabled = selectedStudents.length === 0;

  function CustomHeader() {
    return (
      <Stack spacing={3} direction={{ sm: 'row' }}>
        {!addPayment && isProjectAdd && (
          <Button variant="outlined" onClick={handleAddNewProject}>
            Add New
          </Button>
        )}
        {!addPayment && projectList.length > 0 && (
          <Button
            startIcon={<Icon icon="ri:secure-payment-fill" width={22} height={22} />}
            color="secondary"
            variant="outlined"
            onClick={() => setAddPayment(true)}
          >
            Make Payment
          </Button>
        )}
        {addPayment && (
          <div>
            <Button
              color="secondary"
              variant="outlined"
              disabled={startButtonDisabled}
              onClick={() => {
                navigate('/dashboard/payments_for_projects', { replace: true });
              }}
            >
              Checkout
            </Button>
            <Button
              variant="outlined"
              color="error"
              style={{ marginLeft: 10 }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        )}
        {downloading && (
          <Stack
            spacing={1}
            direction={{ xs: 'column', sm: 'row' }}
            sx={{ mr: 1, display: 'flex', alignItems: 'center' }}
          >
            <p style={{ fontWeight: 'bold' }}>Downloading...</p>
            <CircularProgress size={20} />
          </Stack>
        )}
      </Stack>
    );
  }

  return (
    <Stack spacing={3}>
      {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
      <Grid item xs={12}>
        <MuiTable
          title={<CustomHeader />}
          tableColumns={headers}
          tableData={projectList}
          rowsPerPage={10}
          customOption={{
            selectableRows: addPayment ? 'multiple' : 'none',
            selectableRowsOnClick: true,
            onRowSelectionChange,
            rowsSelected: selectedStudents,
            isRowSelectable: (dataIndex) => projectList[dataIndex].balance !== '0.00'
          }}
        />
      </Grid>
      <CustomDialog onClose={() => setDialogShow(false)} open={dialogShow} title="CheckList">
        <DialogContent>
          <CheckListDialog data={checkListData} handleClose={() => setDialogShow(false)} />
        </DialogContent>
      </CustomDialog>
    </Stack>
  );
}

LandingPage.propTypes = {
  handleAddNewProject: PropTypes.func,
  dispatch: PropTypes.func,
  projectList: PropTypes.array,
  editProject: PropTypes.func,
  deleteProject: PropTypes.func,
  guidelines: PropTypes.array,
  active_term_object: PropTypes.object
};

export default connect((store) => ({
  active_term_object: store.loginReducer.active_term_object
}))(LandingPage);
