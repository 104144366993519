import { MenuItem, Stack, TextField } from '@material-ui/core';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@material-ui/lab';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { setLoading, setSnackbar } from '../../../../../actions/alertActions';
import { assignAllForGroup, getGroupSessionList } from '../../serviceCalls';

function AssignAllDialog(props) {
  const { handleClose, dispatch, refreshData } = props;
  const [sessionList, setSessionList] = useState([]);

  useEffect(() => {
    getGroupSessionList(
      {},
      (data) => {
        setSessionList(data);
      },
      () => {}
    );
  }, []);

  const Schema = Yup.object().shape({
    judging_session_id: Yup.number().required('Session is required')
  });
  const formik = useFormik({
    initialValues: {
      judging_session_id: ''
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      dispatch(setLoading(1, 'Loading...'));
      assignAllForGroup(
        values,
        () => {
          dispatch(setLoading(0, ''));
          refreshData();
          dispatch(setSnackbar('success', 'The group has been assigned'));
        },
        () => {
          dispatch(setLoading(0, ''));
        }
      );
    }
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            sx={{ mt: 1 }}
            fullWidth
            label="Session List"
            select
            {...getFieldProps('judging_session_id')}
            error={Boolean(touched.judging_session_id && errors.judging_session_id)}
            helperText={touched.judging_session_id && errors.judging_session_id}
          >
            {sessionList.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {`${item.judging_session_type}-${item.name}-${item.discipline}`}
              </MenuItem>
            ))}
          </TextField>
          <Stack direction="row" justifyContent="end" spacing={1}>
            <LoadingButton size="small" type="submit" variant="outlined">
              Assign
            </LoadingButton>
            <Button size="small" color="error" onClick={handleClose} variant="outlined">
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

AssignAllDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired
};

export default connect(() => ({}))(AssignAllDialog);
