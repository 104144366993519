import React, { useEffect, useState } from 'react';
import { IconButton, MenuItem, TextField } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { Stack, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import headers from './headerVariables';
import MuiTable from '../../../../components/CustomComponents/MuiTable';
import { getFairRequest, updateFairRequest } from '../serviceCalls';
import { setConfirmationAlert, setSnackbar } from '../../../../actions/alertActions';
import ProcessInfoBox from '../../../../components/CustomComponents/CustomCard/ProcessInfoBox';

function Index(props) {
  const { dispatch, terms, active_term } = props;
  const [term, setTerm] = useState(active_term);
  const [tableData, setTableData] = useState([]);
  const [guidelines, setGuidelines] = useState([]);

  useEffect(() => {
    fetchData();
  }, [term]);
  function fetchData() {
    getFairRequest(
      { term_id: term },
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((item) => {
          item.approve =
            item.fair_status_id === 2 ? (
              <IconButton color="success" disabled style={{ color: '#54D62C' }}>
                <Icon color="success" icon="akar-icons:circle-check" />
              </IconButton>
            ) : (
              <Tooltip title="Approve">
                <IconButton color="secondary" onClick={() => handleApprove(item.project_id)}>
                  <Icon icon="dashicons:update" />
                </IconButton>
              </Tooltip>
            );
          item.deny =
            item.fair_status_id === 3 ? (
              <IconButton color="error" disabled style={{ color: '#FF4842' }}>
                <Icon icon="akar-icons:circle-x" />
              </IconButton>
            ) : (
              <Tooltip title="Deny">
                <IconButton color="secondary" onClick={() => handleDeny(item.project_id)}>
                  <Icon icon="dashicons:update" />
                </IconButton>
              </Tooltip>
            );
        });
        setTableData(list);
      },
      () => {}
    );
  }
  function handleApprove(project_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, APPROVE',
        'This action will approve the request',
        () => {
          updateFairRequest(
            { project_id, project_fair_status_id: 2 },
            () => {
              fetchData();
              dispatch(setSnackbar('success', 'The fair status has been approved'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }
  function handleDeny(project_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DENY',
        'This action will deny the request',
        () => {
          updateFairRequest(
            { project_id, project_fair_status_id: 3 },
            () => {
              fetchData();
              dispatch(setSnackbar('success', 'The fair status has been denied'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }
  function TermSelect() {
    return (
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 2 }}>
        <TextField select label="Terms" value={term} onChange={(e) => setTerm(e.target.value)}>
          {terms.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
    );
  }

  return (
    <Stack spacing={2}>
      {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
      <MuiTable title={<TermSelect />} tableData={tableData} tableColumns={headers} />
    </Stack>
  );
}

Index.propTypes = {
  terms: PropTypes.array,
  active_term: PropTypes.number,
  dispatch: PropTypes.func
};

export default connect((store) => ({
  terms: store.loginReducer.terms,
  active_term: store.loginReducer.active_term
}))(Index);
