import { fetchApi } from '../../../api/fetchApi';

const scorekeeper = require('./apis');

export async function startCompetition(config, scb, ecb) {
  try {
    const url = scorekeeper.START_COMPETITION;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'START_COMPETITION');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addRubricCounts(config, scb, ecb) {
  try {
    const url = scorekeeper.ADD_RUBRIC_COUNTS;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_RUBRIC_COUNTS');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}
