import React from 'react';
import { DialogContent } from '@mui/material';
import PropTypes from 'prop-types';
import CustomDialog from '../../../../../../components/CustomComponents/CustomDialog';
import CustomMail from '../../../../../../components/CustomComponents/CustomMail';
import { CUSTOM_MAIL_TYPE } from '../../../../../../api/RestVariables/staticVariables';

function MailView(props) {
  const { mailDialogShow, setMailDialogShow, handleRefresh, idList } = props;

  return (
    <CustomDialog
      onClose={() => setMailDialogShow(false)}
      open={mailDialogShow}
      maxWidth="lg"
      title="Send Mail"
      fullWidth
      disableEnforceFocus
    >
      <DialogContent>
        <CustomMail
          handleRefresh={handleRefresh}
          mailType={CUSTOM_MAIL_TYPE}
          idList={idList}
          handleClose={() => setMailDialogShow(false)}
        />
      </DialogContent>
    </CustomDialog>
  );
}

MailView.propTypes = {
  mailDialogShow: PropTypes.bool,
  setMailDialogShow: PropTypes.func,
  handleRefresh: PropTypes.func,
  idList: PropTypes.array
};

export default MailView;
