export default [
  {
    name: 'edit',
    label: 'Edit',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'delete',
    label: 'Delete',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'id',
    label: 'ID'
  },
  {
    name: 'name',
    label: 'Name'
  },
  {
    name: 'country',
    label: 'Country'
  },
  {
    name: 'fair_discipline',
    label: 'Discipline'
  },
  {
    name: 'email',
    label: 'Email'
  },
  {
    name: 'phone_number',
    label: 'Phone Number'
  },
  {
    name: 'start_year',
    label: 'Start Year'
  },
  {
    name: 'term_id',
    label: 'Term'
  },
  {
    name: 'fair_approval_status',
    label: 'Approval Status'
  },
  {
    name: 'capacity',
    label: 'Capacity'
  },
  {
    name: 'profile',
    label: 'Profile',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  }
];
