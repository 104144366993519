export default [
  {
    name: 'jfk_bus_comp',
    label: 'Jfk Bus',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'jfk_bus_filter',
    label: 'Jfk Bus',
    options: {
      display: false
    }
  },
  {
    name: 'balance_comp',
    label: 'Balance',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'balance_filter',
    label: 'Balance',
    options: {
      display: false
    }
  },
  {
    name: 'edit',
    label: 'Edit',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'delete',
    label: 'Delete',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'participant_id',
    label: 'ID'
  },
  {
    name: 'name',
    label: 'Name'
  },
  {
    name: 'country',
    label: 'Country'
  },
  {
    name: 'school_name',
    label: 'School'
  },
  {
    name: 'gender',
    label: 'Gender'
  },
  {
    name: 'date_of_birth_comp',
    label: 'DOB'
  },
  {
    name: 'participant_type',
    label: 'Type'
  },
  {
    name: 'main_contact_name',
    label: 'Contact Name'
  },
  {
    name: 'main_contact_email',
    label: 'Contact Email'
  },
  {
    name: 'trip_name',
    label: 'Trip Name'
  }
];
