export default [
  {
    name: 'edit',
    label: 'Add/Edit',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'delete',
    label: 'Delete',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'presentation_link_comp',
    label: 'Presentation Link',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'presentation_link_filter',
    label: 'Presentation Link',
    options: {
      display: false
    }
  },
  {
    name: 'project_id',
    label: 'ID'
  },
  {
    name: 'title',
    label: 'Title'
  },
  {
    name: 'discipline',
    label: 'Discipline'
  },
  {
    name: 'category',
    label: 'Category'
  },
  {
    name: 'project_student_complete',
    label: 'Students'
  }
];
