export function setSnackbar(type, message) {
  return (dispatch) => {
    dispatch({
      type: 'SET_SNACKBAR',
      payload: { type, message }
    });
  };
}

export function setSnackbarForMain(type, message) {
  return (dispatch) => {
    dispatch({
      type: 'SET_SNACKBAR_MAIN',
      payload: { type, message }
    });
  };
}

export function setConfirmationAlert(title, confirmBtnText, message, onConfirm) {
  return (dispatch) => {
    dispatch({
      type: 'SET_CONFIRMATION_ALERT',
      payload: { title, confirmBtnText, message, onConfirm }
    });
  };
}

export function setLoading(loadingStatus, loadingText) {
  return (dispatch) => {
    dispatch({ type: 'SET_LOADING', payload: { loadingStatus, loadingText } });
  };
}
