import { createTheme } from '@material-ui/core/styles';

const getMuiTheme = () =>
  createTheme({
    components: {
      MUIDataTableToolbar: {
        styleOverrides: {
          actions: {
            display: 'flex',
            flex: 'initial',
            // move all icons to the right
            '& > span, & > button': {
              order: 99
            }
          }
        }
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            textAlign: 'center',
            justifyContent: 'center',
            fontSize: 14,
            overflow: 'hidden',
            marginRight: 0,
            marginLeft: 0,
            paddingTop: 3,
            paddingBottom: 0,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }
        }
      },
      MUIDataTableBodyRow: {
        styleOverrides: {
          root: {
            '&:nth-child(odd)': {
              backgroundColor: '#F4F6F8'
            }
          }
        }
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            textAlign: 'center',
            justifyContent: 'center',
            whiteSpace: 'nowrap',
            fontSize: 15,
            height: 30,
            marginRight: 0,
            marginLeft: 0,
            paddingTop: 5,
            paddingBottom: 5
          },
          toolButton: {
            justifyContent: 'center'
          },
          contentWrapper: {
            display: 'contents'
          }
        }
      },
      MUIDataTableFilterList: {
        root: {
          marginBottom: 5
        }
      }
    }
  });

export default getMuiTheme();
