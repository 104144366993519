import { Tooltip } from '@mui/material';
import moment from 'moment/moment';
import React from 'react';
import { Icon } from '@iconify/react';
import { IconButton } from '@material-ui/core';
import { fCurrency } from '../../../../utils/formatNumber';
import Label from '../../../../components/Label';

export function dataMakerForProject(data) {
  const list = data.project_payment_list;
  list.forEach((item) => {
    const _balance = Number(parseFloat(item.balance).toFixed(2));
    item.balanceComp =
      _balance < 0 || _balance === 0 ? (
        <Label variant="ghost" color="success">
          {fCurrency(item.balance)}
        </Label>
      ) : (
        <Label variant="ghost" color="error">
          {fCurrency(item.balance)}
        </Label>
      );
    item.balance_filter = _balance < 0 || _balance === 0 ? 'Paid' : 'Not Paid';
    item.project_student_comp = item.project_student_complete && (
      <Tooltip title={item.project_student_complete}>
        <p style={{ cursor: 'pointer' }}>{item.project_student} </p>
      </Tooltip>
    );
    item.file_filter = item.project_file_id ? 'Exist' : 'None';
    item.media_link_comp = item.media_link && (
      <Label variant="ghost" color="primary">
        <a target="_blank" href={item.media_link} rel="noreferrer">
          Link
        </a>
      </Label>
    );
    item.media_link_filter = item.media_link ? 'Exist' : 'None';
    item.created_at_moment = moment(item.created_at).format('YYYY-MM-DD');
  });
  return list;
}

export function dataMakerForParticipant(data) {
  const list = data.participant_payment_list;
  list.forEach((item) => {
    const _balance = Number(parseFloat(item.balance).toFixed(2));
    item.project_titles_filter = item.project_titles;
    item.project_ids_filter = item.project_ids;
    item.balance_comp =
      _balance < 0 || _balance === 0 ? (
        <Label variant="ghost" color="success">
          {fCurrency(item.balance)}
        </Label>
      ) : (
        <Label variant="ghost" color="error">
          {fCurrency(item.balance)}
        </Label>
      );
    item.balance_filter = _balance < 0 || _balance === 0 ? 'Paid' : 'Not Paid';
    item.name = `${item.first_name} ${item.last_name}`;
    item.date_of_birth_comp = moment(item.date_of_birth).format('YYYY-MM-DD');
    item.staying_at_hotel_comp = item.staying_at_hotel ? 'Yes' : 'No';
    item.is_confirmed_comp = item.is_confirmed ? (
      <IconButton color="success" style={{ cursor: 'default' }}>
        <Icon icon="akar-icons:circle-check" width={24} height={24} />
      </IconButton>
    ) : (
      <IconButton color="error" style={{ cursor: 'default' }}>
        <Icon color="error" icon="akar-icons:circle-x" width={24} height={24} />
      </IconButton>
    );
    item.project_titles = item.participant_type_id === 1 ? item.project_titles : '-';
    item.project_ids = item.participant_type_id === 1 ? item.project_ids : '-';
  });
  return list;
}

export function dataMakerForTrip(data) {
  const list = data.trip_payment_list;
  list.forEach((item) => {
    const _balance = Number(parseFloat(item.trip_balance).toFixed(2));
    item.name = `${item.first_name} ${item.last_name}`;
    item.balanceComp =
      _balance < 0 || _balance === 0 || item.trip_balance === null ? (
        <Label variant="ghost" color="success">
          {fCurrency(item.trip_balance)}
        </Label>
      ) : (
        <Label variant="ghost" color="error">
          {fCurrency(item.trip_balance)}
        </Label>
      );
    item.balance_filter = _balance < 0 || _balance === 0 ? 'Paid' : 'Not Paid';
    item.date_of_birth_comp = moment(item.date_of_birth).format('YYYY-MM-DD');
    item.jfk_filter = item.jfk_bus ? 'Yes' : 'No';
    item.jfk_bus_comp = item.jfk_bus ? (
      <IconButton color="success" style={{ cursor: 'default' }}>
        <Icon icon="akar-icons:circle-check" width={24} height={24} />
      </IconButton>
    ) : (
      <IconButton color="error" style={{ cursor: 'default' }}>
        <Icon color="error" icon="akar-icons:circle-x" width={24} height={24} />
      </IconButton>
    );
  });
  return list;
}
