export default [
  {
    name: 'id',
    label: 'ID'
  },
  {
    name: 'first_name',
    label: 'Name'
  },
  {
    name: 'last_name',
    label: 'Last Name'
  },
  {
    name: 'school_name',
    label: 'School'
  },
  {
    name: 'grade_level',
    label: 'Grade'
  },
  {
    name: 'project_title',
    label: 'Project'
  },
  {
    name: 'gender',
    label: 'Gender'
  },
  {
    name: 'date_of_birth',
    label: 'DOB'
  },
  {
    name: 'email',
    label: 'Email'
  }
];
