export default [
  {
    name: 'id',
    label: 'ID'
  },
  {
    name: 'name',
    label: 'Name'
  },
  {
    name: 'country',
    label: 'Country'
  },
  {
    name: 'state',
    label: 'State'
  },
  {
    name: 'city',
    label: 'City'
  },
  {
    name: 'phone_number',
    label: 'Phone Number'
  },
  {
    name: 'main_contact_name',
    label: 'Contact Name'
  },
  {
    name: 'main_contact_email',
    label: 'Contact Email'
  },
  {
    name: 'recover',
    label: 'Recover',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  }
];
