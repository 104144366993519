import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import QuestionViewDetail from './QuestionViewDetail';
import { getSurveyDetail } from '../../../_apis/serviceCalls';

function CopyDialog(props) {
  const { data } = props;
  const [questionList, setQuestionList] = useState(null);

  useEffect(() => {
    getSurveyDetail(
      { survey_id: data.survey_id },
      (data) => {
        const _questions = data.questions;
        _questions.sort((a, b) => a.rank - b.rank);
        setQuestionList(_questions);
      },
      () => {}
    );
  }, []);

  return questionList ? (
    <Stack spacing={1}>
      {questionList.map((question, index) => (
        <QuestionViewDetail question={question} key={index} />
      ))}
    </Stack>
  ) : (
    <div />
  );
}

CopyDialog.propTypes = {
  data: PropTypes.object
};

export default connect(() => ({}))(CopyDialog);
