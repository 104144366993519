import moment from 'moment/moment';
import { IconButton } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { Tooltip } from '@mui/material';
import React from 'react';
import Label from '../../../../../components/Label';
import { fCurrency } from '../../../../../utils/formatNumber';
import { handleProjectEditable } from '../../../Projects/utils/termMaker';

export function dataMaker(
  list,
  handleDownload,
  handleEditParticipant,
  handleDeleteParticipant,
  handleSign,
  active_term_object
) {
  handleProjectEditable(active_term_object);
  list.forEach((item) => {
    const _balance = Number(parseFloat(item.balance).toFixed(2));
    item.project_titles_filter = item.project_titles;
    item.project_ids_filter = item.project_ids;
    item.balance_comp =
      _balance < 0 || _balance === 0 ? (
        <Label variant="ghost" color="success">
          {fCurrency(item.balance)}
        </Label>
      ) : (
        <Label variant="ghost" color="error">
          {fCurrency(item.balance)}
        </Label>
      );
    item.balance_filter = _balance < 0 || _balance === 0 ? 'Paid' : 'Not Paid';
    item.name = `${item.first_name} ${item.last_name}`;
    item.date_of_birth_comp = moment(item.date_of_birth).format('YYYY-MM-DD');
    item.staying_at_hotel_comp = item.staying_at_hotel ? 'Yes' : 'No';
    item.is_confirmed_comp = item.is_confirmed ? (
      <IconButton color="success" style={{ cursor: 'default' }}>
        <Icon icon="akar-icons:circle-check" width={24} height={24} />
      </IconButton>
    ) : (
      <IconButton color="error" style={{ cursor: 'default' }}>
        <Icon color="error" icon="akar-icons:circle-x" width={24} height={24} />
      </IconButton>
    );
    item.certificate = (
      <IconButton
        color="secondary"
        disabled={!item.participation_certificate_id}
        onClick={() => handleDownload(item.participation_certificate_id)}
      >
        <Icon icon="akar-icons:cloud-download" width={24} height={24} />
      </IconButton>
    );
    item.signature = item.participant_consent_form ? (
      <Tooltip title="Completed">
        <Icon
          style={{ cursor: 'default', color: 'green' }}
          icon="akar-icons:circle-check"
          width={24}
          height={24}
        />
      </Tooltip>
    ) : (
      <IconButton
        color="secondary"
        disabled={item.participant_consent_form}
        onClick={() => handleSign(item)}
      >
        <Icon icon="fluent:signature-20-regular" width={24} height={24} />
      </IconButton>
    );
    item.medal = (
      <IconButton
        color="secondary"
        disabled={!item.medal_certificate_id}
        onClick={() => handleDownload(item.medal_certificate_id)}
      >
        <Icon icon="akar-icons:cloud-download" width={24} height={24} />
      </IconButton>
    );
    item.edit = (
      <IconButton color="secondary" onClick={() => handleEditParticipant(item)}>
        <Icon icon="ci:edit" width={24} height={24} />
      </IconButton>
    );
    item.delete = (
      <IconButton color="secondary" onClick={() => handleDeleteParticipant(item.id)}>
        <Icon icon="fluent:delete-48-filled" width={24} height={24} />
      </IconButton>
    );
  });
  return list;
}
