const HOST = process.env.REACT_APP_REST_API_HOST;

const GET_DOCUMENT_LIST = {
  method: 'GET',
  path: `${HOST}go_shared/document/document/list`
};
const GET_DOCUMENT = {
  method: 'GET',
  path: `${HOST}go_shared/document/document`
};

module.exports = {
  GET_DOCUMENT_LIST,
  GET_DOCUMENT
};
