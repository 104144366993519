import React, { useEffect, useState } from 'react';
import { IconButton, MenuItem, Stack, TextField } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, DialogContent, Tooltip } from '@mui/material';
import Email from '@mui/icons-material/Email';
import ReactHtmlParser from 'react-html-parser';
import MuiTable from '../../../../components/CustomComponents/MuiTable';
import headers from './utils/headerVariables';
import { addReviewAnnounce, getReviewDetail, getReviewer, getReviewList } from '../serviceCalls';
import Label from '../../../../components/Label';
import { fCurrency } from '../../../../utils/formatNumber';
import CustomDialog from '../../../../components/CustomComponents/CustomDialog';
import AddReviewerDialog from './utils/AddReviewerDialog';
import ReviewDetailDialog from './utils/ReviewDetailDialog';
import StatusDialog from './utils/StatusDialog';
import StatusDialogForFinalist from '../../ProjectOperation/Info/utils/StatusDialog';
import { setConfirmationAlert, setSnackbar } from '../../../../actions/alertActions';
import CustomMail from '../../../../components/CustomComponents/CustomMail';
import { CUSTOM_MAIL_TYPE } from '../../../../api/RestVariables/staticVariables';
import Page from '../../../../components/Page';
import ViewFileDialog from './Components/ViewFileDialog';
import ProcessInfoBox from '../../../../components/CustomComponents/CustomCard/ProcessInfoBox';
import fileMaker from '../../../Application/Projects/_utils/fileMaker';
import { checkListMaker } from '../../ProjectOperation/Info/_utils/dataMaker';

function Index(props) {
  const { terms, active_term, dispatch, isCommunication } = props;
  const [term, setTerm] = useState(active_term);
  const [tableData, setTableData] = useState([]);
  const [reviewerList, setReviewerList] = useState([]);
  const [addAssign, setAddAssign] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [addStatus, setAddStatus] = useState(false);
  const [assignDialog, setAssignDialog] = useState(false);
  const [statusDialog, setStatusDialog] = useState(false);
  const [reviewDialog, setReviewDialog] = useState(false);
  const [reviewDetail, setReviewDetail] = useState(null);
  const [finalistDialogShow, setFinalistDialogShow] = useState(false);
  const [editData, setEditData] = useState(null);
  const [mailDialogShow, setMailDialogShow] = useState(false);
  const [addMail, setAddMail] = useState(false);
  const [idList, setIdList] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileDialog, setFileDialog] = useState(false);
  const [guidelines, setGuidelines] = useState([]);

  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedItems(rowsSelected);
    const arr = [];
    rowsSelected.forEach((item) => {
      arr.push(tableData[item].id);
    });
    setIdList(arr);
  };
  const handleCancel = () => {
    setSelectedItems([]);
    setAddAssign(false);
    setAddStatus(false);
  };

  useEffect(() => {
    fetchData();
  }, [term]);
  function fetchData() {
    getReviewer(
      {},
      (data) => {
        setReviewerList(data);
      },
      () => {}
    );
    getReviewList(
      { term_id: term },
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((item, index) => {
          const _balance = Number(parseFloat(item.balance).toFixed(2));
          item.balance_comp =
            _balance < 0 || _balance === 0 ? (
              <Label variant="ghost" color="success">
                {fCurrency(item.balance)}
              </Label>
            ) : (
              <Label variant="ghost" color="error">
                {fCurrency(item.balance)}
              </Label>
            );
          item.balance_filter = _balance < 0 || _balance === 0 ? 'Paid' : 'Not Paid';
          item.presentation_link_comp = item.presentation_link && (
            <Label variant="ghost" color="primary">
              <a target="_blank" href={item.presentation_link} rel="noreferrer">
                Link
              </a>
            </Label>
          );
          item.finalist_status_comp = (
            <IconButton color="secondary" onClick={() => handleChangeFinalistStatus(item)}>
              <Icon icon="ic:twotone-published-with-changes" width={24} height={24} />
            </IconButton>
          );
          item.review_status_comp = (
            <IconButton color="secondary" onClick={() => handleChangeReviewStatus(index)}>
              <Icon icon="ic:twotone-published-with-changes" width={24} height={24} />
            </IconButton>
          );
          item.presentation_link_filter = item.presentation_link ? 'Exist' : 'None';
          item.file_comp = fileMaker(item.files, viewFile);
          item.file_filter = item.project_file_id ? 'Exist' : 'None';
          item.file_check_list = checkListMaker(item.files);
          item.file_check_list_filter = checkListMaker(item.files) ? 'Exist' : 'None';
          item.media_link_comp = item.media_link && (
            <Label variant="ghost" color="primary">
              <a target="_blank" href={item.media_link} rel="noreferrer">
                Link
              </a>
            </Label>
          );
          item.media_link_filter = item.media_link ? 'Exist' : 'None';
          item.review_detail = (
            <IconButton color="secondary" onClick={() => handleReviewProject(item.id)}>
              <Icon icon="bx:bx-comment-detail" width={24} height={24} />
            </IconButton>
          );
          item.created_at_moment = moment(item.created_at).format('YYYY-MM-DD');
        });
        setTableData(list);
      },
      () => {}
    );
  }
  function handleReviewProject(project_id) {
    getReviewDetail(
      { project_id },
      (data) => {
        setReviewDetail(data);
        setReviewDialog(true);
      },
      () => {}
    );
  }
  function announceFinalist() {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, ANNOUNCE',
        'This request will update the finalist status of all projects. Please make sure you have completed the project reviews and set the review status fields properly.',
        () => {
          addReviewAnnounce(
            {},
            () => {
              dispatch(setSnackbar('success', 'The finalist projects have been announced'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
              handleRefresh();
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }
  function handleRefresh() {
    setAssignDialog(false);
    setStatusDialog(false);
    setReviewDialog(false);
    setAddStatus(false);
    setAddAssign(false);
    setSelectedItems([]);
    setFinalistDialogShow(false);
    setEditData(null);
    setMailDialogShow(false);
    setAddMail(false);
    setSelectedItems([]);
    fetchData();
  }
  function viewFile(file) {
    const obj = {
      id: file.id,
      name: file.name
    };
    setSelectedFile(obj);
    setFileDialog(true);
  }
  function TermSelect() {
    return (
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 2 }}>
        <TextField select label="Terms" value={term} onChange={(e) => setTerm(e.target.value)}>
          {terms.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
        {!addMail && (
          <div className="header-button-style">
            <Stack spacing={2} direction={{ sm: 'row' }}>
              {!addAssign && !addStatus && (
                <Button color="secondary" variant="outlined" onClick={() => setAddAssign(true)}>
                  Assign Reviewer
                </Button>
              )}
              {!addStatus && !addAssign && (
                <Button color="secondary" variant="outlined" onClick={() => setAddStatus(true)}>
                  Status
                </Button>
              )}
              {!addStatus && !addAssign && (
                <Button color="primary" variant="outlined" onClick={announceFinalist}>
                  Announce Finalist
                </Button>
              )}
              {addAssign && (
                <div>
                  <Button
                    color="secondary"
                    variant="outlined"
                    disabled={startButtonDisabled}
                    onClick={() => setAssignDialog(true)}
                  >
                    Next
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    style={{ marginLeft: 10 }}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </div>
              )}
              {addStatus && (
                <div>
                  <Button
                    color="secondary"
                    variant="outlined"
                    disabled={startButtonDisabled}
                    onClick={() => setStatusDialog(true)}
                  >
                    Next
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    style={{ marginLeft: 10 }}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </div>
              )}
            </Stack>
          </div>
        )}
        {addMail && (
          <div className="header-button-style">
            <Button
              style={{ marginRight: 5 }}
              disabled={selectedItems.length === 0}
              variant="outlined"
              onClick={() => setMailDialogShow(true)}
            >
              Next
            </Button>
            <Button color="error" variant="outlined" onClick={() => setAddMail(false)}>
              Cancel
            </Button>
          </div>
        )}
      </Stack>
    );
  }
  function handleChangeFinalistStatus(project) {
    setFinalistDialogShow(true);
    setEditData(project);
  }
  function handleChangeReviewStatus(index) {
    setStatusDialog(true);
    setSelectedItems([index]);
  }

  const startButtonDisabled = selectedItems.length === 0;

  return (
    <Page title="Review | Genius">
      {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
      <MuiTable
        tableData={tableData}
        tableColumns={headers}
        title={<TermSelect />}
        customOption={{
          selectableRows: addAssign || addStatus || addMail ? 'multiple' : 'none',
          onRowSelectionChange,
          rowsSelected: selectedItems,
          customToolbar: () =>
            isCommunication !== -1 && (
              <Tooltip title="Send Email">
                <IconButton onClick={() => setAddMail(true)}>
                  <Email />
                </IconButton>
              </Tooltip>
            )
        }}
      />
      <CustomDialog
        onClose={() => setAssignDialog(false)}
        open={assignDialog}
        maxWidth="lg"
        title="Assign Reviewer"
      >
        <DialogContent>
          <AddReviewerDialog
            tableData={tableData}
            handleClose={() => setAssignDialog(false)}
            reviewerList={reviewerList}
            selectedItems={selectedItems}
            handleRefresh={handleRefresh}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        onClose={() => setStatusDialog(false)}
        open={statusDialog}
        maxWidth="lg"
        title="Review Status"
      >
        <DialogContent>
          <StatusDialog
            tableData={tableData}
            handleClose={() => setStatusDialog(false)}
            selectedItems={selectedItems}
            handleRefresh={handleRefresh}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        onClose={() => setReviewDialog(false)}
        open={reviewDialog}
        maxWidth="lg"
        title="Review Detail"
      >
        <DialogContent>
          <ReviewDetailDialog data={reviewDetail} handleClose={() => setReviewDialog(false)} />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        onClose={() => setFinalistDialogShow(false)}
        open={finalistDialogShow}
        maxWidth="md"
        title="Finalist Status"
      >
        <DialogContent>
          <StatusDialogForFinalist
            data={editData}
            handleClose={() => setFinalistDialogShow(false)}
            handleRefresh={handleRefresh}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        onClose={() => setMailDialogShow(false)}
        open={mailDialogShow}
        maxWidth="lg"
        title="Send Mail"
        fullWidth
        disableEnforceFocus
      >
        <DialogContent>
          <CustomMail
            handleRefresh={handleRefresh}
            mailType={CUSTOM_MAIL_TYPE}
            idList={idList}
            handleClose={() => setMailDialogShow(false)}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        maxWidth="lg"
        fullScreen
        onClose={() => setFileDialog(false)}
        open={fileDialog}
        title="File"
      >
        <DialogContent>
          <ViewFileDialog handleClose={() => setFileDialog(false)} file={selectedFile} />
        </DialogContent>
      </CustomDialog>
    </Page>
  );
}

Index.propTypes = {
  terms: PropTypes.array,
  active_term: PropTypes.number,
  dispatch: PropTypes.func,
  isCommunication: PropTypes.number
};

export default connect((store) => ({
  terms: store.loginReducer.terms,
  active_term: store.loginReducer.active_term,
  isCommunication: store.routeReducer.isCommunication
}))(Index);
