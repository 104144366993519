import { Box, Container, Typography } from '@material-ui/core';
import { Button, Stack } from '@mui/material';
import * as React from 'react';
import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import Page from '../../../components/Page';
import LandingPage from './LandingPage';
import AddPage from './AddPage';
import { fetchSchoolInfo } from './utils/serviceCalls';
import ProcessInfoBox from '../../../components/CustomComponents/CustomCard/ProcessInfoBox';
// ----------------------------------------------------------------------

const LANDING_PAGE = 0;
const ADD_SCHOOL = 1;
const EDIT_SCHOOL = 2;

function Index() {
  const [mode, setMode] = useState(LANDING_PAGE);
  const [schoolList, setSchoolList] = useState([]);
  const [schoolDataForEdit, setSchoolDataForEdit] = useState(null);
  const [guidelines, setGuidelines] = useState([]);

  useEffect(() => {
    getList();
  }, []);

  function handleRefresh() {
    setMode(LANDING_PAGE);
    getList();
  }

  function handleEditSchool(school) {
    setMode(EDIT_SCHOOL);
    setSchoolDataForEdit(school);
  }

  function getList() {
    fetchSchoolInfo(
      {},
      (data) => {
        setSchoolList(data.data);
        setGuidelines(data.guidelines);
      },
      () => {}
    );
  }

  return (
    <Page title="School | Genius">
      <Container maxWidth="xl">
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h4">
            School {/* eslint-disable-next-line no-nested-ternary */}
            {mode === ADD_SCHOOL
              ? ' / Add New School'
              : mode === EDIT_SCHOOL
              ? ' / Edit School'
              : null}
          </Typography>
          {mode !== LANDING_PAGE && (
            <Button
              startIcon={<Icon icon="akar-icons:arrow-back-thick-fill" />}
              variant="outlined"
              onClick={() => setMode(LANDING_PAGE)}
            >
              Back
            </Button>
          )}
        </Box>
        {mode === LANDING_PAGE ? (
          <Stack spacing={2}>
            {guidelines.length > 0 && (
              <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />
            )}
            <LandingPage
              handleAddSchool={() => setMode(ADD_SCHOOL)}
              handleEditSchool={handleEditSchool}
              schoolList={schoolList}
              refreshData={getList}
            />
          </Stack>
        ) : (
          <AddPage handleBack={handleRefresh} mode={mode} data={schoolDataForEdit} />
        )}
      </Container>
    </Page>
  );
}

export default Index;
