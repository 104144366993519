import { DialogContent } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import CustomDialog from '../../../../../components/CustomComponents/CustomDialog';
import SettingDialog from './SettingDialog';

function Index(props) {
  const { setSettingDialog, settingDialog, terms, term, handleSuccess } = props;

  return (
    <CustomDialog
      onClose={() => setSettingDialog(false)}
      open={settingDialog}
      maxWidth="md"
      title="Setting"
    >
      <DialogContent>
        <SettingDialog
          data={terms.find((x) => x.id === term)}
          handleClose={() => setSettingDialog(false)}
          handleSuccess={handleSuccess}
        />
      </DialogContent>
    </CustomDialog>
  );
}

Index.propTypes = {
  terms: PropTypes.array,
  setSettingDialog: PropTypes.func,
  settingDialog: PropTypes.bool,
  term: PropTypes.number,
  handleSuccess: PropTypes.func
};

export default Index;
