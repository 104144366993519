const HOST = process.env.REACT_APP_REST_API_HOST;

const GET_COUNTRY_LIST = {
  method: 'GET',
  path: `${HOST}go_shared/general/country`
};
const GET_STATE_LIST = {
  method: 'GET',
  path: `${HOST}go_shared/general/state`
};
const GET_TERMS = {
  method: 'GET',
  path: `${HOST}go_shared/general/term`
};

module.exports = {
  GET_COUNTRY_LIST,
  GET_STATE_LIST,
  GET_TERMS
};
