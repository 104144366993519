import { DialogContent } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import CustomDialog from '../../../../../components/CustomComponents/CustomDialog';
import IncludeDialog from './IncludeDialog';

function Index(props) {
  const { setIncludeDialog, includeDialog, handleRefresh } = props;

  return (
    <CustomDialog
      onClose={() => setIncludeDialog(false)}
      open={includeDialog}
      maxWidth="md"
      title="Include Review"
    >
      <DialogContent>
        <IncludeDialog handleSuccess={handleRefresh} handleClose={() => setIncludeDialog(false)} />
      </DialogContent>
    </CustomDialog>
  );
}

Index.propTypes = {
  handleRefresh: PropTypes.func,
  setIncludeDialog: PropTypes.func,
  includeDialog: PropTypes.bool
};

export default Index;
