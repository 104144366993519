import React, { useEffect, useState } from 'react';
import { IconButton } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, DialogContent, Stack, Tooltip } from '@mui/material';
import ReactHtmlParser from 'react-html-parser';
import MuiTable from '../../../components/CustomComponents/MuiTable';
import headers from './utils/headerVariables';
import { getFairAdminRequest, patchFairAdminRequest } from './serviceCalls';
import { setConfirmationAlert, setSnackbar } from '../../../actions/alertActions';
import Page from '../../../components/Page';
import ProcessInfoBox from '../../../components/CustomComponents/CustomCard/ProcessInfoBox';
import DisciplineDialog from './utils/DisciplineDialog';
import CustomDialog from '../../../components/CustomComponents/CustomDialog';

function Index(props) {
  const { dispatch } = props;
  const [tableData, setTableData] = useState([]);
  const [disciplines, setDisciplines] = useState([]);
  const [guidelines, setGuidelines] = useState([]);
  const [dialog, setDialog] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);
  function fetchData() {
    getFairAdminRequest(
      {},
      (data) => {
        const list = data.data;
        const { requests, fair_disciplines } = list;
        setGuidelines(data.guidelines);
        setDisciplines(fair_disciplines);
        requests.forEach((item) => {
          item.capacity = fair_disciplines.find(
            (x) => x.discipline_id === item.discipline_id
          ).capacity;
          item.approve =
            item.fair_status_id === 2 ? (
              <IconButton color="success">
                <Icon icon="akar-icons:circle-check" />
              </IconButton>
            ) : (
              <Tooltip title="Approve">
                <IconButton color="secondary" onClick={() => handleApprove(item)}>
                  <Icon icon="dashicons:update" />
                </IconButton>
              </Tooltip>
            );
          item.deny =
            item.fair_status_id === 3 ? (
              <IconButton color="error">
                <Icon icon="akar-icons:circle-x" />
              </IconButton>
            ) : (
              <Tooltip title="Deny">
                <IconButton color="secondary" onClick={() => handleDeny(item)}>
                  <Icon icon="dashicons:update" />
                </IconButton>
              </Tooltip>
            );
        });
        setTableData(requests);
      },
      () => {}
    );
  }
  function handleApprove(item) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, APPROVE',
        'This action will approve the request',
        () => {
          patchFairAdminRequest(
            { project_id: item.project_id, project_fair_status_id: 2 },
            () => {
              fetchData();
              dispatch(setSnackbar('success', 'The request has been approved'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }
  function handleDeny(item) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DENY',
        'This action will deny the request',
        () => {
          patchFairAdminRequest(
            { project_id: item.project_id, project_fair_status_id: 3 },
            () => {
              fetchData();
              dispatch(setSnackbar('success', 'The request has been denied'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }

  return (
    <Page title="Fair | Genius">
      <Stack spacing={1}>
        {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
        <MuiTable
          title={
            <Button onClick={() => setDialog(true)} variant="outlined">
              Capacity
            </Button>
          }
          tableData={tableData}
          tableColumns={headers}
        />
      </Stack>
      <CustomDialog onClose={() => setDialog(false)} open={dialog} maxWidth="md" title="Capacity">
        <DialogContent>
          <DisciplineDialog data={disciplines} handleClose={() => setDialog(false)} />
        </DialogContent>
      </CustomDialog>
    </Page>
  );
}

Index.propTypes = {
  dispatch: PropTypes.func.isRequired
};

export default connect(() => ({}))(Index);
