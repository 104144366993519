import PropTypes from 'prop-types';
import { Box, Card, Typography, Stack, IconButton } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Button, DialogContent } from '@mui/material';
import { Link as RouterLink, Link } from 'react-router-dom';
import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import Label from '../../components/Label';
import CustomDialog from '../../components/CustomComponents/CustomDialog';

// ----------------------------------------------------------------------

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

// ----------------------------------------------------------------------

CustomCard.propTypes = {
  product: PropTypes.object
};

export default function CustomCard({ product }) {
  const { id, name, cover, status, content } = product;
  let linkUrl;
  if (id === 1) {
    linkUrl = '/judge_registration';
  } else if (id === 2) {
    linkUrl = '/fair_registration';
  } else {
    linkUrl = '/general_user_registration';
  }
  const [isDialogShow, setIsDialogShow] = useState(false);

  return (
    <Card>
      <Box sx={{ pt: '65%', position: 'relative' }} className="main_cards">
        {status && (
          <Label
            cst
            variant="filled"
            color="info"
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase'
            }}
          >
            <p style={{ fontWeight: 'bold' }}>{status}</p>
            &nbsp;
            <p style={{ fontWeight: 'italic' }}>Registration</p>
          </Label>
        )}
        <ProductImgStyle
          className="main_pic"
          alt={name}
          src={cover}
          onClick={() => setIsDialogShow(true)}
        />
        <div className="main_cards_icon">
          <IconButton style={{ color: '#ffffff' }} onClick={() => setIsDialogShow(true)}>
            <Icon icon="bx:bx-plus-medical" width={64} height={64} />
          </IconButton>
        </div>
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Link to={linkUrl} component={RouterLink}>
            <Button variant="outlined"> {status} Registration </Button>
          </Link>
        </Stack>
      </Stack>
      <CustomDialog onClose={() => setIsDialogShow(false)} open={isDialogShow} title={status}>
        <DialogContent>
          <Typography
            variant="subtitle2"
            style={{ paddingRight: 10, paddingLeft: 10, textAlign: 'justify' }}
          >
            {content}
          </Typography>
        </DialogContent>
      </CustomDialog>
    </Card>
  );
}
