import { fetchApi } from '../api/fetchApi';

const reg = require('../api/RestVariables/Shield/registration');
const shared = require('../api/RestVariables/Shield/shared');

export default function handleRegister(params, scb, ecb) {
  try {
    const url = reg.REGISTER;
    fetchApi(
      url,
      params,
      (data) => {
        scb();
        console.log(data, 'data');
      },
      (error) => {
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
    ecb(error);
  }
}

export async function fairRegister(params, scb, ecb) {
  try {
    const url = reg.FAIR_REGISTER;
    fetchApi(
      url,
      params,
      (data) => {
        scb();
        console.log(data, 'data');
      },
      (error) => {
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
    ecb(error);
  }
}

export async function judgeRegister(params, scb, ecb) {
  try {
    const url = reg.JUDGE_REGISTER;
    fetchApi(
      url,
      params,
      (data) => {
        scb();
        console.log(data, 'JUDGE_REGISTER');
      },
      (error) => {
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
    ecb(error);
  }
}

export async function fetchCategoriesForRegister(params, scb, ecb) {
  try {
    const url = reg.GET_DISCIPLINE_FOR_REGISTER;
    fetchApi(
      url,
      params,
      (data) => {
        scb(data);
        console.log(data, 'GET_DISCIPLINE_FOR_REGISTER');
      },
      (error) => {
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
    ecb(error);
  }
}

export async function fetchSessionListForRegister(params, scb, ecb) {
  try {
    const url = reg.GET_SESSION_LIST_FOR_REGISTER;
    fetchApi(
      url,
      params,
      (data) => {
        scb(data);
        console.log(data, 'GET_SESSION_LIST_FOR_REGISTER');
      },
      (error) => {
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
    ecb(error);
  }
}

export async function emailVerificationCreate(config, scb, ecb) {
  try {
    const url = reg.VERIFICATION_CREATE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'VERIFICATION_CREATE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getCountryList(config, scb, ecb) {
  try {
    const url = shared.GET_COUNTRY_LIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_COUNTRY_LIST');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getStateList(config, scb, ecb) {
  try {
    const url = shared.GET_STATE_LIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_STATE_LIST');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}
