const HOST = process.env.REACT_APP_REST_API_HOST;

const GET_PAYMENT_DETAIL = {
  method: 'GET',
  path: `${HOST}go_shared/payment/payment`
};
const MAKE_PROJECT_PAYMENT = {
  method: 'POST',
  path: `${HOST}go_shared/payment/project`
};
const MAKE_PARTICIPANT_PAYMENT = {
  method: 'POST',
  path: `${HOST}go_shared/payment/participant`
};

module.exports = {
  GET_PAYMENT_DETAIL,
  MAKE_PROJECT_PAYMENT,
  MAKE_PARTICIPANT_PAYMENT
};
