export default {
  primary: '#3f51b5',
  secondary: '#f50057',
  warning: '#ff9800',
  danger: '#f44336',
  success: '#4caf50',
  info: '#00acc1',
  rose: '#e91e63',
  gray: '#999999',
  teal: 'rgb(96, 171, 191)',
  white: '#ffffff'
};
