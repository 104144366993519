import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as React from 'react';
import FairProfileForm from '../../../../Fair/Profile/FairProfileForm';

function ProfileDialog(props) {
  const { data, handleRefresh } = props;

  return <FairProfileForm data={data} handleRefresh={handleRefresh} mode={2} />;
}

ProfileDialog.propTypes = {
  data: PropTypes.object.isRequired,
  handleRefresh: PropTypes.func.isRequired
};

export default connect(() => ({}))(ProfileDialog);
