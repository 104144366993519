const HOST = process.env.REACT_APP_REST_API_HOST;

const GET_FAIR_ADMIN_PROFILE = {
  method: 'GET',
  path: `${HOST}go_admin/fair_admin/profile`
};
const UPDATE_FAIR_ADMIN_PROFILE = {
  method: 'PUT',
  path: `${HOST}go_admin/fair_admin/profile`
};
const GET_FAIR_ADMIN_REQUEST = {
  method: 'GET',
  path: `${HOST}go_admin/fair_admin/request`
};
const PATCH_FAIR_ADMIN_REQUEST = {
  method: 'PATCH',
  path: `${HOST}go_admin/fair_admin/request`
};
const GET_FAIR_DOCUMENT_LIST = {
  method: 'GET',
  path: `${HOST}go_admin/fair_admin/document/list`
};
const DOWNLOAD_FAIR_DOCUMENT = {
  method: 'GET',
  path: `${HOST}go_admin/fair_admin/document`
};

module.exports = {
  GET_FAIR_ADMIN_PROFILE,
  UPDATE_FAIR_ADMIN_PROFILE,
  GET_FAIR_ADMIN_REQUEST,
  PATCH_FAIR_ADMIN_REQUEST,
  GET_FAIR_DOCUMENT_LIST,
  DOWNLOAD_FAIR_DOCUMENT
};
