export default [
  {
    name: 'id',
    label: 'ID'
  },
  {
    name: 'name',
    label: 'Discipline Name'
  },
  {
    name: 'description',
    label: 'Description'
  },
  {
    name: 'capacity',
    label: 'Capacity'
  },
  {
    name: 'fee_comp',
    label: 'Fee'
  },
  {
    name: 'term_id',
    label: 'Term'
  },
  {
    name: 'edit',
    label: 'Edit',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'delete',
    label: 'Delete',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  }
];
