// QuestionsLandingPage table headers
export function getHeaders(judgeList) {
  const _sessionList = new Set();
  const _disciplineList = new Set();
  const _sessionTypeList = new Set();
  judgeList.forEach((judge) => {
    const sessionArray = judge.judging_sessions?.split(',');
    // eslint-disable-next-line no-unused-expressions
    sessionArray &&
      sessionArray.forEach((session) => {
        _sessionList.add(session);
      });
    const disciplineArray = judge.judging_session_disciplines?.split(',');
    // eslint-disable-next-line no-unused-expressions
    disciplineArray &&
      disciplineArray.forEach((discipline) => {
        _disciplineList.add(discipline);
      });
    const typeArray = judge.judging_session_types?.split(',');
    // eslint-disable-next-line no-unused-expressions
    typeArray &&
      typeArray.forEach((type) => {
        _sessionTypeList.add(type);
      });
  });
  const arrayOfSessionList = Array.from(_sessionList);
  const arrayOfTypeList = Array.from(_sessionTypeList);

  // eslint-disable-next-line no-unreachable
  const list = [
    {
      name: 'judge_id',
      label: 'ID'
    },
    {
      name: 'first_name',
      label: 'First Name'
    },
    {
      name: 'last_name',
      label: 'Last Name'
    },
    {
      name: 'institution',
      label: 'Institution'
    },
    {
      name: 'email',
      label: 'Email'
    },
    {
      name: 'judging_sessions',
      label: 'Sessions',
      options: {
        filter: true,
        filterOptions: {
          logic: (location, filters) => {
            let isExist = false;
            filters.forEach((item) => {
              isExist = !location.includes(item);
            });
            return isExist;
          },
          names: arrayOfSessionList
        }
      }
    },
    {
      name: 'judging_session_types',
      label: 'Session Types',
      options: {
        filter: true,
        filterOptions: {
          logic: (location, filters) => {
            let isExist = false;
            filters.forEach((item) => {
              isExist = !location.includes(item);
            });
            return isExist;
          },
          names: arrayOfTypeList
        }
      }
    },
    {
      name: 'score',
      label: 'Score'
    },
    {
      name: 'delete',
      label: 'Unassign Project',
      options: {
        sort: false,
        filter: false,
        print: false,
        download: false
      }
    }
  ];

  // eslint-disable-next-line no-unreachable
  return { list };
}
