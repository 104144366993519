import React, { useState } from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import { Button, Grid } from '@mui/material';
import { Icon } from '@iconify/react';
import Page from '../../../components/Page';
import Review from './Review';
import ReviewerReport from './ReviewerReport';
import LandingPageCard from '../../../components/CustomComponents/CustomCard/LandingPageCard';

const CARDS = [
  {
    id: 1,
    name: 'Review',
    description: 'From this module you can arrange review operation.'
  },
  {
    id: 2,
    name: 'Reviewer Report',
    description: 'From this module you can arrange reviewer report'
  }
];

const LANDING_PAGE = 0;
const REVIEW_PAGE = 1;
const REPORT_PAGE = 2;

function Index() {
  const [mode, setMode] = useState(LANDING_PAGE);

  function generate(data) {
    if (data.id === 1) {
      setMode(REVIEW_PAGE);
    } else {
      setMode(REPORT_PAGE);
    }
  }

  return (
    <Page title="Review Operation | Genius">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h4">
            Review Operation {/* eslint-disable-next-line no-nested-ternary */}
            {mode === REVIEW_PAGE
              ? ' / Review'
              : mode === REPORT_PAGE
              ? ' / Reviewer Report'
              : null}
          </Typography>
          {mode !== LANDING_PAGE && (
            <Button
              startIcon={<Icon icon="akar-icons:arrow-back-thick-fill" />}
              variant="outlined"
              onClick={() => setMode(LANDING_PAGE)}
            >
              Back
            </Button>
          )}
        </Box>
        {mode === LANDING_PAGE ? (
          <Grid container spacing={2}>
            {CARDS.map((item, index) => (
              <Grid item xs={3} key={index}>
                <LandingPageCard data={item} generate={generate} />
              </Grid>
            ))}
          </Grid>
        ) : mode === REVIEW_PAGE ? (
          <Review />
        ) : (
          <ReviewerReport />
        )}
      </Container>
    </Page>
  );
}

export default Index;
