import { Stack, TextField } from '@material-ui/core';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useState } from 'react';
import { setLoading, setSnackbar } from '../../../../../../actions/alertActions';
import { updateJudgingFormQuestion } from '../../_apis/serviceCalls';
import MarkdownEditor from '../AddQuestion/MarkdownEditor';

function FormInfoView(props) {
  const { question, dispatch, handleSuccess, handleClose } = props;
  const [markdown, setMarkdown] = useState(question.markdown);

  const Schema = Yup.object().shape({
    scale: Yup.number().required('Scale is required')
  });
  const formik = useFormik({
    initialValues: {
      scale: question.scale
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      dispatch(setLoading(1, 'Loading...'));
      const param = { ...values, markdown, judging_form_question_id: question.id };
      updateJudgingFormQuestion(
        param,
        () => {
          handleSuccess();
          dispatch(setLoading(0, ''));
          dispatch(setSnackbar('success', 'The question has been added'));
        },
        () => {
          dispatch(setLoading(0, ''));
        }
      );
    }
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack spacing={2} sx={{ mt: 1 }}>
            <MarkdownEditor markdown={markdown} setMarkdown={setMarkdown} />
            <TextField
              fullWidth
              label="Scale"
              type="number"
              {...getFieldProps('scale')}
              error={Boolean(touched.scale && errors.scale)}
              helperText={touched.scale && errors.scale}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="end" spacing={2}>
            <Button type="submit" variant="outlined">
              Update
            </Button>
            <Button variant="outlined" color="error" onClick={handleClose}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

FormInfoView.propTypes = {
  question: PropTypes.object.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default connect(() => ({}))(FormInfoView);
