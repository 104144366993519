import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import { Box, Link, Drawer, Typography, Avatar, Stack } from '@material-ui/core';
import { connect } from 'react-redux';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
import { getSidebarConfig } from './SidebarConfig';
import packageJson from '../../../package.json';
import DashboardLogo from '../../components/DashboardLogo';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200]
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

function DashboardSidebar(props) {
  const { active_role, isOpenSidebar, onCloseSidebar, route_reducer, user_profile, profile } =
    props;
  const { pathname } = useLocation();
  const sidebarConfig = getSidebarConfig(route_reducer);

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box sx={{ display: 'inline-flex' }}>
          <DashboardLogo />
        </Box>
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link
          underline="none"
          component={RouterLink}
          to={active_role?.role_id === 3 ? '/fair/fair_profile' : '/dashboard/profile'}
        >
          <AccountStyle>
            <Avatar src={user_profile?.picture_data} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {`${profile?.name}`}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {`${active_role?.role_name}`}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>
      <NavSection navConfig={sidebarConfig} />
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack
          alignItems="center"
          spacing={3}
          sx={{
            p: 2.5,
            pt: 5,
            borderRadius: 2,
            position: 'relative'
          }}
        >
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              &copy; {1900 + new Date().getYear()} Genius v{packageJson.version}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}

DashboardSidebar.propTypes = {
  active_role: PropTypes.object,
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
  route_reducer: PropTypes.object,
  user_profile: PropTypes.object,
  profile: PropTypes.object
};

export default connect((store) => ({
  active_role: store.loginReducer.active_role,
  profile: store.loginReducer.profile,
  route_reducer: store.routeReducer,
  user_profile: store.loginReducer.user_profile
}))(DashboardSidebar);
