import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Alert, IconButton } from '@material-ui/core';
import { Icon } from '@iconify/react';
import MuiTable from '../../../../../../components/CustomComponents/MuiTable';
import {
  setConfirmationAlert,
  setLoading,
  setSnackbar
} from '../../../../../../actions/alertActions';
import { getJudgingProjectDetail, unasssignJudgingProject } from '../../_apis/serviceCalls';
import { getHeaders } from './headerVariables';

function ExpandView(props) {
  const { project_id, dispatch, refreshData } = props;
  const [tableData, setTableData] = useState(null);
  const [headers, setHeaders] = useState([]);

  useEffect(() => {
    dispatch(setLoading(1, 'Loading...'));
    getJudgingProjectDetail(
      { project_id },
      (data) => {
        const _headers = getHeaders(data);
        setHeaders(_headers.list);
        data.forEach((item) => {
          item.delete = (
            <IconButton color="secondary" onClick={() => handleDeleteProject(item.judge_id)}>
              <Icon icon="fluent:delete-48-filled" width={24} height={24} />
            </IconButton>
          );
        });
        dispatch(setLoading(0, ''));
        setTableData(data);
      },
      () => {
        dispatch(setLoading(0, ''));
      }
    );
  }, []);

  function handleDeleteProject(judge_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'This action will delete project from group permanently',
        () => {
          unasssignJudgingProject(
            { project_id, judge_id, force_delete: 0 },
            () => {
              refreshData();
              dispatch(setSnackbar('success', 'The project has been deleted from group'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            },
            (error) => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
              if (error.http_status_code === 409) {
                forceDelete(judge_id, project_id, error.message);
              }
            }
          );
        }
      )
    );
  }

  function forceDelete(judge_id, project_id, message) {
    dispatch(
      setConfirmationAlert('Are you sure?', 'YES, FORCE DELETE', message, () => {
        unasssignJudgingProject(
          { project_id, judge_id, force_delete: 1 },
          () => {
            refreshData();
            dispatch(setSnackbar('success', 'The project has been deleted from group'));
            dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
          },
          () => {
            dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
          }
        );
      })
    );
  }

  return tableData ? (
    tableData.length === 0 ? (
      <Alert severity="info">There is no judge for project.</Alert>
    ) : (
      <MuiTable tableData={tableData} tableColumns={headers} />
    )
  ) : (
    <div />
  );
}

ExpandView.propTypes = {
  project_id: PropTypes.number,
  dispatch: PropTypes.func.isRequired,
  refreshData: PropTypes.func
};

export default connect(() => ({}))(ExpandView);
