const HOST = process.env.REACT_APP_REST_API_HOST;

const ADD_CODING_TEAM = {
  method: 'POST',
  path: `${HOST}go_admin/coding/competition/team`
};
const GET_SCOREBOARD = {
  method: 'GET',
  path: `${HOST}go_admin/coding/competition/scoreboard`
};
const ADD_SCOREBOARD = {
  method: 'PUT',
  path: `${HOST}go_admin/coding/competition/scoreboard`
};
const GET_SCOREBOARD_DETAIL = {
  method: 'GET',
  path: `${HOST}go_admin/coding/competition/scoreboard_detail`
};
const UPDATE_PROJECT_REVIEW = {
  method: 'PATCH',
  path: `${HOST}go_admin/coding/competition/project_review`
};
const UPDATE_PROJECT_FINALIST = {
  method: 'PATCH',
  path: `${HOST}go_admin/coding/competition/project_finalist`
};
const UPDATE_CARBON_SCORE = {
  method: 'PUT',
  path: `${HOST}go_admin/coding/competition/carbon_score`
};

module.exports = {
  ADD_CODING_TEAM,
  GET_SCOREBOARD,
  ADD_SCOREBOARD,
  GET_SCOREBOARD_DETAIL,
  UPDATE_PROJECT_REVIEW,
  UPDATE_PROJECT_FINALIST,
  UPDATE_CARBON_SCORE
};
