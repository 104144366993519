import { fetchApi } from '../../../../api/fetchApi';

const award = require('./apis');

export async function getSurveyList(config, scb, ecb) {
  try {
    const url = award.GET_SURVEY_LIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_SURVEY_LIST');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addSurvey(config, scb, ecb) {
  try {
    const url = award.ADD_SURVEY;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_SURVEY');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateSurvey(config, scb, ecb) {
  try {
    const url = award.UPDATE_SURVEY;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_SURVEY');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteSurvey(config, scb, ecb) {
  try {
    const url = award.DELETE_SURVEY;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_SURVEY');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function copySurvey(config, scb, ecb) {
  try {
    const url = award.COPY_SURVEY;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'COPY_SURVEY');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getSurveyDetail(config, scb, ecb) {
  try {
    const url = award.GET_SURVEY_DETAIL;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_SURVEY_DETAIL');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addSurveyQuestion(config, scb, ecb) {
  try {
    const url = award.ADD_SURVEY_QUESTION;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_SURVEY_QUESTION');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateSurveyQuestion(config, scb, ecb) {
  try {
    const url = award.UPDATE_SURVEY_QUESTION;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_SURVEY_QUESTION');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteSurveyQuestion(config, scb, ecb) {
  try {
    const url = award.DELETE_SURVEY_QUESTION;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_SURVEY_QUESTION');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function changeSurveyQuestion(config, scb, ecb) {
  try {
    const url = award.CHANGE_SURVEY_QUESTION;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'CHANGE_SURVEY_QUESTION');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getReportList(config, scb, ecb) {
  try {
    const url = award.GET_REPORT_LIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_REPORT_LIST');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getReportResults(config, scb, ecb) {
  try {
    const url = award.GET_REPORT_RESULTS;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_REPORT_RESULTS');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}
