export default [
  {
    name: 'id',
    label: 'ID'
  },
  {
    name: 'description',
    label: 'Description'
  },
  {
    name: 'application_start_date_comp',
    label: 'App. S.D.',
    options: {
      tooltip: 'Application Start Date'
    }
  },
  {
    name: 'application_end_date_comp',
    label: 'App. E.D.',
    options: {
      tooltip: 'Application End Date'
    }
  },
  {
    name: 'application_fair_end_date_comp',
    label: 'App. Fair E.D.',
    options: {
      tooltip: 'Application Fair End Date'
    }
  },
  {
    name: 'invitation_end_date_comp',
    label: 'Invitation E.D.',
    options: {
      tooltip: 'Invitation End Date'
    }
  },
  {
    name: 'presentation_end_date_comp',
    label: 'Presentation E.D.',
    options: {
      tooltip: 'Presentation End Date'
    }
  },
  {
    name: 'registration_end_date_comp',
    label: 'Registration E.D.',
    options: {
      tooltip: 'Registration End Date'
    }
  },
  {
    name: 'trip_end_date_comp',
    label: 'Trip E.D.',
    options: {
      tooltip: 'Trip End Date'
    }
  },
  {
    name: 'event_start_date_comp',
    label: 'Event S.D.',
    options: {
      tooltip: 'Event Start Date'
    }
  },
  {
    name: 'event_end_date_comp',
    label: 'Event E.D.',
    options: {
      tooltip: 'Event End Date'
    }
  },
  {
    name: 'is_online_comp',
    label: 'Online',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'is_online_filter',
    label: 'Online',
    options: {
      display: false
    }
  },
  {
    name: 'is_locked_comp',
    label: 'Locked',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'is_locked_filter',
    label: 'Locked',
    options: {
      display: false
    }
  },
  {
    name: 'edit',
    label: 'Edit',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'delete',
    label: 'Delete',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  }
];
