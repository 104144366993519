import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import CardContent from '@material-ui/core/CardContent';
import { Grid, Stack, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { blue } from '@material-ui/core/colors';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import ReactHtmlParser from 'react-html-parser';
import Timer from './Timer';
import { downloadJudgingFile } from '../../_apis/serviceCalls';
import fileMaker from '../../../Application/Projects/_utils/fileMaker';

const useStyles = makeStyles(() => ({
  avatar: {
    backgroundColor: blue[500]
  },
  cardAction: {
    float: 'right'
  }
}));

function Index(props) {
  const { projectData } = props;
  const [downloading, setDownloading] = useState(false);
  const classes = useStyles();
  const file_comp = fileMaker(projectData.files, downloadFile);

  function downloadFile(file) {
    setDownloading(true);
    const param = {
      id: file.id,
      file_name: file.name
    };
    downloadJudgingFile(
      param,
      () => {
        setDownloading(false);
      },
      () => {
        setDownloading(false);
      }
    );
  }

  function getId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  }
  const videoId = projectData.presentation_link && getId(projectData?.presentation_link);
  const iframeMarkup = `<iframe width="560" height="315" src="//www.youtube.com/embed/${videoId}" frameborder="0" allowfullscreen></iframe>`;

  return (
    <Card>
      <CardHeader
        avatar={<Avatar className={classes.avatar}>{`${projectData.title.charAt(0)}`}</Avatar>}
        title={`${projectData.title}`}
        subheader={projectData.discipline}
        action={<Timer duration={projectData.duration} />}
      />
      <CardContent>
        <Typography variant="body2" color="textPrimary" component="span">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <span>
                <b>Project ID: </b>
              </span>
              {projectData.id || '-'}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <span>
                <b>Country: </b>
              </span>
              {projectData.country || '-'}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <span>
                <b>Project Students: </b>
              </span>
              {projectData.project_student_complete || '-'}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} style={{ display: 'flex' }}>
              <span>
                <b>Project Files: </b>
              </span>
              {downloading ? (
                <Stack
                  spacing={1}
                  direction={{ xs: 'column', sm: 'row' }}
                  sx={{ mr: 1, display: 'flex', alignItems: 'center' }}
                >
                  <p> {' Downloading...'}</p>
                  <CircularProgress size={20} />
                </Stack>
              ) : (
                file_comp
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <span>
                <b>Presentation Link: </b>
              </span>
              {projectData.presentation_link ? <div> {ReactHtmlParser(iframeMarkup)} </div> : '-'}
            </Grid>
          </Grid>
        </Typography>
      </CardContent>
    </Card>
  );
}

Index.propTypes = {
  projectData: PropTypes.object.isRequired
};

export default Index;
