const HOST = process.env.REACT_APP_REST_API_HOST;

const GET_DOCUMENT_LIST = {
  method: 'GET',
  path: `${HOST}go_admin/document_operation/document/list`
};
const GET_DOCUMENT_ROLE = {
  method: 'GET',
  path: `${HOST}go_admin/document_operation/document/role`
};
const GET_DOCUMENT = {
  method: 'GET',
  path: `${HOST}go_admin/document_operation/document`
};
const ADD_DOCUMENT = {
  method: 'POST',
  path: `${HOST}go_admin/document_operation/document`
};
const UPDATE_DOCUMENT = {
  method: 'PUT',
  path: `${HOST}go_admin/document_operation/document`
};
const DELETE_DOCUMENT = {
  method: 'DELETE',
  path: `${HOST}go_admin/document_operation/document`
};

module.exports = {
  GET_DOCUMENT_LIST,
  GET_DOCUMENT_ROLE,
  GET_DOCUMENT,
  ADD_DOCUMENT,
  UPDATE_DOCUMENT,
  DELETE_DOCUMENT
};
