export function getAdminRoutes(allModules) {
  const isPayment = allModules.findIndex((x) => x.module_access_key === 'payment');
  const isParticipantPaymentFeature =
    isPayment !== -1
      ? allModules[isPayment].features.findIndex((x) => x.feature_access_key === 'participant')
      : -1;
  const isProjectPaymentFeature =
    isPayment !== -1
      ? allModules[isPayment].features.findIndex((x) => x.feature_access_key === 'project')
      : -1;
  const isTripPaymentFeature =
    isPayment !== -1
      ? allModules[isPayment].features.findIndex((x) => x.feature_access_key === 'trip')
      : -1;
  const isSurvey = allModules.findIndex((x) => x.module_access_key === 'survey');
  const isSOS =
    isPayment !== -1
      ? allModules[isSurvey].features.findIndex((x) => x.feature_access_key === 'survey')
      : -1;
  const isSOR =
    isPayment !== -1
      ? allModules[isSurvey].features.findIndex((x) => x.feature_access_key === 'report')
      : -1;
  const isSchoolOperation = allModules.findIndex((x) => x.module_access_key === 'school_operation');
  const isCodingOperation = allModules.findIndex((x) => x.module_access_key === 'coding');
  const isPerformanceOperation = allModules.findIndex(
    (x) => x.module_access_key === 'performance_operation'
  );
  const isParticipantOperation = allModules.findIndex(
    (x) => x.module_access_key === 'participant_operation'
  );
  const isParticipationParticipantOpFeature =
    isParticipantOperation !== -1
      ? allModules[isParticipantOperation].features.findIndex(
          (x) => x.feature_access_key === 'participation'
        )
      : -1;
  const isInvitationLettersParticipantOpFeature =
    isParticipantOperation !== -1
      ? allModules[isParticipantOperation].features.findIndex(
          (x) => x.feature_access_key === 'invitation_letter'
        )
      : -1;
  const isVipParticipantOpFeature =
    isParticipantOperation !== -1
      ? allModules[isParticipantOperation].features.findIndex((x) => x.feature_access_key === 'vip')
      : -1;
  const isTripParticipantOpFeature =
    isParticipantOperation !== -1
      ? allModules[isParticipantOperation].features.findIndex(
          (x) => x.feature_access_key === 'trip'
        )
      : -1;
  const isTravelParticipantOpFeature =
    isParticipantOperation !== -1
      ? allModules[isParticipantOperation].features.findIndex(
          (x) => x.feature_access_key === 'travel'
        )
      : -1;

  const isFairOperation = allModules.findIndex((x) => x.module_access_key === 'fair_operation');
  const isProfileFairOpFeature =
    isFairOperation !== -1
      ? allModules[isFairOperation].features.findIndex((x) => x.feature_access_key === 'profile')
      : -1;
  const isFairFairOpFeature =
    isFairOperation !== -1
      ? allModules[isFairOperation].features.findIndex((x) => x.feature_access_key === 'fair')
      : -1;
  const isRequestFairOpFeature =
    isFairOperation !== -1
      ? allModules[isFairOperation].features.findIndex((x) => x.feature_access_key === 'request')
      : -1;

  const isSettings = allModules.findIndex((x) => x.module_access_key === 'settings');
  const isFeeSettingsFeature =
    isSettings !== -1
      ? allModules[isSettings].features.findIndex((x) => x.feature_access_key === 'fee')
      : -1;
  const isTripSettingsFeature =
    isSettings !== -1
      ? allModules[isSettings].features.findIndex((x) => x.feature_access_key === 'trip')
      : -1;
  const isCategorySettingsFeature =
    isSettings !== -1
      ? allModules[isSettings].features.findIndex((x) => x.feature_access_key === 'category')
      : -1;
  const isDisciplineSettingsFeature =
    isSettings !== -1
      ? allModules[isSettings].features.findIndex((x) => x.feature_access_key === 'discipline')
      : -1;
  const isTermSettingsFeature =
    isSettings !== -1
      ? allModules[isSettings].features.findIndex((x) => x.feature_access_key === 'term')
      : -1;

  const isUserManagement = allModules.findIndex((x) => x.module_access_key === 'user_management');
  const isRoleUMFeature =
    isUserManagement !== -1
      ? allModules[isUserManagement].features.findIndex((x) => x.feature_access_key === 'role')
      : -1;
  const isUserUMFeature =
    isUserManagement !== -1
      ? allModules[isUserManagement].features.findIndex((x) => x.feature_access_key === 'user')
      : -1;

  const isProjectOperation = allModules.findIndex(
    (x) => x.module_access_key === 'project_operation'
  );
  const isInfoPOFeature =
    isProjectOperation !== -1
      ? allModules[isProjectOperation].features.findIndex((x) => x.feature_access_key === 'info')
      : -1;
  const isReportPOFeature =
    isProjectOperation !== -1
      ? allModules[isProjectOperation].features.findIndex((x) => x.feature_access_key === 'report')
      : -1;

  const isDataRecovery = allModules.findIndex((x) => x.module_access_key === 'data_recovery');
  const isCommunication = allModules.findIndex((x) => x.module_access_key === 'communication');
  const isReviewOperation = allModules.findIndex((x) => x.module_access_key === 'review_operation');
  const isReviewRO =
    isReviewOperation !== -1
      ? allModules[isReviewOperation].features.findIndex((x) => x.feature_access_key === 'review')
      : -1;
  const isReviewerReportRO =
    isReviewOperation !== -1
      ? allModules[isReviewOperation].features.findIndex(
          (x) => x.feature_access_key === 'reviewer_report'
        )
      : -1;
  const isAwardOperation = allModules.findIndex((x) => x.module_access_key === 'award_operation');
  const isAwardRO =
    isAwardOperation !== -1
      ? allModules[isAwardOperation].features.findIndex((x) => x.feature_access_key === 'award')
      : -1;
  const isAwardReportRO =
    isAwardOperation !== -1
      ? allModules[isAwardOperation].features.findIndex((x) => x.feature_access_key === 'report')
      : -1;
  const isPFO = allModules.findIndex((x) => x.module_access_key === 'partner_fair_operation');
  const isConfigPFO =
    isPFO !== -1
      ? allModules[isPFO].features.findIndex((x) => x.feature_access_key === 'config')
      : -1;
  const isReportPFO =
    isPFO !== -1
      ? allModules[isPFO].features.findIndex((x) => x.feature_access_key === 'report')
      : -1;
  const isCertificateRO =
    isAwardOperation !== -1
      ? allModules[isAwardOperation].features.findIndex(
          (x) => x.feature_access_key === 'certificate_template'
        )
      : -1;
  const isJudgeOperation = allModules.findIndex((x) => x.module_access_key === 'judge_operation');
  const isSessionJOFeature =
    isJudgeOperation !== -1
      ? allModules[isJudgeOperation].features.findIndex((x) => x.feature_access_key === 'session')
      : -1;
  const isJudgeJOFeature =
    isJudgeOperation !== -1
      ? allModules[isJudgeOperation].features.findIndex((x) => x.feature_access_key === 'judge')
      : -1;
  const isGroupJOFeature =
    isJudgeOperation !== -1
      ? allModules[isJudgeOperation].features.findIndex((x) => x.feature_access_key === 'group')
      : -1;
  const isJudgingJOFeature =
    isJudgeOperation !== -1
      ? allModules[isJudgeOperation].features.findIndex((x) => x.feature_access_key === 'judging')
      : -1;
  const isJudgingFormJOFeature =
    isJudgeOperation !== -1
      ? allModules[isJudgeOperation].features.findIndex(
          (x) => x.feature_access_key === 'judging_form'
        )
      : -1;
  const isRoboticsJOFeature =
    isJudgeOperation !== -1
      ? allModules[isJudgeOperation].features.findIndex(
          (x) => x.feature_access_key === 'robotics_competition'
        )
      : -1;

  const isGuidelineOperation = allModules.findIndex((x) => x.module_access_key === 'guideline');
  const isDocumentOperation = allModules.findIndex(
    (x) => x.module_access_key === 'document_operation'
  );

  return {
    isPayment,
    isParticipantPaymentFeature,
    isProjectPaymentFeature,
    isTripPaymentFeature,
    isSchoolOperation,
    isPerformanceOperation,
    isParticipantOperation,
    isParticipationParticipantOpFeature,
    isInvitationLettersParticipantOpFeature,
    isVipParticipantOpFeature,
    isTripParticipantOpFeature,
    isTravelParticipantOpFeature,
    isFairOperation,
    isFairFairOpFeature,
    isProfileFairOpFeature,
    isRequestFairOpFeature,
    isSettings,
    isFeeSettingsFeature,
    isTripSettingsFeature,
    isCategorySettingsFeature,
    isDisciplineSettingsFeature,
    isTermSettingsFeature,
    isUserManagement,
    isRoleUMFeature,
    isUserUMFeature,
    isProjectOperation,
    isInfoPOFeature,
    isReportPOFeature,
    isDataRecovery,
    isCommunication,
    isReviewOperation,
    isReviewRO,
    isReviewerReportRO,
    isJudgeOperation,
    isSessionJOFeature,
    isJudgeJOFeature,
    isGroupJOFeature,
    isJudgingJOFeature,
    isJudgingFormJOFeature,
    isRoboticsJOFeature,
    isAwardOperation,
    isAwardRO,
    isAwardReportRO,
    isCertificateRO,
    isGuidelineOperation,
    isDocumentOperation,
    isPFO,
    isConfigPFO,
    isReportPFO,
    isSurvey,
    isCodingOperation,
    isSOS,
    isSOR
  };
}
