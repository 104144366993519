import { Button, Grid } from '@mui/material';
import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Icon } from '@iconify/react';
import LandingPageCard from '../../../../components/CustomComponents/CustomCard/LandingPageCard';
import Page from '../../../../components/Page';
import GroupMode from './GroupMode';
import ProjectMode from './ProjectMode';
import JudgeMode from './JudgeMode';
import MonitoringMode from './MonitoringMode';

const CARDS = [
  {
    id: 1,
    name: 'Group Report',
    description: 'From this module you can arrange Group Report.'
  },
  {
    id: 2,
    name: 'Judge Report',
    description: 'From this module you can arrange Judge Report.'
  },
  {
    id: 3,
    name: 'Project Report',
    description: 'From this module you can arrange Project Report.'
  },
  {
    id: 4,
    name: 'Monitoring Report',
    description: 'From this module you can arrange Monitoring Report.'
  }
];

const LANDING_PAGE_MODE = 0;
const GROUP_MODE = 1;
const JUDGE_MODE = 2;
const PROJECT_MODE = 3;
const MONITORING_MODE = 4;

function Index() {
  const [mode, setMode] = useState(LANDING_PAGE_MODE);

  function generate(data) {
    if (data.id === GROUP_MODE) {
      setMode(GROUP_MODE);
    } else if (data.id === JUDGE_MODE) {
      setMode(JUDGE_MODE);
    } else if (data.id === PROJECT_MODE) {
      setMode(PROJECT_MODE);
    } else {
      setMode(MONITORING_MODE);
    }
  }

  return (
    <Page title="Judge Operation | Genius">
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
        {mode !== LANDING_PAGE_MODE && (
          <>
            <Typography variant="subtitle1">
              Judge Operation > Judging >{' '}
              {mode === GROUP_MODE
                ? 'Group Report'
                : mode === JUDGE_MODE
                ? 'Judge Report'
                : mode === PROJECT_MODE
                ? 'Project Report'
                : 'Monitoring Report'}
            </Typography>
            <Button
              startIcon={<Icon icon="akar-icons:arrow-back-thick-fill" />}
              variant="outlined"
              onClick={() => setMode(LANDING_PAGE_MODE)}
            >
              Back
            </Button>
          </>
        )}
      </Box>
      {mode === LANDING_PAGE_MODE ? (
        <Grid container spacing={2}>
          {CARDS.map((item, index) => (
            <Grid item xs={12} md={6} lg={4} xl={3} key={index}>
              <LandingPageCard data={item} generate={generate} />
            </Grid>
          ))}
        </Grid>
      ) : mode === GROUP_MODE ? (
        <GroupMode />
      ) : mode === JUDGE_MODE ? (
        <JudgeMode />
      ) : mode === PROJECT_MODE ? (
        <ProjectMode />
      ) : (
        <MonitoringMode />
      )}
    </Page>
  );
}

export default Index;
