import { IconButton } from '@material-ui/core';
import { Icon } from '@iconify/react';
import moment from 'moment/moment';
import React from 'react';
import { Stack } from '@mui/material';
import fileMaker from '../../../../Application/Projects/_utils/fileMaker';
import { fCurrency } from '../../../../../utils/formatNumber';
import Label from '../../../../../components/Label';

export function dataMaker(
  list,
  handleEditProject,
  handleDeleteProject,
  viewFile,
  handleChangeFinalistStatus,
  handleAddMoney
) {
  list.forEach((item) => {
    const _balance = Number(parseFloat(item.balance).toFixed(2));
    item.balance_comp = item.balance ? (
      _balance < 0 || _balance === 0 ? (
        <Label variant="ghost" color="success">
          {fCurrency(item.balance)}
        </Label>
      ) : (
        <Label variant="ghost" color="error">
          {fCurrency(item.balance)}
        </Label>
      )
    ) : (
      'Blank'
    );
    item.add_money = (
      <IconButton color="secondary" onClick={() => handleAddMoney(item)}>
        <Icon icon="cil:money" />
      </IconButton>
    );
    item.balance_filter = item.balance
      ? _balance < 0 || _balance === 0
        ? 'Paid'
        : 'Not Paid'
      : 'Blank';
    item.presentation_link_comp = item.presentation_link && (
      <Label variant="ghost" color="primary">
        <a target="_blank" href={item.presentation_link} rel="noreferrer">
          Link
        </a>
      </Label>
    );
    item.presentation_link_filter = item.presentation_link ? 'Exist' : 'None';
    item.file_comp = fileMaker(item.files, viewFile);
    item.file_check_list = checkListMaker(item.files);
    item.file_check_list_filter = checkListMaker(item.files) ? 'Exist' : 'None';
    item.file_filter = item.project_file_id ? 'Exist' : 'None';
    item.media_link_comp = item.media_link && (
      <Label variant="ghost" color="primary">
        <a target="_blank" href={item.media_link} rel="noreferrer">
          Link
        </a>
      </Label>
    );
    item.finalist_status_comp = (
      <IconButton color="secondary" onClick={() => handleChangeFinalistStatus(item)}>
        <Icon icon="ic:twotone-published-with-changes" width={24} height={24} />
      </IconButton>
    );
    item.media_link_filter = item.media_link ? 'Exist' : 'None';
    item.edit = (
      <IconButton color="secondary" onClick={() => handleEditProject(item)}>
        <Icon icon="ci:edit" width={24} height={24} />
      </IconButton>
    );
    item.delete = (
      <IconButton color="secondary" onClick={() => handleDeleteProject(item.id)}>
        <Icon icon="fluent:delete-48-filled" width={24} height={24} />
      </IconButton>
    );
    item.created_at_moment = moment(item.created_at).format('YYYY-MM-DD');
  });
  return list;
}

export function checkListMaker(files) {
  let aiScore = 0;
  let plagiarismScore = 0;
  let resultUrl = '';
  files.forEach((file) => {
    if (file.ai_score) {
      aiScore = file.ai_score;
    }
    if (file.plagiarism_score) {
      plagiarismScore = file.plagiarism_score;
    }
    if (file.result_url) {
      resultUrl = file.result_url;
    }
  });
  return (
    resultUrl && (
      <Stack direction={{ xs: 'row', sm: 'column' }} spacing={1}>
        <Label variant="ghost" color="primary">
          <a target="_blank" href={resultUrl} rel="noreferrer">
            AI: {aiScore} / Plagiarism: {plagiarismScore}
          </a>
        </Label>
      </Stack>
    )
  );
}
