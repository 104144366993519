import * as React from 'react';
import { Card, CardActions, CardContent, CardHeader, Stack, Tooltip } from '@mui/material';
import Avatar from '@material-ui/core/Avatar';
import { Create, Delete } from '@material-ui/icons';
import { useState } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { makeStyles } from '@material-ui/styles';
import EditRubric from '../EditRubric/EditRubric';
import SmartIcon from '../../../../../../components/CustomComponents/SmartIcon';

/*
    This view includes MuiCard to inform user about the sub rubric.
    There are meta data about sub question on the card header.
    There are view, edit and delete question icons there.
    Users can view question detail on the card content.
    This view includes also Edit Sub Rubric modal.
*/

const useStyles = makeStyles(() => ({
  cardAction: {
    float: 'right'
  }
}));

function RubricDetail(props) {
  const { rubric, newRank, rankChange, refreshData, handleDeleteRubric } = props;
  const [editDialog, setEditDialog] = useState(false);
  const classes = useStyles();

  function handleSuccess() {
    setEditDialog(false);
    refreshData();
  }

  return (
    <Card className="drag-drop-inside-card-style">
      <CardHeader
        style={{ marginBottom: 10 }}
        title={rubric.name}
        subheader={rubric.rubric_type_id === 1 ? 'Skill' : 'Penalty'}
        avatar={
          rankChange ? (
            <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>
              <Tooltip title="Old Rank">
                <Avatar className="drag-drop-old-rank-style" aria-label="recipe">
                  {rubric.rank}
                </Avatar>
              </Tooltip>
              <Tooltip title="New Rank">
                <Avatar className="drag-drop-new-rank-style" aria-label="recipe">
                  {newRank + 1}
                </Avatar>
              </Tooltip>
            </Stack>
          ) : (
            <Avatar className="drag-drop-current-rank-style" aria-label="recipe">
              {rubric.rank}
            </Avatar>
          )
        }
      />
      <CardContent>
        <div>{ReactHtmlParser(rubric?.markdown)}</div>
      </CardContent>
      <CardActions className={classes.cardAction}>
        {!rankChange && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{ fontWeight: 'bold' }}
            >{`Limit: ${rubric?.limit} - Points: ${rubric?.points}`}</p>
            <SmartIcon
              onClick={() => setEditDialog(true)}
              tooltip="Edit Rubric"
              size="small"
              icon={<Create />}
              color="primary"
            />
            <SmartIcon
              onClick={() => handleDeleteRubric(rubric.id)}
              tooltip="Delete Rubric"
              size="small"
              icon={<Delete />}
              color="secondary"
            />
          </div>
        )}
      </CardActions>
      {editDialog && (
        <EditRubric
          rubric={rubric}
          closeDialog={() => setEditDialog(false)}
          handleSuccess={handleSuccess}
        />
      )}
    </Card>
  );
}

RubricDetail.propTypes = {
  rubric: PropTypes.object,
  newRank: PropTypes.number,
  rankChange: PropTypes.bool,
  refreshData: PropTypes.func,
  handleDeleteRubric: PropTypes.func
};

export default RubricDetail;
