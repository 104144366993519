import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { getDurationString } from '../../../../utils/formatTime';
import Label from '../../../../components/Label';

const STATUS = {
  STARTED: 'Started',
  STOPPED: 'Stopped'
};

export default function Timer(props) {
  const { duration } = props;
  const sec = getDurationString(duration) * 60;
  const [secondsRemaining, setSecondsRemaining] = useState(sec);
  const [status, setStatus] = useState(STATUS.STARTED);

  const secondsToDisplay = secondsRemaining % 60;
  const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60;
  const minutesToDisplay = minutesRemaining % 60;
  const hoursToDisplay = (minutesRemaining - minutesToDisplay) / 60;

  useInterval(
    () => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1);
      } else {
        setStatus(STATUS.STOPPED);
      }
    },
    status === STATUS.STARTED ? 1000 : null
    // passing null stops the interval
  );
  return (
    <div>
      <Label variant="ghost" color={secondsRemaining < 120 ? 'error' : 'primary'}>
        <p>Time Left: </p>
        {secondsRemaining ? (
          <div style={{ paddingRight: 5, paddingLeft: 5 }}>
            {twoDigits(hoursToDisplay)}:{twoDigits(minutesToDisplay)}:{twoDigits(secondsToDisplay)}
          </div>
        ) : (
          <p>Time is over!</p>
        )}
      </Label>
    </div>
  );
}

Timer.propTypes = {
  duration: PropTypes.string.isRequired
};

// source: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const twoDigits = (num) => String(num).padStart(2, '0');
