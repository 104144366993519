export default [
  {
    name: 'judging_group_id',
    label: 'ID'
  },
  {
    name: 'group_number',
    label: 'Group Number'
  },
  {
    name: 'discipline',
    label: 'Discipline'
  },
  {
    name: 'judging_session_type',
    label: 'Session Type'
  },
  {
    name: 'judging_session',
    label: 'Session Name'
  },
  {
    name: 'number_of_projects',
    label: 'Number of Projects'
  },
  {
    name: 'number_of_judges',
    label: 'Number of Judges'
  },
  {
    name: 'assign_action',
    label: 'Assign Judge',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  }
];
