export default [
  {
    name: 'judging_form_id',
    label: 'ID'
  },
  {
    name: 'discipline',
    label: 'Discipline'
  },
  {
    name: 'judging_form_name',
    label: 'Form Name'
  },
  {
    name: 'duration',
    label: 'Duration'
  },
  {
    name: 'number_of_questions',
    label: 'Number of questions'
  },
  {
    name: 'score_view',
    label: 'Available Score'
  },
  {
    name: 'copy',
    label: 'Copy',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'view',
    label: 'View',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'edit',
    label: 'Edit',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'delete',
    label: 'Delete',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  }
];
