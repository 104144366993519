import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { Card, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import * as React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import { Stack, TextField } from '@material-ui/core';
import {
  addAffiliatedFair,
  deleteAffiliatedFair,
  fetchAffiliatedFair,
  getAffiliatedFairReq
} from '../utils/serviceCalls';
import AffiliatedFairCard from '../../../../components/CustomComponents/CustomCard/AffiliatedFairCard';
import { setConfirmationAlert, setSnackbar } from '../../../../actions/alertActions';
import { handleFairEditable } from '../utils/termMaker';

const ZERO_MODE = 0;
const EXIST_MODE = 1;
const NONE_MODE = 2;

function FairPart(props) {
  const { project_id, handleNext, handleBack, dispatch, activeDiscipline, active_term_object } =
    props;
  const [mode, setMode] = useState(ZERO_MODE);
  const [fairData, setFairData] = useState(null);
  const [fairList, setFairList] = useState([]);
  const [selectedFair, setSelectedFair] = useState(null);
  const isFairEditable = handleFairEditable(active_term_object);

  useEffect(() => {
    fetchAllFairs();
    fetchFairByProjectId();
  }, []);

  function fetchFairByProjectId() {
    getAffiliatedFairReq(
      { project_id },
      (data) => {
        const isExist = Object.keys(data).length !== 0;
        setFairData(data);
        setMode(isExist ? EXIST_MODE : NONE_MODE);
      },
      () => {}
    );
  }
  function fetchAllFairs() {
    fetchAffiliatedFair(
      {},
      (data) => {
        const arr = [];
        data.forEach((item) => {
          const discList = item.fair_discipline_id.split(',');
          const isExist = discList.findIndex((x) => parseInt(x, 10) === activeDiscipline);
          // eslint-disable-next-line no-unused-expressions
          isExist !== -1 && arr.push(item);
        });
        setFairList(arr);
      },
      () => {}
    );
  }
  function deleteFair() {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'This action will delete Affiliation request, are you sure?',
        () => {
          deleteAffiliatedFair(
            { project_id },
            () => {
              setFairData(null);
              setMode(NONE_MODE);
              dispatch(setSnackbar('success', 'Affiliation request has been deleted'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }
  function sendRequest() {
    const param = {
      project_id,
      fair_id: selectedFair.id
    };
    addAffiliatedFair(
      param,
      () => {
        fetchFairByProjectId();
        dispatch(setSnackbar('success', 'Affiliation request has been received'));
      },
      () => {}
    );
  }

  return (
    <Card sx={{ padding: 2, mt: 2 }}>
      {mode === ZERO_MODE ? (
        <></>
      ) : mode === EXIST_MODE ? (
        <Grid item xs={4}>
          <AffiliatedFairCard data={fairData} deleteFair={deleteFair} atReview={!isFairEditable} />
        </Grid>
      ) : (
        <Stack
          spacing={3}
          sx={{ mt: 1, mb: 1, alignItems: 'center' }}
          direction={{ xs: 'column', sm: 'row' }}
        >
          <Autocomplete
            disabled={!isFairEditable}
            value={selectedFair}
            onChange={(event, newValue) => {
              setSelectedFair(newValue);
            }}
            style={{ width: 400 }}
            id="free-solo-demo"
            freeSolo
            options={fairList}
            getOptionLabel={(option) =>
              `${option.country}${option.state ? `-${option.state}` : ''}-${option.name}`
            }
            renderInput={(params) => <TextField {...params} fullWidth label="Affiliated Fair" />}
          />
          <div>
            <Button variant="outlined" onClick={sendRequest} disabled={!isFairEditable}>
              Send Request
            </Button>
          </div>
        </Stack>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Button variant="outlined" color="inherit" sx={{ mr: 1 }} onClick={handleBack}>
          Back
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button onClick={handleNext} variant="outlined">
          Next
        </Button>
      </Box>
    </Card>
  );
}

FairPart.propTypes = {
  handleBack: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  project_id: PropTypes.number.isRequired,
  activeDiscipline: PropTypes.number,
  active_term_object: PropTypes.object
};

export default connect((store) => ({
  project_id: store.projectReducer.activeProject,
  activeDiscipline: store.projectReducer.activeDiscipline,
  active_term_object: store.loginReducer.active_term_object
}))(FairPart);
