const HOST = process.env.REACT_APP_REST_API_HOST;

const GET_TERMS = {
  method: 'GET',
  path: `${HOST}go_admin/common/common/term`
};
const GET_GENDER = {
  method: 'GET',
  path: `${HOST}go_admin/common/common/gender`
};
const GET_DISCIPLINE = {
  method: 'GET',
  path: `${HOST}go_admin/common/common/discipline`
};
const GET_CATEGORY = {
  method: 'GET',
  path: `${HOST}go_admin/common/common/category`
};
const GET_AFFILIATED_FAIR = {
  method: 'GET',
  path: `${HOST}go_admin/common/common/fair`
};
const GET_FINALIST_STATUS = {
  method: 'GET',
  path: `${HOST}go_admin/common/common/finalist_status`
};
const GET_REVIEW_STATUS = {
  method: 'GET',
  path: `${HOST}go_admin/common/common/review_status`
};
const GET_DISCIPLINE_FILE_TYPE = {
  method: 'GET',
  path: `${HOST}go_admin/common/common/discipline_file_type`
};

module.exports = {
  GET_TERMS,
  GET_GENDER,
  GET_DISCIPLINE,
  GET_CATEGORY,
  GET_AFFILIATED_FAIR,
  GET_FINALIST_STATUS,
  GET_REVIEW_STATUS,
  GET_DISCIPLINE_FILE_TYPE
};
