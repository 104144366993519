import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Stack } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MuiTable from '../../../components/CustomComponents/MuiTable';
import headers from './utils/headerVariablesForParticipants';
import { fetchPerformanceParticipants } from './utils/serviceCalls';
import { getPerformanceOperationParticipantList } from '../../Admin/PerformanceOperation/serviceCalls';

function ParticipantDialog(props) {
  const { handleClose, addParticipantToList, selectedParticipantList, admin, userId } = props;
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [participants, setParticipants] = useState(null);

  useEffect(() => {
    const method = admin ? getPerformanceOperationParticipantList : fetchPerformanceParticipants;
    const params = admin ? { user_id: userId } : {};
    method(
      params,
      (data) => {
        setParticipants(data);
      },
      () => {}
    );
  }, []);

  useEffect(() => {
    const arr = [];
    selectedParticipantList.forEach((item) => {
      const ind = participants?.findIndex((x) => x.id === item.id);
      arr.push(ind);
    });
    setSelectedStudents(arr);
  }, [participants]);

  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedStudents(rowsSelected);
  };
  function handleAdd() {
    const arr = [];
    selectedStudents.forEach((item) => {
      const participant = participants[item];
      const obj = {
        id: participant.id,
        first_name: participant.first_name,
        last_name: participant.last_name
      };
      arr.push(obj);
    });
    addParticipantToList(arr);
  }

  return (
    <Stack spacing={3}>
      {participants === null ? (
        <> </>
      ) : participants.length === 0 ? (
        <p>You must confirm your participation under participants menu first.</p>
      ) : (
        <MuiTable
          tableData={participants}
          tableColumns={headers}
          customOption={{
            selectableRows: 'multiple',
            onRowSelectionChange,
            rowsSelected: selectedStudents
          }}
        />
      )}
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="end">
        {selectedStudents.length > 0 && (
          <Button variant="outlined" onClick={handleAdd}>
            Add
          </Button>
        )}
        <Button variant="outlined" color="error" onClick={handleClose}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
}

ParticipantDialog.propTypes = {
  handleClose: PropTypes.func,
  addParticipantToList: PropTypes.func,
  selectedParticipantList: PropTypes.array,
  admin: PropTypes.bool,
  userId: PropTypes.number
};

export default connect(() => ({}))(ParticipantDialog);
