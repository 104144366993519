import React, { useEffect, useState } from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import { Button } from '@mui/material';
import { Icon } from '@iconify/react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Page from '../../../components/Page';
import LandingPage from './LandingPage';
import EditPage from './EditPage';
import AddPage from './AddPage';
import { deleteProject, fetchProjectList } from './utils/serviceCalls';
import { setConfirmationAlert, setSnackbar } from '../../../actions/alertActions';
import { fetchSchoolInfo } from '../School/utils/serviceCalls';
import ProcessWarningBox from '../../../components/CustomComponents/CustomCard/ProcessWarningBox';
import { handleProjectEditable } from './utils/termMaker';

// ----------------------------------------------------------------------

const LANDING_PAGE = 0;
const ADD_PAGE = 1;
const EDIT_PAGE = 2;

function Index(props) {
  const { dispatch, active_term, active_term_object } = props;
  const [mode, setMode] = useState(LANDING_PAGE);
  const [projectList, setProjectList] = useState([]);
  const [atReview, setAtReview] = useState(null);
  const [isSchoolExist, setIsSchoolExist] = useState(-1);
  const [guidelines, setGuidelines] = useState([]);
  const isProjectEditable = handleProjectEditable(active_term_object);

  useEffect(() => {
    fetchSchoolInfo(
      {},
      (data) => {
        if (data.length === 0) {
          setIsSchoolExist(0);
        } else {
          setIsSchoolExist(1);
        }
      },
      () => {}
    );
    getList();
  }, []);

  function handleRefresh() {
    dispatch({ type: 'SET_ACTIVE_PROJECT', payload: null });
    setMode(LANDING_PAGE);
    getList();
  }
  function getList() {
    fetchProjectList(
      {},
      (data) => {
        setProjectList(data.data);
        setGuidelines(data.guidelines);
      },
      () => {}
    );
  }
  function handleAddNewProject() {
    setMode(ADD_PAGE);
  }
  function editProject(project_id, at_review) {
    setAtReview(at_review);
    dispatch({ type: 'SET_ACTIVE_PROJECT', payload: project_id });
    setMode(EDIT_PAGE);
  }
  function handleDeleteProject(project_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'This action will delete Project, are you sure?',
        () => {
          deleteProject(
            { project_id },
            () => {
              handleRefresh();
              dispatch(setSnackbar('success', 'The project has been deleted'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }

  return (
    <Page title="Projects | Genius">
      <Container maxWidth="xl">
        <Box sx={{ pb: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h4">
            Projects {mode === ADD_PAGE ? ' / Add New' : mode === EDIT_PAGE ? ' / Edit' : null}
          </Typography>
          {mode !== LANDING_PAGE && (
            <Button
              startIcon={<Icon icon="akar-icons:arrow-back-thick-fill" />}
              variant="outlined"
              onClick={handleRefresh}
            >
              Back
            </Button>
          )}
        </Box>
        {mode === LANDING_PAGE ? (
          isSchoolExist === -1 ? (
            <></>
          ) : isSchoolExist === 0 ? (
            <ProcessWarningBox
              Comp={<p>You MUST complete School registration before you can add any projects.</p>}
            />
          ) : (
            <LandingPage
              handleAddNewProject={handleAddNewProject}
              projectList={projectList}
              editProject={editProject}
              deleteProject={handleDeleteProject}
              guidelines={guidelines}
            />
          )
        ) : mode === ADD_PAGE ? (
          <AddPage handleFinish={handleRefresh} active_term={active_term} guidelines={guidelines} />
        ) : (
          <EditPage
            handleFinish={handleRefresh}
            atReview={atReview}
            active_term={active_term}
            guidelines={guidelines}
            isProjectEditable={isProjectEditable}
          />
        )}
      </Container>
    </Page>
  );
}

Index.propTypes = {
  dispatch: PropTypes.func.isRequired,
  active_term: PropTypes.number,
  active_term_object: PropTypes.object
};

export default connect((store) => ({
  active_term: store.loginReducer.active_term,
  active_term_object: store.loginReducer.active_term_object
}))(Index);
