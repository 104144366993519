const HOST = process.env.REACT_APP_REST_API_HOST;

const GET_REVIEW_ADMIN_REVIEW = {
  method: 'GET',
  path: `${HOST}go_admin/reviewer/review`
};
const PATCH_REVIEW_ADMIN_REVIEW = {
  method: 'PATCH',
  path: `${HOST}go_admin/reviewer/review`
};
const GET_REVIEW_FILE = {
  method: 'GET',
  path: `${HOST}go_admin/reviewer/review/file`
};

module.exports = {
  GET_REVIEW_ADMIN_REVIEW,
  PATCH_REVIEW_ADMIN_REVIEW,
  GET_REVIEW_FILE
};
