export function getSummaryTableData(judgeList) {
  console.log(judgeList, 'judgeList');
  const _disciplineList = new Set();
  const _sessionTypeList = new Set();
  judgeList.forEach((judge) => {
    const disciplineArray = judge.judging_session_disciplines?.split(',');
    // eslint-disable-next-line no-unused-expressions
    disciplineArray &&
      disciplineArray.forEach((discipline) => {
        _disciplineList.add(discipline);
      });
    const typeArray = judge.judging_session_types?.split(',');
    // eslint-disable-next-line no-unused-expressions
    typeArray &&
      typeArray.forEach((type) => {
        _sessionTypeList.add(type);
      });
  });
  const arrayOfDisciplineList = Array.from(_disciplineList);
  const arrayOfTypeList = Array.from(_sessionTypeList);

  console.log(arrayOfDisciplineList, 'arrayOfDisciplineList');
  console.log(arrayOfTypeList, 'arrayOfTypeList');

  const allList = [];
  arrayOfDisciplineList.forEach((discipline) => {
    arrayOfTypeList.forEach((type) => {
      let totalApproved = 0;
      let totalPending = 0;
      judgeList.forEach((judge) => {
        const judgeDisciplineList = judge.judging_session_disciplines?.split(',');
        const judgeTypeList = judge.judging_session_types?.split(',');
        if (
          judgeDisciplineList &&
          judgeDisciplineList.includes(discipline) &&
          judgeTypeList &&
          judgeTypeList.includes(type)
        ) {
          if (judge.judge_approval_status === 'Approved') {
            totalApproved += 1;
          } else if (judge.judge_approval_status === 'Pending') {
            totalPending += 1;
          }
        }
      });
      const obj = {
        discipline,
        type,
        totalApproved,
        totalPending
      };
      allList.push(obj);
    });
  });
  console.log(allList, 'allList');
  return allList;
}
