import * as React from 'react';
import { Button, Stack } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Checkbox, IconButton, InputAdornment, TextField } from '@material-ui/core';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import FormControlLabel from '@mui/material/FormControlLabel';
import { resetPassword } from '../serviceCalls';
import { setSnackbar } from '../../../../actions/alertActions';

function PasswordDialog(props) {
  const { userId, handleClose, handleRefresh, dispatch } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [isSendEmail, setIsSendEmail] = useState(false);

  const RegisterSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, 'Password should be of minimum 6 characters length!')
      .max(500, 'Too Long!')
      .required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      password: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      resetPassword(
        { ...values, user_id: userId, send_email: isSendEmail ? 1 : 0 },
        () => {
          dispatch(setSnackbar('success', 'User password reset'));
          handleRefresh();
        },
        () => {}
      );
    }
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{ mt: 1 }}>
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          <FormControlLabel
            control={
              <Checkbox checked={isSendEmail} onChange={(e) => setIsSendEmail(e.target.checked)} />
            }
            label="Send Email"
          />
          <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="end" spacing={2}>
            <Button type="submit" variant="outlined">
              Reset
            </Button>
            <Button variant="outlined" color="error" onClick={handleClose}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

PasswordDialog.propTypes = {
  handleClose: PropTypes.func,
  handleRefresh: PropTypes.func,
  dispatch: PropTypes.func,
  userId: PropTypes.number
};

export default connect(() => ({}))(PasswordDialog);
