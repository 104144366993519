export default [
  {
    name: 'country',
    label: 'Country'
  },
  {
    name: 'school',
    label: 'School'
  },
  {
    name: 'school_address',
    label: 'Address'
  },
  {
    name: 'detail',
    label: 'Detail',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'total_score',
    label: 'Score'
  },
  {
    name: 'total',
    label: 'Project Count'
  },
  {
    name: 'student_count',
    label: 'Student Count'
  },
  {
    name: 'honorable_mention_count',
    label: 'H.M.C.',
    options: {
      tooltip: 'Honorable Mention Count'
    }
  },
  {
    name: 'honorable_mention_student_count',
    label: 'H.M.S.C.',
    options: {
      tooltip: 'Honorable Mention Student Count'
    }
  },
  {
    name: 'bronze_count',
    label: 'B.C.',
    options: {
      tooltip: 'Bronze Count'
    }
  },
  {
    name: 'bronze_student_count',
    label: 'B.S.C.',
    options: {
      tooltip: 'Bronze Student Count'
    }
  },
  {
    name: 'silver_count',
    label: 'S.C.',
    options: {
      tooltip: 'Silver Count'
    }
  },
  {
    name: 'silver_student_count',
    label: 'S.S.C.',
    options: {
      tooltip: 'Silver Student Count'
    }
  },
  {
    name: 'gold_count',
    label: 'G.C.',
    options: {
      tooltip: 'Gold Count'
    }
  },
  {
    name: 'gold_student_count',
    label: 'G.S.C.',
    options: {
      tooltip: 'Gold Student Count'
    }
  },
  {
    name: 'grand_count',
    label: 'Gr.C.',
    options: {
      tooltip: 'Grand Count'
    }
  },
  {
    name: 'grand_student_count',
    label: 'Gr.S.C.',
    options: {
      tooltip: 'Grand Student Count'
    }
  }
];
