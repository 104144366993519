import React, { useRef, useMemo } from 'react';
import JoditEditor from 'jodit-react';
import { connect } from 'react-redux';

const BodyEditor = (props) => {
  const { placeholders, value, setTemplateBody } = props;
  const editor = useRef(null);

  const config = {
    iframe: true,
    uploader: {
      insertImageAsBase64URI: true,
      error: () => {
        console.log('The image file format you are trying to add is not appropriate!');
      }
    },
    askBeforePasteFromWord: false,
    askBeforePasteHTML: false,
    extraButtons: [
      {
        name: 'Placeholder',
        tooltip: 'Insert Placeholder',
        list: placeholders.map((item) => item.name),
        childTemplate: (editor, key) => `<span>${key}</span>`,
        exec(editor, t, { control }) {
          const value = control.args && control.args[0];
          if (value) {
            editor.selection.insertHTML(`\${${value}}`);
          }
        }
      }
    ],
    readonly: false
  };

  return useMemo(
    () => (
      <JoditEditor
        ref={editor}
        value={value}
        config={config}
        onBlur={(newContent) => setTemplateBody(newContent)}
      />
    ),
    [value, placeholders]
  );
};

export default connect(() => ({}))(BodyEditor);
