import React, { useEffect, useState } from 'react';
import { Button, DialogContent, Stack, Tooltip } from '@mui/material';
import { IconButton, MenuItem, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Icon } from '@iconify/react';
import Email from '@mui/icons-material/Email';
import ReactHtmlParser from 'react-html-parser';
import MuiTable from '../../../../../components/CustomComponents/MuiTable';
import ProcessInfoBox from '../../../../../components/CustomComponents/CustomCard/ProcessInfoBox';
import SummaryTable from '../../../../../components/CustomComponents/CustomCard/AwardSummaryTable';
import { getSchoolReports } from '../../_apis/serviceCalls';
import headerVariables from './headerVariables';
import { getSummaryTableData } from '../_utils/getSummaryData';
import CustomDialog from '../../../../../components/CustomComponents/CustomDialog';
import ProjectListDialog from '../CountryReports/ProjectListDialog';
import CustomMail from '../../../../../components/CustomComponents/CustomMail';
import { CUSTOM_MAIL_TYPE } from '../../../../../api/RestVariables/staticVariables';

function Index(props) {
  const { terms, active_term, isCommunication } = props;
  const [term, setTerm] = useState(active_term);
  const [countryProjectList, setCountryProjectList] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [projectDialog, setProjectDialog] = useState(false);
  const [param, setParam] = useState([]);
  const [mailDialogShow, setMailDialogShow] = useState(false);
  const [addMail, setAddMail] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [idList, setIdList] = useState([]);
  const [guidelines, setGuidelines] = useState([]);

  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedItems(rowsSelected);
    const arr = [];
    rowsSelected.forEach((item) => {
      arr.push(countryProjectList[item].user_id);
    });
    setIdList(arr);
  };

  useEffect(() => {
    getSchoolReports(
      { term_id: term },
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        const _summaryData = getSummaryTableData(list);
        setSummaryData(_summaryData);
        list.forEach((item) => {
          item.detail = (
            <IconButton color="secondary" onClick={() => getProjectDetail(item)}>
              <Icon icon="bx:bx-comment-detail" width={24} height={24} />
            </IconButton>
          );
          item.total_score =
            item.total * 1 +
            item.honorable_mention_count * 1 +
            item.bronze_count * 2 +
            item.silver_count * 3 +
            item.gold_count * 5 +
            item.grand_count * 6;
        });
        setCountryProjectList(list);
      },
      () => {}
    );
  }, [term]);

  function filterChange(displayData) {
    const arr = [];
    displayData.forEach((item) => {
      arr.push(countryProjectList[item.dataIndex]);
    });
    const _summaryData = getSummaryTableData(arr);
    setSummaryData(_summaryData);
  }

  function getProjectDetail(school) {
    const _param = {
      term_id: term,
      school_id: school.school_id
    };
    setParam(_param);
    setProjectDialog(true);
  }

  function TermSelect() {
    return (
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={2}
        sx={{ mt: 2 }}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <TextField select label="Terms" value={term} onChange={(e) => setTerm(e.target.value)}>
          {terms.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
        {addMail && (
          <div>
            <Button
              style={{ marginRight: 5 }}
              disabled={selectedItems.length === 0}
              variant="outlined"
              onClick={() => setMailDialogShow(true)}
            >
              Next
            </Button>
            <Button color="error" variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        )}
      </Stack>
    );
  }

  function handleRefresh() {
    setSelectedItems([]);
    setMailDialogShow(false);
    setAddMail(false);
  }

  function handleCancel() {
    setSelectedItems([]);
    setMailDialogShow(false);
    setAddMail(false);
  }

  return (
    <Stack spacing={2}>
      {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
      <ProcessInfoBox Comp={<SummaryTable data={summaryData} />} title="Summary" />
      <MuiTable
        title={<TermSelect />}
        tableData={countryProjectList}
        tableColumns={headerVariables}
        customOption={{
          selectableRows: addMail ? 'multiple' : 'none',
          onRowSelectionChange,
          rowsSelected: selectedItems,
          customToolbar: () =>
            isCommunication !== -1 && (
              <Tooltip title="Send Email">
                <IconButton onClick={() => setAddMail(true)}>
                  <Email />
                </IconButton>
              </Tooltip>
            ),
          onFilterChange: (changedColumn, filterList, type, changedColumnIndex, displayData) => {
            filterChange(displayData);
            console.log(changedColumn);
            console.log(filterList);
            console.log(displayData);
          }
        }}
      />
      <CustomDialog
        onClose={() => setProjectDialog(false)}
        open={projectDialog}
        maxWidth="lg"
        title="Project List"
        fullWidth
      >
        <DialogContent>
          <ProjectListDialog param={param} />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        onClose={() => setMailDialogShow(false)}
        open={mailDialogShow}
        maxWidth="lg"
        title="Send Mail"
        fullWidth
        disableEnforceFocus
      >
        <DialogContent>
          <CustomMail
            handleRefresh={handleRefresh}
            mailType={CUSTOM_MAIL_TYPE}
            idList={idList}
            handleClose={() => setMailDialogShow(false)}
          />
        </DialogContent>
      </CustomDialog>
    </Stack>
  );
}

Index.propTypes = {
  terms: PropTypes.array,
  active_term: PropTypes.number,
  isCommunication: PropTypes.bool
};

export default connect((store) => ({
  terms: store.loginReducer.terms,
  active_term: store.loginReducer.active_term,
  isCommunication: store.routeReducer.isCommunication
}))(Index);
