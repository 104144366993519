import { connect } from 'react-redux';
import { MenuItem, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import ReactHtmlParser from 'react-html-parser';
import { setLoading } from '../../../../actions/alertActions';
import { getReportList } from '../_apis/serviceCalls';
import ProcessInfoBox from '../../../../components/CustomComponents/CustomCard/ProcessInfoBox';
import ReportTable from './ReportTable';

function Report(props) {
  const { terms, active_term, dispatch } = props;
  const [term, setTerm] = useState(active_term);
  const [surveyList, setSurveyList] = useState([]);
  const [guidelines, setGuidelines] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = useState(null);

  useEffect(() => {
    fetchData();
    setSelectedSurvey(null);
  }, [term]);

  function fetchData() {
    dispatch(setLoading(1, 'Loading...'));
    getReportList({ term_id: term }, (data) => {
      setGuidelines(data.guidelines);
      setSurveyList(data.data);
    }).then(() => dispatch(setLoading(0, '')));
  }

  return (
    <Stack spacing={2}>
      {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
      <p>Please select term and survey to review report.</p>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <TextField
          fullWidth
          select
          label="Terms"
          value={term}
          onChange={(e) => setTerm(e.target.value)}
        >
          {terms.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          select
          label="Surveys"
          value={selectedSurvey}
          onChange={(e) => setSelectedSurvey(e.target.value)}
        >
          {surveyList.map((item) => (
            <MenuItem key={item.survey_id} value={item.survey_id}>
              {item.survey_name}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
      {selectedSurvey && <ReportTable key={selectedSurvey} id={selectedSurvey} />}
    </Stack>
  );
}

Report.propTypes = {
  terms: PropTypes.array,
  active_term: PropTypes.number,
  dispatch: PropTypes.func
};

export default connect((store) => ({
  terms: store.loginReducer.terms,
  active_term: store.loginReducer.active_term
}))(Report);
