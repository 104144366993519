import * as React from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import FormControl from '@mui/material/FormControl';
import { FormLabel, Radio, RadioGroup, Stack } from '@material-ui/core';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/styles';
import { blue } from '@material-ui/core/colors';
import { scoreJudging } from '../../_apis/serviceCalls';

const useStyles = makeStyles(() => ({
  avatar: {
    backgroundColor: blue[500]
  },
  cardAction: {
    float: 'right'
  }
}));

function QuestionViewDetail(props) {
  const { question, handleSelectScore } = props;
  const [value, setValue] = useState(question.score ? question.score.toString() : '');
  const { scale } = question;
  const classes = useStyles();

  const scaleList = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < scale + 1; i++) {
    scaleList.push(i.toString());
  }

  const handleChange = (event) => {
    const param = {
      judging_id: question.judging_id,
      judging_form_question_id: question.judging_form_question_id,
      score: event.target.value
    };
    scoreJudging(
      param,
      () => {
        setValue(event.target.value);
        handleSelectScore(question.judging_form_question_id);
      },
      () => {}
    );
  };
  return (
    <Card className="drag-drop-inside-card-style">
      <CardHeader
        title={<div>{ReactHtmlParser(question?.markdown)}</div>}
        avatar={<Avatar className={classes.avatar}>{`${question.rank}`}</Avatar>}
      />
      <CardContent>
        <Stack spacing={1}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Score</FormLabel>
            <RadioGroup
              row
              aria-label="score"
              name="row-radio-buttons-group"
              value={value}
              onChange={handleChange}
            >
              {scaleList.map((scale, index) => (
                <FormControlLabel key={index} value={scale} control={<Radio />} label={scale} />
              ))}
            </RadioGroup>
          </FormControl>
        </Stack>
      </CardContent>
    </Card>
  );
}

QuestionViewDetail.propTypes = {
  question: PropTypes.object,
  handleSelectScore: PropTypes.func
};

export default QuestionViewDetail;
