import { Button, DialogContent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Icon } from '@iconify/react';
import { IconButton, Stack } from '@material-ui/core';
import MuiTable from '../../../../../components/CustomComponents/MuiTable';
import headers from './headerVariablesForProjects';
import Label from '../../../../../components/Label';
import { fCurrency } from '../../../../../utils/formatNumber';
import {
  deleteAwardJudgingDetail,
  getAwardJudgingDetail,
  getProjectList
} from '../../_apis/serviceCalls';
import CustomDialog from '../../../../../components/CustomComponents/CustomDialog';
import JudgingDetailDialog from '../../Award/DetailView/JudgingDetailDialog';
import { setConfirmationAlert, setSnackbar } from '../../../../../actions/alertActions';
import MedalDialog from '../../Award/MedalTypeView/MedalDialog';

function Index(props) {
  const { dispatch, param } = props;
  const [tableData, setTableData] = useState([]);
  const [judgingDetail, setJudgingDetail] = useState([]);
  const [judgingDialog, setJudgingDialog] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [medalDialog, setMedalDialog] = useState(false);
  const [addMedal, setAddMedal] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedItems(rowsSelected);
  };
  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    getProjectList(
      param,
      (data) => {
        data.forEach((item, index) => {
          const _balance = Number(parseFloat(item.balance).toFixed(2));
          const _project_student_total_balance = Number(
            parseFloat(item.project_student_total_balance).toFixed(2)
          );
          item.balance_comp =
            _balance < 0 || _balance === 0 ? (
              <Label variant="ghost" color="success">
                {fCurrency(item.balance)}
              </Label>
            ) : (
              <Label variant="ghost" color="error">
                {fCurrency(item.balance)}
              </Label>
            );
          item.balance_filter = _balance < 0 || _balance === 0 ? 'Paid' : 'Not Paid';
          item.balance_comp_student =
            _project_student_total_balance < 0 || _project_student_total_balance === 0 ? (
              <Label variant="ghost" color="success">
                {fCurrency(item.project_student_total_balance)}
              </Label>
            ) : (
              <Label variant="ghost" color="error">
                {fCurrency(item.project_student_total_balance)}
              </Label>
            );
          item.balance_filter_student =
            _project_student_total_balance < 0 || _project_student_total_balance === 0
              ? 'Paid'
              : 'Not Paid';
          item.presentation_link_comp = item.presentation_link && (
            <Label variant="ghost" color="primary">
              <a target="_blank" href={item.presentation_link} rel="noreferrer">
                Link
              </a>
            </Label>
          );
          item.presentation_link_filter = item.presentation_link ? 'Exist' : 'None';
          item.media_link_comp = item.media_link && (
            <Label variant="ghost" color="primary">
              <a target="_blank" href={item.media_link} rel="noreferrer">
                Link
              </a>
            </Label>
          );
          item.media_link_filter = item.media_link ? 'Exist' : 'None';
          item.judging_detail = (
            <IconButton
              disabled={!item.completed_judging}
              color="secondary"
              onClick={() => viewJudgingDetail(item.id)}
            >
              <Icon icon="bx:bx-comment-detail" width={24} height={24} />
            </IconButton>
          );
          item.medal_type_change = (
            <IconButton
              disabled={!item.completed_judging}
              onClick={() => handleAddMedal(index)}
              color="secondary"
            >
              <Icon icon="ic:baseline-published-with-changes" width={24} height={24} />
            </IconButton>
          );
          item.average_score_comp = item.average_score
            ? parseFloat(item.average_score).toFixed(2)
            : '-';
        });
        setTableData(data);
      },
      () => {}
    );
  }

  function viewJudgingDetail(project_id) {
    setSelectedProjectId(project_id);
    getAwardJudgingDetail(
      { project_id },
      (data) => {
        setJudgingDetail(data);
        setJudgingDialog(true);
      },
      () => {}
    );
  }

  function deleteJudging(judging_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'This action will delete judging',
        () => {
          deleteAwardJudgingDetail(
            { judging_id },
            () => {
              dispatch(setSnackbar('success', 'The judging has been deleted'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
              viewJudgingDetail(selectedProjectId);
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }

  function handleAddMedal(index) {
    setMedalDialog(true);
    setSelectedItems([index]);
  }

  function handleRefresh() {
    setSelectedItems([]);
    setSelectedItems([]);
    setSelectedProjectId(null);
    setMedalDialog(false);
    setAddMedal(false);
    fetchData();
  }

  function TermSelect() {
    return (
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={2}
        sx={{ mt: 2 }}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        {!addMedal && (
          <Button color="secondary" variant="outlined" onClick={() => setAddMedal(true)}>
            Medal Type Change
          </Button>
        )}
        {addMedal && (
          <div>
            <Button
              color="secondary"
              variant="outlined"
              disabled={startButtonDisabled}
              onClick={() => setMedalDialog(true)}
            >
              Next
            </Button>
            <Button
              variant="outlined"
              color="error"
              style={{ marginLeft: 10 }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        )}
      </Stack>
    );
  }

  const handleCancel = () => {
    setSelectedItems([]);
    setAddMedal(false);
  };

  const startButtonDisabled = selectedItems.length === 0;

  return (
    <>
      <MuiTable
        tableData={tableData}
        tableColumns={headers}
        title={<TermSelect />}
        customOption={{
          selectableRows: addMedal ? 'multiple' : 'none',
          onRowSelectionChange,
          rowsSelected: selectedItems,
          isRowSelectable: (dataIndex) => tableData[dataIndex].completed_judging
        }}
      />
      <CustomDialog
        onClose={() => setJudgingDialog(false)}
        open={judgingDialog}
        maxWidth="md"
        fullWidth
        title="Judging Detail"
      >
        <DialogContent>
          <JudgingDetailDialog
            data={judgingDetail}
            handleClose={() => setJudgingDialog(false)}
            deleteJudging={deleteJudging}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        onClose={() => setMedalDialog(false)}
        open={medalDialog}
        maxWidth="lg"
        title="Medal Type Change"
      >
        <DialogContent>
          <MedalDialog
            tableData={tableData}
            handleClose={() => setMedalDialog(false)}
            selectedItems={selectedItems}
            handleRefresh={handleRefresh}
          />
        </DialogContent>
      </CustomDialog>
    </>
  );
}

Index.propTypes = {
  dispatch: PropTypes.func,
  param: PropTypes.object
};

export default connect(() => ({}))(Index);
