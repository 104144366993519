export default [
  {
    name: 'id',
    label: 'ID'
  },
  {
    name: 'travel_type',
    label: 'Travel Type'
  },
  {
    name: 'participants_comp',
    label: 'Participants',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'participant_names',
    label: 'Participants',
    options: {
      display: false
    }
  },
  {
    name: 'travel_option',
    label: 'Travel Option'
  },
  {
    name: 'travel_airline',
    label: 'Airline'
  },
  {
    name: 'travel_airport',
    label: 'Airport'
  },
  {
    name: 'travel_flight_number',
    label: 'Flight Number'
  },
  {
    name: 'travel_date',
    label: 'Date'
  },
  {
    name: 'travel_time',
    label: 'Time'
  }
];
