import React, { useEffect } from 'react';

import './style.css';

const GoogleTranslateWidget = () => {
  const googleTranslateElementInit = () => {
    /* eslint-disable no-new */
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en',
        autoDisplay: false
      },
      'google_translate_element'
    );
  };
  useEffect(() => {
    const addScript = document.createElement('script');
    addScript.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);
  return <div id="google_translate_element" />;
};
export default GoogleTranslateWidget;
