export default [
  {
    name: 'participant_id',
    label: 'ID'
  },
  {
    name: 'first_name',
    label: 'First Name'
  },
  {
    name: 'last_name',
    label: 'Last Name'
  },
  {
    name: 'project_id',
    label: 'Project ID'
  },
  {
    name: 'project_title',
    label: 'Project Title'
  },
  {
    name: 'participant_type',
    label: 'Type'
  },
  {
    name: 'grade_level',
    label: 'Grade Level'
  },
  {
    name: 'date_of_birth_comp',
    label: 'Date of Birth'
  },
  {
    name: 'delete',
    label: 'Delete'
  }
];
