export default [
  {
    name: 'student_id',
    label: 'ID'
  },
  {
    name: 'project_titles',
    label: 'Project Title'
  },
  {
    name: 'name',
    label: 'Student Name'
  },
  {
    name: 'gender_name',
    label: 'Gender'
  },
  {
    name: 'grade_level',
    label: 'Grade Level'
  },
  {
    name: 'date_of_birth',
    label: 'Date of Birth'
  }
];
