import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { InfoOutlined } from '@material-ui/icons';
import { Stack } from '@material-ui/core';
import PropTypes from 'prop-types';

const style = {
  root: {
    width: 'auto',
    marginTop: 30,
    paddingBottom: 10,
    backgroundColor: '#ffffff'
  },
  title: {
    color: '#B72136',
    fontSize: '1rem',
    marginTop: 2
  }
};

function ProcessErrorBox(props) {
  const { Comp } = props;
  return (
    <Card style={style.root} variant="outlined">
      <CardContent
        style={{
          paddingTop: 0,
          paddingBottom: 0
        }}
      >
        <Stack spacing={2}>
          <div
            style={{
              height: 5,
              background: 'linear-gradient(90deg, #B72136, #FFE7D9)',
              marginLeft: -20,
              marginRight: -20
            }}
          />
          <Stack direction="row" spacing={1} justifyContent="space-between">
            <Stack direction="row" spacing={1}>
              <InfoOutlined style={{ color: '#B72136' }} />
              <Typography variant="h6" component="h2" style={style.title}>
                Error
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        {Comp}
      </CardContent>
    </Card>
  );
}

ProcessErrorBox.propTypes = {
  Comp: PropTypes.object
};

export default ProcessErrorBox;
