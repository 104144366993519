import { DialogContent } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import CustomDialog from '../../../../../components/CustomComponents/CustomDialog';
import JudgingDetailDialog from './JudgingDetailDialog';

function Index(props) {
  const { setJudgingDialog, judgingDialog, deleteJudging, deleteRobotics, judgingDetail } = props;

  return (
    <CustomDialog
      onClose={() => setJudgingDialog(false)}
      open={judgingDialog}
      maxWidth="md"
      fullWidth
      title="Judging Detail"
    >
      <DialogContent>
        <JudgingDetailDialog
          data={judgingDetail}
          handleClose={() => setJudgingDialog(false)}
          deleteJudging={deleteJudging}
          deleteRobotics={deleteRobotics}
        />
      </DialogContent>
    </CustomDialog>
  );
}

Index.propTypes = {
  setJudgingDialog: PropTypes.func,
  judgingDialog: PropTypes.bool,
  judgingDetail: PropTypes.array,
  deleteJudging: PropTypes.func,
  deleteRobotics: PropTypes.func
};

export default Index;
