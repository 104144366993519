import { Stack } from '@material-ui/core';
import React from 'react';
import Label from '../../../../components/Label';

export default function fileMaker(fileList, downloadFile) {
  const nameList = nameMaker(fileList);
  return (
    <Stack direction={{ xs: 'row', sm: 'column' }} spacing={1}>
      {nameList.map((file, index) => (
        <Label
          key={index}
          style={{ cursor: 'pointer' }}
          variant="ghost"
          color="secondary"
          onClick={() => downloadFile(file)}
        >
          {file.rank !== 1 ? `${file.file_type} ${file.rank}` : `${file.file_type}`}
        </Label>
      ))}
    </Stack>
  );
}

function nameMaker(fileList) {
  const nameList = [];
  fileList.forEach((file, index) => {
    if (index === 0) {
      nameList.push({ ...file, rank: 1 });
    } else {
      let counter = 1;
      nameList.forEach((name) => {
        if (file.file_type === name.file_type) {
          counter += 1;
        }
      });
      nameList.push({ ...file, rank: counter });
    }
  });
  return nameList;
}
