import { Stack } from '@material-ui/core';
import { Button, DialogContent } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import CustomDialog from '../../../../components/CustomComponents/CustomDialog';
import ProcessInfoBox from '../../../../components/CustomComponents/CustomCard/ProcessInfoBox';

function ViewDialog(props) {
  const { selectedGuideline, handleClose } = props;

  return (
    <CustomDialog onClose={handleClose} title="View" fullWidth maxWidth="lg">
      <DialogContent>
        <Stack spacing={1}>
          <ProcessInfoBox Comp={ReactHtmlParser(selectedGuideline.markdown)} />
          <div style={{ textAlign: 'end' }}>
            <Button size="small" color="error" onClick={handleClose} variant="outlined">
              Cancel
            </Button>
          </div>
        </Stack>
      </DialogContent>
    </CustomDialog>
  );
}

ViewDialog.propTypes = {
  selectedGuideline: PropTypes.object,
  handleClose: PropTypes.func.isRequired
};

export default connect(() => ({}))(ViewDialog);
