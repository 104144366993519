import { fetchApi } from '../../../../../api/fetchApi';

const robotics = require('./robotics_apis');

export async function getRoboticsForm(config, scb, ecb) {
  try {
    const url = robotics.GET_ROBOTICS_FORM;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_ROBOTICS_FORM');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteRoboticsForm(config, scb, ecb) {
  try {
    const url = robotics.DELETE_ROBOTICS_FORM;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_ROBOTICS_FORM');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getRoboticsFormRubricType(config, scb, ecb) {
  try {
    const url = robotics.GET_ROBOTICS_FORM_RUBRIC_TYPE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_ROBOTICS_FORM_RUBRIC_TYPE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addRoboticsForm(config, scb, ecb) {
  try {
    const url = robotics.ADD_ROBOTICS_FORM;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_ROBOTICS_FORM');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addRoboticsFormRubric(config, scb, ecb) {
  try {
    const url = robotics.ADD_ROBOTICS_FORM_RUBRIC;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_ROBOTICS_FORM_RUBRIC');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getRoboticsFormDetail(config, scb, ecb) {
  try {
    const url = robotics.GET_ROBOTICS_FORM_DETAIL;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_ROBOTICS_FORM_DETAIL');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateRoboticsFormRubric(config, scb, ecb) {
  try {
    const url = robotics.UPDATE_ROBOTICS_FORM_RUBRIC;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_ROBOTICS_FORM_RUBRIC');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateRoboticsFormRubricRank(config, scb, ecb) {
  try {
    const url = robotics.CHANGE_ROBOTICS_FORM_RUBRIC;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'CHANGE_ROBOTICS_FORM_RUBRIC');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteRoboticsFormRubric(config, scb, ecb) {
  try {
    const url = robotics.DELETE_ROBOTICS_FORM_RUBRIC;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_ROBOTICS_FORM_RUBRIC');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}
