import { Box, Container, Typography } from '@material-ui/core';
import { Button } from '@mui/material';
import * as React from 'react';
import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Page from '../../../components/Page';
import LandingPage from './LandingPage';
import { fetchPerformance } from './utils/serviceCalls';
import AddUpdateStepper from './AddUpdateStepper';

const LANDING_PAGE = 0;
const ADD_MODE = 1;
const EDIT_MODE = 2;

function Index(props) {
  const { dispatch } = props;
  const [mode, setMode] = useState(LANDING_PAGE);
  const [performanceList, setPerformanceList] = useState([]);
  const [performanceDataForEdit, setPerformanceDataForEdit] = useState(null);
  const [guidelines, setGuidelines] = useState([]);
  useEffect(() => {
    getList();
  }, []);

  function handleRefresh() {
    setMode(LANDING_PAGE);
    setPerformanceDataForEdit(null);
    getList();
  }

  function handleEditPerformance(performance) {
    dispatch({ type: 'SET_ACTIVE_PERFORMANCE', payload: performance.id });
    setMode(EDIT_MODE);
    setPerformanceDataForEdit(performance);
  }

  function getList() {
    fetchPerformance(
      {},
      (data) => {
        setGuidelines(data.guidelines);
        setPerformanceList(data.data);
      },
      () => {}
    );
  }

  return (
    <Page title="Performance | Genius">
      <Container maxWidth="xl">
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h4">
            Performance {/* eslint-disable-next-line no-nested-ternary */}
            {mode === ADD_MODE
              ? ' / Add New Performance'
              : mode === EDIT_MODE
              ? ' / Edit Performance'
              : null}
          </Typography>
          {mode !== LANDING_PAGE && (
            <Button
              startIcon={<Icon icon="akar-icons:arrow-back-thick-fill" />}
              variant="outlined"
              onClick={handleRefresh}
            >
              Back
            </Button>
          )}
        </Box>
        {mode === LANDING_PAGE ? (
          <LandingPage
            handleAddPerformance={() => setMode(ADD_MODE)}
            handleEditPerformance={handleEditPerformance}
            performanceList={performanceList}
            refreshData={getList}
            guidelines={guidelines}
          />
        ) : (
          <AddUpdateStepper
            handleFinish={handleRefresh}
            mode={mode}
            data={performanceDataForEdit}
          />
        )}
      </Container>
    </Page>
  );
}

Index.propTypes = {
  dispatch: PropTypes.func
};

export default connect(() => ({}))(Index);
