import { fetchApi } from '../../../../api/fetchApi';

const award = require('./apis');

export async function getPartnerConfig(config, scb, ecb) {
  try {
    const url = award.GET_PARTNER_CONFIG;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_PARTNER_CONFIG');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updatePartnerConfig(config, scb, ecb) {
  try {
    const url = award.UPDATE_PARTNER_CONFIG;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_PARTNER_CONFIG');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getPartnerReport(config, scb, ecb) {
  try {
    const url = award.GET_PARTNER_REPORT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_PARTNER_REPORT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function approvePartnerReport(config, scb, ecb) {
  try {
    const url = award.APPROVE_PARTNER_REPORT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'APPROVE_PARTNER_REPORT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}
