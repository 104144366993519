export default [
  {
    name: 'participant_id',
    label: 'Participant ID'
  },
  {
    name: 'name',
    label: 'Name'
  },
  {
    name: 'school_name',
    label: 'School'
  },
  {
    name: 'transaction_id_comp',
    label: 'Transaction ID'
  },
  {
    name: 'description',
    label: 'Description'
  },
  {
    name: 'transaction_date_comp',
    label: 'Transaction Date'
  },
  {
    name: 'country',
    label: 'Country'
  },
  {
    name: 'project_id',
    label: 'Project ID'
  },
  {
    name: 'total_refund',
    label: 'Total Refund'
  },
  {
    name: 'amount',
    label: 'Amount'
  },
  {
    name: 'balance',
    label: 'Balance'
  },
  {
    name: 'payment_link',
    label: 'Payment Link',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'add_money',
    label: 'Add Payment',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'refund',
    label: 'Refund',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  }
];
