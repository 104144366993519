const HOST = process.env.REACT_APP_REST_API_HOST;

const GET_FEATURE = {
  method: 'GET',
  path: `${HOST}go_admin/user_management/feature`
};
const ADD_ROLE = {
  method: 'POST',
  path: `${HOST}go_admin/user_management/role`
};
const UPDATE_ROLE = {
  method: 'PUT',
  path: `${HOST}go_admin/user_management/role`
};
const DELETE_ROLE = {
  method: 'DELETE',
  path: `${HOST}go_admin/user_management/role`
};
const GET_ROLE = {
  method: 'GET',
  path: `${HOST}go_admin/user_management/role`
};
const COPY_ROLE = {
  method: 'POST',
  path: `${HOST}go_admin/user_management/role/copy`
};
const ADD_ROLE_FEATURE = {
  method: 'POST',
  path: `${HOST}go_admin/user_management/role_feature`
};
const DELETE_ROLE_FEATURE = {
  method: 'DELETE',
  path: `${HOST}go_admin/user_management/role_feature`
};
const GET_ROLE_FEATURE = {
  method: 'GET',
  path: `${HOST}go_admin/user_management/role_feature`
};
const SET_ROLE_FEATURE = {
  method: 'POST',
  path: `${HOST}go_admin/user_management/role_feature/set`
};
// USER
const ADD_USER = {
  method: 'POST',
  path: `${HOST}go_admin/user_management/user`
};
const GET_USER = {
  method: 'GET',
  path: `${HOST}go_admin/user_management/user`
};
const UPDATE_USER = {
  method: 'PUT',
  path: `${HOST}go_admin/user_management/user`
};
const DELETE_USER = {
  method: 'DELETE',
  path: `${HOST}go_admin/user_management/user`
};
const ADD_USER_ROLE = {
  method: 'POST',
  path: `${HOST}go_admin/user_management/user_role`
};
const GET_USER_ROLE = {
  method: 'GET',
  path: `${HOST}go_admin/user_management/user_role`
};
const DELETE_USER_ROLE = {
  method: 'DELETE',
  path: `${HOST}go_admin/user_management/user_role`
};
const GET_USER_LIST_FEATURE = {
  method: 'GET',
  path: `${HOST}go_admin/user_management/user_role/list_feature`
};
const SET_USER_DEFAULT = {
  method: 'PATCH',
  path: `${HOST}go_admin/user_management/user_role/set_default`
};
const REVOKE_USER = {
  method: 'DELETE',
  path: `${HOST}go_admin/user_management/user_role/revoke`
};
const UNLOCK_USER = {
  method: 'POST',
  path: `${HOST}go_admin/user_management/user_role/unlock`
};
const VERIFY_USER = {
  method: 'POST',
  path: `${HOST}go_admin/user_management/user_role/verify`
};
const RESET_PASSWORD = {
  method: 'PATCH',
  path: `${HOST}go_admin/user_management/user/password`
};

module.exports = {
  GET_FEATURE,
  GET_ROLE_FEATURE,
  ADD_ROLE,
  UPDATE_ROLE,
  DELETE_ROLE,
  GET_ROLE,
  COPY_ROLE,
  ADD_ROLE_FEATURE,
  DELETE_ROLE_FEATURE,
  SET_ROLE_FEATURE,
  ADD_USER,
  GET_USER,
  DELETE_USER,
  ADD_USER_ROLE,
  GET_USER_ROLE,
  DELETE_USER_ROLE,
  GET_USER_LIST_FEATURE,
  SET_USER_DEFAULT,
  REVOKE_USER,
  UPDATE_USER,
  UNLOCK_USER,
  RESET_PASSWORD,
  VERIFY_USER
};
