import React, { useState } from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import { Button, Grid } from '@mui/material';
import { Icon } from '@iconify/react';
import Page from '../../../components/Page';
import School from './School';
import Project from './Project';
import Participant from './Participant';
import LandingPageCard from '../../../components/CustomComponents/CustomCard/LandingPageCard';

const CARDS = [
  {
    id: 1,
    name: 'School',
    description: 'From this module you can arrange user roles.'
  },
  {
    id: 2,
    name: 'Project',
    description: 'From this module you can arrange features of all users.'
  },
  {
    id: 3,
    name: 'Participant',
    description: 'From this module you can arrange features of all users.'
  }
];

const LANDING_PAGE = 0;
const SCHOOL_PAGE = 1;
const PROJECT_PAGE = 2;
const PARTICIPANT_PAGE = 3;

function Index() {
  const [mode, setMode] = useState(LANDING_PAGE);

  function generate(data) {
    if (data.id === 1) {
      setMode(SCHOOL_PAGE);
    } else if (data.id === 2) {
      setMode(PROJECT_PAGE);
    } else {
      setMode(PARTICIPANT_PAGE);
    }
  }

  return (
    <Page title="Data Recovery | Genius">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h4">
            Data Recovery {/* eslint-disable-next-line no-nested-ternary */}
            {mode === SCHOOL_PAGE
              ? ' / School'
              : mode === PROJECT_PAGE
              ? ' / Project'
              : mode === PARTICIPANT_PAGE
              ? ' / Participant'
              : null}
          </Typography>
          {mode !== LANDING_PAGE && (
            <Button
              startIcon={<Icon icon="akar-icons:arrow-back-thick-fill" />}
              variant="outlined"
              onClick={() => setMode(LANDING_PAGE)}
            >
              Back
            </Button>
          )}
        </Box>
        {mode === LANDING_PAGE ? (
          <Grid container spacing={2}>
            {CARDS.map((item, index) => (
              <Grid item xs={12} md={6} lg={4} xl={3} key={index}>
                <LandingPageCard data={item} generate={generate} />
              </Grid>
            ))}
          </Grid>
        ) : mode === SCHOOL_PAGE ? (
          <School />
        ) : mode === PROJECT_PAGE ? (
          <Project />
        ) : (
          <Participant />
        )}
      </Container>
    </Page>
  );
}

export default Index;
