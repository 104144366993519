const HOST = process.env.REACT_APP_REST_API_HOST;

const ADD_TRAVEL = {
  method: 'POST',
  path: `${HOST}go_core/travel/travel`
};
const UPDATE_TRAVEL = {
  method: 'PUT',
  path: `${HOST}go_core/travel/travel`
};
const DELETE_TRAVEL = {
  method: 'DELETE',
  path: `${HOST}go_core/travel/travel`
};
const GET_TRAVEL = {
  method: 'GET',
  path: `${HOST}go_core/travel/travel`
};
const GET_TRAVEL_TYPE = {
  method: 'GET',
  path: `${HOST}go_core/travel/travel/type`
};
const GET_TRAVEL_OPTION = {
  method: 'GET',
  path: `${HOST}go_core/travel/travel/option`
};
const GET_TRAVEL_PARTICIPANT = {
  method: 'GET',
  path: `${HOST}go_core/travel/travel/participant`
};
const ADD_TRAVEL_PARTICIPANT = {
  method: 'POST',
  path: `${HOST}go_core/travel/travel/participant`
};

module.exports = {
  ADD_TRAVEL,
  UPDATE_TRAVEL,
  DELETE_TRAVEL,
  GET_TRAVEL,
  GET_TRAVEL_TYPE,
  GET_TRAVEL_OPTION,
  GET_TRAVEL_PARTICIPANT,
  ADD_TRAVEL_PARTICIPANT
};
