import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { IconButton, MenuItem, Stack, TextField } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { Button, DialogContent, Tooltip } from '@mui/material';
import Email from '@mui/icons-material/Email';
import CircularProgress from '@mui/material/CircularProgress';
import ReactHtmlParser from 'react-html-parser';
import MuiTable from '../../../../components/CustomComponents/MuiTable';
import {
  downloadCertificate,
  getBadge,
  getForm,
  getParticipantOperationList,
  getParticipantOperationParticipant,
  updateParticipantOperationAttendance
} from '../serviceCalls';
import headers from './utils/headerVariables';
import CustomDialog from '../../../../components/CustomComponents/CustomDialog';
import AddUpdateDialog from './AddUpdateDialog';
import { fetchGender } from '../../ProjectOperation/serviceCalls';
import { setConfirmationAlert, setSnackbar } from '../../../../actions/alertActions';
import CustomMail from '../../../../components/CustomComponents/CustomMail';
import { PARTICIPANT_MAIL_TYPE } from '../../../../api/RestVariables/staticVariables';
import Page from '../../../../components/Page';
import Label from '../../../../components/Label';
import { fCurrency } from '../../../../utils/formatNumber';
import AttDialog from './AttDialog';
import PaymentDialog from './AddPaymentDialog';
import ProcessInfoBox from '../../../../components/CustomComponents/CustomCard/ProcessInfoBox';

function Index(props) {
  const { dispatch, terms, active_term, isCommunication } = props;
  const [term, setTerm] = useState(active_term);
  const [tableData, setTableData] = useState([]);
  const [addUpdateDialog, setAddUpdateDialog] = useState(false);
  const [participantData, setParticipantData] = useState(null);
  const [genderList, setGenderList] = useState([]);
  const [mailDialogShow, setMailDialogShow] = useState(false);
  const [addMail, setAddMail] = useState(false);
  const [addAtt, setAddAtt] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [idList, setIdList] = useState([]);
  const [attDialog, setAttDialog] = useState(false);
  const [addMoneyData, setAddMoneyData] = useState(null);
  const [paymentDialogShow, setPaymentDialogShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [guidelines, setGuidelines] = useState([]);
  const [badge, setBadge] = useState(false);
  const [form, setForm] = useState(false);
  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedItems(rowsSelected);
    const arr = [];
    rowsSelected.forEach((item) => {
      arr.push(tableData[item].id);
    });
    setIdList(arr);
  };
  useEffect(() => {
    fetchData();
  }, [term]);
  function fetchData() {
    getParticipantOperationList(
      { term_id: term },
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((item) => {
          const _balance = Number(parseFloat(item.balance).toFixed(2));
          item.balance_comp = item.balance ? (
            _balance < 0 || _balance === 0 ? (
              <Label variant="ghost" color="success">
                {fCurrency(item.balance)}
              </Label>
            ) : (
              <Label variant="ghost" color="error">
                {fCurrency(item.balance)}
              </Label>
            )
          ) : (
            'Blank'
          );
          item.balance_filter = item.balance
            ? _balance < 0 || _balance === 0
              ? 'Paid'
              : 'Not Paid'
            : 'Blank';
          item.name = `${item.first_name} ${item.last_name}`;
          item.date_of_birth_comp = moment(item.date_of_birth).format('YYYY-MM-DD');
          item.staying_at_hotel_comp = item.staying_at_hotel ? 'Yes' : 'No';
          item.is_confirmed_comp = item.is_confirmed ? (
            <IconButton color="success" disabled className="Success-icon-color">
              <Icon icon="akar-icons:circle-check" width={24} height={24} />
            </IconButton>
          ) : (
            <IconButton color="error" disabled className="Error-icon-color">
              <Icon color="error" icon="akar-icons:circle-x" width={24} height={24} />
            </IconButton>
          );
          item.add_money = (
            <IconButton color="secondary" onClick={() => handleAddMoney(item)}>
              <Icon icon="cil:money" />
            </IconButton>
          );
          item.is_confirmed_filter = item.is_confirmed ? 'Yes' : 'No';
          item.presentation_link_comp = item.presentation_link && (
            <Label variant="ghost" color="primary">
              <a target="_blank" href={item.presentation_link} rel="noreferrer">
                Link
              </a>
            </Label>
          );
          item.presentation_link_filter = item.presentation_link ? 'Exist' : 'None';
          item.is_attended_comp = item.is_attended ? (
            <Tooltip title="Change Attendance">
              <IconButton color="success" onClick={() => handleAttendance(false, item.id)}>
                <Icon icon="akar-icons:circle-check" width={24} height={24} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Change Attendance">
              <IconButton color="error" onClick={() => handleAttendance(true, item.id)}>
                <Icon color="error" icon="akar-icons:circle-x" width={24} height={24} />
              </IconButton>
            </Tooltip>
          );
          item.certificate = (
            <IconButton
              color="secondary"
              disabled={!item.participation_certificate_id}
              onClick={() => handleDownload(item.participation_certificate_id)}
            >
              <Icon icon="akar-icons:cloud-download" width={24} height={24} />
            </IconButton>
          );
          item.medal = (
            <IconButton
              color="secondary"
              disabled={!item.medal_certificate_id}
              onClick={() => handleDownload(item.medal_certificate_id)}
            >
              <Icon icon="akar-icons:cloud-download" width={24} height={24} />
            </IconButton>
          );
          item.is_attended_filter = item.is_attended ? 'Yes' : 'No';
          item.edit = (
            <IconButton color="secondary" onClick={() => handleEditParticipant(item)}>
              <Icon icon="ci:edit" width={24} height={24} />
            </IconButton>
          );
        });
        setTableData(list);
      },
      () => {}
    );
  }
  function handleDownload(certificate_id) {
    setLoading(true);
    downloadCertificate(
      { certificate_id },
      () => {
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  }
  function handleAddMoney(data) {
    setPaymentDialogShow(true);
    setAddMoneyData(data);
  }
  function handleEditParticipant(item) {
    getParticipantOperationParticipant(
      { participant_id: item.id },
      (_data) => {
        fetchGender(
          {},
          (data) => {
            setGenderList(data);
            setParticipantData(_data);
            setAddUpdateDialog(true);
          },
          () => {}
        );
      },
      () => {}
    );
  }
  function refreshData() {
    setAddUpdateDialog(false);
    setParticipantData(null);
    setMailDialogShow(false);
    setAddMail(false);
    setSelectedItems([]);
    setAddAtt(false);
    setAttDialog(false);
    setPaymentDialogShow(false);
    setAddMoneyData(null);
    setBadge(false);
    fetchData();
  }
  function handleAttendance(bool, participant_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, UPDATE',
        bool
          ? 'Attendance will be added as a result of this action'
          : 'Attendance will be removed as a result of this action',
        () => {
          updateParticipantOperationAttendance(
            { participant_ids: [participant_id], is_attended: bool ? 1 : 0 },
            () => {
              fetchData();
              dispatch(setSnackbar('success', 'The attendance has been updated'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }
  function handleDownloadBadge() {
    const params = {
      participant_ids: idList
    };
    getBadge(
      params,
      (data) => {
        if (!data?.result.url) {
          dispatch(
            setSnackbar(
              'warning',
              'Badge files are not ready for all selected participants. You will be notified by e-mail when it is completed.'
            )
          );
        }
        handleCancel();
      },
      (error) => {
        console.log(error);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  function handleDownloadForm() {
    const params = {
      participant_ids: idList
    };
    getForm(
      params,
      (data) => {
        if (!data?.result.url) {
          dispatch(
            setSnackbar(
              'warning',
              'Form files are not ready for all selected participants. You will be notified by e-mail when it is completed.'
            )
          );
        }
        handleCancel();
      },
      (error) => {
        console.log(error);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  function TermSelect() {
    return (
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 2 }}>
        <TextField select label="Terms" value={term} onChange={(e) => setTerm(e.target.value)}>
          {terms.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
        {!addAtt && !badge && !addMail && !form && (
          <div className="header-button-style">
            <Button color="secondary" variant="outlined" onClick={setAddAtt}>
              Choose Participant(s) to Attend
            </Button>
          </div>
        )}
        {!addAtt && !badge && !addMail && !form && (
          <div className="header-button-style">
            <Button color="secondary" variant="outlined" onClick={() => setBadge(true)}>
              Badge
            </Button>
          </div>
        )}
        {!addAtt && !badge && !addMail && !form && (
          <div className="header-button-style">
            <Button color="secondary" variant="outlined" onClick={() => setForm(true)}>
              Consent Form
            </Button>
          </div>
        )}
        {loading && (
          <Stack
            spacing={1}
            direction={{ xs: 'column', sm: 'row' }}
            sx={{ mr: 1, display: 'flex', alignItems: 'center' }}
          >
            <p style={{ fontWeight: 'bold' }}>Downloading...</p>
            <CircularProgress size={20} />
          </Stack>
        )}
        {addAtt && (
          <div className="header-button-style">
            <Button
              color="primary"
              variant="outlined"
              disabled={startButtonDisabled}
              onClick={() => setAttDialog(true)}
            >
              Attend
            </Button>
            <Button
              variant="outlined"
              color="error"
              style={{ marginLeft: 10 }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        )}
        {badge && (
          <div className="header-button-style">
            <Button
              color="primary"
              variant="outlined"
              disabled={startButtonDisabled}
              onClick={handleDownloadBadge}
            >
              Download Badge
            </Button>
            <Button
              variant="outlined"
              color="error"
              style={{ marginLeft: 10 }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        )}
        {form && (
          <div className="header-button-style">
            <Button
              color="primary"
              variant="outlined"
              disabled={startButtonDisabled}
              onClick={handleDownloadForm}
            >
              Download Consent Form
            </Button>
            <Button
              variant="outlined"
              color="error"
              style={{ marginLeft: 10 }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        )}
        {addMail && (
          <div className="header-button-style">
            <Button
              style={{ marginRight: 5 }}
              disabled={selectedItems.length === 0}
              variant="outlined"
              onClick={() => setMailDialogShow(true)}
            >
              Next
            </Button>
            <Button color="error" variant="outlined" onClick={() => setAddMail(false)}>
              Cancel
            </Button>
          </div>
        )}
      </Stack>
    );
  }
  const handleCancel = () => {
    setSelectedItems([]);
    setAddAtt(false);
    setBadge(false);
    setForm(false);
  };
  const startButtonDisabled = selectedItems.length === 0;

  return (
    <Page title="Participation | Genius">
      <Stack spacing={2}>
        {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
        <MuiTable
          tableData={tableData}
          tableColumns={headers}
          title={<TermSelect />}
          customOption={{
            selectableRows: addMail || addAtt || badge || form ? 'multiple' : 'none',
            isRowSelectable: (dataIndex) =>
              form
                ? tableData[dataIndex].participant_consent_form
                : badge
                ? tableData[dataIndex].is_attended
                : true,
            onRowSelectionChange,
            rowsSelected: selectedItems,
            customToolbar: () =>
              isCommunication !== -1 && (
                <Tooltip title="Send Email">
                  <IconButton onClick={() => setAddMail(true)}>
                    <Email />
                  </IconButton>
                </Tooltip>
              )
          }}
        />
      </Stack>
      <CustomDialog
        onClose={() => setAddUpdateDialog(false)}
        open={addUpdateDialog}
        title="Update Participant"
      >
        <DialogContent>
          <AddUpdateDialog
            data={participantData}
            handleClose={() => setAddUpdateDialog(false)}
            refreshData={refreshData}
            genderList={genderList}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        onClose={() => setMailDialogShow(false)}
        open={mailDialogShow}
        maxWidth="lg"
        title="Send Mail"
        fullWidth
        disableEnforceFocus
      >
        <DialogContent>
          <CustomMail
            handleRefresh={refreshData}
            mailType={PARTICIPANT_MAIL_TYPE}
            idList={idList}
            handleClose={() => setMailDialogShow(false)}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        onClose={() => setAttDialog(false)}
        open={attDialog}
        title="Attendance Participant"
      >
        <DialogContent>
          <AttDialog
            data={tableData}
            handleClose={() => setAttDialog(false)}
            refreshData={refreshData}
            selectedParticipants={selectedItems}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog
        onClose={() => setPaymentDialogShow(false)}
        open={paymentDialogShow}
        title="Payment"
      >
        <DialogContent>
          <PaymentDialog
            data={addMoneyData}
            handleRefresh={refreshData}
            handleClose={() => setPaymentDialogShow(false)}
          />
        </DialogContent>
      </CustomDialog>
    </Page>
  );
}

Index.propTypes = {
  dispatch: PropTypes.func.isRequired,
  terms: PropTypes.array,
  active_term: PropTypes.number,
  isCommunication: PropTypes.number
};

export default connect((store) => ({
  terms: store.loginReducer.terms,
  active_term: store.loginReducer.active_term,
  isCommunication: store.routeReducer.isCommunication
}))(Index);
