import { MenuItem, Stack, TextField } from '@material-ui/core';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@material-ui/lab';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as React from 'react';
import { addCategory, updateCategory } from '../serviceCalls';
import { setLoading, setSnackbar } from '../../../../actions/alertActions';

const ADD_CATEGORY = 0;
const UPDATE_CATEGORY = 1;

function AddUpdateDialog(props) {
  const { data, mode, handleClose, dispatch, refreshData, disciplineList } = props;
  const Schema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Too Short!')
      .max(500, 'Too Long!')
      .matches('^[\x20-\x7F]+$', 'Please enter only English characters!')
      .required('Category name required'),
    allowed_student_count: Yup.number().required('Student count required')
  });
  const formik = useFormik({
    initialValues: {
      discipline_id: mode === UPDATE_CATEGORY ? data.discipline_id : '',
      name: mode === UPDATE_CATEGORY ? data.name : '',
      allowed_student_count: mode === UPDATE_CATEGORY ? data.allowed_student_count : '',
      is_media_link_required: mode === UPDATE_CATEGORY ? !!data.is_media_link_required : false,
      is_file_required: mode === UPDATE_CATEGORY ? !!data.is_file_required : false
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      dispatch(setLoading(1, 'Loading...'));
      // eslint-disable-next-line no-unused-expressions
      mode === ADD_CATEGORY
        ? addCategory(
            {
              discipline_id: values.discipline_id,
              name: values.name,
              allowed_student_count: values.allowed_student_count,
              is_media_link_required: values.is_media_link_required ? 1 : 0,
              is_file_required: values.is_file_required ? 1 : 0
            },
            () => {
              dispatch(setLoading(0, ''));
              refreshData();
              dispatch(setSnackbar('success', 'The category has been added'));
            },
            () => {
              dispatch(setLoading(0, ''));
            }
          )
        : updateCategory(
            {
              id: data.id,
              discipline_id: values.discipline_id,
              name: values.name,
              allowed_student_count: values.allowed_student_count,
              is_media_link_required: values.is_media_link_required ? 1 : 0,
              is_file_required: values.is_file_required ? 1 : 0
            },
            () => {
              refreshData();
              dispatch(setLoading(0, ''));
              dispatch(setSnackbar('success', 'The category has been updated'));
            },
            () => {
              dispatch(setLoading(0, ''));
            }
          );
    }
  });
  const { errors, touched, handleSubmit, getFieldProps, values } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            sx={{ mt: 1 }}
            fullWidth
            label="Related Discipline"
            select
            {...getFieldProps('discipline_id')}
            error={Boolean(touched.discipline_id && errors.discipline_id)}
            helperText={touched.discipline_id && errors.discipline_id}
          >
            {disciplineList.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 1 }}>
            <TextField
              fullWidth
              label="Category name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
            <TextField
              fullWidth
              label="Allowed Student Count"
              type="number"
              {...getFieldProps('allowed_student_count')}
              error={Boolean(touched.allowed_student_count && errors.allowed_student_count)}
              helperText={touched.allowed_student_count && errors.allowed_student_count}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FormControlLabel
              control={
                <Checkbox
                  {...getFieldProps('is_file_required')}
                  checked={values.is_file_required}
                />
              }
              label="File Required"
            />
            <FormControlLabel
              control={
                <Checkbox
                  {...getFieldProps('is_media_link_required')}
                  checked={values.is_media_link_required}
                />
              }
              label="Media Link Required"
            />
          </Stack>
          <Stack direction="row" justifyContent="end" spacing={1}>
            <LoadingButton size="small" type="submit" variant="outlined">
              {mode === ADD_CATEGORY ? 'Add' : 'Update'}
            </LoadingButton>
            <Button size="small" color="error" onClick={handleClose} variant="outlined">
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

AddUpdateDialog.propTypes = {
  data: PropTypes.object,
  mode: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  disciplineList: PropTypes.array.isRequired
};

export default connect(() => ({}))(AddUpdateDialog);
