import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import { Delete, Check, CloudDownload } from '@material-ui/icons';
import { Grid, Stack, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import { LoadingButton } from '@material-ui/lab';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles(() => ({
  avatar: {
    backgroundColor: red[500]
  },
  cardAction: {
    float: 'right',
    padding: 5
  }
}));

export default function FileCard(props) {
  const classes = useStyles();
  const {
    data,
    deleteFile,
    submitFile,
    editMode,
    downloadFile,
    deleteFileFromDB,
    atReview,
    loading,
    downloading,
    index,
    performance
  } = props;
  const englishChar = performance
    ? /^[-a-zA-Z0-9&_ ]+\.(mp3)$/
    : /^[-a-zA-Z0-9&_ ]+\.(gif|svg|jpg|jpeg|png|pdf)$/;
  const fileNameOk = englishChar.test(data?.data?.name);
  const fileNameLengthOk = data?.data?.name.length < 100;

  return (
    <Card>
      <CardContent>
        <Typography variant="body2" color="textPrimary" component="span">
          <Grid container spacing={1}>
            <Grid item xs={12} style={{ display: 'flex' }}>
              <span>
                <b>File Name: </b>
              </span>
              &nbsp;
              <p style={{ color: fileNameOk && fileNameLengthOk ? 'black' : 'red' }}>
                {' '}
                {`${data?.data?.name}` || '-'}
              </p>
            </Grid>
            {!editMode && (
              <Grid item xs={12}>
                <span>
                  <b>Type: </b>
                </span>
                {data?.data?.type || '-'}
              </Grid>
            )}
            {!editMode && (
              <Grid item xs={12}>
                <span>
                  <b>Size: </b>
                </span>
                {`${(data?.data?.size / (1024 * 1024)).toFixed(2)} MB` || '-'}
              </Grid>
            )}
            <Grid item xs={12}>
              <span>
                <b>Created Date: </b>
              </span>
              {editMode
                ? moment(data?.data?.timestamp).format('YYYY-MM-DD')
                : moment(data?.data?.lastModifiedDate).format('YYYY-MM-DD')}
            </Grid>
            {!fileNameOk && (
              <Grid item xs={12}>
                <span>
                  <p style={{ color: 'red', fontWeight: 'bold' }}>
                    {performance
                      ? 'File name must contain only English characters and cannot include special characters. Only mp3 file is allowed'
                      : 'File name must contain only English characters and cannot include special characters. Only pdf and image files are allowed'}
                  </p>
                </span>
              </Grid>
            )}
            {!fileNameLengthOk && (
              <Grid item xs={12}>
                <span>
                  <p style={{ color: 'red', fontWeight: 'bold' }}>
                    File name must be no more than 100 characters
                  </p>
                </span>
              </Grid>
            )}
          </Grid>
        </Typography>
      </CardContent>
      <CardActions className={classes.cardAction}>
        {data.isSent !== true ? (
          <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>
            <LoadingButton
              loading={index === loading}
              variant="outlined"
              color="error"
              onClick={deleteFile}
              size="small"
            >
              Remove
            </LoadingButton>
            {fileNameOk && fileNameLengthOk && (
              <LoadingButton
                disabled={!fileNameOk || !fileNameLengthOk}
                loading={index === loading}
                variant="outlined"
                onClick={submitFile}
                size="small"
              >
                Upload
              </LoadingButton>
            )}
          </Stack>
        ) : index === downloading ? (
          <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }} sx={{ mr: 1 }}>
            <p style={{ fontWeight: 'bold' }}>Downloading...</p>
            <CircularProgress size={20} />
          </Stack>
        ) : index === loading ? (
          <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }} sx={{ mr: 1 }}>
            <p style={{ fontWeight: 'bold' }}>Uploading...</p>
            <CircularProgress size={20} />
          </Stack>
        ) : (
          <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>
            <Tooltip title="Delete file">
              <IconButton
                disabled={atReview}
                size="small"
                color="error"
                onClick={() => deleteFileFromDB(data.data.id)}
              >
                <Delete size="small" />
              </IconButton>
            </Tooltip>
            {editMode && (
              <Tooltip title="Download file">
                <IconButton size="small" color="secondary" onClick={downloadFile}>
                  <CloudDownload size="small" />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="File has been uploaded.">
              <IconButton size="small" color="primary">
                <Check size="small" />
              </IconButton>
            </Tooltip>
          </Stack>
        )}
      </CardActions>
    </Card>
  );
}

FileCard.propTypes = {
  data: PropTypes.object.isRequired,
  deleteFile: PropTypes.func,
  submitFile: PropTypes.func,
  editMode: PropTypes.bool,
  downloadFile: PropTypes.func,
  deleteFileFromDB: PropTypes.func,
  atReview: PropTypes.number,
  loading: PropTypes.number,
  index: PropTypes.number,
  downloading: PropTypes.number,
  performance: PropTypes.bool
};
