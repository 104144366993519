import {
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from '@stripe/react-stripe-js';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Card, Stack, CardHeader, Divider, Button } from '@mui/material';
import PropTypes from 'prop-types';
import { payment } from '../../pages/Application/Projects/utils/serviceCalls';
import { setLoading, setSnackbar, setSnackbarForMain } from '../../actions/alertActions';

import useResponsiveFontSize from './useResponsiveFontSize';
import { paymentForParticipant } from '../../pages/Application/Participation/serviceCalls';
import { paymentForTrip } from '../../pages/Application/Trip/utils/serviceCalls';
import {
  makeExternalParticipantPayment,
  makeExternalProjectPayment
} from '../../pages/PaymentLink/_apis/serviceCalls';
import {
  addParticipantPayment,
  addProjectPayment,
  addTripPayment
} from '../../pages/Application/Payment/_apis/serviceCalls';

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#9e2146'
        }
      }
    }),
    [fontSize]
  );
  return options;
};

function PaymentForm(props) {
  const {
    dispatch,
    cartItemsProject,
    cartItemsParticipants,
    cartItemsTrips,
    totalDiscount,
    netBalance,
    cartTotalFee,
    setSuccess,
    handleClose,
    discountList,
    type,
    external,
    fromGeneralPayments
  } = props;
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const tripObj = discountList || cartItemsTrips;
  const active_method =
    type === 'project'
      ? cartItemsProject
      : type === 'participant'
      ? cartItemsParticipants
      : tripObj;

  const handleSubmit = async (e) => {
    dispatch(setLoading(1, 'Loading'));
    e.preventDefault();
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
      billing_details: {
        name: e.target.name.value,
        email: e.target.email.value
      }
    });

    if (!error) {
      try {
        const { id } = paymentMethod;
        const arr = [];
        active_method.forEach((item) => {
          const obj_projects = {
            project_id: item.id,
            amount: Number(parseFloat(item.balance).toFixed(2)),
            description: `Payment for application for project: ${item.id}`
          };
          const obj_participants = {
            participant_id: item.id,
            amount: Number(parseFloat(item.balance).toFixed(2)),
            description: `Payment for registration for participant: ${item.id}`
          };
          const obj_trip = {
            participant_id: item.participant_id,
            amount: Number(parseFloat(item.amount).toFixed(2)),
            discount: item.discount,
            description: `Payment for trip for participant: ${item.participant_id}`
          };
          arr.push(
            type === 'project' ? obj_projects : type === 'participant' ? obj_participants : obj_trip
          );
        });
        const param_project = {
          payment_id: id,
          amount: Number(parseFloat(cartTotalFee).toFixed(2)),
          description: 'Payment for the projects',
          project_details: arr
        };
        if (external) param_project.id = Number(active_method[0].paramId);
        if (external) param_project.token = active_method[0].token;
        const param_participant = {
          payment_id: id,
          amount: Number(parseFloat(cartTotalFee).toFixed(2)),
          description: 'Payment for the participants',
          participant_details: arr
        };
        if (external) param_participant.id = Number(active_method[0].paramId);
        if (external) param_participant.token = active_method[0].token;
        const param_trip = {
          payment_id: id,
          amount: Number(parseFloat(netBalance).toFixed(2)),
          discount: totalDiscount,
          description: 'Payment for the trip',
          participant_details: arr
        };
        if (type === 'project') {
          const method = fromGeneralPayments
            ? addProjectPayment
            : external
            ? makeExternalProjectPayment
            : payment;
          method(
            param_project,
            (data) => {
              console.log(data, 'Pay Data');
              setSuccess(true);
              dispatch(setSnackbar('success', 'Your payment has been received.'));
              dispatch(setSnackbarForMain('success', 'Your payment has been received.'));
              dispatch(setLoading(0, ''));
            },
            () => {
              dispatch(setLoading(0, ''));
            }
          );
        } else if (type === 'participant') {
          const method = fromGeneralPayments
            ? addParticipantPayment
            : external
            ? makeExternalParticipantPayment
            : paymentForParticipant;
          method(
            param_participant,
            (data) => {
              console.log(data, 'Pay Data');
              setSuccess(true);
              dispatch(setSnackbar('success', 'Your payment has been received.'));
              dispatch(setSnackbarForMain('success', 'Your payment has been received.'));
              dispatch(setLoading(0, ''));
            },
            () => {
              dispatch(setLoading(0, ''));
            }
          );
        } else {
          const method = fromGeneralPayments ? addTripPayment : paymentForTrip;
          method(
            param_trip,
            (data) => {
              console.log(data, 'Pay Data');
              setSuccess(true);
              dispatch(setSnackbar('success', 'Your payment has been received.'));
              dispatch(setSnackbarForMain('success', 'Your payment has been received.'));
              dispatch(setLoading(0, ''));
            },
            () => {
              dispatch(setLoading(0, ''));
            }
          );
        }
      } catch (error) {
        console.log('Error', error);
      }
    } else {
      dispatch(setLoading(0, ''));
      dispatch(setSnackbar('error', error.message));
    }
  };

  return (
    <>
      <Card style={{ padding: 10 }}>
        <CardHeader title="Credit/Bank Card" style={{ textAlign: 'center' }} />
        <Divider />
        <form onSubmit={handleSubmit}>
          <Stack spacing={1}>
            <label>
              <input
                style={{ width: '100%' }}
                name="name"
                type="text"
                placeholder="Name"
                required
                className="stripe_input"
              />
            </label>
            <label>
              <input
                style={{ width: '100%' }}
                name="email"
                type="email"
                placeholder="Email"
                required
                className="stripe_input"
              />
            </label>
            <label>
              Card number
              <CardNumberElement options={options} />
            </label>
            <label>
              Expiration date
              <CardExpiryElement options={options} />
            </label>
            <label>
              CVC
              <CardCvcElement options={options} />
            </label>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={2}
              style={{ justifyContent: 'end' }}
            >
              <button type="submit" disabled={!stripe} className="Button_payment">
                Submit
              </button>
              <Button color="error" onClick={handleClose} variant="outlined">
                Cancel
              </Button>
            </Stack>
          </Stack>
        </form>
      </Card>
    </>
  );
}

PaymentForm.propTypes = {
  cartItemsProject: PropTypes.array,
  cartItemsParticipants: PropTypes.array,
  dispatch: PropTypes.func,
  cartTotalFee: PropTypes.number,
  setSuccess: PropTypes.func,
  handleClose: PropTypes.func,
  type: PropTypes.string,
  cartItemsTrips: PropTypes.array,
  totalDiscount: PropTypes.string,
  netBalance: PropTypes.string,
  discountList: PropTypes.array,
  external: PropTypes.bool,
  fromGeneralPayments: PropTypes.bool
};

export default connect((store) => ({
  cartItemsProject: store.dashboardReducer.cartItemsProject,
  cartItemsParticipants: store.dashboardReducer.cartItemsParticipants,
  cartItemsTrips: store.dashboardReducer.cartItemsTrips,
  cartTotalFee: store.dashboardReducer.cartTotalFee
}))(PaymentForm);
