import { fetchApi } from '../../../api/fetchApi';

const home = require('../../../api/RestVariables/Application/home');

export async function getHomeProjectCheckListReport(config, scb, ecb) {
  try {
    const url = home.GET_HOME_PROJECT_CHECK_LIST_REPORT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_HOME_CHECK_LIST_REPORT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getHomeParticipantCheckListReport(config, scb, ecb) {
  try {
    const url = home.GET_HOME_PARTICIPANT_CHECK_LIST_REPORT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_HOME_PARTICIPANT_CHECK_LIST_REPORT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getAttendedStudentList(config, scb, ecb) {
  try {
    const url = home.GET_ATTENDED_STUDENT_LIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_ATTENDED_STUDENT_LIST');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateFlagShow(config, scb, ecb) {
  try {
    const url = home.UPDATE_FLAG_SHOW;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_FLAG_SHOW');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}
