import * as React from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { IconButton, Stack } from '@material-ui/core';
import { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/styles';
import { blue } from '@material-ui/core/colors';
import { Icon } from '@iconify/react';

const useStyles = makeStyles(() => ({
  avatar: {
    backgroundColor: blue[500]
  },
  cardAction: {
    float: 'right'
  }
}));

function RubricViewDetail(props) {
  const { rubric, handleSelectScore } = props;
  const [value, setValue] = useState(0);
  const classes = useStyles();

  const handleChange = (mode) => {
    if (mode === 0) {
      const updatedValue = value - 1;
      setValue(updatedValue);
      handleSelectScore(rubric.id, updatedValue);
    } else {
      const updatedValue = value + 1;
      setValue(updatedValue);
      handleSelectScore(rubric.id, updatedValue);
    }
  };

  return (
    <Card className="drag-drop-inside-card-style">
      <CardHeader
        title={rubric?.name}
        avatar={<Avatar className={classes.avatar}>{`${rubric.rank}`}</Avatar>}
      />
      <CardContent>
        <Stack spacing={1}>
          <div>{ReactHtmlParser(rubric?.markdown)}</div>
          <Stack
            spacing={1}
            direction={{ xs: 'column', sm: 'row' }}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <p style={{ fontWeight: 'bold' }}>Count</p>
            <IconButton disabled={value === 0} color="secondary" onClick={() => handleChange(0)}>
              <Icon icon="icon-park-twotone:reduce" width={24} height={24} />
            </IconButton>
            <p style={{ fontWeight: 'bold' }}>{value}</p>
            <IconButton
              disabled={value === rubric.limit}
              color="secondary"
              onClick={() => handleChange(1)}
            >
              <Icon icon="icon-park-twotone:add" width={24} height={24} />
            </IconButton>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

RubricViewDetail.propTypes = {
  rubric: PropTypes.object,
  handleSelectScore: PropTypes.func
};

export default RubricViewDetail;
