import { Stack } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Button, Tooltip } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { setLoading, setSnackbar } from '../../../../../../actions/alertActions';
import MuiTable from '../../../../../../components/CustomComponents/MuiTable';
import headers from './headerVariables';
import Label from '../../../../../../components/Label';
import { fCurrency } from '../../../../../../utils/formatNumber';
import { assignJudging, getJudgingGroupList } from '../../_apis/serviceCalls';

function AddJudgeDialog(props) {
  const { data, handleClose, dispatch, refreshData } = props;
  const [projectList, setProjectList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [idList, setIdList] = useState([]);

  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    setSelectedItems(rowsSelected);
    const arr = [];
    rowsSelected.forEach((item) => {
      arr.push(projectList[item].judge_id);
    });
    setIdList(arr);
  };
  useEffect(() => {
    getJudgingGroupList(
      { judging_group_id: data.judging_group_id },
      (data) => {
        data.forEach((item) => {
          const _balance = Number(parseFloat(item.balance).toFixed(2));
          item.balance_comp =
            _balance < 0 || _balance === 0 ? (
              <Label variant="ghost" color="success">
                {fCurrency(item.balance)}
              </Label>
            ) : (
              <Label variant="ghost" color="error">
                {fCurrency(item.balance)}
              </Label>
            );
          item.balance_filter = _balance < 0 || _balance === 0 ? 'Paid' : 'Not Paid';
          item.presentation_link_comp = item.presentation_link && (
            <Label variant="ghost" color="primary">
              <a target="_blank" href={item.presentation_link} rel="noreferrer">
                Link
              </a>
            </Label>
          );
          item.presentation_link_filter = item.presentation_link ? 'Exist' : 'None';
          item.project_student_comp = item.project_student_complete && (
            <Tooltip title={item.project_student_complete}>
              <p style={{ cursor: 'pointer' }}>{item.project_student} </p>
            </Tooltip>
          );
          item.media_link_comp = item.media_link && (
            <Label variant="ghost" color="primary">
              <a target="_blank" href={item.media_link} rel="noreferrer">
                Link
              </a>
            </Label>
          );
          item.media_link_filter = item.media_link ? 'Exist' : 'None';
          item.created_at_moment = moment(item.created_at).format('YYYY-MM-DD');
        });
        setProjectList(data);
      },
      () => {}
    );
  }, []);

  function handleAssignJudges() {
    dispatch(setLoading(1, 'Loading...'));
    assignJudging(
      { judging_group_id: data.judging_group_id, judge_ids: idList },
      () => {
        dispatch(setLoading(0, ''));
        refreshData();
        dispatch(setSnackbar('success', 'The judge(s) has been added to group'));
      },
      () => {
        dispatch(setLoading(0, ''));
      }
    );
  }

  return (
    <Stack spacing={3}>
      <MuiTable
        tableColumns={headers}
        tableData={projectList}
        rowsPerPage={10}
        customOption={{
          selectableRows: 'multiple',
          selectableRowsOnClick: true,
          onRowSelectionChange,
          rowsSelected: selectedItems
        }}
      />
      <Stack direction="row" justifyContent="end" spacing={1}>
        <LoadingButton size="small" onClick={handleAssignJudges} variant="outlined">
          Assign
        </LoadingButton>
        <Button size="small" color="error" onClick={handleClose} variant="outlined">
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
}

AddJudgeDialog.propTypes = {
  data: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired
};

export default connect(() => ({}))(AddJudgeDialog);
