import { Grid } from '@mui/material';
import * as React from 'react';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Alert } from '@material-ui/core';
import ApplicantDocumentCard from '../../components/CustomComponents/CustomCard/ApplicantDocumentCard';
import { getDocumentList, getDocument } from './_apis/serviceCalls';
import ProcessInfoBox from '../../components/CustomComponents/CustomCard/ProcessInfoBox';
import Page from '../../components/Page';

function Index() {
  const [documentList, setDocumentList] = React.useState([]);
  const [guidelines, setGuidelines] = React.useState([]);
  const [serviceCall, setServiceCall] = React.useState(false);
  const [loadingButton, setLoadingButton] = React.useState(false);

  useEffect(() => {
    getDocumentList({}, (data) => {
      setGuidelines(data.guidelines);
      setDocumentList(data.data);
    }).then(() => setServiceCall(true));
  }, []);

  function downloadFile(config) {
    setLoadingButton(true);
    getDocument(config, () => {
      setLoadingButton(false);
    }).then(() => {});
  }

  return (
    <Page title="Documents | Genius">
      {guidelines.length > 0 && <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />}
      {serviceCall && documentList.length === 0 && (
        <Alert severity="info">There is no document.</Alert>
      )}
      <Grid container spacing={2} sx={{ mt: 1 }}>
        {documentList.map((item, index) => (
          <Grid item xs={12} md={6} lg={4} xl={3} key={index}>
            <ApplicantDocumentCard
              data={item}
              downloadFile={downloadFile}
              loadingButton={loadingButton}
            />
          </Grid>
        ))}
      </Grid>
    </Page>
  );
}

export default connect(() => ({}))(Index);
