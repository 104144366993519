import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Alert, Grid } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import Page from '../../../components/Page';
import { downloadCertificateForJudge, getCertificateDetail } from '../_apis/serviceCalls';
import CustomCertificateCard from './CustomCertificateCard';
import ProcessInfoBox from '../../../components/CustomComponents/CustomCard/ProcessInfoBox';

const LOADING = 0;
const NO_DATA = 1;
const DATA = 2;

function Index() {
  const [detail, setDetail] = useState(null);
  const [mode, setMode] = useState(LOADING);
  const [loading, setLoading] = useState(false);
  const [guidelines, setGuidelines] = useState([]);

  useEffect(() => {
    getCertificateDetail(
      {},
      (data) => {
        setGuidelines(data.guidelines);
        if (Object.keys(data.data).length === 0) {
          setMode(NO_DATA);
        } else {
          setMode(DATA);
        }
        setDetail(data.data);
      },
      () => {}
    );
  }, []);

  function downloadCertificate(certificate) {
    const param = {
      id: certificate.judge_certificate_id,
      file_name: 'Certificate'
    };
    setLoading(true);
    downloadCertificateForJudge(
      param,
      () => {
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  }

  return (
    <Page title="Certificate | Genius">
      {mode === LOADING ? (
        <div />
      ) : mode === NO_DATA ? (
        <Alert severity="info">
          Once you have completed all projects, your certificate will be available.
        </Alert>
      ) : (
        <Grid container spacing={1}>
          {guidelines.length > 0 && (
            <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />
          )}
          <Grid item xs={4}>
            <CustomCertificateCard
              data={detail}
              downloadCertificate={downloadCertificate}
              loading={loading}
            />
          </Grid>
        </Grid>
      )}
    </Page>
  );
}

export default connect(() => ({}))(Index);
