import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/styles';
import SmartIconColors from './SmartIconColors';
import './iconStyle.css';

function SmartIcon(props) {
  const { tooltip, icon, color, size, custom, table, disabled, justIcon, ...rest } = props;
  // Material-UI Icons Color and Size options
  const _color = color && SmartIconColors[color];
  const fontSize =
    size === 'small'
      ? {
          color: _color,
          '& svg': {
            fontSize: 20,
            padding: 0,
            cursor: disabled && 'default'
          }
        }
      : size === 'large'
      ? {
          color: _color,
          '& svg': {
            fontSize: 40,
            cursor: disabled && 'default'
          }
        }
      : {
          color: _color,
          '& svg': {
            fontSize: 30,
            cursor: disabled && 'default'
          }
        };
  const useStyles = makeStyles(() => ({
    customSize: fontSize
  }));
  const disStyle = disabled && {
    'pointer-events': 'none'
  };
  const justIconStyle = {
    cursor: 'text',
    padding: 0
  };
  const classes = useStyles();
  // Custom Icons Color and Size options
  const sizeClass =
    size === 'small'
      ? 'small_icon_button'
      : size === 'large'
      ? 'large_icon_button'
      : 'medium_icon_button';
  const colorClass =
    color === 'secondary'
      ? 'secondary_color'
      : color === 'warning'
      ? 'warning_color'
      : color === 'danger'
      ? 'danger_color'
      : color === 'success'
      ? 'success_color'
      : color === 'info'
      ? 'info_color'
      : color === 'rose'
      ? 'rose_color'
      : color === 'gray'
      ? 'gray_color'
      : 'primary_color';

  const sty = justIcon ? justIconStyle : table ? { padding: 0 } : {};

  if (custom) {
    return tooltip ? (
      <Tooltip title={tooltip} style={disStyle}>
        <IconButton {...rest} className={`${sizeClass} ${colorClass}`} disabled={disabled}>
          {icon}
        </IconButton>
      </Tooltip>
    ) : (
      <IconButton {...rest} className={`${sizeClass} ${colorClass}`} disabled={disabled}>
        {icon}
      </IconButton>
    );
  }
  return tooltip ? (
    <Tooltip title={tooltip} style={disStyle}>
      <IconButton {...rest} className={classes.customSize} style={sty} disabled={disabled}>
        {icon}
      </IconButton>
    </Tooltip>
  ) : (
    <IconButton {...rest} className={classes.customSize} style={sty} disabled={disabled}>
      {icon}
    </IconButton>
  );
}

SmartIcon.propTypes = {
  tooltip: PropTypes.string,
  icon: PropTypes.element,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'warning',
    'danger',
    'success',
    'info',
    'rose',
    'gray',
    'teal',
    'white'
  ]),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  custom: PropTypes.bool,
  disabled: PropTypes.bool,
  table: PropTypes.bool,
  justIcon: PropTypes.bool
};

export default SmartIcon;
