import { Grid, IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { Icon } from '@iconify/react';
import { Button, DialogContent, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import MuiTable from '../../../components/CustomComponents/MuiTable';
import headers from './utils/headerVariables';
import Label from '../../../components/Label';
import { fCurrency } from '../../../utils/formatNumber';
import { getTripParticipantList, updateTrip } from './utils/serviceCalls';
import CustomDialog from '../../../components/CustomComponents/CustomDialog';
import AddTripDialog from './AddTripDialog';
import { setConfirmationAlert, setSnackbar } from '../../../actions/alertActions';
import Page from '../../../components/Page';
import ProcessInfoBox from '../../../components/CustomComponents/CustomCard/ProcessInfoBox';
import ProcessWarningBox from '../../../components/CustomComponents/CustomCard/ProcessWarningBox';
import { ParticipantInfo } from '../../../info/ParticipantInfo';

function LandingPage(props) {
  const { dispatch, isFinalist } = props;
  const [addPayment, setAddPayment] = useState(false);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [dialogShow, setDialogShow] = useState(false);
  const [participantData, setParticipantData] = useState(null);
  const [guidelines, setGuidelines] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchParticipantList();
  }, []);

  function fetchParticipantList() {
    getTripParticipantList(
      {},
      (data) => {
        setGuidelines(data.guidelines);
        const list = data.data;
        list.forEach((item) => {
          const _balance = Number(parseFloat(item.trip_balance).toFixed(2));
          item.name = `${item.first_name} ${item.last_name}`;
          item.balanceComp =
            _balance < 0 || _balance === 0 || item.trip_balance === null ? (
              <Label variant="ghost" color="success">
                {fCurrency(item.trip_balance)}
              </Label>
            ) : (
              <Label variant="ghost" color="error">
                {fCurrency(item.trip_balance)}
              </Label>
            );
          item.balance_filter = _balance < 0 || _balance === 0 ? 'Paid' : 'Not Paid';
          item.date_of_birth_comp = moment(item.date_of_birth).format('YYYY-MM-DD');
          item.jfk_filter = item.jfk_bus ? 'Yes' : 'No';
          item.jfk_bus_comp = item.jfk_bus ? (
            <IconButton color="success" style={{ cursor: 'default' }}>
              <Icon icon="akar-icons:circle-check" width={24} height={24} />
            </IconButton>
          ) : (
            <IconButton color="error" style={{ cursor: 'default' }}>
              <Icon color="error" icon="akar-icons:circle-x" width={24} height={24} />
            </IconButton>
          );
          item.edit = (
            <IconButton color="secondary" onClick={() => handleEditTrip(item)}>
              <Icon icon="ci:edit" width={24} height={24} />
            </IconButton>
          );
          item.delete = (
            <IconButton
              color="secondary"
              disabled={item.trip_id === null}
              onClick={() => handleDeleteTrip(item.participant_id)}
            >
              <Icon icon="fluent:delete-48-filled" width={24} height={24} />
            </IconButton>
          );
        });
        setTableData(list);
      },
      () => {}
    );
  }
  function handleEditTrip(item) {
    setParticipantData(item);
    setDialogShow(true);
  }
  function handleDeleteTrip(participant_id) {
    const param = {
      participant_id,
      trip_id: null,
      jfk_bus: 0
    };
    dispatch(
      setConfirmationAlert('Are you sure?', 'YES, DELETE', 'This action will delete trip', () => {
        updateTrip(
          param,
          () => {
            dispatch(setSnackbar('success', 'The trip has been deleted'));
            dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            handleRefresh();
          },
          () => {
            dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
          }
        );
      })
    );
  }
  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    let totalFee = parseFloat('0').toFixed(2);
    const list = [];
    allRowsSelected.forEach((item) => {
      totalFee = parseFloat(totalFee) + parseFloat(tableData[item.index].trip_balance);
      list.push(tableData[item.index]);
    });
    setSelectedStudents(rowsSelected);
    dispatch({ type: 'ADD_FEE', payload: totalFee });
    dispatch({ type: 'ADD_ITEM_CART_FROM_TRIPS', payload: list });
  };
  const handleCancel = () => {
    dispatch({ type: 'CLEAR_CART' });
    setSelectedStudents([]);
    setAddPayment(false);
  };
  const startButtonDisabled = selectedStudents.length === 0;
  function handleRefresh() {
    setDialogShow(false);
    fetchParticipantList();
  }

  function CustomHeader() {
    return (
      <Stack spacing={3} direction={{ sm: 'row' }}>
        {!addPayment && tableData.length > 0 && (
          <Button
            startIcon={<Icon icon="ri:secure-payment-fill" width={22} height={22} />}
            color="secondary"
            variant="outlined"
            onClick={() => setAddPayment(true)}
          >
            Make Payment
          </Button>
        )}
        {addPayment && (
          <div>
            <Button
              color="secondary"
              variant="outlined"
              disabled={startButtonDisabled}
              onClick={() => {
                navigate('/dashboard/payments_for_trips', { replace: true });
              }}
            >
              Checkout
            </Button>
            <Button
              variant="outlined"
              color="error"
              style={{ marginLeft: 10 }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        )}
      </Stack>
    );
  }

  return (
    <Page title="Trip | Genius">
      {isFinalist ? (
        <Stack spacing={3}>
          {guidelines.length > 0 && (
            <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />
          )}
          <Grid item xs={12}>
            <MuiTable
              title={<CustomHeader />}
              tableColumns={headers}
              tableData={tableData}
              rowsPerPage={10}
              customOption={{
                selectableRows: addPayment ? 'multiple' : 'none',
                onRowSelectionChange,
                selectableRowsOnClick: true,
                rowsSelected: selectedStudents,
                isRowSelectable: (dataIndex) =>
                  Number(parseFloat(tableData[dataIndex].trip_balance)) > 0
              }}
            />
          </Grid>
          <CustomDialog
            onClose={() => setDialogShow(false)}
            open={dialogShow}
            maxWidth="lg"
            title="Trip Update"
          >
            <DialogContent>
              <AddTripDialog
                participantData={participantData}
                handleClose={() => setDialogShow(false)}
                handleRefresh={handleRefresh}
              />
            </DialogContent>
          </CustomDialog>
        </Stack>
      ) : (
        <ProcessWarningBox Comp={<ParticipantInfo />} />
      )}
    </Page>
  );
}

LandingPage.propTypes = {
  dispatch: PropTypes.func,
  isFinalist: PropTypes.bool
};

export default connect((store) => ({
  isFinalist: store.dashboardReducer.isFinalist
}))(LandingPage);
