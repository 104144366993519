import { fetchApi } from '../../../../../api/fetchApi';

const judging_form = require('./judging_form_apis');

export async function getJudgingForm(config, scb, ecb) {
  try {
    const url = judging_form.GET_JUDGING_FORM;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_JUDGING_FORM');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addJudgingForm(config, scb, ecb) {
  try {
    const url = judging_form.ADD_JUDGING_FORM;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_JUDGING_FORM');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateJudgingForm(config, scb, ecb) {
  try {
    const url = judging_form.UPDATE_JUDGING_FORM;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_JUDGING_FORM');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteJudgingForm(config, scb, ecb) {
  try {
    const url = judging_form.DELETE_JUDGING_FORM;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_JUDGING_FORM');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function copyJudgingForm(config, scb, ecb) {
  try {
    const url = judging_form.COPY_JUDGING_FORM;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'COPY_JUDGING_FORM');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getJudgingFormDetail(config, scb, ecb) {
  try {
    const url = judging_form.GET_JUDGING_FORM_DETAIL;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_JUDGING_FORM_DETAIL');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addJudgingFormQuestion(config, scb, ecb) {
  try {
    const url = judging_form.ADD_JUDGING_FORM_QUESTION;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_JUDGING_FORM_QUESTION');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateJudgingFormQuestion(config, scb, ecb) {
  try {
    const url = judging_form.UPDATE_JUDGING_FORM_QUESTION;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_JUDGING_FORM_QUESTION');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteJudgingFormQuestion(config, scb, ecb) {
  try {
    const url = judging_form.DELETE_JUDGING_FORM_QUESTION;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_JUDGING_FORM_QUESTION');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateJudgingFormQuestionRank(config, scb, ecb) {
  try {
    const url = judging_form.UPDATE_JUDGING_FORM_QUESTION_RANK;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_JUDGING_FORM_QUESTION_RANK');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}
