import * as React from 'react';
import { Card, CardContent, CardHeader, Stack, Tooltip } from '@mui/material';
import Avatar from '@material-ui/core/Avatar';
import { Create, Delete } from '@material-ui/icons';
import { useState } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import SmartIcon from '../../../../../../components/CustomComponents/SmartIcon';
import EditQuestion from '../EditQuestion/EditQuestion';

/*
    This view includes MuiCard to inform user about the sub question.
    There are meta data about sub question on the card header.
    There are view, edit and delete question icons there.
    Users can view question detail on the card content.
    This view includes also Edit Sub Question modal.
*/

function QuestionDetail(props) {
  const { question, newRank, rankChange, refreshData, handleDeleteQuestion } = props;
  const [editDialog, setEditDialog] = useState(false);

  function handleSuccess() {
    setEditDialog(false);
    refreshData();
  }

  return (
    <Card className="drag-drop-inside-card-style">
      <CardHeader
        style={{ marginBottom: 10 }}
        avatar={
          rankChange ? (
            <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>
              <Tooltip title="Old Rank">
                <Avatar className="drag-drop-old-rank-style" aria-label="recipe">
                  {question.rank}
                </Avatar>
              </Tooltip>
              <Tooltip title="New Rank">
                <Avatar className="drag-drop-new-rank-style" aria-label="recipe">
                  {newRank + 1}
                </Avatar>
              </Tooltip>
            </Stack>
          ) : (
            <Avatar className="drag-drop-current-rank-style" aria-label="recipe">
              {question.rank}
            </Avatar>
          )
        }
        action={
          !rankChange && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p style={{ fontWeight: 'bold' }}>{`Out of ${question?.scale}`}</p>
              <SmartIcon
                onClick={() => setEditDialog(true)}
                tooltip="Edit Question"
                size="small"
                icon={<Create />}
                color="primary"
              />
              <SmartIcon
                onClick={() => handleDeleteQuestion(question.id)}
                tooltip="Delete Question"
                size="small"
                icon={<Delete />}
                color="secondary"
              />
            </div>
          )
        }
      />
      <CardContent>
        <div>{ReactHtmlParser(question?.markdown)}</div>
      </CardContent>
      {editDialog && (
        <EditQuestion
          question={question}
          closeDialog={() => setEditDialog(false)}
          handleSuccess={handleSuccess}
        />
      )}
    </Card>
  );
}

QuestionDetail.propTypes = {
  question: PropTypes.object,
  newRank: PropTypes.number,
  rankChange: PropTypes.bool,
  refreshData: PropTypes.func,
  handleDeleteQuestion: PropTypes.func
};

export default QuestionDetail;
