import { Paper } from '@material-ui/core';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import React from 'react';
import PropTypes from 'prop-types';

function SummaryTable({ data }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 400 }} aria-label="simple table">
        <TableHead>
          <TableRow style={{ background: 'gainsboro' }}>
            <TableCell>Discipline</TableCell>
            <TableCell align="center">Total Project</TableCell>
            <TableCell align="center">Student Count</TableCell>
            <TableCell align="center">Honorable Mention</TableCell>
            <TableCell align="center">Honorable Mention Student</TableCell>
            <TableCell align="center">Bronze</TableCell>
            <TableCell align="center">Bronze Student</TableCell>
            <TableCell align="center">Silver</TableCell>
            <TableCell align="center">Silver Student</TableCell>
            <TableCell align="center">Gold</TableCell>
            <TableCell align="center">Gold Student</TableCell>
            <TableCell align="center">Grand</TableCell>
            <TableCell align="center">Grand Student</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell
                style={{ fontWeight: index === data.length - 1 && 'bold' }}
                component="th"
                scope="row"
              >
                {row.name}
              </TableCell>
              <TableCell style={{ fontWeight: index === data.length - 1 && 'bold' }} align="center">
                {row.t}
              </TableCell>
              <TableCell style={{ fontWeight: index === data.length - 1 && 'bold' }} align="center">
                {row.sc}
              </TableCell>
              <TableCell style={{ fontWeight: index === data.length - 1 && 'bold' }} align="center">
                {row.hmc}
              </TableCell>
              <TableCell style={{ fontWeight: index === data.length - 1 && 'bold' }} align="center">
                {row.hmsc}
              </TableCell>
              <TableCell style={{ fontWeight: index === data.length - 1 && 'bold' }} align="center">
                {row.bc}
              </TableCell>
              <TableCell style={{ fontWeight: index === data.length - 1 && 'bold' }} align="center">
                {row.bsc}
              </TableCell>
              <TableCell style={{ fontWeight: index === data.length - 1 && 'bold' }} align="center">
                {row.sic}
              </TableCell>
              <TableCell style={{ fontWeight: index === data.length - 1 && 'bold' }} align="center">
                {row.sisc}
              </TableCell>
              <TableCell style={{ fontWeight: index === data.length - 1 && 'bold' }} align="center">
                {row.gc}
              </TableCell>
              <TableCell style={{ fontWeight: index === data.length - 1 && 'bold' }} align="center">
                {row.gsc}
              </TableCell>
              <TableCell style={{ fontWeight: index === data.length - 1 && 'bold' }} align="center">
                {row.grc}
              </TableCell>
              <TableCell style={{ fontWeight: index === data.length - 1 && 'bold' }} align="center">
                {row.grsc}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

SummaryTable.propTypes = {
  data: PropTypes.array.isRequired
};

export default SummaryTable;
