import { Icon } from '@iconify/react';

export function getSidebarConfig(route_reducer) {
  const {
    isPerformance,
    isHome,
    isParticipation,
    isParticipantFeature,
    isInvitationLetterFeature,
    isVipFeature,
    isProject,
    isSchool,
    isTravel,
    isTrip,
    isPresentation,
    isProfile,
    isPayment,
    isParticipantPaymentFeature,
    isProjectPaymentFeature,
    isTripPaymentFeature,
    isParticipantOperation,
    isParticipationParticipantOpFeature,
    isInvitationLettersParticipantOpFeature,
    isVipParticipantOpFeature,
    isTripParticipantOpFeature,
    isTravelParticipantOpFeature,
    isSchoolOperation,
    isFairOperation,
    isProfileFairOpFeature,
    isFairFairOpFeature,
    isRequestFairOpFeature,
    isSettings,
    isFeeSettingsFeature,
    isTripSettingsFeature,
    isCategorySettingsFeature,
    isDisciplineSettingsFeature,
    isTermSettingsFeature,
    isUserManagement,
    isRoleUMFeature,
    isUserUMFeature,
    isProjectOperation,
    isFairAdmin,
    isReviewOperation,
    isReviewRO,
    isReviewerReportRO,
    isDataRecovery,
    isCommunication,
    isProfileFairAdminFeature,
    isRequestFairAdminFeature,
    isReviewAdmin,
    isInfoPOFeature,
    isReportPOFeature,
    isPerformanceOperation,
    isDocumentFairAdminFeature,
    isDocumentForApplicant,
    isJudgeOperation,
    isSessionJOFeature,
    isJudgeJOFeature,
    isGroupJOFeature,
    isJudgingJOFeature,
    isJudgingFormJOFeature,
    isJudge,
    isRoboticsJOFeature,
    isScorekeeper,
    isApplicantJudging,
    isAwardOperation,
    isAwardRO,
    isAwardReportRO,
    isCertificateRO,
    isJudgeCertificate,
    isGuidelineOperation,
    isDocumentOperation,
    isPFO,
    isConfigPFO,
    isReportPFO,
    isApplicantPayment,
    isSurvey,
    isCodingOperation,
    isSOS,
    isSOR
  } = route_reducer;

  console.log(
    isProfile,
    isProfileFairOpFeature,
    isFairFairOpFeature,
    isUserManagement,
    isProjectOperation,
    isFairAdmin,
    isProfileFairAdminFeature
  );

  const arr = [];
  // APPLICANT
  if (isHome !== -1) {
    arr.push({
      label: 'Home',
      title: 'home',
      path: '/dashboard/home',
      icon: <Icon icon="ant-design:home-twotone" width={22} height={22} />
    });
  }
  if (isDocumentForApplicant !== -1) {
    arr.push({
      label: 'Documents',
      title: 'documents',
      path: '/dashboard/documents',
      icon: <Icon icon="fluent:document-20-filled" width={22} height={22} />
    });
  }
  if (isSchool !== -1) {
    arr.push({
      label: 'School',
      title: 'school',
      path: '/dashboard/school',
      icon: <Icon icon="emojione-monotone:school" width={22} height={22} />
    });
  }
  if (isProject !== -1) {
    arr.push({
      label: 'Project',
      title: 'project',
      path: '/dashboard/projects',
      icon: <Icon icon="ant-design:project-twotone" width={22} height={22} />
    });
  }
  if (isParticipation !== -1) {
    const feature_arr = [];
    if (isParticipantFeature !== -1) {
      feature_arr.push({
        label: 'Participant',
        title: 'participant',
        path: '/dashboard/participation/participant'
      });
    }
    if (isInvitationLetterFeature !== -1) {
      feature_arr.push({
        label: 'Invitation Letter',
        title: 'invitation_letter',
        path: '/dashboard/participation/invitation_letter'
      });
    }
    if (isVipFeature !== -1) {
      feature_arr.push({
        label: 'VIP',
        title: 'vip',
        path: '/dashboard/participation/vip'
      });
    }
    arr.push({
      label: 'Participation',
      title: 'participation',
      path: '/dashboard/participation',
      icon: <Icon icon="icon-park-outline:every-user" width={22} height={22} />,
      children: feature_arr
    });
  }
  if (isPresentation !== -1) {
    arr.push({
      label: 'Presentation',
      title: 'presentation',
      path: '/dashboard/presentation',
      icon: <Icon icon="heroicons-outline:presentation-chart-bar" width={22} height={22} />
    });
  }
  if (isTrip !== -1) {
    arr.push({
      label: 'Trip',
      title: 'trip',
      path: '/dashboard/trip',
      icon: <Icon icon="healthicons:travel-alt-outline" width={22} height={22} />
    });
  }
  if (isApplicantPayment !== -1) {
    arr.push({
      label: 'Payment',
      title: 'payment',
      path: '/dashboard/payment',
      icon: <Icon icon="ri:secure-payment-fill" width={22} height={22} />
    });
  }
  if (isTravel !== -1) {
    arr.push({
      label: 'Travel',
      title: 'travel',
      path: '/dashboard/travel',
      icon: <Icon icon="cil:flight-takeoff" width={22} height={22} />
    });
  }
  if (isPerformance !== -1) {
    arr.push({
      label: 'Performance',
      title: 'performance',
      path: '/dashboard/performance',
      icon: <Icon icon="ph:microphone-stage-duotone" width={22} height={22} />
    });
  }
  if (isApplicantJudging !== -1) {
    arr.push({
      label: 'Judging Session',
      title: 'judging_session',
      path: '/dashboard/judging_session',
      icon: <Icon icon="ic:round-credit-score" width={22} height={22} />
    });
  }
  // ADMIN
  if (isSchoolOperation !== -1) {
    arr.push({
      label: 'School Operation',
      title: 'school_operation',
      path: '/admin/school_operation',
      icon: <Icon icon="emojione-monotone:school" width={22} height={22} />
    });
  }
  if (isProjectOperation !== -1) {
    const feature_arr = [];
    if (isInfoPOFeature !== -1) {
      feature_arr.push({
        label: 'Info',
        title: 'info',
        path: '/admin/project_operation/info'
      });
    }
    if (isReportPOFeature !== -1) {
      feature_arr.push({
        label: 'Report',
        title: 'report',
        path: '/admin/project_operation/report'
      });
    }
    arr.push({
      label: 'Project Operation',
      title: 'project_operation',
      path: '/admin/project_operation',
      icon: <Icon icon="ant-design:project-twotone" width={22} height={22} />,
      children: feature_arr
    });
  }
  if (isParticipantOperation !== -1) {
    const feature_arr = [];
    if (isParticipationParticipantOpFeature !== -1) {
      feature_arr.push({
        label: 'Participation',
        title: 'participation',
        path: '/admin/participant_operation/participation'
      });
    }
    feature_arr.push({
      label: 'Participant Report',
      title: 'participant_report',
      path: '/admin/participant_operation/participant_report'
    });
    if (isInvitationLettersParticipantOpFeature !== -1) {
      feature_arr.push({
        label: 'Invitation Letter',
        title: 'invitation_letter',
        path: '/admin/participant_operation/invitation_letter'
      });
    }
    if (isVipParticipantOpFeature !== -1) {
      feature_arr.push({
        label: 'Vip',
        title: 'vip',
        path: '/admin/participant_operation/vip'
      });
    }
    if (isTripParticipantOpFeature !== -1) {
      feature_arr.push({
        label: 'Trip',
        title: 'trip',
        path: '/admin/participant_operation/trip'
      });
    }
    if (isTravelParticipantOpFeature !== -1) {
      feature_arr.push({
        label: 'Travel',
        title: 'travel',
        path: '/admin/participant_operation/travel'
      });
    }
    arr.push({
      label: 'Participant Operation',
      title: 'participant_operation',
      path: '/admin/participant_operation',
      icon: <Icon icon="icon-park-outline:every-user" width={22} height={22} />,
      children: feature_arr
    });
  }
  if (isPayment !== -1) {
    const feature_arr = [];
    if (isProjectPaymentFeature !== -1) {
      feature_arr.push({
        label: 'Projects',
        title: 'projects',
        path: '/admin/payment/projects'
      });
    }
    if (isParticipantPaymentFeature !== -1) {
      feature_arr.push({
        label: 'Participants',
        title: 'participant',
        path: '/admin/payment/participants'
      });
    }
    if (isTripPaymentFeature !== -1) {
      feature_arr.push({
        label: 'Trips',
        title: 'trip',
        path: '/admin/payment/trip'
      });
    }
    arr.push({
      label: 'Payment',
      title: 'payment',
      path: '/admin/payment',
      icon: <Icon icon="ri:secure-payment-fill" width={22} height={22} />,
      children: feature_arr
    });
  }
  if (isReviewOperation !== -1) {
    const feature_arr = [];
    if (isReviewRO !== -1) {
      feature_arr.push({
        label: 'Review',
        title: 'review',
        path: '/admin/review_operation/review'
      });
    }
    if (isReviewerReportRO !== -1) {
      feature_arr.push({
        label: 'Reviewer Report',
        title: 'reviewer_report',
        path: '/admin/review_operation/report'
      });
    }
    arr.push({
      label: 'Review Operation',
      title: 'review_operation',
      path: '/admin/review_operation',
      icon: <Icon icon="fontisto:preview" width={22} height={22} />,
      children: feature_arr
    });
  }
  if (isFairOperation !== -1) {
    const feature_arr = [];
    if (isFairFairOpFeature !== -1) {
      feature_arr.push({
        label: 'Fair',
        title: 'fair',
        path: '/admin/fair_operation/fair'
      });
    }
    if (isRequestFairOpFeature !== -1) {
      feature_arr.push({
        label: 'Request',
        title: 'request',
        path: '/admin/fair_operation/request'
      });
    }
    arr.push({
      label: 'Fair Operation',
      title: 'fair_operation',
      path: '/admin/fair_operation',
      icon: <Icon icon="wpf:statistics" width={22} height={22} />,
      children: feature_arr
    });
  }
  if (isPFO !== -1) {
    const feature_arr = [];
    if (isConfigPFO !== -1) {
      feature_arr.push({
        label: 'Config',
        title: 'config',
        path: '/admin/partner_fair_operation/config'
      });
    }
    if (isReportPFO !== -1) {
      feature_arr.push({
        label: 'Report',
        title: 'report',
        path: '/admin/partner_fair_operation/report'
      });
    }
    arr.push({
      label: 'Partner Fair Operation',
      title: 'partner_fair_operation',
      path: '/admin/partner_fair_operation',
      icon: <Icon icon="material-symbols:partner-exchange" width={22} height={22} />,
      children: feature_arr
    });
  }
  if (isJudgeOperation !== -1) {
    const feature_arr = [];
    if (isSessionJOFeature !== -1) {
      feature_arr.push({
        label: 'Session',
        title: 'session',
        path: '/admin/judge_operation/session'
      });
    }
    if (isJudgeJOFeature !== -1) {
      feature_arr.push({
        label: 'Judge',
        title: 'judge',
        path: '/admin/judge_operation/judge'
      });
    }
    if (isGroupJOFeature !== -1) {
      feature_arr.push({
        label: 'Group',
        title: 'group',
        path: '/admin/judge_operation/group'
      });
    }
    if (isJudgingJOFeature !== -1) {
      feature_arr.push({
        label: 'Judging',
        title: 'judging',
        path: '/admin/judge_operation/judging'
      });
    }
    if (isJudgingFormJOFeature !== -1) {
      feature_arr.push({
        label: 'Judging Form',
        title: 'judging_form',
        path: '/admin/judge_operation/form'
      });
    }
    if (isRoboticsJOFeature !== -1) {
      feature_arr.push({
        label: 'Robotics Competition',
        title: 'robotics_competition',
        path: '/admin/judge_operation/robotics_competition'
      });
    }
    arr.push({
      label: 'Judge Operation',
      title: 'judge_operation',
      path: '/admin/judge_operation',
      icon: <Icon icon="ic:round-credit-score" width={22} height={22} />,
      children: feature_arr
    });
  }
  if (isAwardOperation !== -1) {
    const feature_arr = [];
    if (isAwardRO !== -1) {
      feature_arr.push({
        label: 'Award',
        title: 'award',
        path: '/admin/award_operation/award'
      });
    }
    if (isAwardReportRO !== -1) {
      feature_arr.push({
        label: 'Report',
        title: 'award_report',
        path: '/admin/award_operation/report'
      });
    }
    if (isCertificateRO !== -1) {
      feature_arr.push({
        label: 'Certificate Template',
        title: 'certificate_template',
        path: '/admin/award_operation/certificate_template'
      });
    }
    arr.push({
      label: 'Award Operation',
      title: 'award_operation',
      path: '/admin/award_operation',
      icon: <Icon icon="dashicons:awards" width={22} height={22} />,
      children: feature_arr
    });
  }
  if (isPerformanceOperation !== -1) {
    arr.push({
      label: 'Performance Operation',
      title: 'performance_operation',
      path: '/admin/performance_operation',
      icon: <Icon icon="ph:microphone-stage-duotone" width={22} height={22} />
    });
  }
  if (isJudge !== -1) {
    arr.push({
      label: 'Judge',
      title: 'judge',
      path: '/admin/judge',
      icon: <Icon icon="ic:round-credit-score" width={22} height={22} />
    });
  }
  if (isJudgeCertificate !== -1) {
    arr.push({
      label: 'Certificate',
      title: 'certificate',
      path: '/admin/certificate',
      icon: <Icon icon="icon-park-twotone:certificate" width={22} height={22} />
    });
  }
  if (isScorekeeper !== -1) {
    arr.push({
      label: 'Scorekeeper',
      title: 'scorekeeper',
      path: '/admin/scorekeeper',
      icon: <Icon icon="ic:round-credit-score" width={22} height={22} />
    });
  }
  if (isCommunication !== -1) {
    arr.push({
      label: 'Communication',
      title: 'communication',
      path: '/admin/communication',
      icon: <Icon icon="ant-design:mail-twotone" width={22} height={22} />
    });
  }
  if (isDataRecovery !== -1) {
    arr.push({
      label: 'Data Recovery',
      title: 'data_recovery',
      path: '/admin/data_recovery',
      icon: <Icon icon="bx:bx-data" width={22} height={22} />
    });
  }
  if (isGuidelineOperation !== -1) {
    arr.push({
      label: 'Guideline Operation',
      title: 'guideline_operation',
      path: '/admin/guideline_operation',
      icon: <Icon icon="ph:info-duotone" width={22} height={22} />
    });
  }
  if (isDocumentOperation !== -1) {
    arr.push({
      label: 'Document Operation',
      title: 'document_operation',
      path: '/admin/document_operation',
      icon: <Icon icon="mdi:file-document-box-search-outline" width={22} height={22} />
    });
  }
  if (isSurvey !== -1) {
    const feature_arr = [];
    if (isSOS !== -1) {
      feature_arr.push({
        label: 'Survey',
        title: 'survey',
        path: '/admin/survey_operation/survey'
      });
    }
    if (isSOR !== -1) {
      feature_arr.push({
        label: 'Report',
        title: 'report',
        path: '/admin/survey_operation/report'
      });
    }
    arr.push({
      label: 'Survey Operation',
      title: 'survey_operation',
      path: '/admin/survey_operation',
      icon: <Icon icon="wpf:survey" width={22} height={22} />,
      children: feature_arr
    });
  }
  if (isCodingOperation !== -1) {
    arr.push({
      label: 'Coding Operation',
      title: 'coding_operation',
      path: '/admin/coding_operation',
      icon: <Icon icon="mingcute:code-fill" width={22} height={22} />
    });
  }
  if (isSettings !== -1) {
    const feature_arr = [];
    if (isFeeSettingsFeature !== -1) {
      feature_arr.push({
        label: 'Fee',
        title: 'fee',
        path: '/admin/settings/fee'
      });
    }
    if (isTripSettingsFeature !== -1) {
      feature_arr.push({
        label: 'Trip',
        title: 'trip',
        path: '/admin/settings/trip'
      });
    }
    if (isCategorySettingsFeature !== -1) {
      feature_arr.push({
        label: 'Category',
        title: 'category',
        path: '/admin/settings/category'
      });
    }
    if (isDisciplineSettingsFeature !== -1) {
      feature_arr.push({
        label: 'Discipline',
        title: 'discipline',
        path: '/admin/settings/discipline'
      });
    }
    if (isTermSettingsFeature !== -1) {
      feature_arr.push({
        label: 'Terms',
        title: 'terms',
        path: '/admin/settings/terms'
      });
    }
    arr.push({
      label: 'Settings',
      title: 'settings',
      path: '/admin/settings',
      icon: <Icon icon="clarity:settings-solid" width={22} height={22} />,
      children: feature_arr
    });
  }
  if (isUserManagement !== -1) {
    const feature_arr = [];
    if (isRoleUMFeature !== -1) {
      feature_arr.push({
        label: 'Roles',
        title: 'role',
        path: '/admin/user_management/role'
      });
    }
    if (isUserUMFeature !== -1) {
      feature_arr.push({
        label: 'Users',
        title: 'user',
        path: '/admin/user_management/user'
      });
    }
    arr.push({
      label: 'User Management',
      title: 'user_management',
      path: '/admin/user_management',
      icon: <Icon icon="carbon:cloud-service-management" width={22} height={22} />,
      children: feature_arr
    });
  }
  // FAIR
  if (isRequestFairAdminFeature !== -1) {
    arr.push({
      label: 'Fair Admin',
      title: 'fair_admin',
      path: '/fair/fair_admin',
      icon: <Icon icon="iconoir:profile-circled" width={22} height={22} />
    });
  }
  if (isDocumentFairAdminFeature !== -1) {
    arr.push({
      label: 'Documents',
      title: 'documents',
      path: '/fair/documents',
      icon: <Icon icon="fluent:document-20-filled" width={22} height={22} />
    });
  }
  // REVIEWER
  if (isReviewAdmin !== -1) {
    arr.push({
      label: 'Review Admin',
      title: 'review admin',
      path: '/reviewer/review_admin',
      icon: <Icon icon="fontisto:preview" width={22} height={22} />
    });
  }
  return arr;
}
