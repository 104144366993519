import { DialogContent } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import MedalDialog from './MedalDialog';
import CustomDialog from '../../../../../components/CustomComponents/CustomDialog';

function Index(props) {
  const { setMedalDialog, medalDialog, tableData, selectedItems, handleRefresh } = props;

  return (
    <CustomDialog
      onClose={() => setMedalDialog(false)}
      open={medalDialog}
      maxWidth="lg"
      title="Medal Type Change"
    >
      <DialogContent>
        <MedalDialog
          tableData={tableData}
          handleClose={() => setMedalDialog(false)}
          selectedItems={selectedItems}
          handleRefresh={handleRefresh}
        />
      </DialogContent>
    </CustomDialog>
  );
}

Index.propTypes = {
  setMedalDialog: PropTypes.func,
  selectedItems: PropTypes.array,
  medalDialog: PropTypes.bool,
  tableData: PropTypes.array,
  handleRefresh: PropTypes.func
};

export default Index;
