export default [
  {
    name: 'delete',
    label: 'Delete',
    options: {
      sort: false,
      filter: false,
      print: false,
      download: false
    }
  },
  {
    name: 'id',
    label: 'ID'
  },
  {
    name: 'name',
    label: 'Participant Name'
  },
  {
    name: 'participant_type',
    label: 'Type'
  },
  {
    name: 'vip_description',
    label: 'Vip Reason'
  },
  {
    name: 'school_name',
    label: 'School'
  },
  {
    name: 'project_id',
    label: 'Project ID'
  },
  {
    name: 'project_title',
    label: 'Project Title'
  },
  {
    name: 'project_count',
    label: 'Project Count'
  },
  {
    name: 'gender',
    label: 'Gender'
  },
  {
    name: 'grade_level',
    label: 'Grade Level'
  },
  {
    name: 'date_of_birth_comp',
    label: 'Date of Birth'
  }
];
