import { fetchApi } from '../../../api/fetchApi';

const variables = require('./variables');

export async function getPaymentDetail(config, scb, ecb) {
  try {
    const url = variables.GET_PAYMENT_DETAIL;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_PAYMENT_DETAIL');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function makeExternalProjectPayment(config, scb, ecb) {
  try {
    const url = variables.MAKE_PROJECT_PAYMENT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'MAKE_PROJECT_PAYMENT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function makeExternalParticipantPayment(config, scb, ecb) {
  try {
    const url = variables.MAKE_PARTICIPANT_PAYMENT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'MAKE_PARTICIPANT_PAYMENT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}
