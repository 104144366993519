import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Paper } from '@material-ui/core';
import { Button, Stack } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';

const ReviewDetailDialog = (props) => {
  const { handleClose, data } = props;

  return (
    <Stack spacing={2}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow style={{ background: 'gainsboro' }}>
              <TableCell style={{ width: '20%' }}>Reviewer ID</TableCell>
              <TableCell style={{ width: '30%' }}>First Name</TableCell>
              <TableCell style={{ width: '30%' }}>Last Name</TableCell>
              <TableCell style={{ width: '30%' }}>Email</TableCell>
              <TableCell style={{ width: '20%' }}>Score</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.reviewer_id}
                </TableCell>
                <TableCell>{row.reviewer_first_name}</TableCell>
                <TableCell>{row.reviewer_last_name}</TableCell>
                <TableCell>{row.reviewer_email}</TableCell>
                <TableCell>{row.score || '-'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="end" spacing={2}>
        <Button variant="outlined" color="error" onClick={handleClose}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};

ReviewDetailDialog.propTypes = {
  data: PropTypes.array,
  handleClose: PropTypes.func.isRequired
};

export default connect(() => ({}))(ReviewDetailDialog);
