import { Container, Stack, Typography } from '@material-ui/core';
import { Button, Card, Grid } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { Icon } from '@iconify/react';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Page from '../../components/Page';
import { fCurrency } from '../../utils/formatNumber';
import { ReactComponent as EmptyCart } from './empty_cart.svg';
import StripeContainer from '../../components/payments/StripeContainer';
import { getDiscount } from '../Application/Trip/utils/serviceCalls';

function CheckoutWidget(props) {
  const { cartItemsTrips, cartTotalFee, dispatch } = props;
  const [showPayment, setShowPayment] = useState();
  const [discount, setDiscount] = useState(null);
  const [discountList, setDiscountList] = useState(null);
  const [netBalance, setNetBalance] = useState(cartTotalFee);
  const navigate = useNavigate();

  useEffect(() => {
    const idList = [];
    cartItemsTrips.forEach((item) => {
      idList.push(item.participant_id);
    });
    if (idList.length > 0) {
      getDiscount(
        { participant_ids: idList },
        (data) => {
          setDiscountList(data);
          let disc = 0;
          data.forEach((item) => {
            disc += item.discount;
          });
          setNetBalance(parseFloat(cartTotalFee).toFixed(2) - parseFloat(disc).toFixed(2));
          setDiscount(disc);
        },
        () => {}
      );
    }
  }, [cartItemsTrips]);

  function handleRemoveItem(id) {
    dispatch({ type: 'DELETE_ITEM_CART_FROM_TRIPS', payload: id });
  }
  function handleSuccessPayment() {
    navigate('/dashboard/trip', { replace: true });
    dispatch({ type: 'CLEAR_CART' });
  }

  return (
    <div>
      <Page title="Checkout | Genius">
        <Container>
          <Typography variant="h4" sx={{ mb: 5 }}>
            Trip Checkout
          </Typography>
          <Stack spacing={2} justifyContent="center">
            <Grid item xs={12} md={8}>
              <Card>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  justifyContent="space-between"
                  display="flex"
                >
                  <Typography variant="h5" sx={{ mb: 1, padding: 2 }}>
                    Trip(s) Details
                  </Typography>
                  <div style={{ padding: 20 }}>
                    <Button
                      color="error"
                      size="small"
                      variant="contained"
                      onClick={handleSuccessPayment}
                    >
                      Cancel
                    </Button>
                  </div>
                </Stack>
                {cartItemsTrips.length === 0 ? (
                  <Stack style={{ textAlign: 'center', alignItems: 'center' }}>
                    <EmptyCart />
                    <Typography variant="h5" sx={{ padding: 2 }}>
                      Cart is empty
                    </Typography>
                    <Typography variant="body" sx={{ mb: 1, padding: 2 }}>
                      Looks like you have no items in your cart.
                    </Typography>
                  </Stack>
                ) : (
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow style={{ background: 'gainsboro' }}>
                          <TableCell style={{ width: '20%' }}> Type </TableCell>
                          <TableCell style={{ width: '30%' }}>ID</TableCell>
                          <TableCell style={{ width: '40%' }}>Details</TableCell>
                          <TableCell align="right" style={{ width: '20%' }}>
                            Price
                          </TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {cartItemsTrips.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              {row.participant_type}
                            </TableCell>
                            <TableCell>{row.participant_id}</TableCell>
                            <TableCell>{`${row.first_name} ${row.last_name}`}</TableCell>
                            <TableCell align="right">{fCurrency(row.trip_balance)}</TableCell>
                            <TableCell align="right">
                              <Icon
                                style={{ cursor: 'pointer' }}
                                icon={trash2Outline}
                                width={24}
                                height={24}
                                onClick={() => handleRemoveItem(row.participant_id)}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell colSpan={2}>
                            <Typography variant="h6">Order Summary</Typography>
                          </TableCell>
                          <TableCell />
                          <TableCell>
                            <Stack
                              direction="row"
                              spacing={1}
                              flexShrink={0}
                              style={{ justifyContent: 'space-between', marginBottom: 5 }}
                            >
                              <Typography variant="body2">Sub Total</Typography>
                              <Typography variant="subtitle2">
                                {fCurrency(parseFloat(cartTotalFee).toFixed(2))}
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              spacing={1}
                              flexShrink={0}
                              style={{ justifyContent: 'space-between', marginBottom: 5 }}
                            >
                              <Typography variant="body2">Discount</Typography>
                              <Typography variant="subtitle2">
                                {fCurrency(parseFloat(discount).toFixed(2))}
                              </Typography>
                            </Stack>
                            <Divider />
                            <Stack
                              direction="row"
                              spacing={1}
                              flexShrink={0}
                              style={{ justifyContent: 'space-between', marginTop: 5 }}
                            >
                              <Button
                                size="small"
                                variant="contained"
                                onClick={() => setShowPayment(true)}
                                disabled={cartItemsTrips.length === 0 || showPayment}
                              >
                                Pay
                              </Button>
                              <Typography variant="h6" style={{ color: 'red' }}>
                                {fCurrency(parseFloat(netBalance).toFixed(2))}
                              </Typography>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Card>
            </Grid>
            {showPayment && (
              <Grid item xs={12} md={4}>
                <StripeContainer
                  netBalance={netBalance}
                  totalDiscount={discount}
                  discountList={discountList}
                  type="trip"
                  setSuccess={handleSuccessPayment}
                  handleClose={() => setShowPayment(false)}
                />
              </Grid>
            )}
          </Stack>
        </Container>
      </Page>
    </div>
  );
}

CheckoutWidget.propTypes = {
  cartItemsTrips: PropTypes.array,
  cartTotalFee: PropTypes.number,
  dispatch: PropTypes.func.isRequired
};

export default connect((store) => ({
  cartItemsTrips: store.dashboardReducer.cartItemsTrips,
  cartTotalFee: store.dashboardReducer.cartTotalFee
}))(CheckoutWidget);
