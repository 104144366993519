import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LandingPageCard from '../../../components/CustomComponents/CustomCard/LandingPageCard';
import Page from '../../../components/Page';

const CARDS = [
  {
    id: 1,
    name: 'Session',
    description: 'From this module you can arrange sessions.'
  },
  {
    id: 2,
    name: 'Judge',
    description: 'From this module you can arrange jedges.'
  }
];

function Index() {
  const navigate = useNavigate();
  function generate(data) {
    // eslint-disable-next-line no-lone-blocks
    {
      // eslint-disable-next-line no-unused-expressions
      data.id === 1
        ? navigate('/admin/judge_operation/session', { replace: true })
        : navigate('/admin/judge_operation/judge', { replace: true });
    }
  }

  return (
    <Page title="Judge Operation | Genius">
      <Grid container spacing={2}>
        {CARDS.map((item, index) => (
          <Grid item xs={3} key={index}>
            <LandingPageCard data={item} generate={generate} />
          </Grid>
        ))}
      </Grid>
    </Page>
  );
}

export default Index;
