import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Box, Typography, Container } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { Button, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import ReactHtmlParser from 'react-html-parser';
import Page from '../../../../components/Page';
import MuiTable from '../../../../components/CustomComponents/MuiTable';
import { deleteParticipant, getParticipantList, downloadCertificate } from '../serviceCalls';
import { setConfirmationAlert, setSnackbar } from '../../../../actions/alertActions';
import AddPage from './AddPage';
import UpdatePage from './UpdatePage';
import { ParticipantInfo } from '../../../../info/ParticipantInfo';
import ProcessWarningBox from '../../../../components/CustomComponents/CustomCard/ProcessWarningBox';
import ProcessInfoBox from '../../../../components/CustomComponents/CustomCard/ProcessInfoBox';
import { getHeaders } from './utils/headerVariables';
import { dataMaker } from './utils/dataMaker';
import SignatureDialog from './Components/SignatureDialog';

const LANDING_PAGE = 0;
const ADD_PARTICIPANT = 1;
const UPDATE_PARTICIPANT = 2;

function Index(props) {
  const { dispatch, isFinalist, active_term_object } = props;
  const [tableData, setTableData] = useState([]);
  const [mode, setMode] = useState(0);
  const [editData, setEditData] = useState(null);
  const [addPayment, setAddPayment] = useState(false);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [headers, setHeaders] = useState([]);
  const [guidelines, setGuidelines] = useState([]);
  const [signDialog, setSignDialog] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const _headers = getHeaders(active_term_object, tableData).list;
    setHeaders(_headers);
    fetchData();
  }, []);
  function fetchData() {
    getParticipantList(
      {},
      (data) => {
        setGuidelines(data.guidelines);
        const updatedData = dataMaker(
          data.data,
          handleDownload,
          handleEditParticipant,
          handleDeleteParticipant,
          handleSign,
          active_term_object
        );
        const _headers = getHeaders(active_term_object, updatedData).list;
        setHeaders(_headers);
        setTableData(updatedData);
      },
      () => {}
    );
  }
  function handleDownload(certificate_id) {
    setLoading(true);
    downloadCertificate(
      { certificate_id },
      () => {
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  }
  function handleAddParticipant() {
    setMode(ADD_PARTICIPANT);
  }
  function handleSign(participant) {
    setEditData(participant);
    setSignDialog(true);
  }
  function handleEditParticipant(data) {
    setEditData(data);
    setMode(UPDATE_PARTICIPANT);
  }
  function refreshData() {
    setMode(LANDING_PAGE);
    setSignDialog(false);
    setEditData(null);
    fetchData();
  }
  function handleDeleteParticipant(participant_id) {
    dispatch(
      setConfirmationAlert(
        'Are you sure?',
        'YES, DELETE',
        'This action will delete participant permanently',
        () => {
          deleteParticipant(
            { participant_id },
            () => {
              fetchData();
              dispatch(setSnackbar('success', 'The participant has been deleted'));
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            },
            () => {
              dispatch({ type: 'RESET_CONFIRMATION_ALERT' });
            }
          );
        }
      )
    );
  }
  const startButtonDisabled = selectedStudents.length === 0;
  const handleCancel = () => {
    dispatch({ type: 'CLEAR_CART' });
    setSelectedStudents([]);
    setAddPayment(false);
  };
  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    let totalFee = parseFloat('0').toFixed(2);
    const list = [];
    allRowsSelected.forEach((item) => {
      totalFee = parseFloat(totalFee) + parseFloat(tableData[item.index].balance);
      list.push(tableData[item.index]);
    });
    setSelectedStudents(rowsSelected);
    dispatch({ type: 'ADD_FEE', payload: totalFee });
    dispatch({ type: 'ADD_ITEM_CART_FROM_PARTICIPANTS', payload: list });
  };
  function CustomHeader() {
    return (
      <Stack spacing={3} direction={{ sm: 'row' }}>
        {!addPayment && (
          <Button variant="outlined" onClick={handleAddParticipant}>
            Add New
          </Button>
        )}
        {loading && (
          <Stack
            spacing={1}
            direction={{ xs: 'column', sm: 'row' }}
            sx={{ mr: 1, display: 'flex', alignItems: 'center' }}
          >
            <p style={{ fontWeight: 'bold' }}>Downloading...</p>
            <CircularProgress size={20} />
          </Stack>
        )}
        {!addPayment && tableData.length > 0 && (
          <Button
            startIcon={<Icon icon="ri:secure-payment-fill" width={22} height={22} />}
            color="secondary"
            variant="outlined"
            onClick={() => setAddPayment(true)}
          >
            Make Payment
          </Button>
        )}
        {addPayment && (
          <div>
            <Button
              color="secondary"
              variant="outlined"
              disabled={startButtonDisabled}
              onClick={() => {
                navigate('/dashboard/payments_for_participants', { replace: true });
              }}
            >
              Checkout
            </Button>
            <Button
              variant="outlined"
              color="error"
              style={{ marginLeft: 10 }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        )}
      </Stack>
    );
  }

  return (
    <Page title="Participant | Genius">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5, mt: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h4">
            Participant
            {/* eslint-disable-next-line no-nested-ternary */}
            {mode === ADD_PARTICIPANT
              ? ' / Add New Participant'
              : mode === UPDATE_PARTICIPANT
              ? ' / Update Participant'
              : null}
          </Typography>
          {mode !== LANDING_PAGE && (
            <Button
              startIcon={<Icon icon="akar-icons:arrow-back-thick-fill" />}
              variant="outlined"
              onClick={() => setMode(LANDING_PAGE)}
            >
              Back
            </Button>
          )}
        </Box>
        {mode === LANDING_PAGE ? (
          isFinalist ? (
            <Stack spacing={1}>
              {guidelines.length > 0 && (
                <ProcessInfoBox Comp={ReactHtmlParser(guidelines[0].markdown)} />
              )}
              <MuiTable
                title={<CustomHeader />}
                tableData={tableData}
                tableColumns={headers}
                customOption={{
                  selectableRows: addPayment ? 'multiple' : 'none',
                  selectableRowsOnClick: true,
                  onRowSelectionChange,
                  rowsSelected: selectedStudents,
                  isRowSelectable: (dataIndex) =>
                    Number(parseFloat(tableData[dataIndex].balance)) > 0
                }}
              />
            </Stack>
          ) : (
            <ProcessWarningBox Comp={<ParticipantInfo />} />
          )
        ) : mode === ADD_PARTICIPANT ? (
          <AddPage handleBack={refreshData} />
        ) : (
          <UpdatePage data={editData} handleRefresh={refreshData} handleClose={() => setMode(0)} />
        )}
        {signDialog && (
          <SignatureDialog
            participant={editData}
            handleClose={() => setSignDialog(false)}
            refreshData={refreshData}
          />
        )}
      </Container>
    </Page>
  );
}

Index.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isFinalist: PropTypes.bool,
  active_term_object: PropTypes.object
};

export default connect((store) => ({
  isFinalist: store.dashboardReducer.isFinalist,
  active_term_object: store.loginReducer.active_term_object
}))(Index);
