import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Tooltip, Paper, ListItemSecondaryAction } from '@material-ui/core';
import { Button } from '@mui/material';

export default function RoleList(props) {
  const { editRoleClick, addRoleClick, selectedRoleIndex, deleteRoleClick, rolesList } = props;
  return (
    <Paper>
      <List dense>
        {rolesList &&
          rolesList.length > 0 &&
          rolesList.map((role, index) => (
            <ListItem key={index} divider selected={selectedRoleIndex === index}>
              <ListItemText primary={role.role_name} />
              <ListItemSecondaryAction>
                <Tooltip title="Edit Role">
                  <IconButton color="primary" edge="end" onClick={() => editRoleClick(role, index)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete Role">
                  <IconButton
                    color="error"
                    edge="end"
                    onClick={() => deleteRoleClick(role.role_id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
      </List>
      <Button variant="outlined" onClick={addRoleClick}>
        Add New Role
      </Button>
    </Paper>
  );
}

RoleList.propTypes = {
  editRoleClick: PropTypes.func.isRequired,
  addRoleClick: PropTypes.func.isRequired,
  selectedRoleIndex: PropTypes.number.isRequired,
  deleteRoleClick: PropTypes.func.isRequired,
  rolesList: PropTypes.array.isRequired
};
