import { fetchApi } from '../../../../api/fetchApi';

const documentVariables = require('./apis');

export async function getDocumentList(config, scb, ecb) {
  try {
    const url = documentVariables.GET_DOCUMENT_LIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_DOCUMENT_LIST');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getDocumentRole(config, scb, ecb) {
  try {
    const url = documentVariables.GET_DOCUMENT_ROLE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_DOCUMENT_ROLE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getDocument(config, scb, ecb) {
  try {
    const url = documentVariables.GET_DOCUMENT;
    await fetchApi(
      url,
      { document_id: config.id },
      (data) => {
        getFilesApi(data, config, scb, ecb);
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

const getFilesApi = (data, config, scb, ecb) => {
  const { url } = data;
  const requestOptions = {
    method: 'GET'
  };
  try {
    fetch(url, requestOptions)
      // eslint-disable-next-line consistent-return
      .then((response) => {
        if (response.status === 200) {
          return response.blob();
        }
        ecb(response.status);
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        scb();
        console.log(link);
        link.href = href;
        link.setAttribute('download', `${config.name}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  } catch (error) {
    console.log(error);
    ecb(error);
  }
};

export async function addDocument(config, scb, ecb) {
  const params = {
    role_id: config.roleId,
    description: config.description,
    name: config.file.name,
    title: config.title
  };
  try {
    const url = documentVariables.ADD_DOCUMENT;
    await fetchApi(
      url,
      params,
      (data) => {
        uploadFilesApi(data, config, scb, ecb);
        console.log(data, 'ADD_FILE');
      },
      (error) => {
        ecb();
        console.log(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

const uploadFilesApi = (data, config, scb, ecb) => {
  const { url } = data;

  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': config.file.type },
    body: config.file
  };
  try {
    fetch(url, requestOptions).then((response) => {
      if (response.status === 200) {
        uploadStatusOfFile(
          {
            role_id: config.roleId,
            name: config.file.name,
            is_verified: 1
          },
          () => {},
          () => {}
        );
        scb();
      } else {
        uploadStatusOfFile(
          {
            role_id: config.roleId,
            name: config.file.name,
            is_verified: 0
          },
          () => {},
          () => {}
        );
        ecb();
      }
    });
  } catch (error) {
    console.log(error);
    ecb();
  }
};

export async function uploadStatusOfFile(config, scb, ecb) {
  try {
    const url = documentVariables.UPDATE_DOCUMENT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UPDATE_DOCUMENT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteDocument(config, scb, ecb) {
  try {
    const url = documentVariables.DELETE_DOCUMENT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_DOCUMENT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}
