import { fetchApi } from '../../../../../api/fetchApi';

const judging = require('./judging_apis');

export async function getJudgingGroup(config, scb, ecb) {
  try {
    const url = judging.GET_JUDGING_GROUP;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_JUDGING_GROUP');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addCertificateForJudging(config, scb, ecb) {
  try {
    const url = judging.ADD_CERTIFICATE_TO_JUDGING;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_CERTIFICATE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function assignJudging(config, scb, ecb) {
  try {
    const url = judging.JUDGING_ASSIGN;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'JUDGING_ASSIGN');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getJudgingGroupList(config, scb, ecb) {
  try {
    const url = judging.GET_JUDGING_GROUP_LIST;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_JUDGING_GROUP_LIST');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getJudgingGroupProject(config, scb, ecb) {
  try {
    const url = judging.GET_JUDGING_GROUP_PROJECT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_JUDGING_GROUP_PROJECT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getJudgingJudge(config, scb, ecb) {
  try {
    const url = judging.GET_JUDGING_JUDGE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_JUDGING_JUDGE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getJudgingJudgeProjects(config, scb, ecb) {
  try {
    const url = judging.GET_JUDGING_JUDGE_PROJECT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_JUDGING_JUDGE_PROJECT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteJudgingJudgeProject(config, scb, ecb) {
  try {
    const url = judging.DELETE_JUDGING_JUDGE_PROJECT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'DELETE_JUDGING_JUDGE_PROJECT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addJudgingJudgeProject(config, scb, ecb) {
  try {
    const url = judging.ADD_JUDGING_JUDGE_PROJECT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ADD_JUDGING_JUDGE_PROJECT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getJudgingJudgeAvailableProject(config, scb, ecb) {
  try {
    const url = judging.GET_JUDGING_JUDGE_AVAILABLE_PROJECT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_JUDGING_JUDGE_AVAILABLE_PROJECT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getJudgingProject(config, scb, ecb) {
  try {
    const url = judging.GET_JUDGING_PROJECT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_JUDGING_PROJECT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getJudgingProjectDetail(config, scb, ecb) {
  try {
    const url = judging.GET_JUDGING_PROJECT_DETAIL;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_JUDGING_PROJECT_DETAIL');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function unasssignJudgingProject(config, scb, ecb) {
  try {
    const url = judging.UNASSIGN_JUDGING_PROJECT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'UNASSIGN_JUDGING_PROJECT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getJudgingProjectJudge(config, scb, ecb) {
  try {
    const url = judging.GET_JUDGING_PROJECT_JUDGE;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_JUDGING_PROJECT_JUDGE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function assignJudgingProject(config, scb, ecb) {
  try {
    const url = judging.ASSIGN_JUDGING_PROJECT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'ASSIGN_JUDGING_PROJECT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getJudgingProjectBadge(config, scb, ecb, secondError) {
  try {
    const url = judging.GET_JUDGING_PROJECT_BADGE;
    await fetchApi(
      url,
      config,
      (data) => {
        if (data?.result.url) {
          getBadgeFiles(data?.result, secondError);
        }
        scb(data);
        console.log(data, 'GET_BADGE');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

const getBadgeFiles = (data, ecb) => {
  const { url } = data;
  const extension = url.substring(url.lastIndexOf('.') + 1, url.lastIndexOf('?'));

  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'multipart/form-data' }
  };
  try {
    fetch(url, requestOptions)
      // eslint-disable-next-line consistent-return
      .then((response) => {
        if (response.status === 200) {
          return response.blob();
        }
        ecb(response.status);
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        console.log(link);
        link.href = href;
        link.setAttribute('download', `badges.${extension}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  } catch (error) {
    console.log(error);
    ecb(error);
  }
};

export async function getMonitoringReport(config, scb, ecb) {
  try {
    const url = judging.GET_MONITORING_REPORT;
    await fetchApi(
      url,
      config,
      (data) => {
        scb(data);
        console.log(data, 'GET_MONITORING_REPORT');
      },
      (error) => {
        console.log(error);
        ecb(error);
      }
    );
  } catch (error) {
    console.log(error);
  }
}
