import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { blue } from '@material-ui/core/colors';
import { Grid, Stack, Typography } from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';
import { Button } from '@mui/material';
import { CloudDownload } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  avatar: {
    backgroundColor: blue[500]
  },
  cardAction: {
    float: 'right'
  }
}));

export default function CustomCertificateCard(props) {
  const classes = useStyles();
  const { data, downloadCertificate, loading } = props;

  return (
    <Card>
      <CardHeader title="Community Service Certificate" />
      <CardContent>
        <Typography variant="body2" color="textPrimary" component="span">
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <span>
                <b>First Name: </b>
              </span>
              {data?.first_name || '-'}
            </Grid>
            <Grid item xs={6}>
              <span>
                <b>Last Name: </b>
              </span>
              {data?.last_name || '-'}
            </Grid>
            <Grid item xs={6}>
              <span>
                <b>Institution: </b>
              </span>
              {data?.institution || '-'}
            </Grid>
            <Grid item xs={6}>
              <span>
                <b>Judging Sessions: </b>
              </span>
              {data?.judging_sessions || '-'}
            </Grid>
          </Grid>
        </Typography>
      </CardContent>
      <CardActions className={classes.cardAction}>
        {loading ? (
          <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }} sx={{ mr: 1 }}>
            <p style={{ fontWeight: 'bold' }}>Downloading...</p>
            <CircularProgress size={20} />
          </Stack>
        ) : (
          <Button
            startIcon={<CloudDownload />}
            variant="outlined"
            onClick={() => downloadCertificate(data)}
          >
            Download
          </Button>
        )}
      </CardActions>
    </Card>
  );
}

CustomCertificateCard.propTypes = {
  data: PropTypes.object.isRequired,
  downloadCertificate: PropTypes.func.isRequired,
  loading: PropTypes.bool
};
