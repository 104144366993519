import { Container, Stack, Typography } from '@material-ui/core';
import { Button, Card, Grid } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Page from '../../components/Page';
import { fCurrency } from '../../utils/formatNumber';
import StripeContainer from '../../components/payments/StripeContainer';

function ParticipantCheckoutComponent(props) {
  const { cartItemsParticipants, cartTotalFee, dispatch } = props;
  const [showPayment, setShowPayment] = useState();
  const navigate = useNavigate();

  function handleSuccessPayment() {
    navigate('/', { replace: true });
    dispatch({ type: 'CLEAR_CART' });
  }

  return (
    <div>
      <Page title="Checkout | Genius">
        <Container>
          <Typography variant="h4" sx={{ mb: 5 }}>
            Participant Checkout
          </Typography>
          <Stack
            spacing={2}
            justifyContent="center"
            sx={{
              display: 'flex',
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Grid item xs={12}>
              <Card>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  justifyContent="space-between"
                  display="flex"
                >
                  <Typography variant="h5" sx={{ mb: 1, padding: 2 }}>
                    Participant(s) Details
                  </Typography>
                </Stack>
                {cartItemsParticipants.length === 0 ? (
                  <Stack style={{ textAlign: 'center', alignItems: 'center' }}>
                    <Typography variant="h5" sx={{ padding: 2 }}>
                      Cart is empty
                    </Typography>
                    <Typography variant="body" sx={{ mb: 1, padding: 2 }}>
                      Looks like you have no items in your cart.
                    </Typography>
                  </Stack>
                ) : (
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow style={{ background: 'gainsboro' }}>
                          <TableCell style={{ width: '20%' }}> Type </TableCell>
                          <TableCell style={{ width: '30%' }}>ID</TableCell>
                          <TableCell style={{ width: '40%' }}>Details</TableCell>
                          <TableCell align="right" style={{ width: '20%' }}>
                            Price
                          </TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {cartItemsParticipants.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              Participant
                            </TableCell>
                            <TableCell>{row.id}</TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell align="right">{fCurrency(row.balance)}</TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell colSpan={2}>
                            <Typography variant="h6">Order Summary</Typography>
                          </TableCell>
                          <TableCell />
                          <TableCell>
                            <Typography variant="h6" style={{ color: 'red' }}>
                              {fCurrency(parseFloat(cartTotalFee).toFixed(2))}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Button
                              size="small"
                              variant="contained"
                              onClick={() => setShowPayment(true)}
                              disabled={cartItemsParticipants.length === 0 || showPayment}
                            >
                              Pay
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Card>
            </Grid>
            {showPayment && (
              <Grid item xs={12} md={6}>
                <StripeContainer
                  external
                  type="participant"
                  setSuccess={handleSuccessPayment}
                  handleClose={() => setShowPayment(false)}
                />
              </Grid>
            )}
          </Stack>
        </Container>
      </Page>
    </div>
  );
}

ParticipantCheckoutComponent.propTypes = {
  cartItemsParticipants: PropTypes.array,
  cartTotalFee: PropTypes.number,
  dispatch: PropTypes.func.isRequired
};

export default connect((store) => ({
  cartItemsParticipants: store.dashboardReducer.cartItemsParticipants,
  cartTotalFee: store.dashboardReducer.cartTotalFee
}))(ParticipantCheckoutComponent);
