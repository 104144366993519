import React from 'react';
import { Stack } from '@material-ui/core';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ProcessInfoBox from '../../../components/CustomComponents/CustomCard/ProcessInfoBox';

export default function JudgeInfoBoxView() {
  const navigate = useNavigate();

  function handleBackHome() {
    navigate('/', { replace: true });
  }

  return (
    <ProcessInfoBox
      noMinimize
      title="Success Registration"
      Comp={
        <Stack spacing={1}>
          <p>
            You have completed your registration. You will be notified via email address for your
            account details and judging assignment.
          </p>
          <div>
            <Button variant="outlined" onClick={handleBackHome}>
              Back to Main Page
            </Button>
          </div>
        </Stack>
      }
    />
  );
}
