import { connect } from 'react-redux';
import { Form, FormikProvider, useFormik } from 'formik';
import { Button, Grid } from '@mui/material';
import { MenuItem, Stack, TextField } from '@material-ui/core';
import * as React from 'react';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { addStudent, fetchGender } from '../utils/serviceCalls';

const GRADE_LIST = [8, 9, 10, 11, 12];

function AddStudentForm(props) {
  const { project_id, setFormShow, handleRefresh, active_term_object } = props;
  const [genderList, setGenderList] = useState([]);
  useEffect(() => {
    fetchGender(
      {},
      (data) => {
        setGenderList(data);
      },
      () => {}
    );
  }, []);
  const englishChar = /^[-a-zA-Z0-9& ]+$/;
  const StudentSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(2, 'Too Short!')
      .max(500, 'Too Long!')
      .matches(englishChar, 'Please enter only English characters')
      .required('First name is required'),
    last_name: Yup.string()
      .min(2, 'Too Short!')
      .max(500, 'Too Long!')
      .matches(englishChar, 'Please enter only English characters')
      .required('Last name is required'),
    gender_id: Yup.string().required('Gender is required'),
    email: Yup.string()
      .max(500, 'Too Long!')
      .email('Email must be a valid email address')
      .required('Student Email is required'),
    date_of_birth: Yup.date()
      .max(
        moment(active_term_object.student_dob_min_value).format('YYYY-MM-DD'),
        'Students must be older than 13 at the time of registration'
      )
      .required('Date of Birth is required')
      .typeError('Date of Birth is required'),
    grade_level_id: Yup.string().required('Grade Level is required')
  });

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      gender_id: '',
      email: '',
      date_of_birth: null,
      grade_level_id: ''
    },
    validationSchema: StudentSchema,
    onSubmit: (values, { resetForm }) => {
      const obj = {
        ...values,
        project_id,
        first_name: values.first_name.toUpperCase(),
        last_name: values.last_name.toUpperCase()
      };
      addStudent(
        obj,
        () => {
          handleRefresh();
          resetForm();
          setFormShow(false);
        },
        () => {}
      );
    }
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid item xs={12}>
          <Stack spacing={3} sx={{ mt: 2, mb: 1 }}>
            <Stack spacing={3} sx={{ mt: 2, mb: 1 }} direction={{ xs: 'column', sm: 'row' }}>
              <TextField
                inputProps={{ style: { textTransform: 'uppercase' } }}
                fullWidth
                label="First Name"
                {...getFieldProps('first_name')}
                error={Boolean(touched.first_name && errors.first_name)}
                helperText={touched.first_name && errors.first_name}
              />
              <TextField
                inputProps={{ style: { textTransform: 'uppercase' } }}
                fullWidth
                label="Last Name"
                {...getFieldProps('last_name')}
                error={Boolean(touched.last_name && errors.last_name)}
                helperText={touched.last_name && errors.last_name}
              />
            </Stack>
            <Stack spacing={3} sx={{ mt: 2, mb: 1 }} direction={{ xs: 'column', sm: 'row' }}>
              <TextField
                fullWidth
                label="Gender"
                select
                {...getFieldProps('gender_id')}
                error={Boolean(touched.gender_id && errors.gender_id)}
                helperText={touched.gender_id && errors.gender_id}
              >
                {genderList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                type="date"
                label="Date of Birth"
                {...getFieldProps('date_of_birth')}
                error={Boolean(touched.date_of_birth && errors.date_of_birth)}
                helperText={touched.date_of_birth && errors.date_of_birth}
              />
            </Stack>
            <Stack spacing={3} sx={{ mt: 2, mb: 1 }} direction={{ xs: 'column', sm: 'row' }}>
              <TextField
                fullWidth
                label="Email"
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
              <TextField
                fullWidth
                select
                label="Grade Level"
                {...getFieldProps('grade_level_id')}
                error={Boolean(touched.grade_level_id && errors.grade_level_id)}
                helperText={touched.grade_level_id && errors.grade_level_id}
              >
                {GRADE_LIST.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="end" spacing={2}>
              <Button type="submit" variant="outlined">
                Save
              </Button>
              <Button variant="outlined" color="error" onClick={() => setFormShow(false)}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

AddStudentForm.propTypes = {
  handleRefresh: PropTypes.func.isRequired,
  setFormShow: PropTypes.func.isRequired,
  project_id: PropTypes.number,
  active_term_object: PropTypes.object
};

export default connect((store) => ({
  project_id: store.projectReducer.activeProject,
  active_term_object: store.loginReducer.active_term_object
}))(AddStudentForm);
